import Decimal from 'decimal.js';

import {
  GrowthProfileGrowthType,
  GrowthProfileOverrideKind,
} from '@/types/schema';
import { PathsOf } from '@/types/subform';

import { GrowthProfileModal_GrowthProfileOverrideFragment } from './graphql/GrowthProfileModal.generated';

export enum CustomEntityGrowthCustomizationType {
  GrowthRate = 'GrowthRate',
  FutureValuation = 'FutureValuation',
  GrowthRateAndFutureValuation = 'GrowthRateAndFutureValuation',
  None = 'None',
}

// re-use the existing fragment, but make the `id` field optional for cases where we create
// new overrides/profiles
export interface GrowthProfileModalGrowthOverride
  extends Omit<GrowthProfileModal_GrowthProfileOverrideFragment, 'id'> {
  /**
   * This ID is the index of the asset class or entity in the list -- the actual asset class or entity ID is held within those fields
   */
  id: number;
  /**
   * The currently-selected entity ID if the override applies to an entity.
   */
  entityId?: string | undefined;
  customizationType: CustomEntityGrowthCustomizationType;
  defaultGrowthRate?: Decimal | undefined | null;
  customGrowthRate?: Decimal | undefined | null;
  displayName: string;
}

export interface GrowthProfileModalFormShape {
  displayName: string;
  exemptionGrowthRate: Decimal;
  willExemptionSunset: boolean;
  growthType: GrowthProfileGrowthType;
  /** This is displayed as the "Global growth rate" on the form.*/
  assetGrowthReturn: Decimal;
  growthProfileOverrides: GrowthProfileModalGrowthOverride[];
}

export type GrowthProfileModalFormPaths = PathsOf<GrowthProfileModalFormShape>;

export const defaultGrowthProfileModalFormShape: GrowthProfileModalFormShape = {
  displayName: 'Untitled growth profile',
  exemptionGrowthRate: new Decimal(0),
  willExemptionSunset: true,
  growthType: GrowthProfileGrowthType.DefaultGrowth,
  assetGrowthReturn: new Decimal(0),
  growthProfileOverrides: [],
};

export interface GrowthProfileCustomGrowthOverrideRowData {
  id: number;
  kind: GrowthProfileOverrideKind;

  displayName: string;
  displayType: string;

  futureValuationValue: string;
  futureValuationYear: string | undefined;

  growthRateValue: string;
  growthRateSubtitle: string | undefined;
}
