import { Box, Stack, Typography } from '@mui/material';
import { isEmpty, sum } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';

import { Divider } from '@/components/Divider';
import { Button } from '@/components/form/baseInputs/Button';
import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { PlusCircleIcon } from '@/components/icons/PlusCircleIcon';
import { SearchSmIcon } from '@/components/icons/SearchSmIcon';
import { XCircleIcon } from '@/components/icons/XCircleIcon';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { TableHeaderBar } from '@/components/tables/components/TableHeaderBar/TableHeaderBar';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { AI_ONBOARDING_MODAL_FORM_TABLE_STYLE_CONFIGS } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.constants';
import {
  AIOnboardingModalFormShape,
  AIOnboardingModalFormShapeKeys,
} from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import { EntityFieldsRow } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/fields/entities.fields';
import { getEmptyEntityField } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/fields/entities.fields.utils';
import { IndividualFieldsRow } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/fields/individuals.fields';
import { getEmptyIndividualField } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/fields/individuals.fields.utils';
import { OrganizationFieldsRow } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/fields/organizations.fields';
import { getEmptyOrganizationField } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/fields/organizations.fields.utils';
import { TestamentaryEntityFieldsRow } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/fields/testamentaryEntities.fields';
import { getEmptyTestamentaryEntityField } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/fields/testamentaryEntities.fields.utils';
import { useAIOnboardingModalContext } from '@/modules/aiOnboarding/AIOnboardingModal/context/AIOnboardingModal.context';
import { useSelectedSuggestionEvidenceContext } from '@/modules/aiSuggestions/context/SelectedSuggestionEvidence.context';
import { COLORS } from '@/styles/tokens/colors';
import { diagnostics } from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';

interface AIOnboardingModalFormTableProps {
  header: string;
  formPath: AIOnboardingModalFormShapeKeys;
}

export function AIOnboardingModalFormTable({
  header,
  formPath,
}: AIOnboardingModalFormTableProps) {
  const trackUserEvent = useTrackUserEvent();

  const { addRejectedSuggestion, suggestionsByID } =
    useAIOnboardingModalContext();

  const { locateSuggestion } = useSelectedSuggestionEvidenceContext();
  const { control } = useFormContext<AIOnboardingModalFormShape>();

  const { fields, remove, prepend } = useFieldArray({
    control,
    name: formPath,
  });

  const { columnPaddingHorizontal } =
    AI_ONBOARDING_MODAL_FORM_TABLE_STYLE_CONFIGS[formPath].styles;

  const columns = useMemo(
    () =>
      Object.values(
        AI_ONBOARDING_MODAL_FORM_TABLE_STYLE_CONFIGS[formPath].columns
      ).filter((c) => c.columnHeader),
    [formPath]
  );

  const handleRemoveRow = useCallback(
    (fieldId: string) => {
      const index = fields.findIndex((field) => field.id === fieldId);
      const field = fields[index];
      if (index === -1 || !field) {
        diagnostics.warn(
          `Trying to remove fieldArrayId=${fieldId} but it does not exist in the AIOnboarding.${formPath} form`
        );
        return;
      }
      addRejectedSuggestion(field._suggestionID);
      remove(index);
      trackUserEvent('ai_onboarding delete suggested row', {
        formPath,
      });
    },
    [addRejectedSuggestion, fields, formPath, remove, trackUserEvent]
  );

  const handleAddRow = useCallback(() => {
    trackUserEvent('ai_onboarding add row', {
      formPath,
    });

    switch (formPath) {
      case 'individuals':
        prepend(getEmptyIndividualField());
        break;
      case 'organizations':
        prepend(getEmptyOrganizationField());
        break;
      case 'entities':
        prepend(getEmptyEntityField());
        break;
      case 'testamentaryEntities':
        prepend(getEmptyTestamentaryEntityField());
        break;
      default:
        throw new UnreachableError({
          case: formPath,
          message:
            'Unknown formPath in AIOnboardingModalFormTable.handleAddRow',
        });
    }
  }, [formPath, prepend, trackUserEvent]);

  const handleSearchEvidence = useCallback(
    (suggestionID: string | undefined) => {
      if (!suggestionID || !suggestionsByID[suggestionID]) {
        diagnostics.warn(
          `Trying to search evidence for suggestionID=${suggestionID} but it does not exist`
        );
        return;
      }
      locateSuggestion(suggestionsByID[suggestionID]!);
    },
    [locateSuggestion, suggestionsByID]
  );

  if (sum(columns.map((c) => c.columnWidth)) != 12) {
    throw new Error(
      `The sum of column widths must be 12 for AIOnboardingModal.${formPath}`
    );
  }

  return (
    <Stack data-testid={`onboarding-suggestion-table-${formPath}`}>
      <TableHeaderBar
        header={header}
        variant="h5"
        rightContent={
          <Button
            size="xs"
            variant="transparent-inverse"
            startIcon={PlusCircleIcon}
            onClick={handleAddRow}
            data-testid="onboarding-suggestion-table-addRow-button"
          >
            Manually add
          </Button>
        }
      />
      <Stack direction="row" p={1.5} bgcolor={COLORS.GRAY['100']}>
        <Box width="40px" />
        <Stack width="100%" pl={1.5}>
          <FormLayoutRow
            sx={{
              gap: columnPaddingHorizontal,
            }}
          >
            {columns.map(({ columnHeader, columnWidth }) => (
              <FormLayoutItem key={columnHeader} width={columnWidth}>
                <Typography variant="h6" color={COLORS.GRAY['500']}>
                  {columnHeader}
                </Typography>
              </FormLayoutItem>
            ))}
          </FormLayoutRow>
        </Stack>
        <Box width="40px" />
      </Stack>
      <Stack
        divider={
          <Divider sx={{ backgroundColor: COLORS.GRAY['200'] }} noMargin />
        }
      >
        {fields.map((field, index) => (
          <AIOnboardingModalFormRow
            key={field.id}
            index={index}
            formPath={formPath}
            suggestionID={field._suggestionID}
            onEvidenceClick={() => handleSearchEvidence(field._suggestionID)}
            onRemoveClick={() => handleRemoveRow(field.id)}
          />
        ))}
        {isEmpty(fields) && (
          <Typography ml={7} mt={2.5} color={COLORS.GRAY['500']}>
            No remaining suggestions
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

interface AIOnboardingModalFormRowProps {
  index: number;
  formPath: AIOnboardingModalFormShapeKeys;
  suggestionID: string | undefined;
  onEvidenceClick: () => void;
  onRemoveClick: () => void;
}

export function AIOnboardingModalFormRow({
  index,
  formPath,
  suggestionID,
  onEvidenceClick,
  onRemoveClick,
}: AIOnboardingModalFormRowProps) {
  const { rowPaddingVertical } =
    AI_ONBOARDING_MODAL_FORM_TABLE_STYLE_CONFIGS[formPath].styles;

  const row = useMemo(() => {
    switch (formPath) {
      case 'individuals':
        return <IndividualFieldsRow index={index} />;
      case 'organizations':
        return <OrganizationFieldsRow index={index} />;
      case 'entities':
        return <EntityFieldsRow index={index} suggestionID={suggestionID} />;
      case 'testamentaryEntities':
        return <TestamentaryEntityFieldsRow index={index} />;
    }
  }, [formPath, index, suggestionID]);

  return (
    <Stack
      direction="row"
      sx={{
        pt: rowPaddingVertical,
        pb: rowPaddingVertical,
      }}
      data-testid="onboarding-suggestion-row"
    >
      <Stack pl={1.5} pr={1.5} alignItems="center" justifyContent="center">
        {suggestionID ? (
          <IconButton
            size="xs"
            icon={SearchSmIcon}
            onClick={onEvidenceClick}
            ariaLabel="Show evidence"
            sx={{
              p: '3px',
              color: 'white',
              background: COLORS.TEAL[600],
              border: 'none',
              ':hover': {
                color: 'white',
                background: COLORS.TEAL[900],
                border: 'none',
              },
            }}
            data-testid="onboarding-suggestion-row-search-button"
          />
        ) : (
          // Rows that are added manually will not have a search icon, but we
          // need a placeholder for the cell width.
          <Box width="22px" />
        )}
      </Stack>
      <Stack width="100%" gap={1} pl={1.5}>
        {row}
      </Stack>
      <Stack pl={1.5} pr={1.5} alignItems="center" justifyContent="center">
        <IconButton
          size="xs"
          icon={XCircleIcon}
          onClick={onRemoveClick}
          ariaLabel="Remove row"
          sx={{
            color: COLORS.GRAY['500'],
            border: 'none',
          }}
          data-testid="onboarding-suggestion-row-remove-button"
        />
      </Stack>
    </Stack>
  );
}
