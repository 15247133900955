import { Stack } from '@mui/material';
import { useLayoutEffect, useMemo } from 'react';

import { CompoundHeader } from '@/components/display/CompoundHeader/CompoundHeader';
import { useEntityDPSuggestionsContext } from '@/modules/aiDP/entity/context/entityDPSuggestions.context';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';

import { useDispositiveProvisionsContext } from '../../contexts/dispositiveProvisions.context';
import { DispositionSchemeSelector } from '../../DispositionSchemeSelector/DispositionSchemeSelector';
import { DispositionScheme } from '../../dispositiveProvisions.types';
import {
  useActiveClientProfile,
  useDispositionScheme,
  useHasBeenReviewed,
  useOrderedDyingClients,
} from '../hooks/utilityHooks';

export interface InitialDispositionSchemeSelectorProps {
  onSchemeSelection: (scheme: DispositionScheme) => void;
}

export function InitialDispositionSchemeSelector({
  onSchemeSelection,
}: InitialDispositionSchemeSelectorProps) {
  const aiDpEnabled = useFeatureFlag('ai_dispositive_provisions');

  const {
    client: activeClientProfile,
    isFirstToDie: activeClientProfileIsFirstToDie,
  } = useActiveClientProfile();

  const { isTwoClientHousehold, isClientProfile } =
    useDispositiveProvisionsContext();

  const [firstClientToDie] = useOrderedDyingClients();

  const { hasSuggestions } = useEntityDPSuggestionsContext();

  const hasBeenReviewed = useHasBeenReviewed();
  const dispositionScheme = useDispositionScheme();

  const subheading = useMemo(() => {
    if (isTwoClientHousehold && !isClientProfile) {
      return `If ${firstClientToDie.firstName} dies first`;
    }

    if (!isTwoClientHousehold) return undefined;

    // the last case here is that we're a two-client household looking at a client profile
    // configuration situation
    if (activeClientProfileIsFirstToDie) {
      return `If ${activeClientProfile?.firstName} dies first`;
    }

    return `If ${firstClientToDie.firstName} dies first and ${activeClientProfile?.firstName} dies second`;
  }, [
    activeClientProfile?.firstName,
    activeClientProfileIsFirstToDie,
    firstClientToDie.firstName,
    isClientProfile,
    isTwoClientHousehold,
  ]);

  useLayoutEffect(() => {
    // If the AI DP feature is on, and we have identified recipient suggestions,
    // automatically select the UPON_FIRST_DEATH scheme for single-client households.
    if (
      aiDpEnabled &&
      !hasBeenReviewed &&
      dispositionScheme === DispositionScheme.NONE &&
      hasSuggestions &&
      !isTwoClientHousehold
    ) {
      onSchemeSelection(DispositionScheme.UPON_FIRST_DEATH);
    }
  }, [
    aiDpEnabled,
    hasBeenReviewed,
    dispositionScheme,
    onSchemeSelection,
    hasSuggestions,
    isTwoClientHousehold,
  ]);

  return (
    <Stack spacing={2}>
      <CompoundHeader
        heading="When do dispositions occur?"
        subheading={subheading}
      />
      <DispositionSchemeSelector onChange={onSchemeSelection} />
    </Stack>
  );
}
