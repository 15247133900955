import type { Edge, Node, NodeType } from '@/components/diagrams/FlowChart';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';

import { CltTermDiagram_EntityFragment } from '../graphql/CltTermDiagram.generated';
import { CrtTermDiagram_EntityFragment } from '../graphql/CrtTermDiagram.generated';
import { DispositionsDiagram_EntityFragment } from '../graphql/DispositionsDiagram.generated';
import { GratTermDiagram_EntityFragment } from '../graphql/GratTermDiagram.generated';
import { OwnershipDiagram_EntityFragment } from '../graphql/OwnershipDiagram.generated';
import { QprtTermDiagram_EntityFragment } from '../graphql/QprtTermDiagram.generated';
import { EntityDiagramGraph } from './entityDiagramGraph';

export enum EntityDiagramVariant {
  Dispositions = 'DISPOSITIONS', // Show the first and second order disposition recipients
  Ownership = 'OWNERSHIP', // Show the owners of the entity
  GratTerm = 'GRAT_TERM', // Show the GRAT grantor and remainder trust
  QprtTerm = 'QPRT_TERM', // Show the QPRT grantors and remainder trust
  CrtTerm = 'CRT_TERM', // Show the CRT grantors and remainder trust
  CltTerm = 'CLT_TERM', // Show the CLT grantors and remainder trust
}

export interface EntityDiagramStateProps {
  primaryClients: ContextPrimaryClient[];
  entity:
    | DispositionsDiagram_EntityFragment
    | OwnershipDiagram_EntityFragment
    | GratTermDiagram_EntityFragment
    | QprtTermDiagram_EntityFragment
    | CrtTermDiagram_EntityFragment
    | CltTermDiagram_EntityFragment;
  isTwoClientHousehold: boolean;
  /** presentationMode is used to disable interactivity with the graph */
  presentationMode: boolean;
  selectedPrimaryClientId: string;
  entityDiagramVariant: EntityDiagramVariant;
}

export interface EntityDiagramDerivedData {
  nodesByType: Partial<Record<NodeType, Node[]>>;
  nodesBySectionId: Record<string, Node[]>;
  nodesById: Record<string, Node>;
  edgesById: Record<string, Edge>;
}

export interface EntityDiagramState extends EntityDiagramStateProps {
  entityDiagramGraph: EntityDiagramGraph;
  isInitializing: boolean;
  nodes: Node[];
  edges: Edge[];
  highlightedIds: Set<string>;
  derivedData: EntityDiagramDerivedData;
  isExporting: boolean;
  opacity: number;
}
