import React, { HTMLAttributes } from 'react';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';

import { TileGroupItem } from './TileGroupItem';

const useStyles = makeStyles()((_theme) => ({
  root: {
    backgroundColor: COLORS.PRIMITIVES.WHITE,
    overflow: 'hidden',
    height: '100%',
    overflowY: 'auto',
    padding: '8px 0',
  },
  itemsCount: {
    '&:hover': {
      backgroundColor: COLORS.PRIMITIVES.WHITE,
    },
  },
}));

export interface TileGroupProps extends HTMLAttributes<HTMLDivElement> {
  classes?: WithClasses<typeof useStyles>;
}

export const TileGroup = ({
  classes: externalClasses,
  children,
  ...props
}: TileGroupProps): JSX.Element => {
  const { classes } = useStyles(undefined, {
    props: { classes: externalClasses },
  });

  const childrenCount = React.Children.count(children);

  return (
    <div className={classes?.root} {...props}>
      <TileGroupItem
        typographyProps={{ variant: 'h6' }}
        description={`Items (${childrenCount})`}
        classes={{ root: classes.itemsCount }}
      />
      {children}
    </div>
  );
};
