import Decimal from 'decimal.js';

import { TileSummary } from '@/components/diagrams/components/Tile/TileSummary';
import { MiniTable } from '@/components/tables/MiniTable/MiniTable';

import { useWaterfallSummaryContext } from '../../contexts/waterfallSummary.context';
import { GetWaterfallSummary_EstateWaterfallFragment } from '../../graphql/GetWaterfallSummary.generated';
import { useShowSummaryTileDetails } from '../../hooks/useShowSummaryTileDetails.hook';
import { WaterfallBarChartSection } from '../EstateWaterfallSummaryBarCharts/hooks/useWaterfallSummaryBarChartSections';
import { TileSection } from './components/TileSection';
import {
  formatCompactValue,
  getSummaryTileNode,
  isGrouped,
} from './EstateWaterfallSummaryTiles.utils';
import { useMiniTableRows } from './hooks/useMiniTableRows.hook';
import { useShowMoreSplit } from './hooks/useShowMoreSplit.hook';

export interface FirstDeathSummarySectionProps {
  waterfall: GetWaterfallSummary_EstateWaterfallFragment;
  section: WaterfallBarChartSection;
  showDetails?: boolean;
}

export const FirstDeathSummarySection = ({
  waterfall,
  section,
}: FirstDeathSummarySectionProps) => {
  const showSummaryTileDetails = useShowSummaryTileDetails(waterfall.id);
  const inEstate = section.waterfallSections[0];
  const grantorName = waterfall.household?.possiblePrimaryClients.find(
    (c) => c.id !== waterfall.firstGrantorDeath.id
  )?.firstName;
  const householdId = waterfall.household?.id as unknown as string;

  const firstDeathGrantorName =
    waterfall.household?.possiblePrimaryClients.find(
      (c) => c.id === waterfall.firstGrantorDeath.id
    )?.firstName;

  const splitData = useShowMoreSplit(inEstate.nodes);

  const {
    summaryPanel: { openModal, data },
  } = useWaterfallSummaryContext();

  const title = `Net to ${grantorName}`;

  const rows = useMiniTableRows(splitData);
  const inEstateDetails = <MiniTable rows={rows} variant="default" />;

  return (
    <TileSection label={section.label}>
      <TileSummary
        lineOne={title}
        lineTwo={formatCompactValue(inEstate.value)}
        onClick={() =>
          openModal({
            type: 'summaryTileDetails',
            nodes: inEstate.nodes.map(getSummaryTileNode),
            subtitle: `Combined value of assets owned by ${grantorName} and received from ${firstDeathGrantorName} after ${firstDeathGrantorName}'s death in ${waterfall.firstGrantorDeathYear}`,
            title,
            total: new Decimal(inEstate.value),
            firstPrimaryClientDeathId: waterfall.firstGrantorDeath.id,
            householdId,
            waterfallId: waterfall.id,
          })
        }
        isGrouped={isGrouped(title, data)}
      >
        {showSummaryTileDetails ? inEstateDetails : null}
      </TileSummary>
    </TileSection>
  );
};
