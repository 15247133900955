import { useCallback, useMemo, useState } from 'react';

import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';

export function useDiagramSlideState(pageIndex: number) {
  const slideHasDiagram = useMemo(() => {
    if (isFeatureFlagEnabled('entity_diagrams')) {
      return pageIndex === 0;
    }

    return false;
  }, [pageIndex]);

  // We need to wait for the diagram to be rendered completely before
  // scaling the container because the diagram does all its calculations
  // based on the unscaled container size.
  const [diagramReady, setDiagramReady] = useState(!slideHasDiagram);

  const registerDiagramReady = useCallback(() => {
    setDiagramReady(true);
  }, []);

  return { slideHasDiagram, diagramReady, registerDiagramReady };
}
