import {
  TILE_DEFAULT_FONT_SIZE_VARIANT,
  TILE_DEFAULT_VARIANT,
} from '@/components/diagrams/components/Tile';
import {
  TileBeforeAndAfter,
  TileBeforeAndAfterProps,
} from '@/components/diagrams/components/Tile/TileBeforeAndAfter';

import { useNode } from '../../hooks/useNode';
import { TileNode } from '../../types';

export function TileNodeBeforeAndAfter(
  props: Omit<TileBeforeAndAfterProps, 'fontSizeVariant' | 'variant'>
) {
  const node = useNode<TileNode>();

  return (
    <TileBeforeAndAfter
      {...props}
      fontSizeVariant={
        node.data.fontSizeVariant ?? TILE_DEFAULT_FONT_SIZE_VARIANT
      }
      variant={node.data.variant ?? TILE_DEFAULT_VARIANT}
    />
  );
}
