import { Stack, Typography, useTheme } from '@mui/material';
import Decimal from 'decimal.js';

import {
  THEMED_CANDY_CANE,
  useChartColorDefinitions,
} from '@/components/charts/constants';
import { StackedHorizontalBar } from '@/components/charts/StackedHorizontalBar/StackedHorizontalBar';
import { Callout } from '@/components/notifications/Callout/Callout';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import {
  getCumulativeGratPerformance,
  GratPerformance,
} from './getPerformanceForGrat';

export function GratPerformanceBar({ grats }: { grats: GratPerformance[] }) {
  const colors = useChartColorDefinitions();
  const theme = useTheme();

  const { gratValue, remainingPayments, impliedSurplus } =
    getCumulativeGratPerformance(grats ? grats : []);

  const isInShortfall = impliedSurplus.isNegative();
  return (
    <Stack gap={2}>
      <Stack>
        <Typography variant="subtitle2">GRAT value</Typography>
        <Typography variant="h1" component="p">
          {formatCurrencyNoDecimals(gratValue ?? new Decimal(0))}
        </Typography>
      </Stack>
      <StackedHorizontalBar
        sections={[
          {
            value: isInShortfall
              ? gratValue.toNumber()
              : (remainingPayments?.toNumber() ?? 0),
            color: colors.PRIMARY.backgroundColor,
            label: isInShortfall
              ? 'Current value'
              : 'Remaining annuity payments',
          },
          {
            value: impliedSurplus.abs().toNumber(),
            color: isInShortfall
              ? THEMED_CANDY_CANE(theme)
              : colors.SECONDARY.backgroundColor,
            backgroundCss: isInShortfall ? THEMED_CANDY_CANE(theme) : undefined,
            label: isInShortfall
              ? 'Annuity payment shortfall'
              : 'Implied surplus',
          },
        ]}
        variant="legend"
      />
      {isInShortfall && (
        <Callout severity="warning">
          This GRAT has{' '}
          <b>
            {formatCurrencyNoDecimals(remainingPayments)} in remaining annuity
            payments.
          </b>{' '}
          If the asset value does not recover the current deficit of{' '}
          {formatCurrencyNoDecimals(impliedSurplus.abs())} by the end of the
          GRAT term, the full value of the GRAT will be returned to the grantor.
        </Callout>
      )}
    </Stack>
  );
}
