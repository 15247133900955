import { Stack } from '@mui/material';
import { noop } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { File06Icon } from '@/components/icons/File06Icon';
import { FolderIcon } from '@/components/icons/FolderIcon';
import { LayersThree01Icon } from '@/components/icons/LayersThree01Icon';
import { Coachmark } from '@/components/poppers/Coachmark/Coachmark';
import { FlyoutMenuItem } from '@/components/poppers/MenuPopper/FlyoutMenuItem';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';
import { useAISuggestionsEnabled } from '@/modules/aiSuggestions/hooks/useAISuggestionsEnabled';
import { DocumentPrecedenceModal } from '@/modules/documents/components/HouseholdDocumentsViewer/DocumentPrecedence/DocumentPrecedenceModal';
import { RichDocumentUploader } from '@/modules/documents/RichDocumentUploader/RichDocumentUploader';
import { COLORS } from '@/styles/tokens/colors';
import { zIndexes } from '@/styles/zIndexes';
import { PulidKind } from '@/utils/pulid';

import { useHouseholdDocumentsViewerContext } from '../context/HouseholdDocumentsViewer.context';
import {
  DOCUMENT_CONTROLS_TOP_OFFSET,
  DOCUMENT_CONTROLS_X_OFFSET,
} from '../HouseholdDocumentsViewer.constants';
import { getAdditionalDetailsForPulidKind } from '../HouseholdDocumentsViewer.utils';
import { AddNewDocumentMenuItem } from './AddNewDocumentMenuItem';
import { DocumentSelectorMenuItem } from './DocumentSelectorMenuItem';

export function DocumentSelector() {
  const {
    targetDocuments,
    otherHouseholdDocuments,
    householdId,
    targetId,
    targetKind,
    entityKind,
    refetch,
    updateActiveDocumentId,
    setNewlyUploadedDocumentId,
    hideAddDocument,
  } = useHouseholdDocumentsViewerContext();

  const aiSuggestionsEnabled = useAISuggestionsEnabled(entityKind);

  const noDocumentsPresent = !targetDocuments?.length;
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [documentPrecedenceModalOpen, setDocumentPrecedenceModalOpen] =
    useState(false);
  const [coachmarkSeen, setCoachmarkSeen] = useLocalStorage<boolean>(
    LOCAL_STORAGE_KEYS.COACHMARK_DOCUMENT_PRECEDENCE
  );

  const showDocumentPrecedenceButton = useMemo(() => {
    const numSuggestionEnabledDocs = (targetDocuments || []).filter(
      (d) => d.enableAiSuggestions
    ).length;

    return aiSuggestionsEnabled && numSuggestionEnabledDocs > 1;
  }, [aiSuggestionsEnabled, targetDocuments]);

  const handleUploadModalClose = useCallback(
    (documentId: string | undefined) => {
      setUploadModalOpen(false);

      // documentId being present means a document was uploaded
      if (documentId) {
        refetch();
        updateActiveDocumentId({ documentId, shouldClearSearchResults: true });
        setNewlyUploadedDocumentId(documentId);
      }
    },
    [refetch, updateActiveDocumentId, setNewlyUploadedDocumentId]
  );

  const handleDocumentPrecedenceModalClose = useCallback(() => {
    setDocumentPrecedenceModalOpen(false);
  }, []);

  return (
    <>
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        sx={{
          position: 'absolute',
          top: DOCUMENT_CONTROLS_TOP_OFFSET,
          right: DOCUMENT_CONTROLS_X_OFFSET,
          zIndex: zIndexes.MENU,
        }}
      >
        {showDocumentPrecedenceButton && (
          <Coachmark
            id={LOCAL_STORAGE_KEYS.COACHMARK_DOCUMENT_PRECEDENCE}
            initialOpen={!coachmarkSeen}
            title="Edit document precedence"
            body="Re-order your documents to prioritize suggestions from one document over similar details found in another"
            buttonLabel="Got it"
            onClose={() => setCoachmarkSeen(true)}
          >
            <IconButton
              icon={LayersThree01Icon}
              ariaLabel="Document Precedence"
              size="sm"
              onClick={() => setDocumentPrecedenceModalOpen(true)}
            />
          </Coachmark>
        )}
        <ButtonWithPopover
          size="sm"
          variant="secondary-filled"
          popperVariant="menuBelow"
          label={<File06Icon size={20} sx={{ color: COLORS.GRAY[800] }} />}
        >
          {!hideAddDocument && (
            <AddNewDocumentMenuItem onClick={() => setUploadModalOpen(true)} />
          )}
          {otherHouseholdDocuments && otherHouseholdDocuments.length > 0 && (
            <FlyoutMenuItem
              label="Other client documents"
              icon={<FolderIcon size={18} sx={{ color: COLORS.GRAY[500] }} />}
              muiMenuItemProps={{
                divider: true,
              }}
            >
              {otherHouseholdDocuments.map((doc) => (
                <DocumentSelectorMenuItem
                  key={doc.id}
                  document={doc}
                  // hide the "default" button for documents that aren't
                  // already directly associated with the entity/te/cp
                  allowSetDefault={false}
                />
              ))}
            </FlyoutMenuItem>
          )}
          {targetDocuments?.map((doc) => (
            <DocumentSelectorMenuItem key={doc.id} document={doc} />
          ))}
          {noDocumentsPresent && (
            <MenuItem onClick={noop} label="No documents" disabled />
          )}
        </ButtonWithPopover>
      </Stack>
      {uploadModalOpen && (
        <RichDocumentUploader
          variant="default"
          documentId={null}
          // by the time we get to uploading a document, this must already
          // be defined
          householdId={householdId}
          entityId={targetKind === PulidKind.Entity ? targetId : undefined}
          isOpen={true}
          onClose={handleUploadModalClose}
          additionalDetails={getAdditionalDetailsForPulidKind(targetKind)}
        />
      )}
      {documentPrecedenceModalOpen && (
        <DocumentPrecedenceModal
          isOpen={true}
          onClose={handleDocumentPrecedenceModalClose}
          entityId={targetKind === PulidKind.Entity ? targetId : undefined}
        />
      )}
    </>
  );
}
