import { PopperContent } from '@/components/poppers/PopperContent';

export function TileNotes() {
  return (
    <PopperContent
      body={
        <span>
          Add notes to your tile to help provide additional context about this
          tile.
        </span>
      }
    />
  );
}
