import { useRequiredParam } from '@/hooks/useRequiredParam';
import { useLoggedTransferWhereInput } from '@/modules/beneficiaryReporting/hooks/useBeneficiariesData';
import {
  ClientPresentationDesignerV2QueryHookResult,
  useClientPresentationDesignerV2Query,
} from '@/modules/presentation/clientPresentation/ClientPresentationDesignerV2/graphql/ClientPresentationDesignerV2.generated';

export function usePrintOnlyClientPresentationV2Details(): ClientPresentationDesignerV2QueryHookResult {
  const presentationId = useRequiredParam('presentationId');
  const householdId = useRequiredParam('householdId');
  const loggedTransferWhereInput = useLoggedTransferWhereInput();
  return useClientPresentationDesignerV2Query({
    fetchPolicy: 'no-cache',
    variables: {
      presentationId,
      householdId,
      loggedTransferWhereInput,
      presentationOnly: false,
    },
  });
}
