import { Box, Stack, Typography } from '@mui/material';
import { PropsWithChildren, useState } from 'react';

import { TypeaheadMultiSelectInput } from '@/components/form/baseInputs/TypeaheadMultiSelectInput/TypeaheadMultiSelectInput';
import { FolderIcon } from '@/components/icons/FolderIcon';
import { COLORS } from '@/styles/tokens/colors';

import { LuminaryAIDocumentUploaderText } from '../components/LuminaryAIDocumentUploaderText';
import { useMultiDocumentUploaderContext } from './context/multiDocumentUploader.context';
import { useExistingHouseholdDocumentOptions } from './hooks/useExistingHouseholdDocumentOptions';

interface AIDocumentUploaderFrameProps extends PropsWithChildren {
  uploaderTextHeader: string;
  uploaderTextSubheader: string;
  /** If showFrame is false, the frame will not be shown and the children will be rendered */
  showFrame: boolean;
  /** If showExistingDocuments is false, the existing documents section will not be shown */
  showExistingDocuments: boolean;
}

const BORDER_RADIUS = 1.5;

export function AIDocumentUploaderFrame({
  uploaderTextHeader,
  uploaderTextSubheader,
  showFrame,
  showExistingDocuments,
  children,
}: AIDocumentUploaderFrameProps) {
  const { selectedExistingDocuments, setSelectedExistingDocuments } =
    useMultiDocumentUploaderContext();
  const [inputValue, setInputValue] = useState('');

  const { options, documentsHavePrimaryEntity, loading } =
    useExistingHouseholdDocumentOptions({
      skip: !showExistingDocuments,
    });
  const selectedExistingDocumentOptions = options.filter((option) =>
    selectedExistingDocuments.some(({ id }) => id === option.value)
  );

  if (!showFrame) {
    return <Box>{children}</Box>;
  }

  return (
    <Stack borderRadius={BORDER_RADIUS} bgcolor={COLORS.TEAL[50]} width="100%">
      <Stack p={3} gap={3}>
        <LuminaryAIDocumentUploaderText
          headerText={uploaderTextHeader}
          subheaderText={uploaderTextSubheader}
        />
        {children}
      </Stack>
      {showExistingDocuments && (
        <Stack
          sx={{
            backgroundColor: COLORS.GRAY[100],
            px: 1,
            py: 1,
            borderBottomRightRadius: BORDER_RADIUS,
            borderBottomLeftRadius: BORDER_RADIUS,
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <FolderIcon size={16} color={COLORS.GRAY[500]} />
            <Typography variant="subtitle2" color={COLORS.GRAY[500]}>
              Use previously uploaded files
            </Typography>
          </Stack>
          <Box sx={{ width: '60%' }}>
            <TypeaheadMultiSelectInput
              label="Select from existing files"
              hideLabel
              loading={loading}
              values={selectedExistingDocumentOptions}
              placeholder="Select documents..."
              maxTagDisplayLength={25}
              onChange={(_e, values) => {
                setSelectedExistingDocuments(
                  values.map((v) => ({
                    id: v.value,
                    markAsPrimary:
                      documentsHavePrimaryEntity[v.value]?.hasPrimaryEntity ??
                      false,
                  }))
                );
              }}
              options={options}
              inputValue={inputValue}
              onInputChange={setInputValue}
            />
          </Box>
        </Stack>
      )}
    </Stack>
  );
}
