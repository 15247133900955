import { Stack, useTheme } from '@mui/material';
import { useReactFlow } from '@xyflow/react';
import { FormProvider } from 'react-hook-form';

import { FormConfigurationProvider } from '@/components/form/context/FormConfigurationContext';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { useSubmitSuccessHandler } from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { GraphVizNodeConfigFormField } from '@/modules/graphViz/graphql/GraphVizNodeConfigFormFields';

import { useNode } from '../../hooks/useNode';
import { TileNode } from '../../types';

export interface TileEditModalFormShape {
  notes: string;
}

interface TileEditModalInnerProps {
  isOpen: boolean;
  onClose: () => void;
  node: TileNode;
}

function TileEditModalInner({
  isOpen,
  onClose,
  node,
}: TileEditModalInnerProps) {
  const theme = useTheme();
  const { updateNodeData } = useReactFlow();
  const { formRef, handleSave } = useFormSaveHandler();
  const { formState, handleSubmit, reset, control } =
    useFormContext<TileEditModalFormShape>();

  const onSubmit = handleSubmit((formData) => {
    const update: Partial<TileNode['data']> = { notes: formData.notes };
    updateNodeData(node.id, update);
    return Promise.resolve();
  });

  const onSuccess = () => {
    onClose();
    setTimeout(() => {
      reset();
    }, theme.transitions.duration.leavingScreen);
  };

  useSubmitSuccessHandler(onSuccess);

  return (
    <FormModal
      isOpen={isOpen}
      onClose={onClose}
      heading="Tile settings"
      rightHeaderContent={null}
      actions={
        <FormModalActions.Provider formState={formState}>
          <FormModalActions.CancelButton onClick={onClose} />
          <FormModalActions.SaveButton onClick={handleSave}>
            Save changes
          </FormModalActions.SaveButton>
        </FormModalActions.Provider>
      }
    >
      <form ref={formRef} onSubmit={onSubmit} noValidate>
        <Stack spacing={3}>
          <FormLayoutRow>
            <FormLayoutItem>
              <GraphVizNodeConfigFormField.Notes<TileEditModalFormShape>
                control={control}
                fieldName={
                  'notes' as const satisfies keyof TileEditModalFormShape
                }
              />
            </FormLayoutItem>
          </FormLayoutRow>
          <FormLayoutRow>
            <FormLayoutItem>
              <GraphVizNodeConfigFormField.SettingsDisclaimer />
            </FormLayoutItem>
          </FormLayoutRow>
        </Stack>
      </form>
    </FormModal>
  );
}

export type TileEditModalProps = Omit<TileEditModalInnerProps, 'node'>;
export function TileEditModal(props: TileEditModalProps) {
  const node = useNode<TileNode>();

  const formMethods = useForm<TileEditModalFormShape>({
    defaultValues: {
      notes: node.data.notes,
    },
  });

  return (
    <FormProvider {...formMethods}>
      <FormConfigurationProvider
        value={{ optionalDisplayType: 'optional-label' }}
      >
        <TileEditModalInner node={node} {...props} />
      </FormConfigurationProvider>
    </FormProvider>
  );
}
