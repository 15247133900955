import { Box } from '@mui/material';
import dynamic from 'next/dynamic';

import { Button } from '@/components/form/baseInputs/Button';
import { iconSizeByButtonSize } from '@/components/form/baseInputs/Button/styles';
import { Loader } from '@/components/progress/Loader/Loader';

export const DocumentDetailsPageContextMenuLazy = dynamic(
  () =>
    import('./DocumentDetailsPageContextMenu').then(
      (mod) => mod.DocumentDetailsPageContextMenu
    ),

  {
    ssr: false,
    loading: () => (
      <Box>
        <Button size="sm" variant="secondary" square>
          <Loader
            boxProps={{
              sx: {
                display: 'flex',
              },
            }}
            circularProgressProps={{
              size: iconSizeByButtonSize.sm,
            }}
          />
        </Button>
      </Box>
    ),
  }
);
