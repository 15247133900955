import { createSectionBoundsHandlers } from '@/components/diagrams/FlowChart/nodes/SectionLabelNode/hooks';

import { EntityDiagramState } from '../types';

export function applySectionBoundsToState(
  state: EntityDiagramState
): EntityDiagramState {
  const nodes = [...state.nodes];
  if (!nodes.length) {
    return { ...state, nodes };
  }

  const { initSectionBounds } = createSectionBoundsHandlers({
    changes: [],
    nextNodes: nodes,
    padding: 64,
  });

  initSectionBounds();

  return { ...state, nodes };
}
