import { Settings04Icon } from '@/components/icons/Settings04Icon';

import {
  TILE_NODE_CONTROL_BUTTON_ICON_SIZE,
  TileNodeControlButton,
  TileNodeControlButtonProps,
} from './TileNodeControlButton';

export const TileSettingsControlButton = (
  props: TileNodeControlButtonProps
) => {
  return (
    <TileNodeControlButton {...props}>
      <Settings04Icon size={TILE_NODE_CONTROL_BUTTON_ICON_SIZE} />
    </TileNodeControlButton>
  );
};
