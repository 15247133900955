import React, { useEffect } from 'react';

import { TenantPresentationConfiguration_TenantDisclaimerConfigurationFragment } from '@/modules/presentation/graphql/TenantPresentationConfiguration.generated';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';

import { useProposalYearProjectionOptionsLazyQuery } from '../proposal/Scenarios/graphql/ProposalYearProjectionOptions.generated';
import { Slide, SlideType } from './hooks/usePresentation';
import { SlideRenderer } from './PresentationPage';
import { StandaloneBeneficiaryTree } from './slides/StrategyOverview';

interface UseSlidesInput {
  slides: Slide[];
  proposal: ProposalFragment;
  legalDisclaimers:
    | TenantPresentationConfiguration_TenantDisclaimerConfigurationFragment
    | null
    | undefined;
}

function useSlides({ slides, proposal, legalDisclaimers }: UseSlidesInput) {
  const [allSlides, setAllSlides] = React.useState<JSX.Element[]>([]);
  const [queryYearOptions] = useProposalYearProjectionOptionsLazyQuery();

  useEffect(() => {
    const components: JSX.Element[] = [];

    slides.forEach(async (slide) => {
      if (!proposal) {
        return;
      }

      if (slide.type === SlideType.ScenarioDetail) {
        const entityProposal = proposal.entityProposals?.find(
          (entityProposal) => entityProposal.entity.id === slide.params.entity
        );

        // get year options for this entity
        const { data } = await queryYearOptions({
          variables: {
            entityId: entityProposal?.entity.id ?? '',
          },
        });

        const yearOptions = data?.proposalYearProjectionOptionsV2 ?? [];

        // Render each scenario detail slide for each year option
        yearOptions.forEach((_, idx) => {
          components.push(
            <SlideRenderer
              proposal={proposal}
              currentSlide={slide}
              timeHorizonIdx={idx}
              legalDisclaimers={legalDisclaimers}
            />
          );
        });

        return;
      }

      if (slide.type === SlideType.StrategyOverview) {
        // split the strategy overview slide into two slides, beneficiary tree and strategy overview
        components.push(
          <StandaloneBeneficiaryTree proposal={proposal} currentSlide={slide} />
        );
      }

      if (slide.type === SlideType.ImpactComparison) {
        // split the impact comparison slide into two slides, pre-tax and post-tax

        // pre-tax time period 1, post-tax time period 1
        // pre-tax time period 2, post-tax time period 2
        // ...

        const entityProposal = proposal.entityProposals?.find(
          (entityProposal) => entityProposal.entity.id === slide.params.entity
        );

        // get year options for this entity
        const { data } = await queryYearOptions({
          variables: {
            entityId: entityProposal?.entity.id ?? '',
          },
        });

        const yearOptions = data?.proposalYearProjectionOptionsV2 ?? [];

        yearOptions.forEach((_, idx) => {
          components.push(
            <SlideRenderer
              proposal={proposal}
              currentSlide={slide}
              showPostTax={false}
              timeHorizonIdx={idx}
              legalDisclaimers={legalDisclaimers}
            />
          );
          components.push(
            <SlideRenderer
              proposal={proposal}
              currentSlide={slide}
              showPostTax={true}
              timeHorizonIdx={idx}
              legalDisclaimers={legalDisclaimers}
            />
          );
        });

        return;
      }

      components.push(
        <SlideRenderer
          proposal={proposal}
          currentSlide={slide}
          legalDisclaimers={legalDisclaimers}
        />
      );

      setAllSlides(components);
    });
  }, [legalDisclaimers, proposal, queryYearOptions, slides]);

  return allSlides;
}

interface PrintSlidesProps {
  slides: Slide[];
  proposal: ProposalFragment;
  legalDisclaimers:
    | TenantPresentationConfiguration_TenantDisclaimerConfigurationFragment
    | null
    | undefined;
}

export function PrintSlides({
  slides,
  proposal,
  legalDisclaimers,
}: PrintSlidesProps) {
  const components = useSlides({ slides, proposal, legalDisclaimers });

  if (!components || components.length === 0) {
    return null;
  }

  return (
    <>
      <style type="text/css" media="print">
        {
          // print-color-adjust indicates to the printing mechanism that the printer
          // should faithfully include background colors and images, rather than excluding
          // them to quaintly save ink
          '@page {size: landscape; margin: 0 0 0 0; print-color-adjust: exact;}'
        }
        {'@media print {body {width: 100%; height: 100%; }}'}
      </style>
      {components}
    </>
  );
}
