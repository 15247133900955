import { noop } from 'lodash';
import { createContext } from 'react';
import { FullScreenHandle } from 'react-full-screen';

import { useGuardedContext } from '@/hooks/useGuardedContext';

export type FullscreenName = 'waterfallDiagram' | 'entityDiagram';

export interface FullscreenContext {
  isFullscreen: boolean;
  handles?: Partial<Record<FullscreenName, FullScreenHandle>>;
  registerFullscreenInstance: (
    name: FullscreenName,
    handle: FullScreenHandle
  ) => void;
}

export const FullscreenContext = createContext<FullscreenContext>({
  isFullscreen: false,
  handles: {},
  registerFullscreenInstance: noop,
});

export const useFullscreenContext = () => {
  return useGuardedContext(FullscreenContext, 'FullscreenProvider');
};
