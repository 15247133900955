import { IntegrationEntitiesTypeahead_IntegrationEntityFragment } from './graphql/IntegrationEntitiesTypeahead.generated';
import { INTEGRATION_ENTITY_TYPEAHEAD_SORT_ORDER } from './IntegrationEntitiesTypeahead.constants';

// Define externalKind type based on the constant ordering array
export type IntegrationEntityExternalKind =
  (typeof INTEGRATION_ENTITY_TYPEAHEAD_SORT_ORDER)[number];

export function sortIntegrationEntities(
  entities: IntegrationEntitiesTypeahead_IntegrationEntityFragment[]
): IntegrationEntitiesTypeahead_IntegrationEntityFragment[] {
  return entities.sort((a, b) => {
    const aClientName = a.integrationClient?.name ?? '';
    const bClientName = b.integrationClient?.name ?? '';
    if (aClientName !== bClientName) {
      return aClientName.localeCompare(bClientName);
    }

    const aIndex = a.externalKind
      ? INTEGRATION_ENTITY_TYPEAHEAD_SORT_ORDER.indexOf(a.externalKind)
      : -1;
    const bIndex = b.externalKind
      ? INTEGRATION_ENTITY_TYPEAHEAD_SORT_ORDER.indexOf(b.externalKind)
      : -1;

    // If they have different externalKinds, sort by the order
    if (aIndex !== bIndex) {
      // If only one has a defined order, put the defined one first
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      // Otherwise sort by the order
      return aIndex - bIndex;
    }

    // If they have the same externalKind (or both have none), sort alphabetically by name
    return a.name.localeCompare(b.name);
  });
}
