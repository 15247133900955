import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { getNodes } from '@/utils/graphqlUtils';

import { useGrowthProfileOptionsQuery } from '../graphql/SaleLoanIllustration.generated';

export function useGrowthProfileOptions(householdId: string) {
  const queryProps = useGrowthProfileOptionsQuery({
    variables: {
      householdId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const household = getNodes(queryProps.data?.households)[0];
  if (!household) {
    return {
      options: [],
      ...queryProps,
    };
  }

  const options: SelectInputOption<string>[] = getNodes(
    household.growthProfiles
  ).map(({ id, displayName }) => ({
    display: displayName,
    value: id,
  }));

  return {
    options,
    ...queryProps,
  };
}
