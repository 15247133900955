import { isString } from 'lodash';
import { useMemo, useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';

import { isTileNode } from '@/components/diagrams/FlowChart/utils/nodes';
import { DropdownButton } from '@/components/form/baseInputs/DropdownButton/DropdownButton';
import { RadioGroupOption } from '@/components/form/baseInputs/inputTypes';
import {
  CheckboxListWrapper,
  FormAwareCheckboxListItem,
} from '@/components/form/formAwareInputs/FormAwareCheckboxList/FormAwareCheckboxList';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { ModalFormLayout } from '@/components/form/layout/ModalFormLayout';
import { DotsHorizontalIcon } from '@/components/icons/DotsHorizontalIcon';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import {
  ButtonTab,
  commonTabStyle,
  Tabs,
} from '@/components/navigation/NavigationTabs';
import { useFormContext } from '@/components/react-hook-form';
import {
  EntityGraphViewKind,
  EntityGraphViewOrientation,
} from '@/types/schema';
import { createOptionsFromEnum } from '@/utils/createOptionsFromEnum';

import { GraphVizViewFormData } from './types';

const fieldArrayName = 'nodeConfigurations' as const;

interface Tab<V> {
  display: string;
  value: V;
}

type NodeIdsByTabMap<TabValue extends string> = Record<TabValue, Set<string>>;

type Field = GraphVizViewFormData['nodeConfigurations'][number];

const orientationOptions: RadioGroupOption<EntityGraphViewOrientation>[] = [
  { label: 'Vertical', value: EntityGraphViewOrientation.TopBottom },
  { label: 'Horizontal', value: EntityGraphViewOrientation.LeftRight },
];

export interface GraphVizViewFormProps<TabValue extends string> {
  getTabForField: (field: Field) => TabValue;
  tabs: Tab<TabValue>[];
}

export function GraphVizViewForm<TabValue extends string>({
  getTabForField,
  tabs,
}: GraphVizViewFormProps<TabValue>) {
  const [currentTab, setCurrentTab] = useState<TabValue>(tabs[0]!.value);

  const { control, setValue, getValues } = useFormContext();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any -- TODO (LUM-1552): Causes infinite type recursion with latest TS, need to upgrade to RHF v8
  const { fields } = (useFieldArray as any)({
    control,
    name: fieldArrayName,
  }) as { fields: Field[] };

  // TODO (LUM-1552): Causes infinite type recursion with latest TS, need to upgrade to RHF v8
  const watchedNodes = useWatch({
    name: fieldArrayName,
  }) as Field[];

  const selectedNodeIds = useMemo(() => {
    return watchedNodes.flatMap((n) => (n.visible ? n.nodeID : []));
  }, [watchedNodes]);

  const kindOptions = useMemo(() => {
    return createOptionsFromEnum(EntityGraphViewKind);
  }, []);

  const nodesIdsByTab = fields.reduce<NodeIdsByTabMap<TabValue>>(
    (acc, field) => {
      const tabValue = getTabForField(field);
      acc[tabValue] = acc[tabValue] || new Set();
      acc[tabValue].add(field.node.id);
      return acc;
    },
    {} as NodeIdsByTabMap<TabValue>
  );

  const currentTabNodeIds = nodesIdsByTab[currentTab] ?? new Set();

  const makeSelect = (visible: boolean) => () => {
    watchedNodes.forEach((field, index) => {
      if (!currentTabNodeIds.has(field.node.id)) return;
      setValue(`${fieldArrayName}.${index}.visible`, visible, {
        shouldDirty: true,
      });
    });
  };

  const selectAll = makeSelect(true);
  const deselectAll = makeSelect(false);
  const currentTabSelection = tabs.find((tab) => tab.value === currentTab)!;

  return (
    <ModalFormLayout>
      <FormLayoutRow>
        <FormLayoutItem>
          <Card variant="filled" sx={{ p: 2 }}>
            <FormLayoutRow>
              <FormLayoutItem>
                <FormAwareTextInput
                  control={control}
                  required
                  label="Name of map"
                  // TODO (LUM-1552): Typed as string, fix to be more specific
                  fieldName="displayName"
                />
              </FormLayoutItem>
            </FormLayoutRow>
            <FormLayoutRow>
              <FormLayoutItem>
                <FormAwareSelectInput
                  control={control}
                  fieldName="kind"
                  required
                  label="View by"
                  disabled={!!getValues('id')}
                  helpText="Create a new map to change view by type"
                  options={kindOptions}
                />
              </FormLayoutItem>
            </FormLayoutRow>
            <FormLayoutRow>
              <FormLayoutItem>
                <FormAwareRadioGroup
                  control={control}
                  fieldName="orientation"
                  required
                  label="Map orientation"
                  options={orientationOptions}
                  row
                />
              </FormLayoutItem>
            </FormLayoutRow>
          </Card>
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <Tabs>
            {tabs.map((tab) => {
              const selectedCount = selectedNodeIds.reduce((count, nodeId) => {
                return nodesIdsByTab[tab.value]?.has(nodeId)
                  ? count + 1
                  : count;
              }, 0);

              return (
                <ButtonTab
                  key={tab.value}
                  display={tab.display}
                  isActive={currentTab === tab.value}
                  onClick={() => setCurrentTab(tab.value)}
                  badge={`${selectedCount}`}
                  sx={{ whiteSpace: 'initial' }}
                />
              );
            })}
            <DropdownButton
              style={commonTabStyle}
              showArrow={false}
              name="Dropdown-AdditionalContent"
              variant="transparent"
              size="xs"
              buttonContent={<DotsHorizontalIcon />}
              items={[
                {
                  name: `Select all ${currentTabSelection.display}`,
                  clickHandler: selectAll,
                },
                {
                  name: `Deselect all ${currentTabSelection.display}`,
                  clickHandler: deselectAll,
                },
              ]}
            />
          </Tabs>
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <CheckboxListWrapper>
            {fields.map((field, i) => {
              const isFieldInTab = currentTabNodeIds.has(field.node.id);
              // we don't have a way to render non tile nodes at this moment, and this whole UI
              // is planned to be replaced with a better filtering experience
              if (!isTileNode(field.node)) return null;
              return (
                <FormAwareCheckboxListItem
                  key={field.node.id}
                  fieldName={`${fieldArrayName}.${i}.visible` as const}
                  title={field.node.data.lineOne}
                  lineTwo={
                    isString(field.node.data.lineTwo)
                      ? field.node.data.lineTwo
                      : undefined
                  }
                  control={control}
                  sx={{ display: isFieldInTab ? undefined : 'none' }}
                />
              );
            })}
          </CheckboxListWrapper>
        </FormLayoutItem>
      </FormLayoutRow>
    </ModalFormLayout>
  );
}
