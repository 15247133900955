import { SelectChangeEvent, Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useLayoutEffect } from 'react';

import { Divider } from '@/components/Divider';
import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { SelectInput } from '@/components/form/baseInputs/SelectInput';
import { LoadingAdornment } from '@/components/form/baseInputs/SelectInput/LoadingAdornment';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { DOCUMENT_TYPE_OPTIONS } from '@/modules/documents/documents.constants';
import { useMultiDocumentUploaderContext } from '@/modules/documents/MultiDocumentUploader/context/multiDocumentUploader.context';
import { FileRepresentation } from '@/modules/files/FileRepresentation/FileRepresentation';
import { FileUploader } from '@/modules/files/FileUploader/FileUploader';
import { CreateFileMutation } from '@/modules/files/FileUploader/graphql/CreateFile.generated';
import { useGenerateSignedFileUploadURL } from '@/modules/files/FileUploader/useGenerateSignedFileUploadURL';
import { COLORS } from '@/styles/tokens/colors';
import { DocumentType } from '@/types/schema';

import { AIDocumentUploaderFrame } from './AIDocumentUploaderFrame';

// We use this element id to scroll the uploaded documents list into view after upload.
const UPLOADED_FILES_SCROLL_TARGET = 'uploaded-files-scroll-target';

interface MultiDocumentUploaderWithListProps {
  householdId: string;
  uploaderTextHeader: string;
  uploaderTextSubheader: string;
  hideAIUploaderFrame?: boolean;
  /**
   * allowSelectExistingDocuments is only applicable when the AI uploader frame is shown.
   * Allows the user to select existing documents from the household.
   * Defaults to false.
   */
  allowSelectExistingDocuments?: boolean;
}

export function MultiDocumentUploaderWithList({
  householdId,
  uploaderTextHeader,
  uploaderTextSubheader,
  hideAIUploaderFrame = false,
  allowSelectExistingDocuments = false,
}: MultiDocumentUploaderWithListProps) {
  const { generateUploadURL } = useGenerateSignedFileUploadURL(householdId);

  const {
    uploadedFiles,
    handleUploadFilesStarted,
    handleUploadFilesComplete,
    handleDeleteFile,
    setDocumentTypeOfUploadedFile,
    fileIDTypeMap,
    uploaderErrorMessage,
    inferringDocumentTypes,
  } = useMultiDocumentUploaderContext();

  useLayoutEffect(() => {
    if (isEmpty(uploadedFiles)) {
      return;
    }
    document.getElementById(UPLOADED_FILES_SCROLL_TARGET)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [uploadedFiles]);

  return (
    <Stack gap={3}>
      <AIDocumentUploaderFrame
        uploaderTextHeader={uploaderTextHeader}
        uploaderTextSubheader={uploaderTextSubheader}
        showFrame={!hideAIUploaderFrame}
        showExistingDocuments={allowSelectExistingDocuments}
      >
        <FileUploader<CreateFileMutation>
          generateSignedUploadURL={generateUploadURL}
          onUploadStarted={handleUploadFilesStarted}
          onUploadComplete={handleUploadFilesComplete}
          errorMessage={uploaderErrorMessage}
          allowMultiple
        />
      </AIDocumentUploaderFrame>
      <Stack id={UPLOADED_FILES_SCROLL_TARGET}>
        {!isEmpty(uploadedFiles) && (
          <Stack width="100%" p={1.5} bgcolor={COLORS.GRAY[100]}>
            <Typography variant="h6" color={COLORS.GRAY[500]}>
              Uploaded files
            </Typography>
          </Stack>
        )}
        <Stack spacing={3} divider={<Divider noMargin />}>
          {uploadedFiles.map((uploadedFile, index) => {
            const { fileId, fileName, uploadedByName, uploadedAt } =
              uploadedFile;

            return (
              <FileRepresentation
                key={fileId}
                sx={{
                  border: 'none',
                  gap: 2,
                  breakWord: 'break-all',
                }}
                fileName={fileName}
                fileNameOverflow="ellipsis"
                uploadedAt={uploadedAt}
                uploadedAtFormat="date-only"
                uploadedBy={uploadedByName}
                tooltipOnHover
                hideIcon
                actions={[
                  <SelectInput<DocumentType | ''>
                    key="select"
                    required
                    sx={{ width: 160 }}
                    name={`document-type-${index}`}
                    label="Specify type"
                    hideLabel
                    value={fileIDTypeMap?.[fileId] || ''}
                    options={DOCUMENT_TYPE_OPTIONS}
                    onChange={(e: SelectChangeEvent<DocumentType | ''>) =>
                      setDocumentTypeOfUploadedFile(
                        fileId,
                        e.target.value as DocumentType
                      )
                    }
                    startAdornment={
                      inferringDocumentTypes && <LoadingAdornment />
                    }
                  />,
                  <IconButton
                    key="delete"
                    icon={Trash01Icon}
                    ariaLabel="delete"
                    size="sm"
                    variant="destructive-transparent"
                    onClick={() => handleDeleteFile(fileId)}
                  />,
                ]}
              />
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
}
