import {
  ControlButton,
  ControlButtonProps,
} from '../../../components/Controls/ControlButton';

export const TILE_NODE_CONTROL_BUTTON_ICON_SIZE = 16;

export type TileNodeControlButtonProps = ControlButtonProps;

export const TileNodeControlButton = (props: TileNodeControlButtonProps) => {
  return <ControlButton size="xs" variant="secondary-filled" {...props} />;
};
