// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DispositionsDiagram_GetTaxStatusForEntityFragment = (
  { id: string, subtype: (
    { id: string }
    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
  ) | (
    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null }
    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'ILITTrust' | 'IndividualPersonalAccount' | 'InsurancePersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
  ) }
  & { __typename?: 'Entity' }
);

export type DispositionsDiagram_ClientProfileMinimalGrantorRelationshipFragment = (
  { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
    { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
    & { __typename?: 'ClientProfile' }
  ), toClientProfile: (
    { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
    & { __typename?: 'ClientProfile' }
  ) }
  & { __typename?: 'ClientProfileRelationship' }
);

export type DispositionsDiagram_DispositiveProvisionTemplateFirstDeathFragment = (
  { id: string, displayName: string, dispositiveProvisions: (
    { totalCount: number, edges?: Array<(
      { node?: (
        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
          { id: string }
          & { __typename?: 'EstateWaterfall' }
        ) | null, entity?: (
          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
            { id: string, displayName: string, dispositionScenarios?: Array<(
              { id: string, firstGrantorDeath: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ), dispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, secondDeathDispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ), secondDeathDispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositionScenario' }
            )> | null }
            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
          ) | (
            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
              { id: string, firstGrantorDeath: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ), dispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, secondDeathDispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ), secondDeathDispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositionScenario' }
            )> | null }
            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
          ) | (
            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                { id: string }
                & { __typename?: 'ClientProfile' }
              )> | null }
              & { __typename?: 'InsurancePolicy' }
            )> | null, dispositionScenarios?: Array<(
              { id: string, firstGrantorDeath: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ), dispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, secondDeathDispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ), secondDeathDispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositionScenario' }
            )> | null }
            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
          ) }
          & { __typename?: 'Entity' }
        ) | null, individual?: (
          { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
            { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
              { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
              & { __typename?: 'ClientProfile' }
            ), toClientProfile: (
              { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
              & { __typename?: 'ClientProfile' }
            ) }
            & { __typename?: 'ClientProfileRelationship' }
          )> | null }
          & { __typename?: 'ClientProfile' }
        ) | null, organization?: (
          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
          & { __typename?: 'ClientOrganization' }
        ) | null, testamentaryEntity?: (
          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
            { id: string }
            & { __typename?: 'ClientProfile' }
          ) | null, dispositionScenarios?: Array<(
            { id: string, firstGrantorDeath: (
              { id: string }
              & { __typename?: 'ClientProfile' }
            ), dispositiveProvisionsTemplate?: (
              { id: string, displayName: string, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositiveProvisionTemplate' }
            ) | null, secondDeathDispositiveProvisionsTemplate?: (
              { id: string, displayName: string, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositiveProvisionTemplate' }
            ) | null, dispositiveProvisions: (
              { totalCount: number, edges?: Array<(
                { node?: (
                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                    { id: string }
                    & { __typename?: 'EstateWaterfall' }
                  ) | null, entity?: (
                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                      { id: string, displayName: string }
                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        )> | null }
                        & { __typename?: 'InsurancePolicy' }
                      )> | null }
                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                    ) }
                    & { __typename?: 'Entity' }
                  ) | null, individual?: (
                    { id: string, displayName: string }
                    & { __typename?: 'ClientProfile' }
                  ) | null, organization?: (
                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                    & { __typename?: 'ClientOrganization' }
                  ) | null, testamentaryEntity?: (
                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null }
                    & { __typename?: 'TestamentaryEntity' }
                  ) | null }
                  & { __typename?: 'DispositiveProvision' }
                ) | null }
                & { __typename?: 'DispositiveProvisionEdge' }
              ) | null> | null }
              & { __typename?: 'DispositiveProvisionConnection' }
            ), secondDeathDispositiveProvisions: (
              { totalCount: number, edges?: Array<(
                { node?: (
                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                    { id: string }
                    & { __typename?: 'EstateWaterfall' }
                  ) | null, entity?: (
                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                      { id: string, displayName: string }
                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        )> | null }
                        & { __typename?: 'InsurancePolicy' }
                      )> | null }
                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                    ) }
                    & { __typename?: 'Entity' }
                  ) | null, individual?: (
                    { id: string, displayName: string }
                    & { __typename?: 'ClientProfile' }
                  ) | null, organization?: (
                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                    & { __typename?: 'ClientOrganization' }
                  ) | null, testamentaryEntity?: (
                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null }
                    & { __typename?: 'TestamentaryEntity' }
                  ) | null }
                  & { __typename?: 'DispositiveProvision' }
                ) | null }
                & { __typename?: 'DispositiveProvisionEdge' }
              ) | null> | null }
              & { __typename?: 'DispositiveProvisionConnection' }
            ) }
            & { __typename?: 'DispositionScenario' }
          )> | null }
          & { __typename?: 'TestamentaryEntity' }
        ) | null }
        & { __typename?: 'DispositiveProvision' }
      ) | null }
      & { __typename?: 'DispositiveProvisionEdge' }
    ) | null> | null }
    & { __typename?: 'DispositiveProvisionConnection' }
  ) }
  & { __typename?: 'DispositiveProvisionTemplate' }
);

export type DispositionsDiagram_DispositiveProvisionTemplateSecondDeathFragment = (
  { id: string, displayName: string, dispositiveProvisions: (
    { totalCount: number, edges?: Array<(
      { node?: (
        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
          { id: string }
          & { __typename?: 'EstateWaterfall' }
        ) | null, entity?: (
          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
            { id: string, displayName: string }
            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
          ) | (
            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
          ) | (
            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                { id: string }
                & { __typename?: 'ClientProfile' }
              )> | null }
              & { __typename?: 'InsurancePolicy' }
            )> | null }
            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
          ) }
          & { __typename?: 'Entity' }
        ) | null, individual?: (
          { id: string, displayName: string }
          & { __typename?: 'ClientProfile' }
        ) | null, organization?: (
          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
          & { __typename?: 'ClientOrganization' }
        ) | null, testamentaryEntity?: (
          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
            { id: string }
            & { __typename?: 'ClientProfile' }
          ) | null }
          & { __typename?: 'TestamentaryEntity' }
        ) | null }
        & { __typename?: 'DispositiveProvision' }
      ) | null }
      & { __typename?: 'DispositiveProvisionEdge' }
    ) | null> | null }
    & { __typename?: 'DispositiveProvisionConnection' }
  ) }
  & { __typename?: 'DispositiveProvisionTemplate' }
);

export type DispositionsDiagram_DispositionProvisionFirstDeathFragment = (
  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
    { id: string }
    & { __typename?: 'EstateWaterfall' }
  ) | null, entity?: (
    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
      { id: string, displayName: string, dispositionScenarios?: Array<(
        { id: string, firstGrantorDeath: (
          { id: string }
          & { __typename?: 'ClientProfile' }
        ), dispositiveProvisionsTemplate?: (
          { id: string, displayName: string, dispositiveProvisions: (
            { totalCount: number, edges?: Array<(
              { node?: (
                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                  { id: string }
                  & { __typename?: 'EstateWaterfall' }
                ) | null, entity?: (
                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                    { id: string, displayName: string }
                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                  ) | (
                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                  ) | (
                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      )> | null }
                      & { __typename?: 'InsurancePolicy' }
                    )> | null }
                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                  ) }
                  & { __typename?: 'Entity' }
                ) | null, individual?: (
                  { id: string, displayName: string }
                  & { __typename?: 'ClientProfile' }
                ) | null, organization?: (
                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                  & { __typename?: 'ClientOrganization' }
                ) | null, testamentaryEntity?: (
                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ) | null }
                  & { __typename?: 'TestamentaryEntity' }
                ) | null }
                & { __typename?: 'DispositiveProvision' }
              ) | null }
              & { __typename?: 'DispositiveProvisionEdge' }
            ) | null> | null }
            & { __typename?: 'DispositiveProvisionConnection' }
          ) }
          & { __typename?: 'DispositiveProvisionTemplate' }
        ) | null, secondDeathDispositiveProvisionsTemplate?: (
          { id: string, displayName: string, dispositiveProvisions: (
            { totalCount: number, edges?: Array<(
              { node?: (
                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                  { id: string }
                  & { __typename?: 'EstateWaterfall' }
                ) | null, entity?: (
                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                    { id: string, displayName: string }
                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                  ) | (
                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                  ) | (
                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      )> | null }
                      & { __typename?: 'InsurancePolicy' }
                    )> | null }
                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                  ) }
                  & { __typename?: 'Entity' }
                ) | null, individual?: (
                  { id: string, displayName: string }
                  & { __typename?: 'ClientProfile' }
                ) | null, organization?: (
                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                  & { __typename?: 'ClientOrganization' }
                ) | null, testamentaryEntity?: (
                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ) | null }
                  & { __typename?: 'TestamentaryEntity' }
                ) | null }
                & { __typename?: 'DispositiveProvision' }
              ) | null }
              & { __typename?: 'DispositiveProvisionEdge' }
            ) | null> | null }
            & { __typename?: 'DispositiveProvisionConnection' }
          ) }
          & { __typename?: 'DispositiveProvisionTemplate' }
        ) | null, dispositiveProvisions: (
          { totalCount: number, edges?: Array<(
            { node?: (
              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                { id: string }
                & { __typename?: 'EstateWaterfall' }
              ) | null, entity?: (
                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                  { id: string, displayName: string }
                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    )> | null }
                    & { __typename?: 'InsurancePolicy' }
                  )> | null }
                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                ) }
                & { __typename?: 'Entity' }
              ) | null, individual?: (
                { id: string, displayName: string }
                & { __typename?: 'ClientProfile' }
              ) | null, organization?: (
                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                & { __typename?: 'ClientOrganization' }
              ) | null, testamentaryEntity?: (
                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ) | null }
                & { __typename?: 'TestamentaryEntity' }
              ) | null }
              & { __typename?: 'DispositiveProvision' }
            ) | null }
            & { __typename?: 'DispositiveProvisionEdge' }
          ) | null> | null }
          & { __typename?: 'DispositiveProvisionConnection' }
        ), secondDeathDispositiveProvisions: (
          { totalCount: number, edges?: Array<(
            { node?: (
              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                { id: string }
                & { __typename?: 'EstateWaterfall' }
              ) | null, entity?: (
                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                  { id: string, displayName: string }
                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    )> | null }
                    & { __typename?: 'InsurancePolicy' }
                  )> | null }
                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                ) }
                & { __typename?: 'Entity' }
              ) | null, individual?: (
                { id: string, displayName: string }
                & { __typename?: 'ClientProfile' }
              ) | null, organization?: (
                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                & { __typename?: 'ClientOrganization' }
              ) | null, testamentaryEntity?: (
                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ) | null }
                & { __typename?: 'TestamentaryEntity' }
              ) | null }
              & { __typename?: 'DispositiveProvision' }
            ) | null }
            & { __typename?: 'DispositiveProvisionEdge' }
          ) | null> | null }
          & { __typename?: 'DispositiveProvisionConnection' }
        ) }
        & { __typename?: 'DispositionScenario' }
      )> | null }
      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
    ) | (
      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
        { id: string, firstGrantorDeath: (
          { id: string }
          & { __typename?: 'ClientProfile' }
        ), dispositiveProvisionsTemplate?: (
          { id: string, displayName: string, dispositiveProvisions: (
            { totalCount: number, edges?: Array<(
              { node?: (
                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                  { id: string }
                  & { __typename?: 'EstateWaterfall' }
                ) | null, entity?: (
                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                    { id: string, displayName: string }
                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                  ) | (
                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                  ) | (
                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      )> | null }
                      & { __typename?: 'InsurancePolicy' }
                    )> | null }
                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                  ) }
                  & { __typename?: 'Entity' }
                ) | null, individual?: (
                  { id: string, displayName: string }
                  & { __typename?: 'ClientProfile' }
                ) | null, organization?: (
                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                  & { __typename?: 'ClientOrganization' }
                ) | null, testamentaryEntity?: (
                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ) | null }
                  & { __typename?: 'TestamentaryEntity' }
                ) | null }
                & { __typename?: 'DispositiveProvision' }
              ) | null }
              & { __typename?: 'DispositiveProvisionEdge' }
            ) | null> | null }
            & { __typename?: 'DispositiveProvisionConnection' }
          ) }
          & { __typename?: 'DispositiveProvisionTemplate' }
        ) | null, secondDeathDispositiveProvisionsTemplate?: (
          { id: string, displayName: string, dispositiveProvisions: (
            { totalCount: number, edges?: Array<(
              { node?: (
                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                  { id: string }
                  & { __typename?: 'EstateWaterfall' }
                ) | null, entity?: (
                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                    { id: string, displayName: string }
                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                  ) | (
                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                  ) | (
                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      )> | null }
                      & { __typename?: 'InsurancePolicy' }
                    )> | null }
                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                  ) }
                  & { __typename?: 'Entity' }
                ) | null, individual?: (
                  { id: string, displayName: string }
                  & { __typename?: 'ClientProfile' }
                ) | null, organization?: (
                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                  & { __typename?: 'ClientOrganization' }
                ) | null, testamentaryEntity?: (
                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ) | null }
                  & { __typename?: 'TestamentaryEntity' }
                ) | null }
                & { __typename?: 'DispositiveProvision' }
              ) | null }
              & { __typename?: 'DispositiveProvisionEdge' }
            ) | null> | null }
            & { __typename?: 'DispositiveProvisionConnection' }
          ) }
          & { __typename?: 'DispositiveProvisionTemplate' }
        ) | null, dispositiveProvisions: (
          { totalCount: number, edges?: Array<(
            { node?: (
              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                { id: string }
                & { __typename?: 'EstateWaterfall' }
              ) | null, entity?: (
                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                  { id: string, displayName: string }
                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    )> | null }
                    & { __typename?: 'InsurancePolicy' }
                  )> | null }
                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                ) }
                & { __typename?: 'Entity' }
              ) | null, individual?: (
                { id: string, displayName: string }
                & { __typename?: 'ClientProfile' }
              ) | null, organization?: (
                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                & { __typename?: 'ClientOrganization' }
              ) | null, testamentaryEntity?: (
                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ) | null }
                & { __typename?: 'TestamentaryEntity' }
              ) | null }
              & { __typename?: 'DispositiveProvision' }
            ) | null }
            & { __typename?: 'DispositiveProvisionEdge' }
          ) | null> | null }
          & { __typename?: 'DispositiveProvisionConnection' }
        ), secondDeathDispositiveProvisions: (
          { totalCount: number, edges?: Array<(
            { node?: (
              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                { id: string }
                & { __typename?: 'EstateWaterfall' }
              ) | null, entity?: (
                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                  { id: string, displayName: string }
                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    )> | null }
                    & { __typename?: 'InsurancePolicy' }
                  )> | null }
                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                ) }
                & { __typename?: 'Entity' }
              ) | null, individual?: (
                { id: string, displayName: string }
                & { __typename?: 'ClientProfile' }
              ) | null, organization?: (
                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                & { __typename?: 'ClientOrganization' }
              ) | null, testamentaryEntity?: (
                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ) | null }
                & { __typename?: 'TestamentaryEntity' }
              ) | null }
              & { __typename?: 'DispositiveProvision' }
            ) | null }
            & { __typename?: 'DispositiveProvisionEdge' }
          ) | null> | null }
          & { __typename?: 'DispositiveProvisionConnection' }
        ) }
        & { __typename?: 'DispositionScenario' }
      )> | null }
      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
    ) | (
      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
          { id: string }
          & { __typename?: 'ClientProfile' }
        )> | null }
        & { __typename?: 'InsurancePolicy' }
      )> | null, dispositionScenarios?: Array<(
        { id: string, firstGrantorDeath: (
          { id: string }
          & { __typename?: 'ClientProfile' }
        ), dispositiveProvisionsTemplate?: (
          { id: string, displayName: string, dispositiveProvisions: (
            { totalCount: number, edges?: Array<(
              { node?: (
                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                  { id: string }
                  & { __typename?: 'EstateWaterfall' }
                ) | null, entity?: (
                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                    { id: string, displayName: string }
                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                  ) | (
                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                  ) | (
                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      )> | null }
                      & { __typename?: 'InsurancePolicy' }
                    )> | null }
                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                  ) }
                  & { __typename?: 'Entity' }
                ) | null, individual?: (
                  { id: string, displayName: string }
                  & { __typename?: 'ClientProfile' }
                ) | null, organization?: (
                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                  & { __typename?: 'ClientOrganization' }
                ) | null, testamentaryEntity?: (
                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ) | null }
                  & { __typename?: 'TestamentaryEntity' }
                ) | null }
                & { __typename?: 'DispositiveProvision' }
              ) | null }
              & { __typename?: 'DispositiveProvisionEdge' }
            ) | null> | null }
            & { __typename?: 'DispositiveProvisionConnection' }
          ) }
          & { __typename?: 'DispositiveProvisionTemplate' }
        ) | null, secondDeathDispositiveProvisionsTemplate?: (
          { id: string, displayName: string, dispositiveProvisions: (
            { totalCount: number, edges?: Array<(
              { node?: (
                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                  { id: string }
                  & { __typename?: 'EstateWaterfall' }
                ) | null, entity?: (
                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                    { id: string, displayName: string }
                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                  ) | (
                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                  ) | (
                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      )> | null }
                      & { __typename?: 'InsurancePolicy' }
                    )> | null }
                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                  ) }
                  & { __typename?: 'Entity' }
                ) | null, individual?: (
                  { id: string, displayName: string }
                  & { __typename?: 'ClientProfile' }
                ) | null, organization?: (
                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                  & { __typename?: 'ClientOrganization' }
                ) | null, testamentaryEntity?: (
                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ) | null }
                  & { __typename?: 'TestamentaryEntity' }
                ) | null }
                & { __typename?: 'DispositiveProvision' }
              ) | null }
              & { __typename?: 'DispositiveProvisionEdge' }
            ) | null> | null }
            & { __typename?: 'DispositiveProvisionConnection' }
          ) }
          & { __typename?: 'DispositiveProvisionTemplate' }
        ) | null, dispositiveProvisions: (
          { totalCount: number, edges?: Array<(
            { node?: (
              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                { id: string }
                & { __typename?: 'EstateWaterfall' }
              ) | null, entity?: (
                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                  { id: string, displayName: string }
                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    )> | null }
                    & { __typename?: 'InsurancePolicy' }
                  )> | null }
                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                ) }
                & { __typename?: 'Entity' }
              ) | null, individual?: (
                { id: string, displayName: string }
                & { __typename?: 'ClientProfile' }
              ) | null, organization?: (
                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                & { __typename?: 'ClientOrganization' }
              ) | null, testamentaryEntity?: (
                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ) | null }
                & { __typename?: 'TestamentaryEntity' }
              ) | null }
              & { __typename?: 'DispositiveProvision' }
            ) | null }
            & { __typename?: 'DispositiveProvisionEdge' }
          ) | null> | null }
          & { __typename?: 'DispositiveProvisionConnection' }
        ), secondDeathDispositiveProvisions: (
          { totalCount: number, edges?: Array<(
            { node?: (
              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                { id: string }
                & { __typename?: 'EstateWaterfall' }
              ) | null, entity?: (
                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                  { id: string, displayName: string }
                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    )> | null }
                    & { __typename?: 'InsurancePolicy' }
                  )> | null }
                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                ) }
                & { __typename?: 'Entity' }
              ) | null, individual?: (
                { id: string, displayName: string }
                & { __typename?: 'ClientProfile' }
              ) | null, organization?: (
                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                & { __typename?: 'ClientOrganization' }
              ) | null, testamentaryEntity?: (
                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ) | null }
                & { __typename?: 'TestamentaryEntity' }
              ) | null }
              & { __typename?: 'DispositiveProvision' }
            ) | null }
            & { __typename?: 'DispositiveProvisionEdge' }
          ) | null> | null }
          & { __typename?: 'DispositiveProvisionConnection' }
        ) }
        & { __typename?: 'DispositionScenario' }
      )> | null }
      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
    ) }
    & { __typename?: 'Entity' }
  ) | null, individual?: (
    { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
      { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
        { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
        & { __typename?: 'ClientProfile' }
      ), toClientProfile: (
        { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
        & { __typename?: 'ClientProfile' }
      ) }
      & { __typename?: 'ClientProfileRelationship' }
    )> | null }
    & { __typename?: 'ClientProfile' }
  ) | null, organization?: (
    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
    & { __typename?: 'ClientOrganization' }
  ) | null, testamentaryEntity?: (
    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
      { id: string }
      & { __typename?: 'ClientProfile' }
    ) | null, dispositionScenarios?: Array<(
      { id: string, firstGrantorDeath: (
        { id: string }
        & { __typename?: 'ClientProfile' }
      ), dispositiveProvisionsTemplate?: (
        { id: string, displayName: string, dispositiveProvisions: (
          { totalCount: number, edges?: Array<(
            { node?: (
              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                { id: string }
                & { __typename?: 'EstateWaterfall' }
              ) | null, entity?: (
                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                  { id: string, displayName: string }
                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    )> | null }
                    & { __typename?: 'InsurancePolicy' }
                  )> | null }
                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                ) }
                & { __typename?: 'Entity' }
              ) | null, individual?: (
                { id: string, displayName: string }
                & { __typename?: 'ClientProfile' }
              ) | null, organization?: (
                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                & { __typename?: 'ClientOrganization' }
              ) | null, testamentaryEntity?: (
                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ) | null }
                & { __typename?: 'TestamentaryEntity' }
              ) | null }
              & { __typename?: 'DispositiveProvision' }
            ) | null }
            & { __typename?: 'DispositiveProvisionEdge' }
          ) | null> | null }
          & { __typename?: 'DispositiveProvisionConnection' }
        ) }
        & { __typename?: 'DispositiveProvisionTemplate' }
      ) | null, secondDeathDispositiveProvisionsTemplate?: (
        { id: string, displayName: string, dispositiveProvisions: (
          { totalCount: number, edges?: Array<(
            { node?: (
              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                { id: string }
                & { __typename?: 'EstateWaterfall' }
              ) | null, entity?: (
                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                  { id: string, displayName: string }
                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    )> | null }
                    & { __typename?: 'InsurancePolicy' }
                  )> | null }
                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                ) }
                & { __typename?: 'Entity' }
              ) | null, individual?: (
                { id: string, displayName: string }
                & { __typename?: 'ClientProfile' }
              ) | null, organization?: (
                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                & { __typename?: 'ClientOrganization' }
              ) | null, testamentaryEntity?: (
                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ) | null }
                & { __typename?: 'TestamentaryEntity' }
              ) | null }
              & { __typename?: 'DispositiveProvision' }
            ) | null }
            & { __typename?: 'DispositiveProvisionEdge' }
          ) | null> | null }
          & { __typename?: 'DispositiveProvisionConnection' }
        ) }
        & { __typename?: 'DispositiveProvisionTemplate' }
      ) | null, dispositiveProvisions: (
        { totalCount: number, edges?: Array<(
          { node?: (
            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
              { id: string }
              & { __typename?: 'EstateWaterfall' }
            ) | null, entity?: (
              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                { id: string, displayName: string }
                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
              ) | (
                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
              ) | (
                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  )> | null }
                  & { __typename?: 'InsurancePolicy' }
                )> | null }
                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
              ) }
              & { __typename?: 'Entity' }
            ) | null, individual?: (
              { id: string, displayName: string }
              & { __typename?: 'ClientProfile' }
            ) | null, organization?: (
              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
              & { __typename?: 'ClientOrganization' }
            ) | null, testamentaryEntity?: (
              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ) | null }
              & { __typename?: 'TestamentaryEntity' }
            ) | null }
            & { __typename?: 'DispositiveProvision' }
          ) | null }
          & { __typename?: 'DispositiveProvisionEdge' }
        ) | null> | null }
        & { __typename?: 'DispositiveProvisionConnection' }
      ), secondDeathDispositiveProvisions: (
        { totalCount: number, edges?: Array<(
          { node?: (
            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
              { id: string }
              & { __typename?: 'EstateWaterfall' }
            ) | null, entity?: (
              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                { id: string, displayName: string }
                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
              ) | (
                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
              ) | (
                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  )> | null }
                  & { __typename?: 'InsurancePolicy' }
                )> | null }
                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
              ) }
              & { __typename?: 'Entity' }
            ) | null, individual?: (
              { id: string, displayName: string }
              & { __typename?: 'ClientProfile' }
            ) | null, organization?: (
              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
              & { __typename?: 'ClientOrganization' }
            ) | null, testamentaryEntity?: (
              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ) | null }
              & { __typename?: 'TestamentaryEntity' }
            ) | null }
            & { __typename?: 'DispositiveProvision' }
          ) | null }
          & { __typename?: 'DispositiveProvisionEdge' }
        ) | null> | null }
        & { __typename?: 'DispositiveProvisionConnection' }
      ) }
      & { __typename?: 'DispositionScenario' }
    )> | null }
    & { __typename?: 'TestamentaryEntity' }
  ) | null }
  & { __typename?: 'DispositiveProvision' }
);

export type DispositionsDiagram_DispositionProvisionSecondDeathFragment = (
  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
    { id: string }
    & { __typename?: 'EstateWaterfall' }
  ) | null, entity?: (
    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
      { id: string, displayName: string }
      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
    ) | (
      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
    ) | (
      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
          { id: string }
          & { __typename?: 'ClientProfile' }
        )> | null }
        & { __typename?: 'InsurancePolicy' }
      )> | null }
      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
    ) }
    & { __typename?: 'Entity' }
  ) | null, individual?: (
    { id: string, displayName: string }
    & { __typename?: 'ClientProfile' }
  ) | null, organization?: (
    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
    & { __typename?: 'ClientOrganization' }
  ) | null, testamentaryEntity?: (
    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
      { id: string }
      & { __typename?: 'ClientProfile' }
    ) | null }
    & { __typename?: 'TestamentaryEntity' }
  ) | null }
  & { __typename?: 'DispositiveProvision' }
);

export type DispositionsDiagram_DispositionScenarioFirstDeathFragment = (
  { id: string, firstGrantorDeath: (
    { id: string }
    & { __typename?: 'ClientProfile' }
  ), dispositiveProvisionsTemplate?: (
    { id: string, displayName: string, dispositiveProvisions: (
      { totalCount: number, edges?: Array<(
        { node?: (
          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
            { id: string }
            & { __typename?: 'EstateWaterfall' }
          ) | null, entity?: (
            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
              { id: string, displayName: string, dispositionScenarios?: Array<(
                { id: string, firstGrantorDeath: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ), dispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, secondDeathDispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ), secondDeathDispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositionScenario' }
              )> | null }
              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
            ) | (
              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                { id: string, firstGrantorDeath: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ), dispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, secondDeathDispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ), secondDeathDispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositionScenario' }
              )> | null }
              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
            ) | (
              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                )> | null }
                & { __typename?: 'InsurancePolicy' }
              )> | null, dispositionScenarios?: Array<(
                { id: string, firstGrantorDeath: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ), dispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, secondDeathDispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ), secondDeathDispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositionScenario' }
              )> | null }
              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
            ) }
            & { __typename?: 'Entity' }
          ) | null, individual?: (
            { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
              { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                & { __typename?: 'ClientProfile' }
              ), toClientProfile: (
                { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                & { __typename?: 'ClientProfile' }
              ) }
              & { __typename?: 'ClientProfileRelationship' }
            )> | null }
            & { __typename?: 'ClientProfile' }
          ) | null, organization?: (
            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
            & { __typename?: 'ClientOrganization' }
          ) | null, testamentaryEntity?: (
            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
              { id: string }
              & { __typename?: 'ClientProfile' }
            ) | null, dispositionScenarios?: Array<(
              { id: string, firstGrantorDeath: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ), dispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, secondDeathDispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ), secondDeathDispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositionScenario' }
            )> | null }
            & { __typename?: 'TestamentaryEntity' }
          ) | null }
          & { __typename?: 'DispositiveProvision' }
        ) | null }
        & { __typename?: 'DispositiveProvisionEdge' }
      ) | null> | null }
      & { __typename?: 'DispositiveProvisionConnection' }
    ) }
    & { __typename?: 'DispositiveProvisionTemplate' }
  ) | null, secondDeathDispositiveProvisionsTemplate?: (
    { id: string, displayName: string, dispositiveProvisions: (
      { totalCount: number, edges?: Array<(
        { node?: (
          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
            { id: string }
            & { __typename?: 'EstateWaterfall' }
          ) | null, entity?: (
            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
              { id: string, displayName: string, dispositionScenarios?: Array<(
                { id: string, firstGrantorDeath: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ), dispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, secondDeathDispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ), secondDeathDispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositionScenario' }
              )> | null }
              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
            ) | (
              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                { id: string, firstGrantorDeath: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ), dispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, secondDeathDispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ), secondDeathDispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositionScenario' }
              )> | null }
              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
            ) | (
              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                )> | null }
                & { __typename?: 'InsurancePolicy' }
              )> | null, dispositionScenarios?: Array<(
                { id: string, firstGrantorDeath: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ), dispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, secondDeathDispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ), secondDeathDispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositionScenario' }
              )> | null }
              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
            ) }
            & { __typename?: 'Entity' }
          ) | null, individual?: (
            { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
              { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                & { __typename?: 'ClientProfile' }
              ), toClientProfile: (
                { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                & { __typename?: 'ClientProfile' }
              ) }
              & { __typename?: 'ClientProfileRelationship' }
            )> | null }
            & { __typename?: 'ClientProfile' }
          ) | null, organization?: (
            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
            & { __typename?: 'ClientOrganization' }
          ) | null, testamentaryEntity?: (
            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
              { id: string }
              & { __typename?: 'ClientProfile' }
            ) | null, dispositionScenarios?: Array<(
              { id: string, firstGrantorDeath: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ), dispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, secondDeathDispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ), secondDeathDispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositionScenario' }
            )> | null }
            & { __typename?: 'TestamentaryEntity' }
          ) | null }
          & { __typename?: 'DispositiveProvision' }
        ) | null }
        & { __typename?: 'DispositiveProvisionEdge' }
      ) | null> | null }
      & { __typename?: 'DispositiveProvisionConnection' }
    ) }
    & { __typename?: 'DispositiveProvisionTemplate' }
  ) | null, dispositiveProvisions: (
    { totalCount: number, edges?: Array<(
      { node?: (
        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
          { id: string }
          & { __typename?: 'EstateWaterfall' }
        ) | null, entity?: (
          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
            { id: string, displayName: string, dispositionScenarios?: Array<(
              { id: string, firstGrantorDeath: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ), dispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, secondDeathDispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ), secondDeathDispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositionScenario' }
            )> | null }
            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
          ) | (
            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
              { id: string, firstGrantorDeath: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ), dispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, secondDeathDispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ), secondDeathDispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositionScenario' }
            )> | null }
            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
          ) | (
            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                { id: string }
                & { __typename?: 'ClientProfile' }
              )> | null }
              & { __typename?: 'InsurancePolicy' }
            )> | null, dispositionScenarios?: Array<(
              { id: string, firstGrantorDeath: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ), dispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, secondDeathDispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ), secondDeathDispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositionScenario' }
            )> | null }
            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
          ) }
          & { __typename?: 'Entity' }
        ) | null, individual?: (
          { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
            { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
              { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
              & { __typename?: 'ClientProfile' }
            ), toClientProfile: (
              { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
              & { __typename?: 'ClientProfile' }
            ) }
            & { __typename?: 'ClientProfileRelationship' }
          )> | null }
          & { __typename?: 'ClientProfile' }
        ) | null, organization?: (
          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
          & { __typename?: 'ClientOrganization' }
        ) | null, testamentaryEntity?: (
          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
            { id: string }
            & { __typename?: 'ClientProfile' }
          ) | null, dispositionScenarios?: Array<(
            { id: string, firstGrantorDeath: (
              { id: string }
              & { __typename?: 'ClientProfile' }
            ), dispositiveProvisionsTemplate?: (
              { id: string, displayName: string, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositiveProvisionTemplate' }
            ) | null, secondDeathDispositiveProvisionsTemplate?: (
              { id: string, displayName: string, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositiveProvisionTemplate' }
            ) | null, dispositiveProvisions: (
              { totalCount: number, edges?: Array<(
                { node?: (
                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                    { id: string }
                    & { __typename?: 'EstateWaterfall' }
                  ) | null, entity?: (
                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                      { id: string, displayName: string }
                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        )> | null }
                        & { __typename?: 'InsurancePolicy' }
                      )> | null }
                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                    ) }
                    & { __typename?: 'Entity' }
                  ) | null, individual?: (
                    { id: string, displayName: string }
                    & { __typename?: 'ClientProfile' }
                  ) | null, organization?: (
                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                    & { __typename?: 'ClientOrganization' }
                  ) | null, testamentaryEntity?: (
                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null }
                    & { __typename?: 'TestamentaryEntity' }
                  ) | null }
                  & { __typename?: 'DispositiveProvision' }
                ) | null }
                & { __typename?: 'DispositiveProvisionEdge' }
              ) | null> | null }
              & { __typename?: 'DispositiveProvisionConnection' }
            ), secondDeathDispositiveProvisions: (
              { totalCount: number, edges?: Array<(
                { node?: (
                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                    { id: string }
                    & { __typename?: 'EstateWaterfall' }
                  ) | null, entity?: (
                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                      { id: string, displayName: string }
                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        )> | null }
                        & { __typename?: 'InsurancePolicy' }
                      )> | null }
                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                    ) }
                    & { __typename?: 'Entity' }
                  ) | null, individual?: (
                    { id: string, displayName: string }
                    & { __typename?: 'ClientProfile' }
                  ) | null, organization?: (
                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                    & { __typename?: 'ClientOrganization' }
                  ) | null, testamentaryEntity?: (
                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null }
                    & { __typename?: 'TestamentaryEntity' }
                  ) | null }
                  & { __typename?: 'DispositiveProvision' }
                ) | null }
                & { __typename?: 'DispositiveProvisionEdge' }
              ) | null> | null }
              & { __typename?: 'DispositiveProvisionConnection' }
            ) }
            & { __typename?: 'DispositionScenario' }
          )> | null }
          & { __typename?: 'TestamentaryEntity' }
        ) | null }
        & { __typename?: 'DispositiveProvision' }
      ) | null }
      & { __typename?: 'DispositiveProvisionEdge' }
    ) | null> | null }
    & { __typename?: 'DispositiveProvisionConnection' }
  ), secondDeathDispositiveProvisions: (
    { totalCount: number, edges?: Array<(
      { node?: (
        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
          { id: string }
          & { __typename?: 'EstateWaterfall' }
        ) | null, entity?: (
          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
            { id: string, displayName: string, dispositionScenarios?: Array<(
              { id: string, firstGrantorDeath: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ), dispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, secondDeathDispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ), secondDeathDispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositionScenario' }
            )> | null }
            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
          ) | (
            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
              { id: string, firstGrantorDeath: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ), dispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, secondDeathDispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ), secondDeathDispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositionScenario' }
            )> | null }
            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
          ) | (
            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                { id: string }
                & { __typename?: 'ClientProfile' }
              )> | null }
              & { __typename?: 'InsurancePolicy' }
            )> | null, dispositionScenarios?: Array<(
              { id: string, firstGrantorDeath: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ), dispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, secondDeathDispositiveProvisionsTemplate?: (
                { id: string, displayName: string, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositiveProvisionTemplate' }
              ) | null, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ), secondDeathDispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositionScenario' }
            )> | null }
            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
          ) }
          & { __typename?: 'Entity' }
        ) | null, individual?: (
          { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
            { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
              { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
              & { __typename?: 'ClientProfile' }
            ), toClientProfile: (
              { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
              & { __typename?: 'ClientProfile' }
            ) }
            & { __typename?: 'ClientProfileRelationship' }
          )> | null }
          & { __typename?: 'ClientProfile' }
        ) | null, organization?: (
          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
          & { __typename?: 'ClientOrganization' }
        ) | null, testamentaryEntity?: (
          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
            { id: string }
            & { __typename?: 'ClientProfile' }
          ) | null, dispositionScenarios?: Array<(
            { id: string, firstGrantorDeath: (
              { id: string }
              & { __typename?: 'ClientProfile' }
            ), dispositiveProvisionsTemplate?: (
              { id: string, displayName: string, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositiveProvisionTemplate' }
            ) | null, secondDeathDispositiveProvisionsTemplate?: (
              { id: string, displayName: string, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositiveProvisionTemplate' }
            ) | null, dispositiveProvisions: (
              { totalCount: number, edges?: Array<(
                { node?: (
                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                    { id: string }
                    & { __typename?: 'EstateWaterfall' }
                  ) | null, entity?: (
                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                      { id: string, displayName: string }
                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        )> | null }
                        & { __typename?: 'InsurancePolicy' }
                      )> | null }
                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                    ) }
                    & { __typename?: 'Entity' }
                  ) | null, individual?: (
                    { id: string, displayName: string }
                    & { __typename?: 'ClientProfile' }
                  ) | null, organization?: (
                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                    & { __typename?: 'ClientOrganization' }
                  ) | null, testamentaryEntity?: (
                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null }
                    & { __typename?: 'TestamentaryEntity' }
                  ) | null }
                  & { __typename?: 'DispositiveProvision' }
                ) | null }
                & { __typename?: 'DispositiveProvisionEdge' }
              ) | null> | null }
              & { __typename?: 'DispositiveProvisionConnection' }
            ), secondDeathDispositiveProvisions: (
              { totalCount: number, edges?: Array<(
                { node?: (
                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                    { id: string }
                    & { __typename?: 'EstateWaterfall' }
                  ) | null, entity?: (
                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                      { id: string, displayName: string }
                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        )> | null }
                        & { __typename?: 'InsurancePolicy' }
                      )> | null }
                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                    ) }
                    & { __typename?: 'Entity' }
                  ) | null, individual?: (
                    { id: string, displayName: string }
                    & { __typename?: 'ClientProfile' }
                  ) | null, organization?: (
                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                    & { __typename?: 'ClientOrganization' }
                  ) | null, testamentaryEntity?: (
                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null }
                    & { __typename?: 'TestamentaryEntity' }
                  ) | null }
                  & { __typename?: 'DispositiveProvision' }
                ) | null }
                & { __typename?: 'DispositiveProvisionEdge' }
              ) | null> | null }
              & { __typename?: 'DispositiveProvisionConnection' }
            ) }
            & { __typename?: 'DispositionScenario' }
          )> | null }
          & { __typename?: 'TestamentaryEntity' }
        ) | null }
        & { __typename?: 'DispositiveProvision' }
      ) | null }
      & { __typename?: 'DispositiveProvisionEdge' }
    ) | null> | null }
    & { __typename?: 'DispositiveProvisionConnection' }
  ) }
  & { __typename?: 'DispositionScenario' }
);

export type DispositionsDiagram_DispositionScenarioSecondDeathFragment = (
  { id: string, firstGrantorDeath: (
    { id: string }
    & { __typename?: 'ClientProfile' }
  ), dispositiveProvisionsTemplate?: (
    { id: string, displayName: string, dispositiveProvisions: (
      { totalCount: number, edges?: Array<(
        { node?: (
          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
            { id: string }
            & { __typename?: 'EstateWaterfall' }
          ) | null, entity?: (
            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
              { id: string, displayName: string }
              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
            ) | (
              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
            ) | (
              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                )> | null }
                & { __typename?: 'InsurancePolicy' }
              )> | null }
              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
            ) }
            & { __typename?: 'Entity' }
          ) | null, individual?: (
            { id: string, displayName: string }
            & { __typename?: 'ClientProfile' }
          ) | null, organization?: (
            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
            & { __typename?: 'ClientOrganization' }
          ) | null, testamentaryEntity?: (
            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
              { id: string }
              & { __typename?: 'ClientProfile' }
            ) | null }
            & { __typename?: 'TestamentaryEntity' }
          ) | null }
          & { __typename?: 'DispositiveProvision' }
        ) | null }
        & { __typename?: 'DispositiveProvisionEdge' }
      ) | null> | null }
      & { __typename?: 'DispositiveProvisionConnection' }
    ) }
    & { __typename?: 'DispositiveProvisionTemplate' }
  ) | null, secondDeathDispositiveProvisionsTemplate?: (
    { id: string, displayName: string, dispositiveProvisions: (
      { totalCount: number, edges?: Array<(
        { node?: (
          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
            { id: string }
            & { __typename?: 'EstateWaterfall' }
          ) | null, entity?: (
            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
              { id: string, displayName: string }
              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
            ) | (
              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
            ) | (
              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                )> | null }
                & { __typename?: 'InsurancePolicy' }
              )> | null }
              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
            ) }
            & { __typename?: 'Entity' }
          ) | null, individual?: (
            { id: string, displayName: string }
            & { __typename?: 'ClientProfile' }
          ) | null, organization?: (
            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
            & { __typename?: 'ClientOrganization' }
          ) | null, testamentaryEntity?: (
            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
              { id: string }
              & { __typename?: 'ClientProfile' }
            ) | null }
            & { __typename?: 'TestamentaryEntity' }
          ) | null }
          & { __typename?: 'DispositiveProvision' }
        ) | null }
        & { __typename?: 'DispositiveProvisionEdge' }
      ) | null> | null }
      & { __typename?: 'DispositiveProvisionConnection' }
    ) }
    & { __typename?: 'DispositiveProvisionTemplate' }
  ) | null, dispositiveProvisions: (
    { totalCount: number, edges?: Array<(
      { node?: (
        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
          { id: string }
          & { __typename?: 'EstateWaterfall' }
        ) | null, entity?: (
          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
            { id: string, displayName: string }
            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
          ) | (
            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
          ) | (
            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                { id: string }
                & { __typename?: 'ClientProfile' }
              )> | null }
              & { __typename?: 'InsurancePolicy' }
            )> | null }
            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
          ) }
          & { __typename?: 'Entity' }
        ) | null, individual?: (
          { id: string, displayName: string }
          & { __typename?: 'ClientProfile' }
        ) | null, organization?: (
          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
          & { __typename?: 'ClientOrganization' }
        ) | null, testamentaryEntity?: (
          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
            { id: string }
            & { __typename?: 'ClientProfile' }
          ) | null }
          & { __typename?: 'TestamentaryEntity' }
        ) | null }
        & { __typename?: 'DispositiveProvision' }
      ) | null }
      & { __typename?: 'DispositiveProvisionEdge' }
    ) | null> | null }
    & { __typename?: 'DispositiveProvisionConnection' }
  ), secondDeathDispositiveProvisions: (
    { totalCount: number, edges?: Array<(
      { node?: (
        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
          { id: string }
          & { __typename?: 'EstateWaterfall' }
        ) | null, entity?: (
          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
            { id: string, displayName: string }
            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
          ) | (
            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
          ) | (
            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                { id: string }
                & { __typename?: 'ClientProfile' }
              )> | null }
              & { __typename?: 'InsurancePolicy' }
            )> | null }
            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
          ) }
          & { __typename?: 'Entity' }
        ) | null, individual?: (
          { id: string, displayName: string }
          & { __typename?: 'ClientProfile' }
        ) | null, organization?: (
          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
          & { __typename?: 'ClientOrganization' }
        ) | null, testamentaryEntity?: (
          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
            { id: string }
            & { __typename?: 'ClientProfile' }
          ) | null }
          & { __typename?: 'TestamentaryEntity' }
        ) | null }
        & { __typename?: 'DispositiveProvision' }
      ) | null }
      & { __typename?: 'DispositiveProvisionEdge' }
    ) | null> | null }
    & { __typename?: 'DispositiveProvisionConnection' }
  ) }
  & { __typename?: 'DispositionScenario' }
);

export type OwnershipDiagram_InsurancePolicyFragment = (
  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
    { id: string }
    & { __typename?: 'ClientProfile' }
  )> | null }
  & { __typename?: 'InsurancePolicy' }
);

export type DispositionsDiagram_EntityFragment = (
  { id: string, extendedDisplayKind: string, stage: EntityStage, entityKind: EntityKind, subtype: (
    { id: string, displayName: string, dispositionScenarios?: Array<(
      { id: string, firstGrantorDeath: (
        { id: string }
        & { __typename?: 'ClientProfile' }
      ), dispositiveProvisionsTemplate?: (
        { id: string, displayName: string, dispositiveProvisions: (
          { totalCount: number, edges?: Array<(
            { node?: (
              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                { id: string }
                & { __typename?: 'EstateWaterfall' }
              ) | null, entity?: (
                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                  { id: string, displayName: string, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    )> | null }
                    & { __typename?: 'InsurancePolicy' }
                  )> | null, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                ) }
                & { __typename?: 'Entity' }
              ) | null, individual?: (
                { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                  { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                    { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                    & { __typename?: 'ClientProfile' }
                  ), toClientProfile: (
                    { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                    & { __typename?: 'ClientProfile' }
                  ) }
                  & { __typename?: 'ClientProfileRelationship' }
                )> | null }
                & { __typename?: 'ClientProfile' }
              ) | null, organization?: (
                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                & { __typename?: 'ClientOrganization' }
              ) | null, testamentaryEntity?: (
                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ) | null, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'TestamentaryEntity' }
              ) | null }
              & { __typename?: 'DispositiveProvision' }
            ) | null }
            & { __typename?: 'DispositiveProvisionEdge' }
          ) | null> | null }
          & { __typename?: 'DispositiveProvisionConnection' }
        ) }
        & { __typename?: 'DispositiveProvisionTemplate' }
      ) | null, secondDeathDispositiveProvisionsTemplate?: (
        { id: string, displayName: string, dispositiveProvisions: (
          { totalCount: number, edges?: Array<(
            { node?: (
              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                { id: string }
                & { __typename?: 'EstateWaterfall' }
              ) | null, entity?: (
                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                  { id: string, displayName: string, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    )> | null }
                    & { __typename?: 'InsurancePolicy' }
                  )> | null, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                ) }
                & { __typename?: 'Entity' }
              ) | null, individual?: (
                { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                  { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                    { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                    & { __typename?: 'ClientProfile' }
                  ), toClientProfile: (
                    { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                    & { __typename?: 'ClientProfile' }
                  ) }
                  & { __typename?: 'ClientProfileRelationship' }
                )> | null }
                & { __typename?: 'ClientProfile' }
              ) | null, organization?: (
                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                & { __typename?: 'ClientOrganization' }
              ) | null, testamentaryEntity?: (
                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ) | null, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'TestamentaryEntity' }
              ) | null }
              & { __typename?: 'DispositiveProvision' }
            ) | null }
            & { __typename?: 'DispositiveProvisionEdge' }
          ) | null> | null }
          & { __typename?: 'DispositiveProvisionConnection' }
        ) }
        & { __typename?: 'DispositiveProvisionTemplate' }
      ) | null, dispositiveProvisions: (
        { totalCount: number, edges?: Array<(
          { node?: (
            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
              { id: string }
              & { __typename?: 'EstateWaterfall' }
            ) | null, entity?: (
              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                { id: string, displayName: string, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
              ) | (
                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
              ) | (
                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  )> | null }
                  & { __typename?: 'InsurancePolicy' }
                )> | null, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
              ) }
              & { __typename?: 'Entity' }
            ) | null, individual?: (
              { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                  { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                  & { __typename?: 'ClientProfile' }
                ), toClientProfile: (
                  { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                  & { __typename?: 'ClientProfile' }
                ) }
                & { __typename?: 'ClientProfileRelationship' }
              )> | null }
              & { __typename?: 'ClientProfile' }
            ) | null, organization?: (
              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
              & { __typename?: 'ClientOrganization' }
            ) | null, testamentaryEntity?: (
              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ) | null, dispositionScenarios?: Array<(
                { id: string, firstGrantorDeath: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ), dispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, secondDeathDispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ), secondDeathDispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositionScenario' }
              )> | null }
              & { __typename?: 'TestamentaryEntity' }
            ) | null }
            & { __typename?: 'DispositiveProvision' }
          ) | null }
          & { __typename?: 'DispositiveProvisionEdge' }
        ) | null> | null }
        & { __typename?: 'DispositiveProvisionConnection' }
      ), secondDeathDispositiveProvisions: (
        { totalCount: number, edges?: Array<(
          { node?: (
            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
              { id: string }
              & { __typename?: 'EstateWaterfall' }
            ) | null, entity?: (
              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                { id: string, displayName: string, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
              ) | (
                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
              ) | (
                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  )> | null }
                  & { __typename?: 'InsurancePolicy' }
                )> | null, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
              ) }
              & { __typename?: 'Entity' }
            ) | null, individual?: (
              { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                  { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                  & { __typename?: 'ClientProfile' }
                ), toClientProfile: (
                  { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                  & { __typename?: 'ClientProfile' }
                ) }
                & { __typename?: 'ClientProfileRelationship' }
              )> | null }
              & { __typename?: 'ClientProfile' }
            ) | null, organization?: (
              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
              & { __typename?: 'ClientOrganization' }
            ) | null, testamentaryEntity?: (
              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ) | null, dispositionScenarios?: Array<(
                { id: string, firstGrantorDeath: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ), dispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, secondDeathDispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ), secondDeathDispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositionScenario' }
              )> | null }
              & { __typename?: 'TestamentaryEntity' }
            ) | null }
            & { __typename?: 'DispositiveProvision' }
          ) | null }
          & { __typename?: 'DispositiveProvisionEdge' }
        ) | null> | null }
        & { __typename?: 'DispositiveProvisionConnection' }
      ) }
      & { __typename?: 'DispositionScenario' }
    )> | null }
    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
  ) | (
    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
      { id: string, firstGrantorDeath: (
        { id: string }
        & { __typename?: 'ClientProfile' }
      ), dispositiveProvisionsTemplate?: (
        { id: string, displayName: string, dispositiveProvisions: (
          { totalCount: number, edges?: Array<(
            { node?: (
              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                { id: string }
                & { __typename?: 'EstateWaterfall' }
              ) | null, entity?: (
                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                  { id: string, displayName: string, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    )> | null }
                    & { __typename?: 'InsurancePolicy' }
                  )> | null, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                ) }
                & { __typename?: 'Entity' }
              ) | null, individual?: (
                { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                  { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                    { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                    & { __typename?: 'ClientProfile' }
                  ), toClientProfile: (
                    { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                    & { __typename?: 'ClientProfile' }
                  ) }
                  & { __typename?: 'ClientProfileRelationship' }
                )> | null }
                & { __typename?: 'ClientProfile' }
              ) | null, organization?: (
                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                & { __typename?: 'ClientOrganization' }
              ) | null, testamentaryEntity?: (
                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ) | null, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'TestamentaryEntity' }
              ) | null }
              & { __typename?: 'DispositiveProvision' }
            ) | null }
            & { __typename?: 'DispositiveProvisionEdge' }
          ) | null> | null }
          & { __typename?: 'DispositiveProvisionConnection' }
        ) }
        & { __typename?: 'DispositiveProvisionTemplate' }
      ) | null, secondDeathDispositiveProvisionsTemplate?: (
        { id: string, displayName: string, dispositiveProvisions: (
          { totalCount: number, edges?: Array<(
            { node?: (
              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                { id: string }
                & { __typename?: 'EstateWaterfall' }
              ) | null, entity?: (
                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                  { id: string, displayName: string, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    )> | null }
                    & { __typename?: 'InsurancePolicy' }
                  )> | null, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                ) }
                & { __typename?: 'Entity' }
              ) | null, individual?: (
                { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                  { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                    { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                    & { __typename?: 'ClientProfile' }
                  ), toClientProfile: (
                    { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                    & { __typename?: 'ClientProfile' }
                  ) }
                  & { __typename?: 'ClientProfileRelationship' }
                )> | null }
                & { __typename?: 'ClientProfile' }
              ) | null, organization?: (
                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                & { __typename?: 'ClientOrganization' }
              ) | null, testamentaryEntity?: (
                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ) | null, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'TestamentaryEntity' }
              ) | null }
              & { __typename?: 'DispositiveProvision' }
            ) | null }
            & { __typename?: 'DispositiveProvisionEdge' }
          ) | null> | null }
          & { __typename?: 'DispositiveProvisionConnection' }
        ) }
        & { __typename?: 'DispositiveProvisionTemplate' }
      ) | null, dispositiveProvisions: (
        { totalCount: number, edges?: Array<(
          { node?: (
            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
              { id: string }
              & { __typename?: 'EstateWaterfall' }
            ) | null, entity?: (
              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                { id: string, displayName: string, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
              ) | (
                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
              ) | (
                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  )> | null }
                  & { __typename?: 'InsurancePolicy' }
                )> | null, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
              ) }
              & { __typename?: 'Entity' }
            ) | null, individual?: (
              { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                  { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                  & { __typename?: 'ClientProfile' }
                ), toClientProfile: (
                  { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                  & { __typename?: 'ClientProfile' }
                ) }
                & { __typename?: 'ClientProfileRelationship' }
              )> | null }
              & { __typename?: 'ClientProfile' }
            ) | null, organization?: (
              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
              & { __typename?: 'ClientOrganization' }
            ) | null, testamentaryEntity?: (
              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ) | null, dispositionScenarios?: Array<(
                { id: string, firstGrantorDeath: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ), dispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, secondDeathDispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ), secondDeathDispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositionScenario' }
              )> | null }
              & { __typename?: 'TestamentaryEntity' }
            ) | null }
            & { __typename?: 'DispositiveProvision' }
          ) | null }
          & { __typename?: 'DispositiveProvisionEdge' }
        ) | null> | null }
        & { __typename?: 'DispositiveProvisionConnection' }
      ), secondDeathDispositiveProvisions: (
        { totalCount: number, edges?: Array<(
          { node?: (
            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
              { id: string }
              & { __typename?: 'EstateWaterfall' }
            ) | null, entity?: (
              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                { id: string, displayName: string, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
              ) | (
                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
              ) | (
                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  )> | null }
                  & { __typename?: 'InsurancePolicy' }
                )> | null, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
              ) }
              & { __typename?: 'Entity' }
            ) | null, individual?: (
              { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                  { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                  & { __typename?: 'ClientProfile' }
                ), toClientProfile: (
                  { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                  & { __typename?: 'ClientProfile' }
                ) }
                & { __typename?: 'ClientProfileRelationship' }
              )> | null }
              & { __typename?: 'ClientProfile' }
            ) | null, organization?: (
              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
              & { __typename?: 'ClientOrganization' }
            ) | null, testamentaryEntity?: (
              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ) | null, dispositionScenarios?: Array<(
                { id: string, firstGrantorDeath: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ), dispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, secondDeathDispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ), secondDeathDispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositionScenario' }
              )> | null }
              & { __typename?: 'TestamentaryEntity' }
            ) | null }
            & { __typename?: 'DispositiveProvision' }
          ) | null }
          & { __typename?: 'DispositiveProvisionEdge' }
        ) | null> | null }
        & { __typename?: 'DispositiveProvisionConnection' }
      ) }
      & { __typename?: 'DispositionScenario' }
    )> | null }
    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
  ) | (
    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
        { id: string }
        & { __typename?: 'ClientProfile' }
      )> | null }
      & { __typename?: 'InsurancePolicy' }
    )> | null, dispositionScenarios?: Array<(
      { id: string, firstGrantorDeath: (
        { id: string }
        & { __typename?: 'ClientProfile' }
      ), dispositiveProvisionsTemplate?: (
        { id: string, displayName: string, dispositiveProvisions: (
          { totalCount: number, edges?: Array<(
            { node?: (
              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                { id: string }
                & { __typename?: 'EstateWaterfall' }
              ) | null, entity?: (
                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                  { id: string, displayName: string, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    )> | null }
                    & { __typename?: 'InsurancePolicy' }
                  )> | null, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                ) }
                & { __typename?: 'Entity' }
              ) | null, individual?: (
                { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                  { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                    { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                    & { __typename?: 'ClientProfile' }
                  ), toClientProfile: (
                    { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                    & { __typename?: 'ClientProfile' }
                  ) }
                  & { __typename?: 'ClientProfileRelationship' }
                )> | null }
                & { __typename?: 'ClientProfile' }
              ) | null, organization?: (
                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                & { __typename?: 'ClientOrganization' }
              ) | null, testamentaryEntity?: (
                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ) | null, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'TestamentaryEntity' }
              ) | null }
              & { __typename?: 'DispositiveProvision' }
            ) | null }
            & { __typename?: 'DispositiveProvisionEdge' }
          ) | null> | null }
          & { __typename?: 'DispositiveProvisionConnection' }
        ) }
        & { __typename?: 'DispositiveProvisionTemplate' }
      ) | null, secondDeathDispositiveProvisionsTemplate?: (
        { id: string, displayName: string, dispositiveProvisions: (
          { totalCount: number, edges?: Array<(
            { node?: (
              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                { id: string }
                & { __typename?: 'EstateWaterfall' }
              ) | null, entity?: (
                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                  { id: string, displayName: string, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                ) | (
                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    )> | null }
                    & { __typename?: 'InsurancePolicy' }
                  )> | null, dispositionScenarios?: Array<(
                    { id: string, firstGrantorDeath: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ), dispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, secondDeathDispositiveProvisionsTemplate?: (
                      { id: string, displayName: string, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositiveProvisionTemplate' }
                    ) | null, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ), secondDeathDispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositionScenario' }
                  )> | null }
                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                ) }
                & { __typename?: 'Entity' }
              ) | null, individual?: (
                { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                  { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                    { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                    & { __typename?: 'ClientProfile' }
                  ), toClientProfile: (
                    { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                    & { __typename?: 'ClientProfile' }
                  ) }
                  & { __typename?: 'ClientProfileRelationship' }
                )> | null }
                & { __typename?: 'ClientProfile' }
              ) | null, organization?: (
                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                & { __typename?: 'ClientOrganization' }
              ) | null, testamentaryEntity?: (
                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ) | null, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'TestamentaryEntity' }
              ) | null }
              & { __typename?: 'DispositiveProvision' }
            ) | null }
            & { __typename?: 'DispositiveProvisionEdge' }
          ) | null> | null }
          & { __typename?: 'DispositiveProvisionConnection' }
        ) }
        & { __typename?: 'DispositiveProvisionTemplate' }
      ) | null, dispositiveProvisions: (
        { totalCount: number, edges?: Array<(
          { node?: (
            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
              { id: string }
              & { __typename?: 'EstateWaterfall' }
            ) | null, entity?: (
              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                { id: string, displayName: string, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
              ) | (
                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
              ) | (
                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  )> | null }
                  & { __typename?: 'InsurancePolicy' }
                )> | null, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
              ) }
              & { __typename?: 'Entity' }
            ) | null, individual?: (
              { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                  { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                  & { __typename?: 'ClientProfile' }
                ), toClientProfile: (
                  { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                  & { __typename?: 'ClientProfile' }
                ) }
                & { __typename?: 'ClientProfileRelationship' }
              )> | null }
              & { __typename?: 'ClientProfile' }
            ) | null, organization?: (
              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
              & { __typename?: 'ClientOrganization' }
            ) | null, testamentaryEntity?: (
              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ) | null, dispositionScenarios?: Array<(
                { id: string, firstGrantorDeath: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ), dispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, secondDeathDispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ), secondDeathDispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositionScenario' }
              )> | null }
              & { __typename?: 'TestamentaryEntity' }
            ) | null }
            & { __typename?: 'DispositiveProvision' }
          ) | null }
          & { __typename?: 'DispositiveProvisionEdge' }
        ) | null> | null }
        & { __typename?: 'DispositiveProvisionConnection' }
      ), secondDeathDispositiveProvisions: (
        { totalCount: number, edges?: Array<(
          { node?: (
            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
              { id: string }
              & { __typename?: 'EstateWaterfall' }
            ) | null, entity?: (
              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                { id: string, displayName: string, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
              ) | (
                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
              ) | (
                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  )> | null }
                  & { __typename?: 'InsurancePolicy' }
                )> | null, dispositionScenarios?: Array<(
                  { id: string, firstGrantorDeath: (
                    { id: string }
                    & { __typename?: 'ClientProfile' }
                  ), dispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, secondDeathDispositiveProvisionsTemplate?: (
                    { id: string, displayName: string, dispositiveProvisions: (
                      { totalCount: number, edges?: Array<(
                        { node?: (
                          { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                            { id: string }
                            & { __typename?: 'EstateWaterfall' }
                          ) | null, entity?: (
                            { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                              { id: string, displayName: string }
                              & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                              & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                            ) | (
                              { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                )> | null }
                                & { __typename?: 'InsurancePolicy' }
                              )> | null }
                              & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                            ) }
                            & { __typename?: 'Entity' }
                          ) | null, individual?: (
                            { id: string, displayName: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null, organization?: (
                            { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                            & { __typename?: 'ClientOrganization' }
                          ) | null, testamentaryEntity?: (
                            { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null }
                            & { __typename?: 'TestamentaryEntity' }
                          ) | null }
                          & { __typename?: 'DispositiveProvision' }
                        ) | null }
                        & { __typename?: 'DispositiveProvisionEdge' }
                      ) | null> | null }
                      & { __typename?: 'DispositiveProvisionConnection' }
                    ) }
                    & { __typename?: 'DispositiveProvisionTemplate' }
                  ) | null, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ), secondDeathDispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositionScenario' }
                )> | null }
                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
              ) }
              & { __typename?: 'Entity' }
            ) | null, individual?: (
              { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                  { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                  & { __typename?: 'ClientProfile' }
                ), toClientProfile: (
                  { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                  & { __typename?: 'ClientProfile' }
                ) }
                & { __typename?: 'ClientProfileRelationship' }
              )> | null }
              & { __typename?: 'ClientProfile' }
            ) | null, organization?: (
              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
              & { __typename?: 'ClientOrganization' }
            ) | null, testamentaryEntity?: (
              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                { id: string }
                & { __typename?: 'ClientProfile' }
              ) | null, dispositionScenarios?: Array<(
                { id: string, firstGrantorDeath: (
                  { id: string }
                  & { __typename?: 'ClientProfile' }
                ), dispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, secondDeathDispositiveProvisionsTemplate?: (
                  { id: string, displayName: string, dispositiveProvisions: (
                    { totalCount: number, edges?: Array<(
                      { node?: (
                        { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                          { id: string }
                          & { __typename?: 'EstateWaterfall' }
                        ) | null, entity?: (
                          { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                            { id: string, displayName: string }
                            & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                            & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                          ) | (
                            { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                              { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              )> | null }
                              & { __typename?: 'InsurancePolicy' }
                            )> | null }
                            & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                          ) }
                          & { __typename?: 'Entity' }
                        ) | null, individual?: (
                          { id: string, displayName: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null, organization?: (
                          { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                          & { __typename?: 'ClientOrganization' }
                        ) | null, testamentaryEntity?: (
                          { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ) | null }
                          & { __typename?: 'TestamentaryEntity' }
                        ) | null }
                        & { __typename?: 'DispositiveProvision' }
                      ) | null }
                      & { __typename?: 'DispositiveProvisionEdge' }
                    ) | null> | null }
                    & { __typename?: 'DispositiveProvisionConnection' }
                  ) }
                  & { __typename?: 'DispositiveProvisionTemplate' }
                ) | null, dispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ), secondDeathDispositiveProvisions: (
                  { totalCount: number, edges?: Array<(
                    { node?: (
                      { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                        { id: string }
                        & { __typename?: 'EstateWaterfall' }
                      ) | null, entity?: (
                        { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                          { id: string, displayName: string }
                          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                        ) | (
                          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                              { id: string }
                              & { __typename?: 'ClientProfile' }
                            )> | null }
                            & { __typename?: 'InsurancePolicy' }
                          )> | null }
                          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                        ) }
                        & { __typename?: 'Entity' }
                      ) | null, individual?: (
                        { id: string, displayName: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, organization?: (
                        { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                        & { __typename?: 'ClientOrganization' }
                      ) | null, testamentaryEntity?: (
                        { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ) | null }
                        & { __typename?: 'TestamentaryEntity' }
                      ) | null }
                      & { __typename?: 'DispositiveProvision' }
                    ) | null }
                    & { __typename?: 'DispositiveProvisionEdge' }
                  ) | null> | null }
                  & { __typename?: 'DispositiveProvisionConnection' }
                ) }
                & { __typename?: 'DispositionScenario' }
              )> | null }
              & { __typename?: 'TestamentaryEntity' }
            ) | null }
            & { __typename?: 'DispositiveProvision' }
          ) | null }
          & { __typename?: 'DispositiveProvisionEdge' }
        ) | null> | null }
        & { __typename?: 'DispositiveProvisionConnection' }
      ) }
      & { __typename?: 'DispositionScenario' }
    )> | null }
    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
  ) }
  & { __typename?: 'Entity' }
);

export type DispositionsDiagramQueryVariables = Exact<{
  entityWhere: EntityWhereInput;
}>;


export type DispositionsDiagramQuery = (
  { entities: (
    { edges?: Array<(
      { node?: (
        { id: string, extendedDisplayKind: string, stage: EntityStage, entityKind: EntityKind, subtype: (
          { id: string, displayName: string, dispositionScenarios?: Array<(
            { id: string, firstGrantorDeath: (
              { id: string }
              & { __typename?: 'ClientProfile' }
            ), dispositiveProvisionsTemplate?: (
              { id: string, displayName: string, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                        { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                          { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                          & { __typename?: 'ClientProfile' }
                        ), toClientProfile: (
                          { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                          & { __typename?: 'ClientProfile' }
                        ) }
                        & { __typename?: 'ClientProfileRelationship' }
                      )> | null }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositiveProvisionTemplate' }
            ) | null, secondDeathDispositiveProvisionsTemplate?: (
              { id: string, displayName: string, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                        { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                          { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                          & { __typename?: 'ClientProfile' }
                        ), toClientProfile: (
                          { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                          & { __typename?: 'ClientProfile' }
                        ) }
                        & { __typename?: 'ClientProfileRelationship' }
                      )> | null }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositiveProvisionTemplate' }
            ) | null, dispositiveProvisions: (
              { totalCount: number, edges?: Array<(
                { node?: (
                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                    { id: string }
                    & { __typename?: 'EstateWaterfall' }
                  ) | null, entity?: (
                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                      { id: string, displayName: string, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        )> | null }
                        & { __typename?: 'InsurancePolicy' }
                      )> | null, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                    ) }
                    & { __typename?: 'Entity' }
                  ) | null, individual?: (
                    { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                      { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                        { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                        & { __typename?: 'ClientProfile' }
                      ), toClientProfile: (
                        { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                        & { __typename?: 'ClientProfile' }
                      ) }
                      & { __typename?: 'ClientProfileRelationship' }
                    )> | null }
                    & { __typename?: 'ClientProfile' }
                  ) | null, organization?: (
                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                    & { __typename?: 'ClientOrganization' }
                  ) | null, testamentaryEntity?: (
                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, dispositionScenarios?: Array<(
                      { id: string, firstGrantorDeath: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ), dispositiveProvisionsTemplate?: (
                        { id: string, displayName: string, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositiveProvisionTemplate' }
                      ) | null, secondDeathDispositiveProvisionsTemplate?: (
                        { id: string, displayName: string, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositiveProvisionTemplate' }
                      ) | null, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ), secondDeathDispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositionScenario' }
                    )> | null }
                    & { __typename?: 'TestamentaryEntity' }
                  ) | null }
                  & { __typename?: 'DispositiveProvision' }
                ) | null }
                & { __typename?: 'DispositiveProvisionEdge' }
              ) | null> | null }
              & { __typename?: 'DispositiveProvisionConnection' }
            ), secondDeathDispositiveProvisions: (
              { totalCount: number, edges?: Array<(
                { node?: (
                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                    { id: string }
                    & { __typename?: 'EstateWaterfall' }
                  ) | null, entity?: (
                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                      { id: string, displayName: string, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        )> | null }
                        & { __typename?: 'InsurancePolicy' }
                      )> | null, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                    ) }
                    & { __typename?: 'Entity' }
                  ) | null, individual?: (
                    { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                      { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                        { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                        & { __typename?: 'ClientProfile' }
                      ), toClientProfile: (
                        { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                        & { __typename?: 'ClientProfile' }
                      ) }
                      & { __typename?: 'ClientProfileRelationship' }
                    )> | null }
                    & { __typename?: 'ClientProfile' }
                  ) | null, organization?: (
                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                    & { __typename?: 'ClientOrganization' }
                  ) | null, testamentaryEntity?: (
                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, dispositionScenarios?: Array<(
                      { id: string, firstGrantorDeath: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ), dispositiveProvisionsTemplate?: (
                        { id: string, displayName: string, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositiveProvisionTemplate' }
                      ) | null, secondDeathDispositiveProvisionsTemplate?: (
                        { id: string, displayName: string, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositiveProvisionTemplate' }
                      ) | null, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ), secondDeathDispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositionScenario' }
                    )> | null }
                    & { __typename?: 'TestamentaryEntity' }
                  ) | null }
                  & { __typename?: 'DispositiveProvision' }
                ) | null }
                & { __typename?: 'DispositiveProvisionEdge' }
              ) | null> | null }
              & { __typename?: 'DispositiveProvisionConnection' }
            ) }
            & { __typename?: 'DispositionScenario' }
          )> | null }
          & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
        ) | (
          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
            { id: string, firstGrantorDeath: (
              { id: string }
              & { __typename?: 'ClientProfile' }
            ), dispositiveProvisionsTemplate?: (
              { id: string, displayName: string, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                        { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                          { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                          & { __typename?: 'ClientProfile' }
                        ), toClientProfile: (
                          { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                          & { __typename?: 'ClientProfile' }
                        ) }
                        & { __typename?: 'ClientProfileRelationship' }
                      )> | null }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositiveProvisionTemplate' }
            ) | null, secondDeathDispositiveProvisionsTemplate?: (
              { id: string, displayName: string, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                        { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                          { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                          & { __typename?: 'ClientProfile' }
                        ), toClientProfile: (
                          { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                          & { __typename?: 'ClientProfile' }
                        ) }
                        & { __typename?: 'ClientProfileRelationship' }
                      )> | null }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositiveProvisionTemplate' }
            ) | null, dispositiveProvisions: (
              { totalCount: number, edges?: Array<(
                { node?: (
                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                    { id: string }
                    & { __typename?: 'EstateWaterfall' }
                  ) | null, entity?: (
                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                      { id: string, displayName: string, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        )> | null }
                        & { __typename?: 'InsurancePolicy' }
                      )> | null, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                    ) }
                    & { __typename?: 'Entity' }
                  ) | null, individual?: (
                    { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                      { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                        { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                        & { __typename?: 'ClientProfile' }
                      ), toClientProfile: (
                        { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                        & { __typename?: 'ClientProfile' }
                      ) }
                      & { __typename?: 'ClientProfileRelationship' }
                    )> | null }
                    & { __typename?: 'ClientProfile' }
                  ) | null, organization?: (
                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                    & { __typename?: 'ClientOrganization' }
                  ) | null, testamentaryEntity?: (
                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, dispositionScenarios?: Array<(
                      { id: string, firstGrantorDeath: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ), dispositiveProvisionsTemplate?: (
                        { id: string, displayName: string, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositiveProvisionTemplate' }
                      ) | null, secondDeathDispositiveProvisionsTemplate?: (
                        { id: string, displayName: string, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositiveProvisionTemplate' }
                      ) | null, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ), secondDeathDispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositionScenario' }
                    )> | null }
                    & { __typename?: 'TestamentaryEntity' }
                  ) | null }
                  & { __typename?: 'DispositiveProvision' }
                ) | null }
                & { __typename?: 'DispositiveProvisionEdge' }
              ) | null> | null }
              & { __typename?: 'DispositiveProvisionConnection' }
            ), secondDeathDispositiveProvisions: (
              { totalCount: number, edges?: Array<(
                { node?: (
                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                    { id: string }
                    & { __typename?: 'EstateWaterfall' }
                  ) | null, entity?: (
                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                      { id: string, displayName: string, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        )> | null }
                        & { __typename?: 'InsurancePolicy' }
                      )> | null, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                    ) }
                    & { __typename?: 'Entity' }
                  ) | null, individual?: (
                    { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                      { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                        { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                        & { __typename?: 'ClientProfile' }
                      ), toClientProfile: (
                        { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                        & { __typename?: 'ClientProfile' }
                      ) }
                      & { __typename?: 'ClientProfileRelationship' }
                    )> | null }
                    & { __typename?: 'ClientProfile' }
                  ) | null, organization?: (
                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                    & { __typename?: 'ClientOrganization' }
                  ) | null, testamentaryEntity?: (
                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, dispositionScenarios?: Array<(
                      { id: string, firstGrantorDeath: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ), dispositiveProvisionsTemplate?: (
                        { id: string, displayName: string, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositiveProvisionTemplate' }
                      ) | null, secondDeathDispositiveProvisionsTemplate?: (
                        { id: string, displayName: string, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositiveProvisionTemplate' }
                      ) | null, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ), secondDeathDispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositionScenario' }
                    )> | null }
                    & { __typename?: 'TestamentaryEntity' }
                  ) | null }
                  & { __typename?: 'DispositiveProvision' }
                ) | null }
                & { __typename?: 'DispositiveProvisionEdge' }
              ) | null> | null }
              & { __typename?: 'DispositiveProvisionConnection' }
            ) }
            & { __typename?: 'DispositionScenario' }
          )> | null }
          & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
        ) | (
          { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
            { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
              { id: string }
              & { __typename?: 'ClientProfile' }
            )> | null }
            & { __typename?: 'InsurancePolicy' }
          )> | null, dispositionScenarios?: Array<(
            { id: string, firstGrantorDeath: (
              { id: string }
              & { __typename?: 'ClientProfile' }
            ), dispositiveProvisionsTemplate?: (
              { id: string, displayName: string, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                        { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                          { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                          & { __typename?: 'ClientProfile' }
                        ), toClientProfile: (
                          { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                          & { __typename?: 'ClientProfile' }
                        ) }
                        & { __typename?: 'ClientProfileRelationship' }
                      )> | null }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositiveProvisionTemplate' }
            ) | null, secondDeathDispositiveProvisionsTemplate?: (
              { id: string, displayName: string, dispositiveProvisions: (
                { totalCount: number, edges?: Array<(
                  { node?: (
                    { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                      { id: string }
                      & { __typename?: 'EstateWaterfall' }
                    ) | null, entity?: (
                      { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                        { id: string, displayName: string, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                      ) | (
                        { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                          { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          )> | null }
                          & { __typename?: 'InsurancePolicy' }
                        )> | null, dispositionScenarios?: Array<(
                          { id: string, firstGrantorDeath: (
                            { id: string }
                            & { __typename?: 'ClientProfile' }
                          ), dispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, secondDeathDispositiveProvisionsTemplate?: (
                            { id: string, displayName: string, dispositiveProvisions: (
                              { totalCount: number, edges?: Array<(
                                { node?: (
                                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                    { id: string }
                                    & { __typename?: 'EstateWaterfall' }
                                  ) | null, entity?: (
                                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                      { id: string, displayName: string }
                                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                    ) | (
                                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                          { id: string }
                                          & { __typename?: 'ClientProfile' }
                                        )> | null }
                                        & { __typename?: 'InsurancePolicy' }
                                      )> | null }
                                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                    ) }
                                    & { __typename?: 'Entity' }
                                  ) | null, individual?: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null, organization?: (
                                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                    & { __typename?: 'ClientOrganization' }
                                  ) | null, testamentaryEntity?: (
                                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    ) | null }
                                    & { __typename?: 'TestamentaryEntity' }
                                  ) | null }
                                  & { __typename?: 'DispositiveProvision' }
                                ) | null }
                                & { __typename?: 'DispositiveProvisionEdge' }
                              ) | null> | null }
                              & { __typename?: 'DispositiveProvisionConnection' }
                            ) }
                            & { __typename?: 'DispositiveProvisionTemplate' }
                          ) | null, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ), secondDeathDispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositionScenario' }
                        )> | null }
                        & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                      ) }
                      & { __typename?: 'Entity' }
                    ) | null, individual?: (
                      { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                        { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                          { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                          & { __typename?: 'ClientProfile' }
                        ), toClientProfile: (
                          { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                          & { __typename?: 'ClientProfile' }
                        ) }
                        & { __typename?: 'ClientProfileRelationship' }
                      )> | null }
                      & { __typename?: 'ClientProfile' }
                    ) | null, organization?: (
                      { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                      & { __typename?: 'ClientOrganization' }
                    ) | null, testamentaryEntity?: (
                      { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ) | null, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'TestamentaryEntity' }
                    ) | null }
                    & { __typename?: 'DispositiveProvision' }
                  ) | null }
                  & { __typename?: 'DispositiveProvisionEdge' }
                ) | null> | null }
                & { __typename?: 'DispositiveProvisionConnection' }
              ) }
              & { __typename?: 'DispositiveProvisionTemplate' }
            ) | null, dispositiveProvisions: (
              { totalCount: number, edges?: Array<(
                { node?: (
                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                    { id: string }
                    & { __typename?: 'EstateWaterfall' }
                  ) | null, entity?: (
                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                      { id: string, displayName: string, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        )> | null }
                        & { __typename?: 'InsurancePolicy' }
                      )> | null, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                    ) }
                    & { __typename?: 'Entity' }
                  ) | null, individual?: (
                    { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                      { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                        { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                        & { __typename?: 'ClientProfile' }
                      ), toClientProfile: (
                        { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                        & { __typename?: 'ClientProfile' }
                      ) }
                      & { __typename?: 'ClientProfileRelationship' }
                    )> | null }
                    & { __typename?: 'ClientProfile' }
                  ) | null, organization?: (
                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                    & { __typename?: 'ClientOrganization' }
                  ) | null, testamentaryEntity?: (
                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, dispositionScenarios?: Array<(
                      { id: string, firstGrantorDeath: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ), dispositiveProvisionsTemplate?: (
                        { id: string, displayName: string, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositiveProvisionTemplate' }
                      ) | null, secondDeathDispositiveProvisionsTemplate?: (
                        { id: string, displayName: string, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositiveProvisionTemplate' }
                      ) | null, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ), secondDeathDispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositionScenario' }
                    )> | null }
                    & { __typename?: 'TestamentaryEntity' }
                  ) | null }
                  & { __typename?: 'DispositiveProvision' }
                ) | null }
                & { __typename?: 'DispositiveProvisionEdge' }
              ) | null> | null }
              & { __typename?: 'DispositiveProvisionConnection' }
            ), secondDeathDispositiveProvisions: (
              { totalCount: number, edges?: Array<(
                { node?: (
                  { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                    { id: string }
                    & { __typename?: 'EstateWaterfall' }
                  ) | null, entity?: (
                    { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                      { id: string, displayName: string, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                    ) | (
                      { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                        { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        )> | null }
                        & { __typename?: 'InsurancePolicy' }
                      )> | null, dispositionScenarios?: Array<(
                        { id: string, firstGrantorDeath: (
                          { id: string }
                          & { __typename?: 'ClientProfile' }
                        ), dispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, secondDeathDispositiveProvisionsTemplate?: (
                          { id: string, displayName: string, dispositiveProvisions: (
                            { totalCount: number, edges?: Array<(
                              { node?: (
                                { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                  { id: string }
                                  & { __typename?: 'EstateWaterfall' }
                                ) | null, entity?: (
                                  { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                    { id: string, displayName: string }
                                    & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                    & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                  ) | (
                                    { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                      { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                        { id: string }
                                        & { __typename?: 'ClientProfile' }
                                      )> | null }
                                      & { __typename?: 'InsurancePolicy' }
                                    )> | null }
                                    & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                  ) }
                                  & { __typename?: 'Entity' }
                                ) | null, individual?: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null, organization?: (
                                  { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                  & { __typename?: 'ClientOrganization' }
                                ) | null, testamentaryEntity?: (
                                  { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  ) | null }
                                  & { __typename?: 'TestamentaryEntity' }
                                ) | null }
                                & { __typename?: 'DispositiveProvision' }
                              ) | null }
                              & { __typename?: 'DispositiveProvisionEdge' }
                            ) | null> | null }
                            & { __typename?: 'DispositiveProvisionConnection' }
                          ) }
                          & { __typename?: 'DispositiveProvisionTemplate' }
                        ) | null, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ), secondDeathDispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositionScenario' }
                      )> | null }
                      & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                    ) }
                    & { __typename?: 'Entity' }
                  ) | null, individual?: (
                    { id: string, displayName: string, firstName: string, lastName: string, relationships?: Array<(
                      { id: string, type: ClientProfileRelationshipType, fromClientProfile: (
                        { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                        & { __typename?: 'ClientProfile' }
                      ), toClientProfile: (
                        { id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }
                        & { __typename?: 'ClientProfile' }
                      ) }
                      & { __typename?: 'ClientProfileRelationship' }
                    )> | null }
                    & { __typename?: 'ClientProfile' }
                  ) | null, organization?: (
                    { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                    & { __typename?: 'ClientOrganization' }
                  ) | null, testamentaryEntity?: (
                    { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                      { id: string }
                      & { __typename?: 'ClientProfile' }
                    ) | null, dispositionScenarios?: Array<(
                      { id: string, firstGrantorDeath: (
                        { id: string }
                        & { __typename?: 'ClientProfile' }
                      ), dispositiveProvisionsTemplate?: (
                        { id: string, displayName: string, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositiveProvisionTemplate' }
                      ) | null, secondDeathDispositiveProvisionsTemplate?: (
                        { id: string, displayName: string, dispositiveProvisions: (
                          { totalCount: number, edges?: Array<(
                            { node?: (
                              { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                                { id: string }
                                & { __typename?: 'EstateWaterfall' }
                              ) | null, entity?: (
                                { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                  { id: string, displayName: string }
                                  & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                  & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                                ) | (
                                  { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                    { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                      { id: string }
                                      & { __typename?: 'ClientProfile' }
                                    )> | null }
                                    & { __typename?: 'InsurancePolicy' }
                                  )> | null }
                                  & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                                ) }
                                & { __typename?: 'Entity' }
                              ) | null, individual?: (
                                { id: string, displayName: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null, organization?: (
                                { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                                & { __typename?: 'ClientOrganization' }
                              ) | null, testamentaryEntity?: (
                                { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                  { id: string }
                                  & { __typename?: 'ClientProfile' }
                                ) | null }
                                & { __typename?: 'TestamentaryEntity' }
                              ) | null }
                              & { __typename?: 'DispositiveProvision' }
                            ) | null }
                            & { __typename?: 'DispositiveProvisionEdge' }
                          ) | null> | null }
                          & { __typename?: 'DispositiveProvisionConnection' }
                        ) }
                        & { __typename?: 'DispositiveProvisionTemplate' }
                      ) | null, dispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ), secondDeathDispositiveProvisions: (
                        { totalCount: number, edges?: Array<(
                          { node?: (
                            { id: string, dispositionKind: DispositiveProvisionDispositionKind, recipientKind?: DispositiveProvisionRecipientKind | null, transferTaxKind: DispositiveProvisionTransferTaxKind, associatedHypotheticalWaterfall?: (
                              { id: string }
                              & { __typename?: 'EstateWaterfall' }
                            ) | null, entity?: (
                              { id: string, extendedDisplayKind: string, entityKind: EntityKind, subtype: (
                                { id: string, displayName: string }
                                & { __typename?: 'CCorpBusinessEntity' | 'GPBusinessEntity' | 'LLCBusinessEntity' | 'LPBusinessEntity' | 'SCorpBusinessEntity' | 'SoleProprietorshipBusinessEntity' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string }
                                & { __typename?: 'CLTTrust' | 'CRTTrust' | 'CustodialPersonalAccount' | 'DonorAdvisedFund' | 'GRATTrust' | 'IndividualPersonalAccount' | 'IrrevocableTrust' | 'JointPersonalAccount' | 'PrivateFoundation' | 'QPRTTrust' | 'QualifiedTuitionPersonalAccount' | 'RetirementPersonalAccount' | 'RevocableTrust' | 'SLATTrust' }
                              ) | (
                                { id: string, gstStatus?: EntityGstStatus | null, inEstateStatus?: EntityInEstateStatus | null, displayName: string, policies?: Array<(
                                  { id: string, deathBenefitAmountString: CurrencyUSD, policyHolders?: Array<(
                                    { id: string }
                                    & { __typename?: 'ClientProfile' }
                                  )> | null }
                                  & { __typename?: 'InsurancePolicy' }
                                )> | null }
                                & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
                              ) }
                              & { __typename?: 'Entity' }
                            ) | null, individual?: (
                              { id: string, displayName: string }
                              & { __typename?: 'ClientProfile' }
                            ) | null, organization?: (
                              { id: string, name: string, clientOrganizationKind?: ClientOrganizationKind | null }
                              & { __typename?: 'ClientOrganization' }
                            ) | null, testamentaryEntity?: (
                              { id: string, displayName: string, inEstateStatus?: EntityInEstateStatus | null, gstStatus?: EntityGstStatus | null, testamentaryEntityKind: TestamentaryEntityKind, grantorDeath?: (
                                { id: string }
                                & { __typename?: 'ClientProfile' }
                              ) | null }
                              & { __typename?: 'TestamentaryEntity' }
                            ) | null }
                            & { __typename?: 'DispositiveProvision' }
                          ) | null }
                          & { __typename?: 'DispositiveProvisionEdge' }
                        ) | null> | null }
                        & { __typename?: 'DispositiveProvisionConnection' }
                      ) }
                      & { __typename?: 'DispositionScenario' }
                    )> | null }
                    & { __typename?: 'TestamentaryEntity' }
                  ) | null }
                  & { __typename?: 'DispositiveProvision' }
                ) | null }
                & { __typename?: 'DispositiveProvisionEdge' }
              ) | null> | null }
              & { __typename?: 'DispositiveProvisionConnection' }
            ) }
            & { __typename?: 'DispositionScenario' }
          )> | null }
          & { __typename?: 'ILITTrust' | 'InsurancePersonalAccount' }
        ) }
        & { __typename?: 'Entity' }
      ) | null }
      & { __typename?: 'EntityEdge' }
    ) | null> | null }
    & { __typename?: 'EntityConnection' }
  ) }
  & { __typename?: 'Query' }
);

export const OwnershipDiagram_InsurancePolicyFragmentDoc = gql`
    fragment OwnershipDiagram_InsurancePolicy on InsurancePolicy {
  id
  deathBenefitAmountString: deathBenefitAmount
  policyHolders {
    id
  }
}
    `;
export const DispositionsDiagram_GetTaxStatusForEntityFragmentDoc = gql`
    fragment DispositionsDiagram_GetTaxStatusForEntity on Entity {
  id
  subtype {
    id
    ... on GRATTrust {
      id
      gstStatus
      inEstateStatus
    }
    ... on ILITTrust {
      id
      gstStatus
      inEstateStatus
    }
    ... on RevocableTrust {
      id
      gstStatus
      inEstateStatus
    }
    ... on SLATTrust {
      id
      gstStatus
      inEstateStatus
    }
    ... on IrrevocableTrust {
      id
      gstStatus
      inEstateStatus
    }
    ... on QPRTTrust {
      id
      gstStatus
      inEstateStatus
    }
    ... on IndividualPersonalAccount {
      id
      gstStatus
      inEstateStatus
    }
    ... on JointPersonalAccount {
      id
      gstStatus
      inEstateStatus
    }
    ... on RetirementPersonalAccount {
      id
      gstStatus
      inEstateStatus
    }
    ... on CustodialPersonalAccount {
      id
      gstStatus
      inEstateStatus
    }
    ... on QualifiedTuitionPersonalAccount {
      id
      gstStatus
      inEstateStatus
    }
    ... on CRTTrust {
      id
      gstStatus
      inEstateStatus
    }
    ... on CLTTrust {
      id
      gstStatus
      inEstateStatus
    }
    ... on DonorAdvisedFund {
      id
      gstStatus
      inEstateStatus
    }
    ... on PrivateFoundation {
      id
      gstStatus
      inEstateStatus
    }
    ... on InsurancePersonalAccount {
      id
      gstStatus
      inEstateStatus
    }
  }
}
    `;
export const DispositionsDiagram_DispositionProvisionSecondDeathFragmentDoc = gql`
    fragment DispositionsDiagram_DispositionProvisionSecondDeath on DispositiveProvision {
  id
  dispositionKind
  associatedHypotheticalWaterfall {
    id
  }
  entity {
    id
    entityKind: kind
    extendedDisplayKind
    subtype {
      id
      displayName
      ... on ILITTrust {
        policies {
          ...OwnershipDiagram_InsurancePolicy
        }
      }
      ... on InsurancePersonalAccount {
        policies {
          ...OwnershipDiagram_InsurancePolicy
        }
      }
    }
    ...DispositionsDiagram_GetTaxStatusForEntity
  }
  individual {
    id
    displayName
  }
  organization {
    id
    clientOrganizationKind: kind
    name
  }
  recipientKind
  testamentaryEntity {
    id
    displayName
    inEstateStatus
    testamentaryEntityKind: kind
    gstStatus
    grantorDeath {
      id
    }
  }
  transferTaxKind
}
    ${OwnershipDiagram_InsurancePolicyFragmentDoc}
${DispositionsDiagram_GetTaxStatusForEntityFragmentDoc}`;
export const DispositionsDiagram_DispositiveProvisionTemplateSecondDeathFragmentDoc = gql`
    fragment DispositionsDiagram_DispositiveProvisionTemplateSecondDeath on DispositiveProvisionTemplate {
  id
  displayName
  dispositiveProvisions(orderBy: {direction: ASC, field: DISPOSITION_ORDER}) {
    totalCount
    edges {
      node {
        ...DispositionsDiagram_DispositionProvisionSecondDeath
      }
    }
  }
}
    ${DispositionsDiagram_DispositionProvisionSecondDeathFragmentDoc}`;
export const DispositionsDiagram_DispositionScenarioSecondDeathFragmentDoc = gql`
    fragment DispositionsDiagram_DispositionScenarioSecondDeath on DispositionScenario {
  id
  firstGrantorDeath {
    id
  }
  dispositiveProvisionsTemplate {
    ...DispositionsDiagram_DispositiveProvisionTemplateSecondDeath
  }
  secondDeathDispositiveProvisionsTemplate {
    ...DispositionsDiagram_DispositiveProvisionTemplateSecondDeath
  }
  dispositiveProvisions(orderBy: {direction: ASC, field: DISPOSITION_ORDER}) {
    totalCount
    edges {
      node {
        ...DispositionsDiagram_DispositionProvisionSecondDeath
      }
    }
  }
  secondDeathDispositiveProvisions(
    orderBy: {direction: ASC, field: DISPOSITION_ORDER}
  ) {
    totalCount
    edges {
      node {
        ...DispositionsDiagram_DispositionProvisionSecondDeath
      }
    }
  }
}
    ${DispositionsDiagram_DispositiveProvisionTemplateSecondDeathFragmentDoc}
${DispositionsDiagram_DispositionProvisionSecondDeathFragmentDoc}`;
export const DispositionsDiagram_ClientProfileMinimalGrantorRelationshipFragmentDoc = gql`
    fragment DispositionsDiagram_ClientProfileMinimalGrantorRelationship on ClientProfileRelationship {
  id
  type
  fromClientProfile {
    id
    isGrantor
    isPrimary
    firstName
    lastName
  }
  toClientProfile {
    id
    isGrantor
    isPrimary
    firstName
    lastName
  }
}
    `;
export const DispositionsDiagram_DispositionProvisionFirstDeathFragmentDoc = gql`
    fragment DispositionsDiagram_DispositionProvisionFirstDeath on DispositiveProvision {
  id
  dispositionKind
  associatedHypotheticalWaterfall {
    id
  }
  entity {
    id
    entityKind: kind
    extendedDisplayKind
    subtype {
      id
      displayName
      dispositionScenarios {
        ...DispositionsDiagram_DispositionScenarioSecondDeath
      }
      ... on ILITTrust {
        policies {
          ...OwnershipDiagram_InsurancePolicy
        }
      }
      ... on InsurancePersonalAccount {
        policies {
          ...OwnershipDiagram_InsurancePolicy
        }
      }
    }
    ...DispositionsDiagram_GetTaxStatusForEntity
  }
  individual {
    id
    displayName
    firstName
    lastName
    relationships {
      ...DispositionsDiagram_ClientProfileMinimalGrantorRelationship
    }
  }
  organization {
    id
    clientOrganizationKind: kind
    name
  }
  recipientKind
  testamentaryEntity {
    id
    displayName
    inEstateStatus
    testamentaryEntityKind: kind
    gstStatus
    grantorDeath {
      id
    }
    dispositionScenarios {
      ...DispositionsDiagram_DispositionScenarioSecondDeath
    }
  }
  transferTaxKind
}
    ${DispositionsDiagram_DispositionScenarioSecondDeathFragmentDoc}
${OwnershipDiagram_InsurancePolicyFragmentDoc}
${DispositionsDiagram_GetTaxStatusForEntityFragmentDoc}
${DispositionsDiagram_ClientProfileMinimalGrantorRelationshipFragmentDoc}`;
export const DispositionsDiagram_DispositiveProvisionTemplateFirstDeathFragmentDoc = gql`
    fragment DispositionsDiagram_DispositiveProvisionTemplateFirstDeath on DispositiveProvisionTemplate {
  id
  displayName
  dispositiveProvisions(orderBy: {direction: ASC, field: DISPOSITION_ORDER}) {
    totalCount
    edges {
      node {
        ...DispositionsDiagram_DispositionProvisionFirstDeath
      }
    }
  }
}
    ${DispositionsDiagram_DispositionProvisionFirstDeathFragmentDoc}`;
export const DispositionsDiagram_DispositionScenarioFirstDeathFragmentDoc = gql`
    fragment DispositionsDiagram_DispositionScenarioFirstDeath on DispositionScenario {
  id
  firstGrantorDeath {
    id
  }
  dispositiveProvisionsTemplate {
    ...DispositionsDiagram_DispositiveProvisionTemplateFirstDeath
  }
  secondDeathDispositiveProvisionsTemplate {
    ...DispositionsDiagram_DispositiveProvisionTemplateFirstDeath
  }
  dispositiveProvisions(orderBy: {direction: ASC, field: DISPOSITION_ORDER}) {
    totalCount
    edges {
      node {
        ...DispositionsDiagram_DispositionProvisionFirstDeath
      }
    }
  }
  secondDeathDispositiveProvisions(
    orderBy: {direction: ASC, field: DISPOSITION_ORDER}
  ) {
    totalCount
    edges {
      node {
        ...DispositionsDiagram_DispositionProvisionFirstDeath
      }
    }
  }
}
    ${DispositionsDiagram_DispositiveProvisionTemplateFirstDeathFragmentDoc}
${DispositionsDiagram_DispositionProvisionFirstDeathFragmentDoc}`;
export const DispositionsDiagram_EntityFragmentDoc = gql`
    fragment DispositionsDiagram_Entity on Entity {
  id
  entityKind: kind
  extendedDisplayKind
  stage
  subtype {
    id
    displayName
    dispositionScenarios {
      ...DispositionsDiagram_DispositionScenarioFirstDeath
    }
    ... on ILITTrust {
      policies {
        ...OwnershipDiagram_InsurancePolicy
      }
    }
    ... on InsurancePersonalAccount {
      policies {
        ...OwnershipDiagram_InsurancePolicy
      }
    }
  }
  ...DispositionsDiagram_GetTaxStatusForEntity
}
    ${DispositionsDiagram_DispositionScenarioFirstDeathFragmentDoc}
${OwnershipDiagram_InsurancePolicyFragmentDoc}
${DispositionsDiagram_GetTaxStatusForEntityFragmentDoc}`;
export const DispositionsDiagramDocument = gql`
    query DispositionsDiagram($entityWhere: EntityWhereInput!) {
  entities(where: $entityWhere) {
    edges {
      node {
        ...DispositionsDiagram_Entity
      }
    }
  }
}
    ${DispositionsDiagram_EntityFragmentDoc}`;

/**
 * __useDispositionsDiagramQuery__
 *
 * To run a query within a React component, call `useDispositionsDiagramQuery` and pass it any options that fit your needs.
 * When your component renders, `useDispositionsDiagramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDispositionsDiagramQuery({
 *   variables: {
 *      entityWhere: // value for 'entityWhere'
 *   },
 * });
 */
export function useDispositionsDiagramQuery(baseOptions: Apollo.QueryHookOptions<DispositionsDiagramQuery, DispositionsDiagramQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DispositionsDiagramQuery, DispositionsDiagramQueryVariables>(DispositionsDiagramDocument, options);
      }
export function useDispositionsDiagramLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DispositionsDiagramQuery, DispositionsDiagramQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DispositionsDiagramQuery, DispositionsDiagramQueryVariables>(DispositionsDiagramDocument, options);
        }
export function useDispositionsDiagramSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DispositionsDiagramQuery, DispositionsDiagramQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DispositionsDiagramQuery, DispositionsDiagramQueryVariables>(DispositionsDiagramDocument, options);
        }
export type DispositionsDiagramQueryHookResult = ReturnType<typeof useDispositionsDiagramQuery>;
export type DispositionsDiagramLazyQueryHookResult = ReturnType<typeof useDispositionsDiagramLazyQuery>;
export type DispositionsDiagramSuspenseQueryHookResult = ReturnType<typeof useDispositionsDiagramSuspenseQuery>;
export type DispositionsDiagramQueryResult = Apollo.QueryResult<DispositionsDiagramQuery, DispositionsDiagramQueryVariables>;
export const Operations = {
  Query: {
    DispositionsDiagram: 'DispositionsDiagram' as const
  },
  Fragment: {
    DispositionsDiagram_GetTaxStatusForEntity: 'DispositionsDiagram_GetTaxStatusForEntity' as const,
    DispositionsDiagram_ClientProfileMinimalGrantorRelationship: 'DispositionsDiagram_ClientProfileMinimalGrantorRelationship' as const,
    DispositionsDiagram_DispositiveProvisionTemplateFirstDeath: 'DispositionsDiagram_DispositiveProvisionTemplateFirstDeath' as const,
    DispositionsDiagram_DispositiveProvisionTemplateSecondDeath: 'DispositionsDiagram_DispositiveProvisionTemplateSecondDeath' as const,
    DispositionsDiagram_DispositionProvisionFirstDeath: 'DispositionsDiagram_DispositionProvisionFirstDeath' as const,
    DispositionsDiagram_DispositionProvisionSecondDeath: 'DispositionsDiagram_DispositionProvisionSecondDeath' as const,
    DispositionsDiagram_DispositionScenarioFirstDeath: 'DispositionsDiagram_DispositionScenarioFirstDeath' as const,
    DispositionsDiagram_DispositionScenarioSecondDeath: 'DispositionsDiagram_DispositionScenarioSecondDeath' as const,
    OwnershipDiagram_InsurancePolicy: 'OwnershipDiagram_InsurancePolicy' as const,
    DispositionsDiagram_Entity: 'DispositionsDiagram_Entity' as const
  }
}