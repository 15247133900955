import { EntityDiagramActionFn, SetOpacityOnAction } from '../types';

export const setOpacityOnAction: EntityDiagramActionFn<SetOpacityOnAction> = (
  state,
  _action,
  queueEffect
) => {
  const { presentationMode } = state;

  queueEffect({ type: 'fitViewEffect', kind: 'init', presentationMode });

  return {
    ...state,
    opacity: 1,
  };
};
