import { Decimal } from 'decimal.js';
import { compact, first } from 'lodash';
import { useMemo } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { getNodes } from '@/utils/graphqlUtils';

import {
  GetHypotheticalTransfers_EstateWaterfallLoanFragment,
  GetHypotheticalTransfers_HypotheticalSaleLoanFragment,
  GetHypotheticalTransfers_HypotheticalTransferFragment,
  useGetHypotheticalTransfersQuery,
} from '../graphql/GetHypotheticalTransfers.generated';

export interface SaleLoanWithProjections {
  saleLoan: GetHypotheticalTransfers_HypotheticalSaleLoanFragment;
  loanProjections: GetHypotheticalTransfers_EstateWaterfallLoanFragment | null;
}

export interface TransferWithValue {
  transfer: GetHypotheticalTransfers_HypotheticalTransferFragment;
  value: Decimal;
}

interface GetHypotheticalTransfersInput {
  transferReferenceObjId?: string;
  waterfallId: string;
  sourceTransferReferenceObjId?: string;
  destinationTransferReferenceObjId?: string;
  isIndividual: boolean;
  isEntity: boolean;
}

export function useGetHypotheticalTransfers({
  transferReferenceObjId,
  waterfallId,
  sourceTransferReferenceObjId,
  destinationTransferReferenceObjId,
  isIndividual,
  isEntity,
}: GetHypotheticalTransfersInput) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const hypotheticalTransfersWhere = useMemo(() => {
    if (sourceTransferReferenceObjId) {
      if (isEntity) {
        return {
          hasSourceEntityWith: [
            {
              id: sourceTransferReferenceObjId,
            },
          ],
        };
      }

      if (isIndividual) {
        return {
          hasSourceIndividualWith: [
            {
              id: sourceTransferReferenceObjId,
            },
          ],
        };
      }
    }

    if (destinationTransferReferenceObjId) {
      if (isEntity) {
        return {
          hasDestinationEntityWith: [
            {
              id: destinationTransferReferenceObjId,
            },
          ],
        };
      }

      if (isIndividual) {
        return {
          hasDestinationIndividualWith: [
            {
              id: destinationTransferReferenceObjId,
            },
          ],
        };
      }
    }

    return;
  }, [
    destinationTransferReferenceObjId,
    isEntity,
    isIndividual,
    sourceTransferReferenceObjId,
  ]);

  const hypotheticalSaleLoansWhere = useMemo(() => {
    if (sourceTransferReferenceObjId) {
      if (isEntity) {
        return {
          hasSourceEntityWith: [
            {
              id: sourceTransferReferenceObjId,
            },
          ],
        };
      }

      if (isIndividual) {
        return {
          hasSourceIndividualWith: [
            {
              id: sourceTransferReferenceObjId,
            },
          ],
        };
      }
    }

    if (destinationTransferReferenceObjId) {
      if (isEntity) {
        return {
          hasRecipientEntityWith: [
            {
              id: destinationTransferReferenceObjId,
            },
          ],
        };
      }

      if (isIndividual) {
        return {
          hasRecipientIndividualWith: [
            {
              id: destinationTransferReferenceObjId,
            },
          ],
        };
      }
    }

    return;
  }, [
    destinationTransferReferenceObjId,
    isEntity,
    isIndividual,
    sourceTransferReferenceObjId,
  ]);

  const queryProps = useGetHypotheticalTransfersQuery({
    variables: {
      where: {
        id: waterfallId,
      },
      hypotheticalTransfersWhere,
      hypotheticalSaleLoansWhere,
    },
    fetchPolicy: 'cache-and-network', // Make sure transfers are represented in the cache
    onError: (err) => {
      showFeedback(
        'Failed to fetch transfers. Please refresh the page and try again.'
      );
      reportError('Failed to fetch transfers', err);
    },
    skip: !transferReferenceObjId,
  });

  const { data } = queryProps;

  const transfers: TransferWithValue[] = useMemo(() => {
    const waterfall = getNodes(data?.estateWaterfalls)[0];

    if (!waterfall) {
      return [];
    }

    const orderedTransfers = getNodes(waterfall.hypotheticalTransfers).sort(
      (a, b) => {
        return a.transferOrder - b.transferOrder;
      }
    );

    return orderedTransfers.map((t): TransferWithValue => {
      const edge = waterfall.visualizationWithProjections?.edges?.find((e) =>
        e.associatedTransfers?.find((a) => a?.transfer?.id === t.id)
      );
      const associatedTransfers = compact(
        edge?.associatedTransfers?.filter((a) => a?.transfer?.id === t.id)
      );

      if (!associatedTransfers) {
        return {
          transfer: t,
          value: new Decimal(0),
        };
      }

      return {
        transfer: t,
        value: sumDecimalJS(associatedTransfers.map((a) => a.value)),
      };
    });
  }, [data?.estateWaterfalls]);

  const saleLoans: SaleLoanWithProjections[] = useMemo(() => {
    const waterfall = first(getNodes(data?.estateWaterfalls));
    return (
      getNodes(waterfall?.hypotheticalSaleLoans).map((hsl) => {
        // we want to expose both the sale/loan object itself and the projections that are
        // associated with it
        return {
          saleLoan: hsl,
          loanProjections:
            waterfall?.visualizationWithProjections?.loans?.find(
              (l) => l.id === hsl.id
            ) ?? null,
        };
      }) ?? []
    );
  }, [data?.estateWaterfalls]);

  // This is the value history of the reference object (the entity or individual that we're looking at the
  // hypothetical transfers for). It's used to look up the value of the reference object after the hypothetical
  // transfers are applied.
  const referenceObjectValueHistory = useMemo(() => {
    const waterfall = first(getNodes(data?.estateWaterfalls));
    return (
      waterfall?.visualizationWithProjections?.nodes?.find(
        (n) => n.id === transferReferenceObjId
      )?.valueHistory ?? null
    );
  }, [data?.estateWaterfalls, transferReferenceObjId]);

  return {
    ...queryProps,
    transfers,
    saleLoans,
    referenceObjectValueHistory,
  };
}
