import { Button } from '@/components/form/baseInputs/Button';
import { SwitchHorizontal01Icon } from '@/components/icons/SwitchHorizontal01Icon';

import { isHypotheticalWaterfall } from '../EstateWaterfall.utils';
import { EstateWaterfallFragment } from '../graphql/EstateWaterfall.generated';
import { GetWaterfallSummary_EstateWaterfallFragment } from '../graphql/GetWaterfallSummary.generated';

export interface HypotheticalTransfersButtonProps {
  /**
   * Callback that handles opening the sidebar to the hypothetical transfers summary view
   */
  onClick: () => void;
  /**
   * Waterfall to check against
   */
  waterfall:
    | GetWaterfallSummary_EstateWaterfallFragment
    | EstateWaterfallFragment;
}

export function HypotheticalTransfersButton({
  onClick,
  waterfall,
}: HypotheticalTransfersButtonProps) {
  const isHypothetical = isHypotheticalWaterfall(waterfall);

  if (!isHypothetical) return null;

  return (
    <Button
      variant="secondary"
      size="sm"
      startIcon={SwitchHorizontal01Icon}
      iconProps={{ size: 20 }}
      onClick={onClick}
      data-testid="HypotheticalTransfersButton"
    >
      Transfers
    </Button>
  );
}

export const HYPOTHETICAL_TRANSFERS_SUMMARY_SENTINEL =
  '__HYPOTHETICAL_TRANSFERS__' as string;
