import { Box, BoxProps, Stack, Typography, useTheme } from '@mui/material';
import { ComponentType } from 'react';

import { ClientPresentationDesigner_HouseholdFragment } from '@/modules/presentation/clientPresentation/ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';
import { useDefaultDisclaimerSlideTitle } from '@/modules/presentation/clientPresentation/DisclaimerSlide/useDefaultDisclaimerSlideTitle';
import { useGuardedTenantPresentationConfigurationContext } from '@/modules/presentation/context/tenantPresentationConfiguration.context';
import { PRESENTATION_SPACING_UNITS } from '@/modules/presentation/presentation.constants';
import { COLORS } from '@/styles/tokens/colors';

import { SlideContainer } from './SlideContainer';

export interface DisclaimerSlideProps {
  client: ClientPresentationDesigner_HouseholdFragment;
  SlideWrapper?: ComponentType<BoxProps>;
}

/**
 * @description This slide exposes customers' configured disclaimers in a two-column layout
 */
export function CustomDisclaimerSlide() {
  const { legalDisclaimers } =
    useGuardedTenantPresentationConfigurationContext();
  const theme = useTheme();
  const defaultDisclaimerSlideTitle = useDefaultDisclaimerSlideTitle();

  if (!legalDisclaimers?.presentationStandaloneDisclaimerBody) {
    return null;
  }

  const slideTitle =
    legalDisclaimers?.presentationStandaloneDisclaimerTitle ??
    defaultDisclaimerSlideTitle;

  return (
    <SlideContainer
      sx={{
        paddingX: 11,
        paddingTop: 6,
      }}
      id="standalone-disclaimer"
      autoZoomOnPrint={true}
      scaleFactor={90}
      hideFooter
    >
      <Stack p={PRESENTATION_SPACING_UNITS} height="100%">
        <Typography
          sx={(t) => ({ color: t.palette.primary.main })}
          variant="h1"
        >
          {slideTitle}
        </Typography>
        <Box
          sx={{
            columnCount: 2,
            columnGap: theme.spacing(6),
            columnRule: `1px solid ${COLORS.NEUTRAL_GRAY[900]}`,
            height: 800, // height of a slide's body, necessary for the column content to wrap correctly,
          }}
        >
          <Typography
            variant="body1"
            // white-space: pre-wrap is necessary to preserve line breaks
            sx={{ breakInside: 'avoid', whiteSpace: 'pre-wrap' }}
          >
            {legalDisclaimers?.presentationStandaloneDisclaimerBody ?? ''}
          </Typography>
        </Box>
      </Stack>
    </SlideContainer>
  );
}
