import { cx } from '@emotion/css';
import { isString } from 'lodash';

import { Card } from '@/components/layout/Card/Card';
import { getIdSafeString } from '@/utils/formatting/strings';

import {
  TILE_DEFAULT_FONT_SIZE_VARIANT,
  TILE_DEFAULT_VARIANT,
} from './constants';
import { useTileClasses } from './styles';
import { TileProps } from './Tile';
import { TileFooterLabel } from './TileFooterLabel';
import { TileThemedTypographyClamped } from './TileThemedTypography';
import { TileVariant } from './types';

type TileCardProps = TileProps &
  ReturnType<typeof useTileClasses> & {
    variant: Exclude<TileProps['variant'], TileVariant.Pixel>;
  };

export function TileCard({
  variantClasses,
  variant = TILE_DEFAULT_VARIANT,
  fontSizeVariant = TILE_DEFAULT_FONT_SIZE_VARIANT,
  classes,
  isGrouped,
  isSelected,
  hover,
  lineOne,
  lineTwo,
  lineThree,
  notes,
  children,
  slots,
  footerLabel,
}: TileCardProps) {
  let FooterLabel = null;
  if (slots?.FooterLabel) {
    FooterLabel = slots.FooterLabel;
  } else if (footerLabel) {
    FooterLabel = (
      <TileFooterLabel tileVariant={variant} fontSizeVariant={fontSizeVariant}>
        {footerLabel}
      </TileFooterLabel>
    );
  }

  const isGroupedOrSelected = isGrouped || isSelected;

  return (
    <Card
      variant="transparent"
      data-testid={`TileCard-${getIdSafeString(lineOne)}`}
      className={cx(
        variantClasses[variant].root,
        classes.root,
        hover && variantClasses[variant].hoverRoot,
        hover && classes.hoverRoot,
        isGroupedOrSelected && variantClasses[variant].selectedRoot,
        isGroupedOrSelected && classes.selectedRoot
      )}
    >
      <div
        className={cx(
          variantClasses[variant].innerRoot,
          classes.innerRoot,
          isGroupedOrSelected && variantClasses[variant].innerRootSelected,
          isGroupedOrSelected && classes.innerRootSelected
        )}
      >
        <div
          className={cx(
            variantClasses[variant].linesWrapper,
            classes.linesWrapper
          )}
        >
          <TileThemedTypographyClamped
            variant="h5"
            tileVariant={variant}
            fontSizeVariant={fontSizeVariant}
            lines={2}
            className={cx(variantClasses[variant].lineOne, classes.lineOne)}
          >
            {lineOne}
          </TileThemedTypographyClamped>
          {isString(lineTwo) && !!lineTwo ? (
            <TileThemedTypographyClamped
              lines={2}
              variant="h2light"
              tileVariant={variant}
              fontSizeVariant={fontSizeVariant}
              className={cx(variantClasses[variant].lineTwo, classes.lineTwo)}
            >
              {lineTwo}
            </TileThemedTypographyClamped>
          ) : (
            lineTwo
          )}
          {isString(lineThree) && !!lineThree ? (
            <TileThemedTypographyClamped
              lines={2}
              variant="subtitle2"
              tileVariant={variant}
              fontSizeVariant={fontSizeVariant}
              className={cx(
                variantClasses[variant].lineThree,
                classes.lineThree
              )}
            >
              {lineThree}
            </TileThemedTypographyClamped>
          ) : (
            lineThree
          )}
          {notes && (
            <TileThemedTypographyClamped
              variant="subtitle2"
              tileVariant={variant}
              fontSizeVariant={fontSizeVariant}
              className={cx(variantClasses[variant].notes, classes.notes)}
              lines={2}
              noWrap
            >
              {notes}
            </TileThemedTypographyClamped>
          )}
        </div>
      </div>
      {children}
      {FooterLabel && (
        <div
          className={cx(
            variantClasses[variant].footerWrapper,
            classes.footerWrapper,
            isGroupedOrSelected &&
              variantClasses[variant].footerWrapperSelected,
            isGroupedOrSelected && classes.footerWrapperSelected
          )}
        >
          {FooterLabel}
        </div>
      )}
    </Card>
  );
}
