import { nonTrustEntityTaxStatusToApiTypeMap } from '@/modules/entities/EntitySubforms/utils/shared/taxStatus.utils';
import { getTaxStatusValuesFromEntityType } from '@/modules/entities/TaxStatusSubform/TaxStatusSubform.utils';
import { EntityType } from '@/modules/entities/types/EntityType';
import {
  AugmentedCreateEntityInput,
  EntityInEstateStatus,
} from '@/types/schema';

import {
  CREATE_ENTITY_SHORT_FORM_NAMESPACE,
  EntityShortFormShape,
} from '../CreateEntityShortForm.types';
import {
  getCreateEntityInputCommonProps,
  getCreateSubtypeInputCommonProps,
  getSinglePrincipalInput,
  makeCreateAccountInput,
  MakeCreateEntityInputParams,
  makeCreateMultiplePrincipalInputs,
} from './CreateEntityShortForm.common';

export function makeCreateIndividualAccountInput(
  formInput: EntityShortFormShape,
  householdId: string,
  params: MakeCreateEntityInputParams
): AugmentedCreateEntityInput {
  const ownerInput = getSinglePrincipalInput(formInput);
  return {
    create: getCreateEntityInputCommonProps(formInput, householdId, params),
    withIndividualPersonalAccount: {
      create: {
        ...getCreatePersonalAccountInputCommonProps(formInput),
      },
      withDesignerAccount: makeCreateAccountInput(formInput),
      withOwner: ownerInput,
    },
  };
}

export function makeCreateJointAccountInput(
  formInput: EntityShortFormShape,
  householdId: string,
  params: MakeCreateEntityInputParams
): AugmentedCreateEntityInput {
  return {
    create: getCreateEntityInputCommonProps(formInput, householdId, params),
    withJointPersonalAccount: {
      create: {
        ...getCreatePersonalAccountInputCommonProps(formInput),
      },
      withOwners: makeCreateMultiplePrincipalInputs(formInput),
      withDesignerAccount: makeCreateAccountInput(formInput),
    },
  };
}

export function makeCreateRetirementAccountInput(
  formInput: EntityShortFormShape,
  householdId: string,
  params: MakeCreateEntityInputParams
): AugmentedCreateEntityInput {
  return {
    create: getCreateEntityInputCommonProps(formInput, householdId, params),
    withRetirementPersonalAccount: {
      create: {
        ...getCreatePersonalAccountInputCommonProps(formInput),
      },
      withOwner: getSinglePrincipalInput(formInput),
      withDesignerAccount: makeCreateAccountInput(formInput),
    },
  };
}

export function makeCreateCustodialAccountInput(
  formInput: EntityShortFormShape,
  householdId: string,
  params: MakeCreateEntityInputParams
): AugmentedCreateEntityInput {
  const ownerInput = getSinglePrincipalInput(formInput);
  return {
    create: getCreateEntityInputCommonProps(formInput, householdId, params),
    withCustodialPersonalAccount: {
      create: {
        ...getCreatePersonalAccountInputCommonProps(formInput),
      },
      withDesignerAccount: makeCreateAccountInput(formInput),
      withOwner: ownerInput,
    },
  };
}

export function makeCreateQualifiedTuitionAccountInput(
  formInput: EntityShortFormShape,
  householdId: string,
  params: MakeCreateEntityInputParams
): AugmentedCreateEntityInput {
  const ownerInput = getSinglePrincipalInput(formInput);

  return {
    create: getCreateEntityInputCommonProps(formInput, householdId, params),
    withQualifiedTuitionPersonalAccount: {
      create: {
        ...getCreatePersonalAccountInputCommonProps(formInput),
      },
      withDesignerAccount: makeCreateAccountInput(formInput),
      withOwner: ownerInput,
    },
  };
}

export function makeCreateInsuranceAccountInput(
  formInput: EntityShortFormShape,
  householdId: string,
  params: MakeCreateEntityInputParams
): AugmentedCreateEntityInput {
  return {
    create: getCreateEntityInputCommonProps(formInput, householdId, params),
    withInsurancePersonalAccount: {
      create: {
        ...getCreatePersonalAccountInputCommonProps(formInput),
        inEstateStatus: EntityInEstateStatus.InEstate,
      },
      withDesignerAccount: makeCreateAccountInput(formInput),
      withOwners: makeCreateMultiplePrincipalInputs(formInput),
    },
  };
}

function getCreatePersonalAccountInputCommonProps(
  formInput: EntityShortFormShape
) {
  const { nonTrustEntityTaxStatus } = getTaxStatusValuesFromEntityType(
    formInput[CREATE_ENTITY_SHORT_FORM_NAMESPACE].entityType as EntityType
  );

  return {
    ...getCreateSubtypeInputCommonProps(formInput),
    taxStatus: nonTrustEntityTaxStatus
      ? nonTrustEntityTaxStatusToApiTypeMap[nonTrustEntityTaxStatus]
      : undefined,
  };
}
