import Decimal from 'decimal.js';
import { compact, isEmpty, uniq } from 'lodash';
import { useMemo } from 'react';

import { TileSummary } from '@/components/diagrams/components/Tile/TileSummary';
import { TileVariant } from '@/components/diagrams/components/Tile/types';
import { Button } from '@/components/form/baseInputs/Button';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { ROUTE_KEYS } from '@/navigation/constants';
import { sumDecimalJS } from '@/utils/decimalJSUtils';

import { useWaterfallSummaryContext } from '../../contexts/waterfallSummary.context';
import { GetWaterfallSummary_EstateWaterfallFragment } from '../../graphql/GetWaterfallSummary.generated';
import { useShowSummaryTileDetails } from '../../hooks/useShowSummaryTileDetails.hook';
import { WaterfallBarChartSection } from '../EstateWaterfallSummaryBarCharts/hooks/useWaterfallSummaryBarChartSections';
import { TileSection } from './components/TileSection';
import {
  formatCompactValue,
  getSummaryTileNode,
  isGrouped,
} from './EstateWaterfallSummaryTiles.utils';
import { useMiniTableRows } from './hooks/useMiniTableRows.hook';
import { useShowMoreSplit } from './hooks/useShowMoreSplit.hook';

export interface FinalSummarySectionProps {
  waterfall: GetWaterfallSummary_EstateWaterfallFragment;
  section: WaterfallBarChartSection;
  showDetails?: boolean;
}

export const FinalSummarySection = ({
  section,
  waterfall,
}: FinalSummarySectionProps) => {
  const { navigate } = useNavigateToRoute();
  const showSummaryTileDetails = useShowSummaryTileDetails(waterfall.id);
  const {
    visualizationWithProjections: {
      secondDeathTaxSummary,
      firstDeathTaxSummary,
    },
  } = waterfall;
  const householdId = waterfall.household?.id as unknown as string;
  const outOfEstateFamily = section.waterfallSections[1];
  const outOfEstateCharity = section.waterfallSections[2];
  const tax = section.waterfallSections[3];

  const splitOutOfEstateFamily = useShowMoreSplit(outOfEstateFamily.nodes);
  const splitOutOfEstateCharity = useShowMoreSplit(outOfEstateCharity.nodes);

  const stateTaxSummaries = [
    firstDeathTaxSummary,
    secondDeathTaxSummary,
  ].flatMap((taxSummary) => {
    return taxSummary?.stateTax ?? [];
  });

  const stateTaxCodes = uniq(
    stateTaxSummaries.flatMap((t) => t.stateCode ?? [])
  );

  const stateTaxCodesCopy = `State${stateTaxCodes.length > 0 ? ` (${stateTaxCodes.join(', ')})` : ''}`;

  const stateTaxes = stateTaxSummaries.map((t) => t.tax);

  const federalTaxes = [firstDeathTaxSummary, secondDeathTaxSummary]
    .flatMap((taxSummary) => {
      return taxSummary?.federalTax ?? [];
    })
    .map((t) => t.tax);

  const stateTax = sumDecimalJS(stateTaxes);
  const federalTax = sumDecimalJS(federalTaxes);

  const {
    summaryPanel: { openModal, data },
  } = useWaterfallSummaryContext();

  const outOfEstateFamilyRows = useMiniTableRows(splitOutOfEstateFamily);
  const outOfEstateFamilyDetails = (
    <MiniTable rows={outOfEstateFamilyRows} variant="default" />
  );

  const outOfEstateCharityRows = useMiniTableRows(splitOutOfEstateCharity);
  const outOfEstateCharityDetails = (
    <MiniTable rows={outOfEstateCharityRows} variant="default" />
  );

  const taxesRows = useMemo(() => {
    return [
      ...(!isEmpty(stateTaxSummaries)
        ? [
            {
              label: stateTaxCodesCopy,
              value: formatCompactValue(stateTax),
            },
          ]
        : []),
      {
        label: 'Federal',
        value: formatCompactValue(federalTax),
      },
    ];
  }, [stateTaxSummaries, stateTaxCodesCopy, stateTax, federalTax]);

  const taxesDetails = <MiniTable rows={taxesRows} variant="default" />;

  const netToFamilyTitle = 'Net to family';
  const netToCharityTitle = 'Net to charity';
  const taxTitle = 'Taxes';

  return (
    <TileSection label={section.label}>
      {outOfEstateFamily.value > 0 && (
        <TileSummary
          lineOne="Net to family"
          variant={TileVariant.Secondary}
          lineTwo={formatCompactValue(outOfEstateFamily.value)}
          onClick={() =>
            openModal({
              type: 'summaryTileDetails',
              nodes: outOfEstateFamily.nodes.map(getSummaryTileNode),
              subtitle: `Combined value of assets that are distributed to family members`,
              title: netToFamilyTitle,
              total: new Decimal(outOfEstateFamily.value),
              firstPrimaryClientDeathId: waterfall.firstGrantorDeath.id,
              householdId,
              waterfallId: waterfall.id,
              primaryAction: (
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    navigate(
                      ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_BENEFICIARY_REPORTING,
                      {
                        householdId,
                        waterfallId: waterfall.id,
                      }
                    );
                  }}
                >
                  Go to Beneficiaries
                </Button>
              ),
            })
          }
          isGrouped={isGrouped(netToFamilyTitle, data)}
        >
          {showSummaryTileDetails ? outOfEstateFamilyDetails : null}
        </TileSummary>
      )}
      {outOfEstateCharity.value > 0 && (
        <TileSummary
          lineOne="Net to charity"
          variant={TileVariant.Tertiary}
          lineTwo={formatCompactValue(outOfEstateCharity.value)}
          onClick={() =>
            openModal({
              type: 'summaryTileDetails',
              nodes: outOfEstateCharity.nodes.map(getSummaryTileNode),
              subtitle: `Combined value of assets that are distributed to charitable organizations`,
              title: netToCharityTitle,
              total: new Decimal(outOfEstateCharity.value),
              firstPrimaryClientDeathId: waterfall.firstGrantorDeath.id,
              householdId,
              waterfallId: waterfall.id,
            })
          }
          isGrouped={isGrouped(netToCharityTitle, data)}
        >
          {showSummaryTileDetails ? outOfEstateCharityDetails : null}
        </TileSummary>
      )}
      <TileSummary
        lineOne="Taxes"
        variant={TileVariant.Destructive}
        lineTwo={formatCompactValue(tax.value)}
        onClick={() =>
          openModal({
            type: 'summaryTileDetails',
            nodes: compact([
              !isEmpty(stateTaxSummaries) && {
                id: 'state',
                name: stateTaxCodesCopy,
                value: new Decimal(-stateTax),
              },
              {
                id: 'federal',
                name: 'Federal',
                value: new Decimal(-federalTax),
              },
            ]),
            subtitle: `Combined value of federal & state estate taxes and gift taxes`,
            title: taxTitle,
            primaryAction: (
              <Button
                variant="secondary"
                size="sm"
                onClick={() => {
                  navigate(
                    ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_TAX_OVERVIEW,
                    {
                      householdId,
                      waterfallId: waterfall.id,
                    }
                  );
                }}
              >
                Go to Tax overview
              </Button>
            ),
            total: new Decimal(-tax.value),
            firstPrimaryClientDeathId: waterfall.firstGrantorDeath.id,
            householdId,
            waterfallId: waterfall.id,
          })
        }
        isGrouped={isGrouped(taxTitle, data)}
      >
        {showSummaryTileDetails ? taxesDetails : null}
      </TileSummary>
    </TileSection>
  );
};
