// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InferDocumentTypesQueryVariables = Types.Exact<{
  input: Array<Types.InferDocumentTypeInput> | Types.InferDocumentTypeInput;
}>;


export type InferDocumentTypesQuery = { __typename?: 'Query', inferDocumentTypes: Array<{ __typename?: 'InferDocumentTypeOutput', id: string, fileName: string, documentType: Types.DocumentType }> };

export type MultiDocumentUploader_DocumentFragment = { __typename?: 'Document', id: string, name: string, entity?: { __typename?: 'Entity', id: string } | null };

export type HouseholdDocumentsQueryVariables = Types.Exact<{
  householdId: Types.Scalars['ID']['input'];
}>;


export type HouseholdDocumentsQuery = { __typename?: 'Query', documents: { __typename?: 'DocumentConnection', edges?: Array<{ __typename?: 'DocumentEdge', node?: { __typename?: 'Document', id: string, name: string, entity?: { __typename?: 'Entity', id: string } | null } | null } | null> | null } };

export type UploadDocumentsMutationVariables = Types.Exact<{
  createDocumentInputs: Array<Types.CreateDocumentInput> | Types.CreateDocumentInput;
  updateDocumentInputs: Array<Types.AugmentedUpdateDocumentInput> | Types.AugmentedUpdateDocumentInput;
}>;


export type UploadDocumentsMutation = { __typename?: 'Mutation', createDocuments: Array<{ __typename?: 'Document', id: string }>, updateDocuments: Array<{ __typename?: 'Document', id: string, enableAiSuggestions?: boolean | null }> };

export const MultiDocumentUploader_DocumentFragmentDoc = gql`
    fragment MultiDocumentUploader_Document on Document {
  id
  name
  entity {
    id
  }
}
    `;
export const InferDocumentTypesDocument = gql`
    query InferDocumentTypes($input: [InferDocumentTypeInput!]!) {
  inferDocumentTypes(input: $input) {
    id
    fileName
    documentType
  }
}
    `;

/**
 * __useInferDocumentTypesQuery__
 *
 * To run a query within a React component, call `useInferDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInferDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInferDocumentTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInferDocumentTypesQuery(baseOptions: Apollo.QueryHookOptions<InferDocumentTypesQuery, InferDocumentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InferDocumentTypesQuery, InferDocumentTypesQueryVariables>(InferDocumentTypesDocument, options);
      }
export function useInferDocumentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InferDocumentTypesQuery, InferDocumentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InferDocumentTypesQuery, InferDocumentTypesQueryVariables>(InferDocumentTypesDocument, options);
        }
export function useInferDocumentTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InferDocumentTypesQuery, InferDocumentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InferDocumentTypesQuery, InferDocumentTypesQueryVariables>(InferDocumentTypesDocument, options);
        }
export type InferDocumentTypesQueryHookResult = ReturnType<typeof useInferDocumentTypesQuery>;
export type InferDocumentTypesLazyQueryHookResult = ReturnType<typeof useInferDocumentTypesLazyQuery>;
export type InferDocumentTypesSuspenseQueryHookResult = ReturnType<typeof useInferDocumentTypesSuspenseQuery>;
export type InferDocumentTypesQueryResult = Apollo.QueryResult<InferDocumentTypesQuery, InferDocumentTypesQueryVariables>;
export const HouseholdDocumentsDocument = gql`
    query HouseholdDocuments($householdId: ID!) {
  documents(where: {hasHouseholdWith: [{id: $householdId}]}) {
    edges {
      node {
        ...MultiDocumentUploader_Document
      }
    }
  }
}
    ${MultiDocumentUploader_DocumentFragmentDoc}`;

/**
 * __useHouseholdDocumentsQuery__
 *
 * To run a query within a React component, call `useHouseholdDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHouseholdDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHouseholdDocumentsQuery({
 *   variables: {
 *      householdId: // value for 'householdId'
 *   },
 * });
 */
export function useHouseholdDocumentsQuery(baseOptions: Apollo.QueryHookOptions<HouseholdDocumentsQuery, HouseholdDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HouseholdDocumentsQuery, HouseholdDocumentsQueryVariables>(HouseholdDocumentsDocument, options);
      }
export function useHouseholdDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HouseholdDocumentsQuery, HouseholdDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HouseholdDocumentsQuery, HouseholdDocumentsQueryVariables>(HouseholdDocumentsDocument, options);
        }
export function useHouseholdDocumentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HouseholdDocumentsQuery, HouseholdDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HouseholdDocumentsQuery, HouseholdDocumentsQueryVariables>(HouseholdDocumentsDocument, options);
        }
export type HouseholdDocumentsQueryHookResult = ReturnType<typeof useHouseholdDocumentsQuery>;
export type HouseholdDocumentsLazyQueryHookResult = ReturnType<typeof useHouseholdDocumentsLazyQuery>;
export type HouseholdDocumentsSuspenseQueryHookResult = ReturnType<typeof useHouseholdDocumentsSuspenseQuery>;
export type HouseholdDocumentsQueryResult = Apollo.QueryResult<HouseholdDocumentsQuery, HouseholdDocumentsQueryVariables>;
export const UploadDocumentsDocument = gql`
    mutation UploadDocuments($createDocumentInputs: [CreateDocumentInput!]!, $updateDocumentInputs: [AugmentedUpdateDocumentInput!]!) {
  createDocuments(input: $createDocumentInputs) {
    id
  }
  updateDocuments(input: $updateDocumentInputs) {
    id
    enableAiSuggestions
  }
}
    `;
export type UploadDocumentsMutationFn = Apollo.MutationFunction<UploadDocumentsMutation, UploadDocumentsMutationVariables>;

/**
 * __useUploadDocumentsMutation__
 *
 * To run a mutation, you first call `useUploadDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentsMutation, { data, loading, error }] = useUploadDocumentsMutation({
 *   variables: {
 *      createDocumentInputs: // value for 'createDocumentInputs'
 *      updateDocumentInputs: // value for 'updateDocumentInputs'
 *   },
 * });
 */
export function useUploadDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<UploadDocumentsMutation, UploadDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadDocumentsMutation, UploadDocumentsMutationVariables>(UploadDocumentsDocument, options);
      }
export type UploadDocumentsMutationHookResult = ReturnType<typeof useUploadDocumentsMutation>;
export type UploadDocumentsMutationResult = Apollo.MutationResult<UploadDocumentsMutation>;
export type UploadDocumentsMutationOptions = Apollo.BaseMutationOptions<UploadDocumentsMutation, UploadDocumentsMutationVariables>;
export const Operations = {
  Query: {
    InferDocumentTypes: 'InferDocumentTypes' as const,
    HouseholdDocuments: 'HouseholdDocuments' as const
  },
  Mutation: {
    UploadDocuments: 'UploadDocuments' as const
  },
  Fragment: {
    MultiDocumentUploader_Document: 'MultiDocumentUploader_Document' as const
  }
}