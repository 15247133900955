import { useRequiredParam } from '@/hooks/useRequiredParam';
import { HouseholdDetailsAwareRoute } from '@/modules/household/contexts/HouseholdDetailsAwareRoute';
import { TenantPresentationConfigurationProvider } from '@/modules/presentation/context/TenantPresentationConfiguration.provider';
import { useEntityPresentationDetails } from '@/modules/presentation/entities/hooks/useEntityPresentationDetails';
import { SingleEntityPresentationBundle } from '@/modules/presentation/entities/SingleEntityPresentationBundle/SingleEntityPresentationBundle';
import { ALL_ENTITY_SLIDE_TYPES } from '@/modules/presentation/entities/SingleEntityPresentationBundle/SingleEntityPresentationBundle.constants';
import { useSubBrand } from '@/modules/tenant/TenantDetailsContext/useSubBrand';

import { PrintOnlyClientPresentationWatcher } from '../components/PrintClientPresentationShared';

function useEntityPresentationDetailsQuery() {
  const entityId = useRequiredParam('entityId');
  return useEntityPresentationDetails({
    variables: { entityId },
  });
}

function PrintOnlySingleEntityInner(
  queryHookResult: ReturnType<typeof useEntityPresentationDetailsQuery>
) {
  const entityId = useRequiredParam('entityId');

  const { entityDetails, subtypeDetails, dispositionScenariosDetails } =
    queryHookResult;

  useSubBrand(entityDetails?.household.subBrand?.id ?? null);

  return (
    <SingleEntityPresentationBundle
      slideTypes={ALL_ENTITY_SLIDE_TYPES}
      entity={entityDetails}
      subtype={subtypeDetails}
      dispositionScenarios={dispositionScenariosDetails}
      entityId={entityId}
      isSingleEntityPrint
    />
  );
}

export function PrintOnlySingleEntity() {
  const householdId = useRequiredParam('householdId');

  return (
    <TenantPresentationConfigurationProvider>
      <HouseholdDetailsAwareRoute householdId={householdId}>
        <PrintOnlyClientPresentationWatcher<
          ReturnType<typeof useEntityPresentationDetailsQuery>
        >
          Root={PrintOnlySingleEntityInner}
          useQuery={useEntityPresentationDetailsQuery}
        />
      </HouseholdDetailsAwareRoute>
    </TenantPresentationConfigurationProvider>
  );
}
