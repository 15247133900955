import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { GiftExemptionSunsets } from '@/modules/content/tooltipContent/GiftExemptionSunsets';
import { GrowthProfileGrowthType } from '@/types/schema';

import { growthProfileTypeOptions } from './GrowthProfileModal.constants';
import { useGrowthProfileModalContext } from './GrowthProfileModal.context';
import { GrowthProfileModalTable } from './GrowthProfileModal.table';
import {
  GrowthProfileModalFormPaths,
  GrowthProfileModalFormShape,
} from './GrowthProfileModal.types';

export function GrowthProfileModalBody() {
  const { control } = useFormContext<GrowthProfileModalFormShape>();
  const { growthProfileId, isDefault, isDuplicating } =
    useGrowthProfileModalContext();

  const modalTitle = useMemo(() => {
    if (isDuplicating) return 'Duplicate growth profile';
    if (isDefault) return 'Default growth profile';
    if (growthProfileId) return 'Edit growth profile';
    return 'Create growth profile';
  }, [isDefault, growthProfileId, isDuplicating]);

  return (
    <Stack direction="column" spacing={3} pt={3} pb={4}>
      <Card variant="filled-light" sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Typography variant="h1">{modalTitle}</Typography>
          <Box>
            <FormLayoutRow>
              <FormLayoutItem width={12}>
                <FormAwareTextInput<GrowthProfileModalFormShape>
                  control={control}
                  fieldName={
                    'displayName' as const satisfies GrowthProfileModalFormPaths
                  }
                  label="Name"
                  required
                />
              </FormLayoutItem>
            </FormLayoutRow>
            <FormLayoutRow>
              <FormLayoutItem width={3}>
                <FormAwarePercentInput<GrowthProfileModalFormShape>
                  control={control}
                  fieldName={
                    'exemptionGrowthRate' as const satisfies GrowthProfileModalFormPaths
                  }
                  label="Exemption growth rate"
                  required
                  isDecimalJSInput
                  fixedDecimalScale
                  decimalScale={2}
                />
              </FormLayoutItem>
              <FormLayoutItem width={9} sx={{ pt: 4 }}>
                <FormAwareSwitch<GrowthProfileModalFormShape>
                  control={control}
                  fieldName={
                    'willExemptionSunset' as const satisfies GrowthProfileModalFormPaths
                  }
                  label="Exemption sunsets in 2025"
                  labelPosition="right"
                  contextualHelp={<GiftExemptionSunsets />}
                />
              </FormLayoutItem>
            </FormLayoutRow>
          </Box>
          <GrowthTypeCard />
        </Stack>
      </Card>
      <GrowthProfileModalTable />
    </Stack>
  );
}

function GrowthTypeCard() {
  const { control } = useFormContext<GrowthProfileModalFormShape>();
  const { isDefault } = useGrowthProfileModalContext();

  const [growthType] = useWatch({ control, name: ['growthType'] });

  const shouldDisplayGlobalGrowthInput =
    growthType === GrowthProfileGrowthType.GlobalGrowth;

  return (
    <Card variant="filled" sx={{ p: 3 }}>
      <Stack direction="column" spacing={2}>
        <FormAwareRadioGroup<GrowthProfileModalFormShape>
          data-testid="growth-type-radio-group"
          disabled={isDefault}
          control={control}
          fieldName={
            'growthType' as const satisfies GrowthProfileModalFormPaths
          }
          options={growthProfileTypeOptions}
          row={false}
          columnSpacing={2}
        />
        {shouldDisplayGlobalGrowthInput && (
          <FormLayoutRow>
            <FormLayoutItem width={3}>
              <Box pl={3}>
                <FormAwarePercentInput<GrowthProfileModalFormShape>
                  control={control}
                  fieldName={
                    'assetGrowthReturn' as const satisfies GrowthProfileModalFormPaths
                  }
                  label="Global growth rate"
                  required
                  isDecimalJSInput
                  hideLabel
                  fixedDecimalScale
                  decimalScale={2}
                />
              </Box>
            </FormLayoutItem>
          </FormLayoutRow>
        )}
      </Stack>
    </Card>
  );
}
