import { compact, includes, isEmpty } from 'lodash';

import { AISuggestionsMatcherVariant } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import { ArrayFieldWithID } from '@/modules/aiSuggestions/AISuggestionsMatcher/hooks/useUpdateSubformArrayField';
import { getConfigsForAISuggestionsMatcherVariant } from '@/modules/aiSuggestions/AISuggestionsMatcher/matcherVariant.configs';
import { EntitySuggestionsContext } from '@/modules/aiSuggestions/context/EntitySuggestions.context';
import { EntityDetailsView } from '@/modules/entities/components/EntityDetails';
import {
  BusinessEntityDetailsTabs,
  NonTrustCharitableEntityDetailsTabs,
  PersonalAccountDetailsTabs,
  TrustDetailsTabs,
} from '@/modules/entities/details/entityDetails.types';
import {
  ENTITY_SECTION_TO_SUBFORM_MAP,
  ENTITY_SUBFORM_TO_SECTION_MAP,
  NO_ENTITY_SECTIONS,
} from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.constants';
import {
  CombinedSupportedSubformFieldTypes,
  EditEntityRichSection,
  EditEntitySection,
} from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import {
  INSURANCE_POLICY_ENTITY_TYPES,
  NON_TRUST_CHARITABLE_ENTITY_TYPES,
  PERSONAL_FAMILY_ACCOUNT_TYPES,
} from '@/modules/entities/entities.constants';
import {
  EntitySubformNamespaceType,
  SUBFORM_TYPE_COPY_MAP,
} from '@/modules/entities/EntitySubforms/entitySubformConfigs';
import {
  makeUpdateEntityInput,
  MakeUpdateEntityInputParams,
} from '@/modules/entities/EntitySubforms/updateUtils';
import { EntityType } from '@/modules/entities/types/EntityType';
import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';
import {
  AiSuggestionKind,
  AsyncJobKind,
  AugmentedUpdateEntityInput,
} from '@/types/schema';
import { UnreachableError, ValidationError } from '@/utils/errors';

export function getSectionsForEntityType(
  entityType: EntityType
): EditEntitySection[] {
  switch (entityType) {
    // TRUSTS
    case 'revocable-trust':
    case 'irrevocable-trust':
      return [
        EditEntitySection.BASIC_INFORMATION,
        EditEntitySection.TRUSTEES,
        EditEntitySection.TAX_STATUS,
        EditEntitySection.BENEFICIARIES,
        EditEntitySection.INSURANCE_POLICIES,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2,
      ];
    case 'slat':
      return [
        EditEntitySection.BASIC_INFORMATION,
        EditEntitySection.TRUSTEES,
        EditEntitySection.TAX_STATUS,
        EditEntitySection.LIFETIME_BENEFICIARIES,
        EditEntitySection.INSURANCE_POLICIES,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2,
      ];
    case 'ilit':
      return [
        EditEntitySection.BASIC_INFORMATION,
        EditEntitySection.TRUSTEES,
        EditEntitySection.TAX_STATUS,
        EditEntitySection.INSURANCE_POLICIES,
        EditEntitySection.BENEFICIARIES,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2,
      ];
    case 'qprt':
      return [
        EditEntitySection.BASIC_INFORMATION,
        EditEntitySection.TRUSTEES,
        EditEntitySection.TAX_STATUS,
        EditEntitySection.BENEFICIARIES,
        EditEntitySection.INITIAL_FUNDING_ASSETS,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2,
      ];
    case 'grat':
      return [
        EditEntitySection.BASIC_INFORMATION,
        EditEntitySection.TAX_STATUS,
        EditEntitySection.TRUSTEES,
        EditEntitySection.BENEFICIARIES,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2,
      ];
    case 'clt':
      return [
        EditEntitySection.BASIC_INFORMATION,
        EditEntitySection.TRUSTEES,
        EditEntitySection.TAX_STATUS,
        EditEntitySection.LEAD_BENEFICIARIES,
        EditEntitySection.REMAINDER_BENEFICIARIES,
        EditEntitySection.INSURANCE_POLICIES,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2,
      ];
    case 'crt':
      return [
        EditEntitySection.BASIC_INFORMATION,
        EditEntitySection.TRUSTEES,
        EditEntitySection.INCOME_BENEFICIARIES,
        EditEntitySection.REMAINDER_BENEFICIARIES,
        EditEntitySection.INSURANCE_POLICIES,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2,
      ];
    // BUSINESS ENTITIES
    case 'sole-proprietorship':
    case 'c-corp':
    case 's-corp':
    case 'llc':
    case 'lp':
    case 'gp': {
      // not all business entities have insurance policies
      const shouldShowInsurancePolicies = includes(
        INSURANCE_POLICY_ENTITY_TYPES,
        entityType
      );

      return compact([
        EditEntitySection.BASIC_INFORMATION,
        EditEntitySection.BUSINESS_ENTITY_TAX_STATUS,
        EditEntitySection.BUSINESS_ENTITY_DETAILS,
        shouldShowInsurancePolicies && EditEntitySection.INSURANCE_POLICIES,
      ]);
    }
    // NON-TRUST CHARITABLE ENTITIES
    case 'private-foundation':
    case 'daf':
      return [
        EditEntitySection.BASIC_INFORMATION,
        EditEntitySection.BENEFICIARIES,
        EditEntitySection.INSURANCE_POLICIES,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2,
      ];
    // PERSONAL ACCOUNTS
    case 'individual-account':
    case 'joint-account':
    case 'custodial-account':
    case 'qualified-tuition-account':
      return [
        EditEntitySection.BASIC_INFORMATION,
        EditEntitySection.TAX_STATUS,
        EditEntitySection.BENEFICIARIES,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2,
      ];
    case 'retirement-account':
      return [
        EditEntitySection.BASIC_INFORMATION,
        EditEntitySection.BENEFICIARIES,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2,
      ];
    case 'insurance-account':
      return [
        EditEntitySection.BASIC_INFORMATION,
        EditEntitySection.INSURANCE_POLICIES,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2,
      ];
    default:
      throw new UnreachableError({
        case: entityType,
        message: `Unreachable entityType: ${entityType} in getSectionsForEntityType`,
      });
  }
}

export function getSectionForEntitySubformType(
  subformType: EntitySubformNamespaceType
): EditEntitySection {
  if (!ENTITY_SUBFORM_TO_SECTION_MAP[subformType]) {
    // We'll add more cases here as we implement support for more entity types
    // that have additional sections.
    throw new Error(`Unsupported subform type: ${subformType}`);
  }

  return ENTITY_SUBFORM_TO_SECTION_MAP[subformType];
}

export function getEntitySubformTypeForSection(
  section: EditEntitySection
): EntitySubformNamespaceType {
  const subformType = ENTITY_SECTION_TO_SUBFORM_MAP[section];
  if (!subformType) {
    throw new Error(`Unsupported entity section: ${section}`);
  }

  if (subformType === NO_ENTITY_SECTIONS) {
    // This is type protection to make sure we don't call this in a dispositive provisions scenario.
    throw new Error(`${section} does not have a corresponding entity subform`);
  }

  return subformType;
}

export function getLabelForSection(
  section: EditEntitySection,
  primaryClients: ContextPrimaryClient[],
  entityType: EntityType
): string | null {
  if (section === EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1) {
    if (!primaryClients[0]) {
      throw new Error('Invalid state: missing primary client');
    }
    return `Dispositions (${primaryClients[0].firstName} dies first)`;
  }

  if (section === EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2) {
    if (!primaryClients[1]) {
      return null;
    }
    return `Dispositions (${primaryClients[1].firstName} dies first)`;
  }

  const subformType = getEntitySubformTypeForSection(section);

  if (
    subformType === 'beneficiariesSubform' &&
    (entityType === 'grat' || entityType === 'qprt')
  ) {
    // Special-case handling for GRAT and QPRT Beneficiaries section.
    return 'Remainder beneficiaries';
  }

  if (subformType === 'businessEntityDetailsSubform') {
    // Special-case handling for business entities "details" subform.
    // Which are the ownership and key people fields.
    // Because SUBFORM_TYPE_COPY_MAP contains the label for the old long-form trowser.
    return 'Ownership & key people';
  }

  if (subformType === 'trustDetailsSubform') {
    // this is necessary while we're still in the process of migrating to the new
    // form interface; in the trust details subform should still be called "Trust details"
    // in the context where the new form interface is not yet implemented
    return 'Trustees';
  }

  return SUBFORM_TYPE_COPY_MAP[subformType];
}

export function getSectionOptions(
  entityType: EntityType | null,
  primaryClients: ContextPrimaryClient[] | null,
  shownSectionOptions?: EditEntitySection[] | null
): EditEntityRichSection[] {
  // For typesafety and on initial render. We should always have an entityType and primaryClients.
  if (isEmpty(primaryClients) || !primaryClients || !entityType) return [];

  const validEntitySections = getSectionsForEntityType(entityType);
  const sectionsToRender = shownSectionOptions
    ? shownSectionOptions.filter((section) =>
        validEntitySections.includes(section)
      )
    : validEntitySections;

  return sectionsToRender.flatMap((section) => {
    const label = getLabelForSection(section, primaryClients, entityType);
    if (!label) return [];
    return {
      label,
      section,
    };
  });
}

/**
 * This function returns corresponding edit entity sections for the active tab
 * of the entity details page. This is called from getInitialSectionOptions()
 * to know the section(s) to show when the user clicks the "edit" button to
 * open the edit entity split screen modal. If there's multiple corresponding
 * sections, the "edit" button will be a dropdown button. If there's only 1
 * section, we show a full-width button.
 *
 * @param activeTab
 * @param entityType
 */
export function getSectionForEntityDetailsActiveTab(
  activeTab: EntityDetailsView,
  entityType: EntityType
): EditEntitySection[] {
  switch (activeTab) {
    // TRUSTS TABS
    case TrustDetailsTabs.SUMMARY:
      switch (entityType) {
        case 'qprt':
          return [
            EditEntitySection.BASIC_INFORMATION,
            EditEntitySection.TAX_STATUS,
            EditEntitySection.INITIAL_FUNDING_ASSETS,
          ];
        case 'crt':
          return [EditEntitySection.BASIC_INFORMATION];
        default:
          return [
            EditEntitySection.BASIC_INFORMATION,
            EditEntitySection.TAX_STATUS,
          ];
      }
    case TrustDetailsTabs.TRUSTEES:
      return [EditEntitySection.TRUSTEES];
    case TrustDetailsTabs.BENEFICIARIES:
      switch (entityType) {
        case 'slat':
          return [EditEntitySection.LIFETIME_BENEFICIARIES];
        case 'clt':
          return [EditEntitySection.LEAD_BENEFICIARIES];
        case 'crt':
          return [EditEntitySection.INCOME_BENEFICIARIES];
        default:
          return [EditEntitySection.BENEFICIARIES];
      }
    case TrustDetailsTabs.DISPOSITIONS:
      return [
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1,
        EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2,
      ];
    case TrustDetailsTabs.POLICIES:
    case BusinessEntityDetailsTabs.POLICIES:
    case NonTrustCharitableEntityDetailsTabs.POLICIES:
    case PersonalAccountDetailsTabs.POLICIES:
      return [EditEntitySection.INSURANCE_POLICIES];
    // BUSINESS ENTITIES TABS
    case BusinessEntityDetailsTabs.SUMMARY:
      return [
        EditEntitySection.BASIC_INFORMATION,
        EditEntitySection.BUSINESS_ENTITY_TAX_STATUS,
      ];
    case BusinessEntityDetailsTabs.KEY_PEOPLE:
    case BusinessEntityDetailsTabs.OWNERSHIP:
      return [EditEntitySection.BUSINESS_ENTITY_DETAILS];
    // NON-TRUST CHARITABLE ENTITY TABS
    case NonTrustCharitableEntityDetailsTabs.SUMMARY:
      return [EditEntitySection.BASIC_INFORMATION];
    case NonTrustCharitableEntityDetailsTabs.BENEFICIARIES:
      return [EditEntitySection.BENEFICIARIES];
    case NonTrustCharitableEntityDetailsTabs.DISPOSITIONS:
      return [EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1];
    // PERSONAL ACCOUNTS TABS
    case PersonalAccountDetailsTabs.SUMMARY:
      switch (entityType) {
        case 'retirement-account':
        case 'insurance-account':
          return [EditEntitySection.BASIC_INFORMATION];
        default:
          return [
            EditEntitySection.BASIC_INFORMATION,
            EditEntitySection.TAX_STATUS,
          ];
      }
    case PersonalAccountDetailsTabs.BENEFICIARIES:
      return [EditEntitySection.BENEFICIARIES];
    case PersonalAccountDetailsTabs.DISPOSITIONS:
      return [EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1];
    default:
      throw new UnreachableError({
        case: activeTab,
        message: `Unreachable activeTab: ${activeTab} in getSectionForEntityDetailsActiveTab`,
      });
  }
}

/**
 * This wraps the call to getSectionForEntityDetailsActiveTab() to ensure that
 * we don't show the "edit" button on the Dispositions tab, by returning undefined.
 *
 * @param activeTab
 * @param entityType
 */
export function getInitialSectionOptions(
  activeTab: EntityDetailsView | '',
  entityType: EntityType | null
): EditEntitySection[] | undefined {
  if (
    !entityType ||
    !activeTab ||
    activeTab === PersonalAccountDetailsTabs.DISPOSITIONS ||
    activeTab === NonTrustCharitableEntityDetailsTabs.DISPOSITIONS
  ) {
    return undefined;
  }
  if (
    activeTab === TrustDetailsTabs.DISPOSITIONS &&
    !isFeatureFlagEnabled('ai_dispositive_provisions')
  ) {
    return undefined;
  }

  const sections = getSectionForEntityDetailsActiveTab(activeTab, entityType);

  return isEmpty(sections) ? undefined : sections;
}

/**
 * This is the tab of the entity details page to navigate to after saving and closing the modal.
 * @param entityType
 * @param section the section that was last saved and closed on
 */
export function getActiveTabForSection(
  entityType: EntityType,
  section: EditEntitySection
): EntityDetailsView {
  switch (section) {
    case EditEntitySection.BASIC_INFORMATION:
      if (NON_TRUST_CHARITABLE_ENTITY_TYPES.includes(entityType)) {
        return NonTrustCharitableEntityDetailsTabs.SUMMARY;
      }
      if (PERSONAL_FAMILY_ACCOUNT_TYPES.includes(entityType)) {
        return PersonalAccountDetailsTabs.SUMMARY;
      }
      return TrustDetailsTabs.SUMMARY;
    case EditEntitySection.BENEFICIARIES:
      if (NON_TRUST_CHARITABLE_ENTITY_TYPES.includes(entityType)) {
        return NonTrustCharitableEntityDetailsTabs.BENEFICIARIES;
      }
      if (PERSONAL_FAMILY_ACCOUNT_TYPES.includes(entityType)) {
        return PersonalAccountDetailsTabs.BENEFICIARIES;
      }
      return TrustDetailsTabs.BENEFICIARIES;
    case EditEntitySection.TAX_STATUS:
      if (NON_TRUST_CHARITABLE_ENTITY_TYPES.includes(entityType)) {
        return NonTrustCharitableEntityDetailsTabs.SUMMARY;
      }
      if (PERSONAL_FAMILY_ACCOUNT_TYPES.includes(entityType)) {
        return PersonalAccountDetailsTabs.SUMMARY;
      }
      return TrustDetailsTabs.SUMMARY;
    case EditEntitySection.TRUSTEES:
      return TrustDetailsTabs.TRUSTEES;
    case EditEntitySection.INSURANCE_POLICIES:
      return TrustDetailsTabs.POLICIES;
    case EditEntitySection.INITIAL_FUNDING_ASSETS:
    case EditEntitySection.ANNUITY_PAYMENTS:
      return TrustDetailsTabs.SUMMARY;
    case EditEntitySection.LIFETIME_BENEFICIARIES:
    case EditEntitySection.REMAINDER_BENEFICIARIES:
    case EditEntitySection.INCOME_BENEFICIARIES:
    case EditEntitySection.LEAD_BENEFICIARIES:
      return TrustDetailsTabs.BENEFICIARIES;
    case EditEntitySection.BUSINESS_ENTITY_TAX_STATUS:
      return BusinessEntityDetailsTabs.SUMMARY;
    case EditEntitySection.BUSINESS_ENTITY_DETAILS:
      return BusinessEntityDetailsTabs.KEY_PEOPLE;
    case EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1:
    case EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2:
      return TrustDetailsTabs.DISPOSITIONS;
  }
}

/**
 * Based on the tab of the Entity Details Page, these are the async job kinds
 * that we should track for any pending suggestion jobs.
 *
 * @param activeTab
 */
export function getAsyncJobKindToTrackForActiveTab(
  activeTab: EntityDetailsView | ''
): AsyncJobKind | null {
  switch (activeTab) {
    case TrustDetailsTabs.SUMMARY:
    case TrustDetailsTabs.TRUSTEES:
    case TrustDetailsTabs.BENEFICIARIES:
    case TrustDetailsTabs.POLICIES:
    case BusinessEntityDetailsTabs.SUMMARY:
    case BusinessEntityDetailsTabs.OWNERSHIP:
    case BusinessEntityDetailsTabs.KEY_PEOPLE:
    case BusinessEntityDetailsTabs.POLICIES:
    case NonTrustCharitableEntityDetailsTabs.SUMMARY:
    case NonTrustCharitableEntityDetailsTabs.BENEFICIARIES:
    case NonTrustCharitableEntityDetailsTabs.POLICIES:
    case PersonalAccountDetailsTabs.SUMMARY:
    case PersonalAccountDetailsTabs.BENEFICIARIES:
    case PersonalAccountDetailsTabs.POLICIES:
      return AsyncJobKind.DocumentInferenceAiSuggestion;
    case TrustDetailsTabs.DISPOSITIONS:
      return AsyncJobKind.AiDp;
    case NonTrustCharitableEntityDetailsTabs.DISPOSITIONS:
    case PersonalAccountDetailsTabs.DISPOSITIONS:
    default:
      return null;
  }
}

export function getGrantorIdForSection(
  section: EditEntitySection,
  primaryClients: ContextPrimaryClient[] | null
): string {
  // this should never happen in a real scenario, doing it for typesafety
  if (!primaryClients) return '';
  switch (section) {
    case EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1:
      return primaryClients[0]?.id ?? '';
    case EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2:
      return primaryClients[1]?.id ?? '';
    default:
      throw new Error(
        `getGrantorIdForSection called with invalid section: ${section}. only DP sections are supported`
      );
  }
}

export function getUpdateInputFromFormValuesOrError(
  params: MakeUpdateEntityInputParams,
  values: CombinedSupportedSubformFieldTypes
): {
  input?: AugmentedUpdateEntityInput;
  validationError?: ValidationError;
  error?: Error;
} {
  try {
    const updateEntityInput = makeUpdateEntityInput(values, params);
    return { input: updateEntityInput };
  } catch (err) {
    if (err instanceof ValidationError) {
      // Validation error to let the user know what to fix in the form.
      return { validationError: err };
    }

    // Some unexpected error (for devs).
    return { error: err as Error };
  }
}

export function getMatcherVariantForEditEntitySection(
  entityType: EntityType | null | undefined,
  editEntitySection: EditEntitySection
): AISuggestionsMatcherVariant | undefined {
  switch (editEntitySection) {
    case EditEntitySection.BASIC_INFORMATION:
      if (entityType === 'slat') {
        return AISuggestionsMatcherVariant.SINGLE_GRANTOR;
      }
      return AISuggestionsMatcherVariant.GRANTORS;
    case EditEntitySection.TRUSTEES:
      return AISuggestionsMatcherVariant.TRUSTEES;
    case EditEntitySection.BENEFICIARIES:
      return AISuggestionsMatcherVariant.BENEFICIARIES_V2;
    case EditEntitySection.LIFETIME_BENEFICIARIES:
      return AISuggestionsMatcherVariant.BENEFICIARIES_V2;
    case EditEntitySection.BUSINESS_ENTITY_DETAILS:
      return AISuggestionsMatcherVariant.BUSINESS_PEOPLE;
    default:
      // Other sections don't require matching AI suggestions with existing records.
      return undefined;
  }
}

/**
 * Determines if a section has suggestions that are not accepted or rejected.
 *
 * @returns
 * @param section
 * @param suggestionsByKind
 */
export function getSectionHasUnacknowledgedSuggestions(
  section: EditEntitySection,
  suggestionsByKind: EntitySuggestionsContext['suggestionsByKind']
): boolean {
  return getEditEntitySectionAiSuggestionKinds(section).some((kind) => {
    const suggestions = (suggestionsByKind[kind] || []).filter((sugg) => {
      return sugg.acceptanceStatus === null;
    });
    return !isEmpty(suggestions);
  });
}

/**
 * Returns the AI suggestion kinds that are on the given EditEntitySection.
 *
 * @param section
 */
export function getEditEntitySectionAiSuggestionKinds(
  section: EditEntitySection
): AiSuggestionKind[] {
  switch (section) {
    case EditEntitySection.BASIC_INFORMATION:
      return [
        AiSuggestionKind.EffectiveDate,
        AiSuggestionKind.JurisdictionStateCode,
        AiSuggestionKind.LegalName,
        AiSuggestionKind.Principal,
        AiSuggestionKind.DoingBusinessAsName,
        AiSuggestionKind.FincenId,
      ];
    case EditEntitySection.TRUSTEES:
      return [AiSuggestionKind.Trustee, AiSuggestionKind.TrustAdvisor];
    case EditEntitySection.TAX_STATUS:
      return [AiSuggestionKind.GstStatus];
    case EditEntitySection.BENEFICIARIES:
    case EditEntitySection.LIFETIME_BENEFICIARIES:
    case EditEntitySection.REMAINDER_BENEFICIARIES:
      return [AiSuggestionKind.BeneficiaryV2];
    case EditEntitySection.INSURANCE_POLICIES:
      return [AiSuggestionKind.CrummeyWithdrawalPeriodDays];
    case EditEntitySection.BUSINESS_ENTITY_DETAILS:
      return [
        AiSuggestionKind.BeneficialOwner,
        AiSuggestionKind.BeneficialOwnersAsOfDate,
        AiSuggestionKind.KeyPerson,
      ];
    case EditEntitySection.BUSINESS_ENTITY_TAX_STATUS:
      return [AiSuggestionKind.TaxId, AiSuggestionKind.BusinessEntityTaxStatus];
    case EditEntitySection.INCOME_BENEFICIARIES:
    case EditEntitySection.LEAD_BENEFICIARIES:
    case EditEntitySection.INITIAL_FUNDING_ASSETS:
    case EditEntitySection.ANNUITY_PAYMENTS:
    case EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1:
      return isFeatureFlagEnabled('ai_dispositive_provisions')
        ? [AiSuggestionKind.DpRecipientGrantor_1DiesFirst]
        : [];
    case EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2:
      return isFeatureFlagEnabled('ai_dispositive_provisions')
        ? [AiSuggestionKind.DpRecipientGrantor_2DiesFirst]
        : [];
    default:
      return [];
  }
}

/**
 * Returns the array fields that we need to check for duplicates, before we can
 * submit the given section form.
 *
 * @param entityType
 * @param section
 */
export function getArrayFieldsWithIDForSection(
  entityType: EntityType | null | undefined,
  section: EditEntitySection
): ArrayFieldWithID[] {
  const suggestionsMatcherVariant = getMatcherVariantForEditEntitySection(
    entityType,
    section
  );

  if (!suggestionsMatcherVariant) {
    return [];
  }

  const configs = getConfigsForAISuggestionsMatcherVariant(
    entityType,
    suggestionsMatcherVariant
  );
  if (!configs) {
    return [];
  }

  return configs.subformArrayFieldUpdateProps.map((config) => ({
    arrayFieldFullPath: config.arrayFieldFullPath,
    idField: config.idField,
  }));
}
