import { BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE as BUSINESS_DETAILS_NAMESPACE } from '@/modules/entities/BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.types';
import { makeBusinessEntityOwnershipStakeInputs } from '@/modules/entities/EntitySubforms/utils/entities/businessEntities.utils';
import {
  AugmentedCreateEntityInput,
  AugmentedCreateSoleProprietorshipBusinessEntityInput,
} from '@/types/schema';

import { EntityShortFormShape } from '../CreateEntityShortForm.types';
import {
  getCreateEntityInputCommonProps,
  getCreateSubtypeInputCommonProps,
  makeCreateAccountInput,
  MakeCreateEntityInputParams,
} from './CreateEntityShortForm.common';

function getCreateBusinessEntityInputCommonProperties(
  formInput: EntityShortFormShape
): Partial<AugmentedCreateEntityInput> {
  return {
    withOwnedByStakes: makeBusinessEntityOwnershipStakeInputs(formInput),
  };
}

export function makeCreateSoleProprietorshipInput(
  formInput: EntityShortFormShape,
  householdId: string,
  params: MakeCreateEntityInputParams
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId, params),
    },
    ...getCreateBusinessEntityInputCommonProperties(formInput),
    withSoleProprietorshipBusinessEntity: makeBusinessEntityInput(formInput),
  };

  return input;
}

export function makeCreateLLCInput(
  formInput: EntityShortFormShape,
  householdId: string,
  params: MakeCreateEntityInputParams
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId, params),
    },
    ...getCreateBusinessEntityInputCommonProperties(formInput),
    withLlcBusinessEntity: makeBusinessEntityInput(formInput),
  };

  return input;
}
export function makeCreateCCorpInput(
  formInput: EntityShortFormShape,
  householdId: string,
  params: MakeCreateEntityInputParams
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId, params),
    },
    ...getCreateBusinessEntityInputCommonProperties(formInput),
    withCcorpBusinessEntity: makeBusinessEntityInput(formInput),
  };

  return input;
}
export function makeCreateSCorpInput(
  formInput: EntityShortFormShape,
  householdId: string,
  params: MakeCreateEntityInputParams
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId, params),
    },
    ...getCreateBusinessEntityInputCommonProperties(formInput),
    withScorpBusinessEntity: makeBusinessEntityInput(formInput),
  };

  return input;
}
export function makeCreateGPInput(
  formInput: EntityShortFormShape,
  householdId: string,
  params: MakeCreateEntityInputParams
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId, params),
    },
    ...getCreateBusinessEntityInputCommonProperties(formInput),
    withGpBusinessEntity: makeBusinessEntityInput(formInput),
  };

  return input;
}
export function makeCreateLPInput(
  formInput: EntityShortFormShape,
  householdId: string,
  params: MakeCreateEntityInputParams
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId, params),
    },
    ...getCreateBusinessEntityInputCommonProperties(formInput),
    withLpBusinessEntity: makeBusinessEntityInput(formInput),
  };

  return input;
}

function makeBusinessEntityInput(
  formInput: EntityShortFormShape
): AugmentedCreateSoleProprietorshipBusinessEntityInput {
  return {
    create: {
      ...getCreateSubtypeInputCommonProps(formInput),
      ownershipAsOfDate:
        formInput[BUSINESS_DETAILS_NAMESPACE].ownershipAsOfDate,
    },
    withDesignerAccount: makeCreateAccountInput(formInput),
  };
}
