import { PresentationBundleKind, PresentationPageKind } from '@/types/schema';

export const PAGE_TO_DISPLAY_NAME: Record<PresentationPageKind, string> = {
  [PresentationPageKind.CoverPage]: 'Cover page', // single page type
  [PresentationPageKind.CustomPage]: 'Custom',
  [PresentationPageKind.TableOfContentsPage]: 'Table of contents',
  [PresentationPageKind.DocumentSummaryPage]: 'Document summary', // single page type
  [PresentationPageKind.ProfessionalTeamPage]: 'Professional team',
  // [PresentationPageKind.ValueCreationPage]: 'Value creation',
  [PresentationPageKind.WaterfallAssumptionsPage]: 'Assumptions',
  [PresentationPageKind.WaterfallSummaryPage]: 'Summary',
  [PresentationPageKind.WaterfallDiagramPage]: 'Diagram',
  [PresentationPageKind.WaterfallBeneficiariesPage]: 'Beneficiaries',
  [PresentationPageKind.WaterfallTaxOverviewPage]: 'Tax overview',
  [PresentationPageKind.EntitiesSummaryPage]: 'Summary',
  [PresentationPageKind.EntitiesDiagramPage]: 'Diagram',
  [PresentationPageKind.EntityBeneficiariesAndDispositionsPage]:
    'Beneficiaries and dispositions',
  [PresentationPageKind.EntitySummaryPage]: 'Entity summary',
  [PresentationPageKind.BalanceSheetPage]: 'Balance sheet',
  [PresentationPageKind.StandaloneDisclaimerPage]: 'Disclaimer',
};

export const BUNDLE_TO_DISPLAY_NAME: Record<PresentationBundleKind, string> = {
  [PresentationBundleKind.TableOfContentsBundle]: 'Table of contents',
  [PresentationBundleKind.BalanceSheetBundle]: 'Balance sheet',
  [PresentationBundleKind.EntitiesOverviewBundle]: 'Entities',
  [PresentationBundleKind.WaterfallOverviewBundle]: 'Waterfall',
  [PresentationBundleKind.EntitySummaryBundle]: 'Entity',
  [PresentationBundleKind.CustomPageBundle]: 'Custom',
  [PresentationBundleKind.CoverSlideBundle]: 'Cover slide',
  [PresentationBundleKind.ProfessionalTeamBundle]: 'Professional team',
  [PresentationBundleKind.StandaloneDisclaimerBundle]: 'Disclaimer',
};

/**
 * Bundles in this list should not display child pages in the list;
 * however, they can be dragged into another parent.
 */
const SINGLE_PAGE_BUNDLES: PresentationBundleKind[] = [
  PresentationBundleKind.CoverSlideBundle,
  PresentationBundleKind.CustomPageBundle,
  PresentationBundleKind.TableOfContentsBundle,
  PresentationBundleKind.BalanceSheetBundle,
  PresentationBundleKind.EntitySummaryBundle,
  PresentationBundleKind.EntitiesOverviewBundle,
  PresentationBundleKind.ProfessionalTeamBundle,
  PresentationBundleKind.StandaloneDisclaimerBundle,
];

export function isSinglePageBundle(
  type: PresentationBundleKind | PresentationPageKind | string | undefined
): boolean {
  return SINGLE_PAGE_BUNDLES.includes(type as PresentationBundleKind);
}

const FREE_MOVEMENT_PAGE_TYPES: (
  | PresentationBundleKind
  | PresentationPageKind
)[] = [
  PresentationBundleKind.CustomPageBundle,
  PresentationPageKind.CustomPage,
];

export function isFreeMovementPageType(
  type: PresentationBundleKind | PresentationPageKind | string | undefined
): boolean {
  return FREE_MOVEMENT_PAGE_TYPES.includes(type as PresentationBundleKind);
}
