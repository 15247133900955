import { Node } from '@/components/diagrams/FlowChart';

import { EntityDiagramState } from '../types';

export function applyHighlightIdsToState(
  state: EntityDiagramState
): EntityDiagramState {
  const nodes = state.nodes.map((n): Node => {
    if (n.type !== 'tile') return n;
    return { ...n, data: { ...n.data, hover: false } };
  });

  const edges = state.edges.map((e) => {
    if (e.type !== 'arrow') return e;

    const highlight = (() => {
      if (state.presentationMode) {
        return false;
      }

      return state.highlightedIds.has(e.id);
    })();
    return {
      ...e,
      data: { ...e.data, highlight, hideLabel: !highlight },
    };
  });

  return { ...state, nodes, edges };
}
