import { Stack, Typography, useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import { useState } from 'react';

import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';
import { ScenarioSummary } from '@/modules/proposal/share/ScenarioSummary';
import { getBeneficiariesFromProposal } from '@/modules/proposal/utils';
import { useProposalYearProjectionOptionsQuery } from '@/pages/proposal/Scenarios/graphql/ProposalYearProjectionOptions.generated';

import { Slide } from '../hooks/usePresentation';
import { useQuery } from '../hooks/usePresentation';
import { SlideContainer } from './SlideContainer';

interface Props {
  proposal: ProposalFragment;
  currentSlide: Slide | null;
  timeHorizonIdx?: number;
}

export function ScenarioDetail({
  proposal,
  currentSlide,
  timeHorizonIdx = 0,
}: Props) {
  const query = useQuery();
  const theme = useTheme();
  const viewOnly = useViewOnly();
  const { createErrorFeedback } = useFeedback();

  const [selectedYearOptionIndex, setSelectedYearOptionIndex] =
    useState<number>(timeHorizonIdx);

  const proposalScenarioId = viewOnly
    ? currentSlide?.params.proposalScenario
    : query.get('ps');
  const entityId = viewOnly ? currentSlide?.params.entity : query.get('entity');

  const { data: proposalYearProjectionOptionsData } =
    useProposalYearProjectionOptionsQuery({
      variables: {
        entityId: entityId ?? '',
      },
      onError: createErrorFeedback(
        "We weren't able to fetch this scenario. Please refresh the page and try again."
      ),

      skip: !entityId,
    });

  if (!proposalYearProjectionOptionsData) {
    return null;
  }

  if (!entityId) {
    return null;
  }

  const entityProposal = proposal.entityProposals?.find(
    (entityProposal) => entityProposal.entity.id === entityId
  );

  const proposalScenario = entityProposal?.proposalScenarios?.find(
    (proposalScenario) => proposalScenario.id === proposalScenarioId
  );

  const yearOptions =
    proposalYearProjectionOptionsData.proposalYearProjectionOptionsV2;

  if (!yearOptions) {
    return null;
  }

  const proposalYearProjectionOptions = yearOptions.map((option) => ({
    value: option.value.toString(),
    valueDecimal: new Decimal(option.value),
    display: `${option.display} yr`,
  }));

  if (!entityProposal) {
    throw new Error(`No entityProposal found for entityId ${entityId}`);
  }

  const { entity, proposalScenarios, taxDrag, assetValuationProjectionType } =
    entityProposal;

  const scenarioIdx =
    proposalScenarios?.findIndex((scenario) => {
      return scenario.id === proposalScenarioId;
    }) ?? -1;

  const beneficiaries = getBeneficiariesFromProposal(proposal);

  if (!proposalScenario?.gratProposalScenario) {
    return null;
  }

  return (
    <SlideContainer
      sx={{
        paddingX: 11,
        paddingTop: 6,
      }}
      autoZoomOnPrint={true}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack>
          <Typography variant="h1" color={theme.palette.primary.main}>
            {entity.gratTrust?.displayName ?? ''}
          </Typography>
          <Typography variant="subtitle1">{`Year ${proposalYearProjectionOptions[selectedYearOptionIndex]?.value}`}</Typography>
        </Stack>

        <Stack width={theme.spacing(36)}>
          <ButtonGroup
            onChange={(_, value) => {
              const idx = proposalYearProjectionOptions.findIndex((option) => {
                if (option.value === value) {
                  return true;
                }

                return false;
              });

              setSelectedYearOptionIndex(idx);
            }}
            label=""
            options={proposalYearProjectionOptions}
            value={
              proposalYearProjectionOptions[selectedYearOptionIndex]?.value
            }
          />
        </Stack>
      </Stack>

      <Stack p={4} alignItems="center" width={'100%'}>
        <ScenarioSummary
          gratProposalScenario={proposalScenario.gratProposalScenario}
          entityId={entityId ?? ''}
          scenarioNumber={scenarioIdx + 1}
          selectedYearOptionIndex={selectedYearOptionIndex}
          proposalYearProjectionOptions={proposalYearProjectionOptions}
          taxDrag={taxDrag ?? new Decimal(0)}
          assetValuationProjectionType={assetValuationProjectionType}
          beneficiaries={beneficiaries}
        />
      </Stack>
    </SlideContainer>
  );
}
