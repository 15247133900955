import { ApolloError } from '@apollo/client';
import { ComponentType, useEffect, useState } from 'react';

import { InteractionParadigmContext } from '@/contexts/InteractionParadigm.context';
import { sleep } from '@/utils/sleep';

import { PrintStatuses } from '../printOnly.constants';
import { PrintStatusSentinel } from './PrintStatusSentinel';

export function PrintOnlyClientPresentationWatcher<
  T extends { loading: boolean; error?: ApolloError },
>({
  Root,
  useQuery,
}: {
  Root: ComponentType<T>;
  useQuery: () => T;
}): JSX.Element {
  const [printStatus, setPrintStatus] = useState<PrintStatuses>(
    PrintStatuses.LOADING
  );

  const queryData = useQuery();
  const { loading, error } = queryData;

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (loading) {
      setPrintStatus(PrintStatuses.LOADING);
      return;
    }

    if (error) {
      setPrintStatus(PrintStatuses.ERROR);
      return;
    }

    async function watchPrintStatus() {
      await sleep(1000);
      const waterfalls = document.querySelectorAll(
        '[data-presentationwaterfallready]'
      );

      const entities = document.querySelectorAll(
        '[data-presentationentityready]'
      );

      if (waterfalls.length === 0 && entities.length === 0) {
        setPrintStatus(PrintStatuses.READY);
        return;
      }

      // Check the status of all the waterfalls every second
      // so we can know if that the waterfalls are initialized and
      // ready to be printed
      interval = setInterval(() => {
        const waterfalls = document.querySelectorAll(
          '[data-presentationwaterfallready]'
        );

        const entities = document.querySelectorAll(
          '[data-presentationentityready]'
        );

        // Check if all waterfalls are ready
        const waterfallStatuses = Array.from(waterfalls).map((overlay) => {
          return overlay.getAttribute('data-presentationwaterfallready');
        });

        const entityStatuses = Array.from(entities).map((overlay) => {
          return overlay.getAttribute('data-presentationentityready');
        });

        if (
          waterfallStatuses.includes('false') ||
          entityStatuses.includes('false')
        ) {
          return;
        }

        setPrintStatus(PrintStatuses.READY);
        interval && clearInterval(interval);
      }, 1000);
    }

    void watchPrintStatus();

    return () => {
      interval && clearInterval(interval);
    };
  }, [loading, error]);

  return (
    <InteractionParadigmContext.Provider value={{ viewOnly: true }}>
      {/* this is used to indicate the status of the document to the PDF printing service, so it knows to delay until everything is ready */}
      <PrintStatusSentinel status={printStatus} />
      <Root {...queryData} />
    </InteractionParadigmContext.Provider>
  );
}
