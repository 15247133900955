import { Box } from '@mui/material';
import { chunk } from 'lodash';
import { useMemo } from 'react';

import { getCustodialQualifiedTuitionPersonalAccountSummaryProperties } from '@/modules/entities/details/accounts/CustodialQualifiedTuitionPersonalAccountDetails.utils';
import { getIndividualPersonalAccountSummaryProperties } from '@/modules/entities/details/accounts/IndividualPersonalAccountDetails.utils';
import { getInsuranceAccountSummaryProperties } from '@/modules/entities/details/accounts/InsuranceAccountDetails.utils';
import { getJointPersonalAccountSummaryProperties } from '@/modules/entities/details/accounts/JointPersonalAccountDetails.utils';
import { getRetirementPersonalAccountSummaryProperties } from '@/modules/entities/details/accounts/RetirementPersonalAccountDetails.utils';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import { PersonalAccountSummaryData } from '@/modules/personalAccounts/PersonalAccountDetails/PersonalAccountDetails.types';
import {
  getPaginatedBeneficiariesAndDispositionsForSummary,
  GetPaginatedBeneficiariesAndDispositionsForSummaryOutput,
} from '@/modules/presentation/entities/entityPresentationPagination.utils';
import { EntitySummaryGrantor } from '@/modules/presentation/entities/entityPresentations.types';
import { PersonalAccountSummarySlide } from '@/modules/presentation/entities/personalAccounts/components/PersonalAccount.SummarySlide';
import { EntityKind, PresentationBundleKind, StateCode } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';

import { useRegisterSlide } from '../../../ClientPresentationDesignerV2.context';
import { ClientPresentationDesignerV2_EntityFragment } from '../../../graphql/ClientPresentationDesignerV2.generated';
import { ClientPresentationV2Footer } from '../../ClientPresentationV2Footer';
import { BaseBundleSlideProps } from '../BundleSlide.types';
import { InsurancePoliciesSlide } from './InsurancePoliciesSlideV2';

interface AccountSummarySlideInnerProps
  extends Omit<AccountSummarySlideProps, 'entityKind'> {
  slideProps: GetPaginatedBeneficiariesAndDispositionsForSummaryOutput;
  grantors: EntitySummaryGrantor[];
  summary: PersonalAccountSummaryData | null;
  idx: number;
}

function AccountSummarySlideInner({
  entity,
  bundle,
  SlideWrapper = Box,
  slideProps,
  grantors,
  summary,
  idx,
  page,
  isVisible,
}: AccountSummarySlideInnerProps) {
  const slideId = `account-summary-slide-${bundle.id}-${page?.id || 'no-page'}-entity-${entity.id}-${idx}`;
  useRegisterSlide({
    slideId,
    title:
      bundle.displayName ?? `Entity Summary -- ${entity.subtype.displayName}`,
    includeInToC: idx === 0,
    bundleKind: PresentationBundleKind.EntitySummaryBundle,
  });

  if (!isVisible) {
    return null;
  }

  return (
    <SlideWrapper key={slideId}>
      <PersonalAccountSummarySlide
        registrationProps={{
          bundleId: bundle.id,
          slideId,
          bundleIndex: idx,
        }}
        entity={entity}
        summary={summary!}
        displayName={entity.subtype.displayName}
        grantors={grantors}
        Footer={<ClientPresentationV2Footer slideId={slideId} />}
        {...slideProps}
      />
    </SlideWrapper>
  );
}

export interface AccountSummarySlideProps extends BaseBundleSlideProps {
  entity: ClientPresentationDesignerV2_EntityFragment;
  entityKind:
    | EntityKind.IndividualPersonalAccount
    | EntityKind.JointPersonalAccount
    | EntityKind.InsurancePersonalAccount
    | EntityKind.QualifiedTuitionPersonalAccount
    | EntityKind.RetirementPersonalAccount
    | EntityKind.CustodialPersonalAccount;
}

export function AccountSummarySlide({
  entity,
  bundle,
  page,
  SlideWrapper = Box,
  entityKind,
  isVisible,
}: AccountSummarySlideProps) {
  const { availableStateEstateTaxes } = useIRSConstants();
  const grantors = useMemo<EntitySummaryGrantor[]>(() => {
    return (
      entity.household.possiblePrimaryClients?.map((grantor) => ({
        displayName: grantor.displayName,
        id: grantor.id,
        firstName: grantor.firstName,
        lastName: grantor.lastName,
        stateCode: grantor.address?.stateCode as StateCode | undefined,
        hasStateTax: grantor.address?.stateCode
          ? (availableStateEstateTaxes?.includes(
              grantor.address.stateCode as StateCode
            ) ?? false)
          : false,
      })) ?? []
    );
  }, [availableStateEstateTaxes, entity.household.possiblePrimaryClients]);

  let summary: PersonalAccountSummaryData | null = null;

  switch (entityKind) {
    case EntityKind.IndividualPersonalAccount:
      summary = getIndividualPersonalAccountSummaryProperties({
        entity,
        entityType: ENTITY_TYPES.INDIVIDUAL_ACCOUNT,
      });
      break;
    case EntityKind.JointPersonalAccount:
      summary = getJointPersonalAccountSummaryProperties({
        entity,
        entityType: ENTITY_TYPES.JOINT_ACCOUNT,
      });
      break;
    case EntityKind.InsurancePersonalAccount:
      summary = getInsuranceAccountSummaryProperties({
        entity,
        entityType: ENTITY_TYPES.INSURANCE_ACCOUNT,
      });
      break;
    case EntityKind.QualifiedTuitionPersonalAccount:
      summary = getCustodialQualifiedTuitionPersonalAccountSummaryProperties({
        entity,
        entityType: ENTITY_TYPES.QUALIFIED_TUITION_ACCOUNT,
      });
      break;
    case EntityKind.RetirementPersonalAccount:
      summary = getRetirementPersonalAccountSummaryProperties({
        entity,
        entityType: ENTITY_TYPES.RETIREMENT_ACCOUNT,
      });
      break;
    case EntityKind.CustodialPersonalAccount:
      summary = getCustodialQualifiedTuitionPersonalAccountSummaryProperties({
        entity,
        entityType: ENTITY_TYPES.CUSTODIAL_ACCOUNT,
      });
      break;
    default:
      throw new UnreachableError({
        case: entityKind,
        message: `Unhandled account entity kind: ${entityKind}`,
      });
  }

  if (!summary) {
    diagnostics.error(
      `No summary data found for entity ${entity.id} in ${bundle.id}`
    );
    return null;
  }

  const { insurancePolicies } = summary;

  const policyPages = chunk(insurancePolicies, 2);

  const summaryPagesProps = getPaginatedBeneficiariesAndDispositionsForSummary({
    dispositionScenarios:
      entity.dispositionScenariosSubtype?.dispositionScenarios ?? [],
    entityId: entity.id,
    slideTypes: [],
    beneficiaries: summary.beneficiaries ?? [],
    entityKind: entity.kind,
    grantors,
    description: summary.description,
    reserveFirstPage: isFeatureFlagEnabled('entity_diagrams'),
  });

  return (
    <>
      {summaryPagesProps.map((slideProps, idx) => (
        <AccountSummarySlideInner
          key={`account-summary-slide-${bundle.id}-${page?.id || 'no-page'}-entity-${entity.id}-${idx}`}
          entity={entity}
          bundle={bundle}
          page={page}
          SlideWrapper={SlideWrapper}
          slideProps={slideProps}
          grantors={grantors}
          summary={summary}
          idx={idx}
          isVisible={isVisible}
        />
      ))}
      {policyPages.map((pagePolicies, idx) => (
        <InsurancePoliciesSlide
          key={`account-summary-slide-${bundle.id}-${page?.id || 'no-page'}-entity-${entity.id}-insurance-${idx}`}
          entity={entity}
          bundle={bundle}
          page={page}
          SlideWrapper={SlideWrapper}
          idx={idx}
          pagePolicies={pagePolicies}
          numberOfPolicySlides={policyPages.length}
          isVisible={isVisible}
        />
      ))}
    </>
  );
}
