import { UnreachableError } from '@/utils/errors';

import {
  getTermVizFromEntity,
  GetTermVizFromEntityInput,
} from './getTermVizFromEntity';
import { QprtTermDiagram_EntityFragment } from './graphql/QprtTermDiagram.generated';

export interface GetQprtTermVizFromEntityInput {
  entity: QprtTermDiagram_EntityFragment;
  firstGrantorDeathId: string;
  survivingSpouse?: {
    id: string;
    displayName: string;
  };
}

export function getQprtTermVizFromEntity({
  entity,
  firstGrantorDeathId,
  survivingSpouse,
}: GetQprtTermVizFromEntityInput) {
  if (entity.subtype.__typename !== 'QPRTTrust') {
    throw new UnreachableError({
      case: entity.subtype.__typename as never,
      message: 'Entity is not a QPRTTrust',
    });
  }

  const grantors: GetTermVizFromEntityInput['grantors'] = [];
  entity.subtype.grantors?.forEach((g) => {
    if (!g.individual) {
      return;
    }

    grantors.push({
      id: g.id,
      individual: {
        id: g.individual.id,
        displayName: g.individual.displayName,
      },
    });
  });

  const termBeneficiaries: GetTermVizFromEntityInput['termEndBeneficiaries'] =
    [];
  entity.subtype.beneficiaries?.forEach((b) => {
    termBeneficiaries.push({
      id: b.id,
      individual: b.individual,
      organization: b.organization,
      entity: b.entity,
    });
  });

  return getTermVizFromEntity({
    entity,
    grantors,
    currentTermBeneficiaries: [],
    termEndBeneficiaries: termBeneficiaries,
    firstGrantorDeathId,
    survivingSpouse,
  });
}
