import { includes } from 'lodash';
import { useMemo } from 'react';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareCard } from '@/components/form/formAwareInputs/FormAwareCard';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormAwareTypeaheadSelectInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadSelectInput';
import { ModalFormLayout } from '@/components/form/layout/ModalFormLayout';
import { Card, CardProps } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useAISuggestionsEnabled } from '@/modules/aiSuggestions/hooks/useAISuggestionsEnabled';
import {
  SUGGESTIONS_DISABLED_HEADER,
  SUGGESTIONS_DISABLED_SUBHEADER,
  SUGGESTIONS_ENABLED_HEADER,
  SUGGESTIONS_ENABLED_SUBHEADER,
} from '@/modules/documents/components/LuminaryAIDocumentUploaderText';
import { MultiDocumentUploaderWithList } from '@/modules/documents/MultiDocumentUploader/MultiDocumentUploaderWithList';
import { NonInsuranceAssets } from '@/modules/entities/EntityShortFormModal/CreateEntityShortForm/fields/NonInsuranceAssets';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';

import {
  ACCOUNT_ENTITY_TYPES,
  BUSINESS_ENTITY_TYPES,
  ENTITY_TYPES,
  NON_TRUST_CHARITABLE_ENTITY_TYPES,
  PERSONAL_FAMILY_ACCOUNT_TYPES,
} from '../../entities.constants';
import { CreateEntityShortFormContext } from './context/CreateEntityShortForm.context';
import { OWNERSHIP_PERCENTAGE_ENTITY_TYPES } from './CreateEntityShortForm.constants';
import {
  EntityShortFormPaths,
  EntityShortFormShape,
  PrincipalsOwnershipMap,
} from './CreateEntityShortForm.types';
import { getEntityShortFormOptions } from './CreateEntityShortForm.utils';
import { AssetLocation } from './fields/AssetLocation';
import { Assets } from './fields/Assets';
import { BusinessEntityPrincipals } from './fields/BusinessEntityPrincipals';
import { InfoCallout } from './fields/InfoCallout';
import { Principals } from './fields/Principals';
import { validateMoreThanOnePrincipalSelected } from './fields/Principals.utils';
import { entityTypeAllowsMultipleSelection } from './formUtils/CreateEntityShortForm.common';
import {
  CreateEntityShortForm_HouseholdFragment,
  CreateEntityShortFormDataQuery,
  useCreateEntityShortFormDataQuery,
} from './graphql/CreateEntityShortForm.generated';

interface CreateEntityShortFormProps {
  householdId: string;
  forceEntityType?: boolean;
}

const CARD_PROPS: CardProps = {
  variant: 'filled',
  sx: { p: 2, height: '100%' },
};

export function CreateEntityShortForm({
  householdId,
  forceEntityType,
}: CreateEntityShortFormProps) {
  const { createErrorFeedback } = useFeedback();
  const { applicationConfiguration } = useTenantDetailsContext();

  const { data } = useCreateEntityShortFormDataQuery({
    variables: { householdId: householdId },
    onError: createErrorFeedback(
      `We weren't able to load grantor options. Please refresh the page to try again.`
    ),
    fetchPolicy: 'cache-and-network',
  });

  const { principalOptions } = useMemo(
    () => getFormConfigurationFromData(data),
    [data]
  );
  const { control, watch } = useFormContext<EntityShortFormShape>();
  const entityFormOptions = useMemo(() => getEntityShortFormOptions(), []);
  const entityType =
    watch('createEntityShortForm.entityType') || 'irrevocable-trust';

  const isNonTrustCharitableEntity =
    NON_TRUST_CHARITABLE_ENTITY_TYPES.includes(entityType);
  const shouldHideAssetLocation =
    ACCOUNT_ENTITY_TYPES.includes(entityType) ||
    BUSINESS_ENTITY_TYPES.includes(entityType) ||
    entityType == 'crt' ||
    entityType === 'insurance-account';
  const shouldCollectOwnershipPercentage =
    OWNERSHIP_PERCENTAGE_ENTITY_TYPES.includes(entityType);
  const shouldRenderFullRowPrincipalsInput =
    isNonTrustCharitableEntity ||
    shouldCollectOwnershipPercentage ||
    shouldHideAssetLocation;
  const shouldShowDocumentUploader =
    !applicationConfiguration?.disableAiCapabilities && // Don't show the doc uploader if tenant has disabled AI capabilities
    entityType !== ENTITY_TYPES.SOLE_PROPRIETORSHIP &&
    entityType !== ENTITY_TYPES.GRAT && // Don't show document uploader for GRAT
    !PERSONAL_FAMILY_ACCOUNT_TYPES.includes(entityType);

  // This also checks for tenant-level AI capabilities
  const suggestionsEnabled = useAISuggestionsEnabled(entityType);

  return (
    <CreateEntityShortFormContext.Provider value={{ isShortForm: true }}>
      <ModalFormLayout>
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareTextInput<EntityShortFormShape>
              control={control}
              fieldName={
                'createEntityShortForm.name' as const satisfies EntityShortFormPaths
              }
              label="Display name"
              required
            />
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareTypeaheadSelectInput<EntityShortFormShape>
              control={control}
              options={entityFormOptions}
              fieldName={
                'createEntityShortForm.entityType' as const satisfies EntityShortFormPaths
              }
              label="Entity type"
              groupBy={(option) => option.groupName ?? ''}
              required
              disabled={forceEntityType}
            />
          </FormLayoutItem>
        </FormLayoutRow>
        {entityType !== 'grat' && (
          <>
            <FormLayoutRow>
              <FormLayoutItem
                width={shouldRenderFullRowPrincipalsInput ? 12 : 5}
              >
                {includes(BUSINESS_ENTITY_TYPES, entityType) ? (
                  <Card {...CARD_PROPS}>
                    <BusinessEntityPrincipals
                      entityType={entityType}
                      householdId={householdId}
                    />
                  </Card>
                ) : (
                  <FormAwareCard<EntityShortFormShape>
                    {...CARD_PROPS}
                    control={control}
                    validation={{
                      minLength: (value) => {
                        if (!entityTypeAllowsMultipleSelection(entityType)) {
                          return undefined;
                        }

                        return validateMoreThanOnePrincipalSelected(
                          value as PrincipalsOwnershipMap | undefined,
                          undefined, // no single client selection in this scenario, since where on the multi-client field
                          entityType
                        );
                      },
                    }}
                    fieldName={
                      `createEntityShortForm.principals` as const satisfies EntityShortFormPaths
                    }
                  >
                    <Principals
                      entityType={entityType}
                      principalOptions={principalOptions}
                    />
                  </FormAwareCard>
                )}
              </FormLayoutItem>
              {!shouldRenderFullRowPrincipalsInput && (
                <FormLayoutItem width={7}>
                  <Card variant="filled" sx={{ p: 2, height: '100%' }}>
                    <AssetLocation entityType={entityType} />
                  </Card>
                </FormLayoutItem>
              )}
            </FormLayoutRow>
            {shouldRenderFullRowPrincipalsInput && !shouldHideAssetLocation && (
              <FormLayoutRow>
                <FormLayoutItem
                  // just toggle the visibility rather than unmounting to keep the input registered
                  // so the value can be changed programmatically
                  sx={{
                    display: isNonTrustCharitableEntity ? 'none' : undefined,
                  }}
                  width={12}
                >
                  <Card variant="filled" sx={{ p: 2, height: '100%' }}>
                    <AssetLocation entityType={entityType} />
                  </Card>
                </FormLayoutItem>
              </FormLayoutRow>
            )}
            {entityType === 'qprt' && (
              <FormLayoutRow>
                <FormLayoutItem>
                  <Assets
                    variant="initialFunding"
                    householdId={householdId}
                    entityType={entityType}
                  />
                </FormLayoutItem>
              </FormLayoutRow>
            )}
            {entityType !== 'insurance-account' && entityType !== 'ilit' && (
              <FormLayoutRow>
                <FormLayoutItem>
                  <Assets householdId={householdId} entityType={entityType} />
                </FormLayoutItem>
              </FormLayoutRow>
            )}
          </>
        )}
        {entityType === 'ilit' && <NonInsuranceAssets />}
        {shouldShowDocumentUploader && householdId && (
          <FormLayoutRow>
            <FormLayoutItem>
              <MultiDocumentUploaderWithList
                allowSelectExistingDocuments={true}
                householdId={householdId}
                uploaderTextHeader={
                  suggestionsEnabled
                    ? SUGGESTIONS_ENABLED_HEADER
                    : SUGGESTIONS_DISABLED_HEADER
                }
                uploaderTextSubheader={
                  suggestionsEnabled
                    ? SUGGESTIONS_ENABLED_SUBHEADER
                    : SUGGESTIONS_DISABLED_SUBHEADER
                }
              />
            </FormLayoutItem>
          </FormLayoutRow>
        )}
        <InfoCallout entityType={entityType} />
      </ModalFormLayout>
    </CreateEntityShortFormContext.Provider>
  );
}
function getFormConfigurationFromData(
  data: CreateEntityShortFormDataQuery | undefined
): { principalOptions: SelectInputOption<string>[] } {
  if (!data?.household) {
    return { principalOptions: [] };
  }
  const household = data.household as CreateEntityShortForm_HouseholdFragment;
  const principalOptions = household.possibleGrantors
    .map((grantor) => ({
      display: grantor.displayName,
      value: grantor.id,
    }))
    .sort((a, b) => a.display.localeCompare(b.display));
  return { principalOptions };
}
