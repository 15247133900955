import Decimal from 'decimal.js';
import { uniqBy } from 'lodash';

import { GraphQLCurrencyUSD } from '@/graphql/scalars';
import {
  AfterDeath,
  ClientOrganizationKind,
  DispositiveProvisionRecipientKind,
  DispositiveProvisionTransferTaxKind,
  EntityInEstateStatus,
  EntityKind,
  TestamentaryEntityKind,
} from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { getNodes } from '@/utils/graphqlUtils';

import {
  DispositionsDiagram_DispositionProvisionFirstDeathFragment,
  DispositionsDiagram_DispositionProvisionSecondDeathFragment,
  DispositionsDiagram_DispositionScenarioFirstDeathFragment,
  DispositionsDiagram_DispositionScenarioSecondDeathFragment,
} from './graphql/DispositionsDiagram.generated';
import {
  EntityDiagram_EdgeFragment,
  EntityDiagram_NodeFragment,
  EntityDiagramEdgeKind,
  EntityDiagramTileKind,
} from './types';

export interface MakeNodeFromEntityInput {
  entity: {
    id: string;
    entityKind: EntityKind;
    extendedDisplayKind: string;
    subtype: {
      id: string;
      displayName: string;
      inEstateStatus?: EntityInEstateStatus | null;
      policies?:
        | {
            id: string;
            deathBenefitAmountString: Decimal;
          }[]
        | null;
    };
  };
  afterDeath: AfterDeath;
  ownershipPercentage?: Decimal;
  hasIncomingPouroverDisposition?: boolean;
  entityDiagramTileKind: EntityDiagramTileKind;
}

export function makeNodeFromEntity({
  entity,
  afterDeath,
  ownershipPercentage,
  hasIncomingPouroverDisposition = false,
  entityDiagramTileKind,
}: MakeNodeFromEntityInput): EntityDiagram_NodeFragment {
  let estateStatus = EntityInEstateStatus.InEstate;

  if ('inEstateStatus' in entity.subtype) {
    estateStatus =
      entity.subtype.inEstateStatus ?? EntityInEstateStatus.InEstate;
  }

  let policies:
    | {
        id: string;
        deathBenefitAmount: Decimal;
      }[]
    | undefined = undefined;

  if ('policies' in entity.subtype) {
    policies = entity.subtype.policies?.map((policy) => ({
      id: policy.id,
      // Manually parse the value because we skip scalar parsing for the source query
      deathBenefitAmount: GraphQLCurrencyUSD.parseValue(
        policy.deathBenefitAmountString
      ) as Decimal,
    }));
  }

  // TODO SINGLE_ENTITY_DIAGRAM we don't have configurations yet
  const entityDiagramID = null;

  return {
    id: entity.id,
    afterDeath,
    entityDiagramID,
    inEstateStatus: estateStatus,
    nodeConfiguration: null,
    entityDiagramTileKind,
    node: {
      __typename: 'Entity',
      id: entity.id,
      entityKind: entity.entityKind,
      extendedDisplayKind: entity.extendedDisplayKind,
      hasIncomingPouroverDisposition,
      ownershipPercentage,
      subtype: {
        id: entity.subtype.id,
        displayName: entity.subtype.displayName,
        policies,
      },
    },
  };
}

interface MakeNodeFromTestamentaryEntityInput {
  testamentaryEntity: {
    id: string;
    displayName: string;
    inEstateStatus?: EntityInEstateStatus | null;
    testamentaryEntityKind: TestamentaryEntityKind;
  };
  afterDeath: AfterDeath;
  entityDiagramTileKind: EntityDiagramTileKind;
}

function makeNodeFromTestamentaryEntity({
  testamentaryEntity,
  afterDeath,
  entityDiagramTileKind,
}: MakeNodeFromTestamentaryEntityInput): EntityDiagram_NodeFragment {
  // TODO SINGLE_ENTITY_DIAGRAM we don't have configurations yet
  const entityDiagramID = null;

  return {
    id: testamentaryEntity.id,
    afterDeath,
    entityDiagramID,
    inEstateStatus:
      testamentaryEntity.inEstateStatus ?? EntityInEstateStatus.InEstate,
    nodeConfiguration: null,
    entityDiagramTileKind,
    node: {
      __typename: 'TestamentaryEntity',
      id: testamentaryEntity.id,
      testamentaryEntityKind: testamentaryEntity.testamentaryEntityKind,
      displayName: testamentaryEntity.displayName,
    },
  };
}

export interface MakeNodeFromIndividualInput {
  individual: {
    id: string;
    displayName: string;
  };
  afterDeath: AfterDeath;
  estateStatus: EntityInEstateStatus;
  ownershipPercentage?: Decimal;
  entityDiagramTileKind: EntityDiagramTileKind | EntityDiagramTileKind[];
}

export function makeNodeFromIndividual({
  individual,
  afterDeath,
  estateStatus,
  ownershipPercentage,
  entityDiagramTileKind,
}: MakeNodeFromIndividualInput): EntityDiagram_NodeFragment {
  // TODO SINGLE_ENTITY_DIAGRAM we don't have configurations yet
  const entityDiagramID = null;

  return {
    id: individual.id,
    afterDeath,
    entityDiagramID,
    inEstateStatus: estateStatus,
    nodeConfiguration: null,
    entityDiagramTileKind,
    node: {
      __typename: 'ClientProfile',
      id: individual.id,
      displayName: individual.displayName,
      ownershipPercentage,
    },
  };
}

export interface MakeNodeFromOrganizationInput {
  organization: {
    id: string;
    name: string;
    clientOrganizationKind?: ClientOrganizationKind | null;
  };
  afterDeath: AfterDeath;
  entityDiagramTileKind: EntityDiagramTileKind;
}

export function makeNodeFromOrganization({
  organization,
  afterDeath,
  entityDiagramTileKind,
}: MakeNodeFromOrganizationInput): EntityDiagram_NodeFragment {
  // TODO SINGLE_ENTITY_DIAGRAM we don't have configurations yet
  const entityDiagramID = null;

  return {
    id: organization.id,
    afterDeath,
    entityDiagramID,
    inEstateStatus: EntityInEstateStatus.OutOfEstate,
    nodeConfiguration: null,
    entityDiagramTileKind,
    node: {
      __typename: 'ClientOrganization',
      id: organization.id,
      kind: organization.clientOrganizationKind ?? ClientOrganizationKind.Other,
      name: organization.name,
    },
  };
}

interface MakeNodeFromDispositionInput {
  disposition:
    | DispositionsDiagram_DispositionProvisionFirstDeathFragment
    | DispositionsDiagram_DispositionProvisionSecondDeathFragment;
  afterDeath: AfterDeath;
  survivingSpouse?: {
    id: string;
    displayName: string;
  };
  firstGrantorDeathId: string;
}

function makeNodeFromDisposition({
  disposition,
  afterDeath,
  survivingSpouse,
  firstGrantorDeathId,
}: MakeNodeFromDispositionInput): EntityDiagram_NodeFragment | null {
  if (disposition.associatedHypotheticalWaterfall?.id) {
    return null;
  }

  const hasIncomingPouroverDisposition =
    disposition.transferTaxKind ===
    DispositiveProvisionTransferTaxKind.Pourover;

  const recipientKind =
    disposition.recipientKind as DispositiveProvisionRecipientKind;

  if (!recipientKind) {
    return null;
  }

  switch (recipientKind) {
    case DispositiveProvisionRecipientKind.Entity: {
      if (!disposition.entity) {
        return null;
      }

      return makeNodeFromEntity({
        entity: disposition.entity,
        afterDeath,
        hasIncomingPouroverDisposition,
        entityDiagramTileKind: EntityDiagramTileKind.DispositionBeneficiary,
      });
    }
    case DispositiveProvisionRecipientKind.TestamentaryEntity: {
      if (!disposition.testamentaryEntity) {
        return null;
      }

      return makeNodeFromTestamentaryEntity({
        testamentaryEntity: disposition.testamentaryEntity,
        afterDeath,
        entityDiagramTileKind: EntityDiagramTileKind.DispositionBeneficiary,
      });
    }
    case DispositiveProvisionRecipientKind.Individual: {
      if (!disposition.individual) {
        return null;
      }

      const estateStatus = (() => {
        if (
          disposition.individual.id === firstGrantorDeathId ||
          disposition.individual.id === survivingSpouse?.id
        ) {
          return EntityInEstateStatus.InEstate;
        }

        return EntityInEstateStatus.OutOfEstate;
      })();

      return makeNodeFromIndividual({
        individual: disposition.individual,
        afterDeath,
        estateStatus,
        entityDiagramTileKind: EntityDiagramTileKind.DispositionBeneficiary,
      });
    }
    case DispositiveProvisionRecipientKind.Organization: {
      if (!disposition.organization) {
        return null;
      }

      return makeNodeFromOrganization({
        organization: disposition.organization,
        afterDeath,
        entityDiagramTileKind: EntityDiagramTileKind.DispositionBeneficiary,
      });
    }
    case DispositiveProvisionRecipientKind.SurvivingSpouse:
      if (!survivingSpouse) {
        return null;
      }

      return makeNodeFromIndividual({
        individual: survivingSpouse,
        afterDeath,
        estateStatus: EntityInEstateStatus.InEstate,
        entityDiagramTileKind: EntityDiagramTileKind.DispositionBeneficiary,
      });
    default:
      throw new UnreachableError({
        case: recipientKind,
        message: `Unknown recipient kind ${disposition.recipientKind}`,
      });
  }
}

export interface GetDispositionsVizFromEntityInput {
  // DispositionsDiagram_EntityFragment is an expensive type so we use only the fields we need
  entity: {
    id: string;
    entityKind: EntityKind;
    extendedDisplayKind: string;
    subtype: {
      id: string;
      displayName: string;
      inEstateStatus?: EntityInEstateStatus | null;
      dispositionScenarios?:
        | DispositionsDiagram_DispositionScenarioFirstDeathFragment[]
        | null;
    };
  };
  firstGrantorDeathId: string;
  isTwoClientHousehold: boolean;
  survivingSpouse?: {
    id: string;
    displayName: string;
  };
}

export function getDispositionsVizFromEntity({
  entity,
  firstGrantorDeathId,
  isTwoClientHousehold,
  survivingSpouse,
}: GetDispositionsVizFromEntityInput): {
  nodes: EntityDiagram_NodeFragment[];
  edges: EntityDiagram_EdgeFragment[];
} {
  const rootNode = makeNodeFromEntity({
    entity,
    afterDeath: AfterDeath.None,
    entityDiagramTileKind: EntityDiagramTileKind.Entity,
  });

  const nodes: EntityDiagram_NodeFragment[] = [rootNode];
  const edges: EntityDiagram_EdgeFragment[] = [];

  // Make sure we are using the correct disposition scenario
  const rootNodeRelevantDispositionScenario =
    entity.subtype.dispositionScenarios?.find(
      (scenario) => scenario.firstGrantorDeath?.id === firstGrantorDeathId
    ) ?? null;

  // Get the dispositions from the template or the dispositive provisions

  // These will have an edge from the root to the next section
  const dispositionsToNextSection = (() => {
    const dispositionsFromTemplate = getNodes(
      rootNodeRelevantDispositionScenario?.dispositiveProvisionsTemplate
        ?.dispositiveProvisions
    );

    const dispositionsFromProvisions = getNodes(
      rootNodeRelevantDispositionScenario?.dispositiveProvisions
    );

    if (dispositionsFromTemplate.length > 0) {
      return dispositionsFromTemplate;
    }

    return dispositionsFromProvisions;
  })();

  // These will have an edge from the root in the second section to the last section
  const dispositionsToLastSection = (() => {
    const dispositionsFromTemplate = getNodes(
      rootNodeRelevantDispositionScenario
        ?.secondDeathDispositiveProvisionsTemplate?.dispositiveProvisions
    );

    const dispositionsFromProvisions = getNodes(
      rootNodeRelevantDispositionScenario?.secondDeathDispositiveProvisions
    );

    if (dispositionsFromTemplate.length > 0) {
      return dispositionsFromTemplate;
    }

    return dispositionsFromProvisions;
  })();

  // Track the nodes that are added to the second section that have dispositions of their own
  const secondSectionNodesWithDispositions: (
    | DispositionsDiagram_DispositionProvisionFirstDeathFragment['entity']
    | DispositionsDiagram_DispositionProvisionFirstDeathFragment['testamentaryEntity']
  )[] = [];

  if (dispositionsToNextSection.length > 0) {
    // For each disposition from the root node to the next section,
    // add the node to the next section and an edge from the root node
    dispositionsToNextSection.forEach((disposition) => {
      const dispositionKindsWithDispositionsOfTheirOwn = [
        DispositiveProvisionRecipientKind.Entity,
        DispositiveProvisionRecipientKind.TestamentaryEntity,
      ];
      if (
        disposition.recipientKind &&
        dispositionKindsWithDispositionsOfTheirOwn.includes(
          disposition.recipientKind as DispositiveProvisionRecipientKind
        )
      ) {
        secondSectionNodesWithDispositions.push(
          disposition.entity ?? disposition.testamentaryEntity
        );
      }

      // Draw the edges from the root node to the disposition recipients in the second section

      const node = makeNodeFromDisposition({
        disposition,
        afterDeath: AfterDeath.First,
        survivingSpouse,
        firstGrantorDeathId,
      });

      if (!node) {
        return;
      }

      nodes.push(node);
      edges.push({
        from: rootNode,
        to: node,
        kind: EntityDiagramEdgeKind.Disposition,
      });
    });
  }

  if (dispositionsToLastSection.length > 0) {
    // Add the root node to the second section
    const rootNodeInSecondSection = makeNodeFromEntity({
      entity,
      afterDeath: AfterDeath.First,
      entityDiagramTileKind: EntityDiagramTileKind.Entity,
    });

    nodes.push(rootNodeInSecondSection);

    // Draw the edge from the root node to its descendant in the second section
    edges.push({
      from: rootNode,
      to: rootNodeInSecondSection,
      kind: EntityDiagramEdgeKind.Disposition,
    });

    // For each disposition from the root node to the last section,
    // add the node to the last section and an edge from the root node
    // in the previous section
    dispositionsToLastSection.forEach((disposition) => {
      const node = makeNodeFromDisposition({
        disposition,
        afterDeath: AfterDeath.Second,
        survivingSpouse,
        firstGrantorDeathId,
      });

      if (!node) {
        return;
      }

      nodes.push(node);
      edges.push({
        from: rootNodeInSecondSection,
        to: node,
        kind: EntityDiagramEdgeKind.Disposition,
      });
    });
  }

  if (secondSectionNodesWithDispositions.length > 0) {
    // Add the disposition nodes and edges for the nodes in the second section
    secondSectionNodesWithDispositions.forEach((node) => {
      if (!node) {
        return;
      }

      let graphNode: EntityDiagram_NodeFragment | null = null;
      let relevantDispositionScenario: DispositionsDiagram_DispositionScenarioSecondDeathFragment | null =
        null;

      if (node.__typename === 'Entity') {
        graphNode = makeNodeFromEntity({
          entity: node,
          afterDeath: AfterDeath.First,
          entityDiagramTileKind: EntityDiagramTileKind.DispositionBeneficiary,
        });
        relevantDispositionScenario =
          node.subtype.dispositionScenarios?.find(
            (scenario) => scenario.firstGrantorDeath?.id === firstGrantorDeathId
          ) ?? null;
      } else if (node.__typename === 'TestamentaryEntity') {
        graphNode = makeNodeFromTestamentaryEntity({
          testamentaryEntity: node,
          afterDeath: AfterDeath.First,
          entityDiagramTileKind: EntityDiagramTileKind.DispositionBeneficiary,
        });
        relevantDispositionScenario =
          node.dispositionScenarios?.find(
            (scenario) => scenario.firstGrantorDeath?.id === firstGrantorDeathId
          ) ?? null;
      }

      if (!graphNode || !relevantDispositionScenario) {
        return;
      }

      // Get only the dispositions for the second death event in this scenario for this node
      // because we only want to show what happens to this node after the second death
      const dispositionsToLastSection = (() => {
        const dispositionsFromTemplate = getNodes(
          relevantDispositionScenario?.secondDeathDispositiveProvisionsTemplate
            ?.dispositiveProvisions
        );

        const dispositionsFromProvisions = getNodes(
          relevantDispositionScenario?.secondDeathDispositiveProvisions
        );

        if (dispositionsFromTemplate.length > 0) {
          return dispositionsFromTemplate;
        }

        return dispositionsFromProvisions;
      })();

      // Draw the edges from the node in the second section to the disposition recipients in the last section
      dispositionsToLastSection.forEach((disposition) => {
        const destinationGraphNode = makeNodeFromDisposition({
          disposition,
          afterDeath: AfterDeath.Second,
          survivingSpouse,
          firstGrantorDeathId,
        });

        if (!destinationGraphNode) {
          return;
        }

        nodes.push(destinationGraphNode);
        edges.push({
          from: graphNode!,
          to: destinationGraphNode,
          kind: EntityDiagramEdgeKind.Disposition,
        });
      });
    });
  }

  if (nodes.length === 1) {
    // Special case for when there are no dispositions
    // we want to show the entity cascading to all the sections
    nodes.push(
      makeNodeFromEntity({
        entity,
        afterDeath: AfterDeath.First,
        entityDiagramTileKind: EntityDiagramTileKind.Entity,
      })
    );

    edges.push({
      from: nodes[0]!,
      to: nodes[1]!,
      kind: EntityDiagramEdgeKind.Disposition,
    });

    if (isTwoClientHousehold) {
      nodes.push(
        makeNodeFromEntity({
          entity,
          afterDeath: AfterDeath.Second,
          entityDiagramTileKind: EntityDiagramTileKind.Entity,
        })
      );

      edges.push({
        from: nodes[1]!,
        to: nodes[2]!,
        kind: EntityDiagramEdgeKind.Disposition,
      });
    }
  }

  const uniqueNodes = uniqBy(nodes, (node) => `${node.id}-${node.afterDeath}`);
  const uniqueEdges = uniqBy(
    edges,
    (edge) =>
      `${edge.from.id}-${edge.from.afterDeath}-${edge.to.id}-${edge.to.afterDeath}`
  );

  const sectionsRepresented = new Set(nodes.map((node) => node.afterDeath));

  // Make sure each section has a node so layout works

  if (!sectionsRepresented.has(AfterDeath.None)) {
    // This should not happen because we always have a root node
    uniqueNodes.push({
      id: 'node-0',
      afterDeath: AfterDeath.First,
      node: null,
    });
  }

  if (!sectionsRepresented.has(AfterDeath.First)) {
    uniqueNodes.push({
      id: 'node-1',
      afterDeath: AfterDeath.First,
      node: null,
    });
  }

  if (isTwoClientHousehold && !sectionsRepresented.has(AfterDeath.Second)) {
    uniqueNodes.push({
      id: 'node-2',
      afterDeath: AfterDeath.Second,
      node: null,
    });
  }

  return {
    nodes: uniqueNodes,
    edges: uniqueEdges,
  };
}
