import { first, isEmpty, isEqual, isUndefined } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { usePrevious } from 'react-use';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { useLoggedTransferWhereInput } from '@/modules/beneficiaryReporting/hooks/useBeneficiariesData';
import { AugmentedUpdateClientPresentationV2Input } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { formatDateToMMDDYY } from '@/utils/formatting/dates';
import { getNodes } from '@/utils/graphqlUtils';

import {
  ClientPresentationDesignerV2ContextShape,
  ClientPresentationDesignerV2ViewMode,
  useClientPresentationDesignerV2Context,
  useResetPagination,
} from './ClientPresentationDesignerV2.context';
import {
  ClientPresentationV2Configuration,
  ClientPresentationV2Shape,
} from './ClientPresentationDesignerV2.types';
import {
  getEstateWaterfallWithProjections,
  mapBundleToUpdateInput,
  NEW_BUNDLE_ID_SENTINEL,
  NEW_PAGE_ID_SENTINEL,
  SLIDE_ID_QUERY_PARAM,
} from './ClientPresentationDesignerV2.utils';
import { EstateWaterfallBeneficiarySlide_EstateWaterfallVizFragment } from './components/slides/estateWaterfall/Beneficiaries/graphql/EstateWaterfallBeneficiarySlide.generated';
import {
  ClientPresentationDesignerV2_EstateWaterfallFragment,
  useClientPresentationDesignerV2LazyQuery,
  useUpdateClientPresentationV2Mutation,
} from './graphql/ClientPresentationDesignerV2.generated';

export function usePersistPresentation(): { isPersistRunning: boolean } {
  const { control } = useFormContext<ClientPresentationV2Shape>();
  const {
    presentationId,
    householdId,
    estateWaterfallBundleConfigurationMap,
    setEstateWaterfallBundleConfigurationMap,
    entityDetailMap,
    setEntityDetailMap,
    beneficiaryWaterfallVizMap,
    setBeneficiaryWaterfallVizMap,
    presentationConfiguration: contextPresentationConfiguration,
    setPresentationConfiguration,
    isPersistRunning,
    setPersistRunning,
  } = useClientPresentationDesignerV2Context();
  const { resetPagination } = useResetPagination();
  const [bundles, presentationConfiguration, title] = useWatch({
    control,
    name: ['bundles', 'presentationConfiguration', 'title'],
  });

  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [updateClientPresentationV2] = useUpdateClientPresentationV2Mutation({
    onError(error) {
      reportError(
        `Caught error when updating presentation [${presentationId}]`,
        error
      );
      showFeedback('Failed to save presentation. Please try again later.');
      setPersistRunning(false);
    },
    // don't update state, because that'll cause a runaway re-render/re-save
    fetchPolicy: 'no-cache',
    ignoreResults: true,
    refetchQueries: [], // refetching manually -- *don't* trigger any other fetches
  });

  const loggedTransferWhereInput = useLoggedTransferWhereInput();
  // this is lazy because we don't care about its output on page load, but we want the refetch
  // callback available for the after-write scenario
  const [refetch] = useClientPresentationDesignerV2LazyQuery({
    fetchPolicy: 'no-cache', // cache normalization breaks across the various page types
    variables: {
      presentationId,
      householdId,
      loggedTransferWhereInput,
      presentationOnly: true,
    },
  });

  const previousTitle = usePrevious(title);
  const previousBundles = usePrevious(bundles);
  const previousPresentationConfiguration = usePrevious(
    presentationConfiguration
  );

  useEffect(() => {
    // conditions to skip the save:
    // 1. bundles is empty
    if (isEmpty(bundles)) {
      return;
    }

    // 2. previous bundles, title, or presentationConfiguration are empty (indicator of first render)
    if (
      isUndefined(previousBundles) ||
      isUndefined(previousPresentationConfiguration) ||
      isUndefined(previousTitle)
    ) {
      return;
    }

    // 3. everything is unchanged
    if (
      isEqual(bundles, previousBundles) &&
      isEqual(presentationConfiguration, previousPresentationConfiguration) &&
      title === previousTitle
    ) {
      return;
    }

    const input: AugmentedUpdateClientPresentationV2Input = {
      id: presentationId,
      update: {
        name:
          title ?? `Untitled Presentation ${formatDateToMMDDYY(new Date())}`,
        pageNumberToStartFrom: presentationConfiguration.pageNumberToStartFrom,
        showLegalDisclaimer: presentationConfiguration.showLegalDisclaimer,
        showPageNumbers: presentationConfiguration.showPageNumbers,
        showPageNumbersOnCoverSlide:
          presentationConfiguration.showPageNumbersOnCoverSlide,
        clearBundles: true,
      },
      withBundles: bundles.map((bundle, index) =>
        mapBundleToUpdateInput(bundle, householdId, index)
      ),
    };

    async function persistData() {
      setPersistRunning(true);
      /*
       * Because there is a **massive** performance difference in loading waterfalls
       * after write vs. in a separate query (>10s vs <500ms), chain into a separate read
       */

      // write
      const { data: mutationData } = await updateClientPresentationV2({
        variables: { input },
      });

      // check if the write is valid; if not, bail
      if (!mutationData?.updateClientPresentationV2?.id) {
        return;
      }

      // if the write is valid, re-run the slimmed-down version of the query to get the updated
      // data and update context
      const { data } = await refetch();

      // because the waterfalls/entity details/beneficiaries are stored in separate maps
      // in context and are fetched during the chained read, that context needs to be updated
      if (data) {
        // flags to decide what parts of context need to be updated
        let shouldUpdateEWBundleMap = false;
        let shouldUpdateEntityDetailMap = false;
        let shouldUpdateBeneficiariesMap = false;
        let shouldUpdatePresentationConfiguration = false;

        // populate new maps with the old maps' data
        const newEstateWaterfallBundleConfigurationMap: Record<
          string,
          ClientPresentationDesignerV2_EstateWaterfallFragment
        > = { ...estateWaterfallBundleConfigurationMap };
        const newEntityDetailMap: ClientPresentationDesignerV2ContextShape['entityDetailMap'] =
          { ...entityDetailMap };
        const newBeneficiaryEstateWaterfallVizMap: Record<
          string,
          EstateWaterfallBeneficiarySlide_EstateWaterfallVizFragment
        > = {
          ...beneficiaryWaterfallVizMap,
        };

        const outputPresentation = first(getNodes(data.clientPresentationV2s));
        const newPresentationConfiguration: ClientPresentationV2Configuration =
          {
            showPageNumbers: outputPresentation?.showPageNumbers ?? false,
            showPageNumbersOnCoverSlide:
              outputPresentation?.showPageNumbersOnCoverSlide ?? false,
            pageNumberToStartFrom:
              outputPresentation?.pageNumberToStartFrom ?? 1,
            showLegalDisclaimer:
              outputPresentation?.showLegalDisclaimer ?? false,
          };

        if (
          !isEqual(
            newPresentationConfiguration,
            contextPresentationConfiguration
          )
        ) {
          shouldUpdatePresentationConfiguration = true;
        }

        // check each bundle in the response...
        getNodes(outputPresentation?.bundles).forEach((bundle, index) => {
          // normalize on the pre-save bundle ID (as the bundles are cleared & recreated on saving);
          // if there is no old bundle ID, ignore the result and log the error
          const oldBundleId = bundles[index]?.id;
          if (!oldBundleId) {
            diagnostics.error(
              `Failed to find old bundle ID for bundle ${bundle.id} at index ${index}`
            );
            return;
          }

          // update the EW bundle map
          const estateWaterfall = getEstateWaterfallWithProjections(bundle);
          if (estateWaterfall) {
            // and then push it in to the new object and set the flag
            shouldUpdateEWBundleMap = true;
            newEstateWaterfallBundleConfigurationMap[oldBundleId] =
              estateWaterfall;
          }

          // update the entity summary map
          if (bundle.entitySummaryBundleConfiguration?.entity) {
            newEntityDetailMap[oldBundleId] = {
              ...bundle.entitySummaryBundleConfiguration?.entity,
              deathOrder: bundle.entitySummaryBundleConfiguration?.deathOrder,
            };

            shouldUpdateEntityDetailMap = true;
          }

          if (
            bundle.waterfallBundleConfiguration?.beneficiaryEstateWaterfallViz
          ) {
            shouldUpdateBeneficiariesMap = true;
            newBeneficiaryEstateWaterfallVizMap[oldBundleId] =
              bundle.waterfallBundleConfiguration.beneficiaryEstateWaterfallViz;
          }
        });

        // if a flag has been set, update relevant context
        if (shouldUpdateEWBundleMap) {
          setEstateWaterfallBundleConfigurationMap(
            newEstateWaterfallBundleConfigurationMap
          );
        }
        if (shouldUpdateEntityDetailMap) {
          setEntityDetailMap(newEntityDetailMap);
        }
        if (shouldUpdateBeneficiariesMap) {
          setBeneficiaryWaterfallVizMap(newBeneficiaryEstateWaterfallVizMap);
        }
        if (shouldUpdatePresentationConfiguration) {
          setPresentationConfiguration(newPresentationConfiguration);
        }
        resetPagination();
      }
      setPersistRunning(false);
    }
    void persistData();
  }, [
    beneficiaryWaterfallVizMap,
    bundles,
    contextPresentationConfiguration,
    entityDetailMap,
    estateWaterfallBundleConfigurationMap,
    householdId,
    presentationConfiguration,
    presentationId,
    previousBundles,
    previousPresentationConfiguration,
    previousTitle,
    refetch,
    resetPagination,
    setBeneficiaryWaterfallVizMap,
    setEntityDetailMap,
    setEstateWaterfallBundleConfigurationMap,
    setPersistRunning,
    setPresentationConfiguration,
    title,
    updateClientPresentationV2,
  ]);
  return { isPersistRunning };
}

export function useCurrentBundleIndex(): number {
  const { control } = useFormContext<ClientPresentationV2Shape>();
  const [bundles, selectedItemId] = useWatch({
    control,
    name: ['bundles', 'selectedItemId'],
  });
  const currentBundleIndex = useMemo(
    () =>
      bundles.findIndex(
        (bundle) =>
          bundle.id === selectedItemId ||
          bundle.pages.some((page) => page.id === selectedItemId)
      ),
    [bundles, selectedItemId]
  );

  return currentBundleIndex;
}

export function useIsDesignMode(): boolean {
  const { viewMode } = useClientPresentationDesignerV2Context();
  return viewMode === ClientPresentationDesignerV2ViewMode.Designer;
}

export function useNavigateToSlide(): {
  navigateToSlide: (slideId: string) => void;
} {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setValue } = useFormContext<ClientPresentationV2Shape>();

  const navigateToSlide = useCallback(
    (slideId: string) => {
      setValue('selectedItemId', slideId);

      if (
        slideId.includes(NEW_BUNDLE_ID_SENTINEL) ||
        slideId.includes(NEW_PAGE_ID_SENTINEL) ||
        slideId === searchParams.get(SLIDE_ID_QUERY_PARAM)
      ) {
        return;
      } else {
        setSearchParams(
          {
            [SLIDE_ID_QUERY_PARAM]: slideId,
          },
          {
            replace: true,
          }
        );
      }
    },
    [searchParams, setSearchParams, setValue]
  );

  return { navigateToSlide };
}
