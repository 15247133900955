import { isString } from 'lodash';
import { FieldPath, FieldValues, useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';

export function useRemainingCharacters<
  T extends FieldValues,
  TFieldName extends FieldPath<T>,
>(fieldName: TFieldName, limit: number) {
  const { control } = useFormContext<T>();
  const value = useWatch<T>({ control, name: fieldName });

  if (!isString(value)) {
    return { helpText: `${limit} characters remaining`, remaining: limit };
  }

  const remaining = Math.max(0, limit - value.length);
  return { helpText: `${remaining} characters remaining`, remaining };
}
