import { compact, isEmpty } from 'lodash';
import { useCallback } from 'react';
import { FieldValues, useWatch } from 'react-hook-form';

import {
  ExtendedTypeaheadQueryOpts,
  useAsyncTypeaheadProps,
} from '@/components/form/baseInputs/BackendTypeaheadSelectInput/hooks/useAsyncTypeaheadProps';
import {
  FormAwareTypeaheadMultiSelectInput,
  FormAwareTypeaheadMultiSelectInputProps,
} from '@/components/form/formAwareInputs/FormAwareTypeaheadMultiSelectInput';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import {
  IntegrationEntityKind,
  IntegrationEntityWhereInput,
} from '@/types/schema';
import { formatCurrency } from '@/utils/formatting/currency';

import {
  ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES,
  AssetIntegrationProviders,
} from '../constants';
import {
  GetIntegrationEntitiesQuery,
  GetIntegrationEntitiesQueryVariables,
  useGetIntegrationEntitiesQuery,
} from './graphql/IntegrationEntitiesTypeahead.generated';
import { INTEGRATION_ENTITY_EXTERNAL_KIND_DISPLAY } from './IntegrationEntitiesTypeahead.constants';
import { sortIntegrationEntities } from './IntegrationEntitiesTypeahead.utils';

export type IntegrationEntitiesTypeaheadProps<FormShape extends FieldValues> =
  Omit<
    FormAwareTypeaheadMultiSelectInputProps<FormShape>,
    'options' | 'inputValue' | 'onInputChange'
  > & {
    provider: AssetIntegrationProviders;
    /** filterToHouseholdId is the householdId that we want to filter the integration entities by */
    filterToHouseholdId: string;
    /** notLoggedIn, when true, exposes a message to the user about not having linked their account */
    notLoggedIn?: boolean;
    /**
     * allowAllEntities, when true, allows the user to select all entities across the provider, and not just those linked to the household.
     * Now that we allow linking a household to multiple integration clients, this is probably not necessary any more.
     */
    allowAllEntities?: boolean;
  };

const integrationProviderToIntegrationEntityKindsMap: Record<
  AssetIntegrationProviders,
  IntegrationEntityKind[]
> = {
  [AssetIntegrationProviders.ADDEPAR]: [IntegrationEntityKind.Addepar],
  [AssetIntegrationProviders.BLACK_DIAMOND]: [
    IntegrationEntityKind.BlackDiamondAccount,
    IntegrationEntityKind.BlackDiamondPortfolio,
  ],
  [AssetIntegrationProviders.ORION]: [IntegrationEntityKind.Orion],
  [AssetIntegrationProviders.CSV_IMPORT]: [IntegrationEntityKind.CsvImport],
};

export function IntegrationEntitiesTypeahead<FormShape extends FieldValues>({
  provider,
  filterToHouseholdId,
  allowAllEntities,
  notLoggedIn,
  ...typeaheadProps
}: IntegrationEntitiesTypeaheadProps<FormShape>) {
  const { createErrorFeedback } = useFeedback();
  const providerName = ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES[provider];

  const { control } = useFormContext<FormShape>();

  const selectedIntegrationEntityIds = useWatch({
    control,
    name: typeaheadProps.fieldName,
  });

  const searchTermToVariables = useCallback(
    (searchTerm: string) => {
      const optionsLike: IntegrationEntityWhereInput = {
        and: compact([
          {
            nameContainsFold: searchTerm,
            kindIn: integrationProviderToIntegrationEntityKindsMap[provider],
          },
          // filter the possible results to only entities that are valid for this household
          // or grantor(s)
          getIntegrationEntityWhereInputsForProvider({
            provider,
            filterToHouseholdId,
            allowAllEntities: Boolean(allowAllEntities),
          }),
        ]),
      };

      return { optionsLike };
    },
    [allowAllEntities, filterToHouseholdId, provider]
  );

  const getSelectedOptionVariables = useCallback(() => {
    const values = selectedIntegrationEntityIds as string[];
    return {
      selectedOptionsLike: {
        idIn: isEmpty(values) ? [''] : compact(values),
      },
    };
  }, [selectedIntegrationEntityIds]);

  const toOptions: ExtendedTypeaheadQueryOpts<
    string,
    GetIntegrationEntitiesQuery,
    GetIntegrationEntitiesQueryVariables
  >['toOptions'] = useCallback((data) => {
    const sortedData = sortIntegrationEntities(data);
    return sortedData.map((integrationEntity) => {
      return {
        value: integrationEntity.id,
        display: compact([
          integrationEntity.name,
          integrationEntity.value &&
            `(${formatCurrency(integrationEntity.value, { notation: 'compact' })})`,
        ]).join(' '),
        badgeText: integrationEntity.externalKind
          ? INTEGRATION_ENTITY_EXTERNAL_KIND_DISPLAY[
              integrationEntity.externalKind
            ]
          : undefined,
        groupName: integrationEntity.integrationClient?.name ?? 'No client',
      };
    });
  }, []);

  const [asyncTypeaheadProps] = useAsyncTypeaheadProps(
    useGetIntegrationEntitiesQuery,
    {
      searchTermToVariables,
      getSelectedOptionVariables,
      toOptions,
      skipAutocompleteResetClear: true,
      onError: createErrorFeedback(
        `Error fetching ${providerName} entities. Please refresh the page to try again.`
      ),
    }
  );

  return (
    <FormAwareTypeaheadMultiSelectInput<FormShape>
      {...asyncTypeaheadProps}
      groupBy={(option) => option.groupName ?? ''}
      placeholder={`Search for an account or entity...`}
      helpText={
        notLoggedIn
          ? `You have not linked your ${providerName} account. Go to manage profile under user settings to link.`
          : undefined
      }
      {...typeaheadProps}
      disabled={typeaheadProps.disabled || notLoggedIn}
    />
  );
}

interface GetIntegrationEntityWhereInputsParams {
  provider: AssetIntegrationProviders;
  filterToHouseholdId: string | undefined;
  allowAllEntities: boolean;
}

function getIntegrationEntityWhereInputsForProvider({
  provider: _p,
  filterToHouseholdId,
  allowAllEntities,
}: GetIntegrationEntityWhereInputsParams): IntegrationEntityWhereInput | null {
  // now that both addepar and black diamond link integration clients to households,
  // we can just do this for all providers
  if (allowAllEntities) {
    return null;
  }

  return {
    hasIntegrationClientWith: [
      {
        hasClientHouseholdsWith: [
          {
            id: filterToHouseholdId ?? '',
          },
        ],
      },
    ],
  };
}
