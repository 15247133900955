import Decimal from 'decimal.js';

import { AfterDeath } from '@/types/schema';
import { PathsOf } from '@/types/subform';

export type HypotheticalSaleLoanFormTab =
  | 'basicInformation'
  | 'assets'
  | 'payment';

export type HypotheticalSaleLoanFormPaymentModel =
  | 'amortizing'
  | 'interestOnlyWithBalloon'
  | 'lumpSum';

export type HypotheticalSaleLoanFormFundingKind = 'proRata' | 'assets';

export type HypotheticalSaleLoanFormFundingPattern = 'amount' | 'percent';

export interface HypotheticalSaleLoanFormShape {
  // DISPLAY-ONLY PROPERTIES
  _kind: HypotheticalSaleLoanFormKind;

  // ASSUMPTIONS PROPERTIES
  // If set, projections will run with these assumptions instead of the waterfall's
  firstGrantorDeathYear: number | null;
  secondGrantorDeathYear: number | null;
  growthProfileOverrideId: string | null;

  // ILLUSTRATION PROPERTIES
  illustrationScenario: AfterDeath;
  scheduleDisplayType: 'paymentSchedule' | 'projectedGrowth';

  // FORM PROPERTIES
  displayName: string;
  /** The seller, an entity or individual */
  sellerId: string;
  /** The recipient, an entity or individual */
  recipientId: string;
  /** The start date  */
  startDate: Date | null;
  /** The term length in years. Should be a number, but the form input represents it as a string */
  termLength: string;
  /** The AFR interest rate */
  applicableInterestRate: Decimal | null;
  pretermGrantorDeathHandling: 'repaid' | 'canceled';
  /** The payment model, exposed on the payment tab */
  paymentModel: HypotheticalSaleLoanFormPaymentModel | null;
  /** The funding kind of the hypothetical sale or loan, pro-rata or assets */
  fundingKind: HypotheticalSaleLoanFormFundingKind | null;
  /** The pattern of the pro-rata funding, dollar or percent. Only applicable if fundingKind is proRata */
  proRataPattern: HypotheticalSaleLoanFormFundingPattern;
  /** The dollar amount of the pro-rata funding. Only applicable if fundingKind is proRata and proRataPattern is dollar  */
  proRataAmount: Decimal | null;
  /** The percent amount of the pro-rata funding. Only applicable if fundingKind is proRata and proRataPattern is percent */
  proRataPercent: Decimal | null;
  /** The pattern of the assets funding, dollar or percent */
  assetsPattern: HypotheticalSaleLoanFormFundingPattern;
  /** The selected asset classes and business ids. Only applicable if fundingKind is assets */
  selectedAssetClassesAndBusinessIds: string[];
  /** The selected asset class and business values, which map to the selectedAssetClassesAndBusinessIds */
  selectedAssetClassAndBusinessValues: Record<
    string,
    {
      amount: Decimal | null;
      percent: Decimal | null;
    }
  >;
  /** If true, the transfer will be liquidated to cash */
  forceLiquidationOnTransfer: boolean;
}

export type HypotheticalSaleLoanFormPaths =
  PathsOf<HypotheticalSaleLoanFormShape>;

export type HypotheticalSaleLoanFormKind = 'sale' | 'loan';

type SaleLoanFormField = keyof HypotheticalSaleLoanFormShape;

export const fieldTabMap: Record<
  SaleLoanFormField,
  HypotheticalSaleLoanFormTab | null
> = {
  // display-only
  _kind: null,

  // assumptions aren't part of the form and won't error
  firstGrantorDeathYear: null,
  secondGrantorDeathYear: null,
  growthProfileOverrideId: null,

  // illustration; not part of the form and won't error
  illustrationScenario: null,
  scheduleDisplayType: null,

  // basicInformation
  displayName: 'basicInformation',
  sellerId: 'basicInformation',
  recipientId: 'basicInformation',
  startDate: 'basicInformation',
  termLength: 'basicInformation',
  applicableInterestRate: 'basicInformation',
  pretermGrantorDeathHandling: 'basicInformation',

  // assets
  fundingKind: 'assets',
  proRataPattern: 'assets',
  proRataAmount: 'assets',
  proRataPercent: 'assets',
  assetsPattern: 'assets',
  selectedAssetClassesAndBusinessIds: 'assets',
  selectedAssetClassAndBusinessValues: 'assets',
  forceLiquidationOnTransfer: 'assets',

  // payment
  paymentModel: 'payment',
};
