import { compact } from 'lodash';
import { useWatch } from 'react-hook-form';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { TrendUp01Icon } from '@/components/icons/TrendUp01Icon';
import { useFormContext } from '@/components/react-hook-form';

import { HypotheticalSaleLoanFormShape } from '../EstateWaterfallHypotheticalSaleLoanModal.types';

function getButtonLabel(
  growthProfileName: string | null,
  firstDeathYear: number | null,
  secondDeathYear: number | null
) {
  if (!growthProfileName || !firstDeathYear) {
    return 'Assumptions';
  }

  const deathYears = compact([firstDeathYear, secondDeathYear]);
  return `${growthProfileName}, ${deathYears.join(' & ')} death`;
}

export interface SaleLoanIllustrationAssumptionsButtonProps
  extends Partial<ButtonProps> {
  growthProfileOptions: SelectInputOption<string>[];
}

export function SaleLoanIllustrationAssumptionsButton({
  growthProfileOptions,
  ...props
}: SaleLoanIllustrationAssumptionsButtonProps) {
  const { control } = useFormContext<HypotheticalSaleLoanFormShape>();
  const [growthProfileId, firstDeathYear, secondDeathYear] = useWatch({
    control,
    name: [
      'growthProfileOverrideId',
      'firstGrantorDeathYear',
      'secondGrantorDeathYear',
    ],
  });

  const growthProfile = growthProfileOptions.find(
    (option) => option.value === growthProfileId
  );

  const buttonLabel = getButtonLabel(
    growthProfile?.display ?? '',
    firstDeathYear,
    secondDeathYear
  );

  return (
    <Button
      variant="secondary"
      size="sm"
      startIcon={TrendUp01Icon}
      iconProps={{ size: 20 }}
      {...props}
    >
      {buttonLabel}
    </Button>
  );
}
