import { useApolloClient } from '@apollo/client';
import { Box, Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { EditButton } from '@/components/form/baseInputs/Button/EditButton';
import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { Card } from '@/components/layout/Card/Card';
import { useFormContext } from '@/components/react-hook-form';
import { getGreaterThanCurrentYearValidation } from '@/components/utils/inputUtils';
import { GrowthProfileModal } from '@/modules/growthProfiles/GrowthProfileModal/GrowthProfileModal';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import {
  HypotheticalSaleLoanFormPaths,
  HypotheticalSaleLoanFormShape,
} from '../EstateWaterfallHypotheticalSaleLoanModal.types';
import { Operations } from './graphql/SaleLoanIllustration.generated';

interface SaleLoanIllustrationAssumptionsCardProps {
  growthProfileOptions: SelectInputOption<string>[];
  refetchProjections: () => void;
}

export function SaleLoanIllustrationAssumptionsCard({
  growthProfileOptions,
  refetchProjections,
}: SaleLoanIllustrationAssumptionsCardProps) {
  const apolloClient = useApolloClient();
  const { control } = useFormContext<HypotheticalSaleLoanFormShape>();
  const { householdId, isTwoClientHousehold } = useHouseholdDetailsContext();
  const [growthProfileId, firstGrantorDeathYear] = useWatch({
    control,
    name: [
      'growthProfileOverrideId',
      'firstGrantorDeathYear',
      'secondGrantorDeathYear',
    ],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCloseEditGrowthProfileModal = useCallback(async () => {
    setIsModalOpen(false);
    await apolloClient.refetchQueries({
      include: [Operations.Query.GrowthProfileOptions],
    });
    // need to refetch the projections specifically because the query is no-cache
    refetchProjections();
  }, [apolloClient, refetchProjections]);

  const validateCurrentYear = useCallback((v: unknown) => {
    return getGreaterThanCurrentYearValidation(v as number | null);
  }, []);

  const hasRequiredData = growthProfileId && householdId;
  return (
    <>
      {isModalOpen && hasRequiredData && (
        <GrowthProfileModal
          onClose={handleCloseEditGrowthProfileModal}
          householdId={householdId}
          growthProfileId={growthProfileId}
        />
      )}
      <Card variant="inner-shadow" sx={{ padding: 3 }}>
        {/* align items to the top of the row so that any errors are aligned correctly */}
        <Stack direction="row" spacing={4} alignItems="flex-start">
          <Stack direction="row" spacing={1} flexGrow={1} alignItems="flex-end">
            <Box flexGrow={1}>
              <FormAwareSelectInput
                control={control}
                required
                fieldName="growthProfileOverrideId"
                label="Growth profile"
                options={growthProfileOptions}
              />
            </Box>
            <EditButton
              size="lg"
              aria-label="Edit growth profile"
              onClick={() => setIsModalOpen(true)}
            />
          </Stack>
          <Stack direction="row" spacing={2} sx={{ flexShrink: 0 }}>
            <FormAwareFormattedNumberInput
              control={control}
              required
              fieldName={
                'firstGrantorDeathYear' as const satisfies HypotheticalSaleLoanFormPaths
              }
              label="Year of 1st death"
              decimalScale={0}
              formatHashString="####"
              fixedDecimalScale
              allowNegative={false}
              validateOnChange
              validation={{
                validate: validateCurrentYear,
              }}
            />
            {isTwoClientHousehold && (
              <FormAwareFormattedNumberInput
                control={control}
                required
                fieldName={
                  'secondGrantorDeathYear' as const satisfies HypotheticalSaleLoanFormPaths
                }
                label="Year of 2nd death"
                formatHashString="####"
                decimalScale={0}
                fixedDecimalScale
                allowNegative={false}
                validateOnChange
                validation={{
                  validateCurrentYear: (v) =>
                    getGreaterThanCurrentYearValidation(v as number | null),
                  validate: (value) => {
                    const valueNumber = value ? Number(value) : null;
                    if (
                      valueNumber &&
                      firstGrantorDeathYear &&
                      valueNumber < firstGrantorDeathYear
                    ) {
                      return `Must be ≥ ${firstGrantorDeathYear}`;
                    }

                    return undefined;
                  },
                }}
              />
            )}
          </Stack>
        </Stack>
      </Card>
    </>
  );
}
