import { Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { compact, size } from 'lodash';
import { useCallback, useMemo } from 'react';

import { Share06Icon } from '@/components/icons/Share06Icon';
import { EmptyListActionCard } from '@/components/lists/EmptyListActionCard';
import {
  ButtonTab,
  TabContent,
  Tabs,
} from '@/components/navigation/NavigationTabs';
import { TabsProvider } from '@/components/navigation/NavigationTabs/Tabs.provider';
import { useActiveTab } from '@/components/navigation/NavigationTabs/useActiveTab';
import { Loader } from '@/components/progress/Loader/Loader';
import { useModalState } from '@/hooks/useModalState';
import { EstateWaterfallHypotheticalSaleLoanModal } from '@/modules/estateWaterfall/components/EstateWaterfallHypotheticalSaleLoanModal/EstateWaterfallHypotheticalSaleLoanModal';
import { ClientProfileSummaryPanel_ClientProfileFragment } from '@/modules/summaryPanels/ClientProfileSummaryPanel/graphql/ClientProfileSummaryPanel.generated';
import { CreateHypotheticalEventButton } from '@/modules/summaryPanels/HypotheticalTransfersSummaryPanel/CreateHypotheticalEventButton';
import { sumDecimalJS } from '@/utils/decimalJSUtils';

import { useWaterfallRouteContext } from '../../contexts/waterfallRoute/waterfallRoute.context';
import { EntityDetail_EntityFragment } from '../graphql/EntityDetailPage.generated';
import { useGetHypotheticalTransfers } from './hooks/useGetHypotheticalTransfers';
import { HypotheticalTransfersSummaryTable } from './HypotheticalTransfersSummaryTable';
import { SaleLoanItemsTable } from './SaleLoanItemsTable';
import { TransferItems, TransferTab } from './TransferItems';

interface HypotheticalTransfersCardInnerViewState {
  showNoTransfersMessage: boolean;
  showLoader: boolean;
  showTransfers: boolean;
}

interface HypotheticalTransfersCardInnerProps {
  transferReferenceObj:
    | EntityDetail_EntityFragment
    | ClientProfileSummaryPanel_ClientProfileFragment;
  waterfallId: string;
  householdId: string;
  entityId: string;
}
function HypotheticalTransfersCardInner({
  transferReferenceObj,
  waterfallId,
  householdId,
  entityId,
}: HypotheticalTransfersCardInnerProps) {
  const [
    { isModalOpen: isSaleLoanModalOpen, data: saleLoanId },
    { closeModal: closeSaleLoanModal, openModal: openSaleLoanModal },
  ] = useModalState<string>();

  const { activeTab, setActiveTab } = useActiveTab<TransferTab>({
    defaultTab: TransferTab.Outbound,
    filterSearchParam: 'transfer-direction',
  });

  const isEntity = transferReferenceObj.__typename === 'Entity';
  const isIndividual = transferReferenceObj.__typename === 'ClientProfile';

  let currentValue: Decimal | undefined = undefined;

  if (isEntity) {
    currentValue = transferReferenceObj.subtype.currentValue;
  } else if (isIndividual) {
    currentValue = sumDecimalJS(
      compact(transferReferenceObj.ownedOwnershipStakes)?.map(
        (stake) => stake.ownedValue
      )
    );
  }

  const {
    loading: outboundLoading,
    transfers: outboundTransfers,
    // sourceLoans are sales/loans that are coming from and repaid to this entity
    saleLoans: sourceLoans,
    referenceObjectValueHistory,
    refetch: refetchOutbound,
  } = useGetHypotheticalTransfers({
    transferReferenceObjId: transferReferenceObj.id,
    waterfallId,
    sourceTransferReferenceObjId: transferReferenceObj.id,
    isEntity,
    isIndividual,
  });

  const {
    loading: inboundLoading,
    transfers: inboundTransfers,
    // recipientLoans are sales/loans that are going to and repaid from this entity
    saleLoans: recipientLoans,
    refetch: refetchInbound,
  } = useGetHypotheticalTransfers({
    transferReferenceObjId: transferReferenceObj.id,
    waterfallId,
    destinationTransferReferenceObjId: transferReferenceObj.id,
    isEntity,
    isIndividual,
  });

  const refetchTransfers = useCallback(() => {
    void refetchOutbound();
    void refetchInbound();
  }, [refetchInbound, refetchOutbound]);

  const handleCloseSaleLoanModal = useCallback(() => {
    closeSaleLoanModal();
    void refetchTransfers();
  }, [closeSaleLoanModal, refetchTransfers]);

  const isLoading = outboundLoading || inboundLoading;

  const transfers = {
    inboundTransfers: inboundTransfers ?? [],
    outboundTransfers: outboundTransfers ?? [],
  };

  const saleLoans = {
    recipientLoans: recipientLoans ?? [],
    sourceLoans: sourceLoans ?? [],
  };

  const hasTransfers =
    transfers.inboundTransfers.length > 0 ||
    transfers.outboundTransfers.length > 0;

  const hasSaleLoans =
    saleLoans.recipientLoans.length > 0 || saleLoans.sourceLoans.length > 0;

  // sales and loans are a little bit funny, but they count as both inbound and outbound because
  // there's an inbound component and an outbound component for each
  const totalAssociatedSaleLoans =
    size(saleLoans.sourceLoans) + size(saleLoans.recipientLoans);

  const outboundCount =
    (outboundTransfers?.length ?? 0) + totalAssociatedSaleLoans;

  const inboundCount =
    (inboundTransfers?.length ?? 0) + totalAssociatedSaleLoans;

  const viewState: HypotheticalTransfersCardInnerViewState = {
    showNoTransfersMessage: !hasTransfers && !hasSaleLoans && !isLoading,
    showLoader: isLoading,
    showTransfers: (hasTransfers || hasSaleLoans) && !isLoading,
  };

  const AddATransferButton = useMemo(() => {
    return (
      <CreateHypotheticalEventButton
        onCloseSubmodal={refetchTransfers}
        waterfallId={waterfallId}
        householdId={householdId}
        variant={viewState.showTransfers ? 'secondary' : 'primary'}
        disabled={!transferReferenceObj}
        size="lg"
        fullWidth
      />
    );
  }, [
    householdId,
    refetchTransfers,
    transferReferenceObj,
    viewState.showTransfers,
    waterfallId,
  ]);

  return (
    <>
      {isSaleLoanModalOpen && saleLoanId && (
        <EstateWaterfallHypotheticalSaleLoanModal
          isOpen={isSaleLoanModalOpen}
          onClose={handleCloseSaleLoanModal}
          saleLoanId={saleLoanId}
          waterfallId={waterfallId}
          householdId={householdId}
          initialKind={null}
        />
      )}
      <Loader
        boxProps={{
          sx: {
            textAlign: 'center',
            my: 3,
            display: viewState.showLoader ? undefined : 'none',
          },
        }}
      />
      <Stack
        p={viewState.showTransfers ? 0 : 3}
        py={0}
        spacing={viewState.showTransfers ? 3 : 6}
        display={viewState.showLoader ? 'none' : undefined}
      >
        {viewState.showNoTransfersMessage && (
          <EmptyListActionCard
            icon={Share06Icon}
            heading={`Specify transfers`}
            description={`No transfers have been created for this entity`}
            action={AddATransferButton}
          />
        )}
        <Stack display={viewState.showTransfers ? undefined : 'none'}>
          <TabsProvider currentTab={activeTab}>
            <Stack spacing={3}>
              <Tabs fullWidth>
                <ButtonTab
                  display="Outbound"
                  isActive={activeTab === TransferTab.Outbound}
                  onClick={() => setActiveTab(TransferTab.Outbound)}
                  badge={outboundCount.toString()}
                />
                <ButtonTab
                  display="Inbound"
                  isActive={activeTab === TransferTab.Inbound}
                  onClick={() => setActiveTab(TransferTab.Inbound)}
                  badge={inboundCount.toString()}
                />
              </Tabs>
              <TabContent tabKey={TransferTab.Outbound}>
                <Stack spacing={3}>
                  <TransferItems
                    transfers={transfers}
                    activeTab={TransferTab.Outbound}
                    transferReferenceObj={transferReferenceObj}
                    waterfallId={waterfallId}
                    householdId={householdId}
                    entityId={entityId}
                  />
                  <SaleLoanItemsTable
                    saleLoansWithProjections={saleLoans}
                    direction={TransferTab.Outbound}
                    onEditSaleLoan={openSaleLoanModal}
                  />
                </Stack>
              </TabContent>
              <TabContent tabKey={TransferTab.Inbound}>
                <Stack spacing={3}>
                  <TransferItems
                    transfers={transfers}
                    activeTab={TransferTab.Inbound}
                    transferReferenceObj={transferReferenceObj}
                    waterfallId={waterfallId}
                    householdId={householdId}
                    entityId={entityId}
                  />

                  <SaleLoanItemsTable
                    saleLoansWithProjections={saleLoans}
                    direction={TransferTab.Inbound}
                    onEditSaleLoan={openSaleLoanModal}
                  />
                </Stack>
              </TabContent>
            </Stack>
          </TabsProvider>
        </Stack>
        <HypotheticalTransfersSummaryTable
          referenceObjectId={transferReferenceObj.id}
          referenceObjectValueHistory={referenceObjectValueHistory}
          currentValue={currentValue}
          transfers={transfers}
          saleLoansWithProjections={saleLoans}
          isIndividual={isIndividual}
          isEntity={isEntity}
        />
        {!viewState.showNoTransfersMessage && AddATransferButton}
      </Stack>
    </>
  );
}

interface HypotheticalTransfersCardProps {
  entity?: EntityDetail_EntityFragment;
  individual?: ClientProfileSummaryPanel_ClientProfileFragment;
}

export function HypotheticalTransfersCard({
  entity,
  individual,
}: HypotheticalTransfersCardProps) {
  const { waterfallId } = useWaterfallRouteContext();

  const transferReferenceObj = entity ?? individual;

  if (!waterfallId || !transferReferenceObj) {
    return null;
  }

  return (
    <HypotheticalTransfersCardInner
      key={entity?.id ?? individual?.id ?? ''}
      transferReferenceObj={transferReferenceObj}
      waterfallId={waterfallId}
      householdId={transferReferenceObj?.household?.id ?? ''}
      entityId={entity?.id ?? individual?.id ?? ''}
    />
  );
}
