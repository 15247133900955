import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useContext, useEffect, useMemo } from 'react';

import { ModalContext } from '@/components/modals/ModalContext';
import { Popper } from '@/components/poppers/Popper/Popper';
import { useDelayedCloseBehavior } from '@/hooks/useDelayedCloseBehavior';
import { makeUnprintableStyle } from '@/utils/makeUnprintableStyle';

import { HelpIconButton } from './HelpIconButton';

type Props = React.PropsWithChildren<{
  sx?: SxProps<Theme>;
  wrappedComponent?: JSX.Element;
  // this is a prop that allows a developer to force the tooltip to stay open for debugging
  // and development
  _devOnlyOpen?: boolean;
  center?: boolean;
}>;

export function ContextualHelpTooltip({
  sx,
  children,
  wrappedComponent,
  _devOnlyOpen,
  center,
}: Props) {
  const anchorEl = React.useRef(null);
  const { modalState } = useContext(ModalContext);
  const anyOpen = useMemo(() => {
    return Object.values(modalState).some((modal) => modal === true);
  }, [modalState]);

  const { isOpen, setIsMouseInTargetArea } = useDelayedCloseBehavior({
    isForceOpen: _devOnlyOpen,
  });

  useEffect(() => {
    // if any dialog is open, we want to close the tooltip
    if (anyOpen) {
      setIsMouseInTargetArea(false);
    }
  }, [anyOpen, setIsMouseInTargetArea]);

  // If we provide a width to the tooltip, we want to use that width instead of the width
  // of the wrapped component. This is useful when setting widths as a percentage of the
  // container element.
  const shouldAssumeWidthOfWrappedComponent = !(sx && 'width' in sx);
  const shouldAssumeHeightOfWrappedComponent = !(sx && 'height' in sx);

  return (
    <Box
      sx={makeUnprintableStyle({
        display: 'flex',
        alignItems: 'center',
        ...sx,
      })}
    >
      {wrappedComponent ? (
        <div
          onMouseEnter={() => setIsMouseInTargetArea(true)}
          onMouseLeave={() => setIsMouseInTargetArea(false)}
          ref={anchorEl}
          style={{
            width: shouldAssumeWidthOfWrappedComponent ? 'auto' : '100%',
            height: shouldAssumeHeightOfWrappedComponent ? 'auto' : '100%',
          }}
        >
          {wrappedComponent}
        </div>
      ) : (
        <HelpIconButton
          ref={anchorEl}
          buttonProps={{
            onMouseEnter: () => setIsMouseInTargetArea(true),
            onMouseLeave: () => setIsMouseInTargetArea(false),
            sx: {
              cursor: 'default',
            },
          }}
        />
      )}
      {children && (
        <Popper
          anchorRef={anchorEl}
          onMouseEnter={() => setIsMouseInTargetArea(true)}
          onMouseLeave={() => setIsMouseInTargetArea(false)}
          open={isOpen}
          center={center}
        >
          {children}
        </Popper>
      )}
    </Box>
  );
}
