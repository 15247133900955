import { useWatch } from 'react-hook-form';

import { FormAwareInputRepeater } from '@/components/form/formAwareInputs/FormAwareInputRepeater/FormAwareInputRepeater';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { ControllingPersonsOrSignatories } from '@/modules/content/tooltipContent/ControllingPersonsOrSignatories';
import {
  BASIC_INFORMATION_SUBFORM_NAMESPACE as BASIC_INFORMATION_SUBFORM,
  Fields as BasicInformationSubformFields,
  SubformField as BasicInformationSubformPaths,
} from '@/modules/entities/BasicInformationSubform/BasicInformationSubform.types';
import {
  CONTROLLING_PERSONS_ADD_LABEL,
  CONTROLLING_PERSONS_LABEL,
} from '@/modules/entities/EntitySubforms/utils/shared/controllingParties.utils';
import { getGrantorOptionsFromData } from '@/modules/entities/inputs/PrimaryClientDropdown/PrimaryClientDropdown.utils';
import {
  getOptionsFromData as getTrusteeOptionsFromData,
  TrusteeDropdown,
} from '@/modules/entities/inputs/TrusteeDropdown/TrusteeDropdown';
import { WealthOwnerTooltip } from '@/modules/entities/principals/NonGrantorPrincipalFields/components/WealthOwnerTooltip';
import { useNonGrantorPrincipalFieldsFormOptionsQuery } from '@/modules/entities/principals/NonGrantorPrincipalFields/graphql/NonGrantorPrincipalFields.generated';
import {
  VariantType,
  WealthOwnerType,
} from '@/modules/entities/principals/NonGrantorPrincipalFields/NonGrantorPrincipalFields.types';

export interface NonGrantorPrincipalFieldsProps {
  householdId: string;
  variant: VariantType;
  wealthOwnerType: WealthOwnerType;
  isInsuranceAccount: boolean;
}

export function NonGrantorPrincipalFields({
  householdId,
  variant,
  wealthOwnerType,
  isInsuranceAccount,
}: NonGrantorPrincipalFieldsProps) {
  const { control } = useFormContext<BasicInformationSubformFields>();

  const { data, loading } = useNonGrantorPrincipalFieldsFormOptionsQuery({
    variables: {
      where: {
        id: householdId,
      },
    },
  });

  const subformValues = useWatch({
    name: BASIC_INFORMATION_SUBFORM,
    control,
  });

  const grantorOptions = getGrantorOptionsFromData(
    data?.households.edges?.[0]?.node?.possibleGrantors || [],
    subformValues
  );

  const trusteeOptions = getTrusteeOptionsFromData({
    data: data?.households.edges?.[0]?.node ?? null,
    subformValues,
  });

  return (
    <>
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          {[
            'multipleOwnerNoPercent',
            'multipleOwnerNoPercentNoControllingParty',
          ].includes(variant) && (
            <FormAwareInputRepeater<BasicInformationSubformFields>
              name={`${BASIC_INFORMATION_SUBFORM}.owners`}
              emptyValue={{
                ownerId: '',
                percentOwned: null,
              }}
              control={control}
              shouldInit
              addAnotherItemText={`Add additional ${wealthOwnerType.toLowerCase()}`}
              render={(i: number) => {
                const isFirstInput = i === 0;
                return (
                  <FormLayoutRow>
                    <FormLayoutItem width={12}>
                      <FormAwareSelectInput<BasicInformationSubformFields>
                        fieldName={
                          `${BASIC_INFORMATION_SUBFORM}.owners.${i}.ownerId` as const satisfies BasicInformationSubformPaths
                        }
                        label={`${wealthOwnerType}(s)`}
                        hideLabel={!isFirstInput}
                        contextualHelp={
                          <WealthOwnerTooltip
                            wealthOwnerType={wealthOwnerType}
                            isInsuranceAccount={isInsuranceAccount}
                          />
                        }
                        control={control}
                        options={grantorOptions}
                        disabled={loading}
                      />
                    </FormLayoutItem>
                  </FormLayoutRow>
                );
              }}
            />
          )}
          {variant === 'default' && (
            <FormAwareSelectInput<BasicInformationSubformFields>
              control={control}
              fieldName={
                `${BASIC_INFORMATION_SUBFORM}.owner.ownerId` as const satisfies BasicInformationSubformPaths
              }
              label="Owner"
              options={grantorOptions}
              contextualHelp={
                <WealthOwnerTooltip
                  wealthOwnerType={wealthOwnerType}
                  isInsuranceAccount={isInsuranceAccount}
                />
              }
              disabled={loading}
              required
            />
          )}
        </FormLayoutItem>
      </FormLayoutRow>
      {variant !== 'multipleOwnerNoPercentNoControllingParty' && (
        <FormLayoutRow>
          <FormLayoutItem width={12}>
            <FormAwareInputRepeater<BasicInformationSubformFields>
              name={
                `${BASIC_INFORMATION_SUBFORM}.controllingParties` as const satisfies BasicInformationSubformPaths
              }
              emptyValue={{
                controllingPartyId: '',
              }}
              control={control}
              shouldInit
              addAnotherItemText={CONTROLLING_PERSONS_ADD_LABEL}
              render={(i: number) => {
                const isFirstInput = i === 0;
                return (
                  <TrusteeDropdown
                    fieldName={
                      `${BASIC_INFORMATION_SUBFORM}.controllingParties.${i}.controllingPartyId` as const satisfies BasicInformationSubformPaths
                    }
                    label={CONTROLLING_PERSONS_LABEL}
                    hideLabel={!isFirstInput}
                    options={trusteeOptions}
                    contextualHelp={<ControllingPersonsOrSignatories />}
                    householdId={householdId}
                    blockNavigationAfterCreate
                  />
                );
              }}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
    </>
  );
}
