import { cx } from '@emotion/css';
import { Theme, Typography, TypographyProps } from '@mui/material';
import { CSSProperties } from 'react';
import { makeStyles } from 'tss-react/mui';

import {
  TypographyClamped,
  TypographyClampedProps,
} from '@/components/typography/TypographyClamped';
import { FONT_SIZING } from '@/styles/tokens/fonts';

import { getTextColorForVariant } from './Tile.utils';
import { TileFontSizeVariant, TileVariant } from './types';

const FONT_SIZE_ADJUSTMENTS: Record<TileFontSizeVariant, number> = {
  SM: 0,
  MD: 0.5,
  LG: 1,
} as const;

const parseRemValue = (value: string | number): number => {
  if (typeof value === 'number') return value;

  const remValue = value.match(/^([\d.]+)rem$/)?.[1];
  if (!remValue) {
    throw new Error(`Invalid rem value: ${value}. Expected format: "1.5rem"`);
  }

  const parsed = parseFloat(remValue);
  if (isNaN(parsed)) {
    throw new Error(`Failed to parse rem value: ${value}`);
  }

  return parsed;
};

export interface TileThemedTypographyProps extends TypographyProps {
  tileVariant: TileVariant;
  fontSizeVariant: TileFontSizeVariant;
}

const useStyles = makeStyles<{
  tileVariant: TileVariant;
  fontSizeVariant: TileFontSizeVariant;
  variant?: keyof Theme['typography'];
}>()((theme, { tileVariant, fontSizeVariant, variant }) => {
  const typographyStyles = variant
    ? (theme.typography[variant] as CSSProperties)
    : null;

  const baseFontSize = typographyStyles?.fontSize ?? FONT_SIZING.md.fontSize;
  const baseLineHeight =
    typographyStyles?.lineHeight ?? FONT_SIZING.md.lineHeight;

  const baseFontSizeRem = parseRemValue(baseFontSize);
  const baseLineHeightRem = parseRemValue(baseLineHeight);

  const adjustment = FONT_SIZE_ADJUSTMENTS[fontSizeVariant];
  const fontSize = baseFontSizeRem + adjustment;
  const lineHeight = baseLineHeightRem + adjustment;

  return {
    root: {
      color: getTextColorForVariant(theme, tileVariant),
      fontSize: `${fontSize}rem`,
      lineHeight: `${lineHeight}rem`,
    },
  };
});

export const TileThemedTypography = ({
  tileVariant,
  fontSizeVariant,
  variant,
  className,
  ...otherProps
}: TileThemedTypographyProps) => {
  const { classes } = useStyles({
    tileVariant,
    fontSizeVariant,
    variant: variant as keyof Theme['typography'],
  });
  return (
    <Typography
      {...otherProps}
      variant={variant}
      className={cx(classes.root, className)}
    />
  );
};

export interface TileThemedTypographyClampedProps
  extends TypographyClampedProps {
  tileVariant: TileVariant;
  fontSizeVariant: TileFontSizeVariant;
}

export const TileThemedTypographyClamped = ({
  tileVariant,
  fontSizeVariant,
  className,
  variant,
  ...otherProps
}: TileThemedTypographyClampedProps) => {
  const { classes } = useStyles({
    tileVariant,
    fontSizeVariant,
    variant: variant as keyof Theme['typography'],
  });

  return (
    <TypographyClamped
      {...otherProps}
      variant={variant}
      className={cx(classes.root, className)}
    />
  );
};
