import { useCallback, useMemo, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { MessageTextSquare02Icon } from '@/components/icons/MessageTextSquare02Icon';
import { UserFeedbackModal } from '@/components/modals/UserFeedbackModal/UserFeedbackModal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import {
  TrackUserEventModule,
  useTrackUserEvent,
} from '@/hooks/useTrackUserEvent';
import { useCreateUserFeedbackMutation } from '@/modules/aiUserFeedback/graphql/userFeedback.generated';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { useAICapabilitiesEnabled } from '@/modules/tenant/TenantDetailsContext/hooks/useAICapabilitiesEnabled';
import { COLORS } from '@/styles/tokens/colors';
import { UserFeedbackFeedbackCategory } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

export interface AIUserFeedbackButtonProps {
  feedbackCategory: UserFeedbackFeedbackCategory;
}

export function AIUserFeedbackButton({
  feedbackCategory,
}: AIUserFeedbackButtonProps) {
  const trackUserEvent = useTrackUserEvent();
  const aiEnabled = useAICapabilitiesEnabled();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const { householdId } = useHouseholdDetailsContext();
  const { entityId } = useEntityDetailsContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const userEventModule: TrackUserEventModule = useMemo(() => {
    switch (feedbackCategory) {
      case UserFeedbackFeedbackCategory.AiDpSuggestions:
        return TrackUserEventModule.AI_DP;
      case UserFeedbackFeedbackCategory.EntityFormAiSuggestions:
        return TrackUserEventModule.AI_SUGGESTION;
      case UserFeedbackFeedbackCategory.AiOnboardingSuggestions:
        return TrackUserEventModule.AI_ONBOARDING;
      default:
        throw new UnreachableError({
          case: feedbackCategory,
          message: 'Unknown feedback category',
        });
    }
  }, [feedbackCategory]);

  const [createUserFeedbackMutation] = useCreateUserFeedbackMutation({
    onCompleted: (data) => {
      showFeedback('Feedback submitted!', { variant: 'success' });
      trackUserEvent(`${userEventModule} user feedback submitted`, {
        feedback: data.createUserFeedback.feedback,
        feedbackCategory: data.createUserFeedback.feedbackCategory,
        entityId: data.createUserFeedback?.entity?.id,
        householdId: data.createUserFeedback?.household?.id,
      });
    },
    onError: (error) => {
      showFeedback('Failed to save your feedback. Please try again later.');
      reportError(
        '[AIUserFeedbackButton] error submitting user feedback',
        error,
        {
          householdId,
          entityId,
          feedbackCategory,
        }
      );
    },
  });

  const provideFeedback = useCallback(
    (feedback: string) => {
      return createUserFeedbackMutation({
        variables: {
          input: {
            householdID: householdId!,
            entityID: entityId,
            feedbackCategory,
            feedback,
          },
        },
      });
    },
    [createUserFeedbackMutation, entityId, feedbackCategory, householdId]
  );

  if (!aiEnabled) {
    return null;
  }

  return (
    <>
      <Button
        startIcon={MessageTextSquare02Icon}
        iconProps={{ color: COLORS.TEAL[600] }}
        variant="transparent"
        size="sm"
        onClick={() => setIsModalOpen(true)}
      >
        Share feedback on AI
      </Button>
      {isModalOpen && (
        <UserFeedbackModal
          isFeedbackModalOpen={isModalOpen}
          closeFeedbackModal={() => setIsModalOpen(false)}
          provideFeedback={provideFeedback}
          placeholderText="How can we improve?"
          helpText="We are continuously improving our AI capabilities based on feedback."
        />
      )}
    </>
  );
}
