import { Stack, useTheme } from '@mui/material';

import { COLORS } from '@/styles/tokens/colors';

import { Button } from '../Button/Button';
import { getColorsForVariant } from '../Button/styles';
import { FormControl } from '../FormControl';
import {
  BaseButtonGroupInputProps,
  ButtonGroupVariant,
  FormControlButtonGroupInputProps,
  HelpTextVariant,
} from '../inputTypes';

export interface ButtonGroupProps<V = unknown>
  extends FormControlButtonGroupInputProps<V> {
  label: string;
  hideLabel?: boolean;
  id?: string;
  contextualHelp?: JSX.Element;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
}

function getBackgroundColorForVariant(variant: ButtonGroupVariant) {
  switch (variant) {
    case 'dark':
      return COLORS.GRAY[100];
    case 'onDark':
      return COLORS.GRAY[200];
    case 'light':
    default:
      return COLORS.PRIMITIVES.WHITE;
  }
}

function getContainerBackground({
  hasSelectedValue,
  variant,
}: {
  hasSelectedValue: boolean;
  variant: ButtonGroupVariant;
}) {
  if (!hasSelectedValue) return 'transparent';
  return getBackgroundColorForVariant(variant);
}

function BaseButtonGroupInput({
  options,
  variant = 'dark',
  value: selectedValue,
  error,
  ...inputProps
}: BaseButtonGroupInputProps<unknown>) {
  const theme = useTheme();
  const colors = getColorsForVariant('primary', theme);
  const { disabled, sx, onChange, name, inputRef } = inputProps;
  const containerSx = Object.assign(
    {
      bgcolor: getContainerBackground({
        hasSelectedValue: !!selectedValue,
        variant,
      }),
      borderRadius: 1,
      border: error ? `solid 1px ${COLORS.FUNCTIONAL.ERROR.DEFAULT}` : 'none',
      overflow: 'hidden',
      listStyleType: 'none',
      p: 0,
      m: 0,
    },
    sx
  );

  return (
    <Stack
      component="ul"
      spacing="2px"
      role="group"
      direction="row"
      sx={containerSx}
      tabIndex={-1}
      ref={inputRef}
      id={inputProps.id}
      aria-labelledby={inputProps['aria-labelledby']}
    >
      {options.map(({ value, display, buttonProps = {} }, i) => {
        const isSelected = value === selectedValue;
        const buttonStyles = {
          sx: {
            whiteSpace: 'nowrap',
            px: 1.5,
            '&:hover': {
              backgroundColor: colors.regular.backgroundColorOnHover,
              color: COLORS.PRIMITIVES.WHITE,
            },
          },
          contentBoxSx: {
            '> span': {
              // MUI default is negative margin for the start icon, but
              // we want the icon to be flush with the left edge of the button content
              marginLeft: 0,
            },
          },
        };

        const buttonPropsWithStyles = {
          ...buttonStyles,
          ...buttonProps,
        };

        return (
          <li
            key={i}
            style={{
              flex: 1,
              backgroundColor: getBackgroundColorForVariant(variant),
            }}
          >
            <Button
              {...buttonPropsWithStyles}
              variant={isSelected ? 'primary' : 'transparent'}
              value={value}
              disabled={disabled || buttonProps.disabled}
              aria-pressed={isSelected}
              size="_bg"
              fullWidth
              name={name}
              onClick={(event) => onChange(event, value)}
              data-testid={`button-group-${value ?? ''}`}
            >
              {display}
            </Button>
          </li>
        );
      })}
    </Stack>
  );
}

export function ButtonGroup<V>({
  id,
  label,
  hideLabel,
  contextualHelp,
  errorMessage,
  helpText,
  helpTextVariant,
  ...inputProps
}: ButtonGroupProps<V>) {
  return (
    <FormControl<BaseButtonGroupInputProps<V>>
      id={id}
      required={inputProps.required}
      contextualHelp={contextualHelp}
      component={BaseButtonGroupInput}
      label={label}
      hideLabel={hideLabel}
      errorMessage={errorMessage}
      inputProps={inputProps}
      associateLabelWithAria={true}
      helpText={helpText}
      helpTextVariant={helpTextVariant}
    />
  );
}
