import { Box, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormAwareTypeaheadSelectInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadSelectInput';
import { ArrowRightIcon } from '@/components/icons/ArrowRightIcon';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { PopperContent } from '@/components/poppers/PopperContent';
import { useFormContext } from '@/components/react-hook-form';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { Debtor } from '@/modules/content/tooltipContent/Debtor';
import { Lender } from '@/modules/content/tooltipContent/Lender';
import { COLORS } from '@/styles/tokens/colors';

import { useIssuerAndRecipientOptions } from './hooks/useRecipientOptions';
import {
  LiabilityFormPaths,
  LiabilityFormShape,
} from './LiabilityModal.constants';
import { LiabilityPaymentDetailsForm } from './LiabilityPaymentDetailsForm';

interface LiabilityFormProps {
  entityId?: string;
}

export const NO_LIABILITY_SOURCE_RECIPIENT_VALUE_DISPLAY = 'External';

export function LiabilityForm({ entityId }: LiabilityFormProps) {
  const { control, setValue } = useFormContext<LiabilityFormShape>();
  const { options } = useIssuerAndRecipientOptions({
    currentEntityId: entityId,
  });

  const showExpandedPaymentDetails = useWatch({
    control,
    name: '_showExpandedPaymentDetails',
  });

  const hasDifferentPaymentSource = useWatch({
    control,
    name: 'hasDifferentPaymentSource',
  });

  const handleDeletePaymentDetails = useCallback(() => {
    setValue('_showExpandedPaymentDetails', false);
  }, [setValue]);

  const handleAddPaymentDetails = useCallback(() => {
    setValue('_showExpandedPaymentDetails', true);
  }, [setValue]);

  return (
    <Stack spacing={3}>
      <Box>
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareTextInput<LiabilityFormShape>
              control={control}
              label="Display name"
              fieldName={'name' as const satisfies LiabilityFormPaths}
              required
            />
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow>
          <FormLayoutItem width={5}>
            <FormAwareTypeaheadSelectInput<LiabilityFormShape>
              emptyOptionDisplay={NO_LIABILITY_SOURCE_RECIPIENT_VALUE_DISPLAY}
              control={control}
              label="Lender"
              contextualHelp={<Lender />}
              fieldName={'issuerId' as const satisfies LiabilityFormPaths}
              options={options}
              groupBy={(option) => option.groupName ?? ''}
            />
          </FormLayoutItem>
          <FormLayoutItem width={2}>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="flex-end"
              height="100%"
              pb={1}
            >
              <ArrowRightIcon size={25} sx={{ color: COLORS.GRAY[400] }} />
            </Stack>
          </FormLayoutItem>
          <FormLayoutItem width={5}>
            <FormAwareTypeaheadSelectInput<LiabilityFormShape>
              control={control}
              label="Debtor"
              contextualHelp={<Debtor />}
              emptyOptionDisplay={NO_LIABILITY_SOURCE_RECIPIENT_VALUE_DISPLAY}
              fieldName={'recipientId' as const satisfies LiabilityFormPaths}
              options={options}
              groupBy={(option) => option.groupName ?? ''}
            />
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow>
          <FormLayoutItem width={12}>
            <Card
              variant={hasDifferentPaymentSource ? 'filled' : 'transparent'}
              sx={{ p: 2 }}
            >
              <Stack spacing={1}>
                <FormAwareSwitch
                  control={control}
                  labelPosition="right"
                  fieldName={
                    'hasDifferentPaymentSource' as const satisfies LiabilityFormPaths
                  }
                  contextualHelp={
                    <PopperContent body="Payment is sourced from the assets of the debtor entity by default unless a different payment source is specified." />
                  }
                  label="Specify different payment source instead of the debtor"
                />
                {hasDifferentPaymentSource && (
                  <Box pl={7}>
                    <FormAwareTypeaheadSelectInput<LiabilityFormShape>
                      placeholder="Choose a payment source"
                      control={control}
                      required
                      label="Alternate payment source"
                      hideLabel
                      fieldName={
                        'alternateSourceId' as const satisfies LiabilityFormPaths
                      }
                      options={options}
                      groupBy={(option) => option.groupName ?? ''}
                    />
                  </Box>
                )}
              </Stack>
            </Card>
          </FormLayoutItem>
        </FormLayoutRow>
      </Box>
      <Card variant="filled" sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Stack direction="row">
            <Typography variant="h4" component="div">
              Balance details
            </Typography>
            <ContextualHelpTooltip>
              <PopperContent body="All liabilities are automatically paid off at first death in waterfall calculations." />
            </ContextualHelpTooltip>
          </Stack>
          <FormLayoutRow>
            <FormLayoutItem width={8}>
              <FormAwareCurrencyInput<LiabilityFormShape>
                control={control}
                label="Outstanding balance"
                isDecimalJSInput
                fieldName={
                  'outstandingBalance' as const satisfies LiabilityFormPaths
                }
                required
              />
            </FormLayoutItem>
            <FormLayoutItem width={4}>
              <FormAwareDatePickerInput<LiabilityFormShape>
                control={control}
                label="As of date"
                fieldName={
                  'balanceAsOfDate' as const satisfies LiabilityFormPaths
                }
                required
              />
            </FormLayoutItem>
          </FormLayoutRow>
        </Stack>
      </Card>
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          <FormAwareTextInput<LiabilityFormShape>
            control={control}
            label="Notes"
            fieldName={'notes' as const satisfies LiabilityFormPaths}
            multiline
            rows={3}
          />
        </FormLayoutItem>
      </FormLayoutRow>

      <Box>
        {!showExpandedPaymentDetails && (
          <Button
            fullWidth
            variant="secondary"
            size="md"
            startIcon={PlusIcon}
            onClick={handleAddPaymentDetails}
          >
            Add payment details
          </Button>
        )}
        {showExpandedPaymentDetails && (
          <Card variant="filled" sx={{ p: 2 }}>
            <LiabilityPaymentDetailsForm
              onDeletePaymentDetails={handleDeletePaymentDetails}
            />
          </Card>
        )}
      </Box>
    </Stack>
  );
}
