import { Box, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import {
  FormAwareSelectInput,
  InputOption,
} from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { SidePanel } from '@/components/modals/SidePanel';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { useModalState } from '@/hooks/useModalState';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { Operations as BeneficiaryReportingOperations } from '@/modules/beneficiaryReporting/graphql/BeneficiaryReporting.generated';
import { useUpdateEstateWaterfallAssumptionsMutation } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/graphql/EstateWaterfallAssumptions.generated';
import { EstateWaterfallFragment } from '@/modules/estateWaterfall/graphql/EstateWaterfall.generated';
import { Operations as EstateWaterfallOperations } from '@/modules/estateWaterfall/graphql/EstateWaterfall.generated';
import {
  GetWaterfallSummary_EstateWaterfallFragment,
  Operations as GetWaterfallSummaryOperations,
} from '@/modules/estateWaterfall/graphql/GetWaterfallSummary.generated';
import {
  GrowthProfilesOperations,
  useAvailableGrowthProfiles,
} from '@/modules/estateWaterfall/hooks/useAvailableGrowthProfiles';
import { GrowthProfileModal } from '@/modules/growthProfiles/GrowthProfileModal/GrowthProfileModal';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { PathsOf } from '@/types/subform';

interface GrowthProfileSummaryPanelFormShape {
  growthProfileId: string;
  firstGrantorDeathYear: number | undefined | null;
  secondGrantorDeathYear: number | undefined | null;
}

type GrowthProfileSummaryPanelFormShapePaths =
  PathsOf<GrowthProfileSummaryPanelFormShape>;

interface GrowthProfilesSummaryPanelInnerProps {
  waterfall: GrowthProfilesSummaryPanelProps['waterfall'];
  openModal: () => void;
  onSubmit: () => void;
  onReset: () => void;
  isSaving: boolean;
  isResetting: boolean;
}

function GrowthProfilesSummaryPanelInner({
  waterfall,
  openModal,
  onSubmit,
  onReset: onResetExternal,
  isSaving,
  isResetting,
}: GrowthProfilesSummaryPanelInnerProps) {
  const { control, setValue, formState } =
    useFormContext<GrowthProfileSummaryPanelFormShape>();
  const { isSubmitting, isLoading } = formState;
  const { isTwoClientHousehold, householdId } = useHouseholdDetailsContext();
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();

  const { growthProfiles, loading } = useAvailableGrowthProfiles(householdId, {
    onError(err) {
      reportError(
        `Could not fetch growth profiles for waterfall ${waterfall.id}`,
        err
      );
      showFeedback('Could not fetch growth profiles.  Please try again later.');
    },
  });

  const growthProfileOptions = useMemo(() => {
    return growthProfiles.map<InputOption>((profile) => {
      return {
        display: profile.displayName,
        value: profile.id,
        endAdornment: profile.isDefault ? (
          <Badge variant={BadgeVariants.Primary} display="Default" />
        ) : undefined,
      };
    });
  }, [growthProfiles]);

  const onReset = useCallback(() => {
    const currentYear = new Date().getFullYear();
    const defaultGrowthProfile = growthProfiles.find(
      (profile) => profile.isDefault
    );

    if (!defaultGrowthProfile) {
      reportError(`No default growth profile found for ${householdId}`);
      showFeedback(
        'Could not reset assumptions.  Please confirm a default growth profile exists and try again.'
      );
      return;
    }
    setValue('growthProfileId', defaultGrowthProfile.id);
    setValue('firstGrantorDeathYear', currentYear);
    setValue('secondGrantorDeathYear', currentYear);
    onResetExternal();
  }, [
    growthProfiles,
    householdId,
    onResetExternal,
    reportError,
    setValue,
    showFeedback,
  ]);

  return (
    <Stack width="100%">
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          <Stack direction="row" spacing={2} alignItems="flex-end">
            <Box flex={2}>
              <FormAwareSelectInput<GrowthProfileSummaryPanelFormShape>
                control={control}
                testId="summary-panel-growthProfileSelector"
                fieldName={
                  'growthProfileId' as const satisfies GrowthProfileSummaryPanelFormShapePaths
                }
                label="Growth profile"
                required
                options={growthProfileOptions}
                disabled={loading}
                showEmptyValue={false}
                addNewOption={{
                  text: 'Create new profile',
                  icon: <PlusIcon size={16} />,
                  onClick: () => {
                    setValue('growthProfileId', '');
                    openModal();
                  },
                }}
              />
            </Box>
            <IconButton
              variant="transparent"
              onClick={openModal}
              size="sm"
              icon={Edit02Icon}
              ariaLabel="Edit growth profile"
            />
          </Stack>
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        {isTwoClientHousehold ? (
          <>
            <FormLayoutItem width={6}>
              <FormAwareTextInput<GrowthProfileSummaryPanelFormShape>
                control={control}
                testId="summary-panel-firstGrantorDeathYear"
                fieldName={
                  'firstGrantorDeathYear' as const satisfies GrowthProfileSummaryPanelFormShapePaths
                }
                label="Year of first death"
                required
              />
            </FormLayoutItem>
            <FormLayoutItem width={6}>
              <FormAwareTextInput<GrowthProfileSummaryPanelFormShape>
                control={control}
                testId="summary-panel-secondGrantorDeathYear"
                fieldName={
                  'secondGrantorDeathYear' as const satisfies GrowthProfileSummaryPanelFormShapePaths
                }
                label="Year of second death"
                required
              />
            </FormLayoutItem>
          </>
        ) : (
          <FormLayoutItem width={12}>
            <FormAwareTextInput<GrowthProfileSummaryPanelFormShape>
              control={control}
              fieldName={
                'firstGrantorDeathYear' as const satisfies GrowthProfileSummaryPanelFormShapePaths
              }
              label="Year of death"
              required
            />
          </FormLayoutItem>
        )}
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          <Button
            variant="primary"
            size="sm"
            onClick={onSubmit}
            fullWidth
            disabled={isLoading || isSubmitting}
            loading={isSaving}
          >
            Apply changes
          </Button>
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          <Button
            variant="transparent"
            size="sm"
            onClick={onReset}
            fullWidth
            disabled={isLoading || isSubmitting}
            loading={isResetting}
          >
            Reset to defaults
          </Button>
        </FormLayoutItem>
      </FormLayoutRow>
    </Stack>
  );
}

export interface GrowthProfilesSummaryPanelProps {
  waterfall:
    | GetWaterfallSummary_EstateWaterfallFragment
    | EstateWaterfallFragment;
  householdId: string;
  onClose: () => void;
}

export function GrowthProfilesSummaryPanel({
  onClose,
  householdId,
  waterfall,
}: GrowthProfilesSummaryPanelProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const trackUserEvent = useTrackUserEvent();

  const { isTwoClientHousehold } = useHouseholdDetailsContext();

  const formMethods = useForm<GrowthProfileSummaryPanelFormShape>({
    defaultValues: {
      growthProfileId: waterfall.growthProfile?.id ?? '',
      firstGrantorDeathYear: waterfall.firstGrantorDeathYear,
      secondGrantorDeathYear: isTwoClientHousehold
        ? waterfall.secondGrantorDeathYear
        : undefined,
    },
  });

  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();

  const [updateMutation, { loading: isSaving }] =
    useUpdateEstateWaterfallAssumptionsMutation({
      refetchQueries: 'active',
      awaitRefetchQueries: true,
      onCompleted: () => {
        showFeedback('Waterfall updated successfully', { variant: 'success' });
      },
      onError: (error) => {
        showFeedback(FeedbackMessages.formSaveError);
        reportError(
          `Could not update estate waterfall assumptions for waterfall ${waterfall.id}`,
          error
        );
      },
    });

  const [resetMutation, { loading: isResetting }] =
    useUpdateEstateWaterfallAssumptionsMutation({
      refetchQueries: 'active',
      awaitRefetchQueries: true,
      onCompleted: () => {
        showFeedback('Waterfall reset successfully', { variant: 'success' });
      },
      onError: (error) => {
        showFeedback(FeedbackMessages.formSaveError);
        reportError(
          `Could not update estate waterfall assumptions for waterfall ${waterfall.id}`,
          error
        );
      },
    });

  const { handleSubmit, control } = formMethods;
  const onSubmit = handleSubmit(async (data) => {
    if (waterfall?.growthProfile?.id !== data.growthProfileId) {
      trackUserEvent('growth_profile assigned');
    }

    await updateMutation({
      variables: {
        input: {
          id: waterfall.id,
          update: {
            growthProfileID: data.growthProfileId,
            firstGrantorDeathYear: data.firstGrantorDeathYear,
            secondGrantorDeathYear: isTwoClientHousehold
              ? data.secondGrantorDeathYear
              : undefined,
          },
        },
      },
    });
  });

  const onReset = handleSubmit(async (data) => {
    if (waterfall?.growthProfile?.id !== data.growthProfileId) {
      trackUserEvent('growth_profile assigned');
    }

    await resetMutation({
      variables: {
        input: {
          id: waterfall.id,
          update: {
            growthProfileID: data.growthProfileId,
            firstGrantorDeathYear: data.firstGrantorDeathYear,
            secondGrantorDeathYear: isTwoClientHousehold
              ? data.secondGrantorDeathYear
              : undefined,
          },
        },
      },
    });
  });

  const [growthProfileId] = useWatch({
    control,
    name: ['growthProfileId'],
  });

  return (
    <FormProvider {...formMethods}>
      {isModalOpen && (
        <GrowthProfileModal
          onClose={() => {
            closeModal();
          }}
          householdId={householdId}
          growthProfileId={growthProfileId}
          refetchQueries={[
            GrowthProfilesOperations.Query.GrowthProfiles,
            GetWaterfallSummaryOperations.Query.GetWaterfallSummary,
            EstateWaterfallOperations.Query.EstateWaterfall,
            BeneficiaryReportingOperations.Query.BeneficiaryReporting,
          ]}
        />
      )}
      <SidePanel.Panel>
        <SidePanel.Header title="Assumptions" onClose={onClose} />
        <SidePanel.Content>
          <GrowthProfilesSummaryPanelInner
            waterfall={waterfall}
            openModal={openModal}
            onSubmit={onSubmit}
            onReset={onReset}
            isSaving={isSaving}
            isResetting={isResetting}
          />
        </SidePanel.Content>
      </SidePanel.Panel>
    </FormProvider>
  );
}
