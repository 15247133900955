import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import {
  AfterDeath,
  ClientOrganizationKind,
  EntityInEstateStatus,
  EntityKind,
} from '@/types/schema';

import { CHARITABLE_ENTITY_KINDS } from '../entities/entities.constants';
import {
  makeNodeFromEntity,
  MakeNodeFromEntityInput,
  makeNodeFromIndividual,
  MakeNodeFromIndividualInput,
  makeNodeFromOrganization,
  MakeNodeFromOrganizationInput,
} from './getDispositionsVizFromEntity';
import {
  EntityDiagram_EdgeFragment,
  EntityDiagram_NodeFragment,
  EntityDiagramEdgeKind,
  EntityDiagramTile,
  EntityDiagramTileKind,
} from './types';

// Assert grantorNode is a tile
function assertNodeIsTile(
  node: EntityDiagram_NodeFragment
): asserts node is EntityDiagramTile {
  if (node.node === null) {
    throw new Error('Node is not a valid tile node');
  }
}

export interface GetTermVizFromEntityInput {
  entity: MakeNodeFromEntityInput['entity'];
  grantors: {
    id: string;
    individual: {
      id: string;
      displayName: string;
    };
  }[];
  currentTermBeneficiaries: {
    id: string;
    entity?: MakeNodeFromEntityInput['entity'] | null;
    individual?: MakeNodeFromIndividualInput['individual'] | null;
    organization?: MakeNodeFromOrganizationInput['organization'] | null;
  }[];
  termEndBeneficiaries: {
    id: string;
    entity?: MakeNodeFromEntityInput['entity'] | null;
    individual?: MakeNodeFromIndividualInput['individual'] | null;
    organization?: MakeNodeFromOrganizationInput['organization'] | null;
  }[];
  firstGrantorDeathId: string;
  survivingSpouse?: {
    id: string;
    displayName: string;
  };
}

// Generates nodes and edges for a term diagram
// where an entity is funded by up to two grantors
// and the entity has a has a term end date. At the
// term end date, the entity is distributed to the
// beneficiaries.
export function getTermVizFromEntity({
  entity,
  grantors,
  currentTermBeneficiaries,
  termEndBeneficiaries,
  firstGrantorDeathId,
  survivingSpouse,
}: GetTermVizFromEntityInput) {
  const rootEntityNode = makeNodeFromEntity({
    entity,
    afterDeath: AfterDeath.None,
    entityDiagramTileKind: EntityDiagramTileKind.Entity,
  });

  assertNodeIsTile(rootEntityNode);

  rootEntityNode.entityDiagramTileKind = EntityDiagramTileKind.Entity;
  rootEntityNode.centerY = true;

  const currentTermBeneficiariesThatAreAlsoTestators = new Set<string>();

  const testatorNodes =
    grantors?.flatMap((grantor) => {
      if (!grantor.individual) {
        return [];
      }

      const individualId = grantor.individual?.id ?? '';

      const individualIsAlsoCurrentTermBeneficiary =
        currentTermBeneficiaries.some(
          (beneficiary) => beneficiary.individual?.id === individualId
        );

      const entityDiagramTileKind: EntityDiagramTileKind[] = [
        EntityDiagramTileKind.Grantor,
      ];

      if (individualIsAlsoCurrentTermBeneficiary) {
        currentTermBeneficiariesThatAreAlsoTestators.add(individualId);
        if (entity.entityKind === EntityKind.CltTrust) {
          entityDiagramTileKind.push(EntityDiagramTileKind.LeadBeneficiary);
        } else {
          entityDiagramTileKind.push(EntityDiagramTileKind.IncomeBeneficiary);
        }
      }

      return makeNodeFromIndividual({
        individual: {
          id: individualId,
          displayName: grantor.individual?.displayName ?? EMPTY_CONTENT_HYPHEN,
        },
        afterDeath: AfterDeath.None,
        estateStatus: EntityInEstateStatus.InEstate,
        entityDiagramTileKind,
      });
    }) ?? [];

  const testatorToRootEntityEdges: EntityDiagram_EdgeFragment[] = [];

  testatorNodes.forEach((testatorNode) => {
    assertNodeIsTile(testatorNode);

    testatorNode.order = -1;
    testatorNode.xOffset = -50; // Space this node out a bit more
    testatorNode.omitFromCentering = true;
    testatorNode.stackY = true;
    testatorNode.centerY = true;

    let testatorToEntityEdgeKind: EntityDiagramEdgeKind =
      EntityDiagramEdgeKind.LeftToRightTransfer;

    // If the testator is also a current term beneficiary,
    // we need to draw a bidirectional transfer
    if (currentTermBeneficiariesThatAreAlsoTestators.has(testatorNode.id)) {
      testatorToEntityEdgeKind = EntityDiagramEdgeKind.BidirectionalTransfer;
    }

    // GRATs have a bidirectional transfer
    // between the grantor and the entity
    if (entity.entityKind === EntityKind.GratTrust) {
      testatorToEntityEdgeKind = EntityDiagramEdgeKind.BidirectionalTransfer;
    }

    // Add edge between grantor and the entity
    const grantorToEntityEdge: EntityDiagram_EdgeFragment = {
      from: testatorNode,
      to: rootEntityNode,
      kind: testatorToEntityEdgeKind,
    };

    testatorToRootEntityEdges.push(grantorToEntityEdge);
  });

  const currentTermBeneficiaryNodes =
    currentTermBeneficiaries?.flatMap((beneficiary) => {
      const currentTermBeneficiaryId =
        beneficiary.entity?.id ??
        beneficiary.individual?.id ??
        beneficiary.organization?.id ??
        '';

      const isCurrentTermBeneficiaryAlsoTestator =
        currentTermBeneficiariesThatAreAlsoTestators.has(
          currentTermBeneficiaryId
        );

      // If the current term beneficiary is also a testator,
      // do not include an additional node for them
      if (isCurrentTermBeneficiaryAlsoTestator) {
        return [];
      }

      const currentTermBeneficiaryNodeKind =
        entity.entityKind === EntityKind.CltTrust
          ? EntityDiagramTileKind.LeadBeneficiary
          : EntityDiagramTileKind.IncomeBeneficiary;

      if (beneficiary.entity) {
        return makeNodeFromEntity({
          entity: beneficiary.entity,
          afterDeath: AfterDeath.None,
          entityDiagramTileKind: currentTermBeneficiaryNodeKind,
        });
      }

      if (beneficiary.individual) {
        // If the beneficiary is a surviving spouse or first grantor, they are in estate
        const estateStatus = [
          firstGrantorDeathId,
          survivingSpouse?.id,
        ].includes(beneficiary.individual.id)
          ? EntityInEstateStatus.InEstate
          : EntityInEstateStatus.OutOfEstate;

        return makeNodeFromIndividual({
          individual: beneficiary.individual,
          afterDeath: AfterDeath.None,
          estateStatus,
          entityDiagramTileKind: currentTermBeneficiaryNodeKind,
        });
      }

      if (beneficiary.organization) {
        return makeNodeFromOrganization({
          organization: beneficiary.organization,
          afterDeath: AfterDeath.None,
          entityDiagramTileKind: currentTermBeneficiaryNodeKind,
        });
      }

      return [];
    }) ?? [];

  const rootEntityToCurrentTermBeneficiaryEdges: EntityDiagram_EdgeFragment[] =
    [];

  currentTermBeneficiaryNodes.forEach((currentTermBeneficiaryNode) => {
    assertNodeIsTile(currentTermBeneficiaryNode);

    currentTermBeneficiaryNode.order = Infinity; // Push this node to the end of the row
    currentTermBeneficiaryNode.xOffset = 50; // Space this node out a bit more
    currentTermBeneficiaryNode.omitFromCentering = true;
    currentTermBeneficiaryNode.stackY = true;
    currentTermBeneficiaryNode.centerY = true;

    // Add edge between entity and the current term beneficiary
    const rootEntityToCurrentTermBeneficiaryEdge: EntityDiagram_EdgeFragment = {
      from: rootEntityNode,
      to: currentTermBeneficiaryNode,
      kind: EntityDiagramEdgeKind.LeftToRightTransfer,
    };

    rootEntityToCurrentTermBeneficiaryEdges.push(
      rootEntityToCurrentTermBeneficiaryEdge
    );
  });

  const termEndBeneficiaryNodes =
    termEndBeneficiaries?.flatMap((beneficiary) => {
      if (beneficiary.entity) {
        return makeNodeFromEntity({
          entity: beneficiary.entity,
          afterDeath: AfterDeath.First,
          entityDiagramTileKind: EntityDiagramTileKind.TermEndBeneficiary,
        });
      }

      if (beneficiary.individual) {
        // If the beneficiary is a surviving spouse or first grantor, they are in estate
        const estateStatus = [
          firstGrantorDeathId,
          survivingSpouse?.id,
        ].includes(beneficiary.individual.id)
          ? EntityInEstateStatus.InEstate
          : EntityInEstateStatus.OutOfEstate;

        return makeNodeFromIndividual({
          individual: beneficiary.individual,
          afterDeath: AfterDeath.First,
          estateStatus,
          entityDiagramTileKind: EntityDiagramTileKind.TermEndBeneficiary,
        });
      }

      if (beneficiary.organization) {
        let entityDiagramTileKind: EntityDiagramTileKind =
          EntityDiagramTileKind.TermEndBeneficiary;

        if (
          CHARITABLE_ENTITY_KINDS.includes(entity.entityKind) &&
          beneficiary.organization.clientOrganizationKind ===
            ClientOrganizationKind.CharitableOrganization
        ) {
          entityDiagramTileKind =
            EntityDiagramTileKind.CharitableTermEndBeneficiary;
        }

        return makeNodeFromOrganization({
          organization: beneficiary.organization,
          afterDeath: AfterDeath.First,
          entityDiagramTileKind,
        });
      }

      return [];
    }) ?? [];

  // Handle the case where there are no beneficiaries for the entity
  if (termEndBeneficiaryNodes.length === 0) {
    // Add a node for the term end beneficiary
    const termEndBeneficiaryNode = makeNodeFromIndividual({
      individual: {
        id: entity.id,
        displayName: 'Beneficiaries not yet specified',
      },
      afterDeath: AfterDeath.First,
      estateStatus: EntityInEstateStatus.OutOfEstate,
      entityDiagramTileKind: EntityDiagramTileKind.TermEndBeneficiary,
    });

    termEndBeneficiaryNodes.push(termEndBeneficiaryNode);
  }

  // Draw edges from root to term nodes nodes
  const rootEntityToTermEndBeneficiaryEdges: EntityDiagram_EdgeFragment[] =
    termEndBeneficiaryNodes.map((node) => ({
      from: rootEntityNode,
      to: node,
      kind: EntityDiagramEdgeKind.TermEnd,
    }));

  return {
    nodes: [
      ...testatorNodes,
      rootEntityNode,
      ...currentTermBeneficiaryNodes,
      ...termEndBeneficiaryNodes,
    ],
    edges: [
      ...testatorToRootEntityEdges,
      ...rootEntityToCurrentTermBeneficiaryEdges,
      ...rootEntityToTermEndBeneficiaryEdges,
    ],
  };
}
