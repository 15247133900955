import { DocumentNode } from '@apollo/client';
import { Box, Stack, Typography } from '@mui/material';
import { compact } from 'lodash';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SubmitHandler, useWatch } from 'react-hook-form';

import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import {
  ButtonTab,
  TabContent,
  Tabs,
} from '@/components/navigation/NavigationTabs';
import { TabsProvider } from '@/components/navigation/NavigationTabs/Tabs.provider';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { Loader } from '@/components/progress/Loader/Loader';
import {
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { shouldRefetchQuery } from '@/graphql/client.utils';
import { useReportError } from '@/hooks/useReportError';
import { useDispositiveProvisionsTemplateSplitScreenModalContext } from '@/modules/dispositiveProvisions/DispositiveProvisionsTemplateSplitScreenModal/DispositiveProvisionsTemplateSplitScreenModal.context';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import {
  AugmentedCreateTestamentaryEntityInput,
  AugmentedUpdateTestamentaryEntityInput,
  TestamentaryEntityKind,
} from '@/types/schema';

import { useTaxStatusAssetLocationOptions } from '../../TaxStatusSubform/hooks/useTaxStatusAssetLocationOptions';
import { GST_STATUS_ITEMS } from '../../TaxStatusSubform/TaxStatusSubform.constants';
import { BeneficiariesTab } from './BeneficiariesTab';
import { EntityDetailsTab } from './EntityDetailsTab';
import { useCreateTestamentaryEntityMutation } from './graphql/CreateTestamentaryEntity.generated';
import { useUpdateTestamentaryEntityMutation } from './graphql/UpdateTestamentaryEntity.generated';
import { useSyncDataToForm } from './hooks/useSyncDataToForm';
import { StateRecipientsContextualHelp } from './StateRecipientsContextualHelp';
import {
  getTestamentaryEntityKindOptions,
  TESTAMENTARY_ENTITY,
} from './TestamentaryEntityForm.constants';
import {
  DefaultEstateTaxStatus,
  TestamentaryEntityFormField,
  TestamentaryEntityFormShape,
  TestamentaryEntityTab,
} from './TestamentaryEntityForm.types';
import {
  getCreateTestamentaryEntityInput,
  getTEAssetLocationDefaults,
  getUpdateTestamentaryEntityInput,
} from './TestamentaryEntityForm.utils';

interface TestamentaryEntityFormProps {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  testamentaryEntityId?: string;
  // The grantor who will die and trigger the creation of this testamentary entity
  dyingPrimaryClientId: string | null;
  // The grantors who are dead, i.e., possibly the spouse and the dying grantor
  deadPrimaryClientIds: string[];
  formRef: RefObject<HTMLFormElement>;
  handleClose: () => void;
  isTwoClientHousehold: boolean;
  initialTab?: TestamentaryEntityTab;
  // after a mutation, the modal will trigger a refetch of the active queries.
  // pass any additional queries you want to ignore from the refetch here.
  ignoredQueryDocuments?: DocumentNode[];
  defaultEstateTaxStatus?: DefaultEstateTaxStatus;
  /**
   * If provided, this will auto-fill (but not disable) the entity name field
   */
  defaultName?: string;
  /**
   * If provided this will auto-fill (but not disable) the entity kind field
   */
  defaultKind?: TestamentaryEntityKind;
}

const ASSET_LOCATION_COLUMN_WIDTH = '204px';

export function TestamentaryEntityForm({
  setIsLoading,
  testamentaryEntityId,
  formRef,
  handleClose,
  dyingPrimaryClientId,
  deadPrimaryClientIds,
  isTwoClientHousehold,
  initialTab = 'beneficiaries',
  ignoredQueryDocuments = [],
  defaultEstateTaxStatus,
  defaultName,
  defaultKind,
}: TestamentaryEntityFormProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { isTemplateMode } =
    useDispositiveProvisionsTemplateSplitScreenModalContext();

  const isEdit = !!testamentaryEntityId;

  const { assetLocationOptions } = useTaxStatusAssetLocationOptions({
    fieldNamePrefix: TESTAMENTARY_ENTITY,
    selectedGrantorClientProfileIds: compact([dyingPrimaryClientId]),
  });

  const { primaryClients } = useHouseholdDetailsContext();
  const nameOfDeadClient: string | null = useMemo(() => {
    return (
      (primaryClients || []).find((g) => g.id === dyingPrimaryClientId)
        ?.displayName ?? null
    );
  }, [dyingPrimaryClientId, primaryClients]);

  const areAllClientsDead = useMemo(() => {
    if (isTwoClientHousehold && deadPrimaryClientIds.length === 2) {
      return true;
    }

    return !isTwoClientHousehold;
  }, [deadPrimaryClientIds, isTwoClientHousehold]);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setValue,
    getValues,
    reset,
  } = useFormContext<TestamentaryEntityFormShape>();

  const { loading: loadingSyncDataToForm } = useSyncDataToForm({
    testamentaryEntityId,
    areAllClientsDead,
    dyingPrimaryClientId,
    defaultEstateTaxStatus,
    defaultName,
    defaultKind,
  });

  useEffect(() => {
    setIsLoading(isSubmitting);
  }, [isSubmitting, setIsLoading]);

  useEffect(() => {
    setIsLoading(loadingSyncDataToForm);
  }, [loadingSyncDataToForm, setIsLoading]);

  const selectedTEKind = useWatch({
    control,
    name: `${TESTAMENTARY_ENTITY}.testamentaryEntityKind`,
  });
  const selectedFederalInEstate = useWatch({
    control,
    name: `${TESTAMENTARY_ENTITY}.estateInclusionStatus`,
  });
  const selectedStateInEstate = useWatch({
    control,
    name: `${TESTAMENTARY_ENTITY}.survivingSpouseStateInEstateStatus`,
  });

  useEffect(() => {
    if (isEdit || !selectedTEKind) return;

    const { estateInclusionStatus, survivingSpouseStateInEstateStatus } =
      getTEAssetLocationDefaults(selectedTEKind, areAllClientsDead);

    setValue(
      `${TESTAMENTARY_ENTITY}.estateInclusionStatus`,
      estateInclusionStatus
    );
    setValue(
      `${TESTAMENTARY_ENTITY}.survivingSpouseStateInEstateStatus`,
      survivingSpouseStateInEstateStatus
    );

    reset(getValues());
  }, [
    areAllClientsDead,
    getValues,
    isEdit,
    reset,
    selectedFederalInEstate,
    selectedStateInEstate,
    selectedTEKind,
    setValue,
  ]);

  const [createTestamentaryEntity] = useCreateTestamentaryEntityMutation({
    onError: (error) => {
      showFeedback(
        "We weren't able to create this testamentary entity. Please refresh the page and try again."
      );
      reportError('could not create testamentary entity', error);
    },
    onCompleted: () => {
      showFeedback('Testamentary entity created successfully', {
        variant: 'success',
      });
    },
    refetchQueries: 'active',
    onQueryUpdated: (query) => {
      return shouldRefetchQuery(query.queryName, {
        ignoredQueryDocuments,
      });
    },
    // TODO: Fix pattern around refetch queries, global context?
    // awaitRefetchQueries: true,
  });

  const [updateTestamentaryEntityMutation] =
    useUpdateTestamentaryEntityMutation({
      onError: (error) => {
        showFeedback(
          "We weren't able to update this testamentary entity. Please refresh the page and try again."
        );
        reportError('could not update testamentary entity', error);
      },
      onCompleted: () => {
        showFeedback('Testamentary entity updated successfully', {
          variant: 'success',
        });
      },
      refetchQueries: 'active',
      onQueryUpdated: (query) => {
        return shouldRefetchQuery(query.queryName, {
          ignoredQueryDocuments,
        });
      },
      // awaitRefetchQueries: true,
    });

  const onValidSubmission: SubmitHandler<TestamentaryEntityFormShape> = (
    formValues
  ) => {
    if (isEdit) {
      const updateTestamentaryEntityInput: AugmentedUpdateTestamentaryEntityInput =
        getUpdateTestamentaryEntityInput({
          formValues,
          testamentaryEntityId,
        });

      return updateTestamentaryEntityMutation({
        variables: {
          input: updateTestamentaryEntityInput,
        },
      });
    }

    const createTestamentaryEntityInput: AugmentedCreateTestamentaryEntityInput =
      getCreateTestamentaryEntityInput(formValues);

    return createTestamentaryEntity({
      variables: {
        input: createTestamentaryEntityInput,
      },
    });
  };

  const onSubmit = handleSubmit(onValidSubmission);

  useSubmitSuccessHandler(() => {
    reset();
    handleClose();
  });

  const [currentTab, setCurrentTab] =
    useState<TestamentaryEntityTab>(initialTab);

  if (loadingSyncDataToForm) {
    return (
      <Loader
        boxProps={{
          sx: {
            textAlign: 'center',
            my: 3,
          },
        }}
      />
    );
  }

  return (
    <form
      ref={formRef}
      onSubmit={(event: SyntheticEvent) => {
        // Important this is here to prevent the trowser form from submitting too
        event.preventDefault();
        event.stopPropagation();
        void onSubmit();
      }}
      noValidate
    >
      <Stack flex={1} sx={{ minHeight: 450 }} spacing={3}>
        <Card
          sx={{
            p: 3,
          }}
          variant="filled"
        >
          <FormLayoutRow>
            <FormLayoutItem>
              <FormAwareTextInput<TestamentaryEntityFormShape>
                label="Entity name"
                fieldName={
                  `${TESTAMENTARY_ENTITY}.displayName` as const satisfies TestamentaryEntityFormField
                }
                control={control}
                required
                testId="TestamentaryEntityForm-displayName"
              />
            </FormLayoutItem>
          </FormLayoutRow>
          <FormLayoutRow>
            <FormLayoutItem>
              <FormAwareSelectInput<TestamentaryEntityFormShape>
                fieldName={
                  `${TESTAMENTARY_ENTITY}.testamentaryEntityKind` as const satisfies TestamentaryEntityFormField
                }
                label="Select an entity type"
                control={control}
                options={getTestamentaryEntityKindOptions(nameOfDeadClient)}
                required
                data-testid="TestamentaryEntityModal-testamentaryEntityKind"
              />
            </FormLayoutItem>
          </FormLayoutRow>
          <FormLayoutRow>
            <FormLayoutItem>
              <FormAwareSelectInput<TestamentaryEntityFormShape>
                control={control}
                fieldName={
                  `${TESTAMENTARY_ENTITY}.gstStatus` as const satisfies TestamentaryEntityFormField
                }
                label="Specify GST status"
                options={GST_STATUS_ITEMS}
              />
            </FormLayoutItem>
          </FormLayoutRow>
        </Card>
        {(!areAllClientsDead || isTemplateMode) && (
          <Card
            sx={{
              p: 3,
            }}
            variant="filled"
          >
            <Stack spacing={2}>
              <Typography variant="h4">Asset location</Typography>
              <Stack direction="row" width="100%">
                <Stack justifyContent="flex-end" spacing={1}>
                  <Stack direction="row" spacing={3} alignItems="center">
                    <Box width="60px" />
                    <Typography
                      width="180px"
                      variant="label"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      In estate of surviving spouse
                    </Typography>
                    <Typography
                      width="180px"
                      variant="label"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      Out of estate of surviving spouse
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={3} alignItems="center">
                    <Typography width="60px" variant="label">
                      Federal
                    </Typography>
                    <FormAwareRadioGroup<TestamentaryEntityFormShape>
                      sx={{
                        flexFlow: 'nowrap',
                      }}
                      labelSx={{
                        width: ASSET_LOCATION_COLUMN_WIDTH,
                      }}
                      row={true}
                      options={assetLocationOptions[0]?.options || []}
                      fieldName={
                        `${TESTAMENTARY_ENTITY}.estateInclusionStatus` satisfies TestamentaryEntityFormField
                      }
                      control={control}
                    />
                  </Stack>
                  <Stack direction="row" spacing={3} alignItems="center">
                    <Typography width="60px" variant="label">
                      <Stack direction="row" spacing={0.5}>
                        State
                        <StateRecipientsContextualHelp />
                      </Stack>
                    </Typography>
                    <FormAwareRadioGroup<TestamentaryEntityFormShape>
                      sx={{
                        flexFlow: 'nowrap',
                      }}
                      labelSx={{
                        width: ASSET_LOCATION_COLUMN_WIDTH,
                      }}
                      row={true}
                      options={assetLocationOptions[0]?.options || []}
                      fieldName={
                        `${TESTAMENTARY_ENTITY}.survivingSpouseStateInEstateStatus` satisfies TestamentaryEntityFormField
                      }
                      control={control}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        )}
        <TabsProvider currentTab={currentTab}>
          <Tabs>
            <ButtonTab
              display="Lifetime beneficiaries"
              isActive={currentTab === 'beneficiaries'}
              onClick={() => setCurrentTab('beneficiaries')}
            />
            {!areAllClientsDead && (
              <ButtonTab
                display="Beneficiaries after both client deaths"
                isActive={currentTab === 'beneficiaries-after-both-die'}
                onClick={() => setCurrentTab('beneficiaries-after-both-die')}
              />
            )}
            <ButtonTab
              display="Trustees"
              isActive={currentTab === 'trustees'}
              onClick={() => setCurrentTab('trustees')}
            />
          </Tabs>
          <TabContent tabKey={'beneficiaries'}>
            <BeneficiariesTab bothDie={false} />
          </TabContent>
          <TabContent tabKey={'beneficiaries-after-both-die'}>
            <BeneficiariesTab bothDie={true} />
          </TabContent>
          <TabContent tabKey={'trustees'}>
            <EntityDetailsTab deadClientProfileIds={deadPrimaryClientIds} />
          </TabContent>
        </TabsProvider>
      </Stack>
    </form>
  );
}
