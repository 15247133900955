import { first } from 'lodash';

import { useRequiredParam } from '@/hooks/useRequiredParam';
import { HouseholdDetailsAwareRoute } from '@/modules/household/contexts/HouseholdDetailsAwareRoute';
import {
  ClientPresentationDesignerV2Provider,
  ClientPresentationDesignerV2ViewMode,
} from '@/modules/presentation/clientPresentation/ClientPresentationDesignerV2/ClientPresentationDesignerV2.context';
import { mapClientPresentationV2ToForm } from '@/modules/presentation/clientPresentation/ClientPresentationDesignerV2/ClientPresentationDesignerV2.utils';
import {
  ClientPresentationDesignerV2Query,
  ClientPresentationDesignerV2QueryHookResult,
} from '@/modules/presentation/clientPresentation/ClientPresentationDesignerV2/graphql/ClientPresentationDesignerV2.generated';
import { ClientPresentationDesignerProvider } from '@/modules/presentation/clientPresentation/contexts/ClientPresentationDesigner.provider';
import { TenantPresentationConfigurationProvider } from '@/modules/presentation/context/TenantPresentationConfiguration.provider';
import { CustomerThemeProvider } from '@/styles/themes/CustomerThemeProvider';
import { getNodes } from '@/utils/graphqlUtils';

import { PrintOnlyClientPresentationWatcher } from '../components/PrintClientPresentationShared';
import { PrintOnlyClientPresentationV2SlideMapper } from './PrintOnlyClientPresentationV2.components';
import { usePrintOnlyClientPresentationV2Details } from './PrintOnlyClientPresentationV2.hooks';

function PrintOnlyClientPresentationV2Inner({
  data,
}: {
  data: ClientPresentationDesignerV2Query | undefined;
}) {
  const householdId = useRequiredParam('householdId');
  const presentationId = useRequiredParam('presentationId');

  if (!data) {
    return null;
  }
  const presentation = first(getNodes(data.clientPresentationV2s));
  if (!presentation) {
    return null;
  }
  const { bundles, presentationConfiguration, title } =
    mapClientPresentationV2ToForm(presentation);

  return (
    <ClientPresentationDesignerV2Provider
      householdId={householdId}
      presentationId={presentationId}
      viewMode={ClientPresentationDesignerV2ViewMode.Print}
      queryData={data}
    >
      <PrintOnlyClientPresentationV2SlideMapper
        bundles={bundles}
        presentationConfiguration={presentationConfiguration}
        title={title}
      />
    </ClientPresentationDesignerV2Provider>
  );
}

export function PrintOnlyClientPresentationV2() {
  const householdId = useRequiredParam('householdId');

  return (
    <HouseholdDetailsAwareRoute householdId={householdId}>
      <ClientPresentationDesignerProvider>
        <CustomerThemeProvider>
          <TenantPresentationConfigurationProvider>
            <PrintOnlyClientPresentationWatcher<ClientPresentationDesignerV2QueryHookResult>
              Root={PrintOnlyClientPresentationV2Inner}
              useQuery={usePrintOnlyClientPresentationV2Details}
            />
          </TenantPresentationConfigurationProvider>
        </CustomerThemeProvider>
      </ClientPresentationDesignerProvider>
    </HouseholdDetailsAwareRoute>
  );
}
