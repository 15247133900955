import { Box, BoxProps, Skeleton, Stack, useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import { Fragment } from 'react';
import { useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { FormAwareEditableHeader } from '@/components/form/formAwareInputs/FormAwareEditableHeader';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { DeleteMenuItemWithConfirmationModal } from '@/components/poppers/MenuPopper/DeleteMenuItemWithConfirmationModal';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useFormContext } from '@/components/react-hook-form';
import { InteractionParadigmContext } from '@/contexts/InteractionParadigm.context';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { $downloadFileFromURL } from '@/modules/files/utils/fileUtils';
import { ROUTE_KEYS } from '@/navigation/constants';
import { CustomerThemeProvider } from '@/styles/themes/CustomerThemeProvider';
import { COLORS } from '@/styles/tokens/colors';
import { PresentationBundleKind, PresentationPageKind } from '@/types/schema';

import { useDownloadClientPresentation } from '../hooks/useDownloadClientPresentation';
import { useClientPresentationDesignerV2Context } from './ClientPresentationDesignerV2.context';
import { ClientPresentationV2Shape } from './ClientPresentationDesignerV2.types';
import { isSinglePageBundle } from './components/ClientPresentationDesignerV2TreeView/ClientPresentationDesignerV2TreeView.constants';
import { AllEntitiesSlide } from './components/slides/AllEntities/AllEntitiesSlide';
import { BalanceSheetSlide } from './components/slides/BalanceSheet/BalanceSheetSlide';
import { BaseBundleSlideProps } from './components/slides/BundleSlide.types';
import { CoverSlide } from './components/slides/CoverSlide/CoverSlide';
import { CustomSlideBundle } from './components/slides/CustomSlide/CustomSlide';
import { EntityOverviewSlide } from './components/slides/entityOverview/EntityOverviewSlide';
import { EstateWaterfallBeneficiarySlide } from './components/slides/estateWaterfall/Beneficiaries/EstateWaterfallBeneficiarySlide';
import { EstateWaterfallDiagramSlide } from './components/slides/estateWaterfall/Diagram/EstateWaterfallDiagramSlide';
import { EstateWaterfallSummarySlide } from './components/slides/estateWaterfall/Summary/EstateWaterfallSummarySlide';
import { EstateWaterfallTaxOverviewSlide } from './components/slides/estateWaterfall/TaxOverview/EstateWaterfallTaxOverviewSlide';
import { ProfessionalTeamSlide } from './components/slides/ProfessionalTeamSlide/ProfessionalTeamSlide';
import { StandaloneDisclaimerSlide } from './components/slides/StandaloneDisclaimer/StandaloneDisclaimerSlide';
import { TableOfContents } from './components/slides/TableOfContents/TableOfContents';
import { useDeleteClientPresentationV2Mutation } from './graphql/ClientPresentationDesignerV2.generated';

export const HEADER_HEIGHT = '73px';

export function ClientPresentationDesignerV2Header() {
  const trackUserEvent = useTrackUserEvent();
  const { showFeedback } = useFeedback();
  const { navigate } = useNavigateToRoute();
  const { householdId, presentationId } =
    useClientPresentationDesignerV2Context();
  const { control } = useFormContext<ClientPresentationV2Shape>();
  const [title] = useWatch({ control, name: ['title'] });
  const [printDocument, { loading }] = useDownloadClientPresentation(
    householdId,
    presentationId,
    true
  );

  const [deletePresentation, { loading: deleteLoading }] =
    useDeleteClientPresentationV2Mutation({
      variables: {
        householdId,
        presentationId,
      },
      onCompleted: () => {
        showFeedback('Presentation deleted successfully', {
          variant: 'success',
        });
        navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_PRESENTATIONS, {
          householdId,
        });
      },
      onError: () => {
        showFeedback('Failed to delete presentation');
      },
    });

  const handlePrintAndDownloadDocument = () => {
    void printDocument({
      onCompleted: (data) => {
        const downloadUrl = data.print.download.downloadURL;
        try {
          void $downloadFileFromURL(downloadUrl, 'Presentation');
          trackUserEvent('presentation exported', {
            presentationId,
            householdId,
            source: 'presentation_designer',
          });
          showFeedback('PDF generated successfully', { variant: 'success' });
        } catch (err) {
          showFeedback('Failed to download PDF. Please try again.');
        }
      },
    });
  };
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        borderBottom: `1px solid ${COLORS.ORANGE[100]}`,
        px: 3,
        py: 2,
      }}
      height={HEADER_HEIGHT}
    >
      {title === null ? (
        <Skeleton height="40px" width="400px" />
      ) : (
        <FormAwareEditableHeader<ClientPresentationV2Shape>
          fieldName="title"
          control={control}
        />
      )}
      <Stack direction="row" spacing={1}>
        <Button
          variant="secondary"
          size="sm"
          onClick={() =>
            navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_PRESENTATIONS, {
              householdId,
            })
          }
        >
          Close
        </Button>
        <ContextMenuButton>
          <MenuItem
            label="Download PDF"
            loading={loading}
            onClick={handlePrintAndDownloadDocument}
          />
          <DeleteMenuItemWithConfirmationModal
            label="Delete presentation"
            menuItemProps={{ loading: deleteLoading }}
            modalProps={{
              heading: 'Are you sure you want to delete this presentation?',
            }}
            onConfirmDelete={() => deletePresentation()}
          />
        </ContextMenuButton>
      </Stack>
    </Stack>
  );
}

function PresentationSlideWrapper({ sx = {}, ...otherProps }: BoxProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        border: `solid 1px ${COLORS.NEUTRAL_GRAY[100]}`,
        backgroundColor: 'white',
        boxShadow: theme.palette.shadows.lg,
        maxWidth: '80%',
        pointerEvents: 'none',
        ...sx,
      }}
      {...otherProps}
    />
  );
}

export function ClientPresentationDesignerV2SlideDisplay() {
  const { control } = useFormContext<ClientPresentationV2Shape>();
  const [bundles, selectedItemId, presentationConfiguration, title] = useWatch({
    control,
    name: ['bundles', 'selectedItemId', 'presentationConfiguration', 'title'],
  });
  return (
    <Box flex={1} sx={{ overflowY: 'auto' }}>
      <InteractionParadigmContext.Provider value={{ viewOnly: true }}>
        <CustomerThemeProvider>
          <Stack
            spacing={3}
            alignItems="center"
            justifyContent="flex-start"
            position="relative"
            sx={{
              py: 3,
            }}
          >
            {bundles.map((bundle, idx) => {
              const isSinglePage = isSinglePageBundle(bundle.type);
              if (isSinglePage) {
                return (
                  <ClientPresentationV2RenderSlide
                    key={`designer-bundle-${bundle.id}-${idx}`}
                    bundle={bundle}
                    page={undefined}
                    presentationConfiguration={presentationConfiguration}
                    title={title}
                    isVisible={selectedItemId === bundle.id}
                    SlideWrapper={PresentationSlideWrapper}
                    bundleCount={bundles.length}
                  />
                );
              }

              return (
                <Fragment key={`designer-bundle-${bundle.id}-${idx}`}>
                  {bundle.pages.map((page) => {
                    return (
                      <ClientPresentationV2RenderSlide
                        key={`print-bundle-${bundle.id}-page-${page.id}-${idx}`}
                        bundle={bundle}
                        page={page}
                        presentationConfiguration={presentationConfiguration}
                        title={title}
                        isVisible={page.id === selectedItemId}
                        SlideWrapper={PresentationSlideWrapper}
                        bundleCount={bundles.length}
                      />
                    );
                  })}
                </Fragment>
              );
            })}
          </Stack>
        </CustomerThemeProvider>
      </InteractionParadigmContext.Provider>
    </Box>
  );
}

interface ClientPresentationV2RenderSlideProps extends BaseBundleSlideProps {
  presentationConfiguration: ClientPresentationV2Shape['presentationConfiguration'];
  title: ClientPresentationV2Shape['title'];
  /**
   * Necessary for the table of contents to allocate pages for itself -- each bundle should have
   * one page registered.
   */
  bundleCount: number;
}

export function ClientPresentationV2RenderSlide({
  bundle,
  page,
  SlideWrapper,
  presentationConfiguration,
  title,
  isVisible = true,
  bundleCount,
}: ClientPresentationV2RenderSlideProps) {
  // using an if/else chain here because it's crossing between page & bundle
  if (page?.type === PresentationPageKind.WaterfallSummaryPage) {
    return (
      <EstateWaterfallSummarySlide
        bundle={bundle}
        page={page}
        SlideWrapper={SlideWrapper}
        isVisible={isVisible}
      />
    );
  } else if (page?.type === PresentationPageKind.WaterfallDiagramPage) {
    return (
      <EstateWaterfallDiagramSlide
        bundle={bundle}
        page={page}
        SlideWrapper={SlideWrapper}
        isVisible={isVisible}
      />
    );
  } else if (page?.type === PresentationPageKind.WaterfallTaxOverviewPage) {
    return (
      <EstateWaterfallTaxOverviewSlide
        bundle={bundle}
        page={page}
        SlideWrapper={SlideWrapper}
        federalEstateTaxPercent={new Decimal(0.4)} // TODO
        isVisible={isVisible}
      />
    );
  } else if (page?.type === PresentationPageKind.WaterfallBeneficiariesPage) {
    return (
      <EstateWaterfallBeneficiarySlide
        bundle={bundle}
        page={page}
        SlideWrapper={SlideWrapper}
        isVisible={isVisible}
      />
    );
  } else if (page?.type === PresentationPageKind.EntitySummaryPage) {
    return (
      <EntityOverviewSlide
        bundle={bundle}
        page={page}
        SlideWrapper={SlideWrapper}
        isVisible={isVisible}
      />
    );
  }

  switch (bundle?.type) {
    case PresentationBundleKind.EntitySummaryBundle:
      return (
        <EntityOverviewSlide
          bundle={bundle}
          SlideWrapper={SlideWrapper}
          isVisible={isVisible}
        />
      );
    case PresentationBundleKind.CoverSlideBundle:
      return (
        <CoverSlide
          bundle={bundle}
          SlideWrapper={SlideWrapper}
          presentationConfiguration={presentationConfiguration}
          presentationName={title ?? ''}
          isVisible={isVisible}
        />
      );
    case PresentationBundleKind.CustomPageBundle:
      return (
        <CustomSlideBundle
          bundle={bundle}
          SlideWrapper={SlideWrapper}
          isVisible={isVisible}
        />
      );
    case PresentationBundleKind.EntitiesOverviewBundle:
      return (
        <AllEntitiesSlide
          bundle={bundle}
          SlideWrapper={SlideWrapper}
          isVisible={isVisible}
        />
      );
    case PresentationBundleKind.BalanceSheetBundle:
      return (
        <BalanceSheetSlide
          bundle={bundle}
          SlideWrapper={SlideWrapper}
          isVisible={isVisible}
        />
      );
    case PresentationBundleKind.ProfessionalTeamBundle:
      return (
        <ProfessionalTeamSlide
          bundle={bundle}
          SlideWrapper={SlideWrapper}
          isVisible={isVisible}
        />
      );
    case PresentationBundleKind.StandaloneDisclaimerBundle:
      return (
        <StandaloneDisclaimerSlide
          bundle={bundle}
          SlideWrapper={SlideWrapper}
          isVisible={isVisible}
        />
      );
    case PresentationBundleKind.TableOfContentsBundle:
      return (
        <TableOfContents
          bundle={bundle}
          SlideWrapper={SlideWrapper}
          isVisible={isVisible}
          bundleCount={bundleCount}
        />
      );
    default: {
      return null;
    }
  }
}
