import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { WithClasses } from '@/styles/types';

const useStyles = makeStyles()((_theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    '& > *:first-of-type': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '& > *:last-of-type': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderLeft: 'none',
    },
    '& > *:not(:first-of-type):not(:last-of-type)': {
      borderLeft: 'none',
      borderRadius: 0,
    },
  },
}));

export interface ControlButtonGroupProps {
  children: ReactNode;
  classes?: WithClasses<typeof useStyles>;
}

export function ControlButtonGroup({
  children,
  classes: externalClasses,
}: ControlButtonGroupProps) {
  const { classes } = useStyles(undefined, {
    props: { classes: externalClasses },
  });

  return (
    <Stack direction="row" className={classes.root}>
      {children}
    </Stack>
  );
}
