import Decimal from 'decimal.js';
import { compact } from 'lodash';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import {
  AugmentedCreateGrowthProfileOverrideInput,
  GrowthProfileOverrideKind,
} from '@/types/schema';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';
import { getNodes } from '@/utils/graphqlUtils';

import {
  GrowthProfileModal_AssetClassFragment,
  GrowthProfileModal_GrowthProfileFragment,
  GrowthProfileModal_GrowthProfileOverrideFragment,
} from './graphql/GrowthProfileModal.generated';
import {
  CreateGrowthProfileMutationVariables,
  UpdateGrowthProfileMutationVariables,
} from './graphql/GrowthProfileModalMutations.generated';
import {
  CustomEntityGrowthCustomizationType,
  defaultGrowthProfileModalFormShape,
  GrowthProfileCustomGrowthOverrideRowData,
  GrowthProfileModalFormShape,
  GrowthProfileModalGrowthOverride,
} from './GrowthProfileModal.types';

function getGrowthProfileInput(data: GrowthProfileModalFormShape) {
  return {
    displayName: data.displayName,
    exemptionGrowthRate: data.exemptionGrowthRate,
    growthType: data.growthType,
    assetGrowthReturn: data.assetGrowthReturn,
    willExemptionSunset: data.willExemptionSunset,
  };
}

function getGrowthProfileOverrideInput(
  override: GrowthProfileModalGrowthOverride,
  householdID: string
): AugmentedCreateGrowthProfileOverrideInput | null {
  const {
    // these fields are only needed on the client
    id: _id,
    customizationType: _customizationType,
    displayName: _displayName,
    defaultGrowthRate: _defaultGrowthRate,
    entity,
    entityId,
    assetClass,
    ...rest
  } = override;

  // don't include overrides w/o custom growth rates
  if (
    override.kind === GrowthProfileOverrideKind.Asset &&
    !override.customGrowthRate
  ) {
    return null;
  }

  return {
    create: {
      householdID,
      entityID: entityId ?? entity?.id,
      assetClassID: assetClass?.id,
      ...rest,
    },
  };
}

export function mapFormDataToCreateGrowthProfileInput(
  data: GrowthProfileModalFormShape,
  householdID: string
): CreateGrowthProfileMutationVariables {
  return {
    input: {
      create: {
        householdID,
        ...getGrowthProfileInput(data),
      },
      withGrowthProfileOverrides: compact(
        data.growthProfileOverrides.map<AugmentedCreateGrowthProfileOverrideInput | null>(
          (override) => getGrowthProfileOverrideInput(override, householdID)
        )
      ),
    },
  };
}

export function mapFormDataToUpdateGrowthProfileInput(
  data: GrowthProfileModalFormShape,
  householdID: string,
  growthProfileId: string
): UpdateGrowthProfileMutationVariables {
  return {
    input: {
      id: growthProfileId,
      update: {
        ...getGrowthProfileInput(data),
        clearGrowthProfileOverrides: true,
      },
      withGrowthProfileOverrides: compact(
        data.growthProfileOverrides.map<AugmentedCreateGrowthProfileOverrideInput | null>(
          (override) => getGrowthProfileOverrideInput(override, householdID)
        )
      ),
    },
  };
}

function mapIncomingEntityOverridesToForm(
  overrides: GrowthProfileModal_GrowthProfileOverrideFragment[]
): GrowthProfileModalGrowthOverride[] {
  return overrides
    .filter(({ kind }) => kind === GrowthProfileOverrideKind.Entity)
    .map((growthProfile, index) => ({
      ...growthProfile,
      id: index,
      customizationType: CustomEntityGrowthCustomizationType.None, // TODO
      displayName: growthProfile.entity?.subtype?.displayName ?? 'Unknown',
    }));
}

function mapIncomingAssetClassesToForm(
  assetClasses: GrowthProfileModal_AssetClassFragment[],
  overrides: GrowthProfileModal_GrowthProfileOverrideFragment[],
  entityOverridesLength: number
): GrowthProfileModalGrowthOverride[] {
  return assetClasses.map<GrowthProfileModalGrowthOverride>(
    (assetClass, index) => {
      const matchingOverride = overrides.find(
        (override) => override.assetClass?.id === assetClass.id
      );

      return {
        id: index + entityOverridesLength,
        kind: GrowthProfileOverrideKind.Asset,
        customizationType: CustomEntityGrowthCustomizationType.None,
        defaultGrowthRate: assetClass.growthRate,
        customGrowthRate: matchingOverride?.customGrowthRate,
        displayName: assetClass.displayName,
        assetClass,
      };
    }
  );
}

export function mapIncomingDataToForm(
  growthProfile: GrowthProfileModal_GrowthProfileFragment | null | undefined,
  assetClasses: GrowthProfileModal_AssetClassFragment[]
): GrowthProfileModalFormShape {
  const overrides = getNodes(growthProfile?.growthProfileOverrides);
  const entityOverrides = mapIncomingEntityOverridesToForm(overrides);
  const assetClassOverrides = mapIncomingAssetClassesToForm(
    assetClasses,
    overrides,
    entityOverrides.length
  );
  return {
    displayName:
      growthProfile?.displayName ??
      defaultGrowthProfileModalFormShape.displayName,
    exemptionGrowthRate:
      growthProfile?.exemptionGrowthRate ??
      defaultGrowthProfileModalFormShape.exemptionGrowthRate,
    willExemptionSunset:
      growthProfile?.willExemptionSunset ??
      defaultGrowthProfileModalFormShape.willExemptionSunset,
    growthType:
      growthProfile?.growthType ??
      defaultGrowthProfileModalFormShape.growthType,
    assetGrowthReturn:
      growthProfile?.assetGrowthReturn ??
      defaultGrowthProfileModalFormShape.assetGrowthReturn,
    growthProfileOverrides: [...entityOverrides, ...assetClassOverrides],
  };
}

export function mapGrowthOverrideToTableRow({
  id,
  entity,
  assetClass,
  customGrowthRate,
  entityFutureValuationYear,
  entityFutureValuationValue,
  entityGrowthBeforeFutureValuation,
  entityGrowthAfterFutureValuation,
}: GrowthProfileModalGrowthOverride): GrowthProfileCustomGrowthOverrideRowData {
  if (assetClass) {
    return {
      id,
      kind: GrowthProfileOverrideKind.Asset,
      displayName: assetClass?.displayName ?? 'Unknown',
      displayType: 'Asset class',
      futureValuationValue: EMPTY_CONTENT_HYPHEN,
      futureValuationYear: undefined,
      growthRateValue: `${formatPercent(
        customGrowthRate ?? new Decimal(0),
        2
      )}%`,
      growthRateSubtitle: undefined,
    };
  }

  const futureValuationValue = entityFutureValuationValue
    ? formatCurrency(entityFutureValuationValue)
    : EMPTY_CONTENT_HYPHEN;

  const futureValuationYear: string | undefined =
    entityFutureValuationValue && entityFutureValuationYear
      ? `at year ${entityFutureValuationYear.toString()}`
      : undefined;

  let growthRateValue = customGrowthRate
    ? `${formatPercent(customGrowthRate, 2)}%`
    : EMPTY_CONTENT_HYPHEN;
  let growthRateSubtitle: string | undefined = undefined;

  if (entityGrowthBeforeFutureValuation && entityGrowthAfterFutureValuation) {
    growthRateValue = `${formatPercent(entityGrowthBeforeFutureValuation, 2)}% / ${formatPercent(entityGrowthAfterFutureValuation, 2)}%`;
    growthRateSubtitle = 'before/after future valuation';
  }

  return {
    id,
    kind: GrowthProfileOverrideKind.Entity,
    displayName: entity?.subtype?.displayName ?? 'Unknown',
    displayType: 'Entity',
    futureValuationValue,
    futureValuationYear,
    growthRateValue,
    growthRateSubtitle,
  };
}
