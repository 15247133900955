import { Box } from '@mui/material';
import { chunk } from 'lodash';
import { useMemo } from 'react';

import { ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import { NonTrustCharitableEntitySummaryData } from '@/modules/nonTrustCharitableEntities/NonTrustCharitableEntityDetails/NonTrustCharitableEntityDetails.types';
import { getNonTrustCharitableEntitySummaryProperties } from '@/modules/nonTrustCharitableEntities/NonTrustCharitableEntityDetails/NonTrustCharitableEntityDetails.utils';
import {
  getPaginatedBeneficiariesAndDispositionsForSummary,
  GetPaginatedBeneficiariesAndDispositionsForSummaryOutput,
} from '@/modules/presentation/entities/entityPresentationPagination.utils';
import { EntitySummaryGrantor } from '@/modules/presentation/entities/entityPresentations.types';
import { NonTrustCharitableEntitySummarySlide } from '@/modules/presentation/entities/nonTrustCharitableEntities/components/NonTrustCharitableEntity.SummarySlide';
import { EntityKind, PresentationBundleKind, StateCode } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';

import { useRegisterSlide } from '../../../ClientPresentationDesignerV2.context';
import { ClientPresentationDesignerV2_EntityFragment } from '../../../graphql/ClientPresentationDesignerV2.generated';
import { ClientPresentationV2Footer } from '../../ClientPresentationV2Footer';
import { BaseBundleSlideProps } from '../BundleSlide.types';
import { InsurancePoliciesSlide } from './InsurancePoliciesSlideV2';

interface CharitableSummarySlidePropsInner extends CharitableSummarySlideProps {
  slideProps: GetPaginatedBeneficiariesAndDispositionsForSummaryOutput;
  idx: number;
  summary: NonTrustCharitableEntitySummaryData | null;
  grantors: EntitySummaryGrantor[];
}

function CharitableSummarySlideInner({
  bundle,
  page,
  entity,
  slideProps,
  idx,
  SlideWrapper = Box,
  summary,
  grantors,
  isVisible,
}: CharitableSummarySlidePropsInner) {
  const slideId = `charitable-trust-summary-slide-${bundle.id}-${page?.id || 'no-page'}-${entity.id}-${idx}`;
  useRegisterSlide({
    slideId,
    title:
      bundle.displayName ?? `Entity Summary -- ${entity.subtype.displayName}`,
    includeInToC: idx === 0,
    bundleKind: PresentationBundleKind.EntitySummaryBundle,
  });

  if (!isVisible) {
    return null;
  }

  return (
    <SlideWrapper key={slideId}>
      <NonTrustCharitableEntitySummarySlide
        registrationProps={{
          bundleId: bundle.id,
          slideId,
          bundleIndex: idx,
        }}
        entity={entity}
        summary={summary!}
        displayName={entity.subtype.displayName}
        grantors={grantors}
        Footer={<ClientPresentationV2Footer slideId={slideId} />}
        {...slideProps}
      />
    </SlideWrapper>
  );
}

export interface CharitableSummarySlideProps extends BaseBundleSlideProps {
  entity: ClientPresentationDesignerV2_EntityFragment;
  entityKind: EntityKind.DonorAdvisedFund | EntityKind.PrivateFoundation;
}

export function CharitableSummarySlide({
  entity,
  bundle,
  page,
  SlideWrapper = Box,
  entityKind,
  isVisible,
}: CharitableSummarySlideProps) {
  const { availableStateEstateTaxes } = useIRSConstants();
  const grantors = useMemo<EntitySummaryGrantor[]>(() => {
    return (
      entity.household.possiblePrimaryClients?.map((grantor) => ({
        displayName: grantor.displayName,
        id: grantor.id,
        firstName: grantor.firstName,
        lastName: grantor.lastName,
        stateCode: grantor.address?.stateCode as StateCode | undefined,
        hasStateTax: grantor.address?.stateCode
          ? (availableStateEstateTaxes?.includes(
              grantor.address.stateCode as StateCode
            ) ?? false)
          : false,
      })) ?? []
    );
  }, [availableStateEstateTaxes, entity.household.possiblePrimaryClients]);

  let summary: NonTrustCharitableEntitySummaryData | null = null;

  switch (entityKind) {
    case EntityKind.DonorAdvisedFund:
      summary = getNonTrustCharitableEntitySummaryProperties({
        entity,
        entityType: ENTITY_TYPES.DAF,
      });
      break;
    case EntityKind.PrivateFoundation:
      summary = getNonTrustCharitableEntitySummaryProperties({
        entity,
        entityType: ENTITY_TYPES.PRIVATE_FOUNDATION,
      });
      break;
    default:
      throw new UnreachableError({
        case: entityKind,
        message: `Unhandled charitable entity kind: ${entityKind}`,
      });
  }

  if (!summary) {
    diagnostics.error(
      `No summary data found for entity ${entity.id} in ${bundle.id}`
    );
    return null;
  }

  const policyPages = chunk(summary.insurancePolicies, 2);

  const summaryPagesProps = getPaginatedBeneficiariesAndDispositionsForSummary({
    dispositionScenarios:
      entity.dispositionScenariosSubtype?.dispositionScenarios ?? [],
    entityId: entity.id,
    slideTypes: [],
    beneficiaries: summary.beneficiaries ?? [],
    entityKind: entity.kind,
    grantors,
    description: summary.description,
    reserveFirstPage: isFeatureFlagEnabled('entity_diagrams'),
  });

  return (
    <>
      {summaryPagesProps.map((slideProps, idx) => (
        <CharitableSummarySlideInner
          key={`revocable-irrevocable-trust-summary-slide-${bundle.id}-${page?.id || 'no-page'}-${idx}`}
          bundle={bundle}
          page={page}
          entity={entity}
          slideProps={slideProps}
          idx={idx}
          summary={summary}
          grantors={grantors}
          entityKind={entityKind}
          isVisible={isVisible}
        />
      ))}
      {policyPages.map((pagePolicies, i) => (
        <InsurancePoliciesSlide
          key={`revocable-irrevocable-trust-summary-slide-${bundle.id}-${page?.id || 'no-page'}-insurance-{idx}`}
          entity={entity}
          bundle={bundle}
          page={page}
          SlideWrapper={SlideWrapper}
          idx={i}
          pagePolicies={pagePolicies}
          numberOfPolicySlides={policyPages.length}
          isVisible={isVisible}
        />
      ))}
    </>
  );
}
