import { ArrowEdge } from '../../edges/ArrowEdge';
import { SectionLabelNode } from '../../nodes/SectionLabelNode';
import { TileNode } from '../../nodes/TileNode/TileNode';
import {
  DefaultNodes,
  ExternalFlowChartProps,
  FlowChartProps,
  InternalFlowChartProps,
} from '../../types';

const proOptions: InternalFlowChartProps['proOptions'] = {
  hideAttribution: true,
};

const defaultNodeTypes: Omit<
  InternalFlowChartProps['nodeTypes'],
  // Omit defaults because they are provided by reactflow out of the box
  NonNullable<DefaultNodes['type']>
> = {
  tile: TileNode,
  sectionLabel: SectionLabelNode,
};

const defaultEdgeTypes: InternalFlowChartProps['edgeTypes'] = {
  arrow: ArrowEdge,
};

export function useDefaultProps({
  loading = false,
  slots,
  presentationMode = false,
  ...otherInternalProps
}: FlowChartProps): {
  internalProps: InternalFlowChartProps;
  externalProps: ExternalFlowChartProps;
} {
  const internalProps: InternalFlowChartProps = {
    // Start: props that make a flow "editable". We initialize edit state to "locked" by default
    nodesConnectable: false,
    nodesDraggable: false,
    elementsSelectable: true,
    // End: props that make a flow "editable".

    // Have zoom occur only when "Meta" key is pressed (default behavior)
    // Ties into usePreventScrolling handler
    zoomOnScroll: false,
    zoomOnPinch: false,
    // End zoom / scroll handler

    proOptions,
    fitView: true,
    zoomOnDoubleClick: false,
    minZoom: 0.1,
    nodeTypes: defaultNodeTypes as InternalFlowChartProps['nodeTypes'],
    edgeTypes: defaultEdgeTypes,
    ...otherInternalProps,
  };

  const externalProps: ExternalFlowChartProps = {
    loading,
    slots,
    presentationMode,
  };

  return { internalProps, externalProps };
}
