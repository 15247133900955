import { MutableRefObject, useRef } from 'react';

import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { BeneficiaryDropdown_HouseholdFragment } from '@/modules/entities/inputs/BeneficiaryDropdown/graphql/BeneficiaryDropdown.generated';
import {
  AugmentedCreateLoggedTransferInput,
  AugmentedUpdateLoggedTransferInput,
  CreateBeneficiaryInput,
  CreateLoggedTransferInput,
  LifetimeExclusionEventKind,
  LoggedTransferPurpose,
  UpdateLoggedTransferInput,
} from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { getNodes } from '@/utils/graphqlUtils';

import { useLifetimeExclusionEventsWithLoggedTransfersQuery } from '../graphql/LifetimeExclusionEvents.generated';
import { NAMESPACE } from './LogNewGiftModal.constants';
import { LogNewGiftForm } from './LogNewGiftModal.types';

function makeBeneficiaryInput(
  form: LogNewGiftForm,
  possibleBeneficiaries: BeneficiaryDropdown_HouseholdFragment
): {
  beneficiary: CreateBeneficiaryInput;
  transfer: Partial<CreateLoggedTransferInput>;
} {
  const beneficiaryId = form[NAMESPACE].beneficiary.beneficiaryId;

  // find beneficiaryId in the form options to determine the type of beneficiary

  const isIndividual =
    possibleBeneficiaries.possibleBeneficiariesV2.clients.some((client) => {
      return client.id === beneficiaryId;
    });

  if (isIndividual) {
    return {
      beneficiary: {
        individualID: form[NAMESPACE].beneficiary.beneficiaryId,
      },
      transfer: {
        receivingGrantorID: form[NAMESPACE].beneficiary.beneficiaryId,
      },
    };
  }

  const isEntity = possibleBeneficiaries.possibleBeneficiariesV2.entities.some(
    (entity) => {
      return entity.id === beneficiaryId;
    }
  );

  if (isEntity) {
    return {
      beneficiary: {
        entityID: form[NAMESPACE].beneficiary.beneficiaryId,
      },
      transfer: {
        receivingEntityID: form[NAMESPACE].beneficiary.beneficiaryId,
      },
    };
  }

  const isOrganization =
    possibleBeneficiaries.possibleBeneficiariesV2.organizations.some(
      (organization) => {
        return organization.id === beneficiaryId;
      }
    );

  if (isOrganization) {
    return {
      beneficiary: {
        organizationID: form[NAMESPACE].beneficiary.beneficiaryId,
      },
      transfer: {
        receivingOrganizationID: form[NAMESPACE].beneficiary.beneficiaryId,
      },
    };
  }

  throw new UnreachableError({
    case: beneficiaryId as never,
    message: 'Beneficiary type not found',
  });
}

export function getCreateLoggedTransferEventInput(
  form: LogNewGiftForm,
  possibleBeneficiaries?: BeneficiaryDropdown_HouseholdFragment | null
): AugmentedCreateLoggedTransferInput {
  if (!form[NAMESPACE].dateOfGift) {
    throw new Error('Date of gift is required');
  }

  if (!possibleBeneficiaries) {
    throw new Error(`Must have possible beneficiaries`);
  }

  if (!form[NAMESPACE].lifetimeExemptionUsed) {
    throw new Error('Lifetime exemption used is required');
  }

  if (!form[NAMESPACE].gstExemptionApplied) {
    throw new Error('GST exemption applied is required');
  }

  if (!form[NAMESPACE].giftAmount) {
    throw new Error('Gift amount is required');
  }

  const { beneficiary, transfer } = makeBeneficiaryInput(
    form,
    possibleBeneficiaries
  );

  const create: CreateLoggedTransferInput = {
    amount: form[NAMESPACE].giftAmount,
    documentIDs: form[NAMESPACE].documentIds,
    otherPurposeDescription: undefined,
    purpose: LoggedTransferPurpose.TaxableGift,
    receivingEntityID: undefined,
    receivingGrantorID: undefined,
    receivingOrganizationID: undefined,
    sourceGrantorID: form[NAMESPACE].grantor.clientProfileId,
    transactionDate: form[NAMESPACE].dateOfGift,
    ...transfer,
  };

  const input: AugmentedCreateLoggedTransferInput = {
    create,
    withLifetimeExclusionEvents: [
      {
        create: {
          giftCategory: form[NAMESPACE].assetType,
          eventDate: form[NAMESPACE].dateOfGift,
          documentIDs: form[NAMESPACE].documentIds,
          discountAmount: form[NAMESPACE].discountedValueOfGift,
          annualExclusionAmount: form[NAMESPACE].annualExclusionApplied,
          giftAmount: form[NAMESPACE].giftAmount,
          lifetimeExclusionAmount: form[NAMESPACE].lifetimeExemptionUsed,
          kind: LifetimeExclusionEventKind.Gift,
          gstExclusionAmount: form[NAMESPACE].gstExemptionApplied,
          grantorID: form[NAMESPACE].grantor.clientProfileId,
          notes: form[NAMESPACE].notes,
        },
        withRecipient: {
          create: beneficiary,
        },
      },
    ],
  };

  return input;
}

export function getUpdateLifetimeExclusionEventInput({
  form,
  originalDocumentIds,
  possibleBeneficiaries,
}: {
  form: LogNewGiftForm;
  originalDocumentIds: MutableRefObject<string[]>;
  possibleBeneficiaries?: BeneficiaryDropdown_HouseholdFragment | null;
}): AugmentedUpdateLoggedTransferInput {
  if (!form[NAMESPACE].lifetimeExclusionEventId) {
    throw new Error('Lifetime exclusion event id is required');
  }

  if (!form[NAMESPACE].loggedTransferId) {
    throw new Error('Logged transfer id is required');
  }

  if (!possibleBeneficiaries) {
    throw new Error(`Must have possible beneficiaries`);
  }

  if (!form[NAMESPACE].dateOfGift) {
    throw new Error('Date of gift is required');
  }

  if (!form[NAMESPACE].lifetimeExemptionUsed) {
    throw new Error('Lifetime exemption used is required');
  }

  if (!form[NAMESPACE].gstExemptionApplied) {
    throw new Error('GST exemption applied is required');
  }

  const addDocumentIDs = form[NAMESPACE].documentIds.filter(
    (d) => !originalDocumentIds.current.includes(d)
  );
  const removeDocumentIDs = originalDocumentIds.current.filter(
    (d) => !form[NAMESPACE].documentIds.includes(d)
  );
  const { beneficiary, transfer } = makeBeneficiaryInput(
    form,
    possibleBeneficiaries
  );
  const update: UpdateLoggedTransferInput = {
    addDocumentIDs,
    removeDocumentIDs,
    amount: form[NAMESPACE].giftAmount,
    purpose: LoggedTransferPurpose.TaxableGift,
    transactionDate: form[NAMESPACE].dateOfGift,
    sourceGrantorID: form[NAMESPACE].grantor.clientProfileId,
    ...transfer,
  };
  const input: AugmentedUpdateLoggedTransferInput = {
    id: form[NAMESPACE].loggedTransferId,
    update,
    updateLifetimeExclusionEvents: [
      {
        id: form[NAMESPACE].lifetimeExclusionEventId,
        update: {
          eventDate: form[NAMESPACE].dateOfGift,
          lifetimeExclusionAmount: form[NAMESPACE].lifetimeExemptionUsed,
          gstExclusionAmount: form[NAMESPACE].gstExemptionApplied,
          grantorID: form[NAMESPACE].grantor.clientProfileId,
          clearRecipient: true,
          notes: form[NAMESPACE].notes,
          removeDocumentIDs,
          addDocumentIDs,
          annualExclusionAmount: form[NAMESPACE].annualExclusionApplied,
          discountAmount: form[NAMESPACE].discountedValueOfGift,
          giftAmount: form[NAMESPACE].giftAmount,
          giftCategory: form[NAMESPACE].assetType,
        },
        withRecipient: {
          create: beneficiary,
        },
      },
    ],
  };

  return input;
}

export function useSyncDataToForm({
  lifetimeExclusionEventId,
  isDuplicate,
  defaultBeneficiaryId,
  isOpen,
}: {
  lifetimeExclusionEventId?: string;
  isDuplicate?: boolean;
  defaultBeneficiaryId?: string;
  isOpen: boolean;
}) {
  const { reset } = useFormContext<LogNewGiftForm>();

  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const originalDocumentIds = useRef<string[]>([]);

  const { loading } = useLifetimeExclusionEventsWithLoggedTransfersQuery({
    variables: {
      where: {
        id: lifetimeExclusionEventId,
      },
    },
    skip: !lifetimeExclusionEventId || !isOpen,
    onError: (error) => {
      showFeedback(FeedbackMessages.queryError);
      reportError('failed to load lifetime exclusion event', error, {
        lifetimeExclusionEventId,
      });
    },
    onCompleted: (data) => {
      const lifetimeExclusionEvents = getNodes(data.lifetimeExclusionEvents);

      const loggedTransfer = lifetimeExclusionEvents?.[0]?.loggedTransfer;
      if (!loggedTransfer) {
        reportError(
          'Missing logged transfer when editing; showing an empty modal',
          null,
          {
            lifetimeExclusionEventId,
          }
        );
        showFeedback(
          `We're unexpectedly missing some data for this gift. Our team has been notified; please refresh the page and try again.`,
          {
            variant: 'warning',
          }
        );
        return;
      }

      const lifetimeExclusionEvent =
        loggedTransfer.lifetimeExclusionEvents?.[0];
      if (!lifetimeExclusionEvent) return;

      const possibleEntityBeneficiaryId = loggedTransfer.receivingEntity?.id;
      const possibleIndividualBeneficiaryId =
        loggedTransfer.receivingGrantor?.id;
      const possibleOrganizationBeneficiaryId =
        loggedTransfer.receivingOrganization?.id;

      const beneficiaryId =
        defaultBeneficiaryId ??
        possibleEntityBeneficiaryId ??
        possibleIndividualBeneficiaryId ??
        possibleOrganizationBeneficiaryId ??
        '';

      const existingValues = {
        [NAMESPACE]: {
          grantor: {
            clientProfileId: loggedTransfer.sourceGrantor?.id,
          },
          beneficiary: {
            beneficiaryId,
          },
          giftAmount: loggedTransfer.amount ?? null,
          dateOfGift: loggedTransfer.transactionDate,
          discountedValueOfGift: lifetimeExclusionEvent.discountAmount ?? null,
          annualExclusionApplied:
            lifetimeExclusionEvent.annualExclusionAmount ?? null,
          lifetimeExemptionUsed: lifetimeExclusionEvent.lifetimeExclusionAmount,
          gstExemptionApplied: lifetimeExclusionEvent.gstExclusionAmount,
          notes: lifetimeExclusionEvent.notes ?? '',
          assetType: lifetimeExclusionEvent.giftCategory ?? null,
          documentIds: isDuplicate
            ? []
            : (loggedTransfer.documents?.map((d) => d.id) ?? []),
          lifetimeExclusionEventId,
          loggedTransferId: loggedTransfer.id,
          isForcedBeneficiary: !!defaultBeneficiaryId,
        },
      };

      reset(existingValues);
      originalDocumentIds.current = existingValues[NAMESPACE].documentIds;
    },
  });

  return {
    loading,
    originalDocumentIds,
  };
}
