import { compact } from 'lodash';

import { AISuggestionMatch } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import {
  RecipientKind,
  RecipientWithId,
} from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/DispositiveProvisionsForm.types';
import {
  AiSuggestionKind,
  DispositiveProvisionDispositionKind,
  DispositiveProvisionRecipientKind,
} from '@/types/schema';
import { parseDecimalJSOrNull } from '@/utils/decimalJSUtils';

export function suggestionsToDPRecipientsSubform(
  matches: AISuggestionMatch[]
): NonNullable<RecipientWithId[]> {
  return compact(
    matches
      .filter(
        (m) =>
          m.suggestion.kind ===
            AiSuggestionKind.DpRecipientGrantor_1DiesFirst ||
          m.suggestion.kind === AiSuggestionKind.DpRecipientGrantor_2DiesFirst
      )
      .filter((m) => m.suggestion.dpRecipient)
      .map((match) => suggestionToDPRecipient(match))
  );
}

function suggestionToDPRecipient(
  match: AISuggestionMatch
): RecipientWithId | null {
  if (!match.suggestion.dpRecipient) return null;

  const { dpRecipient: r } = match.suggestion;

  const dpKindFields = mapDispositionKind(r?.dispositionKind);

  return {
    _dispositiveProvisionId: '',
    dispositionAmount: parseDecimalJSOrNull(r.distributionAmount),
    dispositionPercentage: parseDecimalJSOrNull(r.distributionPercentage),
    ...dpKindFields,
    notes: r.notes || '',
    // NOTE: recipientId is the person/org/entity/TE that has been selected to match
    // the suggested DP recipient. We're not using the suggested dpRecipient.recipientId
    // here because the user could have selected a different person/org/entity/TE
    // than the one we suggested.
    recipientId:
      match.clientProfileId ||
      match.clientOrganizationId ||
      match.entityId ||
      match.testamentaryId ||
      '',
    recipientKind: mapRecipientKind(r.recipientKind),
    transferTaxKind: r.recipientTransferTaxKind || '',
  };
}

function mapDispositionKind(
  dpKind: DispositiveProvisionDispositionKind | null | undefined
): Pick<
  RecipientWithId,
  'dispositionKind_Select' | 'dispositionKind_ButtonGroup'
> {
  if (!dpKind)
    return { dispositionKind_Select: '', dispositionKind_ButtonGroup: 'other' };

  if (
    dpKind === DispositiveProvisionDispositionKind.Amount ||
    dpKind === DispositiveProvisionDispositionKind.Percentage
  ) {
    return { dispositionKind_Select: '', dispositionKind_ButtonGroup: dpKind };
  }

  return {
    dispositionKind_Select: dpKind,
    dispositionKind_ButtonGroup: 'other',
  };
}

function mapRecipientKind(
  kind: DispositiveProvisionRecipientKind | null | undefined
): RecipientKind | null {
  if (!kind) return null;

  switch (kind) {
    case DispositiveProvisionRecipientKind.Individual:
      return RecipientKind.Individual;
    case DispositiveProvisionRecipientKind.Entity:
      return RecipientKind.Entity;
    case DispositiveProvisionRecipientKind.Organization:
      return RecipientKind.Organization;
    case DispositiveProvisionRecipientKind.TestamentaryEntity:
      return RecipientKind.TestamentaryEntity;
    case DispositiveProvisionRecipientKind.SurvivingSpouse:
      return RecipientKind.SurvivingSpouse;
  }
}
