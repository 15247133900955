import { useNodeId, useStore } from '@xyflow/react';

import { Node } from '../types';

/**
 * @description This hook is used to get the node data for the current node in react flow context, use it to avoid prop drilling into subcomponents.
 */
export function useNode<N extends Node = Node>(): N {
  const nodeId = useNodeId();

  if (!nodeId) {
    throw new Error(
      `No node id found in useNode, make sure to call this hook inside a react flow node`
    );
  }

  const node = useStore((s) => {
    return s.nodeLookup.get(nodeId);
  });

  if (!node) {
    throw new Error(`No node found for id ${nodeId}`);
  }

  return node as unknown as N;
}
