import { Box, Typography } from '@mui/material';

import { COLORS } from '@/styles/tokens/colors';
import { EntityKind } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import { useClientPresentationDesignerV2Context } from '../../../ClientPresentationDesignerV2.context';
import { ClientPresentationDesignerV2_EntityFragment } from '../../../graphql/ClientPresentationDesignerV2.generated';
import { BaseBundleSlideProps } from '../BundleSlide.types';
import { AccountSummarySlide } from './AccountSummarySlide';
import { BusinessSummarySlide } from './BusinessSummarySlide';
import { CharitableSummarySlide } from './CharitableSummarySlide';
import { TrustSummarySlide } from './TrustSummarySlide';

interface EntityOverviewSlideInnerProps extends BaseBundleSlideProps {
  entity: ClientPresentationDesignerV2_EntityFragment;
}

function EntityOverviewSlideInner({
  bundle,
  page,
  entity,
  SlideWrapper = Box,
  isVisible,
}: EntityOverviewSlideInnerProps) {
  switch (entity.kind) {
    // Trusts
    case EntityKind.IrrevocableTrust:
    case EntityKind.RevocableTrust:
    case EntityKind.SlatTrust:
    case EntityKind.QprtTrust:
    case EntityKind.IlitTrust:
    case EntityKind.CrtTrust:
    case EntityKind.CltTrust:
    case EntityKind.GratTrust:
      return (
        <TrustSummarySlide
          entity={entity}
          bundle={bundle}
          page={page}
          SlideWrapper={SlideWrapper}
          // passing in an explicit entityKind prop here to guard against new types
          entityKind={entity.kind}
          isVisible={isVisible}
        />
      );

    // Personal Accounts
    case EntityKind.IndividualPersonalAccount:
    case EntityKind.JointPersonalAccount:
    case EntityKind.InsurancePersonalAccount:
    case EntityKind.QualifiedTuitionPersonalAccount:
    case EntityKind.RetirementPersonalAccount:
    case EntityKind.CustodialPersonalAccount:
      return (
        <AccountSummarySlide
          entity={entity}
          bundle={bundle}
          page={page}
          SlideWrapper={SlideWrapper}
          entityKind={entity.kind}
          isVisible={isVisible}
        />
      );

    // Non-trust charitable entities
    case EntityKind.DonorAdvisedFund:
    case EntityKind.PrivateFoundation:
      return (
        <CharitableSummarySlide
          entity={entity}
          bundle={bundle}
          page={page}
          SlideWrapper={SlideWrapper}
          entityKind={entity.kind}
          isVisible={isVisible}
        />
      );

    // Business entities
    case EntityKind.LlcBusinessEntity:
    case EntityKind.SoleProprietorshipBusinessEntity:
    case EntityKind.ScorpBusinessEntity:
    case EntityKind.CcorpBusinessEntity:
    case EntityKind.GpBusinessEntity:
    case EntityKind.LpBusinessEntity:
      return (
        <BusinessSummarySlide
          entity={entity}
          bundle={bundle}
          page={page}
          SlideWrapper={SlideWrapper}
          entityKind={entity.kind}
          isVisible={isVisible}
        />
      );
  }
}

export function EntityOverviewSlide({
  bundle,
  page,
  SlideWrapper,
  isVisible,
}: BaseBundleSlideProps) {
  const entityId = bundle.entitySummaryConfiguration?.entityId;

  const { entityDetailMap } = useClientPresentationDesignerV2Context();

  if (!entityId) {
    diagnostics.error(`Could not find entity id for bundle ${bundle.id}`);
    return (
      <Typography color={COLORS.FUNCTIONAL.ERROR.DEFAULT}>
        Entity not found
      </Typography>
    );
  }
  const entity = entityDetailMap[bundle.id];

  if (!entity) {
    diagnostics.error(`Could not find entity for bundle ${bundle.id}`);
    return (
      <Typography color={COLORS.FUNCTIONAL.ERROR.DEFAULT}>
        Entity not found
      </Typography>
    );
  }

  return (
    <EntityOverviewSlideInner
      bundle={bundle}
      page={page}
      entity={entity}
      SlideWrapper={SlideWrapper}
      isVisible={isVisible}
    />
  );
}
