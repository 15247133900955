import { Box } from '@mui/material';

import { EntityDetail_InsurancePolicyFragment } from '@/modules/entities/details/graphql/EntityDetailPage.generated';
import { InsurancePoliciesSlide as InsurancePoliciesSlideExternal } from '@/modules/presentation/entities/components/InsurancePoliciesSlide';
import { PresentationBundleKind } from '@/types/schema';

import { useRegisterSlide } from '../../../ClientPresentationDesignerV2.context';
import { ClientPresentationDesignerV2_EntityFragment } from '../../../graphql/ClientPresentationDesignerV2.generated';
import { ClientPresentationV2Footer } from '../../ClientPresentationV2Footer';
import { BaseBundleSlideProps } from '../BundleSlide.types';

export interface InsurancePoliciesSlideProps extends BaseBundleSlideProps {
  entity: ClientPresentationDesignerV2_EntityFragment;
  idx: number;
  pagePolicies: EntityDetail_InsurancePolicyFragment[];
  numberOfPolicySlides: number;
  isVisible: boolean;
}

export function InsurancePoliciesSlide({
  entity,
  bundle,
  page,
  SlideWrapper = Box,
  idx,
  pagePolicies,
  numberOfPolicySlides,
  isVisible,
}: InsurancePoliciesSlideProps) {
  const slideId = `entity-overview-${bundle.id}-${page?.id || 'no-page'}-entity-${entity.id}-insurance-policies-${idx}`;
  useRegisterSlide({
    slideId,
    title: 'Insurance policies',
    includeInToC: false,
    bundleKind: PresentationBundleKind.EntitySummaryBundle,
  });

  if (!isVisible) {
    return null;
  }

  return (
    <SlideWrapper key={slideId}>
      <InsurancePoliciesSlideExternal
        entity={entity}
        pagePolicies={pagePolicies}
        slideIndex={idx}
        numberOfPolicySlides={numberOfPolicySlides}
        displayName={entity.subtype.displayName}
        Footer={<ClientPresentationV2Footer slideId={bundle.id} />}
        bundleId={bundle.id}
        slideId={slideId}
        bundleIndex={idx}
      />
    </SlideWrapper>
  );
}
