import { Position } from '@xyflow/react';
import { getYear } from 'date-fns';

import { TileVariant } from '@/components/diagrams/components/Tile/types';
import { Edge } from '@/components/diagrams/FlowChart';
import { getDisplayForWaterfallGrantorDeath } from '@/hooks/useWaterfallGrantorDeathDisplay';
import { GraphViz_NodeConfigFragment } from '@/modules/graphViz/graphql/GraphVizNodeConfig.fragment.generated';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';
import { AfterDeath } from '@/types/schema';
import { FlowChartGraph } from '@/utils/graphology/FlowChartGraph';

import { getVizFromEntity } from '../getVizFromEntity';
import { CltTermDiagram_EntityFragment } from '../graphql/CltTermDiagram.generated';
import { CrtTermDiagram_EntityFragment } from '../graphql/CrtTermDiagram.generated';
import { DispositionsDiagram_EntityFragment } from '../graphql/DispositionsDiagram.generated';
import { GratTermDiagram_EntityFragment } from '../graphql/GratTermDiagram.generated';
import { OwnershipDiagram_EntityFragment } from '../graphql/OwnershipDiagram.generated';
import { QprtTermDiagram_EntityFragment } from '../graphql/QprtTermDiagram.generated';
import {
  EntityDiagram_NodeFragment,
  EntityDiagramGraph,
  EntityDiagramGraphAttributes,
  EntityDiagramGraphEdgeAttributes,
  EntityDiagramGraphNodeAttributes,
  EntityDiagramVariant,
  GraphNodeCategorizationType,
} from '../types';
import { drawEdges } from './drawEdges';
import { buildTileFromNode, getCategorizationType, getNodeId } from './utils';

export type EdgeInput = Omit<Edge, 'id' | 'type'>;
export const createEdge = ({
  source,
  target,
  data,
  ...edge
}: EdgeInput): Edge => ({
  id: `${source}:${target}`,
  source,
  target,
  type: 'arrow',
  data: { hideLabel: true, ...data },
  sourceHandle: Position.Bottom,
  targetHandle: Position.Top,
  ...edge,
});

interface ApplyDeathSectionsInput {
  graph: EntityDiagramGraph;
  grantors: ContextPrimaryClient[];
  isTwoClientHousehold: boolean;
  firstGrantorDeathId: string;
}

function applyDeathSections({
  graph,
  grantors,
  isTwoClientHousehold,
  firstGrantorDeathId,
}: ApplyDeathSectionsInput) {
  // Always show at least the first two sections
  const deathSections = [AfterDeath.None, AfterDeath.First];
  if (isTwoClientHousehold) {
    deathSections.push(AfterDeath.Second);
  }
  const parentNodeIds = new Set(deathSections);

  const firstDeathGrantor = grantors.find((g) => g.id === firstGrantorDeathId);
  const secondDeathGrantor = grantors.find((g) => g.id !== firstGrantorDeathId);

  // Build the section group nodes
  parentNodeIds.forEach((parentNodeId) => {
    const afterDeath = parentNodeId;

    const label = getDisplayForWaterfallGrantorDeath({
      afterDeath,
      primaryClients: grantors,
      isTwoClientHousehold,
      firstGrantorDeathYear: null,
      secondGrantorDeathYear: null,
      firstDyingGrantorId: firstGrantorDeathId,
      alwaysIncludeYears: true,
      fallbackGrantorNames: {
        firstDeathFallback: `${firstDeathGrantor?.firstName || '1st Client'}'s`,
        secondDeathFallback: `${secondDeathGrantor?.firstName || '2nd Client'}'s`,
      },
    });

    graph.addNodeSafe(parentNodeId, {
      data: { afterDeath, id: parentNodeId } as EntityDiagram_NodeFragment,
      node: {
        id: parentNodeId,
        position: { x: 0, y: 0 },
        data: { label },
        type: 'sectionLabel',
        draggable: false,
      },
      categorizationType: GraphNodeCategorizationType.SectionLabel,
    });
  });
}

interface ApplyTermEndSectionInput {
  graph: EntityDiagramGraph;
  termEndDate?: Date;
}

function applyTermEndSections({
  graph,
  termEndDate,
}: ApplyTermEndSectionInput) {
  const deathSections = [AfterDeath.None, AfterDeath.First];

  const parentNodeIds = new Set(deathSections);

  // Build the section group nodes
  parentNodeIds.forEach((parentNodeId) => {
    const afterDeath = parentNodeId;

    const label = termEndDate ? `Term end ${getYear(termEndDate)}` : `Term end`;

    graph.addNodeSafe(parentNodeId, {
      data: { afterDeath, id: parentNodeId } as EntityDiagram_NodeFragment,
      node: {
        id: parentNodeId,
        position: { x: 0, y: 0 },
        data: { label },
        type: 'sectionLabel',
        draggable: false,
        hidden: parentNodeId === AfterDeath.None, // Hide the node bounding the top of the diagram
      },
      categorizationType: GraphNodeCategorizationType.SectionLabel,
    });
  });
}

interface CreateEntityDiagramGraphInput {
  entity:
    | DispositionsDiagram_EntityFragment
    | OwnershipDiagram_EntityFragment
    | GratTermDiagram_EntityFragment
    | QprtTermDiagram_EntityFragment
    | CrtTermDiagram_EntityFragment
    | CltTermDiagram_EntityFragment;
  grantors: ContextPrimaryClient[];
  isTwoClientHousehold: boolean;
  isGeneratingDefaultState?: boolean;
  firstGrantorDeathId: string;
  entityDiagramVariant: EntityDiagramVariant;
}

export function createEntityDiagramGraph({
  entity,
  grantors,
  isTwoClientHousehold,
  isGeneratingDefaultState = false,
  firstGrantorDeathId,
  entityDiagramVariant,
}: CreateEntityDiagramGraphInput): EntityDiagramGraph {
  const graph = new FlowChartGraph<
    EntityDiagramGraphNodeAttributes,
    EntityDiagramGraphEdgeAttributes,
    EntityDiagramGraphAttributes
  >();

  // TODO SINGLE_ENTITY_DIAGRAM we don't have configurations yet
  const nodeConfigurations: GraphViz_NodeConfigFragment[] = [];

  function nodeIdHasConfiguration(nodeId: string) {
    return (
      nodeConfigurations.some((config) => config.nodeID === nodeId) ?? false
    );
  }

  const viz = getVizFromEntity({
    entity,
    firstGrantorDeathId,
    isTwoClientHousehold,
    survivingSpouse: grantors.find((g) => g.id !== firstGrantorDeathId),
    entityDiagramVariant,
  });

  const vizNodes = viz.nodes;

  const noneOfTheVizNodesHaveConfigurations = (() => {
    const numVizNodesWithConfig = viz.nodes.filter((vizNode) =>
      nodeIdHasConfiguration(
        getNodeId({
          id: vizNode.id,
          afterDeath: vizNode.afterDeath,
        })
      )
    ).length;

    return numVizNodesWithConfig === 0;
  })();

  function getIsNewNodeById(id: string) {
    if (noneOfTheVizNodesHaveConfigurations) {
      // If we don't have any node configurations, only nodes not included in the default state are new
      if (isGeneratingDefaultState) {
        // If we're generating the initial graph with no configurations, so none of the nodes should be considered new
        return false;
      }

      // All other nodes are not considered new
      return false;
    }
    return !nodeIdHasConfiguration(id);
  }

  if (entityDiagramVariant === EntityDiagramVariant.Dispositions) {
    applyDeathSections({
      graph,
      grantors,
      isTwoClientHousehold,
      firstGrantorDeathId,
    });
  }

  const TERM_END_DIAGRAM_VARIANTS = new Set([
    EntityDiagramVariant.GratTerm,
    EntityDiagramVariant.QprtTerm,
    EntityDiagramVariant.CrtTerm,
    EntityDiagramVariant.CltTerm,
  ]);

  if (
    'termEndDate' in entity.subtype &&
    TERM_END_DIAGRAM_VARIANTS.has(entityDiagramVariant)
  ) {
    const subtype = entity.subtype;

    applyTermEndSections({
      graph,
      termEndDate: subtype.termEndDate ?? undefined,
    });
  }

  // Add the nodes
  vizNodes.forEach((node) => {
    const nodeId = getNodeId(node);

    const hasNoNode = node.node === null;

    if (hasNoNode) {
      const { afterDeath } = node;

      graph.addNodeSafe(nodeId, {
        data: node,
        node: {
          id: nodeId,
          type: 'tile',
          position: {
            x: 0,
            y: 0,
          },
          data: {
            lineOne: '',
            label: '',
            variant: TileVariant.Pixel,
            sectionLabelId: afterDeath,
          },
        },
        categorizationType: GraphNodeCategorizationType.Individual,
      });

      return;
    }

    const tile = buildTileFromNode({
      nodeFragment: node,
      isNewTile: getIsNewNodeById(nodeId),
    });

    const categorizationType = getCategorizationType(node);
    if (!tile) return;
    if (!categorizationType) return;

    graph.addNodeSafe(nodeId, {
      data: node,
      node: tile,
      categorizationType,
    });
  });

  // Draw the edges between the nodes
  drawEdges({
    graph,
    viz,
  });

  graph.setAttribute('firstPrimaryClientDeathId', firstGrantorDeathId);
  graph.setAttribute('entity', entity);

  return graph;
}
