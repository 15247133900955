import { Grid, Stack, Typography } from '@mui/material';
import { Children, ReactNode } from 'react';
import { useMeasure } from 'react-use';

import { Card } from '@/components/layout/Card/Card';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';

import { PRESENTATION_SPACING_UNITS } from '../../presentation.constants';

export interface SummarySlideLayoutProps {
  LeftContent: ReactNode[];
  RightContent: ReactNode;
  pageIndex: number;
}

// Note, if you update styling here, make sure HEIGHT_CONFIG.DESCRIPTION_AVAILABLE_WIDTH_FULL_PX
// and HEIGHT_CONFIG.DESCRIPTION_AVAILABLE_WIDTH_SHARED_PX in entityPresentationPagination.utils.ts are updated
// accordingly to make sure pagination works correctly
export function SummarySlideLayout({
  LeftContent,
  RightContent,
  pageIndex,
}: SummarySlideLayoutProps) {
  const isDisplayTypographyEnabled = useFeatureFlag(
    'use_serif_font_in_presentation'
  );
  const [summaryTitleRef, { height: summaryTitleHeight }] =
    useMeasure<HTMLDivElement>();

  return (
    <Stack px={PRESENTATION_SPACING_UNITS} py={1} spacing={2} height="100%">
      <Stack
        ref={summaryTitleRef}
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant={isDisplayTypographyEnabled ? 'h2_display' : 'h2'}>
          Entity Summary
        </Typography>
      </Stack>
      <Grid container height={`calc(100% - ${summaryTitleHeight}px)`}>
        {pageIndex === 0 && (
          <Grid item sm={4} sx={{ height: '100%' }}>
            <Card variant="outlined" sx={{ height: '100%', p: 2 }}>
              <Stack spacing={2}>
                {Children.map(LeftContent, (child, idx) => (
                  <Stack key={`left-content-${idx}`}>{child}</Stack>
                ))}
              </Stack>
            </Card>
          </Grid>
        )}
        <Grid
          item
          sm={pageIndex === 0 ? 8 : 12}
          sx={{ height: '100%', pl: pageIndex === 0 ? 3 : 0 }}
        >
          <Stack spacing={2.5} height="100%">
            {Children.map(RightContent, (child, idx) => (
              <Stack key={`right-content-${idx}`} height="100%">
                {child}
              </Stack>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
