import { MiniTableRow } from '@/components/tables/MiniTable/MiniTable';
import { GetWaterfallSummary_EstateWaterfallVizNodeFragment } from '@/modules/estateWaterfall/graphql/GetWaterfallSummary.generated';

import {
  formatCompactValue,
  getNameFromNode,
} from '../EstateWaterfallSummaryTiles.utils';
import { useShowMoreSplit } from './useShowMoreSplit.hook';

export function useMiniTableRows(
  data: ReturnType<
    typeof useShowMoreSplit<GetWaterfallSummary_EstateWaterfallVizNodeFragment>
  >
): MiniTableRow[] {
  return [
    ...data.visible.map(({ node, value }) => ({
      label: getNameFromNode(node),
      value: formatCompactValue(value),
    })),
    ...(data.remaining.length > 0
      ? [
          {
            label: data.remainingLabel,
            value: formatCompactValue(data.remainingSum),
          },
        ]
      : []),
  ];
}
