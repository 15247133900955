import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { TileSummary } from '@/components/diagrams/components/Tile/TileSummary';
import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { sumDecimalJS } from '@/utils/decimalJSUtils';

import { useWaterfallSummaryContext } from '../../contexts/waterfallSummary.context';
import { GetWaterfallSummary_EstateWaterfallFragment } from '../../graphql/GetWaterfallSummary.generated';
import { useShowSummaryTileDetails } from '../../hooks/useShowSummaryTileDetails.hook';
import { WaterfallBarChartSection } from '../EstateWaterfallSummaryBarCharts/hooks/useWaterfallSummaryBarChartSections';
import { TileSection } from './components/TileSection';
import {
  formatCompactValue,
  getSummaryTileNode,
  isGrouped,
} from './EstateWaterfallSummaryTiles.utils';
import { useShowMoreSplit } from './hooks/useShowMoreSplit.hook';

export interface CurrentSummarySectionProps {
  waterfall: GetWaterfallSummary_EstateWaterfallFragment;
  section: WaterfallBarChartSection;
  showDetails?: boolean;
}

export const CurrentSummarySection = ({
  waterfall,
  section,
}: CurrentSummarySectionProps) => {
  const householdId = waterfall.household?.id as unknown as string;
  const showSummaryTileDetails = useShowSummaryTileDetails(waterfall.id);
  const currentSectionValue = sumDecimalJS(
    section.waterfallSections.map((v) => {
      return new Decimal(v.value);
    })
  );

  const firstDeathGrantorName =
    waterfall.household?.possiblePrimaryClients.find(
      (c) => c.id === waterfall.firstGrantorDeath.id
    )?.firstName;

  const { visible, remainingLabel, remaining, remainingSum } = useShowMoreSplit(
    section.waterfallSections
  );

  const {
    summaryPanel: { openModal, data },
  } = useWaterfallSummaryContext();

  const rows = useMemo(() => {
    return [
      ...visible.map(({ kind, value }) => ({
        label: kind,
        value: formatCompactValue(value),
      })),
      ...(remaining.length > 0
        ? [
            {
              label: remainingLabel,
              value: formatCompactValue(remainingSum),
            },
          ]
        : []),
    ];
  }, [visible, remainingLabel, remainingSum, remaining]);

  const details = <MiniTable rows={rows} variant="default" />;

  const title = 'Projected net worth';

  return (
    <TileSection label={section.label}>
      <TileSummary
        lineOne="Projected net worth"
        lineTwo={formatCompactValue(currentSectionValue)}
        onClick={() =>
          openModal({
            type: 'summaryTileDetails',
            nodes: section.waterfallSections.flatMap((section) => {
              return section.nodes.map(getSummaryTileNode);
            }),
            subtitle: `Combined value of all in and out of estate assets as of ${firstDeathGrantorName}'s death in ${waterfall.firstGrantorDeathYear}`,
            title,
            total: currentSectionValue,
            householdId,
            firstPrimaryClientDeathId: waterfall.firstGrantorDeath.id,
            waterfallId: waterfall.id,
          })
        }
        isGrouped={isGrouped(title, data)}
      >
        {showSummaryTileDetails ? details : null}
      </TileSummary>
    </TileSection>
  );
};
