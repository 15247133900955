import { Typography } from '@mui/material';
import { ComponentProps } from 'react';
import { FieldValues } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import {
  FormAwareTextInput,
  FormAwareTextInputProps,
} from '@/components/form/formAwareInputs/FormAwareTextInput';
import { useRemainingCharacters } from '@/components/form/hooks/useRemainingCharacters.hook';
import { Callout } from '@/components/notifications/Callout/Callout';
import { getMaxLengthValidation } from '@/components/utils/validators';
import { TileNotes } from '@/modules/content/tooltipContent/TileNotes';

const Notes = <FormShape extends FieldValues>({
  label = 'Tile notes',
  ...props
}: SetOptional<FormAwareTextInputProps<FormShape>, 'label'>) => {
  const { helpText } = useRemainingCharacters(props.fieldName, 240);

  return (
    <FormAwareTextInput
      label={label}
      contextualHelp={<TileNotes />}
      rows={4}
      multiline
      helpText={helpText}
      validation={{
        maxLength: getMaxLengthValidation(240),
      }}
      {...props}
    />
  );
};

const SettingsDisclaimer = ({
  severity = 'info-low',
  ...props
}: Partial<ComponentProps<typeof Callout>>) => {
  return (
    <Callout severity={severity} {...props}>
      <Typography variant="subtitle2">
        Tile settings will display on the platform as well as in any client
        presentations or material exported from Luminary.
      </Typography>
    </Callout>
  );
};

export const GraphVizNodeConfigFormField = {
  Notes,
  SettingsDisclaimer,
};
