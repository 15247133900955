import { ApolloError, useApolloClient } from '@apollo/client';
import { Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import { RefObject, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { FormProvider, SubmitHandler, useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { invalidateCacheObject } from '@/graphql/client.utils';
import { useModalState } from '@/hooks/useModalState';
import { useReportError } from '@/hooks/useReportError';
import { useEntityDPSuggestionsContext } from '@/modules/aiDP/entity/context/entityDPSuggestions.context';
import { EntityDPSuggestionsProvider } from '@/modules/aiDP/entity/context/EntityDPSuggestions.provider';
import { AISuggestionsMatcherProvider } from '@/modules/aiSuggestions/AISuggestionsMatcher/context/AISuggestionsMatcher.provider';
import { useEntitySuggestionsContext } from '@/modules/aiSuggestions/context/EntitySuggestions.context';
import { useMostRecentEntityAISuggestionsAsyncJobs } from '@/modules/aiSuggestions/hooks/useMostRecentEntityAISuggestionsAsyncJobs';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { AsyncJobKind, KgRootStatus } from '@/types/schema';

import { useDispositiveProvisionsContext } from '../contexts/dispositiveProvisions.context';
import { DispositionSchemeSelectorModal } from '../DispositionSchemeSelector/DispositionSchemeSelectorModal';
import { DispositionScheme } from '../dispositiveProvisions.types';
import { DISPOSITIVE_PROVISIONS_FORM_NAMESPACE } from './DispositiveProvisionsForm.constants';
import {
  DispositiveProvisionsFormPaths,
  DispositiveProvisionsFormShape,
} from './DispositiveProvisionsForm.types';
import {
  getCreateDispositiveProvisionsInput,
  getInitialValues,
  NodesWithDispositiveProvisions,
} from './DispositiveProvisionsForm.utils';
import { DispositiveProvisionsFormChrome } from './DispositiveProvisionsFormChrome';
import { DispositiveProvisionsRecipients } from './DispositiveProvisionsFormRecipients/DispositiveProvisionsRecipients';
import { DispositiveProvisionsRecipientsFromTemplate } from './DispositiveProvisionsFormRecipients/DispositiveProvisionsRecipientsFromTemplate';
import { DispositiveProvisionsTotalLine } from './DispositiveProvisionsTotalLine/DispositiveProvisionsTotalLine';
import {
  CreateDispositiveProvisionsAndAckSuggestionsMutation,
  CreateDispositiveProvisionsForClientProfileMutation,
  CreateDispositiveProvisionsForTestamentaryEntitiesMutation,
  CreateDispositiveProvisionsMutation,
  useCreateDispositiveProvisionsAndAckSuggestionsMutation,
  useCreateDispositiveProvisionsForClientProfileMutation,
  useCreateDispositiveProvisionsForTestamentaryEntitiesMutation,
  useCreateDispositiveProvisionsMutation,
} from './graphql/CreateDispositiveProvisions.generated';
import { Operations } from './graphql/GetDispositiveProvisions.generated';
import { useSimulateDispositions } from './hooks/useSimulateDispositions';
import {
  useDispositionScheme,
  useOrderedDyingClients,
} from './hooks/utilityHooks';

export interface DispositiveProvisionsFormProps {
  footerRef: RefObject<HTMLDivElement>;
  onClose: () => void;
  onAfterSubmit: () => void;
  firstDeathClientId: string;
  topRightContent?: React.ReactNode;
}

function DispositiveProvisionsFormInner({
  onAfterSubmit,
  onClose,
  footerRef,
  topRightContent,
}: DispositiveProvisionsFormProps) {
  const aiDpEnabled = useFeatureFlag('ai_dispositive_provisions');

  const client = useApolloClient();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [
    { isModalOpen: isDispositionSchemaModalOpen },
    { closeModal, openModal },
  ] = useModalState<boolean>(false);
  const { entitySubtypeId, entityType } = useEntityDetailsContext();
  const [firstDyingClient, secondDyingClient] = useOrderedDyingClients();
  const {
    householdId,
    clientProfileOrEntityOrTestamentaryEntityId,
    isTestamentaryEntity,
    isClientProfile,
    isTwoClientHousehold,
    isOnHypotheticalWaterfall,
    waterfall,
    totalMarketValue,
  } = useDispositiveProvisionsContext();

  const { acknowledgedSuggestions, refetchSuggestions } =
    useEntitySuggestionsContext();
  const { kgRoot } = useEntityDPSuggestionsContext();
  const { refetch: refetchPendingAiSuggestionJobs } =
    useMostRecentEntityAISuggestionsAsyncJobs(AsyncJobKind.AiDp);

  const {
    reset,
    handleSubmit,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { defaultValues, isSubmitting },
  } = useFormContext<DispositiveProvisionsFormShape>();

  const mutationOptions = useMemo(() => {
    return {
      onError: (error: ApolloError) => {
        showFeedback(
          "We weren't able to save your dispositive provisions. Please refresh the page and try again."
        );
        reportError('could not create dispositive provisions', error);
      },
      onCompleted: (
        _data:
          | CreateDispositiveProvisionsMutation
          | CreateDispositiveProvisionsAndAckSuggestionsMutation
          | CreateDispositiveProvisionsForTestamentaryEntitiesMutation
          | CreateDispositiveProvisionsForClientProfileMutation
      ) => {
        showFeedback('Dispositive provisions created successfully', {
          variant: 'success',
        });
      },
      refetchQueries: [Operations.Query.GetDispositiveProvisions],
    };
  }, [reportError, showFeedback]);

  // This mutation creates dispositive provisions for entities.
  const [setDispositiveProvisions] = useCreateDispositiveProvisionsMutation({
    ...mutationOptions,
    onCompleted: async (data) => {
      mutationOptions.onCompleted(data);
      await invalidateCacheObject(data.updateEntity, client);
    },
  });
  // This mutation creates dispositive provisions for entities, AND acknowledges suggestions.
  const [setDispositiveProvisionsAndSuggestions] =
    useCreateDispositiveProvisionsAndAckSuggestionsMutation({
      ...mutationOptions,
      onCompleted: async (data) => {
        mutationOptions.onCompleted(data);
        await invalidateCacheObject(data.updateEntity, client);
        // Clear out the banner/notification
        void refetchPendingAiSuggestionJobs();
        void refetchSuggestions({ entityID: data.updateEntity.id });
      },
    });

  const [setDispositiveProvisionsForTestamentaryEntities] =
    useCreateDispositiveProvisionsForTestamentaryEntitiesMutation({
      ...mutationOptions,
      onCompleted: async (data) => {
        mutationOptions.onCompleted(data);
        await invalidateCacheObject(data.updateTestamentaryEntity, client);
      },
    });
  const [setDispositiveProvisionsForClientProfile] =
    useCreateDispositiveProvisionsForClientProfileMutation({
      ...mutationOptions,
      onCompleted: async (data) => {
        const clientProfileId = clientProfileOrEntityOrTestamentaryEntityId; // Rename for clarity
        mutationOptions.onCompleted(data);
        // Invalidate the ClientProfile cache object as the response does not contain the updated
        // client profile object that we want to update.
        await invalidateCacheObject(
          {
            __typename: 'ClientProfile',
            id: clientProfileId,
          },
          client
        );
      },
    });

  const onValidSubmission: SubmitHandler<DispositiveProvisionsFormShape> = (
    values
  ) => {
    if (!clientProfileOrEntityOrTestamentaryEntityId) {
      throw new Error(
        'Cannot create dispositive provisions without the necessary client profile id, entity id, or testamentary entity id'
      );
    }

    if (isTestamentaryEntity) {
      const { type, input } = getCreateDispositiveProvisionsInput(
        values,
        defaultValues as DispositiveProvisionsFormShape,
        {
          testamentaryEntityId: clientProfileOrEntityOrTestamentaryEntityId,
          isOnHypotheticalWaterfall,
          waterfallId: waterfall?.id,
        }
      );

      if (type !== NodesWithDispositiveProvisions.TestamentaryEntity) {
        throw new Error('expected input type for testamentary entity');
      }

      return setDispositiveProvisionsForTestamentaryEntities({
        variables: {
          input,
        },
      });
    } else if (isClientProfile) {
      const { type, input } = getCreateDispositiveProvisionsInput(
        values,
        defaultValues as DispositiveProvisionsFormShape,
        {
          householdId: householdId,
          clientProfileId: clientProfileOrEntityOrTestamentaryEntityId,
          isOnHypotheticalWaterfall,
          waterfallId: waterfall?.id,
        }
      );

      if (type !== NodesWithDispositiveProvisions.ClientProfile) {
        throw new Error('expected input type for client profile');
      } else {
        return setDispositiveProvisionsForClientProfile({
          variables: {
            input,
          },
        });
      }
    } else {
      if (!entitySubtypeId || !entityType) {
        throw new Error(
          'Cannot create dispositive provisions without entitySubtypeId and entityType'
        );
      }

      const { type, input } = getCreateDispositiveProvisionsInput(
        values,
        defaultValues as DispositiveProvisionsFormShape,
        {
          entityId: clientProfileOrEntityOrTestamentaryEntityId,
          entitySubtypeId,
          entityType,
          isOnHypotheticalWaterfall,
          waterfallId: waterfall?.id,
        }
      );

      if (type !== NodesWithDispositiveProvisions.Entity) {
        throw new Error('expected input type for entity');
      }

      if (aiDpEnabled && !isEmpty(acknowledgedSuggestions) && kgRoot?.id) {
        // We should only have the kgRoot if we're accessing the DP form within
        // an entity suggestions context. NOT from the waterfall.
        return setDispositiveProvisionsAndSuggestions({
          variables: {
            entityInput: input,
            acknowledgeSuggestionsInput: Object.values(
              acknowledgedSuggestions
            ).map((s) => ({
              suggestionID: s.suggestionID,
              status: s.status,
              clientProfileID: s.clientProfileId,
              clientOrganizationID: s.clientOrganizationId,
              entityID: s.entityId,
              testamentaryEntityID: s.testamentaryEntityId,
            })),
            updateKgRootInput: {
              id: kgRoot.id,
              update: {
                status: KgRootStatus.SuggestionReviewComplete,
              },
            },
          },
        });
      }

      return setDispositiveProvisions({
        variables: {
          input,
        },
      });
    }
  };

  const onSubmit = handleSubmit(onValidSubmission, (errors) => {
    if (errors.dispositiveProvisions?._hasBeenReviewed) {
      showFeedback('Select when distributions occur in order to save.');
    }
    return;
  });

  useSubmitSuccessHandler(() => {
    reset();
    onAfterSubmit();
  });

  function handleDispositionSchemeSelection(
    selection: DispositionScheme | null
  ) {
    // null selection means the user hit cancel, so we don't want to update the value
    if (selection) {
      setValue('dispositiveProvisions.dispositionScheme', selection);
    }

    closeModal();
  }

  const dispositionScheme = useDispositionScheme();

  function handleOpenSchemeSelectionModal() {
    // for a single-client household, we can just switch the disposition scheme directly because we don't have to
    if (!isTwoClientHousehold) {
      throw new Error(
        'Invalid state: Single-client households can only be UPON_FIRST_DEATH'
      );
    }

    openModal();
  }

  const [recipients, hasBeenReviewed, templateId] = useWatch({
    control,
    name: [
      `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients` as const satisfies DispositiveProvisionsFormPaths,
      `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}._hasBeenReviewed` as const satisfies DispositiveProvisionsFormPaths,
      `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.templateId` as const satisfies DispositiveProvisionsFormPaths,
    ],
  });

  // If the disposition scheme is NONE and it hasn't been reviewed,
  // then the user hasn't made a selection yet; in this case,
  // add an error to block them from saving
  useEffect(() => {
    if (dispositionScheme === DispositionScheme.NONE && !hasBeenReviewed) {
      setError(`${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}._hasBeenReviewed`, {
        type: 'custom',
        // note that this message isn't user-facing
        message: 'A disposition scheme must be selected to continue',
      });
    } else {
      clearErrors(`${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}._hasBeenReviewed`);
    }
  }, [clearErrors, dispositionScheme, hasBeenReviewed, setError]);

  const { lastSimulationResults, simulationResults } = useSimulateDispositions({
    firstGrantorId: firstDyingClient.id,
    secondGrantorId: secondDyingClient?.id,
    recipients,
    entityTotalMarketValue: totalMarketValue,
    selectedDispositionScheme: dispositionScheme,
  });

  const currentSimulationResults = useMemo(() => {
    if (simulationResults) {
      return simulationResults;
    }

    return lastSimulationResults.current ?? null;
  }, [lastSimulationResults, simulationResults]);

  return (
    <>
      <DispositionSchemeSelectorModal
        isOpen={isDispositionSchemaModalOpen}
        onClose={handleDispositionSchemeSelection}
      />
      <Stack
        data-testid="DispositiveProvisionsForm"
        component="form"
        height="100%"
        onSubmit={onSubmit}
        noValidate
      >
        <DispositiveProvisionsFormChrome
          topRightContent={topRightContent}
          footer={
            (dispositionScheme !== DispositionScheme.NONE ||
              hasBeenReviewed) && (
              <DispositiveProvisionsTotalLine
                lastSimulationResults={lastSimulationResults}
                simulationResults={currentSimulationResults ?? undefined}
                entityTotalMarketValue={totalMarketValue}
              />
            )
          }
        >
          {templateId ? (
            <DispositiveProvisionsRecipientsFromTemplate
              simulationResults={currentSimulationResults ?? undefined}
              templateId={templateId}
              onClose={onClose}
            />
          ) : (
            <DispositiveProvisionsRecipients
              simulationResults={currentSimulationResults ?? undefined}
              onOpenSchemeSelectionModal={handleOpenSchemeSelectionModal}
            />
          )}
        </DispositiveProvisionsFormChrome>
        <>
          {footerRef.current &&
            createPortal(
              <Button
                variant="secondary"
                size="sm"
                onClick={() => onClose()}
                disabled={isSubmitting}
              >
                Cancel
              </Button>,
              footerRef.current
            )}
          {footerRef.current &&
            createPortal(
              <Button
                variant="primary"
                size="sm"
                type="submit"
                onClick={onSubmit}
                loading={isSubmitting}
              >
                Save changes
              </Button>,
              footerRef.current
            )}
        </>
      </Stack>
    </>
  );
}

/**
 * @description This component is intended to replace the `DispositiveProvisionsForm_LEGACY` component.
 * It's not complete yet.
 */
export function DispositiveProvisionsForm(
  props: DispositiveProvisionsFormProps
) {
  const {
    primaryClients,
    dispositionScenarios,
    isClientProfile,
    clientProfileOrEntityOrTestamentaryEntityId,
    isTestamentaryEntity,
  } = useDispositiveProvisionsContext();

  const primaryClientIds = useMemo(() => {
    return primaryClients.map((c) => c.id);
  }, [primaryClients]);

  const formMethods = useForm<DispositiveProvisionsFormShape>({
    defaultValues: getInitialValues({
      primaryClientIds,
      dispositionScenarios,
      isClientProfile,
      clientProfileOrEntityOrTestamentaryEntityId,
      firstDeathPrimaryClientId: props.firstDeathClientId,
      isTestamentaryEntity,
    }),
  });

  return (
    <FormProvider {...formMethods}>
      <AISuggestionsMatcherProvider>
        <EntityDPSuggestionsProvider>
          <DispositiveProvisionsFormInner {...props} />
        </EntityDPSuggestionsProvider>
      </AISuggestionsMatcherProvider>
    </FormProvider>
  );
}
