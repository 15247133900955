import { FetchResult } from '@apollo/client';
import { css } from '@emotion/css';
import { Stack, useTheme } from '@mui/material';
import { FormProvider, Path } from 'react-hook-form';

import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';

interface UserFeedbackModalProps<T> {
  isFeedbackModalOpen: boolean;
  closeFeedbackModal: () => void;
  provideFeedback: (text: string) => Promise<FetchResult<T>>;

  /** header of the modal. default: Provide feedback */
  headingText?: string;
  /** placeholder text for the feedback input */
  placeholderText: string;
  /** help text placed below the feedback input */
  helpText: string;
}

interface UserFeedbackForm {
  textFeedback: string;
}

const modalStyles = css({
  height: 'auto',
});

function UserFeedbackModalInner<T>({
  isFeedbackModalOpen,
  closeFeedbackModal,
  provideFeedback,
  headingText = 'Provide feedback',
  placeholderText,
  helpText,
}: UserFeedbackModalProps<T>) {
  const theme = useTheme();
  const { formRef, handleSave } = useFormSaveHandler();
  const { control, formState, shouldBlockNavigation, handleSubmit, reset } =
    useFormContext<UserFeedbackForm>();

  useSubmitSuccessHandler(() => {
    closeFeedbackModal();
    setTimeout(() => {
      reset();
    }, theme.transitions.duration.leavingScreen);
  });

  const onSubmit = handleSubmit((formData) => {
    if (!formData.textFeedback) {
      return Promise.resolve();
    }

    return provideFeedback(formData.textFeedback);
  });

  return (
    <FormModal
      heading={headingText}
      rightHeaderContent={null}
      isOpen={isFeedbackModalOpen}
      onClose={closeFeedbackModal}
      shouldBlockClose={shouldBlockNavigation}
      classes={{
        paper: modalStyles, // Let the modal shrink its height to the content
      }}
      actions={
        <FormModalActions.Provider formState={formState}>
          <FormModalActions.CancelButton
            variant="transparent"
            onClick={closeFeedbackModal}
          />
          <FormModalActions.SaveButton onClick={handleSave}>
            Submit
          </FormModalActions.SaveButton>
        </FormModalActions.Provider>
      }
    >
      <Stack spacing={2}>
        <form ref={formRef} onSubmit={onSubmit} noValidate>
          <FormAwareTextInput<UserFeedbackForm>
            label="Feedback"
            hideLabel
            placeholder={placeholderText}
            fieldName={'textFeedback' as const satisfies Path<UserFeedbackForm>}
            helpText={helpText}
            control={control}
            multiline
            rows={3}
          />
        </form>
      </Stack>
    </FormModal>
  );
}

export function UserFeedbackModal<T>(props: UserFeedbackModalProps<T>) {
  const formMethods = useForm<UserFeedbackForm>({
    defaultValues: {
      textFeedback: '', // Form is always initialized with empty text feedback
    },
  });
  return (
    <FormProvider {...formMethods}>
      <UserFeedbackModalInner {...props} />
    </FormProvider>
  );
}
