import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { GroupedHeaderList } from '@/components/lists/GroupedHeaderList/GroupedHeaderList';
import { TableCell, TableCellContext } from '@/components/lists/TableCell';
import { EmptyListItem } from '@/components/typography/EmptyListItem';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrencyNoDecimalsAccounting } from '@/utils/formatting/currency';

import { SaleLoanWithProjections } from './hooks/useGetHypotheticalTransfers';
import {
  createSaleLoanTableFundingItem,
  createSaleLoanTableRepaymentItem,
} from './SaleLoanItemsTable.utils';
import { TransferTab } from './TransferItems';

interface SaleLoanItemsTableProps {
  saleLoansWithProjections: {
    sourceLoans: SaleLoanWithProjections[];
    recipientLoans: SaleLoanWithProjections[];
  };
  direction: TransferTab;
  onEditSaleLoan: (saleLoanId: string) => void;
}

export function SaleLoanItemsTable({
  saleLoansWithProjections,
  direction,
  onEditSaleLoan,
}: SaleLoanItemsTableProps) {
  // each loan should be represented as one outbound item and one inbound item.
  // the outbound item represents the initial sale or loan, and the inbound item
  // represents the repayment(s) of the loan.
  const itemsAndValues = useMemo(() => {
    if (direction === TransferTab.Outbound) {
      const sourceItemsAndValues = saleLoansWithProjections.sourceLoans.map(
        ({ saleLoan, loanProjections }) =>
          createSaleLoanTableFundingItem(
            saleLoan,
            loanProjections,
            {
              heading: 'recipient',
              direction: direction,
            },
            onEditSaleLoan
          )
      );
      const recipientItemsAndValues =
        saleLoansWithProjections.recipientLoans.map(
          ({ saleLoan, loanProjections }) =>
            createSaleLoanTableRepaymentItem(
              saleLoan,
              loanProjections,
              {
                heading: 'source',
                direction: direction,
              },
              onEditSaleLoan
            )
        );
      return [...sourceItemsAndValues, ...recipientItemsAndValues];
    } else {
      const recipientItemsAndValues =
        saleLoansWithProjections.recipientLoans.map(
          ({ saleLoan, loanProjections }) =>
            createSaleLoanTableFundingItem(
              saleLoan,
              loanProjections,
              {
                heading: 'source',
                direction: direction,
              },
              onEditSaleLoan
            )
        );
      const sourceItemsAndValues = saleLoansWithProjections.sourceLoans.map(
        ({ saleLoan, loanProjections }) =>
          createSaleLoanTableRepaymentItem(
            saleLoan,
            loanProjections,
            {
              heading: 'recipient',
              direction: direction,
            },
            onEditSaleLoan
          )
      );
      return [...recipientItemsAndValues, ...sourceItemsAndValues];
    }
  }, [direction, onEditSaleLoan, saleLoansWithProjections]);

  const netAmount = useMemo(() => {
    return itemsAndValues.reduce((acc, { value }) => {
      return acc.plus(value);
    }, new Decimal(0));
  }, [itemsAndValues]);

  const isOutbound = direction === TransferTab.Outbound;
  const items = itemsAndValues.map(({ item }) => item);
  return (
    <Stack>
      <Stack p={1.5} direction="row" bgcolor={COLORS.GRAY[100]}>
        <Box>
          <Typography variant="h6" component="p" color={COLORS.GRAY[500]}>
            Sales and loans
          </Typography>
        </Box>
      </Stack>
      <Stack
        direction="column"
        sx={{
          borderBottom: `1px solid ${COLORS.GRAY[200]}`,
          '&:last-child': {
            borderBottom: 'none',
          },
        }}
      >
        {itemsAndValues.length === 0 && <EmptyListItem>No items</EmptyListItem>}
        <GroupedHeaderList
          rowGroups={[{ items }]}
          withDividers
          ItemComponent={({ item }) => (
            <TableCell
              RightContent={
                item.context && <TableCellContext {...item.context} />
              }
              {...item}
            />
          )}
        />
        <Stack
          sx={{
            borderTop: `2px solid ${COLORS.NAVY[600]}`,
            px: 1.5,
            py: 2.5,
          }}
          direction="row"
          justifyContent="space-between"
        >
          <Typography variant="h5" component="span">
            Transferred {isOutbound ? 'out' : 'in'}
          </Typography>
          <Typography
            variant="h5"
            component="span"
            sx={{
              color: isOutbound ? COLORS.MATH.NEGATIVE : undefined,
            }}
          >
            {formatCurrencyNoDecimalsAccounting(
              netAmount.times(isOutbound ? -1 : 1)
            )}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
