import { Box, Stack, SxProps, Typography } from '@mui/material';
import {
  TreeItem2,
  TreeItem2Props,
  TreeItem2SlotProps,
  TreeItem2Slots,
  UseTreeItem2Status,
  useTreeItem2Utils,
} from '@mui/x-tree-view-pro';
import { isEmpty } from 'lodash';
import React, { CSSProperties, ReactNode, useState } from 'react';

import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { ChevronDownIcon } from '@/components/icons/ChevronDownIcon';
import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { DotsMoveIcon } from '@/components/icons/DotsMoveIcon';
import { File06Icon } from '@/components/icons/File06Icon';
import { COLORS } from '@/styles/tokens/colors';
import { PresentationBundleKind } from '@/types/schema';

import { useClientPresentationDesignerV2Context } from '../../ClientPresentationDesignerV2.context';
import { useClientPresentationV2ModalContext } from '../../ClientPresentationV2ModalContext';
import { CONTEXT_MENU_MAP } from './ClientPresentationDesignerV2TreeView.contextMenuMap';
import { PresentationTreeViewItem } from './ClientPresentationDesignerV2TreeView.types';

export const TREE_ITEM_HEIGHT: CSSProperties['height'] = '40px';

// necessary to prevent the label from overflowing and pushing the context menu button into
// the overflow
const MAX_NAME_LABEL_WIDTH: CSSProperties['maxWidth'] = '209px';

function CustomLabel({ label, bundle, type }: PresentationTreeViewItem) {
  let growthProfileNameDisplay: ReactNode | null = null;
  const { growthProfileMap, estateWaterfallBundleConfigurationMap } =
    useClientPresentationDesignerV2Context();

  const estateWaterfall =
    estateWaterfallBundleConfigurationMap[bundle?.id || ''];

  const growthProfile =
    growthProfileMap[
      bundle?.waterfallOverviewBundleConfiguration?.growthProfileId ||
        estateWaterfall?.growthProfile?.id ||
        ''
    ];

  if (
    type === PresentationBundleKind.WaterfallOverviewBundle &&
    growthProfile?.displayName
  ) {
    growthProfileNameDisplay = (
      <Typography
        sx={{
          fontSize: '10px',
          lineHeight: '12px',
          color: COLORS.NAVY[500],
        }}
      >
        {growthProfile.displayName}
      </Typography>
    );
  } else {
    growthProfileNameDisplay = null;
  }

  const { modifyBundle, deleteBundle, duplicateBundle } =
    useClientPresentationV2ModalContext();

  let endContent: ReactNode | null = <Box />;
  const ContextMenuContents = CONTEXT_MENU_MAP[type];
  if (ContextMenuContents) {
    endContent = (
      <ContextMenuButton variant="transparent" size="xs">
        <ContextMenuContents
          bundleId={bundle?.id}
          onManageBundle={() => bundle && modifyBundle(bundle.type, bundle.id)}
          onDeleteBundle={() => bundle && deleteBundle(bundle.id)}
          onDuplicateBundle={() =>
            bundle && duplicateBundle(bundle.type, bundle.id)
          }
          kind={type}
        />
      </ContextMenuButton>
    );
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      width="100%"
      justifyContent="space-between"
      height={TREE_ITEM_HEIGHT}
    >
      <Stack sx={{ flex: '2 2 auto', maxWidth: MAX_NAME_LABEL_WIDTH }}>
        <Typography
          variant="subtitle2"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: COLORS.NAVY[900],
          }}
        >
          {label}
        </Typography>
        {growthProfileNameDisplay}
      </Stack>
      {endContent}
    </Stack>
  );
}

function CustomIconContainer({
  item,
  status,
  isHovering,
}: {
  item: PresentationTreeViewItem;
  status: UseTreeItem2Status;
  isHovering: boolean;
}) {
  const folderSx: SxProps = {
    color: COLORS.NEUTRAL_GRAY[500],
    '&:hover': {
      background: COLORS.NEUTRAL_GRAY[100],
    },
  };

  let icon: React.ElementType;
  let label: string;
  if (!item.isBundle || isEmpty(item.children)) {
    icon = File06Icon;
    label = 'Slide';
  } else if (status.expanded) {
    icon = ChevronDownIcon;
    label = 'Collapse';
  } else {
    icon = ChevronRightIcon;
    label = 'Expand';
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      height={TREE_ITEM_HEIGHT}
      sx={{
        flex: '1 1 auto',
      }}
    >
      {isHovering ? (
        <IconButton
          icon={DotsMoveIcon}
          sx={{
            ...folderSx,
            cursor: 'ns-resize',
          }}
          size="xs"
          variant="transparent"
          ariaLabel="Move"
        />
      ) : (
        <Box sx={{ width: 30 }} />
      )}
      <IconButton
        icon={icon}
        sx={folderSx}
        size="xs"
        variant="transparent"
        ariaLabel={label}
      />
    </Stack>
  );
}

export const ClientPresentationDesignerV2TreeViewItem = React.forwardRef(
  function ClientPresentationDesignerV2TreeViewItem(
    props: TreeItem2Props,
    ref: React.Ref<HTMLLIElement>
  ) {
    const [isHovering, setHovering] = useState(false);
    const { itemId, children } = props;

    const { publicAPI, status } = useTreeItem2Utils({
      itemId,
      children,
    });

    const item = publicAPI.getItem(itemId) as PresentationTreeViewItem;

    const slots: TreeItem2Slots = {
      label: CustomLabel,
      iconContainer: CustomIconContainer,
    };

    const slotProps: TreeItem2SlotProps = {
      label: item as TreeItem2SlotProps['label'],
      iconContainer: {
        item,
        status,
        isHovering,
      } as TreeItem2SlotProps['iconContainer'],
      content: {
        onMouseEnter: () => setHovering(true),
        onMouseLeave: () => setHovering(false),
        width: '100%',
        height: TREE_ITEM_HEIGHT,
        'data-testid': 'tree-item-content',
        sx: {
          borderRadius: 0,
          height: 40,
          backgroundColor: status.selected ? COLORS.NAVY[50] : 'transparent',
          '&:hover': {
            backgroundColor: COLORS.NAVY[50],
          },
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          pl: 1,
          pr: 0.5,
        },
      } as TreeItem2SlotProps['content'],
      groupTransition: {
        sx: {
          pl: '30px',
        },
      } as TreeItem2SlotProps['groupTransition'],
    };

    return (
      <TreeItem2 {...props} ref={ref} slots={slots} slotProps={slotProps} />
    );
  }
);
