import { UnreachableError } from '@/utils/errors';

import {
  getCltTermVizFromEntity,
  GetCltTermVizFromEntityInput,
} from './getCltTermVizFromEntity';
import {
  getCrtTermVizFromEntity,
  GetCrtTermVizFromEntityInput,
} from './getCrtTermVizFromEntity';
import {
  getDispositionsVizFromEntity,
  GetDispositionsVizFromEntityInput,
} from './getDispositionsVizFromEntity';
import {
  getGratTermVizFromEntity,
  GetGratTermVizFromEntityInput,
} from './getGratTermVizFromEntity';
import {
  getOwnershipVizFromEntity,
  GetOwnershipVizFromEntityInput,
} from './getOwnershipVizFromEntity';
import {
  getQprtTermVizFromEntity,
  GetQprtTermVizFromEntityInput,
} from './getQprtTermVizFromEntity';
import {
  EntityDiagram_EdgeFragment,
  EntityDiagram_NodeFragment,
  EntityDiagramVariant,
} from './types';

export interface GetVizFromEntityInput {
  entity:
    | GetDispositionsVizFromEntityInput['entity']
    | GetOwnershipVizFromEntityInput['entity']
    | GetGratTermVizFromEntityInput['entity']
    | GetQprtTermVizFromEntityInput['entity']
    | GetCrtTermVizFromEntityInput['entity']
    | GetCltTermVizFromEntityInput['entity'];
  firstGrantorDeathId: string;
  isTwoClientHousehold: boolean;
  survivingSpouse?: {
    id: string;
    displayName: string;
  };
  entityDiagramVariant: EntityDiagramVariant;
}

export function getVizFromEntity({
  entity,
  firstGrantorDeathId,
  isTwoClientHousehold,
  survivingSpouse,
  entityDiagramVariant,
}: GetVizFromEntityInput): {
  nodes: EntityDiagram_NodeFragment[];
  edges: EntityDiagram_EdgeFragment[];
} {
  switch (entityDiagramVariant) {
    case EntityDiagramVariant.Dispositions:
      return getDispositionsVizFromEntity({
        entity: entity as GetDispositionsVizFromEntityInput['entity'],
        firstGrantorDeathId,
        isTwoClientHousehold,
        survivingSpouse,
      });
    case EntityDiagramVariant.Ownership:
      return getOwnershipVizFromEntity({
        entity: entity as GetOwnershipVizFromEntityInput['entity'],
      });
    case EntityDiagramVariant.GratTerm:
      return getGratTermVizFromEntity({
        entity: entity as GetGratTermVizFromEntityInput['entity'],
        firstGrantorDeathId,
        survivingSpouse,
      });
    case EntityDiagramVariant.QprtTerm:
      return getQprtTermVizFromEntity({
        entity: entity as GetQprtTermVizFromEntityInput['entity'],
        firstGrantorDeathId,
        survivingSpouse,
      });
    case EntityDiagramVariant.CrtTerm:
      return getCrtTermVizFromEntity({
        entity: entity as GetCrtTermVizFromEntityInput['entity'],
        firstGrantorDeathId,
        survivingSpouse,
      });
    case EntityDiagramVariant.CltTerm:
      return getCltTermVizFromEntity({
        entity: entity as GetCltTermVizFromEntityInput['entity'],
        firstGrantorDeathId,
        survivingSpouse,
      });
    default:
      throw new UnreachableError({
        case: entityDiagramVariant,
        message: `Unknown entity diagram variant ${entityDiagramVariant}`,
      });
  }
}
