// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RateSummaryMutationVariables = Types.Exact<{
  updateDocumentSummaryId: Types.Scalars['ID']['input'];
  input: Types.UpdateDocumentSummaryInput;
}>;


export type RateSummaryMutation = { __typename?: 'Mutation', updateDocumentSummary: { __typename?: 'DocumentSummary', id: string, aiSummaryRating?: Types.DocumentSummaryAiSummaryRating | null, aiSummaryFeedback?: string | null, document: { __typename?: 'Document', id: string } } };


export const RateSummaryDocument = gql`
    mutation RateSummary($updateDocumentSummaryId: ID!, $input: UpdateDocumentSummaryInput!) {
  updateDocumentSummary(id: $updateDocumentSummaryId, input: $input) {
    id
    aiSummaryRating
    aiSummaryFeedback
    document {
      id
    }
  }
}
    `;
export type RateSummaryMutationFn = Apollo.MutationFunction<RateSummaryMutation, RateSummaryMutationVariables>;

/**
 * __useRateSummaryMutation__
 *
 * To run a mutation, you first call `useRateSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateSummaryMutation, { data, loading, error }] = useRateSummaryMutation({
 *   variables: {
 *      updateDocumentSummaryId: // value for 'updateDocumentSummaryId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRateSummaryMutation(baseOptions?: Apollo.MutationHookOptions<RateSummaryMutation, RateSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RateSummaryMutation, RateSummaryMutationVariables>(RateSummaryDocument, options);
      }
export type RateSummaryMutationHookResult = ReturnType<typeof useRateSummaryMutation>;
export type RateSummaryMutationResult = Apollo.MutationResult<RateSummaryMutation>;
export type RateSummaryMutationOptions = Apollo.BaseMutationOptions<RateSummaryMutation, RateSummaryMutationVariables>;
export const Operations = {
  Mutation: {
    RateSummary: 'RateSummary' as const
  }
}