import { Box } from '@mui/material';

import { BalanceSheetTable } from '@/modules/balanceSheet/BalanceSheetTable/BalanceSheetTable';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { PresentationSlideSecondaryHeading } from '@/modules/presentation/components/PresentationSlideSecondaryHeading';
import { PRESENTATION_SPACING_UNITS } from '@/modules/presentation/presentation.constants';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { PresentationBundleKind } from '@/types/schema';

import {
  useClientPresentationDesignerV2Context,
  useRegisterSlide,
} from '../../../ClientPresentationDesignerV2.context';
import { ClientPresentationV2Footer } from '../../ClientPresentationV2Footer';
import { BaseBundleSlideProps } from '../BundleSlide.types';

export function BalanceSheetSlide({
  bundle,
  SlideWrapper = Box,
  isVisible,
}: BaseBundleSlideProps) {
  const slideTitle = 'Balance sheet';
  const { displayName } = useHouseholdDetailsContext();
  const { balanceSheet } = useClientPresentationDesignerV2Context();

  useRegisterSlide({
    slideId: bundle.id,
    title: slideTitle,
    includeInToC: true,
    bundleKind: PresentationBundleKind.BalanceSheetBundle,
  });

  if (!isVisible) {
    return null;
  }

  return (
    <SlideWrapper>
      <PresentationSlide.Slide
        id={bundle.id}
        leftHeaderContent={
          <PresentationSlide.MainHeading heading={slideTitle} />
        }
        rightHeaderContent={
          <PresentationSlideSecondaryHeading clientDisplayName={displayName} />
        }
        footer={<ClientPresentationV2Footer slideId={bundle.id} />}
      >
        <Box px={PRESENTATION_SPACING_UNITS}>
          <BalanceSheetTable balanceSheet={balanceSheet} loading={false} />
        </Box>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}
