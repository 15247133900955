import { AfterDeath, EntityInEstateStatus } from '@/types/schema';

import {
  makeNodeFromEntity,
  makeNodeFromIndividual,
} from './getDispositionsVizFromEntity';
import { OwnershipDiagram_EntityFragment } from './graphql/OwnershipDiagram.generated';
import {
  EntityDiagram_EdgeFragment,
  EntityDiagramEdgeKind,
  EntityDiagramTileKind,
} from './types';

export interface GetOwnershipVizFromEntityInput {
  entity: OwnershipDiagram_EntityFragment;
}

export function getOwnershipVizFromEntity({
  entity,
}: GetOwnershipVizFromEntityInput) {
  let estateStatus = EntityInEstateStatus.InEstate;

  const rootNode = makeNodeFromEntity({
    entity,
    afterDeath: AfterDeath.None,
    entityDiagramTileKind: EntityDiagramTileKind.Entity,
  });

  if ('inEstateStatus' in entity.subtype) {
    estateStatus =
      entity.subtype.inEstateStatus ?? EntityInEstateStatus.InEstate;
  }

  const ownershipNodes =
    entity.ownedByStakes?.flatMap((ownership) => {
      const { ownershipPercentage } = ownership;

      if (ownership.owningEntity) {
        return makeNodeFromEntity({
          entity: ownership.owningEntity,
          afterDeath: AfterDeath.First,
          ownershipPercentage: ownershipPercentage ?? undefined,
          entityDiagramTileKind: EntityDiagramTileKind.OwnershipBeneficiary,
        });
      }

      if (ownership.owningIndividual) {
        return makeNodeFromIndividual({
          individual: ownership.owningIndividual,
          afterDeath: AfterDeath.First,
          // Same estate status as the business entity
          estateStatus,
          ownershipPercentage: ownershipPercentage ?? undefined,
          entityDiagramTileKind: EntityDiagramTileKind.OwnershipBeneficiary,
        });
      }

      return [];
    }) ?? [];

  // Draw edges from root to ownership nodes
  const edges: EntityDiagram_EdgeFragment[] = ownershipNodes.map((node) => ({
    from: rootNode,
    to: node,
    kind: EntityDiagramEdgeKind.Ownership,
  }));

  return {
    nodes: [rootNode, ...ownershipNodes],
    edges,
  };
}
