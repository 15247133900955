import { Box, Stack, Typography } from '@mui/material';
import { getYear } from 'date-fns';
import Decimal from 'decimal.js';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { Button } from '@/components/form/baseInputs/Button';
import { Dataflow04Icon } from '@/components/icons/Dataflow04Icon';
import { ActionCard } from '@/components/layout/ActionCard/ActionCard';
import { Card } from '@/components/layout/Card/Card';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useModalState } from '@/hooks/useModalState';
import { CreateEstateWaterfallTrowser } from '@/modules/estateWaterfall/components/EstateWaterfallModal/CreateEstateWaterfallTrowser';
import { EstateWaterfallViewsButton } from '@/modules/estateWaterfall/components/EstateWaterfallsDropdown';
import { isHypotheticalWaterfall } from '@/modules/estateWaterfall/EstateWaterfall.utils';
import { useMostRecentlyViewedWaterfall } from '@/modules/user/hooks/useMostRecentlyViewedWaterfall';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';
import { FONT_WEIGHTS } from '@/styles/tokens/fonts';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { formatCurrency } from '@/utils/formatting/currency';

import { useHouseholdDetailsContext } from '../contexts/householdDetails.context';
import { useHouseholdTaxForWaterfallQuery } from './hooks/useHouseholdTaxForWaterfallQuery';

const currencyFormatOptions = {
  notation: 'compact' as const,
  currencySign: 'accounting',
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
};

interface HouseholdTaxForWaterfallProps {
  householdId: string;
}

export function HouseholdTaxForWaterfall({
  householdId,
}: HouseholdTaxForWaterfallProps) {
  const { primaryClients, isTwoClientHousehold } = useHouseholdDetailsContext();

  const [
    { isModalOpen: isCreateWaterfallOpen },
    { setModalIsOpen: setIsCreateWaterfallOpen },
  ] = useModalState();

  const { mostRecentlyViewedWaterfallId } =
    useMostRecentlyViewedWaterfall(householdId);

  const { waterfalls, data: waterfallData } = useHouseholdTaxForWaterfallQuery({
    householdId,
  });

  const [selectedWaterfallId, setSelectedWaterfallId] = useState<string | null>(
    mostRecentlyViewedWaterfallId
  );

  const selectedWaterfall = useMemo(() => {
    return waterfalls.find((wf) => wf.id === selectedWaterfallId);
  }, [selectedWaterfallId, waterfalls]);

  const isHypothetical = isHypotheticalWaterfall(selectedWaterfall);

  const waterfallPage = selectedWaterfall
    ? getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_SUMMARY,
        { householdId, waterfallId: selectedWaterfall.id }
      )
    : null;

  const { estimatedEstateTax, estimatedGiftTax } = useMemo(() => {
    const projections = selectedWaterfall?.visualizationWithProjections;

    if (!projections?.nodes.length) {
      return {
        estimatedEstateTax: null,
        estimatedGiftTax: null,
      };
    }

    const allEstateTaxTransfers = projections.nodes.flatMap(
      (n) => n.transferredToEstateTax ?? []
    );

    const allGiftTaxTransfers = projections.nodes.flatMap(
      (n) => n.transferredToGiftingTax ?? []
    );

    return {
      estimatedEstateTax: sumDecimalJS(allEstateTaxTransfers),
      estimatedGiftTax: sumDecimalJS(allGiftTaxTransfers),
    };
  }, [selectedWaterfall?.visualizationWithProjections]);

  const title = useMemo(() => {
    if (isHypothetical) {
      return 'Estimated gift & estate tax';
    }

    return 'Estimated estate tax';
  }, [isHypothetical]);

  const totalEstimatedTax = useMemo(() => {
    if (estimatedEstateTax && estimatedGiftTax) {
      return estimatedEstateTax.plus(estimatedGiftTax);
    }

    return null;
  }, [estimatedEstateTax, estimatedGiftTax]);

  const calloutText = useMemo(() => {
    if (!selectedWaterfall) return '';

    if (isTwoClientHousehold) {
      return (
        <>
          {`Calculations are based off a second death occurring in the year `}
          <Box
            component="span"
            sx={{
              fontWeight: FONT_WEIGHTS.bold,
            }}
          >
            {selectedWaterfall.secondGrantorDeathYear ?? getYear(new Date())}
          </Box>
        </>
      );
    }

    return (
      <>
        {`Calculations are based off a death occurring in the year `}
        <Box
          component="span"
          sx={{
            fontWeight: FONT_WEIGHTS.bold,
          }}
        >
          {selectedWaterfall.firstGrantorDeathYear ?? getYear(new Date())}
        </Box>
      </>
    );
  }, [isTwoClientHousehold, selectedWaterfall]);

  useEffect(
    function setInitialWaterfall() {
      if (selectedWaterfallId || !waterfalls.length) {
        // If the user has viewed a waterfall, it should already be set
        // If there are no waterfalls for this client, it should already be set to null
        return;
      }
      // If the user has not viewed a waterfall, return the most recently created source waterfall
      const mostRecentlyCreatedSourceWaterfall = [...waterfalls]
        .sort((wfA, wfB) => {
          return wfB.createdAt.getTime() - wfA.createdAt.getTime();
        })
        .find((wf) => !wf.parent?.id);

      if (mostRecentlyCreatedSourceWaterfall) {
        setSelectedWaterfallId(mostRecentlyCreatedSourceWaterfall.id);
      }
    },
    [selectedWaterfallId, waterfalls]
  );

  const handleWaterfallClick = useCallback((waterfallId: string) => {
    setSelectedWaterfallId(waterfallId);
  }, []);

  const showEmptyState = waterfallData && !waterfalls.length;

  return (
    <>
      {primaryClients && (
        <CreateEstateWaterfallTrowser
          isOpen={isCreateWaterfallOpen}
          householdId={householdId}
          grantors={primaryClients ?? []}
          onClose={() => setIsCreateWaterfallOpen(false)}
        />
      )}

      {showEmptyState ? (
        <Stack px={2} spacing={3} height="100%">
          <ActionCard
            grow
            heading="Set up a waterfall"
            variant="noCard"
            icon={
              <Dataflow04Icon
                size={48}
                sx={{
                  color: COLORS.GRAY[200],
                }}
              />
            }
            description="Set up an estate waterfall to calculate estimated estate taxes"
            size="lg"
            actions={
              <Button
                onClick={() => setIsCreateWaterfallOpen(true)}
                variant="primary"
                disabled={!primaryClients}
                size="lg"
                fullWidth
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                Create an estate waterfall
              </Button>
            }
          />
        </Stack>
      ) : (
        <Stack px={2} spacing={3} height="100%">
          <OversizedMetricItem
            title={title}
            titleVariant="h4"
            valueVariant="h1"
            valueColor={COLORS.NAVY[600]}
            value={
              totalEstimatedTax
                ? formatCurrency(
                    totalEstimatedTax.times(-1),
                    currencyFormatOptions
                  )
                : formatCurrency(new Decimal(0), currencyFormatOptions)
            }
          />
          {calloutText && <Callout severity="info-low">{calloutText}</Callout>}
          <Card
            variant="filled"
            sx={{
              p: 2,
              borderRadius: '6px',
            }}
          >
            <Stack spacing="6px">
              <Typography variant="label">
                Waterfall used for calculations
              </Typography>
              <EstateWaterfallViewsButton
                waterfallId={selectedWaterfallId}
                householdId={householdId}
                showCreateNew={false}
                showHypotheticalBadge={false}
                onClick={handleWaterfallClick}
                setSelectedWaterfallId={setSelectedWaterfallId}
                buttonProps={{
                  size: 'sm',
                  sx: {
                    px: 2,
                    py: 1.25,
                  },
                  contentBoxSx: {
                    width: '100%',
                  },
                }}
              />
            </Stack>
          </Card>
          <Stack direction="row" justifyContent="end">
            <Button
              disabled={!waterfallPage}
              variant="secondary"
              size="sm"
              href={waterfallPage ?? ''}
            >
              View this waterfall
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  );
}
