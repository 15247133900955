import { Stack } from '@mui/material';
import Decimal from 'decimal.js';

import {
  DispositiveProvisionDispositionKind,
  DispositiveProvisionTemplateKind,
  DispositiveProvisionTransferTaxKind,
  TestamentaryEntityKind,
} from '@/types/schema';

import { Recipient } from '../../DispositiveProvisionsForm/DispositiveProvisionsForm.types';
import { DraggableListItemRecipient } from '../../DispositiveProvisionsForm/DispositiveProvisionsFormRecipients/DraggableListItemRecipient';
import {
  MaritalTrustRecipient,
  TrustRecipientProps,
} from './DispositiveProvisionsTemplateSplitScreenModal.components';

type CreditShelterRecipientProps = Pick<TrustRecipientProps, 'index'>;

function CreditShelterRecipient({ index }: CreditShelterRecipientProps) {
  return (
    <DraggableListItemRecipient
      index={index}
      Draghandle={null}
      simulatedValue={null}
      onRemove={null}
      defaultExpanded
      templateType={DispositiveProvisionTemplateKind.AbcTrust}
      emptyRecipientOptionDisplay="Select credit shelter (federal & state exempt)"
      recipientConfiguration={{
        defaultNewTestamentaryEntityName: 'Credit shelter trust',
        defaultNewTestamentaryEntityKind:
          TestamentaryEntityKind.TestamentaryTrust,
        testamentaryEntityTaxSettings: {
          estateInclusionStatus: 'out-of-estate',
          survivingSpouseStateInEstateStatus: 'out-of-estate',
          gstStatus: 'gst-non-exempt',
        },
      }}
    />
  );
}

export function DispositiveProvisionsTemplateABCTrustPreset() {
  return (
    <Stack direction="column" spacing={3}>
      <CreditShelterRecipient index={0} />
      <MaritalTrustRecipient
        index={1}
        templateType={DispositiveProvisionTemplateKind.AbcTrust}
        emptyRecipientOptionDisplay="Select marital trust (federal exempt, state non-exempt)"
        recipientConfiguration={{
          defaultNewTestamentaryEntityName: 'State QTIP marital trust',
          defaultNewTestamentaryEntityKind:
            TestamentaryEntityKind.TestamentaryTrust,
          includeSpouseFederalCreditShelterStateMaritalExclusion: true,
          testamentaryEntityTaxSettings: {
            estateInclusionStatus: 'out-of-estate',
            survivingSpouseStateInEstateStatus: 'in-estate',
            gstStatus: 'gst-non-exempt',
          },
        }}
      />
      <MaritalTrustRecipient
        index={2}
        templateType={DispositiveProvisionTemplateKind.AbTrust}
        emptyRecipientOptionDisplay="Select GST exempt marital trust"
        isOptional
        recipientConfiguration={{
          defaultNewTestamentaryEntityName: 'Marital trust (GST exempt)',
          defaultNewTestamentaryEntityKind:
            TestamentaryEntityKind.TestamentaryTrust,
          testamentaryEntityTaxSettings: {
            estateInclusionStatus: 'in-estate',
            survivingSpouseStateInEstateStatus: 'in-estate',
            gstStatus: 'gst-exempt',
          },
        }}
      />
      <MaritalTrustRecipient
        index={3}
        templateType={DispositiveProvisionTemplateKind.AbTrust}
        emptyRecipientOptionDisplay="Select GST non-exempt marital trust"
        isOptional
        recipientConfiguration={{
          defaultNewTestamentaryEntityName: 'Marital trust (GST non-exempt)',
          defaultNewTestamentaryEntityKind:
            TestamentaryEntityKind.TestamentaryTrust,
          testamentaryEntityTaxSettings: {
            estateInclusionStatus: 'in-estate',
            survivingSpouseStateInEstateStatus: 'in-estate',
            gstStatus: 'gst-non-exempt',
          },
        }}
      />
    </Stack>
  );
}

const BLANK_CREDIT_SHELTER: Recipient = {
  _dispositiveProvisionId: null,
  recipientId: '',
  recipientKind: null,
  dispositionKind_ButtonGroup: 'other',
  dispositionKind_Select:
    DispositiveProvisionDispositionKind.RemainingStateExemption,
  dispositionAmount: null,
  dispositionPercentage: null,
  notes: '',
  transferTaxKind: DispositiveProvisionTransferTaxKind.SpouseCreditShelter,
};

const BLANK_ABC_FED_EXEMPT_STATE_NON_EXEMPT_TRUST: Recipient = {
  _dispositiveProvisionId: null,
  recipientId: '',
  recipientKind: null,
  dispositionKind_ButtonGroup: 'other',
  dispositionKind_Select:
    DispositiveProvisionDispositionKind.RemainingFederalLifetimeExemptionInExcessOfStateExemption,
  dispositionAmount: null,
  dispositionPercentage: new Decimal(100),
  notes: '',
  transferTaxKind:
    DispositiveProvisionTransferTaxKind.SpouseFederalCreditShelterStateMaritalExclusion,
};

export const BLANK_ABC_GST_EXEMPT_MARITAL_TRUST: Recipient = {
  _dispositiveProvisionId: null,
  recipientId: '',
  recipientKind: null,
  dispositionKind_ButtonGroup: 'other',
  dispositionKind_Select:
    DispositiveProvisionDispositionKind.RemainingGstExclusionOfGrantorInExcessOfLifetimeExclusion,
  dispositionAmount: null,
  dispositionPercentage: new Decimal(100),
  notes: '',
  transferTaxKind: DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion,
};

export const BLANK_ABC_GST_NON_EXEMPT_MARITAL_TRUST: Recipient = {
  _dispositiveProvisionId: null,
  recipientId: '',
  recipientKind: null,
  dispositionKind_ButtonGroup: 'other',
  dispositionKind_Select: DispositiveProvisionDispositionKind.AnythingLeftOver,
  dispositionAmount: null,
  dispositionPercentage: new Decimal(100),
  notes: '',
  transferTaxKind: DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion,
};
export const DEFAULT_ABC_TRUST_RECIPIENTS: Recipient[] = [
  BLANK_CREDIT_SHELTER,
  BLANK_ABC_FED_EXEMPT_STATE_NON_EXEMPT_TRUST,
  BLANK_ABC_GST_EXEMPT_MARITAL_TRUST,
  BLANK_ABC_GST_NON_EXEMPT_MARITAL_TRUST,
];
