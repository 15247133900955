import Decimal from 'decimal.js';
import { compact } from 'lodash';

import { TileVariant } from '@/components/diagrams/components/Tile/types';
import { TileNode } from '@/components/diagrams/FlowChart';
import {
  AfterDeath,
  ClientOrganizationKind,
  EntityInEstateStatus,
} from '@/types/schema';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { diagnostics } from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatSignificant } from '@/utils/formatting/formatSignificant';

import {
  EntityDiagram_NodeFragment,
  EntityDiagramGraph,
  EntityDiagramTile,
  EntityDiagramTileKind,
  GraphNodeCategorizationType,
} from '../types';
import {
  categorizationTypeToVariant,
  EntityNode,
  kindToCategorizationType,
  ValidTypeNamesSet,
} from './constants';

export function getNodeId({
  id,
  afterDeath,
}: Pick<EntityDiagram_NodeFragment, 'id' | 'afterDeath'>) {
  return [afterDeath, id].join(':');
}

export function isEntityNode(
  n: EntityDiagram_NodeFragment['node']
): n is EntityNode {
  if (!n) {
    return false;
  }

  return ValidTypeNamesSet.has(n.__typename);
}

export function isNodeIdForSection(nodeId: string, sectionId: AfterDeath) {
  return nodeId.startsWith(`${sectionId}:`);
}

export function getCategorizationType({
  node,
}: EntityDiagram_NodeFragment): GraphNodeCategorizationType | null {
  let kind;
  if (node?.__typename === 'Entity') {
    kind = node.entityKind;
  } else if (node?.__typename === 'TestamentaryEntity') {
    kind = node.testamentaryEntityKind;
  } else if (
    node?.__typename === 'ClientProfile' ||
    node?.__typename === 'ClientOrganization'
  ) {
    return GraphNodeCategorizationType.Individual;
  } else {
    throw new Error(`Unhandled tile in entity diagram`);
  }

  const type = kindToCategorizationType[kind];
  if (!type) {
    diagnostics.error(`Unhandled type from kind in entity diagram ${type}`);
    return null;
  }
  return type;
}

export interface BuildTileFromNodeInput {
  nodeFragment: EntityDiagramTile;
  isNewTile?: boolean;
}

export function buildTileFromNode({
  nodeFragment,
  isNewTile,
}: BuildTileFromNodeInput): TileNode | null {
  const {
    id,
    node,
    afterDeath,
    order,
    xOffset,
    yOffset,
    stackY,
    centerY,
    omitFromCentering,
  } = nodeFragment;
  if (!isEntityNode(node)) {
    diagnostics.error(`Unhandled tile in entity diagram`);
    return null;
  }

  const inEstateStatus = (() => {
    if ('inEstateStatus' in nodeFragment) {
      return nodeFragment.inEstateStatus;
    }
    return EntityInEstateStatus.InEstate;
  })();

  const categorizationType = getCategorizationType(nodeFragment);
  if (!categorizationType) {
    // just return here; this is logged in getCategorizationType
    return null;
  }

  let lineOne = '';
  let variant = categorizationTypeToVariant[categorizationType];

  if (inEstateStatus === EntityInEstateStatus.InEstate) {
    variant = TileVariant.Primary;
  }

  if (node.__typename === 'Entity') {
    lineOne = node.subtype.displayName;
  } else if (node.__typename === 'TestamentaryEntity') {
    lineOne = node.displayName;
  } else if (node.__typename === 'ClientProfile') {
    lineOne = node.displayName;
  } else if (node.__typename === 'ClientOrganization') {
    lineOne = node.name;
    if (
      node.kind === ClientOrganizationKind.CharitableOrganization &&
      inEstateStatus !== EntityInEstateStatus.InEstate
    ) {
      variant = TileVariant.Tertiary;
    }
  }

  const nodeId = getNodeId({ id, afterDeath });

  let deathBenefitAmount: Decimal | null = null;

  if (node.__typename === 'Entity' && node.subtype.policies) {
    deathBenefitAmount = sumDecimalJS(
      node.subtype.policies.map(({ deathBenefitAmount }) => deathBenefitAmount)
    );
  }

  let lineThree;

  if ('entityDiagramTileKind' in nodeFragment) {
    const getLineThreeFromTileKind = (kind: EntityDiagramTileKind) => {
      switch (kind) {
        case EntityDiagramTileKind.Grantor:
          return 'Grantor';
        case EntityDiagramTileKind.IncomeBeneficiary:
          return 'Income beneficiary';
        case EntityDiagramTileKind.LeadBeneficiary:
          return 'Lead beneficiary';
        case EntityDiagramTileKind.TermEndBeneficiary:
          return 'Remainder beneficiary';
        case EntityDiagramTileKind.CharitableTermEndBeneficiary:
          return 'Charitable remainder beneficiary';
        case EntityDiagramTileKind.Entity:
        case EntityDiagramTileKind.DispositionBeneficiary:
        case EntityDiagramTileKind.OwnershipBeneficiary:
          return undefined;
        default:
          throw new UnreachableError({
            case: kind,
            message: `Unhandled entity diagram tile kind ${kind}`,
          });
      }
    };

    if (Array.isArray(nodeFragment.entityDiagramTileKind)) {
      lineThree = compact(
        nodeFragment.entityDiagramTileKind.map(getLineThreeFromTileKind)
      ).join(', ');
    } else {
      lineThree = getLineThreeFromTileKind(nodeFragment.entityDiagramTileKind);
    }
  }

  if (deathBenefitAmount) {
    lineThree = `Death benefit ${formatCurrency(deathBenefitAmount, { notation: 'compact' })}`;
  }

  if ('ownershipPercentage' in node && node.ownershipPercentage) {
    lineThree = `${formatSignificant(node.ownershipPercentage, 3)}% ownership`;
  }

  let footerLabel: string | undefined = undefined;
  if (
    'hasIncomingPouroverDisposition' in node &&
    node.hasIncomingPouroverDisposition
  ) {
    footerLabel = 'Pour-over will';
  }

  return {
    id: nodeId,
    type: 'tile',
    position: {
      x: 0,
      y: 0,
    },
    selectable: false,
    data: {
      lineOne,
      lineThree,
      variant,
      footerLabel,
      sectionLabelId: afterDeath,
      isNewTile,
      order,
      xOffset,
      yOffset,
      stackY,
      centerY,
      omitFromCentering,
    },
  };
}

export function extractReactFlowNodesAndEdges(graph: EntityDiagramGraph) {
  const nodes = compact(
    graph.mapNodes((_nodeId, node) => {
      return node.node;
    })
  );

  const edges = graph.getEdges();

  return { nodes, edges };
}
