import { Box, Stack } from '@mui/material';

import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';
import { CellContainer } from '@/components/tables/DataTable/components/cells';
import { AvatarRenderer } from '@/components/tables/DataTable/renderers/cell/AvatarRenderer';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import {
  SanitizeHTML,
  sanitizeToString,
} from '@/components/typography/SanitizeHTML';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { DownloadPresentationButton } from '../DownloadPresentationButton/DownloadPresentationButton';
import { ClientPresentationsTable_ClientPresentationFragment } from './graphql/ClientPresentationsTable.generated';

export enum ClientPresentationsTableColumns {
  LAST_MODIFIED = 'lastModified',
  NAME = 'name',
  CREATED_BY = 'createdBy',
  ACTIONS = 'actions',
}

export interface RowData {
  id: string;
  lastModified: Date;
  name: string;
  householdId: string;
  createdBy: ClientPresentationsTable_ClientPresentationFragment['createdBy'];
  actions?: never;
  isV2Presentation: boolean;
}

export const getColumns = (): Column<RowData>[] => {
  return [
    {
      field: ClientPresentationsTableColumns.LAST_MODIFIED,
      headerName: 'Last modified',
      width: 200,
      renderCell: TextRenderer({
        text: ({ row }) => formatDateToMonDDYYYY(row.lastModified),
      }),
    },
    {
      field: ClientPresentationsTableColumns.NAME,
      headerName: 'Report',
      width: 175,
      flex: 1,
      renderCell: TextRenderer({
        text: ({ row }) => <SanitizeHTML html={row.name} />,
        textTitle: ({ row }) => sanitizeToString(row.name),
      }),
    },
    {
      field: ClientPresentationsTableColumns.CREATED_BY,
      headerName: 'Created by',
      width: 200,
      renderCell: AvatarRenderer({
        avatarProps: ({ row }) => ({
          initials: row.createdBy.initials,
          fullName: row.createdBy.displayName,
        }),
      }),
    },
    {
      field: ClientPresentationsTableColumns.ACTIONS,
      headerName: '',
      align: 'center',
      width: 100,
      minWidth: 100,
      // TODO: this will be an action tray
      renderCell: ({ row }) => {
        return (
          <CellContainer>
            <Stack direction="row" spacing={2} alignItems="center">
              <Tooltip title="Download presentation PDF" placement="top-start">
                <Box>
                  <DownloadPresentationButton
                    householdId={row.householdId}
                    presentationId={row.id}
                    presentationDisplayName={row.name}
                    isV2Presentation={row.isV2Presentation}
                    size="xs"
                    variant="secondary"
                  />
                </Box>
              </Tooltip>
              <ChevronRightIcon size={20} />
            </Stack>
          </CellContainer>
        );
      },
      sortable: false,
    },
  ];
};
