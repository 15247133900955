import { Box } from '@mui/material';

import { Card } from '@/components/layout/Card/Card';
import { FloatingMenuContainer } from '@/components/layout/FloatingMenuContainer';
import { Markdown } from '@/components/Markdown/Markdown';
import { DocumentSearchResult } from '@/modules/pdf/DocumentSearch/DocumentSearch.types';
import { DocumentSearchBar } from '@/modules/pdf/DocumentSearch/DocumentSearchBar';
import { PDFViewerLazy } from '@/modules/pdf/PDFViewer';
import { useAnnotatedPDFViewerContext } from '@/modules/pdf/PDFViewer/AnnotatedPDFViewer/context/AnnotatedPDFViewer.context';
import { AnnotatedPDFViewerProvider } from '@/modules/pdf/PDFViewer/AnnotatedPDFViewer/context/AnnotatedPDFViewer.provider';
import { DocumentChromeProps } from '@/modules/pdf/PDFViewer/DocumentChrome';
import { COLORS } from '@/styles/tokens/colors';

import { useHouseholdDocumentsViewerContext } from './context/HouseholdDocumentsViewer.context';
import { DocumentEmptyState } from './DocumentEmptyState';
import { DocumentRenderError } from './DocumentRenderError';
import { DocumentSelector } from './DocumentSelector/DocumentSelector';
import { DocumentSummarySwitch } from './DocumentSummarySwitch';

type InsetCardChromeProps = DocumentChromeProps;

function InsetCardChrome({
  children,
  shouldCenterContent,
  sx = {},
}: InsetCardChromeProps) {
  return (
    <Card
      data-name="InsetCardChrome"
      variant="inner-shadow"
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: COLORS.GRAY[50],
        overflowY: 'auto',
        px: 2,
        pt: 3,
        borderRadius: 0,
        ...sx,
      }}
    >
      {/*
        we have this extra container because if the relative parent of the annotations has padding,
        then the absolute positioning of the annotation overlays won't correctly consider the
        padding and will be offset
      */}
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: shouldCenterContent ? 'center' : 'start',
        }}
      >
        {children}
      </Box>
    </Card>
  );
}

function HouseholdDocumentViewerWithAnnotations() {
  const {
    activeDocument,
    documentViewerActiveTab,
    error,
    loading,
    householdId,
  } = useHouseholdDocumentsViewerContext();
  const { annotations, activeAnnotationIndex } = useAnnotatedPDFViewerContext();

  const documentUrl = activeDocument?.file.download.downloadURL ?? null;

  return (
    <>
      {documentViewerActiveTab === 'document' && (
        <PDFViewerLazy
          // using `key` to force remounting the whole PDFViewer to reset the state
          // when the document changes
          key={documentUrl}
          loading={loading || !householdId}
          error={!!error}
          documentId={activeDocument?.id}
          documentUrl={documentUrl}
          annotations={annotations}
          activeAnnotationIdx={activeAnnotationIndex}
          slots={{
            DocumentEmptyState: DocumentEmptyState,
            DocumentChrome: InsetCardChrome,
            DocumentTopLeftControl: activeDocument?.documentSummary
              ? DocumentSummarySwitch
              : undefined,
            DocumentTopRightControl: DocumentSelector,
            DocumentFooter: DocumentSearchBar,
            DocumentRenderError: DocumentRenderError,
          }}
        />
      )}
      {documentViewerActiveTab === 'summary' &&
        activeDocument?.documentSummary && (
          <Box
            height="100%"
            sx={{
              height: '100%',
              position: 'relative',
              borderRadius: 0,
              borderRight: 1,
              borderColor: COLORS.GRAY[200],
              overflowY: 'auto',
              pl: 2,
            }}
          >
            <FloatingMenuContainer offset={{ top: 26 }}>
              <DocumentSummarySwitch />
            </FloatingMenuContainer>
            <Card
              variant="transparent"
              sx={{
                maxWidth: 708,
                margin: '0 auto',
                pt: 8,
              }}
            >
              <Markdown markdown={activeDocument?.documentSummary.summary} />
            </Card>
          </Box>
        )}
    </>
  );
}

export function HouseholdDocumentsViewerInner({
  overrideAnnotations,
  overrideSearchQuery,
  onUpdateSearchQuery,
  overrideSearchLoading,
}: Pick<
  HouseholdDocumentsViewerProps,
  | 'overrideAnnotations'
  | 'overrideSearchQuery'
  | 'onUpdateSearchQuery'
  | 'overrideSearchLoading'
>) {
  const { activeDocumentId } = useHouseholdDocumentsViewerContext();

  return (
    <AnnotatedPDFViewerProvider
      documentId={activeDocumentId}
      overrideAnnotations={overrideAnnotations}
      overrideSearchQuery={overrideSearchQuery}
      onUpdateSearchQuery={onUpdateSearchQuery}
      overrideSearchLoading={overrideSearchLoading}
    >
      <HouseholdDocumentViewerWithAnnotations />
    </AnnotatedPDFViewerProvider>
  );
}

export interface HouseholdDocumentsViewerProps {
  overrideAnnotations?: DocumentSearchResult[];
  overrideSearchQuery?: string;
  onUpdateSearchQuery?: () => void;
  overrideSearchLoading?: boolean;
}

export function HouseholdDocumentsViewer(props: HouseholdDocumentsViewerProps) {
  return (
    <HouseholdDocumentsViewerInner
      overrideAnnotations={props.overrideAnnotations}
      overrideSearchQuery={props.overrideSearchQuery}
      onUpdateSearchQuery={props.onUpdateSearchQuery}
      overrideSearchLoading={props.overrideSearchLoading}
    />
  );
}
