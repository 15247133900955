import { Box, Stack, Typography, useTheme } from '@mui/material';

import { Logomark, LogomarkSize } from '@/components/brand/Logomark/Logomark';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { PresentationBundleKind } from '@/types/schema';

import {
  useClientPresentationDesignerV2Context,
  useRegisterSlide,
} from '../../../ClientPresentationDesignerV2.context';
import { ClientPresentationV2Configuration } from '../../../ClientPresentationDesignerV2.types';
import { ClientPresentationV2Footer } from '../../ClientPresentationV2Footer';
import { BaseBundleSlideProps } from '../BundleSlide.types';

export interface CoverSlideProps extends BaseBundleSlideProps {
  presentationConfiguration: ClientPresentationV2Configuration;
  presentationName: string;
}

export function CoverSlide({
  bundle,
  SlideWrapper = Box,
  presentationName,
  isVisible,
}: CoverSlideProps) {
  const theme = useTheme();
  const isDisplayTypographyEnabled = useFeatureFlag(
    'use_serif_font_in_presentation'
  );
  const { presentationConfiguration } =
    useClientPresentationDesignerV2Context();
  useRegisterSlide({
    slideId: bundle.id,
    title: 'Cover slide',
    includeInToC: true,
    bundleKind: PresentationBundleKind.CoverSlideBundle,
  });

  if (!isVisible) {
    return null;
  }

  return (
    <SlideWrapper>
      <PresentationSlide.Slide
        id="slide-display"
        footer={
          <ClientPresentationV2Footer
            slideId={bundle.id}
            hideLogo
            hidePageNumber={
              !presentationConfiguration?.showPageNumbersOnCoverSlide
            }
            variant="cover"
          />
        }
      >
        <Stack spacing={3} justifyContent="center" p={10.5} height="100%">
          <Stack direction="column" spacing={theme.spacing(0.5)}>
            <Stack
              maxHeight="15vh"
              direction="row"
              alignItems="center"
              mb="3vh !important"
            >
              <Logomark
                style={{ maxHeight: '100%' }}
                size={LogomarkSize.Wide}
              />
            </Stack>
            {presentationName && (
              <Typography
                variant={isDisplayTypographyEnabled ? 'h1_display' : 'h1'}
                color={theme.palette.primary.main}
              >
                {presentationName}
              </Typography>
            )}
            {bundle.coverSlideBundleConfiguration?.customText && (
              <Typography variant="body1">
                {bundle.coverSlideBundleConfiguration?.customText}
              </Typography>
            )}
          </Stack>
        </Stack>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}
