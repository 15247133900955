import { Stack, Typography, useTheme } from '@mui/material';

import { Logomark, LogomarkSize } from '@/components/brand/Logomark/Logomark';
import { GratContentTrigger } from '@/modules/content/sidebarContent/clientFacingGRAT/GratContentTrigger';
import { useUnguardedTenantPresentationConfigurationContext } from '@/modules/presentation/context/tenantPresentationConfiguration.context';
import { DisclaimerFooter } from '@/modules/presentation/PresentationSlide/DisclaimerFooter';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { ProposalKind } from '../hooks/useProposalsQuery';
import { SlideContainer } from './SlideContainer';

export function CoverPage({
  proposal,
  proposalKind,
}: {
  proposal: ProposalFragment;
  proposalKind: ProposalKind;
}) {
  const theme = useTheme();

  const { displayName: tenantName } = useTenantDetailsContext();
  const { legalDisclaimers } =
    useUnguardedTenantPresentationConfigurationContext();

  return (
    <SlideContainer
      id="cover-page"
      sx={{
        background: `linear-gradient(250deg, ${theme.palette.grey[200]} 0%, ${theme.palette.common.white} 100%)`,
        display: 'flex',
        flexGrow: 1,
      }}
      hideFooter
    >
      <Stack direction="column" paddingTop="14%" width="100%">
        <Stack paddingLeft={11} direction="row" justifyContent="space-between">
          <Stack
            direction="column"
            spacing={theme.spacing(1)}
            alignSelf="center"
          >
            <Stack height="15vh" direction="row" alignItems="center" mb="3vh">
              <Logomark
                style={{ maxHeight: '100%' }}
                size={LogomarkSize.Wide}
                alt={`Proposal authored by ${tenantName}`}
              />
            </Stack>
            <Typography variant="h1" color={theme.palette.primary.main}>
              {proposal?.displayName}
            </Typography>
            <Typography variant="h6">
              {proposal?.updatedAt &&
                `Last updated ${formatDateToMonDDYYYY(proposal.updatedAt)}`}
            </Typography>
            <Typography variant="body1">{proposal?.clientNotes}</Typography>
          </Stack>
          <Stack flexBasis="120px" flexShrink={0} />
          {proposalKind === ProposalKind.Entity && <GratContentTrigger />}
        </Stack>
        <Stack flex={1} />
      </Stack>
      <Stack m={3} justifyContent="end" flex="1">
        {legalDisclaimers ? (
          <DisclaimerFooter variant="cover" />
        ) : (
          <Typography variant="subtitle2">
            The material presented is designed to provide illustrative
            information about ideas and strategies. It is for discussion
            purposes only, and the availability and effectiveness of any
            strategies are dependent upon your individual facts and
            circumstances. Always consult with your independent attorney, tax
            advisor, investment manager, and insurance agent for final
            recommendations before changing or implementing any financial, tax,
            or investment strategy.
          </Typography>
        )}
      </Stack>
    </SlideContainer>
  );
}
