import { InputAdornment, Typography } from '@mui/material';
import { compact, isEmpty } from 'lodash';
import { ReactElement, useContext } from 'react';
import { useWatch } from 'react-hook-form';

import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import {
  GRANTOR_RELATIONSHIP_OPTIONS,
  PRIMARY_CLIENT_RELATIONSHIP_OPTIONS,
} from '@/components/form/PersonFormFragment/constants';
import { Mail01Icon } from '@/components/icons/Mail01Icon';
import { UsersLeftIcon } from '@/components/icons/UsersLeftIcon';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { ClientProfileFormShape } from '@/modules/clientProfiles/ClientProfile.types';
import { StateDropdownInput } from '@/modules/common/StateDropdownInput/StateDropdownInput';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { PathsOf } from '@/types/subform';

import { ClientProfileModalContext } from './ClientProfileModal.context';

function getRelationshipPath(
  fieldName: number
): `relationships.${number}.type` {
  return `relationships.${fieldName}.type` satisfies PathsOf<ClientProfileFormShape>;
}

function GrantorRelationships() {
  const { control } = useFormContext<ClientProfileFormShape>();
  const { primaryClients } = useHouseholdDetailsContext();
  const [relationships, clientId] = useWatch({
    name: ['relationships', 'id'],
    control,
  });

  if (isEmpty(relationships)) {
    return null;
  }

  const primaryClientIds: string[] = compact(primaryClients?.map((c) => c.id));

  return (
    <FormLayoutRow>
      <FormLayoutItem>
        <Card variant="filled" sx={{ p: 2 }}>
          <Typography variant="h4" sx={{ pb: 1 }}>
            Relationship to client(s)
          </Typography>
          <FormLayoutRow>
            {relationships.map((relationship, index) => {
              const isPrimaryClientRelationship = Boolean(
                clientId &&
                  relationship.toClientProfileId &&
                  primaryClientIds?.includes(clientId) &&
                  primaryClientIds.includes(relationship.toClientProfileId)
              );
              return (
                <FormLayoutItem
                  width={6}
                  key={`relationship-${relationship.toClientProfileId}`}
                >
                  <FormAwareSelectInput<ClientProfileFormShape>
                    fieldName={getRelationshipPath(index)}
                    label={relationship.display}
                    required={isPrimaryClientRelationship}
                    options={
                      isPrimaryClientRelationship
                        ? PRIMARY_CLIENT_RELATIONSHIP_OPTIONS
                        : GRANTOR_RELATIONSHIP_OPTIONS
                    }
                    control={control}
                    startAdornment={
                      <InputAdornment position="start">
                        <UsersLeftIcon size={20} />
                      </InputAdornment>
                    }
                  />
                </FormLayoutItem>
              );
            })}
          </FormLayoutRow>
        </Card>
      </FormLayoutItem>
    </FormLayoutRow>
  );
}
export interface ClientProfilePersonProps {
  disableBeneficiary?: boolean;
  disableTrustee?: boolean;
  disableGrantor?: boolean;
}

export function ClientProfilePerson({
  disableBeneficiary = false,
  disableTrustee = false,
  disableGrantor = false,
}: ClientProfilePersonProps): ReactElement {
  const { control } = useFormContext<ClientProfileFormShape>();
  const { isPrimary } = useContext(ClientProfileModalContext);

  // if the profile is for a grantor, or if either city or state are present, then both city & state are required
  const hasCity = !!useWatch({
    control,
    name: 'address.cityName',
  });

  return (
    <>
      <FormLayoutRow>
        <FormLayoutItem width={6}>
          <FormAwareTextInput<ClientProfileFormShape>
            fieldName={'firstName'}
            label="First name"
            control={control}
            required
          />
        </FormLayoutItem>
        <FormLayoutItem width={6}>
          <FormAwareTextInput<ClientProfileFormShape>
            fieldName={'middleName'}
            label="Middle name"
            placeholder="Optional"
            control={control}
          />
        </FormLayoutItem>
      </FormLayoutRow>

      <FormLayoutRow>
        <FormLayoutItem width={10}>
          <FormAwareTextInput<ClientProfileFormShape>
            control={control}
            label="Last name"
            required
            fieldName="lastName"
          />
        </FormLayoutItem>
        <FormLayoutItem width={2}>
          <FormAwareTextInput<ClientProfileFormShape>
            control={control}
            label="Suffix"
            fieldName="suffix"
          />
        </FormLayoutItem>
      </FormLayoutRow>

      <FormLayoutRow>
        <FormLayoutItem width={5}>
          <FormAwareTextInput<ClientProfileFormShape>
            control={control}
            label="City"
            fieldName="address.cityName"
          />
        </FormLayoutItem>
        <FormLayoutItem width={3}>
          <StateDropdownInput<ClientProfileFormShape>
            label="State"
            required={
              isPrimary ||
              hasCity /* if city is entered, state is required.  checking this way so user can still clear the state. */
            }
            fieldName="address.stateCode"
            control={control}
          />
        </FormLayoutItem>
        <FormLayoutItem width={4}>
          <FormAwareDatePickerInput
            control={control}
            label="Date of birth"
            fieldName="dateOfBirth"
          />
        </FormLayoutItem>
      </FormLayoutRow>

      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareTextInput<ClientProfileFormShape>
            control={control}
            label="Email"
            type="email"
            fieldName="email"
            startAdornment={
              <InputAdornment position="start">
                <Mail01Icon size={20} />
              </InputAdornment>
            }
          />
        </FormLayoutItem>
      </FormLayoutRow>

      <GrantorRelationships />

      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareSwitch<ClientProfileFormShape>
            disabled={disableTrustee}
            control={control}
            label="Enable use as a trustee"
            fieldName="isTrustee"
            labelPosition="right"
          />
        </FormLayoutItem>
      </FormLayoutRow>

      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareSwitch<ClientProfileFormShape>
            disabled={disableBeneficiary}
            control={control}
            label="Enable use as a beneficiary"
            fieldName="isBeneficiary"
            labelPosition="right"
          />
        </FormLayoutItem>
      </FormLayoutRow>

      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareSwitch<ClientProfileFormShape>
            disabled={disableGrantor}
            control={control}
            label="Enable use as a grantor/owner/donor"
            fieldName="isGrantor"
            labelPosition="right"
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </>
  );
}
