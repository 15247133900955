import { AutoPositionAction, EntityDiagramActionFn } from '../types';

export const autoPositionAction: EntityDiagramActionFn<AutoPositionAction> = (
  state,
  _action,
  queueEffect
) => {
  const { presentationMode } = state;

  queueEffect({ type: 'fitViewEffect', presentationMode });

  return state;
};
