import { useMemo } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { entityDiagramEntityVar } from '@/graphql/reactiveVars';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { diagnostics } from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';
import { getNodes } from '@/utils/graphqlUtils';

import {
  Operations as CltTermDiagramOperations,
  useCltTermDiagramQuery,
} from '../graphql/CltTermDiagram.generated';
import {
  Operations as CrtTermDiagramOperations,
  useCrtTermDiagramQuery,
} from '../graphql/CrtTermDiagram.generated';
import {
  Operations as DispositionsDiagramOperations,
  useDispositionsDiagramQuery,
} from '../graphql/DispositionsDiagram.generated';
import {
  Operations as GratTermDiagramOperations,
  useGratTermDiagramQuery,
} from '../graphql/GratTermDiagram.generated';
import {
  Operations as OwnershipDiagramOperations,
  useOwnershipDiagramQuery,
} from '../graphql/OwnershipDiagram.generated';
import {
  Operations as QprtTermDiagramOperations,
  useQprtTermDiagramQuery,
} from '../graphql/QprtTermDiagram.generated';
import { EntityDiagramVariant } from '../types';

// Given an entity diagram variant and a query name, determine if we should skip the query
// because it is not needed for the given variant.
function shouldSkipQuery(
  entityDiagramVariant: EntityDiagramVariant | null,
  operationName:
    | typeof DispositionsDiagramOperations.Query.DispositionsDiagram
    | typeof OwnershipDiagramOperations.Query.OwnershipDiagram
    | typeof GratTermDiagramOperations.Query.GratTermDiagram
    | typeof QprtTermDiagramOperations.Query.QprtTermDiagram
    | typeof CrtTermDiagramOperations.Query.CrtTermDiagram
    | typeof CltTermDiagramOperations.Query.CltTermDiagram
) {
  if (
    entityDiagramVariant === EntityDiagramVariant.Dispositions &&
    operationName === DispositionsDiagramOperations.Query.DispositionsDiagram
  ) {
    return false;
  }

  if (
    entityDiagramVariant === EntityDiagramVariant.Ownership &&
    operationName === OwnershipDiagramOperations.Query.OwnershipDiagram
  ) {
    return false;
  }

  if (
    entityDiagramVariant === EntityDiagramVariant.GratTerm &&
    operationName === GratTermDiagramOperations.Query.GratTermDiagram
  ) {
    return false;
  }

  if (
    entityDiagramVariant === EntityDiagramVariant.QprtTerm &&
    operationName === QprtTermDiagramOperations.Query.QprtTermDiagram
  ) {
    return false;
  }

  if (
    entityDiagramVariant === EntityDiagramVariant.CrtTerm &&
    operationName === CrtTermDiagramOperations.Query.CrtTermDiagram
  ) {
    return false;
  }

  if (
    entityDiagramVariant === EntityDiagramVariant.CltTerm &&
    operationName === CltTermDiagramOperations.Query.CltTermDiagram
  ) {
    return false;
  }

  return true;
}

interface UseQueryEntityDiagramProps {
  entityId: string;
  entityDiagramVariant: EntityDiagramVariant | null;
}

export function useQueryEntityDiagram({
  entityId,
  entityDiagramVariant,
}: UseQueryEntityDiagramProps) {
  const { primaryClients } = useHouseholdDetailsContext();
  const { showFeedback } = useFeedback();

  const { data: dispositionsData, ...dispositionsRest } =
    useDispositionsDiagramQuery({
      // This query is deeply nested and causes cache reconciliation issues
      // that block the main thread. By avoiding the cache, we just query
      // the data from the server.
      fetchPolicy: 'no-cache',
      variables: {
        entityWhere: {
          id: entityId,
        },
      },
      onError: (error) => {
        showFeedback(
          `Sorry, we failed to load the page. Please refresh and try again.`
        );
        diagnostics.error('dispositions diagram load failure', error, {
          entityId,
        });
      },
      skip: shouldSkipQuery(
        entityDiagramVariant,
        DispositionsDiagramOperations.Query.DispositionsDiagram
      ),
    });

  const { data: ownershipData, ...ownershipRest } = useOwnershipDiagramQuery({
    variables: {
      entityWhere: {
        id: entityId,
      },
    },
    onError: (error) => {
      showFeedback(
        `Sorry, we failed to load the page. Please refresh and try again.`
      );
      diagnostics.error('ownership diagram load failure', error, {
        entityId,
      });
    },
    skip: shouldSkipQuery(
      entityDiagramVariant,
      OwnershipDiagramOperations.Query.OwnershipDiagram
    ),
  });

  const { data: gratTermData, ...gratTermRest } = useGratTermDiagramQuery({
    variables: {
      entityWhere: {
        id: entityId,
      },
    },
    onError: (error) => {
      showFeedback(
        `Sorry, we failed to load the page. Please refresh and try again.`
      );
      diagnostics.error('GRAT term diagram load failure', error, {
        entityId,
      });
    },
    skip: shouldSkipQuery(
      entityDiagramVariant,
      GratTermDiagramOperations.Query.GratTermDiagram
    ),
  });

  const { data: qprtTermData, ...qprtTermRest } = useQprtTermDiagramQuery({
    variables: {
      entityWhere: {
        id: entityId,
      },
    },
    onError: (error) => {
      showFeedback(
        `Sorry, we failed to load the page. Please refresh and try again.`
      );
      diagnostics.error('QPRT term diagram load failure', error, {
        entityId,
      });
    },
    skip: shouldSkipQuery(
      entityDiagramVariant,
      QprtTermDiagramOperations.Query.QprtTermDiagram
    ),
  });

  const { data: crtTermData, ...crtTermRest } = useCrtTermDiagramQuery({
    variables: {
      entityWhere: {
        id: entityId,
      },
    },
    onError: (error) => {
      showFeedback(
        `Sorry, we failed to load the page. Please refresh and try again.`
      );
      diagnostics.error('CRT term diagram load failure', error, {
        entityId,
      });
    },
    skip: shouldSkipQuery(
      entityDiagramVariant,
      CrtTermDiagramOperations.Query.CrtTermDiagram
    ),
  });

  const { data: cltTermData, ...cltTermRest } = useCltTermDiagramQuery({
    variables: {
      entityWhere: {
        id: entityId,
      },
    },
    onError: (error) => {
      showFeedback(
        `Sorry, we failed to load the page. Please refresh and try again.`
      );
      diagnostics.error('CLT term diagram load failure', error, {
        entityId,
      });
    },
    skip: shouldSkipQuery(
      entityDiagramVariant,
      CltTermDiagramOperations.Query.CltTermDiagram
    ),
  });

  const rest = useMemo(() => {
    if (!entityDiagramVariant) {
      return {};
    }

    switch (entityDiagramVariant) {
      case EntityDiagramVariant.Dispositions:
        return dispositionsRest;
      case EntityDiagramVariant.Ownership:
        return ownershipRest;
      case EntityDiagramVariant.GratTerm:
        return gratTermRest;
      case EntityDiagramVariant.CrtTerm:
        return crtTermRest;
      case EntityDiagramVariant.CltTerm:
        return cltTermRest;
      case EntityDiagramVariant.QprtTerm:
        return qprtTermRest;
      default:
        throw new UnreachableError({
          case: entityDiagramVariant,
          message: 'Unhandled entity diagram variant',
        });
    }
  }, [
    entityDiagramVariant,
    dispositionsRest,
    ownershipRest,
    gratTermRest,
    crtTermRest,
    cltTermRest,
    qprtTermRest,
  ]);

  const { entity } = useMemo(() => {
    if (!entityDiagramVariant) {
      return { entity: null };
    }

    switch (entityDiagramVariant) {
      case EntityDiagramVariant.Dispositions: {
        // Because we do not store the result of this query in the cache,
        // we use a reactive variable to store the result so we effectively
        // get cache and network behavior.
        const [cachedEntityId, cachedEntity] = entityDiagramEntityVar();

        if (!dispositionsData && cachedEntityId === entityId) {
          return { entity: cachedEntity };
        }

        // If we don't have data and the entityId doesn't match the cached entityId,
        // we return null
        if (!dispositionsData) {
          return { entity: null };
        }

        const entity = getNodes(dispositionsData.entities)[0] ?? null;

        // Set the entity on the reactive var
        entityDiagramEntityVar([entityId, entity]);

        return { entity };
      }
      case EntityDiagramVariant.Ownership: {
        if (!ownershipData) {
          return { entity: null };
        }

        const entity = getNodes(ownershipData.entities)[0] ?? null;
        return { entity };
      }
      case EntityDiagramVariant.GratTerm: {
        if (!gratTermData) {
          return { entity: null };
        }

        const entity = getNodes(gratTermData.entities)[0] ?? null;
        return { entity };
      }
      case EntityDiagramVariant.CrtTerm: {
        if (!crtTermData) {
          return { entity: null };
        }

        const entity = getNodes(crtTermData.entities)[0] ?? null;
        return { entity };
      }
      case EntityDiagramVariant.CltTerm: {
        if (!cltTermData) {
          return { entity: null };
        }

        const entity = getNodes(cltTermData.entities)[0] ?? null;
        return { entity };
      }
      case EntityDiagramVariant.QprtTerm: {
        if (!qprtTermData) {
          return { entity: null };
        }

        const entity = getNodes(qprtTermData.entities)[0] ?? null;
        return { entity };
      }
      default:
        throw new UnreachableError({
          case: entityDiagramVariant,
          message: 'Unhandled entity diagram variant',
        });
    }
  }, [
    cltTermData,
    crtTermData,
    dispositionsData,
    entityDiagramVariant,
    entityId,
    gratTermData,
    ownershipData,
    qprtTermData,
  ]);

  return {
    primaryClients,
    entity,
    ...rest,
  };
}
