import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { BUSINESS_ENTITY_KINDS } from '@/modules/entities/entities.constants';
import { EntityKind } from '@/types/schema';

import { EntityDiagramVariant } from '../types';

export function useEntityDiagramVariant(): EntityDiagramVariant | null {
  const { entityKind } = useEntityDetailsContext();

  if (!entityKind) {
    return null;
  }

  return getEntityDiagramVariant(entityKind);
}

export function getEntityDiagramVariant(
  entityKind: EntityKind
): EntityDiagramVariant {
  if (entityKind === EntityKind.GratTrust) {
    return EntityDiagramVariant.GratTerm;
  }

  if (entityKind === EntityKind.QprtTrust) {
    return EntityDiagramVariant.QprtTerm;
  }

  if (entityKind === EntityKind.CrtTrust) {
    return EntityDiagramVariant.CrtTerm;
  }

  if (entityKind === EntityKind.CltTrust) {
    return EntityDiagramVariant.CltTerm;
  }

  if (BUSINESS_ENTITY_KINDS.includes(entityKind)) {
    return EntityDiagramVariant.Ownership;
  }

  return EntityDiagramVariant.Dispositions;
}
