import { Box, Stack } from '@mui/material';

import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { ArrowLeftIcon } from '@/components/icons/ArrowLeftIcon';
import { ArrowRightIcon } from '@/components/icons/ArrowRightIcon';
import { StepTracker } from '@/components/navigation/StepTracker/StepTracker';
import { COLORS } from '@/styles/tokens/colors';

import { HypotheticalSaleLoanFormTab } from '../EstateWaterfallHypotheticalSaleLoanModal.types';
import { FormContainer } from './FormContainer';
import { useActiveSaleLoanTab } from './hooks/useActiveSaleLoanTab';
import { SaleLoanAssetsForm } from './SaleLoanAssetsForm';
import { SaleLoanBasicInformationForm } from './SaleLoanBasicInformationForm';
import { SaleLoanPaymentForm } from './SaleLoanPaymentForm';

const STEPS: {
  display: string;
  id: HypotheticalSaleLoanFormTab;
}[] = [
  {
    display: 'Basics',
    id: 'basicInformation',
  },
  {
    display: 'Assets',
    id: 'assets',
  },
  {
    display: 'Payment',
    id: 'payment',
  },
];

export function SaleLoanModalForm() {
  const { activeTab, setActiveTab } = useActiveSaleLoanTab();

  const activeStepIndex = STEPS.findIndex((step) => step.id === activeTab);

  const handleStepChange = (delta: number) => {
    const newIndex = activeStepIndex + delta;
    if (newIndex >= 0 && newIndex < STEPS.length) {
      setActiveTab(STEPS[newIndex]!.id);
    }
  };

  return (
    <Stack>
      <Stack
        spacing={3}
        sx={{ borderBottom: `1px solid ${COLORS.ORANGE[400]}` }}
      >
        <Stack
          p={3}
          pt={0}
          alignItems={'center'}
          direction="row"
          justifyContent="space-between"
        >
          <Box maxWidth={350} minWidth={250}>
            <StepTracker
              segments={STEPS.map((step, i) => ({
                ...step,
                display: i === activeStepIndex ? step.display : '',
                isComplete: i === activeStepIndex,
                onClick: () => setActiveTab(step.id),
              }))}
              activeSegmentIndex={activeStepIndex}
            />
          </Box>
          <Stack direction="row" spacing={1}>
            <IconButton
              variant="primary"
              onClick={() => handleStepChange(-1)}
              disabled={activeStepIndex === 0}
              icon={ArrowLeftIcon}
              ariaLabel="Previous"
              size="sm"
            />
            <IconButton
              variant="primary"
              onClick={() => handleStepChange(1)}
              disabled={activeStepIndex === STEPS.length - 1}
              icon={ArrowRightIcon}
              ariaLabel="Next"
              size="sm"
            />
          </Stack>
        </Stack>
      </Stack>
      <FormContainer visible={activeTab === 'basicInformation'}>
        <SaleLoanBasicInformationForm />
      </FormContainer>
      <FormContainer visible={activeTab === 'assets'}>
        <SaleLoanAssetsForm />
      </FormContainer>
      <FormContainer visible={activeTab === 'payment'}>
        <SaleLoanPaymentForm />
      </FormContainer>
    </Stack>
  );
}
