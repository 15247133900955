import { IntegrationEntityExternalKind } from '@/types/schema';

export const INTEGRATION_ENTITY_EXTERNAL_KIND_DISPLAY: Record<
  IntegrationEntityExternalKind,
  string | undefined
> = {
  ADDEPAR_HOLDING_ACCOUNT: 'Account',
  ADDEPAR_LEGAL_ENTITY: 'Entity',
  BLACK_DIAMOND_ACCOUNT: 'Account',
  BLACK_DIAMOND_PORTFOLIO: 'Portfolio',
  ORION_ACCOUNT: 'Account',
  ORION_PORTFOLIO: 'Portfolio',
};

export const INTEGRATION_ENTITY_TYPEAHEAD_SORT_ORDER: IntegrationEntityExternalKind[] =
  [
    // Addepar
    IntegrationEntityExternalKind.AddeparLegalEntity,
    IntegrationEntityExternalKind.AddeparHoldingAccount,

    // Black Diamond
    IntegrationEntityExternalKind.BlackDiamondAccount,
    IntegrationEntityExternalKind.BlackDiamondPortfolio,

    // Orion
    IntegrationEntityExternalKind.OrionAccount,
    IntegrationEntityExternalKind.OrionPortfolio,
  ];
