import { Box, Stack, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { useWatch } from 'react-hook-form';

import { Accordion } from '@/components/Accordion/Accordion';
import { Card } from '@/components/layout/Card/Card';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFormContext } from '@/components/react-hook-form';
import { DefaultLuminaryDisclaimer } from '@/modules/presentation/clientPresentation/DisclaimerSlide/DefaultLuminaryDisclaimer';
import { useDefaultDisclaimerSlideTitle } from '@/modules/presentation/clientPresentation/DisclaimerSlide/useDefaultDisclaimerSlideTitle';
import { PresentationSlideSecondaryHeading } from '@/modules/presentation/components/PresentationSlideSecondaryHeading';
import { PRESENTATION_SPACING_UNITS } from '@/modules/presentation/presentation.constants';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { COLORS } from '@/styles/tokens/colors';

import { LegalDisclaimersFormShape } from './LegalDisclaimersForm';

export function StandaloneDisclaimerPreview() {
  const theme = useTheme();
  const { control } = useFormContext<LegalDisclaimersFormShape>();
  const [
    presentationStandaloneDisclaimerTitle,
    presentationStandaloneDisclaimerBody,
    enableLuminaryDisclaimer,
  ] = useWatch({
    control,
    name: [
      'presentationStandaloneDisclaimerTitle',
      'presentationStandaloneDisclaimerBody',
      'enableLuminaryDisclaimer',
    ],
  });
  const defaultDisclaimerTitle = useDefaultDisclaimerSlideTitle();
  const title = presentationStandaloneDisclaimerTitle ?? defaultDisclaimerTitle;

  let body: ReactNode | undefined;
  if (presentationStandaloneDisclaimerBody) {
    body = presentationStandaloneDisclaimerBody;
  } else if (enableLuminaryDisclaimer) {
    body = <DefaultLuminaryDisclaimer />;
  }

  return (
    <Accordion title="Standalone disclaimer preview" variant="default">
      {body ? (
        <Card variant="outlined">
          <PresentationSlide.Slide
            id="preview-slide"
            leftHeaderContent={
              <PresentationSlide.MainHeading heading={title} />
            }
            rightHeaderContent={
              <PresentationSlideSecondaryHeading clientDisplayName="[Client name]" />
            }
            footer={<PresentationSlide.Footer hideDisclaimer />}
          >
            <Stack p={PRESENTATION_SPACING_UNITS} height="100%">
              <Box
                sx={{
                  columnCount: 2,
                  columnGap: theme.spacing(6),
                  columnRule: `1px solid ${COLORS.NEUTRAL_GRAY[900]}`,
                  height: 800, // height of a slide's body, necessary for the column content to wrap correctly,
                }}
              >
                <Typography
                  variant="body1"
                  // white-space: pre-wrap is necessary to preserve line breaks
                  sx={{ breakInside: 'avoid', whiteSpace: 'pre-wrap' }}
                >
                  {body}
                </Typography>
              </Box>
            </Stack>
          </PresentationSlide.Slide>
        </Card>
      ) : (
        <Callout severity="warning">
          No standalone disclaimer configured. Please add standalone disclaimer
          content or enable the Luminary disclaimer slide.
        </Callout>
      )}
    </Accordion>
  );
}
