import { compact } from 'lodash';

import { Copy07Icon } from '@/components/icons/Copy07Icon';
import { Settings01Icon } from '@/components/icons/Settings01Icon';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { COLORS } from '@/styles/tokens/colors';
import { PresentationBundleKind, PresentationPageKind } from '@/types/schema';

export interface ContextMenuItemsProps {
  bundleId?: string;
  onDuplicateBundle?: () => void;
  onManageBundle?: () => void;
  onDeleteBundle?: () => void;
  kind: PresentationBundleKind | PresentationPageKind;
}

interface ContextMenuItems {
  showDuplicate: boolean;
  showManage: boolean;
}

export const CONTEXT_MENU_ITEMS_MAP: Partial<
  Record<PresentationBundleKind | PresentationPageKind, ContextMenuItems>
> = {
  [PresentationBundleKind.WaterfallOverviewBundle]: {
    showDuplicate: true,
    showManage: true,
  },
  [PresentationBundleKind.EntitySummaryBundle]: {
    showDuplicate: true,
    showManage: true,
  },
  [PresentationBundleKind.BalanceSheetBundle]: {
    showDuplicate: false,
    showManage: false,
  },
  [PresentationBundleKind.EntitiesOverviewBundle]: {
    showDuplicate: false,
    showManage: false,
  },
  [PresentationBundleKind.ProfessionalTeamBundle]: {
    showDuplicate: false,
    showManage: false,
  },
  [PresentationBundleKind.StandaloneDisclaimerBundle]: {
    showDuplicate: false,
    showManage: false,
  },
  [PresentationBundleKind.TableOfContentsBundle]: {
    showDuplicate: false,
    showManage: false,
  },
};

function ContextMenuItems({
  bundleId,
  onDuplicateBundle,
  onManageBundle,
  onDeleteBundle,
  kind,
}: ContextMenuItemsProps) {
  const { showDuplicate, showManage } = CONTEXT_MENU_ITEMS_MAP[kind] ?? {
    showDuplicate: false,
    showManage: false,
  };

  const shouldShowDuplicate = showDuplicate && !!onDuplicateBundle;
  const shouldShowManage = showManage && !!onManageBundle;
  const shouldShowDelete = !!onDeleteBundle;

  return (
    <>
      {compact([
        shouldShowDuplicate ? (
          <MenuItem
            key={`${bundleId}-duplicate`}
            label="Duplicate"
            onClick={onDuplicateBundle}
            icon={<Copy07Icon size={16} />}
            muiMenuItemProps={{
              divider: !shouldShowManage && shouldShowDelete,
            }}
            typographyProps={{
              variant: 'subtitle2',
              color: COLORS.NAVY[900],
            }}
          />
        ) : null,
        shouldShowManage ? (
          <MenuItem
            key={`${bundleId}-manage`}
            label="Manage"
            onClick={onManageBundle}
            icon={<Settings01Icon size={16} />}
            muiMenuItemProps={{
              divider: shouldShowDelete,
            }}
            typographyProps={{
              variant: 'subtitle2',
              color: COLORS.NAVY[900],
            }}
          />
        ) : null,
        shouldShowDelete ? (
          <MenuItem
            key={`${bundleId}-delete`}
            label="Delete"
            onClick={onDeleteBundle}
            typographyProps={{
              sx: { color: COLORS.FUNCTIONAL.ERROR.DEFAULT },
              variant: 'subtitle2',
            }}
            icon={
              <Trash01Icon color={COLORS.FUNCTIONAL.ERROR.DEFAULT} size={16} />
            }
          />
        ) : null,
      ])}
    </>
  );
}

export const CONTEXT_MENU_MAP: Partial<
  Record<
    PresentationBundleKind | PresentationPageKind,
    ((props: ContextMenuItemsProps) => JSX.Element) | null
  >
> = {
  [PresentationBundleKind.CoverSlideBundle]: null,
  [PresentationBundleKind.TableOfContentsBundle]: ContextMenuItems,
  [PresentationBundleKind.WaterfallOverviewBundle]: ContextMenuItems,
  [PresentationBundleKind.EntitySummaryBundle]: ContextMenuItems,
  [PresentationBundleKind.BalanceSheetBundle]: ContextMenuItems,
  [PresentationBundleKind.EntitiesOverviewBundle]: ContextMenuItems,
  [PresentationBundleKind.ProfessionalTeamBundle]: ContextMenuItems,
  [PresentationBundleKind.StandaloneDisclaimerBundle]: ContextMenuItems,
};
