import React, { useEffect, useState } from 'react';

import { useRequiredParam } from '@/hooks/useRequiredParam';
import { HouseholdDetailsAwareRoute } from '@/modules/household/contexts/HouseholdDetailsAwareRoute';
import { ClientPresentationContent } from '@/modules/presentation/clientPresentation/ClientPresentationContent/ClientPresentationContent';
import { useGuardedClientPresentationDesignerContext } from '@/modules/presentation/clientPresentation/contexts/clientPresentationDesigner.context';
import { ClientPresentationDesignerProvider } from '@/modules/presentation/clientPresentation/contexts/ClientPresentationDesigner.provider';
import { useSubBrand } from '@/modules/tenant/TenantDetailsContext/useSubBrand';

import { PrintOnlyClientPresentationWatcher } from '../components/PrintClientPresentationShared';
import { usePrintOnlyClientPresentationDetails } from './PrintOnlyClientPresentation.hooks';

type DataType = ReturnType<typeof usePrintOnlyClientPresentationDetails>;

function PrintOnlyClientPresentationInner({
  entities: entitiesDetails,
  waterfalls: estateWaterfalls,
  client,
  allEntitiesOverview,
  professionalTeam,
  legalDisclaimers,
  balanceSheet,
  federalEstateTaxPercent,
  loading,
  visibleEntityPulids: initialVisibleEntityPulids,
  visibleWaterfallPulids: initialVisibleWaterfallPulids,
  sectionOrder,
  coverSlideDescription,
  coverSlideTitle,
  showCoverSlide,
}: DataType) {
  const [haveSetInitialValues, setHaveSetInitialValues] = useState(false);
  const { _setInitialItemVisibility } =
    useGuardedClientPresentationDesignerContext();

  const presentationId = useRequiredParam('presentationId');

  useEffect(() => {
    if (loading || haveSetInitialValues) return;
    _setInitialItemVisibility({
      shouldShowCoverSlide: showCoverSlide ?? false,
      visibleEntityPulids: initialVisibleEntityPulids,
      visibleWaterfallPulids: initialVisibleWaterfallPulids,
      sectionOrder: sectionOrder ?? [],
    });
    setHaveSetInitialValues(true);
  }, [
    loading,
    haveSetInitialValues,
    initialVisibleEntityPulids,
    initialVisibleWaterfallPulids,
    _setInitialItemVisibility,
    sectionOrder,
    showCoverSlide,
  ]);

  useSubBrand(client.subBrand?.id ?? null);

  return (
    <ClientPresentationContent
      coverSlideDetails={{
        id: presentationId,
        coverSlideDescription,
        coverSlideTitle,
      }}
      balanceSheet={balanceSheet}
      client={client}
      entities={allEntitiesOverview}
      legalDisclaimers={legalDisclaimers}
      estateWaterfalls={estateWaterfalls}
      entitiesDetails={entitiesDetails}
      professionalTeam={professionalTeam}
      federalEstateTaxPercent={federalEstateTaxPercent}
    />
  );
}

export const PrintOnlyClientPresentation = () => {
  const householdId = useRequiredParam('householdId');

  return (
    <HouseholdDetailsAwareRoute householdId={householdId}>
      <ClientPresentationDesignerProvider>
        <PrintOnlyClientPresentationWatcher<DataType>
          Root={PrintOnlyClientPresentationInner}
          useQuery={usePrintOnlyClientPresentationDetails}
        />
      </ClientPresentationDesignerProvider>
    </HouseholdDetailsAwareRoute>
  );
};
