import { Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';

import { FileSearch02Icon } from '@/components/icons/FileSearch02Icon';
import { ActionCard } from '@/components/layout/ActionCard/ActionCard';
import { COLORS } from '@/styles/tokens/colors';
import { PulidKind } from '@/utils/pulid';

import { DocumentUploadButton } from '../DocumentUploadButton/DocumentUploadButton';
import { useHouseholdDocumentsViewerContext } from './context/HouseholdDocumentsViewer.context';
import { DocumentSelectorMenu } from './DocumentSelectorMenu';
import { getAdditionalDetailsForPulidKind } from './HouseholdDocumentsViewer.utils';

function getEmptyCopyForPulidKind(kind: PulidKind | null) {
  switch (kind) {
    case PulidKind.Entity:
      return 'Any document associated with this entity will display here';
    case PulidKind.TestamentaryEntity:
      return 'Any document associated with an entity that distributes into this testamentary entity will display here';
    case PulidKind.ClientProfile:
      return 'Wills and operating agreements for the client will display here';
    default:
      return 'You can upload documents and associate them with entities';
  }
}

export function DocumentEmptyState() {
  const {
    targetId,
    targetKind,
    householdId,
    refetch,
    setNewlyUploadedDocumentId,
    updateActiveDocumentId,
    targetDocuments,
    otherHouseholdDocuments,
  } = useHouseholdDocumentsViewerContext();

  const handleModalClose = useCallback(
    (documentId?: string) => {
      refetch();
      if (documentId) {
        setNewlyUploadedDocumentId(documentId);
        updateActiveDocumentId({
          documentId,
          shouldClearSearchResults: true,
        });
      }
    },
    [refetch, setNewlyUploadedDocumentId, updateActiveDocumentId]
  );

  const documentsToRender = (() => {
    if (targetDocuments && targetDocuments.length > 0) {
      return targetDocuments;
    }

    if (otherHouseholdDocuments && otherHouseholdDocuments.length > 0) {
      return otherHouseholdDocuments;
    }
    return [];
  })();

  const hasDocumentsToRender = !isEmpty(documentsToRender);
  return (
    <Stack
      data-name="EntityDocumentEmptyState"
      justifyContent="center"
      height="100%"
      width="100%"
      p={6}
    >
      <ActionCard
        variant="noCard"
        heading="Reference a document"
        description={getEmptyCopyForPulidKind(targetKind)}
        sx={{
          backgroundColor: 'transparent !important',
        }}
        icon={<FileSearch02Icon size={40} sx={{ color: COLORS.GRAY[300] }} />}
        actions={
          householdId && (
            <Stack spacing={2} direction="row">
              <DocumentUploadButton
                entityId={
                  targetKind === PulidKind.Entity ? targetId : undefined
                }
                householdId={householdId}
                onModalClose={handleModalClose}
                additionalUploadDetails={getAdditionalDetailsForPulidKind(
                  targetKind
                )}
              />
              {hasDocumentsToRender && (
                <DocumentSelectorMenu
                  documents={documentsToRender.map((doc) => ({
                    id: doc.id,
                    name: doc.name,
                  }))}
                  onDocumentSelect={(documentId) =>
                    updateActiveDocumentId({
                      documentId,
                      shouldClearSearchResults: true,
                    })
                  }
                />
              )}
            </Stack>
          )
        }
      />
    </Stack>
  );
}
