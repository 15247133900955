import { Box, Typography } from '@mui/material';

import { LuminaryAIBannerCard } from '@/components/layout/LuminaryAIBannerCard';
import { EmptyAIDPStatusBanner } from '@/modules/aiDP/components/EmptyAIDPStatusBanner';
import {
  TriggerAIDPSuggestionsBanner,
  TriggerDPAISuggestionsVariant,
} from '@/modules/aiDP/components/TriggerAIDPSuggestionsBanner/TriggerAIDPSuggestionsBanner';
import { useEntityDPSuggestionsContext } from '@/modules/aiDP/entity/context/entityDPSuggestions.context';
import { useHasEntityTriggeredAIDPWorkflow } from '@/modules/aiDP/hooks/useHasEntityTriggeredAIDPWorkflow';
import { AISuggestionsStatusBanner } from '@/modules/aiSuggestions/components/AISuggestionsStatusBanner';
import { useHasBeenReviewed } from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/hooks/utilityHooks';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { AsyncJobKind, KgRootStatus } from '@/types/schema';

export function EntityFormAIDPStatusBanner() {
  const aiDpEnabled = useFeatureFlag('ai_dispositive_provisions');

  const { entityId } = useEntityDetailsContext();
  const hasBeenReviewed = useHasBeenReviewed();
  const { hasUnacknowledgedRecipientSuggestions, kgRoot, hasSuggestions } =
    useEntityDPSuggestionsContext();

  const { shouldHideTriggerBanner, handleStartWorkflowSuccess } =
    useHasEntityTriggeredAIDPWorkflow();

  if (!aiDpEnabled) return null;

  return (
    <Box mb={3}>
      <AISuggestionsStatusBanner trackedAsyncJobKind={AsyncJobKind.AiDp} />
      {!shouldHideTriggerBanner && entityId && (
        <TriggerAIDPSuggestionsBanner
          variant={TriggerDPAISuggestionsVariant.SINGLE_ENTITY}
          onStartWorkflowSuccess={handleStartWorkflowSuccess}
          entityId={entityId}
        />
      )}
      {hasUnacknowledgedRecipientSuggestions && !hasBeenReviewed && (
        <LuminaryAIBannerCard
          bannerText={
            <Typography variant="label">
              Specify when distributions occur to view recipients identified by
              AI
            </Typography>
          }
        />
      )}
      {kgRoot?.id &&
        kgRoot?.status === KgRootStatus.Complete &&
        !hasSuggestions && <EmptyAIDPStatusBanner kgRootId={kgRoot?.id} />}
    </Box>
  );
}
