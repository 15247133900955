import {
  BASIC_ASSETS_INITIAL_FUNDING_SUBFORM,
  BASIC_ASSETS_SUBFORM,
  defaultValues as basicAssetsDefaultValues,
  defaultValues_initialFunding as basicAssetsInitialFundingDefaultValues,
} from '../../BasicAssetsSubform/BasicAssetsSubform.types';
import {
  BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE as BUSINESS_ENTITY_DETAILS_SUBFORM,
  defaultValues as businessEntityFormDefaultValues,
} from '../../BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.types';
import { BUSINESS_ENTITY_TYPES, ENTITY_TYPES } from '../../entities.constants';
import { defaultStateTaxSubformForm } from '../../TaxStatusSubform/TaxStatusSubform.utils';
import {
  CREATE_ENTITY_SHORT_FORM_NAMESPACE,
  EntityShortFormShape,
} from './CreateEntityShortForm.types';

export const defaultValues: EntityShortFormShape = {
  [CREATE_ENTITY_SHORT_FORM_NAMESPACE]: {
    name: '',
    entityType: '',
    principals: {},
    selectedSinglePrincipal: '',
    _ownershipSum: null,
    estateInclusionStatus: 'in-estate',
    stateTax: defaultStateTaxSubformForm,
    defaultDocumentId: undefined,
  },
  [BASIC_ASSETS_SUBFORM]: {
    ...basicAssetsDefaultValues,
    basicAssets: Object.assign({}, basicAssetsDefaultValues.basicAssets, {
      dateOfValuation: new Date(),
    }),
  },
  [BASIC_ASSETS_INITIAL_FUNDING_SUBFORM]:
    basicAssetsInitialFundingDefaultValues,
  [BUSINESS_ENTITY_DETAILS_SUBFORM]: Object.assign(
    {},
    businessEntityFormDefaultValues,
    {
      ownershipAsOfDate: new Date(),
    }
  ),
};

export const OWNERSHIP_PERCENTAGE_ENTITY_TYPES = [
  ...BUSINESS_ENTITY_TYPES,
  ENTITY_TYPES.QPRT,
];
