import { css, cx } from '@emotion/css';
import {
  BaseEdge,
  Edge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
  SmoothStepEdge,
} from '@xyflow/react';
import { ReactNode, useMemo } from 'react';

import { COLORS } from '@/styles/tokens/colors';
import { isReactNode } from '@/utils/reactUtils';

import {
  EdgeLabel as EdgeLabelExternal,
  EdgeLabelProps,
} from '../../components/EdgeLabel';
import { useIsConnectedNodeDragging } from '../hooks/useIsConnectedNodeDragging';
import { useMarkerId } from '../hooks/useMarkerId';
import { MarkerTypeToProps } from '../types';

const styles = {
  labelContainer: css({
    // everything inside EdgeLabelRenderer has no pointer events by default
    // if you have an interactive element, set pointer-events: all
    // https://reactflow.dev/api-reference/components/edge-label-renderer#notes
    // https://github.com/xyflow/xyflow/issues/3303
    pointerEvents: 'all',
    position: 'absolute',
    cursor: 'default',
  }),
};

// Arrow edge variants are the colors of the line
export type ArrowEdgeVariant =
  | 'primary' // gray
  | 'secondary' // yellow
  | 'destructive' // red
  | 'accent'; // green

// Arrow edge style variants are the non-color styles of the arrow
export type ArrowEdgeStyleVariant = 'solid' | 'dashed';

export interface ArrowProps extends Edge {
  data: {
    variant?: ArrowEdgeVariant;
    style?: ArrowEdgeStyleVariant;
    hideLabel?: boolean;
    hideArrow?: boolean;
    /**
     * By default, the arrow is only shown on the destination.
     * If this is true, the arrow will be shown pointing back to the source as well.
     * This is useful for e.g. showing a loan being paid back.
     */
    showFromArrow?: boolean;
    edgeLabel?: EdgeLabelProps | ReactNode;
    highlight?: boolean;
    /**
     * By default, the edges will break halfway along their y travel.
     * If centerFactor is provided, the edge will break in accordance with the
     * centerFactor ratio. 0.5 is the default, 0.8 is closer to the target node,
     * 0.2 is closer to the source node.
     */
    centerFactor?: number;
  };
}

export type ArrowEdgeProps = EdgeProps<ArrowProps>;

// TODO: Need a better way of theming this since props need to come from parent in useEdges.ts,
// see useEdges.ts for more context
export const ARROW_EDGE_COLORS_BY_VARIANT: Record<ArrowEdgeVariant, string> = {
  primary: COLORS.GRAY[400],
  secondary: COLORS.FUNCTIONAL.WARNING[400],
  destructive: COLORS.ORANGE[400],
  accent: COLORS.FUNCTIONAL.SUCCESS[600],
};

export const ARROW_EDGE_COLORS_BY_VARIANT_DRAGGING_OR_HIGHLIGHT: Record<
  ArrowEdgeVariant,
  string
> = {
  primary: COLORS.GRAY[700],
  secondary: COLORS.FUNCTIONAL.WARNING[700],
  destructive: COLORS.ORANGE[700],
  accent: COLORS.FUNCTIONAL.SUCCESS[800],
};

export function useArrowEdgeColor({
  source,
  target,
  data = {},
}: ArrowEdgeProps) {
  const { variant = 'primary', highlight = false } = data;
  const { dragging } = useIsConnectedNodeDragging({ source, target });
  return dragging || highlight
    ? ARROW_EDGE_COLORS_BY_VARIANT_DRAGGING_OR_HIGHLIGHT[variant]
    : ARROW_EDGE_COLORS_BY_VARIANT[variant];
}

export function ArrowEdge(props: ArrowEdgeProps) {
  const { style: externalStyle, data = {}, ...rest } = props;
  const {
    hideLabel,
    hideArrow,
    edgeLabel,
    highlight,
    showFromArrow,
    style: edgeStyle = 'solid',
    centerFactor,
  } = data;
  const color = useArrowEdgeColor(props);

  const style: ArrowEdgeProps['style'] = useMemo(() => {
    return {
      stroke: color,
      strokeWidth: highlight ? '3px' : '2px',
      strokeDasharray: edgeStyle === 'dashed' ? '5 5' : undefined,
      ...externalStyle,
    };
  }, [color, edgeStyle, externalStyle, highlight]);

  const centerY = useMemo(() => {
    if (!centerFactor) {
      return undefined;
    }

    const heightOfYTravel = props.targetY - props.sourceY;

    // Controls how close to the target node the edge breaks
    const centerY = props.sourceY + heightOfYTravel * centerFactor;

    return centerY;
  }, [centerFactor, props.sourceY, props.targetY]);

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    ...props,
    centerY,
    borderRadius: 16,
  });

  const markerProps = useMemo<MarkerTypeToProps['arrow']>(
    () => ({ color }),
    [color]
  );
  const destinationMarkerId = useMarkerId('arrow', markerProps);
  const sourceMarkerId = useMarkerId('arrow', {
    ...markerProps,
    direction: 'reverse',
  });
  let EdgeLabelComponent: ReactNode = null;
  if (isReactNode(edgeLabel)) {
    EdgeLabelComponent = edgeLabel;
  } else if (edgeLabel?.label || edgeLabel?.label === '') {
    // accept an empty string so the value can be shown sans label
    EdgeLabelComponent = (
      <EdgeLabelExternal {...edgeLabel} highlight={highlight} />
    );
  }

  const EdgeLabel = useMemo(() => {
    if (!EdgeLabelComponent) {
      return null;
    }

    return (
      <EdgeLabelRenderer>
        <div
          className={cx(styles.labelContainer, 'nodrag', 'nopan')}
          style={{
            opacity: hideLabel ? 0 : 1,
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
        >
          {EdgeLabelComponent}
        </div>
      </EdgeLabelRenderer>
    );
  }, [EdgeLabelComponent, hideLabel, labelX, labelY]);

  const commonProps = useMemo(() => {
    return {
      ...rest,
      style,
      markerEnd: hideArrow ? undefined : destinationMarkerId,
      markerStart: showFromArrow && !hideArrow ? sourceMarkerId : undefined,
      /**
       * Fixes: https://linear.app/luminary/issue/T1-521
       * It's unclear why, but starting at Chrome v118 this is needed.
       * Internally, SmoothStepEdge is memoized in reactflow but was not an issue in v117.
       * We should try removing this when Chrome, React, or ReactFlow are upgraded
       */
      key: Math.random(),
    };
  }, [
    rest,
    style,
    hideArrow,
    destinationMarkerId,
    showFromArrow,
    sourceMarkerId,
  ]);

  // Render a custom path if we provide a centerFactor
  if (centerFactor) {
    return (
      <>
        <BaseEdge {...commonProps} path={edgePath} />
        {EdgeLabel}
      </>
    );
  }

  return (
    <>
      <SmoothStepEdge {...commonProps} pathOptions={{ borderRadius: 16 }} />
      {EdgeLabel}
    </>
  );
}
