import { Stack } from '@mui/material';
import { first } from 'lodash';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import { FullScreenModal } from '@/components/modals/FullScreenModal/FullScreenModal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { LoadingOverlay } from '@/components/progress/LoadingOverlay/LoadingOverlay';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { useLoggedTransferWhereInput } from '@/modules/beneficiaryReporting/hooks/useBeneficiariesData';
import { CustomerThemeProvider } from '@/styles/themes/CustomerThemeProvider';
import { zIndexes } from '@/styles/zIndexes';
import { getNodes } from '@/utils/graphqlUtils';

import { TenantPresentationConfigurationProvider } from '../../context/TenantPresentationConfiguration.provider';
import {
  ClientPresentationDesignerV2Header,
  ClientPresentationDesignerV2SlideDisplay,
} from './ClientPresentationDesignerV2.components';
import {
  ClientPresentationDesignerV2Provider,
  ClientPresentationDesignerV2ViewMode,
} from './ClientPresentationDesignerV2.context';
import { usePersistPresentation } from './ClientPresentationDesignerV2.hooks';
import { ClientPresentationV2Shape } from './ClientPresentationDesignerV2.types';
import { mapClientPresentationV2ToForm } from './ClientPresentationDesignerV2.utils';
import { ClientPresentationV2ModalContextProvider } from './ClientPresentationV2ModalContext';
import { ClientPresentationDesignerV2TreeView } from './components/ClientPresentationDesignerV2TreeView/ClientPresentationDesignerV2TreeView';
import { useClientPresentationDesignerV2Query } from './graphql/ClientPresentationDesignerV2.generated';

function ClientPresentationDesignerV2Inner({
  loading: initialLoading,
}: {
  loading: boolean;
}) {
  const { isPersistRunning } = usePersistPresentation();
  return (
    <>
      <LoadingOverlay
        open={initialLoading || isPersistRunning}
        sx={{
          zIndex: zIndexes.MODAL + 1, // need to raise the z-index above FullScreenModal's 400
        }}
      />
      <FullScreenModal isOpen>
        <Stack
          direction="column"
          sx={{
            height: '100vh',
            width: '100vw',
            overflowX: 'hidden',
            overflowY: 'hidden',
          }}
        >
          <ClientPresentationDesignerV2Header />
          <Stack direction="row" sx={{ overflowY: 'hidden' }}>
            <ClientPresentationDesignerV2TreeView />
            <CustomerThemeProvider>
              <ClientPresentationDesignerV2SlideDisplay />
            </CustomerThemeProvider>
          </Stack>
        </Stack>
      </FullScreenModal>
    </>
  );
}

export interface ClientPresentationDesignerV2Props {
  householdId: string;
  presentationId: string;
}
export function ClientPresentationDesignerV2({
  householdId,
  presentationId,
}: ClientPresentationDesignerV2Props) {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const formMethods = useForm<ClientPresentationV2Shape>({
    defaultValues: {
      id: undefined,
      bundles: [],
      title: null,
    },
  });
  const loggedTransferWhereInput = useLoggedTransferWhereInput();
  const { reset } = formMethods;
  const { data, loading, error } = useClientPresentationDesignerV2Query({
    fetchPolicy: 'no-cache', // cache normalization breaks across the various page types
    variables: {
      presentationId,
      householdId,
      loggedTransferWhereInput,
      presentationOnly: false,
    },
  });

  useEffect(() => {
    if (loading) return;
    if (error) {
      reportError(
        `Caught error when loading presentation [${presentationId}]`,
        error
      );
      showFeedback('Failed to load presentation; please try again');
      return;
    }
    const presentation = first(getNodes(data?.clientPresentationV2s));
    if (presentation) {
      reset(mapClientPresentationV2ToForm(presentation));
    } else {
      reportError(
        `No presentation found for [${presentationId}]`,
        new Error('No presentation found')
      );
      showFeedback('Failed to load presentation; please try again');
    }
  }, [
    data?.clientPresentationV2s,
    error,
    reportError,
    reset,
    showFeedback,
    presentationId,
    loading,
  ]);

  if (loading) {
    return <LoadingOverlay open />;
  }

  // This is a placeholder landing page for now
  return (
    <TenantPresentationConfigurationProvider>
      <ClientPresentationDesignerV2Provider
        householdId={householdId}
        presentationId={presentationId}
        viewMode={ClientPresentationDesignerV2ViewMode.Designer}
        queryData={data}
      >
        <FormProvider {...formMethods}>
          <ClientPresentationV2ModalContextProvider>
            <ClientPresentationDesignerV2Inner loading={loading} />
          </ClientPresentationV2ModalContextProvider>
        </FormProvider>
      </ClientPresentationDesignerV2Provider>
    </TenantPresentationConfigurationProvider>
  );
}
