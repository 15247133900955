import { css, cx } from '@emotion/css';
import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { WiggleProps } from '@/components/animation/Wiggle/Wiggle';
import { WithClasses } from '@/styles/types';
import { compactReactChildren } from '@/utils/reactUtils';

import { Tile, TileProps } from '../Tile/Tile';
import { TileVariant } from './types';

const classes: TileProps['classes'] = {
  lineOne: css({
    textAlign: 'center',
  }),
  lineTwo: css({
    textAlign: 'center',
  }),
  wrapper: css({
    height: '100%',
    cursor: 'pointer',
  }),
  linesWrapper: css({
    width: '100%',
  }),
  innerRoot: css({
    padding: `0 !important`,
    '~ *': {
      borderTop: 'none !important',
    },
  }),
};

const wiggleClasses: WiggleProps['classes'] = {
  root: css({
    height: '100%',
  }),
};

const useStyles = makeStyles()((_theme) => ({
  // eslint-disable-next-line tss-unused-classes/unused-classes -- lint rule doesn't work with extended classes object
  childrenWrapper: {
    width: '100%',
    height: '100%',
    padding: 16,
    borderRadius: 4,
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 4,
    background: 'rgba(255, 255, 255, 0.60)',
  },
}));

export type TileSummaryProps = TileProps & {
  classes?: TileProps['classes'] & WithClasses<typeof useStyles>;
  onClick?: () => void;
};

export const TileSummary = ({
  children,
  onClick,
  variant = TileVariant.Default,
  ...props
}: TileSummaryProps) => {
  const { classes: tileSummaryClasses } = useStyles(undefined, { props });
  const compactChildren = compactReactChildren(children);

  const rootClass = useMemo(() => {
    const baseStyles: Parameters<typeof css>[0] = {
      borderLeft: 'none !important',
      padding: '16px !important',
      gap: '16px',
    };

    if (!compactChildren) return css({ ...baseStyles });
    /**
     * For some reason styles are loading in out of order with the classes / useStyles pattern
     * so the important is needed.
     * Unclear if this was a library upgrade or something else.
     * @todo: https://linear.app/luminary/issue/T1-1142/classes-pattern-not-applying-styles-in-correct-order
     */
    // @ts-ignore -- see above
    return css({
      ...baseStyles,
      display: 'grid !important',
      gridTemplateColumns: '1fr 2fr',
      alignItems: 'center',
      height: '100%',
    });
  }, [compactChildren]);

  return (
    <Box onClick={onClick} display="flex" alignSelf="stretch">
      <Tile
        {...props}
        variant={variant}
        width={compactChildren ? 430 : undefined}
        classes={{
          ...props.classes,
          root: cx(rootClass, props.classes?.root),
          lineOne: cx(classes.lineOne, props.classes?.lineOne),
          lineTwo: cx(classes.lineTwo, props.classes?.lineTwo),
          linesWrapper: cx(classes.linesWrapper, props.classes?.linesWrapper),
          wrapper: cx(classes.wrapper, props.classes?.wrapper),
          innerRoot: cx(classes.innerRoot, props.classes?.innerRoot),
        }}
        wiggleClasses={wiggleClasses}
      >
        {compactChildren && (
          <div className={cx(tileSummaryClasses.childrenWrapper)}>
            {compactChildren}
          </div>
        )}
      </Tile>
    </Box>
  );
};
