import { first, some } from 'lodash';

// eslint-disable-next-line luminary-rules/no-page-imports
import { ManageDispositions_EntityFragment } from '@/pages/ClientDetailsPage/ClientDetailsManageDispositionsPage/graphql/ManageDispositionsPage.generated';
import { AiSuggestionKind, KgRootStatus } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

/**
 * Determines if an entity has unacknowledged AI DP suggestions by traversing
 * the input entity -> async job -> kgroot -> ai suggestions
 * @param entity
 */
export function doesEntityHaveUnacknowledgedAIDPSuggestions(
  entity: ManageDispositions_EntityFragment
) {
  // Get the previous AI DP job
  const job = first(getNodes(entity?.prevAIDPJobs));
  if (!job) {
    return false;
  }

  // Check if any of the roots is in "Complete" status AND has any unacknowledged (null) suggestions
  return (job.kgRoot || []).some((root) => {
    if (root.status !== KgRootStatus.Complete) {
      return false;
    }
    return some(
      root.aiSuggestions,
      (suggestion) =>
        suggestion.acceptanceStatus === null &&
        (suggestion.kind === AiSuggestionKind.DpRecipientGrantor_1DiesFirst ||
          suggestion.kind === AiSuggestionKind.DpRecipientGrantor_2DiesFirst)
    );
  });
}
