import { makeVar } from '@apollo/client';

import { DispositionsDiagram_EntityFragment } from '@/modules/entityDiagram/graphql/DispositionsDiagram.generated';
import { FeatureFlagMap } from '@/modules/featureFlags/featureFlags.types';
import { EstateWaterfall, User } from '@/types/schema';

export const mostRecentlyViewedWaterfallVar =
  makeVar<User['localMostRecentlyViewedWaterfall']>(null);

export const mostRecentlyViewedEntityMapVar =
  makeVar<User['localMostRecentlyViewedEntityMap']>(null);

export const mostRecentlyViewedHouseholdsVar = makeVar<
  { id: string; displayName: string }[]
>([]);

export const deletedHouseholdIdsVar = makeVar<string[]>([]);
export const deletedEntityIdsVar = makeVar<string[]>([]);

export const showWaterfallSummaryTileDetailsVar = makeVar<
  EstateWaterfall['localShowSummaryTileDetails'] | null
>(null);

export const featureFlagsVar = makeVar<FeatureFlagMap>({});

// Store the entityId and entity data in a tuple so we
// can identify the correct entity data for a given entityId
export const entityDiagramEntityVar = makeVar<
  [string | null, DispositionsDiagram_EntityFragment | null]
>([null, null]);
