import { Box, Stack } from '@mui/material';
import { ComponentProps } from 'react';

import {
  Logomark,
  LogomarkSize,
  LogomarkVariant,
} from '@/components/brand/Logomark/Logomark';
import { Footer } from '@/modules/presentation/PresentationSlide/accessoryComponents';
import {
  DisclaimerFooter,
  DisclaimerFooterProps,
} from '@/modules/presentation/PresentationSlide/DisclaimerFooter';
import { COLORS } from '@/styles/tokens/colors';

import {
  useClientPresentationDesignerV2Context,
  usePageNumber,
} from '../ClientPresentationDesignerV2.context';

export interface ClientPresentationV2FooterProps
  extends ComponentProps<typeof Footer> {
  slideId: string;
  hidePageNumber?: boolean;
  hideLogo?: boolean;
  variant?: DisclaimerFooterProps['variant'];
}

export function ClientPresentationV2Footer({
  hideDisclaimer,
  hidePageNumber,
  hideLogo,
  slideId,
  variant = 'standard',
}: ClientPresentationV2FooterProps) {
  const { presentationConfiguration } =
    useClientPresentationDesignerV2Context();

  const shouldDisplayLogo = !hideLogo;
  const shouldDisplayDisclaimer =
    !hideDisclaimer && presentationConfiguration?.showLegalDisclaimer;
  const shouldDisplayPageNumbers =
    !hidePageNumber && presentationConfiguration?.showPageNumbers;

  const pageNumber = usePageNumber(slideId);

  // don't display anything on the cover if the page number & disclaimer are hidden,
  // otherwise the footer will just be a horizontal line with nothing below it
  if (
    variant === 'cover' &&
    !shouldDisplayPageNumbers &&
    !shouldDisplayDisclaimer
  ) {
    return null;
  }

  return (
    <Box sx={{ px: 2 }}>
      <Stack
        sx={{ borderTop: `solid ${COLORS.GRAY[200]} 1px`, pt: 1, px: 1 }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          {shouldDisplayLogo && (
            <Logomark
              style={{ maxHeight: 60, width: 'auto' }}
              size={LogomarkSize.Wide}
              variant={LogomarkVariant.Primary}
            />
          )}
          {shouldDisplayDisclaimer && (
            <Box sx={{ maxWidth: 700 }}>
              <DisclaimerFooter variant={variant} />
            </Box>
          )}
        </Stack>
        {shouldDisplayPageNumbers && <Box>{pageNumber}</Box>}
      </Stack>
    </Box>
  );
}
