import { Box, BoxProps, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { first } from 'lodash';
import { ComponentType, useMemo } from 'react';

import { useWaterfallGrantorDeathDisplay } from '@/hooks/useWaterfallGrantorDeathDisplay';
import { PresentationSlideSecondaryHeading } from '@/modules/presentation/components/PresentationSlideSecondaryHeading';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { TaxOverviewTable } from '@/modules/summaryPanels/TaxOverviewTable/TaxOverviewTable';
import { TaxOverviewTableRowData } from '@/modules/summaryPanels/TaxOverviewTable/TaxOverviewTable.types';
import { COLORS } from '@/styles/tokens/colors';
import { AfterDeath, PresentationBundleKind } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import {
  useBundleWaterfall,
  useClientPresentationDesignerV2Context,
  useRegisterSlide,
} from '../../../../ClientPresentationDesignerV2.context';
import { useIsDesignMode } from '../../../../ClientPresentationDesignerV2.hooks';
import { ClientPresentationDesignerV2_EstateWaterfallFragment } from '../../../../graphql/ClientPresentationDesignerV2.generated';
import { ClientPresentationV2Page } from '../../../../types/ClientPresentationV2.PresentationPageType';
import { ClientPresentationV2Footer } from '../../../ClientPresentationV2Footer';
import { BaseBundleSlideProps } from '../../BundleSlide.types';
import { getTaxSummaryTablePages } from './EstateWaterfallTaxOverviewSlide.utils';

interface EstateWaterfallTaxTableSheetProps
  extends EstateWaterfallTaxOverviewSlideProps {
  waterfall: ClientPresentationDesignerV2_EstateWaterfallFragment;
  afterDeath: AfterDeath;
  deathYear: number | null | undefined;
  dyingGrantorName: string | null | undefined;
  pageRows: TaxOverviewTableRowData[];
  index: number;
  SlideWrapper: ComponentType<BoxProps>;
  federalEstateTaxPercent: Decimal | null | undefined;
  tableId: string;
  isLastPage: boolean;
  isContinued: boolean;
}

function EstateWaterfallTaxOverviewSheet({
  bundle,
  afterDeath,
  pageRows,
  page,
  index,
  SlideWrapper,
  waterfall,
  deathYear,
  dyingGrantorName,
  tableId,
  isLastPage,
  isContinued,
  isVisible,
}: EstateWaterfallTaxTableSheetProps) {
  const rightHeaderContent = `${afterDeath === AfterDeath.None ? 'Before' : 'After'} ${dyingGrantorName}'s death${index > 0 ? ' (cont.)' : ''}`;
  const rows = useMemo(
    () =>
      pageRows.map((pageRow, index) => {
        // if a subsection breaks across pages, add the `(cont.)` suffix to the first row of the next page
        if (isContinued && index === 0) {
          return {
            ...pageRow,
            name: {
              ...pageRow.name,
              lineOne: `${pageRow.name.lineOne} (cont.)`,
            },
          };
        }
        return pageRow;
      }),
    [isContinued, pageRows]
  );
  const tableHeaders = useWaterfallGrantorDeathDisplay(waterfall);
  const slideId = `estate-waterfall-${bundle.id}-tax-overview-${page.id}-after-death-${afterDeath}-${index}`;
  useRegisterSlide({
    slideId,
    title: bundle.displayName,
    includeInToC: page.id === first(bundle.pages)?.id && index === 0,
    bundleKind: PresentationBundleKind.WaterfallOverviewBundle,
  });
  if (!isVisible) {
    return null;
  }
  return (
    <SlideWrapper key={tableId}>
      <PresentationSlide.Slide
        id={slideId}
        leftHeaderContent={
          <PresentationSlide.MainHeading heading={waterfall.displayName} />
        }
        rightHeaderContent={
          <PresentationSlideSecondaryHeading
            clientDisplayName={waterfall.household?.displayName ?? null}
          />
        }
        footer={<ClientPresentationV2Footer slideId={slideId} />}
      >
        <Stack px={3} spacing={3}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2" component="span">
              Tax Overview
            </Typography>
            <Typography
              variant="h2"
              component="span"
              sx={{ color: COLORS.GRAY[400] }}
            >
              {rightHeaderContent}
            </Typography>
          </Stack>
          <TaxOverviewTable
            afterDeath={afterDeath}
            rows={rows}
            deathYear={deathYear}
            isTwoGrantorHousehold={false}
            waterfall={waterfall}
            headerCopy={tableHeaders[afterDeath]}
          />
          {!isLastPage && (
            <Stack direction="row" width="100%" justifyContent="flex-end">
              <Typography variant="subtitle2">
                Continued on next page
              </Typography>
            </Stack>
          )}
        </Stack>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}

interface EstateWaterfallTaxOverviewSlideInnerProps
  extends EstateWaterfallTaxOverviewSlideProps {
  waterfall: ClientPresentationDesignerV2_EstateWaterfallFragment;
}

function EstateWaterfallTaxOverviewSlideInner({
  waterfall,
  federalEstateTaxPercent,
  SlideWrapper = Box,
  bundle,
  page,
  isVisible,
}: EstateWaterfallTaxOverviewSlideInnerProps) {
  /*
  useRegisterSlide({
    displayName: 'Tax Overview',
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });
  */

  const {
    beforeFirstDeathPages,
    afterFirstDeathPages,
    afterSecondDeathPages,
    beforeFirstDeathContinuedPageIndices,
    afterFirstDeathContinuedPageIndices,
    afterSecondDeathContinuedPageIndices,
  } = useMemo(() => {
    return getTaxSummaryTablePages(waterfall, federalEstateTaxPercent);
  }, [federalEstateTaxPercent, waterfall]);

  const firstGrantorDeathName =
    waterfall.household?.possiblePrimaryClients.find(
      (client) => client.id === waterfall.firstGrantorDeath.id
    )?.displayName || 'First';

  const secondGrantorDeathName =
    waterfall.household?.possiblePrimaryClients.find(
      (client) => client.id !== waterfall.firstGrantorDeath.id
    )?.displayName || 'Second';

  return (
    <>
      {beforeFirstDeathPages.map((pageRows, index) => (
        <EstateWaterfallTaxOverviewSheet
          key={`before-first-death-${index}`}
          tableId={`before-first-death-${index}`}
          afterDeath={AfterDeath.None}
          bundle={bundle}
          page={page}
          pageRows={pageRows}
          index={index}
          SlideWrapper={SlideWrapper}
          waterfall={waterfall}
          deathYear={waterfall.firstGrantorDeathYear}
          dyingGrantorName={firstGrantorDeathName}
          federalEstateTaxPercent={federalEstateTaxPercent}
          isLastPage={index === beforeFirstDeathPages.length - 1}
          isContinued={beforeFirstDeathContinuedPageIndices.includes(index)}
          isVisible={isVisible}
        />
      ))}
      {afterFirstDeathPages.map((pageRows, index) => (
        <EstateWaterfallTaxOverviewSheet
          key={`after-first-death-${index}`}
          tableId={`after-first-death-${index}`}
          afterDeath={AfterDeath.First}
          bundle={bundle}
          page={page}
          pageRows={pageRows}
          index={index}
          SlideWrapper={SlideWrapper}
          waterfall={waterfall}
          deathYear={waterfall.firstGrantorDeathYear}
          dyingGrantorName={firstGrantorDeathName}
          federalEstateTaxPercent={federalEstateTaxPercent}
          isLastPage={index === afterFirstDeathPages.length - 1}
          isContinued={afterFirstDeathContinuedPageIndices.includes(index)}
          isVisible={isVisible}
        />
      ))}
      {afterSecondDeathPages.map((pageRows, index) => (
        <EstateWaterfallTaxOverviewSheet
          key={`after-second-death-${index}`}
          tableId={`after-second-death-${index}`}
          afterDeath={AfterDeath.Second}
          bundle={bundle}
          page={page}
          pageRows={pageRows}
          index={index}
          SlideWrapper={SlideWrapper}
          waterfall={waterfall}
          deathYear={waterfall.secondGrantorDeathYear}
          dyingGrantorName={secondGrantorDeathName}
          federalEstateTaxPercent={federalEstateTaxPercent}
          isLastPage={index === afterSecondDeathPages.length - 1}
          isContinued={afterSecondDeathContinuedPageIndices.includes(index)}
          isVisible={isVisible}
        />
      ))}
    </>
  );
}

export interface EstateWaterfallTaxOverviewSlideProps
  extends BaseBundleSlideProps {
  page: ClientPresentationV2Page;
  federalEstateTaxPercent: Decimal | null | undefined;
}

export function EstateWaterfallTaxOverviewSlide(
  props: EstateWaterfallTaxOverviewSlideProps
) {
  const waterfall = useBundleWaterfall(props.bundle.id);
  const { isPersistRunning } = useClientPresentationDesignerV2Context();
  const isDesignMode = useIsDesignMode();
  const showError = !isPersistRunning && props.isVisible;

  if (!waterfall) {
    if (isDesignMode) {
      return showError ? (
        <Typography color={COLORS.FUNCTIONAL.ERROR.DEFAULT}>
          Waterfall not found
        </Typography>
      ) : null;
    }
    diagnostics.error(
      `No waterfall found for ${props.bundle.waterfallOverviewBundleConfiguration?.estateWaterfallId}`
    );
    return null;
  }

  return (
    <EstateWaterfallTaxOverviewSlideInner {...props} waterfall={waterfall} />
  );
}
