import {
  estateInclusionStatusToApiTypeMap,
  nonTrustEntityTaxStatusToApiTypeMap,
} from '@/modules/entities/EntitySubforms/utils/shared/taxStatus.utils';
import { getTaxStatusValuesFromEntityType } from '@/modules/entities/TaxStatusSubform/TaxStatusSubform.utils';
import { EntityType } from '@/modules/entities/types/EntityType';
import {
  AugmentedCreateDonorAdvisedFundInput,
  AugmentedCreateEntityInput,
  AugmentedCreatePrivateFoundationInput,
} from '@/types/schema';

import {
  CREATE_ENTITY_SHORT_FORM_NAMESPACE,
  EntityShortFormShape,
} from '../CreateEntityShortForm.types';
import {
  getCreateEntityInputCommonProps,
  makeCreateAccountInput,
  MakeCreateEntityInputParams,
  makeCreateMultiplePrincipalInputs,
} from './CreateEntityShortForm.common';

export function makeCreateDafInput(
  formInput: EntityShortFormShape,
  householdId: string,
  params: MakeCreateEntityInputParams
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId, params),
    },
    withDonorAdvisedFund: makeCharitableNonTrustEntityInput(formInput),
  };

  return input;
}

export function makeCreatePrivateFoundationInput(
  formInput: EntityShortFormShape,
  householdId: string,
  params: MakeCreateEntityInputParams
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId, params),
    },
    withPrivateFoundation: makeCharitableNonTrustEntityInput(formInput),
  };

  return input;
}

export function makeCharitableNonTrustEntityInput(
  formInput: EntityShortFormShape
):
  | AugmentedCreateDonorAdvisedFundInput
  | AugmentedCreatePrivateFoundationInput {
  const { nonTrustEntityTaxStatus } = getTaxStatusValuesFromEntityType(
    formInput[CREATE_ENTITY_SHORT_FORM_NAMESPACE].entityType as EntityType
  );

  return {
    create: {
      displayName: formInput.createEntityShortForm.name,
      inEstateStatus:
        estateInclusionStatusToApiTypeMap[
          formInput.createEntityShortForm.estateInclusionStatus
        ],
      taxStatus: nonTrustEntityTaxStatus
        ? nonTrustEntityTaxStatusToApiTypeMap[nonTrustEntityTaxStatus]
        : undefined,
    },
    withDonors: makeCreateMultiplePrincipalInputs(formInput),
    withDesignerAccount: makeCreateAccountInput(formInput),
  };
}
