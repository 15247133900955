import Decimal from 'decimal.js';

import { getZeroSignedNormalizedNumber } from './getZeroSignedNormalizedNumber';

function formatCompactCurrency(
  value: Decimal,
  opts: Intl.NumberFormatOptions = {}
) {
  // custom compact notation where thousands don't have a decimal
  // millions and up have one decimal
  let formattedResult = '';
  const isAccounting = opts.currencySign === 'accounting';
  const optsWithoutCurrencySign = { ...opts, currencySign: undefined };

  if (value.abs().gte(1000000)) {
    // one decimal if the number is greater than or equal to 1,000,000
    formattedResult = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 1,
      ...optsWithoutCurrencySign,
    }).format(getZeroSignedNormalizedNumber(value));
  } else {
    // no decimals if the number is less than 1,000,000

    // if the absolute value of the number is less than 1, then we should show the number as 0
    // instead of -0.12 truncated to -0
    if (value.abs().lessThan(1)) {
      value = new Decimal(0);
    }

    formattedResult = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      ...optsWithoutCurrencySign,
    }).format(getZeroSignedNormalizedNumber(value));
  }

  // if the format is accounting, then we should show negative numbers in parentheses
  if (isAccounting && value.isNegative()) {
    // remove the negative sign and add parentheses
    formattedResult = `(${formattedResult.slice(1)})`;
  }

  return formattedResult;
}

// given a decimal with the value of e.g. "13,000,000", this would
// return "$13.00"
export function formatCurrencyMillions(
  value: Decimal,
  opts: Intl.NumberFormatOptions = {}
) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    ...opts,
  }).format(getZeroSignedNormalizedNumber(value.dividedBy(1000000)));
}

export function formatCurrencyNoDecimals(value: Decimal) {
  const renderedValue = value.abs().lessThan(1) ? new Decimal(0) : value;
  return formatCurrency(renderedValue, { maximumFractionDigits: 0 });
}

export function formatCurrencyNoDecimalsAccounting(
  value: Decimal,
  opts?: {
    parensDisplay?: 'always' | 'negativeOnly';
  }
) {
  if (opts?.parensDisplay === 'always') {
    return `(${formatCurrencyNoDecimals(value.abs())})`;
  }

  return formatCurrency(value, {
    maximumFractionDigits: 0,
    currencySign: 'accounting',
  });
}

export function formatCurrency(
  value: Decimal,
  opts: Intl.NumberFormatOptions = {}
) {
  if (opts.notation === 'compact') {
    return formatCompactCurrency(value, opts);
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    ...opts,
  }).format(getZeroSignedNormalizedNumber(value));
}
