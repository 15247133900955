import { Grid, InputAdornment, Typography } from '@mui/material';
import { Control, FieldValues } from 'react-hook-form';

import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { UsersLeftIcon } from '@/components/icons/UsersLeftIcon';
import { Card, CardProps } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { ClientProfileRelationshipFormShape } from '@/modules/clientProfiles/ClientProfile.types';
import { StateDropdownInput } from '@/modules/common/StateDropdownInput/StateDropdownInput';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

import {
  GRANTOR_RELATIONSHIP_OPTIONS,
  PRIMARY_CLIENT_RELATIONSHIP_OPTIONS,
} from './constants';

export interface Props<FormShape extends FieldValues> {
  onSubmit?: (formValue: FormShape) => void;
  control: Control<FormShape>;
  fieldNamePrefix: string;
  showTrusteeSwitch?: boolean;
  showBeneficiarySwitch?: boolean;
  hasBeneficiaryEnabled?: boolean;
  hasTrusteeEnabled?: boolean;
  onDarkBackground?: boolean;
  // householdId can not be available in creation scenarios
  householdId?: string;
  relationshipOptions: ClientProfileRelationshipFormShape[];
  /**
   * showRelationshipToOtherPrimaryClient is used when this form is used to
   * create a household for the AI onboarding flow. In this case, we want to
   * allow the user to select the relationship of the 2nd client to the 1st client.
   */
  showRelationshipToOtherPrimaryClient?: boolean;
}

export function PersonFormFragment<FormShape extends FieldValues>({
  control,
  fieldNamePrefix,
  showTrusteeSwitch = false,
  showBeneficiarySwitch = false,
  hasBeneficiaryEnabled = false,
  hasTrusteeEnabled = false,
  onDarkBackground = false,
  householdId,
  relationshipOptions,
  showRelationshipToOtherPrimaryClient,
}: Props<FormShape>) {
  return (
    <>
      <FormLayoutRow>
        <FormLayoutItem width={6}>
          <FormAwareTextInput<FormShape>
            control={control}
            label="First name"
            required={true}
            fieldName={
              `${fieldNamePrefix}.firstName` as FieldNameFromFormShape<FormShape>
            }
          />
        </FormLayoutItem>
        <FormLayoutItem width={6}>
          <FormAwareTextInput<FormShape>
            control={control}
            placeholder="Optional"
            label="Middle name"
            fieldName={
              `${fieldNamePrefix}.middleName` as FieldNameFromFormShape<FormShape>
            }
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem width={10}>
          <FormAwareTextInput<FormShape>
            control={control}
            label="Last name"
            required={true}
            fieldName={
              `${fieldNamePrefix}.lastName` as FieldNameFromFormShape<FormShape>
            }
          />
        </FormLayoutItem>
        <FormLayoutItem width={2}>
          <FormAwareTextInput<FormShape>
            control={control}
            label="Suffix"
            fieldName={
              `${fieldNamePrefix}.suffix` as FieldNameFromFormShape<FormShape>
            }
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem width={8}>
          <FormAwareTextInput<FormShape>
            control={control}
            label="City"
            fieldName={
              `${fieldNamePrefix}.address.cityName` as FieldNameFromFormShape<FormShape>
            }
          />
        </FormLayoutItem>
        <FormLayoutItem width={4}>
          <StateDropdownInput<FormShape>
            label="State"
            required={true}
            fieldName={`${fieldNamePrefix}.address.stateCode`}
            control={control}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      {/* we really only want to show this if the client is already created, because otherwise we're trying to map from unknown grantors to unknown grantors */}
      {householdId && (
        <GrantorRelationshipForm
          cardVariant={onDarkBackground ? 'filled-dark' : 'filled'}
          fieldNamePrefix={fieldNamePrefix}
          relationshipOptions={relationshipOptions}
          control={control}
        />
      )}
      {showTrusteeSwitch && (
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareSwitch<FormShape>
              disabled={hasTrusteeEnabled}
              control={control}
              label="Enable use as a trustee"
              fieldName={
                `${fieldNamePrefix}.isTrustee` as FieldNameFromFormShape<FormShape>
              }
              labelPosition="right"
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      {showBeneficiarySwitch && (
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareSwitch<FormShape>
              disabled={hasBeneficiaryEnabled}
              control={control}
              label="Enable use as a beneficiary"
              fieldName={
                `${fieldNamePrefix}.isBeneficiary` as FieldNameFromFormShape<FormShape>
              }
              labelPosition="right"
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      {showRelationshipToOtherPrimaryClient && (
        <FormLayoutRow>
          <FormLayoutItem width={12}>
            <FormAwareSelectInput<FormShape>
              fieldName={
                `relationshipToBetweenPrimaryClients` as FieldNameFromFormShape<FormShape>
              }
              label="Relationship"
              required
              options={PRIMARY_CLIENT_RELATIONSHIP_OPTIONS}
              control={control}
              startAdornment={
                <InputAdornment position="start">
                  <UsersLeftIcon size={20} />
                </InputAdornment>
              }
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
    </>
  );
}

interface GrantorRelationshipFormProps<FormShape extends FieldValues> {
  control: Control<FormShape>;
  fieldNamePrefix: string;
  cardVariant: CardProps['variant'];
  relationshipOptions: ClientProfileRelationshipFormShape[];
}

function GrantorRelationshipForm<FormShape extends FieldValues>({
  control,
  fieldNamePrefix,
  cardVariant,
  relationshipOptions,
}: GrantorRelationshipFormProps<FormShape>) {
  // there will be no grantor options in the case where data is loading or where
  // there's a single grantor on the account, and we're considering whether or not to
  // render this form for that one grantor.
  if (!relationshipOptions || relationshipOptions.length === 0) return null;

  return (
    <FormLayoutRow>
      <FormLayoutItem>
        <Card variant={cardVariant} sx={{ p: 2 }}>
          <Typography variant="h4" component="h3" mb={1}>
            Relationship to client(s)
          </Typography>
          <Grid container spacing={2} columnSpacing={2}>
            {relationshipOptions.map((relationshipOption, idx) => {
              return (
                <Grid item sm={6} key={idx}>
                  <FormAwareSelectInput<FormShape>
                    control={control}
                    label={relationshipOption.display}
                    options={GRANTOR_RELATIONSHIP_OPTIONS}
                    fieldName={
                      `${fieldNamePrefix}.relationships.${idx}.type` as FieldNameFromFormShape<FormShape>
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <UsersLeftIcon size={20} />
                      </InputAdornment>
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
          {/* )} */}
        </Card>
      </FormLayoutItem>
    </FormLayoutRow>
  );
}
