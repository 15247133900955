import { noop } from 'lodash';
import { createContext, Dispatch } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';

import { CltTermDiagram_EntityFragment } from '../graphql/CltTermDiagram.generated';
import { CrtTermDiagram_EntityFragment } from '../graphql/CrtTermDiagram.generated';
import { DispositionsDiagram_EntityFragment } from '../graphql/DispositionsDiagram.generated';
import { GratTermDiagram_EntityFragment } from '../graphql/GratTermDiagram.generated';
import { OwnershipDiagram_EntityFragment } from '../graphql/OwnershipDiagram.generated';
import { QprtTermDiagram_EntityFragment } from '../graphql/QprtTermDiagram.generated';
import { EntityDiagramGraph } from '../types';
import { EntityDiagramAction } from '../types/actions';
import {
  EntityDiagramDerivedData,
  EntityDiagramState,
  EntityDiagramVariant,
} from '../types/state';

export interface EntityDiagramContext {
  dispatch: Dispatch<EntityDiagramAction>;
  state: EntityDiagramState;
  getState: () => EntityDiagramState;
}

const defaultDerivedData: EntityDiagramDerivedData = {
  nodesById: {},
  nodesByType: { default: [], sectionLabel: [], tile: [] },
  nodesBySectionId: {},
  edgesById: {},
};

const defaultState: EntityDiagramState = {
  isInitializing: true,
  selectedPrimaryClientId: '',
  primaryClients: null as unknown as ContextPrimaryClient[],
  entity: null as unknown as
    | DispositionsDiagram_EntityFragment
    | OwnershipDiagram_EntityFragment
    | GratTermDiagram_EntityFragment
    | QprtTermDiagram_EntityFragment
    | CrtTermDiagram_EntityFragment
    | CltTermDiagram_EntityFragment,
  entityDiagramGraph: null as unknown as EntityDiagramGraph,
  isTwoClientHousehold: false,
  nodes: [],
  edges: [],
  derivedData: { ...defaultDerivedData },
  highlightedIds: new Set(),
  isExporting: false,
  presentationMode: false,
  opacity: 0,
  entityDiagramVariant: null as unknown as EntityDiagramVariant,
};

export const DEFAULT_ENTITY_DIAGRAM_STATE: Readonly<EntityDiagramState> =
  Object.freeze(defaultState);

export const EntityDiagramContext = createContext<EntityDiagramContext>({
  dispatch: noop,
  state: { ...DEFAULT_ENTITY_DIAGRAM_STATE },
  getState: () => ({ ...DEFAULT_ENTITY_DIAGRAM_STATE }),
});

export const useEntityDiagramContext = () => {
  return useGuardedContext(EntityDiagramContext, 'EntityDiagramProvider');
};
