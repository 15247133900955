import { css } from '@emotion/css';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';

import { FlowChartProvider } from '@/components/diagrams/FlowChart';
import { Fullscreen } from '@/components/Fullscreen/Fullscreen';
import { LoadingOverlay } from '@/components/progress/LoadingOverlay/LoadingOverlay';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { EntityDiagram } from '@/modules/entityDiagram';
import { EntityDiagramProvider } from '@/modules/entityDiagram/contexts/EntityDiagram.provider';
import { useQueryEntityDiagram } from '@/modules/entityDiagram/hooks';
import { useEntityDiagramVariant } from '@/modules/entityDiagram/hooks/useEntityDiagramVariant';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { FIRST_DECEDENT_ID_QUERY_PARAM } from '@/modules/presentation/entities/components/EntityDiagram';

const styles = {
  fullHeight: css({ height: '100%' }),
};

const MIN_HEIGHT = 800;

interface EntityDiagramPageInnerProps {
  entityId: string;
  householdId: string;
  setSelectedPrimaryClientId: (id: string) => void;
}

function EntityDiagramPageInner({
  entityId,
  householdId,
  setSelectedPrimaryClientId,
}: EntityDiagramPageInnerProps) {
  return (
    <Stack className={styles.fullHeight} minHeight={MIN_HEIGHT}>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <EntityDiagram
            height={Math.max(MIN_HEIGHT, height)}
            width={width}
            householdId={householdId}
            entityId={entityId}
            setSelectedPrimaryClientId={setSelectedPrimaryClientId}
          />
        )}
      </AutoSizer>
    </Stack>
  );
}

export function EntityDiagramPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const entityId = useRequiredParam('entityId');
  const householdId = useRequiredParam('householdId');
  const entityDiagramVariant = useEntityDiagramVariant();

  const { entity } = useQueryEntityDiagram({
    entityId,
    entityDiagramVariant,
  });

  const { primaryClients, isTwoClientHousehold } = useHouseholdDetailsContext();
  const [selectedPrimaryClientId, setSelectedPrimaryClientId] = useState('');

  useEffect(() => {
    // Update the query param with the selected primary client id
    if (!selectedPrimaryClientId) {
      return;
    }
    const newParams = new URLSearchParams(searchParams);
    newParams.set(FIRST_DECEDENT_ID_QUERY_PARAM, selectedPrimaryClientId);
    setSearchParams(newParams, { replace: true });
  }, [searchParams, selectedPrimaryClientId, setSearchParams]);

  useEffect(() => {
    setSelectedPrimaryClientId(primaryClients?.[0]?.id ?? '');
  }, [primaryClients]);

  const hasData =
    (primaryClients?.length ?? 0) > 0 &&
    !!entity &&
    !!selectedPrimaryClientId &&
    !!entityDiagramVariant;

  return (
    <Fullscreen name="entityDiagram">
      <FlowChartProvider key={selectedPrimaryClientId}>
        {hasData ? (
          <EntityDiagramProvider
            primaryClients={primaryClients ?? []}
            isTwoClientHousehold={isTwoClientHousehold}
            entity={entity}
            presentationMode={false}
            selectedPrimaryClientId={selectedPrimaryClientId}
            entityDiagramVariant={entityDiagramVariant}
          >
            <EntityDiagramPageInner
              entityId={entityId}
              householdId={householdId}
              setSelectedPrimaryClientId={setSelectedPrimaryClientId}
            />
          </EntityDiagramProvider>
        ) : (
          <LoadingOverlay
            open={true}
            updatingText="Loading diagram..."
            data-diagramloading={true.toString()}
          />
        )}
      </FlowChartProvider>
    </Fullscreen>
  );
}
