import { useCallback } from 'react';
import { useWatch } from 'react-hook-form';

import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { MenuPopper } from '@/components/poppers/MenuPopper/MenuPopper';
import { useFormContext } from '@/components/react-hook-form';
import { PresentationBundleKind } from '@/types/schema';

import {
  useCurrentBundleIndex,
  useNavigateToSlide,
} from '../../ClientPresentationDesignerV2.hooks';
import { ClientPresentationV2Shape } from '../../ClientPresentationDesignerV2.types';
import { ClientPresentationV2Bundle } from '../../types/ClientPresentationV2.PresentationBundleType';
import { isSinglePageBundle } from '../ClientPresentationDesignerV2TreeView/ClientPresentationDesignerV2TreeView.constants';
import { getEmptyBundleForType } from './AddBundleMenu.utils';

export interface AddBundleMenuProps {
  onClose: () => void;
  anchorRef: React.RefObject<HTMLButtonElement>;
  onSelectBundleForConfiguration: (bundleKind: PresentationBundleKind) => void;
}

/**
 * Bundles that can only be added to a presentation once
 */
const SINGLE_BUNDLE_KINDS = [
  PresentationBundleKind.TableOfContentsBundle,
  PresentationBundleKind.EntitiesOverviewBundle,
  PresentationBundleKind.ProfessionalTeamBundle,
  PresentationBundleKind.StandaloneDisclaimerBundle,
];

/**
 * Checks the list of bundles to see if a one-time bundle is already present
 */
function canAddBundle(
  bundles: ClientPresentationV2Bundle[],
  bundleKind: PresentationBundleKind
) {
  if (!SINGLE_BUNDLE_KINDS.includes(bundleKind)) {
    return true;
  }

  return !bundles.map((bundle) => bundle.type).includes(bundleKind);
}

export function AddBundleMenu({
  onClose,
  anchorRef,
  onSelectBundleForConfiguration,
}: AddBundleMenuProps) {
  const { control, setValue } = useFormContext<ClientPresentationV2Shape>();
  const [bundles] = useWatch({ control, name: ['bundles'] });
  const { navigateToSlide } = useNavigateToSlide();
  const currentBundleIndex = useCurrentBundleIndex();

  const handleSelect = useCallback(
    (bundleKind: PresentationBundleKind) => {
      const newBundle = getEmptyBundleForType(bundleKind);
      if (isSinglePageBundle(bundleKind) && newBundle) {
        const before = bundles.slice(0, currentBundleIndex + 1);
        const after = bundles.slice(currentBundleIndex + 1);

        const newBundles = [...before, newBundle, ...after];

        setValue('bundles', newBundles);
        navigateToSlide(newBundle.id);
      } else {
        onSelectBundleForConfiguration(bundleKind);
      }
      onClose();
    },
    [
      onClose,
      bundles,
      currentBundleIndex,
      setValue,
      navigateToSlide,
      onSelectBundleForConfiguration,
    ]
  );

  return (
    <MenuPopper anchorRef={anchorRef} open onClose={onClose}>
      <SelectItemGroupLabel label="Estate overview" />
      {canAddBundle(bundles, PresentationBundleKind.BalanceSheetBundle) && (
        <MenuItem
          label="Balance sheet"
          onClick={() =>
            handleSelect(PresentationBundleKind.BalanceSheetBundle)
          }
        />
      )}
      {canAddBundle(bundles, PresentationBundleKind.EntitiesOverviewBundle) && (
        <MenuItem
          label="Entities overview"
          onClick={() =>
            handleSelect(PresentationBundleKind.EntitiesOverviewBundle)
          }
        />
      )}
      <MenuItem
        label="Waterfall overview"
        onClick={() =>
          handleSelect(PresentationBundleKind.WaterfallOverviewBundle)
        }
      />
      <SelectItemGroupLabel label="Summaries" />
      <MenuItem
        label="Entity summary"
        onClick={() => handleSelect(PresentationBundleKind.EntitySummaryBundle)}
      />
      {
        /* don't show the "other" header if there's nothing in here -- will eventually go away when custom pages are added */
        (canAddBundle(bundles, PresentationBundleKind.ProfessionalTeamBundle) ||
          canAddBundle(
            bundles,
            PresentationBundleKind.StandaloneDisclaimerBundle
          )) && <SelectItemGroupLabel label="Other" />
      }
      {canAddBundle(bundles, PresentationBundleKind.ProfessionalTeamBundle) && (
        <MenuItem
          label="Professional team"
          onClick={() =>
            handleSelect(PresentationBundleKind.ProfessionalTeamBundle)
          }
        />
      )}
      {canAddBundle(
        bundles,
        PresentationBundleKind.StandaloneDisclaimerBundle
      ) && (
        <MenuItem
          label="Disclaimer"
          onClick={() =>
            handleSelect(PresentationBundleKind.StandaloneDisclaimerBundle)
          }
        />
      )}
      {/* 
      Custom pages, ToC won't be supported until M2
      <>
        <MenuItem
          label="Add custom page"
          onClick={() => handleSelect(PresentationBundleKind.CustomPageBundle)}
        />
      </>
      */}
      {canAddBundle(bundles, PresentationBundleKind.TableOfContentsBundle) && (
        <MenuItem
          label="Table of contents"
          onClick={() =>
            handleSelect(PresentationBundleKind.TableOfContentsBundle)
          }
        />
      )}
    </MenuPopper>
  );
}
