/* eslint-disable tss-unused-classes/unused-classes */
import { DataVisualizationColor } from '@mui/material/styles/createPalette';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';

import { TileVariant } from './types';

export const useStyles = makeStyles<{
  variant: TileVariant;
  dragging: boolean;
  hover: boolean;
  viewOnly: boolean;
}>()((theme, { dragging, viewOnly }) => {
  const viewOnlyStyles = viewOnly
    ? {
        '@media screen': {
          boxShadow: 'none',
        },
      }
    : {};

  const hoverStyles = {};

  return {
    wrapper: {
      display: 'flex',
      height: '100%',
      position: 'relative',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      boxShadow: dragging ? theme.palette.shadows.lg : theme.palette.shadows.md,
      // Hide shadow on print
      '@media print': {
        boxShadow: 'none',
      },
      ...viewOnlyStyles,
      width: '100%',
      outlineStyle: 'solid',
      borderLeftWidth: '6px',
      borderLeftStyle: 'solid',
      borderRadius: '4px',
      outlineOffset: -1,
      outlineWidth: '1px',
      '&:hover': hoverStyles,
    },
    innerRoot: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 4,
      width: '100%',
      padding: '12px 16px',
      '~ *': {
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
      },
    },
    innerRootSelected: {},
    footerWrapper: { marginTop: 'auto' },
    footerWrapperSelected: {},
    hoverRoot: hoverStyles,
    selectedRoot: hoverStyles,
    linesWrapper: {},
    lineOne: {},
    lineTwo: {},
    lineThree: {},
    notes: {},
  };
});

function makeTileVariant(
  colorMap: Pick<
    DataVisualizationColor,
    'main' | 'dark' | 'ultraLight' | 'lighter' | 'light'
  >
) {
  const borderColor = colorMap.main;
  const borderColorSelected = colorMap.dark;

  const hoverStyles = {
    outlineColor: borderColorSelected,
  };

  return {
    wrapper: {},
    root: {
      backgroundColor: colorMap.ultraLight,
      borderColor,
      outlineColor: borderColor,
      '&:hover': hoverStyles,
    },
    innerRoot: {
      '~ *': { borderColor },
    },
    innerRootSelected: {
      '~ *': { borderColor: borderColorSelected },
    },
    footerWrapper: {
      backgroundColor: colorMap.lighter,
    },
    footerWrapperSelected: {
      backgroundColor: colorMap.light,
    },
    hoverRoot: hoverStyles,
    selectedRoot: {
      outlineColor: borderColorSelected,
      borderColor: borderColorSelected,
      backgroundColor: colorMap.lighter,
    },
    linesWrapper: {},
    lineOne: {},
    lineTwo: {},
    lineThree: {},
    notes: {},
  };
}

export const usePrimaryStyles = makeStyles()((theme) => {
  return makeTileVariant(theme.palette.dataVisualizationPrimary);
});

export const useSecondaryStyles = makeStyles()((theme) => {
  return makeTileVariant(theme.palette.dataVisualizationSecondary);
});

export const useTertiaryStyles = makeStyles()((theme) => {
  return makeTileVariant(theme.palette.dataVisualizationTertiary);
});

export const useDestructiveStyles = makeStyles()((theme) => {
  return makeTileVariant(theme.palette.dataVisualizationNegative);
});

export const useDefaultStyles = makeStyles()((_theme) => {
  return makeTileVariant({
    ultraLight: COLORS.GRAY[50],
    lighter: COLORS.GRAY[100],
    light: COLORS.GRAY[200],
    main: COLORS.GRAY[300],
    dark: COLORS.GRAY[800],
  });
});

export function useTileClasses(props: {
  variant: TileVariant;
  viewOnly: boolean;
  dragging?: boolean;
  classes?: WithClasses<typeof useStyles>;
  hover?: boolean;
}) {
  const { classes } = useStyles(
    {
      variant: props.variant,
      dragging: props.dragging ?? false,
      hover: props.hover ?? false,
      viewOnly: props.viewOnly,
    },
    { props }
  );

  const { classes: primaryClasses } = usePrimaryStyles();
  const { classes: secondaryClasses } = useSecondaryStyles();
  const { classes: tertiaryClasses } = useTertiaryStyles();
  const { classes: defaultClasses } = useDefaultStyles();
  const { classes: destructiveClasses } = useDestructiveStyles();

  const variantClasses: Record<
    Exclude<TileVariant, TileVariant.Pixel>,
    typeof classes
  > = {
    primary: primaryClasses,
    secondary: secondaryClasses,
    tertiary: tertiaryClasses,
    destructive: destructiveClasses,
    default: defaultClasses,
  };

  return {
    classes,
    variantClasses,
    primaryClasses,
    secondaryClasses,
    tertiaryClasses,
    destructiveClasses,
    defaultClasses,
  };
}
