import { Stack, Typography } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import Decimal from 'decimal.js';
import { compact, isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { Card } from '@/components/layout/Card/Card';
import { useFormContext } from '@/components/react-hook-form';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { DataTableProps } from '@/components/tables/DataTable/types';
import {
  DisplayTable,
  StyledTableCell,
} from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { useModalState } from '@/hooks/useModalState';
import {
  GrowthProfileGrowthType,
  GrowthProfileOverrideKind,
} from '@/types/schema';
import { formatPercent } from '@/utils/formatting/percent';

import { CustomEntityGrowthModal } from '../CustomEntityGrowthModal/CustomEntityGrowthModal';
import { GrowthProfileModal_EntityFragment } from './graphql/GrowthProfileModal.generated';
import {
  CUSTOM_ASSET_CLASS_GROWTH_TABLE_COLUMNS,
  DEFAULT_ASSET_CLASS_GROWTH_TABLE_COLUMNS,
  useCustomGrowthTableColumns,
} from './GrowthProfileModal.constants';
import { useGrowthProfileModalContext } from './GrowthProfileModal.context';
import {
  GrowthProfileCustomGrowthOverrideRowData,
  GrowthProfileModalFormPaths,
  GrowthProfileModalFormShape,
  GrowthProfileModalGrowthOverride,
} from './GrowthProfileModal.types';
import { mapGrowthOverrideToTableRow } from './GrowthProfileModal.utils';

export function GrowthProfileModalTable() {
  const { control, setValue } = useFormContext<GrowthProfileModalFormShape>();

  const [growthType] = useWatch({ control, name: ['growthType'] });

  const [{ isModalOpen, data: index }, { openModal, closeModal }] =
    useModalState<number | null>();

  const { householdId } = useGrowthProfileModalContext();

  const [growthProfileOverrides] = useWatch({
    control,
    name: ['growthProfileOverrides'],
  });

  const handleCustomEntitySubmit = useCallback(
    ({
      formData,
      entity,
    }: {
      formData: GrowthProfileModalGrowthOverride;
      entity: GrowthProfileModal_EntityFragment;
    }) => {
      const newGrowthProfileOverrides = growthProfileOverrides.slice();
      const newEntry: GrowthProfileModalGrowthOverride = {
        ...formData,
        id: index === null ? newGrowthProfileOverrides.length : index,
        entity,
        kind: GrowthProfileOverrideKind.Entity,
      };

      if (index === null) {
        newGrowthProfileOverrides.push(newEntry);
      } else {
        newGrowthProfileOverrides[index] = newEntry;
      }
      setValue('growthProfileOverrides', newGrowthProfileOverrides);
      closeModal();
    },
    [closeModal, growthProfileOverrides, index, setValue]
  );

  const handleDeleteOverride = useCallback(() => {
    if (index === null) return;
    const newGrowthProfileOverrides = growthProfileOverrides.slice();
    newGrowthProfileOverrides.splice(index, 1);
    setValue('growthProfileOverrides', newGrowthProfileOverrides);
    closeModal();
  }, [index, growthProfileOverrides, setValue, closeModal]);

  const onRowClick: DataTableProps['onRowClick'] = useCallback(
    ({ row }: GridRowParams<GrowthProfileCustomGrowthOverrideRowData>) => {
      openModal(row.id);
    },
    [openModal]
  );

  const currentOverride = useMemo(
    () => (index === null ? undefined : growthProfileOverrides?.[index]),
    [index, growthProfileOverrides]
  );

  const currentOverrideIds = useMemo(
    () =>
      compact(
        growthProfileOverrides.map(
          (override) => override.entity?.id ?? override.assetClass?.id
        )
      ),
    [growthProfileOverrides]
  );

  if (growthType === GrowthProfileGrowthType.GlobalGrowth) {
    return null;
  }

  return (
    <>
      {isModalOpen && (
        <CustomEntityGrowthModal
          householdId={householdId}
          onSubmit={handleCustomEntitySubmit}
          onClose={closeModal}
          canDelete={index !== null}
          entityId={currentOverride?.entity?.id}
          onDelete={handleDeleteOverride}
          currentOverrideIds={currentOverrideIds}
          {...currentOverride}
        />
      )}
      <Card variant="outlined" sx={{ p: 3 }}>
        <Stack spacing={3}>
          <TableHeader openModal={openModal} />
          {growthType === GrowthProfileGrowthType.CustomGrowth && (
            <EntityGrowthTable onRowClick={onRowClick} />
          )}
          <AssetClassGrowthTable />
        </Stack>
      </Card>
    </>
  );
}

interface TableHeaderProps {
  openModal: (index: number | null) => void;
}
function TableHeader({ openModal }: TableHeaderProps) {
  const { control } = useFormContext<GrowthProfileModalFormShape>();
  const [growthType] = useWatch({ control, name: ['growthType'] });

  if (growthType === GrowthProfileGrowthType.CustomGrowth) {
    return (
      <>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h1">Custom growth</Typography>
          <AddGrowthRateButton openModal={openModal} />
        </Stack>
        <Typography>
          Customize asset category-level growth rates below and/or add
          entity-level growth overrides, which will take priority over asset
          category growth rates
        </Typography>
      </>
    );
  }
  return <Typography variant="h1">Default growth rates</Typography>;
}

function AssetClassGrowthTable() {
  const { control } = useFormContext<GrowthProfileModalFormShape>();
  const [growthType, growthProfileOverrides] = useWatch({
    control,
    name: ['growthType', 'growthProfileOverrides'],
  });

  const columns = useMemo(() => {
    if (growthType === GrowthProfileGrowthType.CustomGrowth) {
      return CUSTOM_ASSET_CLASS_GROWTH_TABLE_COLUMNS;
    }
    return DEFAULT_ASSET_CLASS_GROWTH_TABLE_COLUMNS;
  }, [growthType]);

  const rows = useMemo(
    () =>
      growthProfileOverrides?.filter(
        (override) => override.kind === GrowthProfileOverrideKind.Asset
      ),
    [growthProfileOverrides]
  );

  return (
    <DisplayTable columns={columns}>
      {rows.map((row) => (
        <AssetClassGrowthTableRow
          key={row.id}
          id={row.id}
          isCustomGrowth={growthType === GrowthProfileGrowthType.CustomGrowth}
        />
      ))}
    </DisplayTable>
  );
}

interface AssetClassGrowthTableRowProps {
  id: number;
  isCustomGrowth: boolean;
}

function AssetClassGrowthTableRow({
  id,
  isCustomGrowth,
}: AssetClassGrowthTableRowProps) {
  const { control } = useFormContext<GrowthProfileModalFormShape>();
  const [displayName, defaultGrowthRate] = useWatch({
    control,
    name: [
      `growthProfileOverrides.${id}.displayName`,
      `growthProfileOverrides.${id}.defaultGrowthRate`,
    ],
  });
  return (
    <StyledTableRow>
      <StyledTableCell>
        <Typography variant="body2">{displayName}</Typography>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Typography variant="body2">
          {formatPercent(defaultGrowthRate ?? new Decimal(0), 2)}%
        </Typography>
      </StyledTableCell>
      {isCustomGrowth && (
        <StyledTableCell align="right">
          <FormAwarePercentInput<GrowthProfileModalFormShape>
            fieldName={
              `growthProfileOverrides.${id}.customGrowthRate` as const satisfies GrowthProfileModalFormPaths
            }
            label="Custom growth rate"
            hideLabel
            control={control}
            isDecimalJSInput
          />
        </StyledTableCell>
      )}
    </StyledTableRow>
  );
}

interface EntityGrowthTableProps {
  onRowClick: DataTableProps['onRowClick'];
}

function EntityGrowthTable({ onRowClick }: EntityGrowthTableProps) {
  const columns = useCustomGrowthTableColumns();
  const { control } = useFormContext<GrowthProfileModalFormShape>();
  const [growthType, growthProfileOverrides] = useWatch({
    control,
    name: ['growthType', 'growthProfileOverrides'],
  });

  const rows = useMemo(
    () =>
      growthProfileOverrides
        ?.filter(
          (override) => override.kind === GrowthProfileOverrideKind.Entity
        )
        .map(mapGrowthOverrideToTableRow),
    [growthProfileOverrides]
  );

  if (growthType !== GrowthProfileGrowthType.CustomGrowth || isEmpty(rows)) {
    return null;
  }

  return (
    <DataTable
      columns={columns}
      rows={rows}
      onRowClick={onRowClick}
      hideFooterPagination
    />
  );
}

interface AddGrowthRateButtonProps {
  openModal: (index: number | null) => void;
}

// Because this button can appear in multiple places, don't let it handle the modal open/close state
function AddGrowthRateButton({ openModal }: AddGrowthRateButtonProps) {
  return (
    <Button
      size="sm"
      variant="primary"
      onClick={() => openModal(null)}
      startIcon={PlusIcon}
    >
      Add entity-level growth override
    </Button>
  );
}
