import { ApolloClient } from '@apollo/client';
import { includes, orderBy } from 'lodash';

import { AccessParameterKind, BeneficiaryLevel } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';
import { getNodes } from '@/utils/graphqlUtils';

import { EntityType } from '../types/EntityType';
import { validUnitrustTypesV2 } from './EntityBeneficiariesSubform.constants';
import {
  defaultAccessParameter,
  defaultCharitableBeneficiary,
} from './EntityBeneficiariesSubform.defaults';
import {
  AccessParameterRadioGroupKind,
  BeneficiaryFormAccessParameter,
  BeneficiaryFormAccessParameterAgeParam,
  BeneficiaryFormAccessParameters,
  BeneficiaryFormPowerOfAppointment,
  EntityBeneficiariesFormBeneficiary,
  EntityBeneficiariesFormNamespace,
  EntityBeneficiariesFormVariant,
  EntityBeneficiariesSubformShape,
} from './EntityBeneficiariesSubform.types';
import {
  BeneficiariesDocument,
  BeneficiariesQuery,
  BeneficiarySubform_AccessAgeParameterFragment,
  BeneficiarySubform_AccessParameterFragment,
  BeneficiarySubform_BeneficiaryFragment,
} from './graphql/Beneficiaries.generated';

function getFormBeneficiaryId(
  beneficiary: BeneficiarySubform_BeneficiaryFragment
): string {
  if (beneficiary.individual) {
    return beneficiary.individual.id;
  }

  if (beneficiary.entity) {
    return beneficiary.entity.id;
  }

  if (beneficiary.organization) {
    return beneficiary.organization.id;
  }

  return '';
}

function getPowerOfAppointment(
  beneficiary: BeneficiarySubform_BeneficiaryFragment
): BeneficiaryFormPowerOfAppointment {
  if (beneficiary.powerOfAppointment) {
    return {
      _hasPowerOfAppointment: !!beneficiary.powerOfAppointment.power,
      power: beneficiary.powerOfAppointment.power ?? '',
      powerOtherNote: beneficiary.powerOfAppointment.powerOtherNote ?? '',
    };
  }

  return {
    _hasPowerOfAppointment: false,
    power: '',
  };
}

function getAccessParameterKind(
  accessParameter?: BeneficiarySubform_AccessParameterFragment
): AccessParameterRadioGroupKind {
  if (!accessParameter) return AccessParameterRadioGroupKind.Full;

  switch (accessParameter.kind) {
    // Full type
    case AccessParameterKind.Full:
      return AccessParameterRadioGroupKind.Full;
    // Partial types
    case AccessParameterKind.Percentage:
    case AccessParameterKind.Amount:
      return AccessParameterRadioGroupKind.Partial;
    // Other types
    case AccessParameterKind.FullDiscretion:
    case AccessParameterKind.Hms:
    case AccessParameterKind.Hems:
    case AccessParameterKind.AllTrustIncome:
    case AccessParameterKind.Other:
      return AccessParameterRadioGroupKind.Other;
    // Charitable types
    case AccessParameterKind.NetIncome:
    case AccessParameterKind.NetIncomeWithMakeup:
      return AccessParameterRadioGroupKind.Partial;
    default:
      throw new UnreachableError(accessParameter.kind);
  }
}

function getAccessParameters(
  accessParameters: BeneficiarySubform_AccessParameterFragment[],
  opts?: {
    hasFrequency?: boolean;
  }
): BeneficiaryFormAccessParameters {
  if (!accessParameters.length) {
    return {
      accessParametersFull: {
        ...defaultAccessParameter,
        _hasFrequency:
          Boolean(opts?.hasFrequency) || defaultAccessParameter._hasFrequency,
      },
      accessParametersPartial: [],
      accessParametersOther: undefined,
    };
  }

  const getAgeRequriementKind = (
    ageParam: BeneficiarySubform_AccessAgeParameterFragment
  ): BeneficiaryFormAccessParameterAgeParam['_ageRequirementKind'] => {
    if (ageParam.ageRequirementStart && ageParam.ageRequirementEnd) {
      return 'between';
    }

    if (ageParam.ageRequirementEnd) return 'until';

    return 'upon';
  };

  const accessParams: BeneficiaryFormAccessParameter[] = accessParameters.map(
    (ap) => ({
      accessParameterNotes: ap.accessParameterNotes ?? undefined,
      // TS PERF see type definition for BeneficiaryFormAccessParameter
      amount: (ap.amount as unknown as undefined) ?? undefined,
      _hasFrequency: Boolean(opts?.hasFrequency) || !!ap.frequency,
      frequency: ap.frequency ?? '',
      kind: ap.kind,
      // TS PERF see type definition for BeneficiaryFormAccessParameter
      percentage: (ap.percentage as unknown as undefined) ?? undefined,
      _hasAgeParams: !!getNodes(ap.accessAgeParameters).length,
      ageParams: getNodes(ap.accessAgeParameters).map((ageParam) => ({
        _ageRequirementKind: getAgeRequriementKind(ageParam),
        ageRequirementEnd: ageParam.ageRequirementEnd?.toString() ?? undefined,
        ageRequirementStart:
          ageParam.ageRequirementStart?.toString() ?? undefined,
        notes: ageParam.notes ?? undefined,
      })),
    })
  );

  const fullAccessParams = accessParams.filter(
    (ap) => ap.kind === AccessParameterKind.Full
  );
  const partialAccessParams = accessParams.filter(
    (ap) =>
      ap.kind === AccessParameterKind.Percentage ||
      ap.kind === AccessParameterKind.Amount ||
      ap.kind === AccessParameterKind.NetIncome ||
      ap.kind === AccessParameterKind.NetIncomeWithMakeup
  );
  const otherAccessParams = accessParams.filter(
    (ap) =>
      ap.kind === AccessParameterKind.FullDiscretion ||
      ap.kind === AccessParameterKind.Hms ||
      ap.kind === AccessParameterKind.Hems ||
      ap.kind === AccessParameterKind.AllTrustIncome ||
      ap.kind === AccessParameterKind.Other
  );

  const accessParametersOther: Partial<
    Record<AccessParameterKind, BeneficiaryFormAccessParameter>
  > = {
    [AccessParameterKind.FullDiscretion]: otherAccessParams.find(
      (ap) => ap.kind === AccessParameterKind.FullDiscretion
    ),
    [AccessParameterKind.Hms]: otherAccessParams.find(
      (ap) => ap.kind === AccessParameterKind.Hms
    ),
    [AccessParameterKind.Hems]: otherAccessParams.find(
      (ap) => ap.kind === AccessParameterKind.Hems
    ),
    [AccessParameterKind.AllTrustIncome]: otherAccessParams.find(
      (ap) => ap.kind === AccessParameterKind.AllTrustIncome
    ),
    [AccessParameterKind.Other]: otherAccessParams.find(
      (ap) => ap.kind === AccessParameterKind.Other
    ),
  };

  const accessParameterKind = getAccessParameterKind(
    // Assume the first access parameter is indicative of the kind as they cannot be mixed
    accessParameters[0]
  );

  const checkboxes = Object.entries(accessParametersOther).reduce(
    (acc, [key, value]) => {
      if (value) {
        acc[key as AccessParameterKind] = true;
      } else {
        acc[key as AccessParameterKind] = false;
      }

      return acc;
    },
    {} as Record<AccessParameterKind, boolean>
  );

  return {
    _accessParameterKind: accessParameterKind,
    accessParametersFull: fullAccessParams[0] ?? undefined,
    accessParametersPartial: partialAccessParams,
    accessParametersOther: {
      checkboxes,
      values: accessParametersOther,
    },
  };
}

export function beneficiaryFragmentToFormBeneficiary(
  beneficiary: BeneficiarySubform_BeneficiaryFragment,
  opts?: {
    hasFrequency?: boolean;
  }
): EntityBeneficiariesFormBeneficiary {
  const getAgeRequriementKind = ({
    ageRequirementStart,
    ageRequirementEnd,
  }: {
    ageRequirementStart?: number | null;
    ageRequirementEnd?: number | null;
  }) => {
    if (ageRequirementStart && ageRequirementEnd) {
      return 'between';
    }

    if (ageRequirementEnd) return 'until';

    return 'upon';
  };

  return {
    id: beneficiary.id || null,
    beneficiaryId: getFormBeneficiaryId(beneficiary),
    level: beneficiary.level ?? '',
    notes: beneficiary.notes ?? '',
    beneficiaryFormScheduledDistributions: {
      _hasScheduledDistributions: !!getNodes(beneficiary.scheduledDistributions)
        .length,
      scheduledDistributions:
        getNodes(beneficiary.scheduledDistributions).map((sd) => {
          const scheduledDistribution = {
            ageRequirementEnd: sd.ageRequirementEnd?.toString() ?? undefined,
            ageRequirementStart:
              sd.ageRequirementStart?.toString() ?? undefined,
            amount: sd.amount ?? undefined,
            frequency: sd.frequency ?? ('' as const),
            kind: sd.kind,
            percentage: sd.percentage ?? undefined,
            scheduledDistributionNotes:
              sd.scheduledDistributionNotes ?? undefined,
          };

          return {
            _hasAgeParams: Boolean(
              sd.ageRequirementStart || sd.ageRequirementEnd
            ),
            _ageRequirementKind: getAgeRequriementKind({
              ageRequirementStart: sd.ageRequirementStart,
              ageRequirementEnd: sd.ageRequirementEnd,
            }),
            scheduledDistribution,
          };
        }) ?? [],
    },
    beneficiaryFormAccessParameters: getAccessParameters(
      getNodes(beneficiary.accessParameters),
      opts
    ),
    beneficiaryFormPowerOfAppointment: getPowerOfAppointment(beneficiary),
  };
}

function getListOfFormBeneficiaries(
  beneficiaries: BeneficiarySubform_BeneficiaryFragment[],
  beneficiaryType:
    | 'beneficiaries'
    | 'lifetimeBeneficiaries'
    | 'remainderBeneficiaries'
) {
  return {
    [beneficiaryType]:
      sortBeneficiariesByLevel(beneficiaries).map((beneficiary) =>
        beneficiaryFragmentToFormBeneficiary(beneficiary)
      ) ?? [],
  };
}

function dataToForm(data: BeneficiariesQuery): EntityBeneficiariesSubformShape {
  if (data.node?.__typename !== 'Entity') {
    throw new Error('Unexpected node type');
  }

  if (data.node.subtype.__typename === 'SLATTrust') {
    return {
      ...getListOfFormBeneficiaries(
        data.node.subtype.lifetimeBeneficiaries ?? [],
        'lifetimeBeneficiaries'
      ),
      ...getListOfFormBeneficiaries(
        data.node.subtype.remainderBeneficiaries ?? [],
        'remainderBeneficiaries'
      ),
    };
  }

  if (data.node.subtype.__typename === 'CLTTrust') {
    const lifetimeBeneficiary:
      | BeneficiarySubform_BeneficiaryFragment
      | undefined = data.node.subtype.lifetimeBeneficiaries?.[0];

    if (getNodes(lifetimeBeneficiary?.accessParameters).length > 1) {
      diagnostics.error(
        'Beneficiary count assumption violation',
        new Error(
          'CLT lifetime beneficiary has more than one access parameter'
        ),
        {
          beneficiaryId: lifetimeBeneficiary!.id,
        }
      );
    }

    // CLT trusts assume that the lifetime beneficiary only has one type of distribution
    const lifetimeBeneficiaryAccessParam = getNodes(
      lifetimeBeneficiary?.accessParameters
    )[0];

    const hasAccessToTrust = !!lifetimeBeneficiaryAccessParam;

    let leadBeneficiary = defaultCharitableBeneficiary();
    if (lifetimeBeneficiary && hasAccessToTrust) {
      const charitableBeneficiaryType: 'unitrust' | 'annuity' =
        validUnitrustTypesV2.includes(lifetimeBeneficiaryAccessParam.kind)
          ? 'unitrust'
          : 'annuity';

      leadBeneficiary = {
        ...beneficiaryFragmentToFormBeneficiary(lifetimeBeneficiary, {
          hasFrequency: true,
        }),
        // infer the tab from the distribution kind
        charitableBeneficiaryType,
      };
    }

    return {
      leadBeneficiary,
      ...getListOfFormBeneficiaries(
        data.node.subtype.remainderBeneficiaries ?? [],
        'remainderBeneficiaries'
      ),
    };
  }

  if (data.node.subtype.__typename === 'CRTTrust') {
    const lifetimeBeneficiary:
      | BeneficiarySubform_BeneficiaryFragment
      | undefined = data.node.subtype.lifetimeBeneficiaries?.[0];

    if (getNodes(lifetimeBeneficiary?.accessParameters).length > 1) {
      diagnostics.error(
        'Beneficiary count assumption violation',
        new Error('CRT lifetime beneficiary has more than one distribution'),
        {
          beneficiaryId: lifetimeBeneficiary!.id,
        }
      );
    }

    // CRT trusts assume that the income beneficiary only has one type of distribution
    const lifetimeBeneficiaryAccessParam = getNodes(
      lifetimeBeneficiary?.accessParameters
    )[0];

    const hasAccessToTrust = !!lifetimeBeneficiaryAccessParam;

    let incomeBeneficiary = defaultCharitableBeneficiary();
    if (lifetimeBeneficiary && hasAccessToTrust) {
      const charitableBeneficiaryType: 'unitrust' | 'annuity' =
        validUnitrustTypesV2.includes(lifetimeBeneficiaryAccessParam.kind)
          ? 'unitrust'
          : 'annuity';
      incomeBeneficiary = {
        ...beneficiaryFragmentToFormBeneficiary(lifetimeBeneficiary, {
          hasFrequency: true,
        }),
        // infer the tab from the distribution kind
        charitableBeneficiaryType,
      };
    }

    return {
      incomeBeneficiary,
      ...getListOfFormBeneficiaries(
        data.node.subtype.remainderBeneficiaries ?? [],
        'remainderBeneficiaries'
      ),
    };
  }
  if (data.node.subtype.__typename === 'ILITTrust') {
    return {
      ...getListOfFormBeneficiaries(
        data.node.subtype.beneficiaries ?? [],
        'beneficiaries'
      ),
    };
  }

  if (
    data.node.subtype.__typename === 'IrrevocableTrust' ||
    data.node.subtype.__typename === 'RevocableTrust' ||
    data.node.subtype.__typename === 'GRATTrust' ||
    data.node.subtype.__typename === 'QPRTTrust' ||
    data.node.subtype.__typename === 'DonorAdvisedFund' ||
    data.node.subtype.__typename === 'PrivateFoundation' ||
    data.node.subtype.__typename === 'CustodialPersonalAccount' ||
    data.node.subtype.__typename === 'QualifiedTuitionPersonalAccount' ||
    data.node.subtype.__typename === 'RetirementPersonalAccount' ||
    data.node.subtype.__typename === 'IndividualPersonalAccount' ||
    data.node.subtype.__typename === 'JointPersonalAccount'
  ) {
    return {
      ...getListOfFormBeneficiaries(
        data.node.subtype.beneficiaries ?? [],
        'beneficiaries'
      ),
    };
  }

  throw new Error(`Unexpected subtype ${data.node.subtype.__typename}`);
}

export async function beneficiariesDataFetcher(
  apolloClient: ApolloClient<object>,
  nodeId: string
): Promise<EntityBeneficiariesSubformShape> {
  const { data, error } = await apolloClient.query<BeneficiariesQuery>({
    query: BeneficiariesDocument,
    variables: {
      nodeId: nodeId,
    },
  });

  if (error) {
    diagnostics.error('Could not fetch data for beneficiaries form', error);

    throw error;
  }

  return dataToForm(data);
}

export function getBeneficiariesVariant(
  type: EntityType,
  namespace: EntityBeneficiariesFormNamespace
): EntityBeneficiariesFormVariant {
  if (type === 'grat') {
    return 'percentOnly';
  }

  if (namespace === 'leadBeneficiarySubform') {
    return 'leadBeneficiary';
  }

  if (namespace === 'incomeBeneficiarySubform') {
    return 'incomeBeneficiary';
  }

  const accountEntityTypes: EntityType[] = [
    'custodial-account',
    'qualified-tuition-account',
    'retirement-account',
    'joint-account',
    'individual-account',
  ];
  if (includes(accountEntityTypes, type)) {
    return 'personalAccount';
  }

  const nonTrustCharitableEntityTypes: EntityType[] = [
    'daf',
    'private-foundation',
  ];
  if (includes(nonTrustCharitableEntityTypes, type)) {
    return 'nonTrustCharitableEntity';
  }

  return 'default';
}

function getLevelIndex(level?: BeneficiaryLevel | null) {
  if (!level) {
    return 4;
  }

  switch (level) {
    case BeneficiaryLevel.Primary:
      return 0;
    case BeneficiaryLevel.Secondary:
      return 1;
    case BeneficiaryLevel.Tertiary:
      return 2;
    case BeneficiaryLevel.Other:
      return 3;
    default:
      throw new UnreachableError({
        case: level,
        message: `Unrecognized beneficiary level ${level}`,
      });
  }
}

export function sortBeneficiariesByLevel<
  T extends { level?: BeneficiaryLevel | null },
>(beneficiaries: T[]) {
  return orderBy(beneficiaries, (b) => getLevelIndex(b.level), ['asc']);
}
