export enum TileVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Destructive = 'destructive',
  Default = 'default',
  Pixel = 'pixel',
}

export type TileFontSizeVariant = 'SM' | 'MD' | 'LG';
