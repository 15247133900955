import Decimal from 'decimal.js';
import { compact, isEmpty, orderBy } from 'lodash';

import { CoinsSwap02Icon } from '@/components/icons/CoinsSwap02Icon';
import { File02Icon } from '@/components/icons/File02Icon';
import { FilePlus02Icon } from '@/components/icons/FilePlus02Icon';
import { FileShield03Icon } from '@/components/icons/FileShield03Icon';
import { LinkExternal01Icon } from '@/components/icons/LinkExternal01Icon';
import { RefreshCw02Icon } from '@/components/icons/RefreshCw02Icon';
import {
  entityHasAssetsIntegration,
  getIntegrationEntitiesFromEntity,
} from '@/modules/assetProviderIntegrations/shared/utils';
import { getDesignerAccountDetails } from '@/modules/assetValuation/entityValuationUtils';
import { EditEntitySection } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { EntityKind } from '@/types/schema';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import {
  formatCurrencyNoDecimals,
  formatCurrencyNoDecimalsAccounting,
} from '@/utils/formatting/currency';
import { formatDateToMMDDYY } from '@/utils/formatting/dates';
import { formatPercent } from '@/utils/formatting/percent';
import { getNodes } from '@/utils/graphqlUtils';

import {
  BUSINESS_ENTITY_KINDS,
  INSURANCE_POLICY_ENTITY_TYPES,
} from '../../entities.constants';
import { InsurancePolicyTypeCopyMap } from '../../InsurancePolicyDetailsSubform/InsurancePolicyDetailsSubform.types';
import { getEntityTypeFromEntityKind } from '../../utils/getEntityTypeFromEntityKind';
import {
  EntityDetail_EntityFragment,
  EntityDetail_InsurancePolicyFragment,
  EntityDetail_LiabilityFragment,
  EntityDetail_OwnerOfStakeFragment,
} from '../graphql/EntityDetailPage.generated';
import { EntityValuationCardProps } from './components/EntityValuationCard';
import { EntityValuationItemProps } from './components/EntityValuationItem';
import {
  EntityValuationAction,
  EntityValuationActionDefinition,
} from './EntityValuationActions';

function getFormattedOwnedBusinessValueDetails(
  stake: EntityDetail_OwnerOfStakeFragment
) {
  const formattedOwnershipPercentage =
    formatPercent(stake.ownershipPercentage ?? new Decimal(0)) + '%';
  const formattedOwnershipValue = formatCurrencyNoDecimals(stake.ownedValue);
  return `${formattedOwnershipValue} (${formattedOwnershipPercentage})`;
}

// Strip off ownedValueProperty, which is used as an intermediate value to be able to
// calculate the remaining value for view-only scenarios
function removeOwnedValueFromEntityValuationItem(
  item: EntityValuationItemProps & { ownedValue: Decimal }
): EntityValuationItemProps {
  const { ownedValue: _ov, ...rest } = item;
  return rest;
}

function getOwnedBusinessEntitiesItems(
  entity: EntityDetail_EntityFragment,
  opts: GetValuationDetailsFromEntityOpts
): EntityValuationItemProps[] {
  const isParentIntegrated = entityHasAssetsIntegration(entity);
  // limit the number of entities shown in the presentation so we don't
  // overflow the page
  const MAX_VIEW_ONLY_ENTITIES_TO_SHOW = 5;

  const ownedBusinessEntities =
    compact(
      entity.ownedOwnershipStakes?.map((stake) => {
        if (!stake.ownedEntity) return null; // ts; shouldn't happen
        const ownedEntity = stake.ownedEntity;

        const businessName = ownedEntity.subtype?.displayName ?? '';
        const mostRecentValuationDate =
          ownedEntity.subtype.mostRecentValuationDate;
        const showIntegrationBadge =
          entityHasAssetsIntegration(ownedEntity) && !isParentIntegrated;
        const href = getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS,
          {
            householdId: entity.household.id,
            entityId: ownedEntity.id,
          }
        );

        return {
          href,
          variant: 'asset' as const,
          indent: isParentIntegrated,
          value: getFormattedOwnedBusinessValueDetails(stake),
          showIntegrationBadge,
          label: compact([
            businessName,
            mostRecentValuationDate &&
              `(as of ${formatDateToMMDDYY(mostRecentValuationDate)})`,
          ]).join(' '),
          ownedValue: stake.ownedValue,
        };
      })
    ) ?? [];

  if (
    opts.isViewOnly &&
    ownedBusinessEntities.length > MAX_VIEW_ONLY_ENTITIES_TO_SHOW
  ) {
    const firstEntities = ownedBusinessEntities
      .slice(0, MAX_VIEW_ONLY_ENTITIES_TO_SHOW)
      .map(removeOwnedValueFromEntityValuationItem);
    const remainingEntities = ownedBusinessEntities.slice(
      MAX_VIEW_ONLY_ENTITIES_TO_SHOW
    );
    const remainingValue = remainingEntities.reduce(
      (sum, entity) => sum.plus(entity.ownedValue ?? new Decimal(0)),
      new Decimal(0)
    );

    return [
      ...firstEntities,
      {
        variant: 'asset' as const,
        indent: isParentIntegrated,
        value: formatCurrencyNoDecimals(remainingValue),
        label: `${remainingEntities.length} other business entities`,
      },
    ];
  }

  return ownedBusinessEntities.map(removeOwnedValueFromEntityValuationItem);
}

function getBusinessOwnershipDetailItems(
  entity: EntityDetail_EntityFragment
): EntityValuationItemProps[] {
  // this is a heuristic for this being a business entity, but also serves as a useful type guard
  if (!('nongrantorControlledPercentage' in entity.subtype)) {
    return [];
  }

  const grantorControlledPercentage =
    entity.subtype.grantorControlledPercentage;

  // if the grantor owns everything, we don't need to worry about showing an ownership breakdown
  if (grantorControlledPercentage.equals(new Decimal(100))) {
    return [];
  }

  const grantorControlledValue = entity.subtype.grantorControlledValue;
  const nonGrantorControlledValue = entity.subtype.nongrantorControlledValue;
  const nonGrantorControlledPercentage =
    entity.subtype.nongrantorControlledPercentage;
  const mostRecentValuationDate = entity.subtype.mostRecentValuationDate;
  const formattedValuationDate = mostRecentValuationDate
    ? `(as of ${formatDateToMMDDYY(mostRecentValuationDate)})`
    : `(no value provided)`;

  return [
    {
      value: formatCurrencyNoDecimals(grantorControlledValue),
      label: `${formatPercent(grantorControlledPercentage)}% grantor-controlled ${formattedValuationDate}`,
      variant: 'asset',
    },
    {
      value: formatCurrencyNoDecimals(nonGrantorControlledValue),
      label: `${formatPercent(nonGrantorControlledPercentage)}% non-controlled ownership ${formattedValuationDate}`,
      variant: 'asset',
    },
  ];
}

function getDirectlyHeldInsuranceCashValueItems(
  entity: EntityDetail_EntityFragment
): EntityValuationItemProps[] {
  if (entity.subtype.__typename !== 'InsurancePersonalAccount') {
    return [];
  }

  return (
    compact(
      entity.subtype.policies?.map((policy) => {
        if (!policy.cashValue) return null;

        const label = (() => {
          if (!policy.carrierName && !policy.cashValueDate)
            return 'Policy cash value';

          if (policy.carrierName && !policy.cashValueDate) {
            return policy.carrierName;
          }

          if (policy.cashValueDate && !policy.carrierName) {
            return `As of ${formatDateToMMDDYY(policy.cashValueDate)}`;
          }

          if (policy.cashValueDate && policy.carrierName) {
            return `${policy.carrierName} (as of ${formatDateToMMDDYY(policy.cashValueDate)})`;
          }

          return undefined;
        })();

        const policyKind =
          InsurancePolicyTypeCopyMap[policy.kind].toLowerCase();
        return {
          value: `${formatCurrencyNoDecimals(policy.cashValue)} ${policyKind}`,
          label: label,
          variant: 'asset',
        };
      })
    ) ?? []
  );
}

function getLiabilitiesSummary(liabilities: EntityDetail_LiabilityFragment[]) {
  const totalLiabilitiesAmount = liabilities.reduce((sum, liability) => {
    if (!liability.currentAmount) return sum;
    return sum.plus(liability.currentAmount);
  }, new Decimal(0));

  const mostRecentAsOfDate = (() => {
    const mostRecentLiability = orderBy(
      liabilities,
      'currentAmountAsOfDate',
      'desc'
    )[0];
    return mostRecentLiability?.currentAmountAsOfDate ?? null;
  })();

  return {
    totalAmount: totalLiabilitiesAmount,
    mostRecentAsOfDate,
  };
}

function getLiabilitiesItems(
  entity: EntityDetail_EntityFragment
): EntityValuationItemProps[] {
  const liabilities = getNodes(entity.liabilitiesOwed);

  const { totalAmount: totalLiabilitiesAmount, mostRecentAsOfDate } =
    getLiabilitiesSummary(liabilities);

  if (totalLiabilitiesAmount.isZero()) {
    return [];
  }

  return [
    {
      variant: 'liability',
      value: `(${formatCurrencyNoDecimals(totalLiabilitiesAmount)})`,
      label: compact([
        'Liabilities',
        mostRecentAsOfDate &&
          `(as of ${formatDateToMMDDYY(mostRecentAsOfDate)})`,
      ]).join(' '),
    },
  ];
}

function getReceivableItems(
  entity: EntityDetail_EntityFragment
): EntityValuationItemProps[] {
  const receivables = getNodes(entity.liabilitiesLent);

  const { totalAmount: totalReceivablesAmount, mostRecentAsOfDate } =
    getLiabilitiesSummary(receivables);

  if (totalReceivablesAmount.isZero()) {
    return [];
  }

  return [
    {
      variant: 'asset',
      value: `${formatCurrencyNoDecimals(totalReceivablesAmount)}`,
      label: compact([
        'Receivables',
        mostRecentAsOfDate &&
          `(as of ${formatDateToMMDDYY(mostRecentAsOfDate)})`,
      ]).join(' '),
    },
  ];
}

function getIlitInsuranceItems(
  entity: EntityDetail_EntityFragment,
  opts: GetValuationDetailsFromEntityOpts
): EntityValuationItemProps[] {
  if (entity.subtype.__typename !== 'ILITTrust') {
    return [];
  }

  const { assets } = getDesignerAccountDetails(entity.subtype.designerAccount);

  const mostRecentCashValueDate = compact(
    entity.subtype.policies?.map((policy) => policy.cashValueDate)
  )
    .sort()
    .reverse()[0];

  return [
    {
      label: compact([
        'Cash value of policies',
        mostRecentCashValueDate &&
          `(as of ${formatDateToMMDDYY(mostRecentCashValueDate)})`,
      ]).join(' '),
      value: formatCurrencyNoDecimals(entity.subtype.totalPolicyCashValue),
      variant: 'asset',
    },
    ...getStructuredAssetItems(assets, opts),
  ];
}

function getStructuredAssetItems(
  assets: ReturnType<typeof getDesignerAccountDetails>['assets'],
  opts: GetValuationDetailsFromEntityOpts
): EntityValuationItemProps[] {
  if (!assets || assets.length === 0) return [];
  const MAX_ASSETS_TO_SHOW = opts.isViewOnly ? 5 : 8;
  const sortedAssets = [...assets].sort((a, b) => {
    const aValue = a.assetValue.ownedValue ?? new Decimal(0);
    const bValue = b.assetValue.ownedValue ?? new Decimal(0);
    return bValue.comparedTo(aValue); // Sort descending
  });

  const firstTenAssets = sortedAssets.slice(0, MAX_ASSETS_TO_SHOW);
  const remainingAssets = sortedAssets.slice(MAX_ASSETS_TO_SHOW);

  const result = firstTenAssets.map((asset) => ({
    label: compact([asset.displayName, asset.class?.displayName]).join(' - '),
    value: formatCurrencyNoDecimals(
      asset.assetValue.ownedValue ?? new Decimal(0)
    ),
    variant: 'asset' as const,
  }));

  if (remainingAssets.length > 0) {
    const remainingValue = remainingAssets.reduce(
      (sum, asset) => sum.plus(asset.assetValue.ownedValue ?? new Decimal(0)),
      new Decimal(0)
    );

    result.push({
      label: `${remainingAssets.length} other assets`,
      value: formatCurrencyNoDecimals(remainingValue),
      variant: 'asset' as const,
    });
  }

  return result;
}

/**
 * @description Returns a summary of the cash values of the policies for an entity, or null if there are no policies
 * TODO: This should maybe take an option to always return something for the ILIT/directly held scenario where we
 * want to show a zero even if there're no policies
 */
function getCashValueItemForPolicies(
  policies: EntityDetail_InsurancePolicyFragment[]
): EntityValuationItemProps | null {
  if (isEmpty(policies)) return null;

  const mostRecentCashValueDate =
    compact(policies.map((policy) => policy.cashValueDate))
      .sort()
      .reverse()[0] ?? null;

  const totalPolicyCashValue = sumDecimalJS(
    compact(policies.map((policy) => policy.cashValue)) ?? []
  );

  return {
    label: compact([
      'Cash value of policies',
      mostRecentCashValueDate &&
        `(as of ${formatDateToMMDDYY(mostRecentCashValueDate)})`,
    ]).join(' '),
    value: formatCurrencyNoDecimals(totalPolicyCashValue),
    variant: 'asset',
  };
}

interface GetAssetSummaryItemsOpts extends GetValuationDetailsFromEntityOpts {
  label?: string;
}

function getAssetSummaryItems(
  entity: EntityDetail_EntityFragment,
  opts: GetAssetSummaryItemsOpts
) {
  const { assets } = getDesignerAccountDetails(entity.subtype.designerAccount);

  let cashValueItem: EntityValuationItemProps | null = null;
  if ('policies' in entity.subtype) {
    cashValueItem = getCashValueItemForPolicies(entity.subtype.policies ?? []);
  }

  return compact([...getStructuredAssetItems(assets, opts), cashValueItem]);
}

interface GetValuationDetailsFromEntityOpts {
  isViewOnly: boolean;
}

export function getValuationDetailsFromEntity(
  entity: EntityDetail_EntityFragment,
  opts: GetValuationDetailsFromEntityOpts
): Pick<
  EntityValuationCardProps,
  | 'assetPropertyGroups'
  | 'headlineValue'
  | 'headlineValueLabel'
  | 'notes'
  | 'hasValidValuation'
  | 'sumLineValue'
  | 'dateOfValuation'
> {
  const isEntitySummariesEnabled = isFeatureFlagEnabled('entity_summaries');
  const shouldShowReceivablesValues = isFeatureFlagEnabled(
    'entity_value_includes_receivables'
  );

  // Business things
  const isBusinessEntity = BUSINESS_ENTITY_KINDS.includes(entity.kind);
  const businessAssetItems = getOwnedBusinessEntitiesItems(entity, opts);
  const businessOwnershipBreakdownItems =
    getBusinessOwnershipDetailItems(entity);
  const hasBusinessOwnershipBreakdown =
    businessOwnershipBreakdownItems.length > 0;
  const hasBusinessAssetItems = businessAssetItems.length > 0;

  // Insurance things
  const directlyHeldInsuranceItems =
    getDirectlyHeldInsuranceCashValueItems(entity);
  const ilitInsuranceItems = getIlitInsuranceItems(entity, opts);

  // Normal values
  const { dateOfValuation, description } = getDesignerAccountDetails(
    entity.subtype.designerAccount
  );
  const liabilityItems = getLiabilitiesItems(entity);
  const receivableItems = shouldShowReceivablesValues
    ? getReceivableItems(entity)
    : [];

  const assetPropertyGroups = (() => {
    if (!isEmpty(ilitInsuranceItems)) {
      return [
        {
          groupName: null,
          items: [
            ...ilitInsuranceItems,
            ...businessAssetItems,
            ...liabilityItems,
          ],
        },
      ];
    }

    if (!isEmpty(directlyHeldInsuranceItems)) {
      return [
        {
          groupName: null,
          items: [...directlyHeldInsuranceItems, ...liabilityItems],
        },
      ];
    }

    const labelOverride =
      isBusinessEntity || hasBusinessAssetItems
        ? 'Directly held assets'
        : undefined;
    const assetSummaryItems = getAssetSummaryItems(entity, {
      ...opts,
      label: labelOverride,
    });

    if (isBusinessEntity) {
      return compact([
        // we don't show the ownership breakdown if we're in view-only mode because we want to
        // make sure there's only one group, and the composition can be seen elsewhere
        hasBusinessOwnershipBreakdown &&
          !opts.isViewOnly && {
            groupName: 'Ownership',
            items: businessOwnershipBreakdownItems,
          },
        {
          groupName: 'Composition',
          items: [
            ...assetSummaryItems,
            ...businessAssetItems,
            ...receivableItems,
            ...liabilityItems,
          ],
        },
      ]);
    }

    return [
      {
        groupName: null,
        items: [
          ...assetSummaryItems,
          ...businessAssetItems,
          ...receivableItems,
          ...liabilityItems,
        ],
      },
    ];
  })();

  const headlineValue = (() => {
    switch (entity.subtype.__typename) {
      case 'SoleProprietorshipBusinessEntity':
      case 'CCorpBusinessEntity':
      case 'LLCBusinessEntity':
      case 'LPBusinessEntity':
      case 'SCorpBusinessEntity':
      case 'GPBusinessEntity':
        return entity.subtype.grantorControlledValue;
      default:
        return entity.subtype.currentValue;
    }
  })();

  const headlineValueLabel = (() => {
    if (isBusinessEntity) {
      return 'Net value of beneficial ownership';
    }

    if (entity.kind === EntityKind.InsurancePersonalAccount) {
      return 'Total cash value';
    }

    return isEntitySummariesEnabled ? 'Net value' : 'Net value of entity';
  })();

  return {
    assetPropertyGroups,
    headlineValue: formatCurrencyNoDecimalsAccounting(headlineValue),
    headlineValueLabel: headlineValueLabel,
    // we don't always want to show the sum line, only
    // when there are numbers that we want to add up to a top-level number
    sumLineValue:
      hasBusinessAssetItems || hasBusinessOwnershipBreakdown
        ? entity.subtype.currentValue
        : undefined,
    hasValidValuation: !!dateOfValuation,
    notes: description,
    dateOfValuation: dateOfValuation ?? undefined,
  };
}

const SEMANTIC_ICONS = {
  INSURANCE_POLICIES: FileShield03Icon,
  VALUATION: CoinsSwap02Icon,
  ADD_LIABILITY: FilePlus02Icon,
  EDIT_LIABILITY: File02Icon,
  RESYNC_VALUATION: RefreshCw02Icon,
  LINK_EXTERNAL: LinkExternal01Icon,
} as const;

const manageInsurancePoliciesAction: EntityValuationActionDefinition = {
  kind: EntityValuationAction.OPEN_ENTITY_EDIT_MODAL,
  initialEditEntitySection: EditEntitySection.INSURANCE_POLICIES,
  label: 'Manage insurance policies',
  icon: SEMANTIC_ICONS.INSURANCE_POLICIES,
};

const manageValuationAction = (
  entityKind: EntityKind
): EntityValuationActionDefinition => {
  return {
    kind: EntityValuationAction.MANAGE_VALUATION,
    icon: SEMANTIC_ICONS.VALUATION,
    label:
      entityKind === EntityKind.IlitTrust
        ? 'Update value of non-insurance holdings'
        : 'Update holdings',
  };
};

export function getEntityValuationActions(
  entity: EntityDetail_EntityFragment
): EntityValuationActionDefinition[] {
  const entityActions: EntityValuationActionDefinition[] = (() => {
    // directly held insurance doesn't have any concept of non-insurance assets
    if (entity.kind === EntityKind.InsurancePersonalAccount) {
      return [manageInsurancePoliciesAction];
    }

    const entityType = getEntityTypeFromEntityKind(entity.kind);
    const includeInsurancePoliciesAction =
      INSURANCE_POLICY_ENTITY_TYPES.includes(entityType);
    return compact([
      manageValuationAction(entity.kind),
      includeInsurancePoliciesAction && manageInsurancePoliciesAction,
    ]);
  })();

  const integrationActions = (() => {
    const integrationEntities = getIntegrationEntitiesFromEntity(entity);
    if (entityHasAssetsIntegration(entity) && integrationEntities) {
      return [
        {
          kind: EntityValuationAction.RESYNC_ENTITY_VALUATION,
          label: 'Resync valuation data',
          icon: SEMANTIC_ICONS.RESYNC_VALUATION,
        } as const,
        ...integrationEntities.map(
          (ie) =>
            ({
              kind: EntityValuationAction.VIEW_ON_INTEGRATED_PLATFORM,
              name: ie.name,
              integrationEntityId: ie.id,
              icon: SEMANTIC_ICONS.LINK_EXTERNAL,
            }) as const
        ),
      ];
    }

    return [];
  })();

  const editLiabilityActions = getEditLiabilityActions(entity, {
    hasFollowingSection: !isEmpty(integrationActions),
  });

  const addLiabilityAction: EntityValuationActionDefinition = {
    kind: EntityValuationAction.ADD_LIABILITY,
    label: 'Add a liability or receivable',
    icon: SEMANTIC_ICONS.ADD_LIABILITY,
    showDivider: !isEmpty(editLiabilityActions) || !isEmpty(integrationActions),
  } as const;

  const nonIntegrationActions: EntityValuationActionDefinition[] = [
    ...entityActions,
    addLiabilityAction,
    ...editLiabilityActions,
  ];

  return compact([...nonIntegrationActions, ...integrationActions]);
}

function getEditLiabilityActions(
  entity: EntityDetail_EntityFragment,
  opts: { hasFollowingSection: boolean }
): EntityValuationActionDefinition[] {
  const existingLiabilities = getNodes(entity.liabilitiesOwed);
  const existingReceivables = getNodes(entity.liabilitiesLent);
  const allLiabilities = [...existingLiabilities, ...existingReceivables];

  const liabilitiesActions: EntityValuationActionDefinition[] =
    allLiabilities.map((l, i) => {
      const label = `Edit ${l.displayName}`;

      return {
        kind: EntityValuationAction.EDIT_LIABILITY,
        liabilityId: l.id,
        label,
        icon: SEMANTIC_ICONS.EDIT_LIABILITY,
        // show divider on the last editable liability only if there's a following section
        showDivider:
          opts.hasFollowingSection && i === allLiabilities.length - 1,
      };
    });

  return liabilitiesActions;
}
