import Decimal from 'decimal.js';
import { noop } from 'lodash';
import React, { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import {
  CombinedSupportedSubformFieldTypes,
  EditEntityRichSection,
  EditEntitySection,
} from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import { SubformConfig } from '@/modules/entities/EntitySubforms/entitySubformConfigs';
import { EntityType } from '@/modules/entities/types/EntityType';

export interface EditEntitySplitScreenModalContext {
  entityType: EntityType | undefined;

  // The current entity section that the user is editing.
  currentSection: EditEntitySection;
  setCurrentSection: (section: EditEntitySection) => void;
  isDPSection: boolean;

  // The SubformConfig of the current section.
  currentSectionSubformConfig: SubformConfig | undefined;

  // The initial data for the whole entity (all sections).
  // Even though we just show one section/subform at a time, some subforms need
  // data from other subforms.
  initialSubformValues: CombinedSupportedSubformFieldTypes | undefined;

  // The available entity sections to edit for the entity type.
  sectionOptions: EditEntityRichSection[];

  // Indicates if there was an error fetching the entity data (from useData hook).
  hasError: boolean;

  // The ref to the footer of the modal, where the cancel/save buttons will be portaled to.
  // But the cancel/save buttons need to live in EditEntitySplitScreenFormInner,
  // because they rely on the form context.
  formActionsPortalRef: React.RefObject<HTMLDivElement>;

  // If true, navigate to the EntityDetailsPage with the entityTab param set to
  // the corresponding section that was saved.
  navigateAfterSave: boolean;

  // When we're editing dispositive provisions from a scenario where we have a projected
  // entity value, like from a waterfall, we show the projected value in the dispositive
  // provisions section of the modal
  entityValueOverride?: Decimal;

  handleCloseModal: () => void;
}

export const getDefaultValues = (): EditEntitySplitScreenModalContext => ({
  entityType: undefined,
  currentSection: EditEntitySection.BASIC_INFORMATION,
  setCurrentSection: noop,
  currentSectionSubformConfig: undefined,
  initialSubformValues: undefined,
  sectionOptions: [],
  hasError: false,
  formActionsPortalRef: { current: null },
  navigateAfterSave: false,
  handleCloseModal: noop,
  isDPSection: false,
});

export const EditEntitySplitScreenModalContext =
  createContext<EditEntitySplitScreenModalContext>(getDefaultValues());

export const useEditEntitySplitScreenModalContext = () => {
  return useGuardedContext(
    EditEntitySplitScreenModalContext,
    'EditEntitySplitScreenModalContextProvider'
  );
};
