import { css, cx } from '@emotion/css';
import { useState } from 'react';

import {
  TileGroup,
  TileGroupItem,
} from '@/components/diagrams/components/Tile';
import { useCursorStyles } from '@/components/diagrams/FlowChart/hooks/useCursorStyles';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';
import { COLORS } from '@/styles/tokens/colors';
import { AfterDeath } from '@/types/schema';

import { useEstateWaterfallContext } from '../contexts/estateWaterfall.context';
import { getIsDraftNode } from '../EstateWaterfall.utils';
import { EstateWaterfall_NodeFragment } from '../graphql/EstateWaterfall.generated';
import { CreateNewGroupModal } from './CreateNewGroupModal';
import { buildTileFromNode, getNodeId } from './utils';

const tileGroupItemRemoveHoverStyle = css({
  /**
   * For some reason styles are loading in out of order with the classes / useStyles pattern
   * so the important is needed.
   * Unclear if this was a library upgrade or something else.
   * @todo: https://linear.app/luminary/issue/T1-1142/classes-pattern-not-applying-styles-in-correct-order
   */
  '&:hover': {
    background: 'none !important',
  },
});

const forceHoverStyles = css({
  backgroundColor: COLORS.FUNCTIONAL.HOVER,
});

interface GroupNodeItemProps {
  groupNodeId: string;
  node: EstateWaterfall_NodeFragment;
  firstDeathGrantor?: ContextPrimaryClient;
  secondDeathGrantor?: ContextPrimaryClient;
}

function GroupNodeItem({
  groupNodeId,
  node,
  firstDeathGrantor,
  secondDeathGrantor,
}: GroupNodeItemProps) {
  const { dispatch, state } = useEstateWaterfallContext();
  const {
    cursorClassName: parentCursorClassName,
    isInteractive,
    styles,
  } = useCursorStyles(groupNodeId);

  const isDraft = getIsDraftNode(node.node);

  const tile = buildTileFromNode({
    visualizationConfig: state.waterfall.visualizationConfig,
    nodeFragment: node,
    firstDeathGrantor,
    secondDeathGrantor,
  });

  if (!tile) return null;

  const { id, data } = tile;

  const summaryNode = state.derivedData.summaryNode;

  return (
    <TileGroupItem
      key={id}
      description={isDraft ? `[Draft] ${data.lineOne}` : data.lineOne}
      classes={{
        root: cx(
          parentCursorClassName,
          // Parent is a tile group, which will never be clickable.
          // We want to inherit the parent styles always, but apply the clickable styles
          // when we are not in an interactive state.
          !isInteractive && styles.clickable,
          // No hover styles when a click can't happen
          isInteractive && tileGroupItemRemoveHoverStyle,
          // Force hover styles when this item is opened
          summaryNode?.node?.id === id && forceHoverStyles
        ),
      }}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        // Only open the summary panel if the item is clickable
        !isInteractive && dispatch({ type: 'OPEN_SUMMARY_PANEL', nodeId: id });
      }}
    />
  );
}

interface GroupNodeItemsProps {
  groupNodeId: string;
  nodes: EstateWaterfall_NodeFragment[];
  firstDeathGrantor?: ContextPrimaryClient;
  secondDeathGrantor?: ContextPrimaryClient;
  shouldTriggerCreateGroupModal: boolean;
  namespace: AfterDeath;
}

export function GroupNodeItems({
  groupNodeId,
  nodes,
  firstDeathGrantor,
  secondDeathGrantor,
  shouldTriggerCreateGroupModal,
  namespace,
}: GroupNodeItemsProps) {
  const { state } = useEstateWaterfallContext();
  const hideList =
    state.isExporting ||
    state.presentationMode ||
    state.waterfall.visualizationConfig?.hideEntitiesListInGroups;
  const showList = !hideList;

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(
    shouldTriggerCreateGroupModal
  );

  return (
    <>
      {isCreateModalOpen && (
        <CreateNewGroupModal
          isOpen={isCreateModalOpen}
          onClose={() => {
            setIsCreateModalOpen(false);
          }}
          nodeIds={nodes.map((node) => node.id)}
          namespace={namespace}
        />
      )}
      {showList && (
        <TileGroup>
          {nodes.map((node) => {
            const { id, afterDeath } = node;
            const key = getNodeId({ id, afterDeath });
            return (
              <GroupNodeItem
                key={key}
                node={node}
                groupNodeId={groupNodeId}
                firstDeathGrantor={firstDeathGrantor}
                secondDeathGrantor={secondDeathGrantor}
              />
            );
          })}
        </TileGroup>
      )}
    </>
  );
}
