import { Box, Stack, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

import { Link } from '@/components/form/baseInputs/Link';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { DefaultLuminaryDisclaimer } from '@/modules/presentation/clientPresentation/DisclaimerSlide/DefaultLuminaryDisclaimer';
import { useDefaultDisclaimerSlideTitle } from '@/modules/presentation/clientPresentation/DisclaimerSlide/useDefaultDisclaimerSlideTitle';
import { PresentationSlideSecondaryHeading } from '@/modules/presentation/components/PresentationSlideSecondaryHeading';
import { useGuardedTenantPresentationConfigurationContext } from '@/modules/presentation/context/tenantPresentationConfiguration.context';
import { PRESENTATION_SPACING_UNITS } from '@/modules/presentation/presentation.constants';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { ROUTE_KEYS } from '@/navigation/constants';
import { COLORS } from '@/styles/tokens/colors';
import { PresentationBundleKind } from '@/types/schema';

import {
  ClientPresentationDesignerV2ViewMode,
  useClientPresentationDesignerV2Context,
  useRegisterSlide,
} from '../../../ClientPresentationDesignerV2.context';
import { ClientPresentationV2Footer } from '../../ClientPresentationV2Footer';
import { BaseBundleSlideProps } from '../BundleSlide.types';

interface StandaloneDisclaimerSlideInnerProps extends BaseBundleSlideProps {
  body: ReactNode;
  slideTitle: string;
}
function StandaloneDisclaimerSlideInner({
  SlideWrapper = Box,
  bundle,
  body,
  slideTitle,
  isVisible,
}: StandaloneDisclaimerSlideInnerProps) {
  const { displayName } = useHouseholdDetailsContext();
  const theme = useTheme();
  const slideId = `standalone-disclaimer-${bundle.id}`;

  useRegisterSlide({
    slideId,
    title: 'Disclaimer',
    includeInToC: true,
    bundleKind: PresentationBundleKind.StandaloneDisclaimerBundle,
  });

  if (!isVisible) {
    return null;
  }
  return (
    <SlideWrapper>
      <PresentationSlide.Slide
        id={slideId}
        leftHeaderContent={
          <PresentationSlide.MainHeading heading={slideTitle} />
        }
        rightHeaderContent={
          <PresentationSlideSecondaryHeading clientDisplayName={displayName} />
        }
        footer={<ClientPresentationV2Footer hideDisclaimer slideId={slideId} />}
      >
        <Stack p={PRESENTATION_SPACING_UNITS} height="100%">
          <Box
            sx={{
              columnCount: 2,
              columnGap: theme.spacing(6),
              columnRule: `1px solid ${COLORS.NEUTRAL_GRAY[900]}`,
              height: 800, // height of a slide's body, necessary for the column content to wrap correctly,
            }}
          >
            <Typography
              variant="body1"
              // white-space: pre-wrap is necessary to preserve line breaks
              sx={{ breakInside: 'avoid', whiteSpace: 'pre-wrap' }}
            >
              {body}
            </Typography>
          </Box>
        </Stack>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}

export function StandaloneDisclaimerSlide({
  bundle,
  SlideWrapper = Box,
  isVisible,
}: BaseBundleSlideProps) {
  const { navigate } = useNavigateToRoute();
  const { legalDisclaimers } =
    useGuardedTenantPresentationConfigurationContext();
  const { viewMode, householdId } = useClientPresentationDesignerV2Context();
  const defaultDisclaimerSlideTitle = useDefaultDisclaimerSlideTitle();
  const slideTitle =
    legalDisclaimers?.presentationStandaloneDisclaimerTitle ??
    defaultDisclaimerSlideTitle;

  let body: ReactNode | undefined;

  if (legalDisclaimers?.presentationStandaloneDisclaimerBody) {
    body = legalDisclaimers?.presentationStandaloneDisclaimerBody;
  } else if (legalDisclaimers?.includeLuminaryDisclaimer) {
    body = <DefaultLuminaryDisclaimer />;
  }

  if (!body) {
    if (
      viewMode === ClientPresentationDesignerV2ViewMode.Designer &&
      isVisible
    ) {
      return (
        <Callout severity="warning">
          <Stack spacing={0.5}>
            <Typography variant="h5">Missing data</Typography>
            <Typography variant="subtitle1">
              You have not configured a standalone disclaimer. Add one via the
              link below.
            </Typography>
            <Box>
              <Link
                display="Go to legal disclaimers"
                onClick={() =>
                  navigate(ROUTE_KEYS.ADMIN_LEGAL_DISCLAIMERS, {
                    householdId,
                  })
                }
              />
            </Box>
          </Stack>
        </Callout>
      );
    }
    return null;
  }

  return (
    <StandaloneDisclaimerSlideInner
      SlideWrapper={SlideWrapper}
      bundle={bundle}
      body={body}
      slideTitle={slideTitle}
      isVisible={isVisible}
    />
  );
}
