import { validateCcorpCreateInput } from '@/modules/entities/EntitySubforms/validators/ccorp';
import { validateCltTrustCreateInput } from '@/modules/entities/EntitySubforms/validators/clt';
import { validateCrtTrustCreateInput } from '@/modules/entities/EntitySubforms/validators/crt';
import { validateCustodialAccountCreateInput } from '@/modules/entities/EntitySubforms/validators/custodialAccount';
import { validateDafCreateInput } from '@/modules/entities/EntitySubforms/validators/daf';
import { validateGPCreateInput } from '@/modules/entities/EntitySubforms/validators/gp';
import { validateIlitTrustCreateInput } from '@/modules/entities/EntitySubforms/validators/ilit';
import { validateIndividualAccountCreateInput } from '@/modules/entities/EntitySubforms/validators/individualAccount';
import { validateInsuranceAccountCreateInput } from '@/modules/entities/EntitySubforms/validators/insuranceAccount';
import { validateIrrevocableTrustCreateInput } from '@/modules/entities/EntitySubforms/validators/irrevocableTrust';
import { validateJointAccountCreateInput } from '@/modules/entities/EntitySubforms/validators/jointAccount';
import { validateLLCCreateInput } from '@/modules/entities/EntitySubforms/validators/llc';
import { validateLPCreateInput } from '@/modules/entities/EntitySubforms/validators/lp';
import { validatePrivateFoundationCreateInput } from '@/modules/entities/EntitySubforms/validators/privateFoundation';
import { validateQprtTrustCreateInput } from '@/modules/entities/EntitySubforms/validators/qprt';
import { validateQualifiedTuitionAccountCreateInput } from '@/modules/entities/EntitySubforms/validators/qualifiedTuitionAccount';
import { validateRetirementAccountCreateInput } from '@/modules/entities/EntitySubforms/validators/retirementAccount';
import { validateRevocableTrustCreateInput } from '@/modules/entities/EntitySubforms/validators/revocableTrust';
import { validateScorpCreateInput } from '@/modules/entities/EntitySubforms/validators/scorp';
import { validateSlatTrustCreateInput } from '@/modules/entities/EntitySubforms/validators/slat';
import { validateSoleProprietorshipCreateInput } from '@/modules/entities/EntitySubforms/validators/soleProprietorship';
import { AugmentedCreateEntityInput } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import {
  CREATE_ENTITY_SHORT_FORM_NAMESPACE,
  EntityShortFormShape,
} from '../CreateEntityShortForm.types';
import {
  makeCreateCCorpInput,
  makeCreateGPInput,
  makeCreateLLCInput,
  makeCreateLPInput,
  makeCreateSCorpInput,
  makeCreateSoleProprietorshipInput,
} from './CreateEntityShortForm.businessEntities';
import { MakeCreateEntityInputParams } from './CreateEntityShortForm.common';
import {
  makeCreateDafInput,
  makeCreatePrivateFoundationInput,
} from './CreateEntityShortForm.nonTrustCharitableEntities';
import {
  makeCreateCustodialAccountInput,
  makeCreateIndividualAccountInput,
  makeCreateInsuranceAccountInput,
  makeCreateJointAccountInput,
  makeCreateQualifiedTuitionAccountInput,
  makeCreateRetirementAccountInput,
} from './CreateEntityShortForm.personalAccounts';
import {
  makeCreateCltInput,
  makeCreateCrtInput,
  makeCreateIlitTrustInput,
  makeCreateIrrevocableTrustInput,
  makeCreateQprtTrustInput,
  makeCreateRevocableTrustInput,
  makeCreateSlatTrustInput,
} from './CreateEntityShortForm.trusts';

export function makeCreateEntityInput(
  input: EntityShortFormShape,
  householdId: string,
  params: MakeCreateEntityInputParams
): AugmentedCreateEntityInput {
  const { entityType } = input[CREATE_ENTITY_SHORT_FORM_NAMESPACE];
  switch (entityType) {
    case 'revocable-trust':
      return validateRevocableTrustCreateInput(
        makeCreateRevocableTrustInput(input, householdId, params)
      );
    case 'irrevocable-trust':
      return validateIrrevocableTrustCreateInput(
        makeCreateIrrevocableTrustInput(input, householdId, params)
      );

    case 'slat':
      return validateSlatTrustCreateInput(
        makeCreateSlatTrustInput(input, householdId, params)
      );

    case 'qprt':
      return validateQprtTrustCreateInput(
        makeCreateQprtTrustInput(input, householdId, params)
      );

    case 'ilit':
      return validateIlitTrustCreateInput(
        makeCreateIlitTrustInput(input, householdId, params)
      );

    case 'clt':
      return validateCltTrustCreateInput(
        makeCreateCltInput(input, householdId, params)
      );

    case 'crt':
      return validateCrtTrustCreateInput(
        makeCreateCrtInput(input, householdId, params)
      );

    case 'daf':
      return validateDafCreateInput(
        makeCreateDafInput(input, householdId, params)
      );

    case 'private-foundation':
      return validatePrivateFoundationCreateInput(
        makeCreatePrivateFoundationInput(input, householdId, params)
      );

    case 'individual-account':
      return validateIndividualAccountCreateInput(
        makeCreateIndividualAccountInput(input, householdId, params)
      );

    case 'insurance-account':
      return validateInsuranceAccountCreateInput(
        makeCreateInsuranceAccountInput(input, householdId, params)
      );

    case 'joint-account':
      return validateJointAccountCreateInput(
        makeCreateJointAccountInput(input, householdId, params)
      );

    case 'retirement-account':
      return validateRetirementAccountCreateInput(
        makeCreateRetirementAccountInput(input, householdId, params)
      );

    case 'custodial-account':
      return validateCustodialAccountCreateInput(
        makeCreateCustodialAccountInput(input, householdId, params)
      );

    case 'qualified-tuition-account':
      return validateQualifiedTuitionAccountCreateInput(
        makeCreateQualifiedTuitionAccountInput(input, householdId, params)
      );

    // Business entities
    case 'sole-proprietorship':
      return validateSoleProprietorshipCreateInput(
        makeCreateSoleProprietorshipInput(input, householdId, params)
      );
    case 'c-corp':
      return validateCcorpCreateInput(
        makeCreateCCorpInput(input, householdId, params)
      );
    case 's-corp':
      return validateScorpCreateInput(
        makeCreateSCorpInput(input, householdId, params)
      );
    case 'llc':
      return validateLLCCreateInput(
        makeCreateLLCInput(input, householdId, params)
      );
    case 'lp':
      return validateLPCreateInput(
        makeCreateLPInput(input, householdId, params)
      );
    case 'gp':
      return validateGPCreateInput(
        makeCreateGPInput(input, householdId, params)
      );

    default: {
      const err = Error(
        `Could not create entity input for unrecognized entity type: ${entityType}`
      );

      diagnostics.error(`Unrecognized entity type: ${entityType}`, err);
      throw err;
    }
  }
}
