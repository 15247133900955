import { Node, TileNode } from '@/components/diagrams/FlowChart';
import { gridLayout } from '@/components/diagrams/FlowChart/utils/gridLayout';
import { isNodeEligibleForGraveyard } from '@/modules/graphViz/graphVizNodeConfig/nodeGraveyard.utils';

import { EntityDiagramState, EntityDiagramVariant } from '../types';
import { applyDerivedDataToState } from './applyDerivedDataToState';

function hasNonZeroPosition({ position }: Node): boolean {
  return !!(position.x || position.y);
}

/**
 * @description A view config is "fully initialized" as a UI only concept when
 * there are nodes with positions that contain non-zero values
 */
export function hasFullyInitializedNodes(state: EntityDiagramState) {
  const { derivedData } = applyDerivedDataToState(state);

  // We only check tile nodes, section group position is managed by a different process
  const nodes = derivedData.nodesByType.tile;
  return nodes?.length && nodes.some(hasNonZeroPosition);
}

/**
 * @description Sets the `isNewTile` flag to false for all nodes in the graveyard
 */
function removeNodeFromGraveyard(node: Node): Node {
  if (isNodeEligibleForGraveyard(node)) {
    // We want to remove all nodes from the graveyard
    return { ...node, data: { ...node.data, isNewTile: false } } as TileNode;
  }
  return node;
}

export function applyAutoLayoutToState(
  state: EntityDiagramState
): EntityDiagramState {
  const {
    derivedData: { nodesByType },
  } = applyDerivedDataToState(state);

  // Section group layout managed by a different process
  const tileNodes = (nodesByType.tile ?? []) as TileNode[];

  const sectionLabelNodes = nodesByType.sectionLabel ?? [];

  const tileNodesZeroed = tileNodes.map((node) => ({
    ...node,
    position: { x: 0, y: 0 },
  }));

  // Apply the grid layout to the tile nodes
  const { grid, edges } = gridLayout({
    nodes: tileNodesZeroed,
    chunkSize: 4,
    paddingX: 25,
    // Give more padding for ownership since we do not show
    // the section labels
    paddingY:
      state.entityDiagramVariant === EntityDiagramVariant.Ownership ? 100 : 64,
    edges: state.edges,
    applyModifyedEdgeCenterFactor:
      state.entityDiagramVariant === EntityDiagramVariant.Dispositions ||
      state.entityDiagramVariant === EntityDiagramVariant.Ownership,
  });

  const nodes = grid.flat().map(removeNodeFromGraveyard);

  return {
    ...state,
    nodes: [...sectionLabelNodes, ...nodes],
    edges,
  };
}
