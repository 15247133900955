import { AugmentedCreateEntityInput } from '@/types/schema';

import { EntityShortFormShape } from './CreateEntityShortForm/CreateEntityShortForm.types';
import { MakeCreateEntityInputParams } from './CreateEntityShortForm/formUtils/CreateEntityShortForm.common';
import { makeCreateEntityInput } from './CreateEntityShortForm/formUtils/CreateEntityShortForm.entityUtils';

export function mapFormDataToInput(
  formData: EntityShortFormShape,
  householdId: string,
  { primaryDocumentIds, additionalDocumentIds }: MakeCreateEntityInputParams
): AugmentedCreateEntityInput {
  const input = makeCreateEntityInput(formData, householdId, {
    primaryDocumentIds,
    additionalDocumentIds,
  });
  return input;
}
