import { SetOptional } from 'type-fest';

import { FilePlus02Icon } from '@/components/icons/FilePlus02Icon';
import {
  MenuItem,
  MenuItemProps,
} from '@/components/poppers/MenuPopper/MenuItem';
import { COLORS } from '@/styles/tokens/colors';

export function AddNewDocumentMenuItem(
  props: SetOptional<MenuItemProps, 'label'>
) {
  return (
    <MenuItem
      icon={<FilePlus02Icon size={18} sx={{ color: COLORS.GRAY[500] }} />}
      label="Upload a new document"
      {...props}
    />
  );
}
