import { Stack, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

import { StackedHorizontalBar } from '@/components/charts/StackedHorizontalBar/StackedHorizontalBar';

import { WaterfallBarChartSection } from './hooks/useWaterfallSummaryBarChartSections';

interface EstateWaterfallSummaryBarChartsProps {
  sections: WaterfallBarChartSection[];
  children?: ReactNode;
}

export function EstateWaterfallSummaryBarCharts({
  sections,
  children,
}: EstateWaterfallSummaryBarChartsProps) {
  const theme = useTheme();

  return (
    <Stack spacing={1}>
      {sections.map(({ label, sections, afterDeath }) => (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="space-between"
          key={`${label}-${afterDeath}`}
          data-testid={`EstateWaterfallsSummaryBarChart-section-${afterDeath}`}
        >
          <Stack width={theme.spacing(20)}>
            <Typography variant="subtitle2">{label}</Typography>
          </Stack>
          <Stack flex={1}>
            <StackedHorizontalBar sections={sections} />
          </Stack>
        </Stack>
      ))}
      {children}
    </Stack>
  );
}
