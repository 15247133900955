import { groupBy, orderBy } from 'lodash';

import { DispositiveProvisions_DispositionScenarioFragment } from '@/modules/dispositiveProvisions/graphql/DispositiveProvisions.fragments.generated';
import { RowData as BeneficiaryRowData } from '@/modules/trusts/TrustBeneficiariesList/FlatBeneficiaryTable.types';
import { getRows } from '@/modules/trusts/TrustBeneficiariesList/FlatBeneficiaryTable.utils';
import { TrustBeneficiariesList_BeneficiaryFragment } from '@/modules/trusts/TrustBeneficiariesList/graphql/TrustBeneficiariesList.fragments.generated';
import { EntityKind, StateCode } from '@/types/schema';
import { approximateTextHeight } from '@/utils/approximateTextHeight';
import { diagnostics } from '@/utils/diagnostics';

import {
  GeneratedDispositionScenarioSlideProps,
  getFilteredDispositionSlideProps,
} from './components/DispositionScenarioSlide/DispositionScenarioSlide.utils';
import { RowData as DispositionRowData } from './components/DispositionScenarioSlide/DispositionScenarioTable.types';
import {
  getDispositionScenarioToShow,
  getTableRows,
} from './components/DispositionScenarioSlide/DispositionScenarioTable.utils';
import { EntityPresentationSlideType } from './entityPresentations.types';

// Configuration constants for row height and layout
export const HEIGHT_CONFIG = {
  HEIGHT_BUDGET_PX: 779, // Maximum height of the content portion of a slide
  GROUP_HEIGHT_PX: 138, // Height for group header and padding
  ONE_LINE_ROW_HEIGHT_PX: 49, // Height for a row with one line of content
  TWO_LINE_ROW_HEIGHT_PX: 61, // Height for a row with two lines of content
  DESCRIPTION_FONT_SIZE_PX: 12, // Font size for a description in subtitle2
  DESCRIPTION_LINE_HEIGHT_PX: 16, // Line height for a description in subtitle2
  DESCRIPTION_AVAILABLE_WIDTH_FULL_PX: 1293, // Maximum width for a description when full width
  DESCRIPTION_AVAILABLE_WIDTH_SHARED_PX: 826, // Maximum width for a description when sharing with left content
  NOTES_CARD_HEIGHT_PX: 54, // Height notes card without content
};

export const BENEFICIARY_GROUP_PREFIX = 'beneficiary-group'; // Prefix for beneficiary group names

type BeneficiaryRowWithPaginationProps = BeneficiaryRowData & {
  group: string; // Group identifier for pagination
  height: number; // Height of the row
};

type DispositionRowWithPaginationProps = DispositionRowData & {
  group: string; // Group identifier for pagination
  height: number; // Height of the row
};

interface DescriptionRowWithPaginationProps {
  description: string;
  group: string;
  height: number;
}

// Union type for rows with pagination properties
export type RowWithPaginationProps =
  | BeneficiaryRowWithPaginationProps
  | DispositionRowWithPaginationProps
  | DescriptionRowWithPaginationProps;

function isBeneficiaryRow(
  row: RowWithPaginationProps
): row is BeneficiaryRowWithPaginationProps {
  return 'beneficiary' in row; // A beneficiary row always has a 'beneficiary' field
}

function isDispositionRow(
  row: RowWithPaginationProps
): row is DispositionRowWithPaginationProps {
  return 'recipient' in row; // A disposition row always has a 'recipient' field
}

function isDescriptionRow(
  row: RowWithPaginationProps
): row is DescriptionRowWithPaginationProps {
  return 'description' in row; // A description row always has a 'description' field
}

export interface PaginateBeneficiaryAndDispositionRowsInput {
  rows: RowWithPaginationProps[];
  description?: string;
  hasDispositionRows?: boolean;
  hasBeneficiaryRows?: boolean;
}

// Function to paginate rows into pages based on height constraints
export function paginateBeneficiaryAndDispositionRows({
  rows,
  description,
  hasDispositionRows = true,
  hasBeneficiaryRows = true,
}: PaginateBeneficiaryAndDispositionRowsInput) {
  let heightBudget = HEIGHT_CONFIG.HEIGHT_BUDGET_PX; // Start with the full page height budget

  // Adjust height budget if we will be showing the no rows placeholder
  if (!hasDispositionRows) {
    heightBudget -= HEIGHT_CONFIG.GROUP_HEIGHT_PX;
  }

  if (!hasBeneficiaryRows) {
    heightBudget -= HEIGHT_CONFIG.GROUP_HEIGHT_PX;
  }

  let pageIndex = 0; // Track the current page index
  const seenGroupIds = new Set<string>(); // Track groups already added to the current page
  const paginatedRows: RowWithPaginationProps[][] = [[]]; // Initialize the list of paginated pages

  // Helper function to check if a row can fit within the remaining height
  const canAddRow = (row: RowWithPaginationProps, remainingHeight: number) => {
    return row.height <= remainingHeight;
  };

  let rowIndex = 0; // Track the current row index
  const retriedRowIndices = new Set<number>(); // Track rows that have been retried on a new page

  while (rowIndex < rows.length) {
    const row = rows[rowIndex];
    if (!row) {
      rowIndex++;
      continue; // Skip null or undefined rows
    }

    // Deduct height for a new group if it hasn't been added yet
    if (!seenGroupIds.has(row.group)) {
      heightBudget -= HEIGHT_CONFIG.GROUP_HEIGHT_PX;
      seenGroupIds.add(row.group);
    }

    if (canAddRow(row, heightBudget)) {
      paginatedRows[pageIndex]?.push(row); // Add row to the current page
      heightBudget -= row.height; // Deduct row height from the budget
      rowIndex++; // Move to the next row
    } else {
      // Handle cases where the row cannot fit
      if (retriedRowIndices.has(rowIndex)) {
        diagnostics.error(
          'Could not paginate beneficiary and disposition rows',
          new Error('Could not paginate beneficiary and disposition rows'),
          {
            rowHeight: row.height,
            heightBudget,
          }
        );
        break; // Stop pagination if the row has already been retried
      }

      // Start a new page and retry the current row
      retriedRowIndices.add(rowIndex);
      pageIndex++;
      paginatedRows.push([]);
      heightBudget = HEIGHT_CONFIG.HEIGHT_BUDGET_PX; // Reset the height budget
      seenGroupIds.clear(); // Reset group tracking for the new page
    }
  }

  if (description) {
    // Try to add the description to the last page
    const {
      DESCRIPTION_AVAILABLE_WIDTH_SHARED_PX,
      DESCRIPTION_AVAILABLE_WIDTH_FULL_PX,
      DESCRIPTION_LINE_HEIGHT_PX,
      DESCRIPTION_FONT_SIZE_PX,
      NOTES_CARD_HEIGHT_PX,
    } = HEIGHT_CONFIG;

    const descriptionHeight =
      approximateTextHeight({
        text: description,
        maxWidthPx:
          pageIndex === 0
            ? DESCRIPTION_AVAILABLE_WIDTH_SHARED_PX
            : DESCRIPTION_AVAILABLE_WIDTH_FULL_PX,
        lineHeightPx: DESCRIPTION_LINE_HEIGHT_PX,
        fontSizePx: DESCRIPTION_FONT_SIZE_PX,
      }) + NOTES_CARD_HEIGHT_PX;

    const descriptionRow: DescriptionRowWithPaginationProps = {
      description: description ?? '',
      group: 'description',
      height: descriptionHeight,
    };

    // Add the description to the last page if it fits, otherwise start a new page
    if (heightBudget > descriptionHeight) {
      paginatedRows[paginatedRows.length - 1]?.push(descriptionRow);
    } else if (heightBudget <= descriptionHeight) {
      paginatedRows.push([descriptionRow]);
    }
  }

  // Remove empty pages and return the result
  return paginatedRows.filter((page) => page.length > 0);
}

function shouldShowNoRowsPlaceholder(hasRowsOfKind: boolean, pageIdx: number) {
  if (pageIdx === 0 && !hasRowsOfKind) {
    // On the first page, show the placeholder if there are no rows of the kind
    return true;
  }

  return false;
}

export interface GetPaginatedPersonalAccountSummaryInput {
  dispositionScenarios: DispositiveProvisions_DispositionScenarioFragment[];
  entityId: string; // Entity identifier
  slideTypes: EntityPresentationSlideType[];
  beneficiaries: TrustBeneficiariesList_BeneficiaryFragment[];
  beneficiariesGroups?: TrustBeneficiariesList_BeneficiaryFragment[][];
  entityKind: EntityKind;
  grantors: {
    displayName: string;
    id: string;
    stateCode?: StateCode;
    hasStateTax: boolean;
  }[];
  description?: string;
  reserveFirstPage?: boolean;
}

export interface GetPaginatedBeneficiariesAndDispositionsForSummaryOutput {
  dispositionScenario?: GeneratedDispositionScenarioSlideProps;
  dispositionRowsByDeath: [DispositionRowData[], DispositionRowData[]] | null;
  pageIndex: number;
  beneficiariesRows: BeneficiaryRowData[] | null;
  beneficiariesGroupRows: BeneficiaryRowData[][] | null[];
  maxPages: number;
  paginatedDescription: string | null;
}

// Function to generate paginated summary data for beneficiaries and dispositions
export function getPaginatedBeneficiariesAndDispositionsForSummary({
  dispositionScenarios,
  entityId,
  slideTypes,
  beneficiaries,
  beneficiariesGroups,
  entityKind,
  grantors,
  description,
  // Adds a blank first page to the output so
  // we can render the diagram on the first page
  reserveFirstPage = false,
}: GetPaginatedPersonalAccountSummaryInput): GetPaginatedBeneficiariesAndDispositionsForSummaryOutput[] {
  // Retrieve filtered disposition slide properties
  const dispositionSlideProps = getFilteredDispositionSlideProps({
    dispositionScenarios,
    entityId,
    slideTypes,
  });

  const dispositionScenarioToShow = getDispositionScenarioToShow(
    dispositionSlideProps
  );

  // Get rows for first and second death scenarios
  const { firstDeathRows, secondDeathRows } = getTableRows({
    dispositionScenario: dispositionScenarioToShow?.dispositionScenario,
    firstDeathProvisions: dispositionScenarioToShow?.firstDeathProvisions ?? [],
    secondDeathProvisions:
      dispositionScenarioToShow?.secondDeathProvisions ?? [],
    grantors,
  }) as {
    firstDeathRows: DispositionRowData[];
    secondDeathRows: DispositionRowData[];
  };

  // Extract row IDs for later filtering
  const firstDeathRowIds = firstDeathRows.map((row) => row.id);
  const secondDeathRowIds = secondDeathRows.map((row) => row.id);

  // Generate rows for beneficiaries and grouped beneficiaries
  const beneficiariesRows = getRows(beneficiaries, entityKind);
  const beneficiariesGroupRows =
    beneficiariesGroups?.map((b) => getRows(b, entityKind)) ?? [];
  const hasBeneficiaryRows =
    beneficiariesRows.length > 0 ||
    beneficiariesGroupRows.some((r) => r.length > 0);

  // Combine disposition rows
  const dispositionRows = [...firstDeathRows, ...secondDeathRows];
  const hasDispositionRows = dispositionRows.length > 0;

  // Combine all rows with additional metadata
  const allRows: RowWithPaginationProps[] = [
    ...beneficiariesGroupRows
      .map((b, idx) =>
        b.map((r) => ({
          ...r,
          group: `${BENEFICIARY_GROUP_PREFIX}-${idx}`, // Assign a unique group name
          height: r.details.lineTwo
            ? HEIGHT_CONFIG.TWO_LINE_ROW_HEIGHT_PX
            : HEIGHT_CONFIG.ONE_LINE_ROW_HEIGHT_PX,
        }))
      )
      .flat(),
    ...beneficiariesRows.map((r) => ({
      ...r,
      group: 'beneficiary',
      height: r.details.lineTwo
        ? HEIGHT_CONFIG.TWO_LINE_ROW_HEIGHT_PX
        : HEIGHT_CONFIG.ONE_LINE_ROW_HEIGHT_PX,
    })),
    ...dispositionRows.map((r) => ({
      ...r,
      group: 'disposition',
      height: HEIGHT_CONFIG.TWO_LINE_ROW_HEIGHT_PX,
    })),
  ];

  // Paginate rows into pages
  const paginatedRows = paginateBeneficiaryAndDispositionRows({
    rows: allRows,
    description,
    hasDispositionRows,
    hasBeneficiaryRows,
  });

  const maxPages = paginatedRows.length;

  if (maxPages === 0) {
    const pages: GetPaginatedBeneficiariesAndDispositionsForSummaryOutput[] = [
      {
        dispositionScenario: dispositionScenarioToShow,
        dispositionRowsByDeath: [[], []],
        pageIndex: reserveFirstPage ? 1 : 0,
        beneficiariesRows: [],
        beneficiariesGroupRows: [[]],
        maxPages: reserveFirstPage ? 2 : 1,
        paginatedDescription: null,
      },
    ];

    if (reserveFirstPage) {
      pages.unshift({
        dispositionScenario: undefined,
        dispositionRowsByDeath: null,
        pageIndex: 0,
        beneficiariesRows: null,
        beneficiariesGroupRows: [null],
        maxPages: 2,
        paginatedDescription: null,
      });
    }

    return pages;
  }

  // Generate pages dynamically
  const pages = Array.from({ length: maxPages }).flatMap((_, idx) => {
    const row = paginatedRows[idx] ?? [];

    // Filter disposition rows by death scenario
    const dispositionRowsByDeath:
      | [DispositionRowData[], DispositionRowData[]]
      | null = (() => {
      const dispositionRows = row.filter((row) =>
        isDispositionRow(row)
      ) as DispositionRowData[];

      const shouldShowNoDispositionRowsPlaceholder =
        shouldShowNoRowsPlaceholder(hasDispositionRows, idx);

      const dispositionRowsByDeath = [
        dispositionRows.filter((row) => firstDeathRowIds.includes(row.id)),
        dispositionRows.filter((row) => secondDeathRowIds.includes(row.id)),
      ] as [DispositionRowData[], DispositionRowData[]];

      if (
        dispositionRowsByDeath.every((r) => r.length === 0) &&
        !shouldShowNoDispositionRowsPlaceholder
      ) {
        return null;
      }

      return dispositionRowsByDeath;
    })();

    // Filter beneficiary rows
    const beneficiariesRows: BeneficiaryRowData[] | null = (() => {
      const beneficiariesRows = row.filter((row) =>
        isBeneficiaryRow(row)
      ) as BeneficiaryRowData[];

      const shouldShowNoBeneficiariesPlaceholder = shouldShowNoRowsPlaceholder(
        hasBeneficiaryRows,
        idx
      );

      if (
        beneficiariesRows.length === 0 &&
        !shouldShowNoBeneficiariesPlaceholder
      ) {
        return null;
      }

      return beneficiariesRows;
    })();

    // Group beneficiary rows by prefix
    const beneficiariesGroupRows: BeneficiaryRowData[][] | null[] = (() => {
      const groupedRows = groupBy(
        row.filter((r) => r.group.startsWith(BENEFICIARY_GROUP_PREFIX)),
        'group'
      );

      const beneficiariesGroupRows = orderBy(
        Object.values(groupedRows),
        'group'
      ) as BeneficiaryRowData[][];

      const shouldShowNoBeneficiariesPlaceholder = shouldShowNoRowsPlaceholder(
        hasBeneficiaryRows,
        idx
      );

      if (beneficiariesGroupRows.length === 0) {
        beneficiariesGroupRows.push([]);
      }

      if (
        beneficiariesGroupRows.every((r) => r.length === 0) &&
        !shouldShowNoBeneficiariesPlaceholder
      ) {
        return [null];
      }

      return beneficiariesGroupRows;
    })();

    const paginatedDescription = (() => {
      const descriptionRow = row.find(isDescriptionRow);
      if (descriptionRow) {
        return descriptionRow.description;
      }
      return null;
    })();

    return {
      dispositionScenario: dispositionScenarioToShow,
      dispositionRowsByDeath,
      pageIndex: idx,
      beneficiariesRows,
      beneficiariesGroupRows,
      maxPages,
      paginatedDescription,
    };
  });

  if (reserveFirstPage) {
    const newMaxPages = pages.length + 1;
    pages.forEach((page) => {
      page.maxPages = newMaxPages;
      page.pageIndex++;
    });

    pages.unshift({
      dispositionScenario: undefined,
      dispositionRowsByDeath: null,
      pageIndex: 0,
      beneficiariesRows: null,
      beneficiariesGroupRows: [null],
      maxPages: newMaxPages,
      paginatedDescription: null,
    });
  }

  return pages;
}
