import Decimal from 'decimal.js';

import { sumDecimalJS } from '@/utils/decimalJSUtils';
import {
  getTransferDestinationName,
  getTransferSourceName,
} from '@/utils/hypotheticalTransfers/transferUtils';

import {
  GetHypotheticalTransfers_EstateWaterfallLoanFragment,
  GetHypotheticalTransfers_HypotheticalTransferFragment,
} from './graphql/GetHypotheticalTransfers.generated';
import {
  SaleLoanWithProjections,
  TransferWithValue,
} from './hooks/useGetHypotheticalTransfers';
import { TransferTab } from './TransferItems';

/**
 * Get the display name for a transfer, including a fallback based on the start and end years
 */
export function getTransferDisplayName({
  name,
  startYear,
  endYear,
}: {
  name?: string | undefined | null;
  startYear?: number | undefined | null;
  endYear?: number | undefined | null;
}): string {
  if (name) return name;

  if (startYear && endYear && endYear !== startYear)
    return 'Recurring transfer';

  return 'One-time transfer';
}

export function getDescriptionFromTransfer(
  transfer: GetHypotheticalTransfers_HypotheticalTransferFragment,
  kind: TransferTab
) {
  if (kind === TransferTab.Inbound) {
    return `From "${getTransferSourceName(transfer)}"`;
  }

  return `To "${getTransferDestinationName(transfer)}"`;
}

function getNetHypotheticalSaleLoans({
  recipientLoans,
  sourceLoans,
}: {
  recipientLoans: SaleLoanWithProjections[];
  sourceLoans: SaleLoanWithProjections[];
}) {
  const lastSaleLoanYear = Math.max(
    ...recipientLoans.map(
      ({ saleLoan }) =>
        saleLoan.startDate.getFullYear() + saleLoan.termLengthYears
    ),
    ...sourceLoans.map(
      ({ saleLoan }) =>
        saleLoan.startDate.getFullYear() + saleLoan.termLengthYears
    )
  );

  const inboundAmount = recipientLoans.reduce((acc, { loanProjections }) => {
    const value = loanProjections
      ? getSaleLoanFundingValueFromEWLoan(loanProjections)
      : new Decimal(0);
    return acc.plus(value);
  }, new Decimal(0));

  const outboundAmount = sourceLoans.reduce((acc, { loanProjections }) => {
    const value = loanProjections
      ? getSaleLoanFundingValueFromEWLoan(loanProjections)
      : new Decimal(0);
    return acc.plus(value);
  }, new Decimal(0));

  const netTransferred = inboundAmount.minus(outboundAmount);
  return {
    lastSaleLoanYear,
    totalSaleLoanValue: netTransferred,
  };
}

/**
 * Get the initial funding value of a sale or loan
 */
export function getSaleLoanFundingValueFromEWLoan(
  loan: GetHypotheticalTransfers_EstateWaterfallLoanFragment
): Decimal {
  // the beginningOfYearValue in the first year of the loan is the funding value of
  // the sale or loan. it's better to use this computed value than to attempt to reimplement
  // all of the logic that computes the value quickly and correctly on the backend
  return loan.valueHistory[0]?.beginningOfYearValue ?? new Decimal(0);
}

/**
 * Get the total value of all repayments of a sale or loan
 */
export function getSaleLoanRepaymentValueFromEWLoan(
  loan: GetHypotheticalTransfers_EstateWaterfallLoanFragment
): Decimal {
  return loan.valueHistory.reduce((acc, yearValue) => {
    const principalPaid = yearValue.principalPaid ?? new Decimal(0);
    const interestPaid = yearValue.interestPaid ?? new Decimal(0);
    return acc.plus(principalPaid).plus(interestPaid);
  }, new Decimal(0));
}

interface GetNetHypotheticalTransfersArg {
  /**
   * All inbound transfers
   */
  inboundTransfers: TransferWithValue[];
  /**
   * All outbound transfers
   */
  outboundTransfers: TransferWithValue[];
  /**
   * All sale loans that the entity/person is the recipient of
   */
  recipientLoans: SaleLoanWithProjections[];
  /**
   * All sale loans that the entity/person is the source of
   */
  sourceLoans: SaleLoanWithProjections[];
}

export function getNetHypotheticalTransfers({
  inboundTransfers,
  outboundTransfers,
  recipientLoans,
  sourceLoans,
}: GetNetHypotheticalTransfersArg): {
  totalNormalizedTransfers: Decimal;
  finalYear: number;
} {
  const allTransfers = [...inboundTransfers, ...outboundTransfers];

  const currentYear = new Date().getFullYear();
  // get the last transfer year
  let lastTransferYear = currentYear;

  if (allTransfers.length) {
    lastTransferYear = Math.max(
      ...allTransfers.map((t) => t.transfer.endYear ?? currentYear)
    );
  }

  const { lastSaleLoanYear, totalSaleLoanValue } = getNetHypotheticalSaleLoans({
    recipientLoans,
    sourceLoans,
  });

  if (lastSaleLoanYear > lastTransferYear) {
    lastTransferYear = lastSaleLoanYear;
  }

  const inboundTransfersAmount = sumDecimalJS(
    inboundTransfers.map((t) => t.value)
  );
  const outboundTransfersAmount = sumDecimalJS(
    outboundTransfers.map((t) => t.value)
  );
  const totalNormalizedTransfers = inboundTransfersAmount
    .minus(outboundTransfersAmount)
    .plus(totalSaleLoanValue);

  return {
    totalNormalizedTransfers,
    finalYear: lastTransferYear,
  };
}
