import { useNodesInitialized } from '@xyflow/react';
import { compact, isEqual } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { UseMeasureRect } from 'react-use/lib/useMeasure';

import { useFlowChartContext } from '@/components/diagrams/FlowChart';
import { useDocumentVisibility } from '@/hooks/useDocumentVisibility';
import { useEffectReducer } from '@/hooks/useEffectReducer';
import { useGetState } from '@/hooks/useGetState';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { reduceEffectReducers } from '@/utils/reducerUtils';

import { useRecomputeSectionWidthsEffect } from '../effects';
import { useCompleteInitialiationEffect } from '../effects/completeInitialization.effect';
import { useEditMutationEffect } from '../effects/editMutation.effect';
import { useFitViewEffect } from '../effects/fitView.effect';
import {
  estateWaterfallReducer,
  estateWaterfallStateTransformReducer,
} from '../reducers';
import { generateDefaultState } from '../state/generateDefaultState';
import {
  EstateWaterfallEffectsMap,
  EstateWaterfallInitialStateGetter,
} from '../types';
import { EstateWaterfallAction } from '../types/actions';
import { EstateWaterfallEffect } from '../types/effects';
import {
  EstateWaterfallState,
  EstateWaterfallStateProps,
} from '../types/state';
import { EstateWaterfallContext } from './estateWaterfall.context';

export interface EstateWaterfallProviderProps
  extends Pick<
    EstateWaterfallStateProps,
    | 'waterfall'
    | 'primaryClients'
    | 'isFilteredWaterfall'
    | 'isEmptyWaterfall'
    | 'visibleNodeIds'
    | 'hiddenNodeIds'
  > {
  children: JSX.Element;
  initWaterfallNodeIds: Set<string>;
  registerWaterfallReady?: (waterfallId: string) => void;
  presentationMode: boolean;
  containerDimensions: UseMeasureRect;
}

export const rootReducer = reduceEffectReducers([
  // Primary reducer that handles incoming actions
  estateWaterfallReducer,
  // Reducer that composes state transform functions. Add computations
  // here that are true for every action type and computed based on state
  // or
  // Derived data which is computed from state, but is more in the form of helper
  // boolean flags, object-by-id look up maps, etc.
  estateWaterfallStateTransformReducer,
]);

function useEffectsMap({
  containerDimensions,
}: {
  containerDimensions: UseMeasureRect;
}): EstateWaterfallEffectsMap {
  const fitViewEffect = useFitViewEffect({
    containerDimensions,
  });
  const completeInitializationEffect = useCompleteInitialiationEffect();
  const editMutationEffect = useEditMutationEffect();
  const recomputeSectionWidthsEffect = useRecomputeSectionWidthsEffect();

  return useMemo<EstateWaterfallEffectsMap>(() => {
    return {
      fitViewEffect,
      completeInitializationEffect,
      editMutationEffect,
      recomputeSectionWidthsEffect,
    };
  }, [
    completeInitializationEffect,
    editMutationEffect,
    fitViewEffect,
    recomputeSectionWidthsEffect,
  ]);
}

export const EstateWaterfallProvider = ({
  children,
  waterfall,
  primaryClients,
  isFilteredWaterfall,
  isEmptyWaterfall,
  visibleNodeIds,
  hiddenNodeIds,
  initWaterfallNodeIds,
  registerWaterfallReady,
  presentationMode,
  containerDimensions,
}: EstateWaterfallProviderProps) => {
  const { isTwoClientHousehold } = useHouseholdDetailsContext();
  const isNodesInitialized = useNodesInitialized();
  const { flowBounds } = useFlowChartContext();
  const visibility = useDocumentVisibility();

  const effectsMap = useEffectsMap({
    containerDimensions,
  });

  const getInitialState = useCallback<EstateWaterfallInitialStateGetter>(
    (_queueEffect) => {
      return generateDefaultState({
        waterfall,
        primaryClients,
        isTwoClientHousehold,
        featureFlags: compact([]),
        isFilteredWaterfall,
        isEmptyWaterfall,
        visibleNodeIds,
        hiddenNodeIds,
        initWaterfallNodeIds,
        presentationMode,
      });
    },
    [
      hiddenNodeIds,
      initWaterfallNodeIds,
      isFilteredWaterfall,
      isEmptyWaterfall,
      isTwoClientHousehold,
      presentationMode,
      primaryClients,
      visibleNodeIds,
      waterfall,
    ]
  );

  const [state, dispatch] = useEffectReducer<
    EstateWaterfallState,
    EstateWaterfallAction,
    EstateWaterfallEffect
  >(rootReducer, getInitialState, effectsMap);

  const getState = useGetState(state);

  // Update state when the input variables to compute a waterfall change
  useEffect(() => {
    // Note: This effect is idempotent, but it's nice to add these conditions to clean up the logs a bit
    if (getState().isInitializing) return;
    if (isEqual(getState().waterfall, waterfall)) return;

    dispatch({
      type: 'UPDATE_WATERFALL',
      waterfall,
      primaryClients,
      isTwoClientHousehold,
      isFilteredWaterfall,
      isEmptyWaterfall,
      visibleNodeIds,
      hiddenNodeIds,
      initWaterfallNodeIds,
      presentationMode,
    });
  }, [
    dispatch,
    getState,
    hiddenNodeIds,
    initWaterfallNodeIds,
    isFilteredWaterfall,
    isEmptyWaterfall,
    isTwoClientHousehold,
    presentationMode,
    primaryClients,
    visibleNodeIds,
    waterfall,
  ]);

  // Once react flow has measured everything, we can start positioning nodes
  useEffect(() => {
    if (!isNodesInitialized) return;

    if (state.isInitializing) {
      dispatch({ type: 'INITIALIZE_NODE_POSITIONS' });
    } else {
      // IMPORTANT code branch: isNodesInitalized can flip from true to false AFTER we've initalized our own node layout.
      // The most basic example of this is creating a group node that doesn't have a height / width until measured by reactflow,
      // so we need to capture this measurement event by "re-measuring" our internal styles, primarily on section groups.
      dispatch({ type: 'REACTFLOW_NODE_MEASUREMENT_EVENT' });
    }
  }, [dispatch, isNodesInitialized, state.isInitializing]);

  useEffect(() => {
    dispatch({
      type: 'FLOW_BOUNDS_CHANGED',
      height: flowBounds.height,
      width: flowBounds.width,
    });
  }, [flowBounds.height, flowBounds.width, dispatch]);

  const waterfallReady =
    visibility === 'visible' && isNodesInitialized && !state.isInitializing;

  useEffect(() => {
    if (!waterfallReady) return;

    registerWaterfallReady?.(waterfall.id);
  }, [registerWaterfallReady, waterfall.id, waterfallReady]);

  const value = useMemo(
    () => ({ state, dispatch, getState }),
    [dispatch, getState, state]
  );

  // The flow chart requires the tab to be active to render correctly
  // https://github.com/xyflow/xyflow/issues/2636
  if (visibility !== 'visible' && !isNodesInitialized) return null;

  return (
    <EstateWaterfallContext.Provider value={value}>
      {children}
    </EstateWaterfallContext.Provider>
  );
};
