import { css } from '@emotion/css';
import { Box, Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { Link } from '@/components/form/baseInputs/Link';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { PresentationSlideSecondaryHeading } from '@/modules/presentation/components/PresentationSlideSecondaryHeading';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { ProfessionalTeamMember } from '@/modules/presentation/ProfessionalTeamSlide/ProfessionalTeamMember';
import { useProfessionalTeamSlideColumns } from '@/modules/presentation/ProfessionalTeamSlide/ProfessionalTeamSlide';
import { ProfessionalTeamSlideMember } from '@/modules/presentation/ProfessionalTeamSlide/ProfessionalTeamSlide.utils';
import { ROUTE_KEYS } from '@/navigation/constants';
import { PresentationBundleKind } from '@/types/schema';

import {
  ClientPresentationDesignerV2ViewMode,
  useClientPresentationDesignerV2Context,
  useRegisterSlide,
} from '../../../ClientPresentationDesignerV2.context';
import { ClientPresentationV2Footer } from '../../ClientPresentationV2Footer';
import { BaseBundleSlideProps } from '../BundleSlide.types';

interface ProfessionalTeamSlideInnerProps extends BaseBundleSlideProps {
  professionalTeam: ProfessionalTeamSlideMember[];
}

function ProfessionalTeamSlideInner({
  bundle,
  SlideWrapper = Box,
  professionalTeam,
  isVisible,
}: ProfessionalTeamSlideInnerProps) {
  const { household } = useClientPresentationDesignerV2Context();
  const slideId = `professional-team-${bundle.id}`;
  const listColumnsStyles = useProfessionalTeamSlideColumns();

  useRegisterSlide({
    slideId,
    title: 'Professional team',
    includeInToC: true,
    bundleKind: PresentationBundleKind.ProfessionalTeamBundle,
  });

  if (!isVisible) {
    return null;
  }

  return (
    <SlideWrapper>
      <PresentationSlide.Slide
        id={slideId}
        leftHeaderContent={
          <PresentationSlide.MainHeading heading={'Professional team'} />
        }
        rightHeaderContent={
          <PresentationSlideSecondaryHeading
            clientDisplayName={household?.displayName || null}
          />
        }
        footer={
          <ClientPresentationV2Footer
            slideId={`professional-team-${bundle.id}`}
          />
        }
      >
        <Box className={css(listColumnsStyles)}>
          {professionalTeam.map((teamMember, index) => (
            <ProfessionalTeamMember
              key={`${teamMember.name}-${index}`}
              teamMember={teamMember}
            />
          ))}
        </Box>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}

export function ProfessionalTeamSlide({
  bundle,
  SlideWrapper,
  isVisible,
}: BaseBundleSlideProps) {
  const { navigate } = useNavigateToRoute();
  const { householdId, professionalTeam, viewMode } =
    useClientPresentationDesignerV2Context();

  const { isTwoClientHousehold, primaryClients } = useHouseholdDetailsContext();

  if (
    isEmpty(professionalTeam) &&
    (viewMode === ClientPresentationDesignerV2ViewMode.Print || !isVisible)
  ) {
    return null;
  } else if (isEmpty(professionalTeam)) {
    let nameDisplay = `${primaryClients?.[0]?.firstName}'s`;
    if (isTwoClientHousehold) {
      nameDisplay = `${primaryClients?.[0]?.firstName} and ${primaryClients?.[1]?.firstName}'s`;
    }

    return (
      <Callout severity="warning">
        <Stack spacing={0.5}>
          <Typography variant="h5">Missing data</Typography>
          <Typography variant="subtitle1">
            You have not specified any individuals as members of {nameDisplay}{' '}
            &quot;Professional Team&quot;. Add individuals via the link below.
          </Typography>
          <Box>
            <Link
              display="Go to client team"
              onClick={() =>
                navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_PEOPLE_CLIENT_TEAM, {
                  householdId,
                })
              }
            />
          </Box>
        </Stack>
      </Callout>
    );
  }

  return (
    <ProfessionalTeamSlideInner
      bundle={bundle}
      SlideWrapper={SlideWrapper}
      professionalTeam={professionalTeam}
      isVisible={isVisible}
    />
  );
}
