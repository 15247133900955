import { Stack } from '@mui/material';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';
import { RichTreeViewPro } from '@mui/x-tree-view-pro/RichTreeViewPro';
import { compact, isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { COLORS } from '@/styles/tokens/colors';

import { HEADER_HEIGHT } from '../../ClientPresentationDesignerV2.components';
import { useNavigateToSlide } from '../../ClientPresentationDesignerV2.hooks';
import { ClientPresentationV2Shape } from '../../ClientPresentationDesignerV2.types';
import { PresentationManager } from '../PresentationManager/PresentationManager';
import { ClientPresentationDesignerV2TreeViewItem } from './ClientPresentationDesignerV2TreeView.components';
import {
  isFreeMovementPageType,
  isSinglePageBundle,
} from './ClientPresentationDesignerV2TreeView.constants';
import { PresentationTreeViewItem } from './ClientPresentationDesignerV2TreeView.types';
import {
  mapPresentationToTreeViewForNav,
  onItemPositionChange,
  TreeItemChangeParam,
} from './ClientPresentationDesignerV2TreeView.utils';

export function ClientPresentationDesignerV2TreeView() {
  const apiRef = useTreeViewApiRef();
  const { control, setValue } = useFormContext<ClientPresentationV2Shape>();
  const [bundles, selectedItemId] = useWatch({
    control,
    name: ['bundles', 'selectedItemId'],
  });
  const { navigateToSlide } = useNavigateToSlide();

  const items = useMemo<PresentationTreeViewItem[]>(
    () => mapPresentationToTreeViewForNav(bundles),
    [bundles]
  );

  const handleItemClick = useCallback(
    (_: unknown, itemId: string) => {
      navigateToSlide(itemId);
    },
    [navigateToSlide]
  );

  const defaultExpandedItems = useMemo(() => {
    return compact(
      items.map((item) => (!isEmpty(item.children) ? item.id : null))
    );
  }, [items]);

  const handleItemPositionChange = useCallback(
    (param: TreeItemChangeParam) => {
      const newBundles = onItemPositionChange(param, bundles);
      setValue('bundles', newBundles);
    },
    [bundles, setValue]
  );

  const canMoveItemToNewPosition = ({
    oldPosition,
    newPosition,
    itemId,
  }: TreeItemChangeParam) => {
    const newParent: PresentationTreeViewItem | null = newPosition.parentId
      ? apiRef.current!.getItem(newPosition.parentId)
      : null;

    const currentItem: PresentationTreeViewItem | null =
      apiRef.current!.getItem(itemId);

    // same parent -- always allow move
    if (newPosition.parentId === oldPosition.parentId) {
      return true;
    }

    // not a free movement page type
    if (!isFreeMovementPageType(currentItem?.type)) {
      return false;
    }

    // free movement to root is always allowed
    if (newPosition.parentId === null || !newParent?.type) {
      return true;
    }

    // free movement is only allowed to bundles that support multiple pages
    if (!newParent.isBundle || isSinglePageBundle(newParent.type)) {
      return false;
    }

    return true;
  };

  return (
    <Stack
      sx={{
        overflowY: 'auto',
        height: `calc(100vh - ${HEADER_HEIGHT})`,
        borderRight: `1px solid ${COLORS.ORANGE[100]}`,
        flex: '0 0 320px',
        pt: 2,
        width: '320px',
      }}
    >
      <PresentationManager />
      <RichTreeViewPro<PresentationTreeViewItem>
        apiRef={apiRef}
        items={items}
        itemsReordering
        experimentalFeatures={{
          indentationAtItemLevel: true,
          itemsReordering: true,
        }}
        slots={{
          item: ClientPresentationDesignerV2TreeViewItem,
        }}
        selectedItems={selectedItemId || ''}
        defaultExpandedItems={defaultExpandedItems}
        onItemPositionChange={handleItemPositionChange}
        canMoveItemToNewPosition={canMoveItemToNewPosition}
        onItemClick={handleItemClick}
      />
    </Stack>
  );
}
