import { Stack } from '@mui/material';
import { Fragment } from 'react';

import { ClientPresentationV2RenderSlide } from '@/modules/presentation/clientPresentation/ClientPresentationDesignerV2/ClientPresentationDesignerV2.components';
import { ClientPresentationV2Shape } from '@/modules/presentation/clientPresentation/ClientPresentationDesignerV2/ClientPresentationDesignerV2.types';
import { isSinglePageBundle } from '@/modules/presentation/clientPresentation/ClientPresentationDesignerV2/components/ClientPresentationDesignerV2TreeView/ClientPresentationDesignerV2TreeView.constants';

export interface PrintOnlyClientPresentationV2SlideMapperProps {
  bundles: ClientPresentationV2Shape['bundles'];
  presentationConfiguration: ClientPresentationV2Shape['presentationConfiguration'];
  title: ClientPresentationV2Shape['title'];
}
export function PrintOnlyClientPresentationV2SlideMapper({
  bundles,
  presentationConfiguration,
  title,
}: PrintOnlyClientPresentationV2SlideMapperProps) {
  return (
    <Stack>
      {bundles.map((bundle, idx) => {
        const isSinglePage = isSinglePageBundle(bundle.type);
        if (isSinglePage) {
          return (
            <ClientPresentationV2RenderSlide
              key={`print-bundle-${bundle.id}-${idx}`}
              bundle={bundle}
              page={undefined}
              presentationConfiguration={presentationConfiguration}
              title={title}
              isVisible={true}
              bundleCount={bundles.length}
            />
          );
        }

        return (
          <Fragment key={`print-bundle-${bundle.id}-${idx}`}>
            {bundle.pages.map((page) => {
              return (
                <ClientPresentationV2RenderSlide
                  key={`print-bundle-${bundle.id}-page-${page.id}-${idx}`}
                  bundle={bundle}
                  page={page}
                  presentationConfiguration={presentationConfiguration}
                  title={title}
                  isVisible={true}
                  bundleCount={bundles.length}
                />
              );
            })}
          </Fragment>
        );
      })}
    </Stack>
  );
}
