import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { TestamentaryEntityKind } from '@/types/schema';

export const TESTAMENTARY_ENTITY = 'testamentaryEntity';

export const getTestamentaryEntityKindOptions = (
  nameOfDeadGrantor: string | null
): SelectInputOption<TestamentaryEntityKind>[] => [
  {
    display: 'Testamentary trust or subtrust',
    value: TestamentaryEntityKind.TestamentaryTrust,
  },
  {
    // nameOfDeadGrantor is null if we're in a scenario (outside of the DPs experience)
    // where we don't know which grantor is passing
    display: `Estate of ${nameOfDeadGrantor || 'grantor'}`,
    value: TestamentaryEntityKind.GrantorEstate,
  },
  {
    display: 'Inherited retirement account',
    value: TestamentaryEntityKind.InheritedRetirementAccount,
  },
  {
    display: 'Charitable trust',
    value: TestamentaryEntityKind.CharitableTrust,
  },
  {
    display: 'Charitable entity',
    value: TestamentaryEntityKind.CharitableEntity,
  },
];

export const controllingPartyEntityKindOptions = [
  TestamentaryEntityKind.GrantorEstate,
  TestamentaryEntityKind.InheritedRetirementAccount,
  TestamentaryEntityKind.CharitableEntity,
];
