import { useReactFlow } from '@xyflow/react';

import {
  ORDERED_TILE_FONT_SIZE_VARIANTS,
  TILE_DEFAULT_FONT_SIZE_VARIANT,
} from '@/components/diagrams/components/Tile';
import { MinusIcon } from '@/components/icons/MinusIcon';
import { PlusIcon } from '@/components/icons/PlusIcon';

import { useNode } from '../../../hooks/useNode';
import { TileNode } from '../../../types';
import { getNodeMeasuredWidthHeight } from '../../../utils/nodes';
import {
  TILE_NODE_CONTROL_BUTTON_ICON_SIZE,
  TileNodeControlButton,
} from './TileNodeControlButton';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TileNodeFontSizeControlsProps {}

export const TileNodeFontSizeControls = (
  _props: TileNodeFontSizeControlsProps
) => {
  const node = useNode<TileNode>();
  const { updateNode, updateNodeData } = useReactFlow();

  const fontSizeVariant =
    node.data.fontSizeVariant ?? TILE_DEFAULT_FONT_SIZE_VARIANT;

  const currentIndex = ORDERED_TILE_FONT_SIZE_VARIANTS.indexOf(fontSizeVariant);

  const handleSizeChange = (increment: boolean) => {
    const nextIndex = currentIndex + (increment ? 1 : -1);
    const adjustment = increment ? 1.25 : 0.75;

    const { height, width } = getNodeMeasuredWidthHeight(node);

    updateNode(node.id, {
      ...(height && { height: height * adjustment }),
      ...(width && { width: width * adjustment }),
    });

    updateNodeData(node.id, {
      fontSizeVariant: ORDERED_TILE_FONT_SIZE_VARIANTS[nextIndex],
    });
  };

  const handleIncrementSize = () => handleSizeChange(true);
  const handleDecrementSize = () => handleSizeChange(false);

  return (
    <>
      <TileNodeControlButton
        onClick={handleIncrementSize}
        disabled={currentIndex === ORDERED_TILE_FONT_SIZE_VARIANTS.length - 1}
      >
        <PlusIcon size={TILE_NODE_CONTROL_BUTTON_ICON_SIZE} />
      </TileNodeControlButton>
      <TileNodeControlButton
        onClick={handleDecrementSize}
        disabled={currentIndex === 0}
      >
        <MinusIcon size={TILE_NODE_CONTROL_BUTTON_ICON_SIZE} />
      </TileNodeControlButton>
    </>
  );
};
