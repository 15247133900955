import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { $downloadFileFromURL } from '@/modules/files/utils/fileUtils';
import { FIRST_DECEDENT_ID_QUERY_PARAM } from '@/modules/presentation/entities/components/EntityDiagram';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { usePrintEntityMutation } from './graphql/PrintEntity.generated';

function getPathToPrint(
  householdId: string,
  entityId: string,
  firstDecedentId: string | null
) {
  const searchParams = new URLSearchParams();
  if (firstDecedentId) {
    searchParams.set(FIRST_DECEDENT_ID_QUERY_PARAM, firstDecedentId);
  }

  const path = getCompletePathFromRouteKey(
    ROUTE_KEYS.__PRINT_ONLY_SINGLE_ENTITY,
    {
      householdId,
      entityId,
    },
    searchParams
  );

  // the lambda actually expects this whole path EXCEPT for the leading slash.
  return path.slice(1, path.length);
}

export function usePrintEntity(
  entityId: string,
  householdId: string,
  entityDisplayName: string
) {
  const [searchParams] = useSearchParams();
  const firstDecedentId = searchParams.get('firstDecedentId');
  const trackUserEvent = useTrackUserEvent();
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const [printDocument, mutationProps] = usePrintEntityMutation({
    variables: {
      householdId,
      pathToPrint: getPathToPrint(householdId, entityId, firstDecedentId),
    },
    onError: (err) => {
      reportError('Failed to print entity', err);
      showFeedback(`We weren't able to generate the PDF. Please try again.`);
    },
  });

  const handlePrintAndDownloadDocument = useCallback(async () => {
    await printDocument({
      onCompleted: (data) => {
        const downloadUrl = data.print.download.downloadURL;
        try {
          void $downloadFileFromURL(downloadUrl, entityDisplayName);
          trackUserEvent('entity exported', {
            entityId,
            householdId,
          });
          showFeedback('PDF generated successfully', { variant: 'success' });
        } catch (err) {
          showFeedback('Failed to download PDF. Please try again.');
        }
      },
    });
  }, [
    entityDisplayName,
    entityId,
    householdId,
    printDocument,
    showFeedback,
    trackUserEvent,
  ]);

  return {
    printDocument,
    handlePrintAndDownloadDocument,
    ...mutationProps,
  };
}
