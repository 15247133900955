import Decimal from 'decimal.js';
import React from 'react';

import { EditButton } from '@/components/form/baseInputs/Button/EditButton';
import { getSaleLoanKindDisplay } from '@/modules/hypotheticalSaleLoans/displayUtils';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import {
  GetHypotheticalTransfers_EstateWaterfallLoanFragment,
  GetHypotheticalTransfers_HypotheticalSaleLoanFragment,
} from './graphql/GetHypotheticalTransfers.generated';
import {
  getSaleLoanFundingValueFromEWLoan,
  getSaleLoanRepaymentValueFromEWLoan,
} from './HypotheticalTransfersCard.utils';
import { TransferTab } from './TransferItems';

export interface SaleLoanTableItem {
  id: string;
  heading: string;
  description: string;
  context: {
    heading: string;
    headingSx?: { color: string };
    subheading: string;
    action: React.ReactNode;
  };
}

type Side = 'source' | 'recipient'; // source is the side that is being loaned from, recipient is the side that is being loaned to

export function getSaleLoanHeading(
  side: Side,
  saleLoan: GetHypotheticalTransfers_HypotheticalSaleLoanFragment
): string {
  if (side === 'recipient') {
    switch (saleLoan.recipientKind) {
      case 'ENTITY':
        return (
          saleLoan.recipientEntity?.subtype?.displayName ?? 'Unknown entity'
        );
      case 'INDIVIDUAL':
        return (
          saleLoan.recipientIndividual?.displayName ?? 'Unknown individual'
        );
      default:
        return 'Unknown recipient';
    }
  } else {
    switch (saleLoan.sourceKind) {
      case 'ENTITY':
        return saleLoan.sourceEntity?.subtype?.displayName ?? 'Unknown entity';
      case 'INDIVIDUAL':
        return saleLoan.sourceIndividual?.displayName ?? 'Unknown individual';
      default:
        return 'Unknown source';
    }
  }
}

interface CreateSaleLoanTableItemParams {
  /* The heading is which side of the transfer to show as the heading text */
  heading: Side;
  /* The direction indicates whether the amount in inbound or outbound */
  direction: TransferTab;
}

// createSaleLoanTableFundingItem is to create the table item to show the
// initial sale/loan from the source to the recipient
export function createSaleLoanTableFundingItem(
  saleLoan: GetHypotheticalTransfers_HypotheticalSaleLoanFragment,
  loanProjections: GetHypotheticalTransfers_EstateWaterfallLoanFragment | null,
  params: CreateSaleLoanTableItemParams,
  onEditSaleLoan: (saleLoanId: string) => void
): { item: SaleLoanTableItem; value: Decimal } {
  const value = loanProjections
    ? getSaleLoanFundingValueFromEWLoan(loanProjections)
    : new Decimal(0);

  const transferredValue = value.times(
    params.direction === TransferTab.Outbound ? -1 : 1
  );
  const heading = formatCurrencyNoDecimals(transferredValue);

  const startYear = saleLoan.startDate.getFullYear();
  const endYear = startYear + saleLoan.termLengthYears;

  return {
    item: {
      id: saleLoan.id,
      heading: getSaleLoanHeading(params.heading, saleLoan),
      description: getSaleLoanKindDisplay(saleLoan.kind),
      context: {
        heading,
        headingSx: transferredValue.lt(0)
          ? { color: COLORS.MATH.NEGATIVE }
          : undefined,
        subheading: `${startYear}-${endYear}`,
        action: (
          <EditButton
            onClick={() => {
              onEditSaleLoan(saleLoan.id);
            }}
          />
        ),
      },
    },
    value: transferredValue,
  };
}

// createSaleLoanTableRepaymentItem is to create the table item to show the
// repayment of the sale/loan from the recipient to the source
export function createSaleLoanTableRepaymentItem(
  saleLoan: GetHypotheticalTransfers_HypotheticalSaleLoanFragment,
  loanProjections: GetHypotheticalTransfers_EstateWaterfallLoanFragment | null,
  params: CreateSaleLoanTableItemParams,
  onEditSaleLoan: (saleLoanId: string) => void
): { item: SaleLoanTableItem; value: Decimal } {
  const value = loanProjections
    ? getSaleLoanRepaymentValueFromEWLoan(loanProjections)
    : new Decimal(0);
  const transferredValue = value.times(
    params.direction === TransferTab.Outbound ? -1 : 1
  );
  const heading = formatCurrencyNoDecimals(transferredValue);
  const startYear = saleLoan.startDate.getFullYear();
  const endYear = startYear + saleLoan.termLengthYears;

  return {
    item: {
      id: saleLoan.id,
      heading: getSaleLoanHeading(params.heading, saleLoan),
      description: getSaleLoanKindDisplay(saleLoan.kind),
      context: {
        heading,
        headingSx: transferredValue.lt(0)
          ? { color: COLORS.MATH.NEGATIVE }
          : undefined,
        subheading: `${startYear}-${endYear}`,
        action: (
          <EditButton
            onClick={() => {
              onEditSaleLoan(saleLoan.id);
            }}
          />
        ),
      },
    },
    value: transferredValue,
  };
}
