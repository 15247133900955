import { useTheme } from '@mui/material';
import { NodeToolbar, NodeToolbarProps, Position } from '@xyflow/react';

import {
  ControlButtonGroup,
  ControlButtonGroupProps,
} from '../../../components/Controls/ControlButtonGroup';

export interface TileNodeControlsProps extends NodeToolbarProps {
  children: ControlButtonGroupProps['children'];
}

export const TileNodeControls = ({
  children,
  ...props
}: TileNodeControlsProps) => {
  const theme = useTheme();

  return (
    <NodeToolbar
      position={Position.Bottom}
      style={{ boxShadow: theme.palette.shadows.md }}
      {...props}
    >
      <ControlButtonGroup>{children}</ControlButtonGroup>
    </NodeToolbar>
  );
};
