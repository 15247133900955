import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import {
  Logomark,
  LogomarkSize,
  LogomarkVariant,
} from '@/components/brand/Logomark/Logomark';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { COLORS } from '@/styles/tokens/colors';

import { DisclaimerFooter } from './DisclaimerFooter';

interface MainHeadingProps {
  heading: React.ReactNode;
  subheading?: React.ReactNode;
}

export function MainHeading({ heading, subheading }: MainHeadingProps) {
  const isDisplayTypographyEnabled = useFeatureFlag(
    'use_serif_font_in_presentation'
  );
  return (
    <Stack>
      <Typography
        sx={(t) => ({ color: t.palette.primary.main })}
        variant={isDisplayTypographyEnabled ? 'h1_display' : 'h1'}
      >
        {heading}
      </Typography>
      <Typography
        sx={(t) => ({
          color: COLORS.NEUTRAL_GRAY[500],
          fontWeight: t.typography.fontWeightRegular,
        })}
        variant="h4"
      >
        {subheading}
      </Typography>
    </Stack>
  );
}

export interface SecondaryHeadingProps {
  lineOne: React.ReactNode;
  lineTwo?: React.ReactNode;
}

export function SecondaryHeading({ lineOne, lineTwo }: SecondaryHeadingProps) {
  return (
    <Stack sx={{ textAlign: 'right' }}>
      <Typography
        component="div"
        sx={{
          color: COLORS.GRAY[900],
        }}
        variant="h6"
      >
        {lineOne}
      </Typography>
      <Typography
        component="div"
        sx={(theme) => ({
          color: COLORS.GRAY[900],
          fontWeight: theme.typography.fontWeightRegular,
        })}
        variant="h6"
      >
        {lineTwo}
      </Typography>
    </Stack>
  );
}

interface FooterProps {
  hideDisclaimer?: boolean;
}

export function Footer({ hideDisclaimer = false }: FooterProps) {
  return (
    <Box sx={{ px: 2 }}>
      <Stack
        sx={{ borderTop: `solid ${COLORS.GRAY[200]} 1px`, pt: 1 }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Logomark
          style={{ maxHeight: 60, width: 'auto' }}
          size={LogomarkSize.Wide}
          variant={LogomarkVariant.Primary}
        />
        <Box sx={{ width: 700, textAlign: 'right' }}>
          {!hideDisclaimer && <DisclaimerFooter variant="standard" />}
        </Box>
      </Stack>
    </Box>
  );
}
