import { Box, Stack, Typography } from '@mui/material';
import { BaseSyntheticEvent, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { FormAwareCheckbox } from '@/components/form/formAwareInputs/FormAwareCheckbox';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Card } from '@/components/layout/Card/Card';
import { useFormContext } from '@/components/react-hook-form/hooks';
import { getMaxLengthValidation } from '@/components/utils/validators';
import { HelpIconButton } from '@/modules/content/components/HelpIconButton';
import { useDefaultDisclaimerSlideTitle } from '@/modules/presentation/clientPresentation/DisclaimerSlide/useDefaultDisclaimerSlideTitle';
import { PathsOf } from '@/types/subform';

import { LuminaryDisclaimerModal } from './LuminaryDisclaimerModal';
import { StandaloneDisclaimerPreview } from './StandaloneDisclaimerPreview';

export interface LegalDisclaimersFormShape {
  presentationCoverDisclaimerTitle: string;
  presentationCoverDisclaimerBody: string;
  presentationFooterDisclaimerTitle: string;
  presentationFooterDisclaimerBody: string;
  presentationStandaloneDisclaimerTitle: string;
  presentationStandaloneDisclaimerBody: string;
  enableLuminaryDisclaimer: boolean;
}

export type FormField = PathsOf<LegalDisclaimersFormShape>;

export interface LegalDisclaimersFormProps {
  onSubmit: (
    e?: BaseSyntheticEvent<object, unknown, unknown> | undefined
  ) => Promise<void>;
}

// these constants are also defined in the backend for DB schema purposes,
// so if you change them here, you need to change them there too
const BODY_MAX_LENGTH = 500;
const HEADING_MAX_LENGTH = 100;

export function LegalDisclaimersForm({ onSubmit }: LegalDisclaimersFormProps) {
  const [isLuminaryDisclaimerModalOpen, setLuminaryDisclaimerModalOpen] =
    useState(false);
  const {
    control,
    formState: { isSubmitting, isLoading },
  } = useFormContext<LegalDisclaimersFormShape>();
  const defaultDisclaimerSlideTitle = useDefaultDisclaimerSlideTitle();
  return (
    <>
      <LuminaryDisclaimerModal
        isOpen={isLuminaryDisclaimerModalOpen}
        onClose={() => setLuminaryDisclaimerModalOpen(false)}
      />
      <FormFieldsDisabledProvider isDisabled={isLoading}>
        <Stack component="form" spacing={5} onSubmit={onSubmit}>
          <Box>
            <Box mb={2}>
              <Typography variant="h2">Cover slide disclaimer</Typography>
              <Typography variant="body1">
                This will appear along the bottom of the cover slide.
              </Typography>
            </Box>
            <Stack spacing={3}>
              <FormAwareTextInput
                control={control}
                label="Headline"
                fieldName={
                  `presentationCoverDisclaimerTitle` as const satisfies FormField
                }
                validation={{
                  maxLength: (value) =>
                    getMaxLengthValidation(HEADING_MAX_LENGTH)(value as string),
                }}
              />
              <FormAwareTextInput
                control={control}
                label="Content"
                rows={4}
                multiline
                fieldName={
                  `presentationCoverDisclaimerBody` as const satisfies FormField
                }
                validation={{
                  maxLength: (value) =>
                    getMaxLengthValidation(BODY_MAX_LENGTH)(value as string),
                }}
              />
            </Stack>
          </Box>
          <Box>
            <Box mb={2}>
              <Typography variant="h2">Footer disclaimer</Typography>
              <Typography variant="body1">
                This will appear in the footer of every slide (excluding the
                cover & standalone disclaimer slide).
              </Typography>
            </Box>
            <Stack spacing={3}>
              <FormAwareTextInput
                control={control}
                label="Headline"
                fieldName={
                  `presentationFooterDisclaimerTitle` as const satisfies FormField
                }
                validation={{
                  maxLength: (value) =>
                    getMaxLengthValidation(HEADING_MAX_LENGTH)(value as string),
                }}
              />
              <FormAwareTextInput
                control={control}
                label="Content"
                rows={4}
                multiline
                fieldName={
                  `presentationFooterDisclaimerBody` as const satisfies FormField
                }
                validation={{
                  maxLength: (value) =>
                    getMaxLengthValidation(BODY_MAX_LENGTH)(value as string),
                }}
              />
            </Stack>
          </Box>
          <Box>
            <Box mb={2}>
              <Typography variant="h2">Standalone disclaimer</Typography>
              <Typography variant="body1">
                Comprehensive disclaimers are included on a dedicated slide at
                the end of presentations.
              </Typography>
            </Box>
            <Stack spacing={3}>
              <FormAwareTextInput
                control={control}
                label="Headline"
                placeholder={defaultDisclaimerSlideTitle}
                fieldName={
                  `presentationStandaloneDisclaimerTitle` as const satisfies FormField
                }
                validation={{
                  maxLength: (value) =>
                    getMaxLengthValidation(HEADING_MAX_LENGTH)(value as string),
                }}
              />
              <FormAwareTextInput
                control={control}
                label="Content"
                rows={4}
                multiline
                fieldName={
                  `presentationStandaloneDisclaimerBody` as const satisfies FormField
                }
                validation={{
                  maxLength: (value) =>
                    getMaxLengthValidation(6000)(value as string),
                }}
              />
            </Stack>
          </Box>
          <Box>
            <Card variant="outlined" sx={{ p: 1 }}>
              <FormAwareCheckbox
                control={control}
                label={
                  <Stack direction="row" spacing={0.5}>
                    <span>Show Luminary disclaimer slide</span>
                    <HelpIconButton
                      buttonProps={{
                        onClick: () => setLuminaryDisclaimerModalOpen(true),
                      }}
                    />
                  </Stack>
                }
                fieldName={
                  `enableLuminaryDisclaimer` as const satisfies FormField
                }
              />
            </Card>
          </Box>
          <StandaloneDisclaimerPreview />
          <Stack direction="row" justifyContent="flex-end">
            <Button
              loading={isSubmitting}
              type="submit"
              variant="primary"
              size="md"
            >
              Save changes
            </Button>
          </Stack>
        </Stack>
      </FormFieldsDisabledProvider>
    </>
  );
}
