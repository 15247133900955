import React, { useEffect, useState } from 'react';

import { InteractionParadigmContext } from '@/contexts/InteractionParadigm.context';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { useGuardedTenantPresentationConfigurationContext } from '@/modules/presentation/context/tenantPresentationConfiguration.context';
import { TenantPresentationConfigurationProvider } from '@/modules/presentation/context/TenantPresentationConfiguration.provider';
import { useSubBrand } from '@/modules/tenant/TenantDetailsContext/useSubBrand';
import { usePresentation } from '@/pages/client-proposal/hooks/usePresentation';
import { useProposalsQuery } from '@/pages/client-proposal/hooks/useProposalsQuery';
import { PrintSlides } from '@/pages/client-proposal/PrintSlides';

import { PrintStatusSentinel } from '../components/PrintStatusSentinel';
import { useWaitForNetworkToSettle } from '../hooks/useWaitForNetworkToSettle';
import { PrintStatuses } from '../printOnly.constants';

function PrintOnlyClientProposalInner() {
  const [printStatus, setPrintStatus] = useState<PrintStatuses>(
    PrintStatuses.LOADING
  );
  const [hasSetDisclaimers, setHasSetDisclaimers] = useState(false);

  const proposalId = useRequiredParam('proposalId');

  const { proposal, proposalKind, subBrandId, data } = useProposalsQuery({
    proposalId,
  });

  const legalDisclaimers = data?.tenantInformation?.disclosuresConfiguration;

  useSubBrand(subBrandId ?? null);

  const { slides } = usePresentation({
    proposal,
    proposalKind,
    legalDisclaimers,
  });

  const { isDoneLoading, hasErrors } = useWaitForNetworkToSettle();

  useEffect(() => {
    if (hasErrors) {
      setPrintStatus(PrintStatuses.ERROR);
      return;
    }

    if (isDoneLoading && hasSetDisclaimers) {
      setPrintStatus(PrintStatuses.READY);
      return;
    }
  }, [hasErrors, hasSetDisclaimers, isDoneLoading, setPrintStatus]);

  const { setLegalDisclaimers } =
    useGuardedTenantPresentationConfigurationContext();
  useEffect(() => {
    if (legalDisclaimers) {
      setLegalDisclaimers(legalDisclaimers ?? null);
      setHasSetDisclaimers(true);
    }
  }, [legalDisclaimers, setLegalDisclaimers]);

  return (
    <>
      {/* this is used to indicate the status of the document to the PDF printing service, so it knows to delay until everything is ready */}
      <PrintStatusSentinel status={printStatus} />
      {proposal && (
        <PrintSlides
          slides={slides}
          proposal={proposal}
          legalDisclaimers={legalDisclaimers}
        />
      )}
    </>
  );
}

export const PrintOnlyClientProposal = () => {
  return (
    <InteractionParadigmContext.Provider value={{ viewOnly: true }}>
      <TenantPresentationConfigurationProvider>
        <PrintOnlyClientProposalInner />
      </TenantPresentationConfigurationProvider>
    </InteractionParadigmContext.Provider>
  );
};
