import { compact, isEqual, isUndefined } from 'lodash';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePrevious } from 'react-use';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import {
  getNewFileUploadFromUploadResult,
  NormalizedUploadedFileData,
} from '@/modules/documents/documentUploaderUtils';
import {
  MultiDocumentUploaderContext,
  SelectedExistingDocument,
} from '@/modules/documents/MultiDocumentUploader/context/multiDocumentUploader.context';
import {
  useInferDocumentTypesLazyQuery,
  useUploadDocumentsMutation,
} from '@/modules/documents/MultiDocumentUploader/graphql/MultiDocumentUploader.generated';
import { convertUploadedFilesToDocumentInputs } from '@/modules/documents/MultiDocumentUploader/multiDocumentUploader.utils';
import { CreateFileMutation } from '@/modules/files/FileUploader/graphql/CreateFile.generated';
import { UploadedFileData } from '@/modules/files/FileUploader/useUploadFile';
import { $downloadFileFromURL } from '@/modules/files/utils/fileUtils';
import { useAICapabilitiesEnabled } from '@/modules/tenant/TenantDetailsContext/hooks/useAICapabilitiesEnabled';
import {
  DocumentType,
  InferDocumentTypeInput,
  InferDocumentTypeOutput,
} from '@/types/schema';

function useContextValue(): MultiDocumentUploaderContext {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const aiCapabilitiesEnabled = useAICapabilitiesEnabled();

  const [bulkCreateMutation] = useUploadDocumentsMutation();
  const [getInferredTypes] = useInferDocumentTypesLazyQuery();

  const [selectedExistingDocuments, setSelectedExistingDocuments] = useState<
    SelectedExistingDocument[]
  >([]);

  const [uploadedFiles, setUploadedFiles] = useState<
    NormalizedUploadedFileData[]
  >([]);

  const [fileIDTypeMap, setFileIDTypeMap] = useState<
    Record<string, DocumentType>
  >({});

  const [inferringDocumentTypes, setInferringDocumentTypes] = useState(false);

  const [inferredTypes, setInferredTypes] = useState<InferDocumentTypeOutput[]>(
    []
  );

  const [uploaderErrorMessage, setUploaderErrorMessage] = useState<
    string | undefined
  >(undefined);

  const uploadedFileNameToIDMap = useMemo(() => {
    return uploadedFiles.reduce<Record<string, string>>((acc, curr) => {
      acc[curr.fileName] = curr.fileId;
      return acc;
    }, {});
  }, [uploadedFiles]);

  const inferredTypePrevious = usePrevious(inferredTypes);
  const fileIDTypeMapPrevious = usePrevious(fileIDTypeMap);

  useEffect(() => {
    if (isUndefined(inferredTypePrevious)) return;
    if (isUndefined(fileIDTypeMapPrevious)) return;

    if (
      isEqual(inferredTypes, inferredTypePrevious) &&
      isEqual(fileIDTypeMap, fileIDTypeMapPrevious)
    )
      return;

    const newFileIDTypeMap = Object.assign(
      {},
      fileIDTypeMap,
      inferredTypes.reduce<Record<string, DocumentType>>(
        (acc, curr) => {
          const fileID = uploadedFileNameToIDMap[curr.fileName];
          if (!fileID) return acc;
          acc[fileID] = curr.documentType;
          return acc;
        },
        {} as Record<string, DocumentType>
      )
    );

    setFileIDTypeMap((prev) => ({
      ...newFileIDTypeMap,
      ...prev,
    }));
  }, [
    uploadedFileNameToIDMap,
    inferredTypes,
    fileIDTypeMap,
    inferredTypePrevious,
    fileIDTypeMapPrevious,
  ]);

  const clearUploaderErrorMessage = useCallback(() => {
    setUploaderErrorMessage(undefined);
  }, []);

  const handleUploadFilesStarted = useCallback(
    (files: File[]) => {
      clearUploaderErrorMessage();

      if (!aiCapabilitiesEnabled) {
        // If tenant-level AI capabilities are not enabled, don't try to infer document types.
        return;
      }

      const input = files.map<InferDocumentTypeInput>((file) => ({
        // Since we're doing this pre-upload, we don't have a file ID yet, so
        // we'll just generate a simple unique-enough ID.
        // We can't use the file name as the ID because it might contain special
        // characters that the LLM escapes non-deterministically.
        id: Math.random().toString(36).slice(2, 11), // 9 characters of alphanumeric
        fileName: file.name,
      }));

      setInferringDocumentTypes(true);

      void getInferredTypes({
        variables: { input },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
          // Map the inferred types back to the original file names, using the IDs we generated.
          const inferredTypes = data.inferDocumentTypes.map((d) => ({
            ...d,
            fileName: input.find((i) => i.id === d.id)?.fileName || '',
          }));
          setInferredTypes(inferredTypes);
          setInferringDocumentTypes(false);
        },
        onError: (error) => {
          setInferringDocumentTypes(false);
          reportError(
            'MultiDocumentUploader: Could not infer document types',
            error
          );
          showFeedback(
            'Could not infer document types for uploaded files. Please manually set the document types.',
            { autoHideDuration: 1000 }
          );
        },
      });
    },
    [
      aiCapabilitiesEnabled,
      clearUploaderErrorMessage,
      getInferredTypes,
      reportError,
      showFeedback,
    ]
  );

  const handleUploadFilesComplete = useCallback(
    (uploaded: UploadedFileData<CreateFileMutation>[]) => {
      const results = compact(
        uploaded.map(({ file, result }) =>
          getNewFileUploadFromUploadResult(file, result?.data)
        )
      );
      setUploadedFiles((prev) => [...prev, ...results]);
    },
    []
  );

  const handleDeleteFile = useCallback((fileId: string | undefined) => {
    setUploadedFiles((prev) => prev.filter((f) => f.fileId !== fileId));
  }, []);

  const handleDownloadFile = useCallback(
    (downloadUrl: string, filename: string) => {
      try {
        void $downloadFileFromURL(downloadUrl, filename);
      } catch (err) {
        showFeedback('Failed to download document. Please try again.');
      }
    },
    [showFeedback]
  );

  const setDocumentTypeOfUploadedFile = useCallback(
    (fileId: string, documentType: DocumentType) => {
      setFileIDTypeMap((prev) => ({
        ...prev,
        [fileId]: documentType,
      }));
    },
    []
  );

  const uploadDocuments = useCallback(
    async ({
      householdId,
      suggestionsEnabled,
      defaultDocumentType,
      entityId,
    }: {
      householdId: string;
      suggestionsEnabled: boolean;
      defaultDocumentType: DocumentType;
      entityId?: string;
    }) => {
      const inputs = convertUploadedFilesToDocumentInputs({
        uploadedFiles,
        fileIDTypeMap,
        defaultDocumentType,
        householdId,
        suggestionsEnabled,
        entityId,
      });

      const result = await bulkCreateMutation({
        variables: {
          createDocumentInputs: inputs.filter(Boolean),
          // if we're associating existing documents to a new entity as part of the upload,
          // we should mark them as having AI suggestions enabled
          updateDocumentInputs: selectedExistingDocuments.map(({ id }) => ({
            id,
            update: {
              enableAiSuggestions: aiCapabilitiesEnabled,
            },
          })),
        },
      });

      // Return the document IDs that were created
      return result.data?.createDocuments.map(({ id }) => id) || [];
    },
    [
      uploadedFiles,
      fileIDTypeMap,
      bulkCreateMutation,
      selectedExistingDocuments,
      aiCapabilitiesEnabled,
    ]
  );

  const clearUploadedFiles = useCallback(() => {
    setUploadedFiles([]);
    setFileIDTypeMap({});
  }, []);

  return {
    uploadedFiles,
    handleUploadFilesStarted,
    handleUploadFilesComplete,
    handleDeleteFile,
    handleDownloadFile,
    setDocumentTypeOfUploadedFile,
    uploadDocuments,
    fileIDTypeMap,
    clearUploadedFiles,
    uploaderErrorMessage,
    setUploaderErrorMessage,
    clearUploaderErrorMessage,
    inferringDocumentTypes,
    selectedExistingDocuments,
    setSelectedExistingDocuments,
  };
}

export const MultiDocumentUploaderProvider = ({
  children,
}: PropsWithChildren) => {
  const value = useContextValue();

  return (
    <MultiDocumentUploaderContext.Provider value={value}>
      {children}
    </MultiDocumentUploaderContext.Provider>
  );
};
