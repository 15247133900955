import Decimal from 'decimal.js';

import { diagnostics } from '@/utils/diagnostics';

/**
 * This is useful if a Decimal is a dependency in a hook.
 */
export const getComparableDecimalJS = (
  value: Decimal | null,
  decimalScale = 2
) => {
  if (value === null) return value;
  return value.toFixed(decimalScale);
};

/**
 * Because Go's empty value is 0, values coming from the backend for Decimals are serialized
 * as "0". Because we expect to have decimals, we know that the "0" is for an uninitialized value,
 * and in order to render an empty input rather than an input that's prefilled with a zero-value,
 * we return null here instead;
 */
export function getNullFromEmptyDecimalJS(value: Decimal) {
  if (value.toString() === '0') {
    return null;
  }

  return value;
}

export function sumDecimalJS(decimalsToSum: Decimal[]): Decimal {
  if (decimalsToSum.length === 0) {
    return new Decimal(0);
  }

  if (decimalsToSum.length === 1 && decimalsToSum[0]) {
    return decimalsToSum[0];
  }

  // Decimal.sum() is a static method that takes an array of decimals
  // but it requries at least 2 arguments to sum.
  return Decimal.sum(...decimalsToSum);
}

/**
 * @description Use this instead of Decimal.max, because Decimal.max uses variadic arguments, and
 * spreading an empty array of decimals will pass "undefined", which will cause Decimal.max to throw
 */
export function maxDecimalJS<T>(
  decimals: Decimal[],
  emptyValue: T
): Decimal | T {
  if (decimals.length === 0) {
    return emptyValue;
  }

  // it's okay to do this here, because we're guaranteed to have at least one decimal in the array
  // eslint-disable-next-line ban/ban
  return Decimal.max(...decimals);
}

/**
 * @description Use this instead of Decimal.min, because Decimal.min uses variadic arguments, and
 * spreading an empty array of decimals will pass "undefined", which will cause Decimal.min to throw
 */
export function minDecimalJS<T>(
  decimals: Decimal[],
  emptyValue: T
): Decimal | T {
  if (decimals.length === 0) {
    return emptyValue;
  }

  // it's okay to do this here, because we're guaranteed to have at least one decimal in the array
  // eslint-disable-next-line ban/ban
  return Decimal.min(...decimals);
}

export function isDecimalJSAndNotZero(
  value?: Decimal | null
): value is Decimal {
  if (value && !value.isZero()) {
    return true;
  }

  return false;
}

/**
 * This converts a percentage string (ex: 33.3%) to a Decimal.
 */
export function parseDecimalJS(str: string): Decimal {
  // Remove any non-numeric characters except for the period
  const cleanedStr = str.replace(/[^\d.]/g, '');
  return new Decimal(cleanedStr);
}

/**
 * This tries to parse a string into a Decimal. Logs an error and returns null on failure.
 * The main use case for this is when parsing an AI suggestion that may have a decimal value (ex: beneficiaries, or dp recipients).
 * It's difficult to guarantee the LLM's response is always valid.
 */
export function parseDecimalJSOrNull(
  str: string | null | undefined
): Decimal | null {
  if (!str) return null; // This just consolidates the empty check into one place

  try {
    return parseDecimalJS(str);
  } catch (e) {
    diagnostics.error(
      `Failed to parse decimal from string: ${str}. Returning null.`,
      e as Error
    );
    return null;
  }
}
