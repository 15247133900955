import { Typography, TypographyProps } from '@mui/material';
import { HTMLAttributes } from 'react';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';

export const ROW_HEIGHT_PX = 24;
export const ROW_PADDING_HORIZONTAL_PX = 16;
export const ROW_PADDING_VERTICAL_PX = 4;

const useStyles = makeStyles()((_theme) => ({
  root: {
    paddingTop: `${ROW_PADDING_VERTICAL_PX}px`,
    paddingBottom: `${ROW_PADDING_VERTICAL_PX}px`,
    paddingLeft: `${ROW_PADDING_HORIZONTAL_PX}px`,
    paddingRight: `${ROW_PADDING_HORIZONTAL_PX}px`,
    display: 'flex',
    gap: '4px',
    '&:hover': {
      backgroundColor: COLORS.FUNCTIONAL.HOVER,
    },
  },
  description: {
    color: COLORS.GRAY[900],
  },
}));

export interface TileGroupItemProps extends HTMLAttributes<HTMLDivElement> {
  classes?: WithClasses<typeof useStyles>;
  typographyProps?: TypographyProps;
  description: string;
}

export const TileGroupItem = ({
  classes: externalClasses,
  typographyProps,
  description,
  ...props
}: TileGroupItemProps): JSX.Element => {
  const { classes } = useStyles(undefined, {
    props: { classes: externalClasses },
  });

  return (
    <div className={classes.root} {...props}>
      <Typography
        variant="subtitle2"
        noWrap
        className={classes.description}
        {...typographyProps}
      >
        {description}
      </Typography>
    </div>
  );
};
