// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientPresentationsButtonQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ClientPresentationsButtonQuery = { __typename?: 'Query', tenantInformation: { __typename?: 'TenantInformation', id: string, disclosuresConfiguration?: { __typename?: 'TenantDisclaimerConfiguration', id: string, includeLuminaryDisclaimer: boolean, presentationStandaloneDisclaimerBody?: string | null } | null } };


export const ClientPresentationsButtonDocument = gql`
    query ClientPresentationsButton {
  tenantInformation {
    id
    disclosuresConfiguration {
      id
      includeLuminaryDisclaimer
      presentationStandaloneDisclaimerBody
    }
  }
}
    `;

/**
 * __useClientPresentationsButtonQuery__
 *
 * To run a query within a React component, call `useClientPresentationsButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientPresentationsButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientPresentationsButtonQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientPresentationsButtonQuery(baseOptions?: Apollo.QueryHookOptions<ClientPresentationsButtonQuery, ClientPresentationsButtonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientPresentationsButtonQuery, ClientPresentationsButtonQueryVariables>(ClientPresentationsButtonDocument, options);
      }
export function useClientPresentationsButtonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientPresentationsButtonQuery, ClientPresentationsButtonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientPresentationsButtonQuery, ClientPresentationsButtonQueryVariables>(ClientPresentationsButtonDocument, options);
        }
export function useClientPresentationsButtonSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientPresentationsButtonQuery, ClientPresentationsButtonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientPresentationsButtonQuery, ClientPresentationsButtonQueryVariables>(ClientPresentationsButtonDocument, options);
        }
export type ClientPresentationsButtonQueryHookResult = ReturnType<typeof useClientPresentationsButtonQuery>;
export type ClientPresentationsButtonLazyQueryHookResult = ReturnType<typeof useClientPresentationsButtonLazyQuery>;
export type ClientPresentationsButtonSuspenseQueryHookResult = ReturnType<typeof useClientPresentationsButtonSuspenseQuery>;
export type ClientPresentationsButtonQueryResult = Apollo.QueryResult<ClientPresentationsButtonQuery, ClientPresentationsButtonQueryVariables>;
export const Operations = {
  Query: {
    ClientPresentationsButton: 'ClientPresentationsButton' as const
  }
}