import Decimal from 'decimal.js';
import { compact, first, includes, keys } from 'lodash';

import { getSaleLoanFundingValueFromEWLoan } from '@/modules/entities/details/HypotheticalTransfersCard/HypotheticalTransfersCard.utils';
import { CHARITABLE_ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { getEntityTypeFromSubtype } from '@/modules/entities/utils/getEntityTypeFromSubtype';
import { useEstateWaterfallSummaryBarChartsSectionColors } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/hooks/useEstateWaterfallSummaryBarChartsSectionColors.hook';
import { EstateWaterfallSummaryBarChartsSections } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/types';
import { EXTERNAL_TRANSFER_NODE_IDENTIFIER } from '@/modules/estateWaterfall/waterfallGraph/constants';
import { getSaleLoanKindDisplay } from '@/modules/hypotheticalSaleLoans/displayUtils';
import { COLORS } from '@/styles/tokens/colors';
import {
  AfterDeath,
  EntityInEstateStatus,
  EntityStage,
  EstateWaterfallHypotheticalTransferDestinationKind,
  EstateWaterfallHypotheticalTransferSourceKind,
  EstateWaterfallHypotheticalTransferTransferTaxKind,
  HypotheticalSaleLoanRecipientKind,
  HypotheticalSaleLoanSourceKind,
} from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { getNodes } from '@/utils/graphqlUtils';

import {
  HypotheticalTransfersSummary_EstateWaterfallHypotheticalTransferFragment,
  HypotheticalTransfersSummary_HypotheticalSaleLoanFragment,
  HypotheticalTransfersSummaryQuery_EstateWaterfallVizNodeFragment,
  HypotheticalTransfersSummaryQueryQuery,
} from './graphql/HypotheticalTransfersSummary.generated';
import {
  HYPOTHETICAL_TRANSFER_EXTERNAL_SENTINEL,
  HypotheticalTransfersSummaryMap,
  HypotheticalTransfersSummaryPanelTransfer,
  SummaryPanelTransferKind,
} from './HypotheticalTransfersSummaryPanel.types';

function getHypotheticalTransferSourceDisplayName({
  sourceKind,
  sourceEntity,
  sourceIndividual,
}: HypotheticalTransfersSummary_EstateWaterfallHypotheticalTransferFragment): string {
  if (
    sourceKind === EstateWaterfallHypotheticalTransferSourceKind.Entity &&
    sourceEntity?.subtype?.displayName
  ) {
    return sourceEntity.subtype.displayName;
  }

  if (
    sourceKind === EstateWaterfallHypotheticalTransferSourceKind.Individual &&
    sourceIndividual?.displayName
  ) {
    return sourceIndividual.displayName;
  }

  if (sourceKind === EstateWaterfallHypotheticalTransferSourceKind.External) {
    return 'External sources';
  }

  return 'Unknown sources';
}

function getSaleLoanSourceDisplayName({
  sourceKind,
  sourceEntity,
  sourceIndividual,
}: HypotheticalTransfersSummary_HypotheticalSaleLoanFragment): string {
  if (
    sourceKind === HypotheticalSaleLoanSourceKind.Entity &&
    sourceEntity?.subtype?.displayName
  ) {
    return sourceEntity.subtype.displayName;
  }

  if (
    sourceKind === HypotheticalSaleLoanSourceKind.Individual &&
    sourceIndividual?.displayName
  ) {
    return sourceIndividual.displayName;
  }

  return 'Unknown sources';
}

function getSourceColor(
  {
    id,
    sourceEntity,
    sourceIndividual,
  }:
    | HypotheticalTransfersSummary_EstateWaterfallHypotheticalTransferFragment
    | HypotheticalTransfersSummary_HypotheticalSaleLoanFragment,
  vizNodes: HypotheticalTransfersSummaryQuery_EstateWaterfallVizNodeFragment[],
  colors: ReturnType<typeof useEstateWaterfallSummaryBarChartsSectionColors>
): string | null {
  if (id === HYPOTHETICAL_TRANSFER_EXTERNAL_SENTINEL) {
    return COLORS.GRAY[300];
  }

  if (sourceIndividual) {
    return COLORS.NAVY[300];
  }
  if (!sourceEntity) {
    return null;
  }

  const vizNode = vizNodes.find((node) => node.id === sourceEntity.id);
  if (!vizNode) {
    return null;
  }

  if (vizNode.inEstateStatus === EntityInEstateStatus.InEstate) {
    return colors[EstateWaterfallSummaryBarChartsSections.InEstate];
  } else {
    const isCharitableEntity =
      sourceEntity.subtype?.__typename &&
      CHARITABLE_ENTITY_TYPES.includes(
        getEntityTypeFromSubtype(sourceEntity.subtype.__typename)
      );

    return colors[
      isCharitableEntity
        ? EstateWaterfallSummaryBarChartsSections.OutOfEstateCharity
        : EstateWaterfallSummaryBarChartsSections.OutOfEstateFamily
    ];
  }
}

function getHypotheticalTransferTargetDetails({
  destinationKind,
  destinationEntity,
  destinationIndividual,
  destinationOrganization,
  id: transferId,
}: HypotheticalTransfersSummary_EstateWaterfallHypotheticalTransferFragment): {
  targetId: string;
  targetDisplayName: string;
} {
  // since this relies on two checks (the destination has to be paired with the right response payload), there
  // are two error cases: an invalid destinationKind, and missing data for a valid destinationKind
  switch (destinationKind) {
    case EstateWaterfallHypotheticalTransferDestinationKind.Entity:
      if (destinationEntity) {
        return {
          targetId: destinationEntity.id,
          targetDisplayName: destinationEntity.subtype?.displayName || '',
        };
      }
      break;
    case EstateWaterfallHypotheticalTransferDestinationKind.Individual:
      if (destinationIndividual) {
        return {
          targetId: destinationIndividual.id,
          targetDisplayName: destinationIndividual.displayName,
        };
      }
      break;
    case EstateWaterfallHypotheticalTransferDestinationKind.Organization:
      if (destinationOrganization) {
        return {
          targetId: destinationOrganization.id,
          targetDisplayName: destinationOrganization.displayName,
        };
      }
      break;
    case EstateWaterfallHypotheticalTransferDestinationKind.External:
      return {
        targetId: HYPOTHETICAL_TRANSFER_EXTERNAL_SENTINEL,
        targetDisplayName: 'External destination',
      };
    default:
      throw new UnreachableError({
        case: destinationKind,
        message: 'Invalid destination kind specified',
      });
  }

  throw new Error(
    `Could not get target details for transfer ${transferId} with destination kind ${destinationKind}`
  );
}

export function getSaleLoanTargetDetails({
  recipientKind,
  recipientEntity,
  recipientIndividual,
  id: saleLoanId,
}: HypotheticalTransfersSummary_HypotheticalSaleLoanFragment): {
  targetId: string;
  targetDisplayName: string;
} {
  switch (recipientKind) {
    case HypotheticalSaleLoanRecipientKind.Entity:
      if (recipientEntity) {
        return {
          targetId: recipientEntity.id,
          targetDisplayName: recipientEntity.subtype?.displayName || '',
        };
      }
      break;
    case HypotheticalSaleLoanRecipientKind.Individual:
      if (recipientIndividual) {
        return {
          targetId: recipientIndividual.id,
          targetDisplayName: recipientIndividual.displayName,
        };
      }
      break;

    default:
      throw new UnreachableError({
        case: recipientKind,
        message: 'Invalid recipient kind specified for sale-loan',
      });
  }

  throw new Error(
    `Could not get target details for sale-loan ${saleLoanId} with recipient kind ${recipientKind}`
  );
}

function getSourceId({
  sourceKind,
  sourceEntity,
  sourceIndividual,
  id: transferId,
}: HypotheticalTransfersSummary_EstateWaterfallHypotheticalTransferFragment): string {
  // same as getTargetDetails -- two error cases, invalid sourceKind and missing paired data
  switch (sourceKind) {
    case EstateWaterfallHypotheticalTransferSourceKind.Entity:
      if (sourceEntity?.id) {
        return sourceEntity.id;
      }
      break;
    case EstateWaterfallHypotheticalTransferSourceKind.Individual:
      if (sourceIndividual?.id) {
        return sourceIndividual.id;
      }
      break;
    case EstateWaterfallHypotheticalTransferSourceKind.External:
      return HYPOTHETICAL_TRANSFER_EXTERNAL_SENTINEL;
    default:
      throw new UnreachableError({
        case: sourceKind,
        message: 'Invalid source kind specified',
      });
  }

  throw new Error(
    `Could not get source details for transfer ${transferId} source ${sourceKind}`
  );
}

// TODO(T1-2634) dedupe with identical function in hypotheticalTransfers/transferUtils
export function getHypotheticalTransferTaxDisplay({
  transferTaxKind,
}: {
  transferTaxKind: EstateWaterfallHypotheticalTransferTransferTaxKind;
}): string {
  switch (transferTaxKind) {
    case EstateWaterfallHypotheticalTransferTransferTaxKind.AnualExclusionGift:
      return 'Annual Exclusion Gift';
    case EstateWaterfallHypotheticalTransferTransferTaxKind.CharitableGift:
      return 'Charitable Gift';
    case EstateWaterfallHypotheticalTransferTransferTaxKind.GenericNonTaxableGift:
      return 'Non-taxable transfer';
    case EstateWaterfallHypotheticalTransferTransferTaxKind.GrantorTaxableGift:
      return 'Taxable gift';
    case EstateWaterfallHypotheticalTransferTransferTaxKind.NonGiftTransfer:
      return 'Non-gift transfer';
    default:
      throw new UnreachableError({
        case: transferTaxKind,
        message: 'Invalid transfer type specified',
      });
  }
}

function addTransferToList(
  transfer: HypotheticalTransfersSummaryPanelTransfer,
  list: HypotheticalTransfersSummaryPanelTransfer[]
): HypotheticalTransfersSummaryPanelTransfer[] {
  const newTransfers = list.slice();
  newTransfers.push(transfer);
  newTransfers.sort((a, b) => {
    // if the start years are different, sort by earliest first
    if (a.startYear !== b.startYear) {
      return a.startYear - b.startYear;
    }
    // otherwise, sort with the highest transfer value first
    return a.transferValue.comparedTo(b.transferValue) * -1;
  });
  return newTransfers;
}

export function mapDataToIncludedIds(
  data: HypotheticalTransfersSummaryQueryQuery | undefined
): string[] {
  const waterfall = first(getNodes(data?.estateWaterfalls));

  if (!waterfall) {
    return [];
  }
  const vizNodes: HypotheticalTransfersSummaryQuery_EstateWaterfallVizNodeFragment[] =
    waterfall.visualization?.nodes || [];

  return compact(
    vizNodes
      .filter(
        ({ isHidden, afterDeath }) =>
          !isHidden && afterDeath === AfterDeath.None
      )
      .map(({ node }) => node?.id)
  );
}

export function mapDataToEntityTransfersMap(
  data: HypotheticalTransfersSummaryQueryQuery | undefined,
  colors: ReturnType<typeof useEstateWaterfallSummaryBarChartsSectionColors>
): HypotheticalTransfersSummaryMap | null {
  const waterfall = first(getNodes(data?.estateWaterfalls));

  if (!waterfall) {
    return null;
  }

  const hypotheticalTransfers = getNodes(waterfall.hypotheticalTransfers);
  const hypotheticalSaleLoans = getNodes(waterfall.hypotheticalSaleLoans);
  const vizNodes: HypotheticalTransfersSummaryQuery_EstateWaterfallVizNodeFragment[] =
    waterfall.visualization?.nodes || [];

  if (!hypotheticalTransfers.length && !hypotheticalSaleLoans.length) {
    return null;
  }

  const output: HypotheticalTransfersSummaryMap = {};
  let externalTransfers: HypotheticalTransfersSummaryPanelTransfer[] = [];

  hypotheticalSaleLoans.forEach((loan) => {
    // If the source is an Entity, skip if it's neither Active nor Draft
    if (
      loan.sourceKind === HypotheticalSaleLoanSourceKind.Entity &&
      loan.sourceEntity &&
      !includes(
        [EntityStage.Active, EntityStage.Draft],
        loan.sourceEntity.stage
      )
    ) {
      return;
    }

    // If the source is an Individual, ensure sourceIndividual is present
    // Otherwise consider this as external
    let sourceId: string = HYPOTHETICAL_TRANSFER_EXTERNAL_SENTINEL;
    if (
      loan.sourceKind === HypotheticalSaleLoanSourceKind.Entity &&
      loan.sourceEntity
    ) {
      sourceId = loan.sourceEntity.id;
    } else if (
      loan.sourceKind === HypotheticalSaleLoanSourceKind.Individual &&
      loan.sourceIndividual
    ) {
      sourceId = loan.sourceIndividual.id;
    }

    // We find the loan projection and use the first-year value as the transfer value,
    // rather than trying to do all the math to compute the value from the funding details
    const loanProjection = waterfall.visualization.loans.find(
      (l) => l.id === loan.id
    );
    const transferValue = loanProjection
      ? getSaleLoanFundingValueFromEWLoan(loanProjection)
      : new Decimal(0);

    const startYear = loan.startDate.getFullYear();
    const outputTransfer: HypotheticalTransfersSummaryPanelTransfer = {
      id: loan.id,
      name: loan.displayName,
      startYear,
      endYear: startYear + loan.termLengthYears,
      transferTaxKind: getSaleLoanKindDisplay(loan.kind),
      transferValue,
      transferKind: SummaryPanelTransferKind.SaleLoan,
      ...getSaleLoanTargetDetails(loan),
    };

    if (sourceId === HYPOTHETICAL_TRANSFER_EXTERNAL_SENTINEL) {
      externalTransfers = addTransferToList(outputTransfer, externalTransfers);
    } else if (!output[sourceId]) {
      output[sourceId] = {
        id: sourceId,
        displayName: getSaleLoanSourceDisplayName(loan),
        transfers: [outputTransfer],
        color: getSourceColor(loan, vizNodes, colors),
      };
    } else {
      output[sourceId]!.transfers = addTransferToList(
        outputTransfer,
        output[sourceId]!.transfers
      );
    }
  });

  hypotheticalTransfers.forEach((transfer) => {
    if (
      transfer.sourceKind ===
        EstateWaterfallHypotheticalTransferSourceKind.Entity &&
      transfer.sourceEntity
    ) {
      // only display transfers from entities that exist or are drafts
      if (
        !(
          transfer.sourceEntity.stage === EntityStage.Active ||
          transfer.sourceEntity.stage === EntityStage.Draft
        )
      ) {
        return;
      }
    }

    const sourceId = getSourceId(transfer);

    let transferValue: Decimal = new Decimal(0);

    if (transfer.taxableValue?.gt(0)) {
      transferValue = transfer.taxableValue;
    } else if (transfer.transferValue?.gt(0)) {
      transferValue = transfer.transferValue;
    }

    const outputTransfer: HypotheticalTransfersSummaryPanelTransfer = {
      ...getHypotheticalTransferTargetDetails(transfer),
      id: transfer.id,
      name: transfer.name || '',
      endYear: transfer.endYear || 0,
      startYear: transfer.startYear || 0,
      transferTaxKind: getHypotheticalTransferTaxDisplay(transfer),
      transferValue,
      transferKind: SummaryPanelTransferKind.Transfer,
    };

    if (sourceId === HYPOTHETICAL_TRANSFER_EXTERNAL_SENTINEL) {
      externalTransfers = addTransferToList(outputTransfer, externalTransfers);
    } else if (!output[sourceId]) {
      output[sourceId] = {
        id: sourceId,
        displayName: getHypotheticalTransferSourceDisplayName(transfer),
        transfers: [outputTransfer],
        color: getSourceColor(transfer, vizNodes, colors),
      };
    } else {
      output[sourceId]!.transfers = addTransferToList(
        outputTransfer,
        output[sourceId]!.transfers
      );
    }
  });

  // always show transfers from external sources last
  if (externalTransfers.length) {
    output[HYPOTHETICAL_TRANSFER_EXTERNAL_SENTINEL] = {
      id: EXTERNAL_TRANSFER_NODE_IDENTIFIER,
      displayName: 'External sources',
      color: COLORS.GRAY[300],
      transfers: externalTransfers,
    };
  }

  return keys(output).length ? output : null;
}
