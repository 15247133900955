import { first, noop } from 'lodash';
import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useWatch } from 'react-hook-form';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useGuardedContext } from '@/hooks/useGuardedContext';
import { PresentationBundleKind } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import {
  useCurrentBundleIndex,
  useNavigateToSlide,
} from './ClientPresentationDesignerV2.hooks';
import { ClientPresentationV2Shape } from './ClientPresentationDesignerV2.types';
import { isSinglePageBundle } from './components/ClientPresentationDesignerV2TreeView/ClientPresentationDesignerV2TreeView.constants';
import { ConfirmDeleteBundle } from './components/settingsModals/ConfirmDeleteBundle';
import { EntitySummaryBundleSettingsModal } from './components/settingsModals/EntitySummaryBundleSettingsModal';
import { PresentationSettingsModal } from './components/settingsModals/PresentationSettingsModal';
import { WaterfallOverviewBundleSettingsModal } from './components/settingsModals/WaterfallOverviewBundleSettingsModal';
import { ClientPresentationV2Bundle } from './types/ClientPresentationV2.PresentationBundleType';

// these modals don't map to bundles
export enum PresentationConfigKind {
  PresentationSettingsModal = 'PresentationSettingsModal',
  ConfirmDeleteBundle = 'ConfirmDeleteBundle',
}

export type ClientPresentationDesignerV2ConfigModals =
  | PresentationBundleKind
  | PresentationConfigKind;

export interface ClientPresentationV2ModalContextShape {
  createBundle: (bundleType: ClientPresentationDesignerV2ConfigModals) => void;
  modifyBundle: (
    bundleType: ClientPresentationDesignerV2ConfigModals,
    bundleId: string
  ) => void;
  duplicateBundle: (
    bundleType: ClientPresentationDesignerV2ConfigModals,
    bundleId: string
  ) => void;
  deleteBundle: (bundleId: string) => void;
  openPresentationSettingsModal: () => void;
}

const ClientPresentationV2ModalContext =
  createContext<ClientPresentationV2ModalContextShape>({
    createBundle: noop,
    modifyBundle: noop,
    duplicateBundle: noop,
    deleteBundle: noop,
    openPresentationSettingsModal: noop,
  });

export const useClientPresentationV2ModalContext = () => {
  return useGuardedContext<ClientPresentationV2ModalContextShape>(
    ClientPresentationV2ModalContext,
    'ClientPresentationV2ModalContext'
  );
};

export type ClientPresentationV2ModalUpdateType =
  | 'create'
  | 'update'
  | 'duplicate';

export const ClientPresentationV2ModalContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { showFeedback } = useFeedback();
  const { control, setValue } = useFormContext<ClientPresentationV2Shape>();
  const { navigateToSlide } = useNavigateToSlide();
  const [openModalName, setOpenModalName] =
    useState<ClientPresentationDesignerV2ConfigModals | null>(null);

  const [editingBundleId, setEditingBundleId] = useState<string | null>(null);
  const [updateType, setUpdateType] =
    useState<ClientPresentationV2ModalUpdateType | null>(null);

  const [presentationConfiguration, bundles] = useWatch({
    control,
    name: ['presentationConfiguration', 'bundles'],
  });

  const currentBundleIndex = useCurrentBundleIndex();

  const editingBundle = useMemo(() => {
    return bundles.find((b) => b.id === editingBundleId);
  }, [editingBundleId, bundles]);

  const closeModal = useCallback(() => {
    setOpenModalName(null);
    setEditingBundleId(null);
    setUpdateType(null);
  }, []);

  const createBundle = (
    bundleType: ClientPresentationDesignerV2ConfigModals
  ) => {
    setOpenModalName(bundleType);
    setEditingBundleId(null);
    setUpdateType('create');
  };

  const modifyBundle = (
    bundleType: ClientPresentationDesignerV2ConfigModals,
    bundleId: string
  ) => {
    setOpenModalName(bundleType);
    setEditingBundleId(bundleId);
    setUpdateType('update');
  };

  const duplicateBundle = (
    bundleType: ClientPresentationDesignerV2ConfigModals,
    bundleId: string
  ) => {
    setOpenModalName(bundleType);
    setEditingBundleId(bundleId);
    setUpdateType('duplicate');
  };

  const deleteBundle = (bundleId: string) => {
    setOpenModalName(PresentationConfigKind.ConfirmDeleteBundle);
    setEditingBundleId(bundleId);
    setUpdateType(null);
  };

  const openPresentationSettingsModal = () => {
    setOpenModalName(PresentationConfigKind.PresentationSettingsModal);
    setEditingBundleId(null);
    setUpdateType(null);
  };

  const handleUpdateBundleConfiguration = useCallback(
    (newBundle: ClientPresentationV2Bundle, shouldCreateMore: boolean) => {
      if (updateType === 'create' || updateType === 'duplicate') {
        const before = bundles.slice(0, currentBundleIndex + 1);
        const after = bundles.slice(currentBundleIndex + 1);

        const newBundles = [...before, newBundle, ...after];

        setValue('bundles', newBundles);
      } else if (!editingBundle) {
        diagnostics.error(
          `Active bundle not present when updating ${newBundle.id}`
        );
        showFeedback(
          'Could not update the presentation.  Please try again later.'
        );

        // intentionally return early so we don't close the modal
        return;
      } else if (updateType === 'update') {
        const newBundles = bundles.slice();
        const index = newBundles.findIndex((b) => b.id === editingBundle.id);
        newBundles[index] = newBundle;
        setValue('bundles', newBundles);
      }

      if (!shouldCreateMore) {
        closeModal();
        if (isSinglePageBundle(newBundle.type)) {
          navigateToSlide(newBundle.id);
        } else {
          const firstPage = first(newBundle.pages);
          if (firstPage) {
            navigateToSlide(firstPage.id);
          } else {
            navigateToSlide(newBundle.id);
            diagnostics.error(
              `No pages found for ${newBundle.id} when updating`
            );
          }
        }
      }
    },
    [
      updateType,
      editingBundle,
      bundles,
      currentBundleIndex,
      setValue,
      showFeedback,
      closeModal,
      navigateToSlide,
    ]
  );

  const handleDeleteBundle = useCallback(() => {
    if (!editingBundleId) return;
    const newBundles = bundles.slice();
    const index = newBundles.findIndex((b) => b.id === editingBundleId);
    newBundles.splice(index, 1);
    setValue('bundles', newBundles);

    const targetBundle = newBundles[index] ?? first(newBundles);
    if (targetBundle) {
      if (isSinglePageBundle(targetBundle.type)) {
        navigateToSlide(targetBundle.id);
      } else {
        const firstPage = first(targetBundle.pages);
        if (firstPage) {
          navigateToSlide(firstPage.id);
        }
      }
    }
    closeModal();
  }, [bundles, closeModal, editingBundleId, navigateToSlide, setValue]);

  const body = useMemo(() => {
    if (openModalName === PresentationConfigKind.PresentationSettingsModal) {
      return (
        <PresentationSettingsModal
          presentationConfiguration={presentationConfiguration}
          onClose={closeModal}
          onSave={(newPresentationConfiguration) => {
            setValue('presentationConfiguration', newPresentationConfiguration);
          }}
        />
      );
    } else if (
      openModalName === PresentationConfigKind.ConfirmDeleteBundle &&
      editingBundleId
    ) {
      return (
        <ConfirmDeleteBundle
          bundleId={editingBundleId}
          onConfirm={handleDeleteBundle}
          onCancel={closeModal}
        />
      );
    } else if (updateType) {
      switch (openModalName) {
        case PresentationBundleKind.WaterfallOverviewBundle:
          return (
            <WaterfallOverviewBundleSettingsModal
              onClose={closeModal}
              onSave={handleUpdateBundleConfiguration}
              bundle={editingBundle ?? undefined}
              updateType={updateType}
            />
          );
        case PresentationBundleKind.EntitySummaryBundle:
          return (
            <EntitySummaryBundleSettingsModal
              onClose={closeModal}
              onSave={handleUpdateBundleConfiguration}
              bundle={editingBundle ?? undefined}
              updateType={updateType}
            />
          );
      }
    }
    return null;
  }, [
    closeModal,
    editingBundle,
    editingBundleId,
    handleDeleteBundle,
    handleUpdateBundleConfiguration,
    openModalName,
    presentationConfiguration,
    setValue,
    updateType,
  ]);

  return (
    <ClientPresentationV2ModalContext.Provider
      value={{
        modifyBundle,
        createBundle,
        duplicateBundle,
        deleteBundle,
        openPresentationSettingsModal,
      }}
    >
      {body}
      {children}
    </ClientPresentationV2ModalContext.Provider>
  );
};
