import { css } from '@emotion/css';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';

import { FlowChartProvider } from '@/components/diagrams/FlowChart';
import { Divider } from '@/components/Divider';
import { Card } from '@/components/layout/Card/Card';
import { EntityDiagram as EntityDiagramFlowChart } from '@/modules/entityDiagram';
import { EntityDiagramLegend } from '@/modules/entityDiagram/components/EntityDiagramLegend/EntityDiagramLegend';
import { EntityDiagramProvider } from '@/modules/entityDiagram/contexts/EntityDiagram.provider';
import { useQueryEntityDiagram } from '@/modules/entityDiagram/hooks';
import { EntityDiagramVariant } from '@/modules/entityDiagram/types';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { COLORS } from '@/styles/tokens/colors';

import { useUnguardedClientPresentationDesignerV2Context } from '../../clientPresentation/ClientPresentationDesignerV2/ClientPresentationDesignerV2.context';
import { PresentationSlideRegistrationProps } from '../../presentation.types';

const styles = {
  fullHeight: css({ height: 'calc(100% - 40px)' }), // Account for the legend and borders
};

export const FIRST_DECEDENT_ID_QUERY_PARAM = 'firstDecedentId';

interface EntityDiagramProps {
  entityId: string;
  householdId: string;
  registerDiagramReady?: () => void;
  entityDiagramVariant: EntityDiagramVariant;
  registrationProps: PresentationSlideRegistrationProps;
}

export function EntityDiagram({
  entityId,
  householdId,
  registerDiagramReady,
  entityDiagramVariant,
  registrationProps,
}: EntityDiagramProps) {
  const [searchParams] = useSearchParams();
  const firstDecedentId = searchParams.get(FIRST_DECEDENT_ID_QUERY_PARAM);
  const { entity } = useQueryEntityDiagram({
    entityId,
    entityDiagramVariant,
  });

  const { primaryClients, isTwoClientHousehold } = useHouseholdDetailsContext();
  const { entityDetailMap } = useUnguardedClientPresentationDesignerV2Context();

  const [selectedPrimaryClientId, setSelectedPrimaryClientId] = useState('');

  useEffect(() => {
    const deathOrder = entityDetailMap[registrationProps.bundleId]?.deathOrder;

    if (deathOrder?.id) {
      setSelectedPrimaryClientId(deathOrder?.id);
    } else if (firstDecedentId) {
      setSelectedPrimaryClientId(firstDecedentId);
    } else {
      setSelectedPrimaryClientId(primaryClients?.[0]?.id ?? '');
    }
  }, [
    entityDetailMap,
    entityId,
    firstDecedentId,
    primaryClients,
    registrationProps.bundleId,
    searchParams,
  ]);

  const hasData =
    (primaryClients?.length ?? 0) > 0 && !!entity && !!selectedPrimaryClientId;

  return (
    <Card variant="outlined" sx={{ height: '100%' }}>
      <EntityDiagramLegend presentationMode={true} />
      <Divider sx={{ borderColor: COLORS.GRAY[200] }} />
      <FlowChartProvider key={selectedPrimaryClientId}>
        {hasData ? (
          <EntityDiagramProvider
            primaryClients={primaryClients ?? []}
            isTwoClientHousehold={isTwoClientHousehold}
            entity={entity}
            presentationMode={true}
            registerDiagramReady={registerDiagramReady}
            selectedPrimaryClientId={selectedPrimaryClientId}
            entityDiagramVariant={entityDiagramVariant}
          >
            <Stack minHeight="400px" className={styles.fullHeight}>
              <AutoSizer>
                {({ height, width }: { height: number; width: number }) => (
                  <EntityDiagramFlowChart
                    height={height}
                    width={width}
                    householdId={householdId}
                    entityId={entityId}
                    setSelectedPrimaryClientId={setSelectedPrimaryClientId}
                  />
                )}
              </AutoSizer>
            </Stack>
          </EntityDiagramProvider>
        ) : (
          <div data-diagramloading={true.toString()} />
        )}
      </FlowChartProvider>
    </Card>
  );
}
