import { ThemeLogo } from '@mui/material';
import {
  createTheme,
  lighten,
  Theme,
  ThemeOptions,
} from '@mui/material/styles';

import { Branding_BrandedFragment } from '@/modules/admin/branding/graphql/Branding.generated';

import { COLORS as BaseColors } from '../tokens/colors';
import {
  COMMON_THEME_PROPERTIES,
  generateDataVisualizationPaletteFromBaseColor,
  generatePaletteFromBaseColor,
  THEME_SHADOWS,
} from './common';

// https://www.figma.com/file/Jv6op8db2Qe3tIpArzQyr4/Dev-Specs?node-id=4148%3A40193
export enum ColorKey {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  BUTTONS_LINKS = 'BUTTONS_LINKS',
  DATA_VISUALIZATION_PRIMARY = 'DATA_VISUALIZATION_PRIMARY',
  DATA_VISUALIZATION_SECONDARY = 'DATA_VISUALIZATION_SECONDARY',
  DATA_VISUALIZATION_TERTIARY = 'DATA_VISUALIZATION_TERTIARY',
  DATA_VISUALIZATION_NEGATIVE = 'DATA_VISUALIZATION_NEGATIVE',
}

export const BRAND: Record<ColorKey, string> = {
  PRIMARY: '#B91C1C',
  SECONDARY: '#09627E',
  BUTTONS_LINKS: '#374151',
  DATA_VISUALIZATION_PRIMARY: '#0C2F39',
  DATA_VISUALIZATION_SECONDARY: '#A37316',
  DATA_VISUALIZATION_TERTIARY: '#1A6FC9',
  DATA_VISUALIZATION_NEGATIVE: '#FA9F9B',
};

export function dataToBrand(data: Branding_BrandedFragment): {
  brand: typeof BRAND;
  logos: ThemeLogo;
} {
  return {
    brand: {
      PRIMARY: data.primaryColor,
      SECONDARY: data.secondaryColor,
      BUTTONS_LINKS: data.buttonsLinkColor,
      DATA_VISUALIZATION_PRIMARY: data.dataVisualizationPrimaryColor,
      DATA_VISUALIZATION_SECONDARY: data.dataVisualizationSecondaryColor,
      DATA_VISUALIZATION_TERTIARY:
        data.dataVisualizationTertiaryColor ??
        data.dataVisualizationSecondaryColor,
      DATA_VISUALIZATION_NEGATIVE:
        data.dataVisualizationNegativeColor ??
        BRAND.DATA_VISUALIZATION_NEGATIVE,
    },
    logos: {
      primaryWide: data.lightWideLogoURL,
      secondaryWide: data.darkWideLogoURL,
      secondarySquare: data.darkSquareLogoURL,
    },
  };
}

export function themeToBrand(theme: Theme): typeof BRAND {
  return {
    PRIMARY: theme.palette.primary.main,
    SECONDARY: theme.palette.secondary.main,
    BUTTONS_LINKS: theme.palette.links.main,
    DATA_VISUALIZATION_PRIMARY: theme.palette.dataVisualizationPrimary.main,
    DATA_VISUALIZATION_SECONDARY: theme.palette.dataVisualizationSecondary.main,
    DATA_VISUALIZATION_TERTIARY: theme.palette.dataVisualizationTertiary.main,
    DATA_VISUALIZATION_NEGATIVE: theme.palette.dataVisualizationNegative.main,
  };
}

export function makeThemeFromBrand(
  colors: Record<ColorKey, string>,
  logos: ThemeLogo = {}
): ThemeOptions {
  const {
    PRIMARY,
    SECONDARY,
    BUTTONS_LINKS,
    DATA_VISUALIZATION_PRIMARY,
    DATA_VISUALIZATION_SECONDARY,
    DATA_VISUALIZATION_TERTIARY,
    DATA_VISUALIZATION_NEGATIVE,
  } = colors;

  const primaryPalette = generatePaletteFromBaseColor(PRIMARY);
  const secondaryPalette = generatePaletteFromBaseColor(SECONDARY);
  const linksPalette = generatePaletteFromBaseColor(BUTTONS_LINKS);
  const buttonsPalette = generatePaletteFromBaseColor(BUTTONS_LINKS);
  const dataVisualizationPrimaryPalette =
    generateDataVisualizationPaletteFromBaseColor(DATA_VISUALIZATION_PRIMARY);
  const dataVisualizationSecondaryPalette =
    generateDataVisualizationPaletteFromBaseColor(DATA_VISUALIZATION_SECONDARY);
  const dataVisualizationTertiaryPalette =
    generateDataVisualizationPaletteFromBaseColor(DATA_VISUALIZATION_TERTIARY);
  const dataVisualizationNegativePalette =
    generateDataVisualizationPaletteFromBaseColor(DATA_VISUALIZATION_NEGATIVE);

  const BODY_TEXT_COLOR = BaseColors.PRIMITIVES.BLACK;

  return {
    typography: {
      h1: {
        color: BaseColors.NEUTRAL_GRAY[900],
      },
      h1_display: {
        color: BaseColors.NEUTRAL_GRAY[900],
      },
      h2_display: {
        color: BaseColors.NEUTRAL_GRAY[900],
      },
      h2: {
        color: BaseColors.NEUTRAL_GRAY[900],
      },
      h2light: {
        color: BaseColors.NEUTRAL_GRAY[900],
      },
      h3: {
        color: BaseColors.NEUTRAL_GRAY[900],
      },
      h4: {
        color: BaseColors.NEUTRAL_GRAY[900],
      },
      h5: {
        color: BaseColors.NEUTRAL_GRAY[900],
      },
      h6: {
        color: BaseColors.NEUTRAL_GRAY[900],
      },
      subtitle1: {
        color: BaseColors.NEUTRAL_GRAY[500],
      },
      subtitle2: {
        color: BaseColors.NEUTRAL_GRAY[500],
      },
      body1: {
        color: BaseColors.NEUTRAL_GRAY[900],
      },
      body2: {
        color: BaseColors.NEUTRAL_GRAY[600],
      },
      button: {
        color: BODY_TEXT_COLOR,
      },
      button2: {
        color: BODY_TEXT_COLOR,
      },
      label: {
        color: BaseColors.NEUTRAL_GRAY[600],
      },
      label2: {
        color: BaseColors.NEUTRAL_GRAY[600],
      },
    },
    palette: {
      primary: primaryPalette,
      secondary: secondaryPalette,
      links: linksPalette,
      buttons: {
        ...buttonsPalette,
        light: lighten(BUTTONS_LINKS, 0.7),
      },
      dataVisualizationPrimary: dataVisualizationPrimaryPalette,
      dataVisualizationSecondary: dataVisualizationSecondaryPalette,
      dataVisualizationTertiary: dataVisualizationTertiaryPalette,
      dataVisualizationNegative: dataVisualizationNegativePalette,
      shadows: COMMON_THEME_PROPERTIES?.palette?.shadows ?? THEME_SHADOWS,
      education: secondaryPalette,
      divider: primaryPalette.light,
    },
    logos,
  };
}

export function getThemeFromTenantBranding(
  branding: Branding_BrandedFragment
): Theme {
  const { brand, logos } = dataToBrand(branding);
  const themeOptions = makeThemeFromBrand(brand, logos);
  return createTheme(COMMON_THEME_PROPERTIES, themeOptions);
}
