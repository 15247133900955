import { GrowthProfileOverrideKind } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import {
  CustomEntityGrowthCustomizationType,
  GrowthProfileModalGrowthOverride,
} from '../GrowthProfileModal/GrowthProfileModal.types';
import { CustomEntityGrowthModalProps } from './CustomEntityGrowthModal';
import {
  CustomEntityGrowthFormShape,
  CustomEntityGrowthModalFormData,
} from './CustomEntityGrowthModal.types';
import { CustomEntityGrowthModal_EntityFragment } from './graphql/CustomEntityGrowthModal.generated';

export function getCustomEntityGrowthModalCustomizationType({
  kind,
  entityGrowthBeforeFutureValuation,
  entityGrowthAfterFutureValuation,
  entityFutureValuationValue,
  entityFutureValuationYear,
  customGrowthRate,
}: Partial<GrowthProfileModalGrowthOverride>): CustomEntityGrowthCustomizationType | null {
  if (kind === GrowthProfileOverrideKind.Asset) {
    return CustomEntityGrowthCustomizationType.None;
  }

  if (entityGrowthBeforeFutureValuation && entityGrowthAfterFutureValuation) {
    return CustomEntityGrowthCustomizationType.GrowthRateAndFutureValuation;
  }

  if (entityFutureValuationValue && entityFutureValuationYear) {
    return CustomEntityGrowthCustomizationType.FutureValuation;
  }

  if (customGrowthRate) {
    return CustomEntityGrowthCustomizationType.GrowthRate;
  }

  return null;
}

export function mapEntityToFormOutput(
  formData: CustomEntityGrowthFormShape,
  entity: CustomEntityGrowthModal_EntityFragment
) {
  const finalFormData: CustomEntityGrowthModalFormData = {
    entityId: formData.entityId,
  };

  switch (formData.customizationType) {
    case CustomEntityGrowthCustomizationType.GrowthRate:
      finalFormData.customGrowthRate = formData.customGrowthRate;
      break;
    case CustomEntityGrowthCustomizationType.FutureValuation:
      finalFormData.entityFutureValuationValue =
        formData.entityFutureValuationValue;
      finalFormData.entityFutureValuationYear =
        formData.entityFutureValuationYear;
      break;
    case CustomEntityGrowthCustomizationType.GrowthRateAndFutureValuation:
      finalFormData.customGrowthRate = formData.customGrowthRate;
      finalFormData.entityFutureValuationValue =
        formData.entityFutureValuationValue;
      finalFormData.entityFutureValuationYear =
        formData.entityFutureValuationYear;
      break;
  }

  return {
    formData: finalFormData,
    entity,
  };
}

export function handleCustomEntityGrowthModalSubmit({
  formData,
  entities,
  onSubmit,
}: {
  formData: GrowthProfileModalGrowthOverride;
  entities: CustomEntityGrowthModal_EntityFragment[];
  onSubmit: CustomEntityGrowthModalProps['onSubmit'];
}): { errorMessage: string } | undefined {
  const relevantEntity = entities.find(
    (entity) => entity.id === formData.entityId
  );

  if (!relevantEntity) {
    throw new Error(`Could not find entity with id ${formData.entityId}`);
  }

  const displayName = relevantEntity.subtype?.displayName || 'Unknown';
  switch (formData.customizationType) {
    case CustomEntityGrowthCustomizationType.GrowthRate:
      onSubmit({
        formData: {
          id: formData.id,
          customizationType: formData.customizationType,
          kind: GrowthProfileOverrideKind.Entity,
          customGrowthRate: formData.customGrowthRate,
          displayName,
        },
        entity: relevantEntity,
      });
      return;
    case CustomEntityGrowthCustomizationType.FutureValuation:
      onSubmit({
        formData: {
          id: formData.id,
          customizationType: formData.customizationType,
          kind: GrowthProfileOverrideKind.Entity,
          entityFutureValuationValue: formData.entityFutureValuationValue,
          entityFutureValuationYear: formData.entityFutureValuationYear,
          displayName,
        },
        entity: relevantEntity,
      });
      return;
    case CustomEntityGrowthCustomizationType.GrowthRateAndFutureValuation:
      onSubmit({
        formData: {
          id: formData.id,
          customizationType: formData.customizationType,
          kind: GrowthProfileOverrideKind.Entity,
          entityFutureValuationValue: formData.entityFutureValuationValue,
          entityFutureValuationYear: formData.entityFutureValuationYear,
          entityGrowthAfterFutureValuation:
            formData.entityGrowthAfterFutureValuation,
          entityGrowthBeforeFutureValuation:
            formData.entityGrowthBeforeFutureValuation,
          displayName,
        },
        entity: relevantEntity,
      });
      return;
    case CustomEntityGrowthCustomizationType.None:
      return {
        errorMessage: 'Please select a customization type',
      };
    default:
      throw new UnreachableError({
        case: formData.customizationType,
        message: 'Invalid customization type',
      });
  }
}
