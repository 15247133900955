import { useApolloClient } from '@apollo/client';
import { Box, Stack } from '@mui/material';
import { DocumentNode } from 'graphql';
import { partition } from 'lodash';
import { useId, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { FormProvider, useWatch } from 'react-hook-form';
import { useLocalStorage } from 'react-use';
import { SetOptional } from 'type-fest';

import { Button } from '@/components/form/baseInputs/Button';
import { SplitButton } from '@/components/form/baseInputs/SplitButton/SplitButton';
import { Switch } from '@/components/form/baseInputs/Switch';
import {
  FormModal,
  FormModalProps,
} from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { Coachmark } from '@/components/poppers/Coachmark/Coachmark';
import { PopperContent } from '@/components/poppers/PopperContent';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';
import {
  invalidateCacheField,
  shouldRefetchQuery,
} from '@/graphql/client.utils';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { useAISuggestionsEnabled } from '@/modules/aiSuggestions/hooks/useAISuggestionsEnabled';
import { useMultiDocumentUploaderContext } from '@/modules/documents/MultiDocumentUploader/context/multiDocumentUploader.context';
import { MultiDocumentUploaderProvider } from '@/modules/documents/MultiDocumentUploader/context/MultiDocumentUploader.provider';
import { getDocumentTypeFromEntityType } from '@/modules/entities/EntitySubforms/utils/shared/common.utils';
import { IMMEDIATE_EDIT_SEARCH_PARAM } from '@/modules/entities/hooks/useImmediateOpenEditModal';
import { GratDesignerStages, ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
// it's okay to import from a page in this scenario because we're navigating specifically
// to that page, and want to change the behavior on that page
// eslint-disable-next-line luminary-rules/no-page-imports
import { DISPLAY_NAME_SEARCH_PARAM_NAME } from '@/pages/designer/GRATDesigner/BasicInformationDesigner/hooks/useBasicInformationDesignerDataFromSearch';
import { diagnostics } from '@/utils/diagnostics';

import { NEW_ENTITY_SENTINEL } from '../entities.constants';
import { EntityType } from '../types/EntityType';
import { CreateEntityShortForm } from './CreateEntityShortForm/CreateEntityShortForm';
import { defaultValues } from './CreateEntityShortForm/CreateEntityShortForm.constants';
import {
  CREATE_ENTITY_SHORT_FORM_NAMESPACE,
  EntityShortFormShape,
} from './CreateEntityShortForm/CreateEntityShortForm.types';
import { mapFormDataToInput } from './CreateEntityShortFormModal.utils';
import { useCreateEntityFromShortFormMutation } from './graphql/CreateEntityShortFormModal.generated';

type FormModalOptionalProps = SetOptional<FormModalProps, 'heading'>;
export interface CreateEntityShortFormModalProps
  extends FormModalOptionalProps {
  householdId: string;
  initialEntityName?: string;
  initialEntityType?: EntityType;
  isOnboardingFlow?: boolean;
  // onAfterCreate, when present, overrides the default redirect behavior on creation of a new entity
  // and allows the caller to define a custom behavior
  onAfterCreate?: (createdEntityId: string) => void;
  /** If true, disable the entity type field, forcing the initialEntityType to be the sumitted type */
  forceEntityType?: boolean;
  // after a mutation, the modal will trigger a refetch of the active queries.
  // pass any queries you want to ignore from the refetch here.
  ignoredQueryDocuments?: DocumentNode[];
}

function CreateEntityShortFormModalInner({
  householdId,
  onClose,
  heading = 'Create a new entity',
  initialEntityType,
  isOnboardingFlow,
  onAfterCreate,
  forceEntityType,
  ignoredQueryDocuments,
  ...formModalProps
}: CreateEntityShortFormModalProps) {
  const trackUserEvent = useTrackUserEvent();
  const buttonId = useId();
  const apolloClient = useApolloClient();
  const { navigate } = useNavigateToRoute();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  // this needs to be a ref because it needs to be set in the SplitButton
  // click handler then immediately read in the onSubmitSuccess handler
  const editEntityImmediately = useRef<boolean>(false);
  const [shouldCreateAdditionalEntities, setShouldCreateAdditionalEntities] =
    useState(isOnboardingFlow ?? false);
  const { selectedExistingDocuments } = useMultiDocumentUploaderContext();
  const {
    handleSubmit,
    reset,
    setError,
    clearErrors,
    setFocus,
    shouldBlockNavigation,
    formState: { isSubmitting },
    setShouldBlockNavigation,
    control,
  } = useFormContext<EntityShortFormShape>();
  const entityType = useWatch({
    name: 'createEntityShortForm.entityType',
    control,
  });
  const suggestionsEnabled = useAISuggestionsEnabled(entityType);

  const { formRef, handleSave } = useFormSaveHandler();
  const [coachmarkSeen, setCoachmarkSeen] = useLocalStorage<boolean>(
    LOCAL_STORAGE_KEYS.COACHMARK_ENTITY_CREATE
  );
  const { uploadDocuments, clearUploadedFiles } =
    useMultiDocumentUploaderContext();

  const [createMutation, { data: createdEntityData }] =
    useCreateEntityFromShortFormMutation({
      refetchQueries: 'active',
      onError: (error) => {
        showFeedback(FeedbackMessages.formSaveError);
        reportError(`could not create entity through short form modal`, error);
      },
      onCompleted: () => {
        return invalidateCacheField('entities', apolloClient);
      },
      onQueryUpdated: (query) => {
        return shouldRefetchQuery(query.queryName, {
          ignoredQueryDocuments,
        });
      },
    });

  const onSubmit = handleSubmit(
    async (formData) => {
      // clear from any previous errors, in case there was a previous error and we're in the
      // repeated creation mode
      clearErrors('createEntityShortForm');
      // this also needs to be explicitly cleared because it's not actually an error set on an input,
      // it's an error set on the card that wraps the principals input
      clearErrors('createEntityShortForm.principals');

      // in a GRAT scenario, we want to not save send the user to the designer because there are a bunch
      // of complexities to supporting creating a GRAT directly from this rather than doing the
      // designer -> implementation -> active path.
      if (formData.createEntityShortForm.entityType === 'grat') {
        const params = {
          householdId,
          entityId: NEW_ENTITY_SENTINEL,
          entityType: 'grat',
          designerStage: GratDesignerStages.BASIC_INFORMATION,
        };

        flushSync(() => {
          setShouldBlockNavigation(false);
        });

        return navigate(ROUTE_KEYS.HOUSEHOLD_ENTITY_DESIGNER, params, {
          [DISPLAY_NAME_SEARCH_PARAM_NAME]: formData.createEntityShortForm.name,
        });
      }

      // Upload the documents and get the IDs, so we can associate them with the
      // newly created entity. We only need to do this for multi-doc uploads,
      // because for single-doc uploads, AIWrappedDocumentUploaderWithList does
      // this automatically on file upload.
      const [additionalDocuments, primaryDocuments] = partition(
        selectedExistingDocuments,
        (doc) => doc.markAsPrimary
      );
      const primaryDocumentIds = primaryDocuments.map((doc) => doc.id);
      const additionalDocumentIds = additionalDocuments.map((doc) => doc.id);

      try {
        const uploadedDocumentIds = await uploadDocuments({
          householdId,
          suggestionsEnabled,
          defaultDocumentType: getDocumentTypeFromEntityType(entityType),
        });
        primaryDocumentIds.push(...uploadedDocumentIds);
      } catch (err) {
        reportError(
          'failed to bulk create documents from uploaded files',
          err as Error
        );
        showFeedback('Could not bulk create documents');

        // you need to set an error here to stop the submit success handler from running, even though we don't
        // expose this error anywhere on the form
        // https://github.com/react-hook-form/react-hook-form/issues/2859
        setError('createEntityShortForm', {
          type: 'manual',
          message: 'Could not bulk create documents',
        });
        return;
      }

      try {
        const input = mapFormDataToInput(formData, householdId, {
          primaryDocumentIds,
          additionalDocumentIds,
        });
        const output = await createMutation({
          variables: { input },
          onCompleted() {
            trackUserEvent('entity created', {
              entityType: formData.createEntityShortForm.entityType,
            });
          },
        });

        if (!output.data?.createEntity) {
          diagnostics.error(
            'Invalid state: output.data.createEntity is missing'
          );
          showFeedback(FeedbackMessages.formSaveError);
          // you need to set an error here to stop the submit success handler from running, even though we don't
          // expose this error anywhere on the form
          // https://github.com/react-hook-form/react-hook-form/issues/2859
          setError('createEntityShortForm', {
            type: 'manual',
            message: FeedbackMessages.formSaveError,
          });
        }

        return output;
      } catch (err) {
        reportError('Could not create entity', err as Error);
        showFeedback(FeedbackMessages.formSaveError);

        // you need to set an error here to stop the submit success handler from running, even though we don't
        // expose this error anywhere on the form
        // https://github.com/react-hook-form/react-hook-form/issues/2859
        setError('createEntityShortForm', {
          type: 'manual',
          message: FeedbackMessages.formSaveError,
        });
      }
    },
    (errors) => {
      if (errors.createEntityShortForm?.type === 'manual') {
        clearErrors('createEntityShortForm');
        void onSubmit();
      }
    }
  );

  const closeModal = () => {
    reset();
    onClose();
  };

  function showSuccessFeedback() {
    // just for ts
    if (!createdEntityData) {
      return;
    }

    const entityName =
      createdEntityData.createEntity?.subtype?.displayName ?? 'Entity';
    showFeedback(`${entityName} was created successfully`, {
      variant: 'success',
      alertTargetUrl: getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS,
        {
          householdId,
          entityId: createdEntityData.createEntity.id,
        }
      ),
    });
  }

  useSubmitSuccessHandler(() => {
    if (!createdEntityData) {
      diagnostics.error('Invalid state: createdEntityData is missing');
      showFeedback(FeedbackMessages.formSaveError);
      return;
    }

    if (shouldCreateAdditionalEntities && !editEntityImmediately.current) {
      showSuccessFeedback();
      setFocus('createEntityShortForm.name');
      reset(defaultValues);
      clearUploadedFiles();
      return;
    }

    closeModal();

    if (onAfterCreate) {
      showSuccessFeedback();
      return onAfterCreate(createdEntityData.createEntity.id);
    }

    return navigate(
      ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS,
      {
        householdId,
        entityId: createdEntityData.createEntity.id,
      },
      {
        [IMMEDIATE_EDIT_SEARCH_PARAM]: editEntityImmediately.current,
      }
    );
  });

  // because we're going to send GRATs straight into the designer form,
  // we don't want to show all the extra options for saving, just a simplified
  // "continue" button
  const isContinueOnlyAction = entityType === 'grat';
  return (
    <FormModal
      {...formModalProps}
      onClose={onClose}
      heading={heading}
      shouldBlockClose={shouldBlockNavigation}
      data-testid="CreateEntityShortFormModal"
      actions={
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            {!isContinueOnlyAction && (
              <Coachmark
                id={LOCAL_STORAGE_KEYS.COACHMARK_ENTITY_CREATE}
                initialOpen={isOnboardingFlow && !coachmarkSeen}
                onClose={() => setCoachmarkSeen(true)}
                title="Disable toggle before creating your last entity"
                body="This toggle enables the creation of multiple entities in sequence. Toggling it off will exit you out of the creation workflow."
                buttonLabel="Got it"
                placement="top-start"
              >
                <Switch
                  labelPosition="right"
                  label="Create additional entities"
                  contextualHelp={
                    <PopperContent
                      body={`Enable this toggle if you'd like to input basic details for your client's entities in quick succession`}
                    />
                  }
                  value={shouldCreateAdditionalEntities}
                  onChange={(event) =>
                    setShouldCreateAdditionalEntities(event.target.checked)
                  }
                />
              </Coachmark>
            )}
          </Box>
          <Stack direction="row" spacing={1}>
            <FormModalActions.CancelButton onClick={closeModal} />
            {isContinueOnlyAction ? (
              <Button
                data-testid="CreateEntityShortFormModal-createEntity"
                variant="primary"
                size="sm"
                onClick={handleSave}
                loading={isSubmitting}
              >
                Continue to next step
              </Button>
            ) : (
              <SplitButton
                htmlId={buttonId}
                onClick={handleSave}
                loading={isSubmitting}
                buttonContent="Create entity"
                mainButtonTestId="CreateEntityShortFormModal-createEntity"
                caretButtonTestId="CreateEntityShortFormModal-createEntityOptions"
                size="sm"
                variant="primary"
                items={[
                  {
                    name: 'Input additional details for this entity',
                    clickHandler: () => {
                      editEntityImmediately.current = true;
                      handleSave();
                    },
                  },
                ]}
              />
            )}
          </Stack>
        </Stack>
      }
    >
      <form ref={formRef} onSubmit={onSubmit} noValidate>
        <CreateEntityShortForm
          householdId={householdId}
          forceEntityType={initialEntityType && forceEntityType}
        />
      </form>
    </FormModal>
  );
}

export function CreateEntityShortFormModal(
  props: CreateEntityShortFormModalProps
) {
  const { initialEntityType, initialEntityName } = props;
  const formMethods = useForm<EntityShortFormShape>({
    defaultValues: {
      ...defaultValues,
      createEntityShortForm: {
        ...defaultValues[CREATE_ENTITY_SHORT_FORM_NAMESPACE],
        entityType:
          initialEntityType ||
          defaultValues[CREATE_ENTITY_SHORT_FORM_NAMESPACE].entityType,
        name:
          initialEntityName ||
          defaultValues[CREATE_ENTITY_SHORT_FORM_NAMESPACE].name,
      },
    },
  });
  return (
    <FormProvider {...formMethods}>
      <MultiDocumentUploaderProvider>
        <CreateEntityShortFormModalInner {...props} />
      </MultiDocumentUploaderProvider>
    </FormProvider>
  );
}
