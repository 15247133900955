import { keyBy } from 'lodash';
import { useMemo } from 'react';

import { TypeaheadSelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { getNodes } from '@/utils/graphqlUtils';

import { useHouseholdDocumentsQuery } from '../graphql/MultiDocumentUploader.generated';

export function useExistingHouseholdDocumentOptions({
  skip = false,
}: {
  skip?: boolean;
}) {
  const { householdId } = useHouseholdDetailsContext();

  const queryProps = useHouseholdDocumentsQuery({
    variables: { householdId: householdId! },
    skip: !householdId || skip,
    fetchPolicy: 'cache-and-network',
  });

  const { data: householdDocuments } = queryProps;

  const documents = getNodes(householdDocuments?.documents);

  const options: TypeaheadSelectInputOption<string>[] = documents.map(
    (doc) => ({
      value: doc.id,
      display: doc.name,
    })
  );

  const documentsHavePrimaryEntity = useMemo(() => {
    return keyBy(
      documents.map((doc) => ({
        id: doc.id,
        hasPrimaryEntity: !!doc.entity?.id,
      })),
      'id'
    );
  }, [documents]);

  return {
    options,
    documentsHavePrimaryEntity,
    ...queryProps,
  };
}
