import { Box, BoxProps, Stack, Typography } from '@mui/material';
import { getYear } from 'date-fns';
import { chunk, first, isEmpty } from 'lodash';
import { ComponentType, useMemo } from 'react';

import {
  BeneficiaryReportingContext,
  useBeneficiaryReportingContext,
} from '@/modules/beneficiaryReporting/beneficiaryReporting.context';
import {
  BenefitsOverviewBarCharts,
  MAX_NUMBER_OF_BARS,
} from '@/modules/beneficiaryReporting/benefitsOverview/BenefitsOverviewBarCharts';
import { usePrimaryBeneficiaryBars } from '@/modules/beneficiaryReporting/benefitsOverview/hooks/usePrimaryBeneficiaryBars';
import {
  BeneficiaryReporting_EstateWaterfallFragment,
  BeneficiaryReportingQuery,
} from '@/modules/beneficiaryReporting/graphql/BeneficiaryReporting.generated';
import { getBeneficiariesDataFromWaterfall } from '@/modules/beneficiaryReporting/hooks/useBeneficiariesData';
import { PrimaryClientDropdown_PossibleGrantorFragment } from '@/modules/entities/inputs/PrimaryClientDropdown/graphql/PrimaryClientDropdown.generated';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { PresentationSlideSecondaryHeading } from '@/modules/presentation/components/PresentationSlideSecondaryHeading';
import { PRESENTATION_SPACING_UNITS } from '@/modules/presentation/presentation.constants';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { COLORS } from '@/styles/tokens/colors';
import { PresentationBundleKind } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import {
  ClientPresentationDesignerV2ViewMode,
  useBundleWaterfall,
  useClientPresentationDesignerV2Context,
  useRegisterSlide,
} from '../../../../ClientPresentationDesignerV2.context';
import { useIsDesignMode } from '../../../../ClientPresentationDesignerV2.hooks';
import { ClientPresentationV2Page } from '../../../../types/ClientPresentationV2.PresentationPageType';
import { ClientPresentationV2Footer } from '../../../ClientPresentationV2Footer';
import { BaseBundleSlideProps } from '../../BundleSlide.types';

interface EstateWaterfallBeneficiariesSlideOutputProps
  extends EstateWaterfallBeneficiariesSlideProps {
  waterfall: BeneficiaryReporting_EstateWaterfallFragment;
  SlideWrapper?: ComponentType<BoxProps>;
  idx: number;
}

function EstateWaterfallBeneficiariesSlideOutput({
  bundle,
  page,
  waterfall,
  SlideWrapper = Box,
  idx,
  isVisible,
}: EstateWaterfallBeneficiariesSlideOutputProps) {
  const {
    firstGrantor,
    secondGrantor,
    firstGrantorDeathYear,
    secondGrantorDeathYear,
  } = useBeneficiaryReportingContext();
  const slideId = `estate-waterfall-${bundle.id}-beneficiaries-${page.id}-slide-${idx}`;
  useRegisterSlide({
    slideId,
    title: bundle.displayName,
    includeInToC: page.id === first(bundle.pages)?.id && idx === 0,
    bundleKind: PresentationBundleKind.WaterfallOverviewBundle,
  });
  if (!isVisible) {
    return null;
  }
  return (
    <SlideWrapper key={`${waterfall.id}-beneficiaries-${idx}`}>
      <PresentationSlide.Slide
        id={slideId}
        leftHeaderContent={
          <PresentationSlide.MainHeading
            heading={waterfall.displayName}
            subheading="Beneficiaries"
          />
        }
        rightHeaderContent={
          <PresentationSlideSecondaryHeading
            clientDisplayName={waterfall.household?.displayName ?? null}
          />
        }
        footer={<ClientPresentationV2Footer slideId={slideId} />}
        readyToScale
        data-presentationwaterfallready="true"
      >
        <Stack
          spacing={3}
          justifyContent="space-between"
          p={PRESENTATION_SPACING_UNITS}
          height="100%"
        >
          <BenefitsOverviewBarCharts
            firstGrantor={firstGrantor}
            secondGrantor={secondGrantor}
            firstGrantorDeathYear={firstGrantorDeathYear}
            secondGrantorDeathYear={secondGrantorDeathYear}
            page={idx}
          />
        </Stack>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}

// this needs to be a child of the BeneficiaryReportingContext.Provider in the Inner component
function EstateWaterfallBeneficiariesSlideContent({
  bundle,
  page,
  waterfall,
  SlideWrapper = Box,
  isVisible,
}: EstateWaterfallBeneficiariesSlideInnerProps) {
  const { viewMode } = useClientPresentationDesignerV2Context();
  const bars = usePrimaryBeneficiaryBars();
  const chunks = chunk(bars, MAX_NUMBER_OF_BARS);

  if (isEmpty(chunks)) {
    if (
      viewMode === ClientPresentationDesignerV2ViewMode.Designer &&
      isVisible
    ) {
      return (
        <Typography color={COLORS.FUNCTIONAL.WARNING.DEFAULT}>
          No beneficiaries data
        </Typography>
      );
    }

    return null;
  }

  return (
    <>
      {chunks.map((_chunk, idx) => (
        <EstateWaterfallBeneficiariesSlideOutput
          key={`${waterfall.id}-beneficiaries-${idx}`}
          bundle={bundle}
          page={page}
          waterfall={waterfall}
          SlideWrapper={SlideWrapper}
          idx={idx}
          isVisible={isVisible}
        />
      ))}
    </>
  );
}

interface EstateWaterfallBeneficiariesSlideInnerProps
  extends EstateWaterfallBeneficiariesSlideProps {
  possibleGrantors: PrimaryClientDropdown_PossibleGrantorFragment[];
  waterfall: BeneficiaryReporting_EstateWaterfallFragment;
}

function EstateWaterfallBeneficiariesSlideInner({
  bundle,
  page,
  SlideWrapper,
  waterfall,
  possibleGrantors,
  isVisible,
}: EstateWaterfallBeneficiariesSlideInnerProps) {
  const { primaryClients } = useHouseholdDetailsContext();

  const firstGrantorDeathId = waterfall?.firstGrantorDeath.id;
  const firstGrantorDeathYear =
    waterfall?.firstGrantorDeathYear ?? getYear(new Date());
  const secondGrantorDeathYear =
    waterfall?.secondGrantorDeathYear ?? getYear(new Date());
  const [firstGrantor, secondGrantor] = useMemo(() => {
    const firstGrantor = primaryClients?.find(
      (g) => g.id === firstGrantorDeathId
    );
    const secondGrantor = primaryClients?.find(
      (g) => g.id !== firstGrantorDeathId
    );

    return [firstGrantor, secondGrantor];
  }, [firstGrantorDeathId, primaryClients]);

  const { loggedTransfers } = useClientPresentationDesignerV2Context();

  const beneficiariesData = getBeneficiariesDataFromWaterfall({
    waterfall,
    loggedTransfers,
    possibleGrantors,
  });

  // rebuild beneficiary reporting query data so we don't need to make a network request
  const queryData: BeneficiaryReportingQuery = {
    estateWaterfalls: {
      edges: [
        {
          node: waterfall,
        },
      ],
    },
    loggedTransfers: {
      edges: loggedTransfers.map((node) => ({
        node,
      })),
    },
  };

  return (
    <BeneficiaryReportingContext.Provider
      value={{
        clientOrOrgId: null,
        beneficiariesData,
        firstGrantor,
        secondGrantor,
        firstGrantorDeathYear,
        secondGrantorDeathYear,
        queryData,
      }}
    >
      <EstateWaterfallBeneficiariesSlideContent
        waterfall={waterfall}
        bundle={bundle}
        page={page}
        SlideWrapper={SlideWrapper}
        possibleGrantors={possibleGrantors}
        isVisible={isVisible}
      />
    </BeneficiaryReportingContext.Provider>
  );
}

export interface EstateWaterfallBeneficiariesSlideProps
  extends BaseBundleSlideProps {
  page: ClientPresentationV2Page;
}

export function EstateWaterfallBeneficiarySlide({
  bundle,
  page,
  SlideWrapper,
  isVisible,
}: EstateWaterfallBeneficiariesSlideProps) {
  const waterfall = useBundleWaterfall(bundle.id);
  const { beneficiaryWaterfallVizMap, isPersistRunning } =
    useClientPresentationDesignerV2Context();
  const { possibleGrantors } = useHouseholdDetailsContext();
  const isDesignMode = useIsDesignMode();

  const beneficiaryWaterfallViz = beneficiaryWaterfallVizMap[bundle.id];
  const showError = !isPersistRunning && isVisible;

  if (!waterfall) {
    if (isDesignMode) {
      return showError ? (
        <Typography color={COLORS.FUNCTIONAL.ERROR.DEFAULT}>
          Waterfall not found
        </Typography>
      ) : null;
    }
    diagnostics.error(`Could not find waterfall for bundle ${bundle.id}`);
    return null;
  }

  if (!possibleGrantors) {
    if (isDesignMode) {
      return showError ? (
        <Typography color={COLORS.FUNCTIONAL.ERROR.DEFAULT}>
          Possible grantors not found
        </Typography>
      ) : null;
    }
    diagnostics.error(`Could not find possible grantors`);
    return null;
  }

  if (!beneficiaryWaterfallViz) {
    if (isDesignMode) {
      return showError ? (
        <Typography color={COLORS.FUNCTIONAL.ERROR.DEFAULT}>
          Beneficiary waterfall viz not found
        </Typography>
      ) : null;
    }
    diagnostics.error(
      `Could not find beneficiary waterfall viz for bundle ${bundle.id}`
    );
    return null;
  }

  const modifiedWaterfall = {
    ...waterfall,
    visualizationWithProjections: beneficiaryWaterfallViz,
  } as BeneficiaryReporting_EstateWaterfallFragment;

  return (
    <EstateWaterfallBeneficiariesSlideInner
      bundle={bundle}
      page={page}
      SlideWrapper={SlideWrapper}
      waterfall={modifiedWaterfall}
      possibleGrantors={possibleGrantors}
      isVisible={isVisible}
    />
  );
}
