import { Box, Stack, Typography } from '@mui/material';
import { first, noop } from 'lodash';

import { Legend } from '@/components/charts/Legend/Legend';
import { EstateWaterfallSummaryBarCharts } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/EstateWaterfallSummaryBarCharts';
import { useEstateWaterfallSummaryLegendSections } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/hooks/useEstateWaterfallSummaryLegend.hook';
import { useWaterfallSummaryBarChartSections } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/hooks/useWaterfallSummaryBarChartSections';
import { EstateWaterfallSummaryTiles } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryTiles/EstateWaterfallSummaryTiles';
import { ClientPresentationDesigner_EstateWaterfallFragment } from '@/modules/presentation/clientPresentation/ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';
import { PresentationSlideSecondaryHeading } from '@/modules/presentation/components/PresentationSlideSecondaryHeading';
import { DisclaimerCallout } from '@/modules/presentation/estateWaterfall/DisclaimerCallout';
import { PRESENTATION_SPACING_UNITS } from '@/modules/presentation/presentation.constants';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { COLORS } from '@/styles/tokens/colors';
import { PresentationBundleKind } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import {
  useBundleWaterfall,
  useClientPresentationDesignerV2Context,
  useRegisterSlide,
} from '../../../../ClientPresentationDesignerV2.context';
import { useIsDesignMode } from '../../../../ClientPresentationDesignerV2.hooks';
import { ClientPresentationV2Page } from '../../../../types/ClientPresentationV2.PresentationPageType';
import { ClientPresentationV2Footer } from '../../../ClientPresentationV2Footer';
import { BaseBundleSlideProps } from '../../BundleSlide.types';

interface EstateWaterfallSummarySlideInnerProps
  extends EstateWaterfallSummarySlideProps {
  waterfall: ClientPresentationDesigner_EstateWaterfallFragment;
}

function EstateWaterfallSummarySlideInner({
  bundle,
  page,
  waterfall,
  SlideWrapper = Box,
  isVisible,
}: EstateWaterfallSummarySlideInnerProps) {
  const waterfallSections = useWaterfallSummaryBarChartSections({
    waterfall,
    onOpenSectionSummaryPanel: noop,
  });

  const legendSections =
    useEstateWaterfallSummaryLegendSections(waterfallSections);
  const slideId = `estate-waterfall-${bundle.id}-summary-${page.id}`;

  useRegisterSlide({
    slideId,
    title: bundle.displayName,
    includeInToC: page.id === first(bundle.pages)?.id,
    bundleKind: PresentationBundleKind.WaterfallOverviewBundle,
  });

  if (!isVisible) {
    return null;
  }

  return (
    <SlideWrapper>
      <PresentationSlide.Slide
        id={slideId}
        leftHeaderContent={
          <PresentationSlide.MainHeading heading={bundle.displayName} />
        }
        rightHeaderContent={
          <PresentationSlideSecondaryHeading
            clientDisplayName={waterfall.household?.displayName ?? null}
          />
        }
        footer={<ClientPresentationV2Footer slideId={slideId} />}
      >
        <Stack
          spacing={3}
          justifyContent="space-between"
          p={PRESENTATION_SPACING_UNITS}
          height="100%"
        >
          <Stack spacing={3} height="100%">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Typography variant="h2">Waterfall summary</Typography>
            </Stack>
            <EstateWaterfallSummaryBarCharts sections={waterfallSections} />
            <Box sx={{ maxHeight: '478px' }}>
              <Box sx={{ transform: 'scale(0.8)', transformOrigin: '50% 0' }}>
                <EstateWaterfallSummaryTiles waterfall={waterfall} />
              </Box>
            </Box>
            <div style={{ marginTop: 'auto' }}>
              <Legend sections={legendSections} />
            </div>
          </Stack>
          <DisclaimerCallout waterfall={waterfall} />
        </Stack>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}

export interface EstateWaterfallSummarySlideProps extends BaseBundleSlideProps {
  page: ClientPresentationV2Page;
}

export function EstateWaterfallSummarySlide({
  bundle,
  SlideWrapper,
  page,
  isVisible,
}: EstateWaterfallSummarySlideProps) {
  const waterfall = useBundleWaterfall(bundle.id);
  const { isPersistRunning } = useClientPresentationDesignerV2Context();
  const isDesignMode = useIsDesignMode();

  const showError = !isPersistRunning && isVisible;

  if (!waterfall) {
    if (isDesignMode) {
      return showError ? (
        <Typography color={COLORS.FUNCTIONAL.ERROR.DEFAULT}>
          Waterfall not found
        </Typography>
      ) : null;
    }
    diagnostics.error(`No waterfall found for bundle ${bundle.id}`);
    return null;
  }

  return (
    <EstateWaterfallSummarySlideInner
      bundle={bundle}
      page={page}
      waterfall={waterfall}
      SlideWrapper={SlideWrapper}
      isVisible={isVisible}
    />
  );
}
