import { Box, Stack } from '@mui/material';
import { ReactNode } from 'react';

import { useRegisterSlide } from '@/modules/presentation/clientPresentation/hooks/useRegisterSlide';
import { PRESENTATION_SPACING_UNITS } from '@/modules/presentation/presentation.constants';
import { getNumberedSlideTitle } from '@/modules/presentation/presentation.utils';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { TrustSummaryData } from '@/modules/trusts/TrustDetails/TrustDetails.types';
import { TrustPoliciesTabPrint } from '@/modules/trusts/TrustPoliciesTab/TrustPoliciesTab';

import { PresentationSlideSecondaryHeading } from '../../components/PresentationSlideSecondaryHeading';
import { PresentationSlideRegistrationDetails } from '../entityPresentations.types';
import { EntityPresentation_EntityFragment } from '../graphql/EntityPresentation.generated';

const SLIDE_TITLE = 'Insurance policies';

export interface InsurancePoliciesSlideProps
  extends PresentationSlideRegistrationDetails {
  entity: EntityPresentation_EntityFragment;
  pagePolicies: TrustSummaryData['insurancePolicies'];
  slideIndex: number;
  numberOfPolicySlides: number;
  displayName: string;
  Footer?: ReactNode; //ComponentType<ComponentProps<typeof PresentationSlide.Footer>>;
}

export function InsurancePoliciesSlide({
  entity,
  pagePolicies,
  slideIndex,
  numberOfPolicySlides,
  displayName,
  Footer,
  ...registrationProps
}: InsurancePoliciesSlideProps) {
  if (!Footer) {
    Footer = <PresentationSlide.Footer />;
  }
  const slideHeader = getNumberedSlideTitle(
    SLIDE_TITLE,
    slideIndex,
    numberOfPolicySlides
  );

  useRegisterSlide({
    displayName: slideHeader,
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });

  return (
    <PresentationSlide.Slide
      id={registrationProps.slideId}
      leftHeaderContent={
        <PresentationSlide.MainHeading
          heading={slideHeader}
          subheading={displayName}
        />
      }
      rightHeaderContent={
        <PresentationSlideSecondaryHeading
          clientDisplayName={entity.household.displayName}
        />
      }
      footer={Footer}
    >
      <Stack
        p={PRESENTATION_SPACING_UNITS}
        spacing={PRESENTATION_SPACING_UNITS}
        minHeight="90%"
      >
        <Box flexGrow={1}>
          <TrustPoliciesTabPrint insurancePolicies={pagePolicies} />
        </Box>
      </Stack>
    </PresentationSlide.Slide>
  );
}
