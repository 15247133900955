import { FormProvider } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import {
  FormModal,
  FormModalProps,
} from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions/FormModalActions';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useForm, useSubmitSuccessHandler } from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { useReportError } from '@/hooks/useReportError';

import { HypotheticalDraftEntityType } from '../draftEntities.constants';
import { getCreateDraftEntityInput } from '../getCreateDraftEntityInput';
import { useDraftEntityGrantorOptions } from '../hooks/useDraftEntityGrantorOptions';
import { useDraftEntityOptions } from '../hooks/useDraftEntityOptions';
import { useCreateDraftEntityMutation } from './graphql/CreateDraftEntityModal.generated';

type FormModalOptionalProps = SetOptional<FormModalProps, 'heading'>;
export type CreateDraftEntityModalProps = FormModalOptionalProps & {
  householdId: string;
  onAfterCreate?: (newId: string) => void;
};

export interface DraftEntityFormShape {
  draftEntityName: string;
  draftEntityType: HypotheticalDraftEntityType | '';
  draftEntityGrantorId: string;
}

const defaultValues: DraftEntityFormShape = {
  draftEntityName: '',
  draftEntityType: '',
  draftEntityGrantorId: '',
};

function CreateDraftEntity({
  onClose,
  heading = 'Create a draft entity',
  householdId,
  onAfterCreate,
  ...formModalProps
}: CreateDraftEntityModalProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { formRef, handleSave } = useFormSaveHandler();
  const { handleSubmit, reset, shouldBlockNavigation, formState, control } =
    useFormContext<DraftEntityFormShape>();

  const draftEntityOptions = useDraftEntityOptions();
  const draftEntityGrantors = useDraftEntityGrantorOptions();

  const [createMutation] = useCreateDraftEntityMutation({
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      reportError(`Could not create draft entity`, error);
    },
    onCompleted: (data) => {
      onAfterCreate?.(data.createEntity.id);
    },
  });

  const onSubmit = handleSubmit((formData) => {
    // this is a required input, just satisfying ts
    if (!formData.draftEntityType) {
      return;
    }

    return createMutation({
      variables: {
        input: getCreateDraftEntityInput({
          draftEntityType: formData.draftEntityType,
          draftEntityName: formData.draftEntityName,
          draftEntityGrantorId: formData.draftEntityGrantorId,
          householdId,
        }),
      },
    });
  });

  const closeModal = () => {
    reset();
    onClose();
  };

  useSubmitSuccessHandler(() => {
    closeModal();
  });

  return (
    <FormModal
      {...formModalProps}
      onClose={onClose}
      heading={heading}
      isOpen={true}
      shouldBlockClose={shouldBlockNavigation}
      actions={
        <FormModalActions.Provider formState={formState}>
          <FormModalActions.CancelButton onClick={closeModal}>
            Cancel
          </FormModalActions.CancelButton>
          <FormModalActions.SaveButton onClick={handleSave}>
            Create draft entity
          </FormModalActions.SaveButton>
        </FormModalActions.Provider>
      }
    >
      <form ref={formRef} onSubmit={onSubmit} noValidate>
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareSelectInput<DraftEntityFormShape>
              control={control}
              fieldName="draftEntityType"
              required
              label="Entity type"
              options={draftEntityOptions}
            />
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow>
          <FormLayoutItem width={6}>
            <FormAwareTextInput<DraftEntityFormShape>
              control={control}
              fieldName="draftEntityName"
              label="Name of draft entity"
              required
            />
          </FormLayoutItem>
          <FormLayoutItem width={6}>
            <FormAwareSelectInput<DraftEntityFormShape>
              control={control}
              fieldName="draftEntityGrantorId"
              label="Grantor"
              required
              options={draftEntityGrantors}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      </form>
    </FormModal>
  );
}

export const CreateDraftEntityModal = ({
  ...props
}: CreateDraftEntityModalProps) => {
  const formMethods = useForm<DraftEntityFormShape>({ defaultValues });

  return (
    <FormProvider {...formMethods}>
      <CreateDraftEntity {...props} />
    </FormProvider>
  );
};
