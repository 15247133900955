import { compact, first, isEmpty, noop } from 'lodash';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { BalanceSheetTable_BalanceSheetFragment } from '@/modules/balanceSheet/BalanceSheetTable/graphql/BalanceSheetTable.fragments.generated';
import { PresentationBundleKind } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { useGuardedTenantPresentationConfigurationContext } from '../../context/tenantPresentationConfiguration.context';
import {
  mapDataToTable,
  ProfessionalTeamSlideMember,
} from '../../ProfessionalTeamSlide/ProfessionalTeamSlide.utils';
import { ClientPresentationV2Configuration } from './ClientPresentationDesignerV2.types';
import { getEstateWaterfallWithProjections } from './ClientPresentationDesignerV2.utils';
import { EstateWaterfallBeneficiarySlide_EstateWaterfallVizFragment } from './components/slides/estateWaterfall/Beneficiaries/graphql/EstateWaterfallBeneficiarySlide.generated';
import {
  ClientPresentationDesignerV2_EntityFragment,
  ClientPresentationDesignerV2_EntityOptionsFragment,
  ClientPresentationDesignerV2_EstateWaterfallFragment,
  ClientPresentationDesignerV2_EstateWaterfallOptionsFragment,
  ClientPresentationDesignerV2_GrowthProfileFragment,
  ClientPresentationDesignerV2_HouseholdFragment,
  ClientPresentationDesignerV2Query,
  ClientPresentationV2_LoggedTransfersFragment,
} from './graphql/ClientPresentationDesignerV2.generated';

export enum ClientPresentationDesignerV2ViewMode {
  Designer = 'DESIGNER',
  Print = 'PRINT',
}

interface SlideDetails {
  title: string;
  slideId: string;
  includeInToC: boolean;
  bundleKind: PresentationBundleKind;
}

export interface ClientPresentationDesignerV2ContextShape {
  householdId: string;
  presentationId: string;
  viewMode: ClientPresentationDesignerV2ViewMode;
  household: ClientPresentationDesignerV2_HouseholdFragment | null;
  growthProfileMap: Record<
    string,
    ClientPresentationDesignerV2_GrowthProfileFragment
  >;
  loggedTransfers: ClientPresentationV2_LoggedTransfersFragment[];
  entities: ClientPresentationDesignerV2_EntityOptionsFragment[];
  balanceSheet: BalanceSheetTable_BalanceSheetFragment | null;
  estateWaterfallOptionsMap: Record<
    string,
    ClientPresentationDesignerV2_EstateWaterfallOptionsFragment
  >;
  beneficiaryWaterfallVizMap: Record<
    string,
    EstateWaterfallBeneficiarySlide_EstateWaterfallVizFragment
  >;
  estateWaterfallBundleConfigurationMap: Record<
    string,
    ClientPresentationDesignerV2_EstateWaterfallFragment
  >;
  entityDetailMap: Record<
    string,
    ClientPresentationDesignerV2_EntityFragment & {
      deathOrder: {
        id: string;
        displayName: string;
      };
    }
  >;
  professionalTeam: ProfessionalTeamSlideMember[];
  /**
   * An ordered list of registered slides.  This is used to drive the table of contents and page numbers
   */
  slideDetails: SlideDetails[];
  setSlideDetails: (slideDetails: SlideDetails[]) => void;
  setEstateWaterfallBundleConfigurationMap: (
    map: Record<string, ClientPresentationDesignerV2_EstateWaterfallFragment>
  ) => void;
  setBeneficiaryWaterfallVizMap: (
    map: Record<
      string,
      EstateWaterfallBeneficiarySlide_EstateWaterfallVizFragment
    >
  ) => void;
  setEntityDetailMap: (
    map: Record<
      string,
      ClientPresentationDesignerV2_EntityFragment & {
        deathOrder: {
          id: string;
          displayName: string;
        };
      }
    >
  ) => void;
  presentationConfiguration: ClientPresentationV2Configuration | null;
  setPresentationConfiguration: (
    configuration: ClientPresentationV2Configuration
  ) => void;
  registerSlide: (slideDetail: SlideDetails) => void;
  isPersistRunning: boolean;
  setPersistRunning: (isPersistRunning: boolean) => void;
}

export const ClientPresentationDesignerV2Context =
  createContext<ClientPresentationDesignerV2ContextShape>({
    householdId: '',
    presentationId: '',
    viewMode: ClientPresentationDesignerV2ViewMode.Designer,
    slideDetails: [],
    household: null,
    growthProfileMap: {},
    loggedTransfers: [],
    entities: [],
    professionalTeam: [],
    balanceSheet: null,
    estateWaterfallOptionsMap: {},
    beneficiaryWaterfallVizMap: {},
    estateWaterfallBundleConfigurationMap: {},
    entityDetailMap: {},
    setEstateWaterfallBundleConfigurationMap: noop,
    setBeneficiaryWaterfallVizMap: noop,
    setEntityDetailMap: noop,
    setSlideDetails: noop,
    presentationConfiguration: null,
    setPresentationConfiguration: noop,
    registerSlide: noop,
    isPersistRunning: false,
    setPersistRunning: noop,
  });

export function useClientPresentationDesignerV2Context() {
  return useGuardedContext(
    ClientPresentationDesignerV2Context,
    'ClientPresentationDesignerV2Context'
  );
}

export function useUnguardedClientPresentationDesignerV2Context() {
  return useContext(ClientPresentationDesignerV2Context);
}

export function useBundleWaterfall(
  bundleId: string | null | undefined
): ClientPresentationDesignerV2_EstateWaterfallFragment | null {
  const { estateWaterfallBundleConfigurationMap, isPersistRunning } =
    useClientPresentationDesignerV2Context();

  if (!bundleId) return null;

  const waterfall = estateWaterfallBundleConfigurationMap[bundleId];
  if (!waterfall) {
    if (!isPersistRunning) {
      diagnostics.info(
        `No waterfall found for bundle ${bundleId} in estateWaterfallBundleConfigurationMap`,
        { keys: Object.keys(estateWaterfallBundleConfigurationMap) }
      );
    }
    return null;
  }

  return waterfall;
}

export function useRegisterSlide(slideDetail: SlideDetails): void {
  const { registerSlide } = useClientPresentationDesignerV2Context();

  useEffect(() => {
    registerSlide(slideDetail);
  }, [registerSlide, slideDetail]);
}

export function getPageNumber(
  slideId: string,
  slideDetails: SlideDetails[],
  presentationConfiguration: ClientPresentationV2Configuration | null
): string {
  const matchingSlideIndex = slideDetails.findIndex(
    (detail) => detail.slideId === slideId
  );

  if (matchingSlideIndex === -1) {
    // don't clutter up the console if there's no slides yet (initial render)
    if (!isEmpty(slideDetails)) {
      diagnostics.debug(
        `Slide ${slideId} not found in slideDetails, cannot determine page number`
      );
    }
    return 'N';
  }

  const startingPage = presentationConfiguration?.pageNumberToStartFrom ?? 1;
  const pageNumber = (matchingSlideIndex + startingPage).toString();

  return pageNumber;
}

export function usePageNumber(slideId: string): string {
  const { slideDetails, presentationConfiguration } =
    useClientPresentationDesignerV2Context();

  return getPageNumber(slideId, slideDetails, presentationConfiguration);
}

export function useResetPagination(): { resetPagination: () => void } {
  const { setSlideDetails } = useClientPresentationDesignerV2Context();

  const resetPagination = useCallback(() => {
    diagnostics.debug('resetting pagination; pages need to re-register');
    setSlideDetails([]);
  }, [setSlideDetails]);

  return { resetPagination };
}

export interface ClientPresentationDesignerV2ProviderProps {
  householdId: string;
  presentationId: string;
  viewMode: ClientPresentationDesignerV2ViewMode;
  queryData: ClientPresentationDesignerV2Query | undefined | null;
}

export function ClientPresentationDesignerV2Provider({
  children,
  householdId,
  presentationId,
  viewMode,
  queryData,
}: PropsWithChildren<ClientPresentationDesignerV2ProviderProps>) {
  const household = first(getNodes(queryData?.households));
  const growthProfiles = getNodes(queryData?.growthProfiles);
  const presentation = first(getNodes(queryData?.clientPresentationV2s));
  const loggedTransfers = getNodes(queryData?.loggedTransfers);
  const estateWaterfalls = getNodes(queryData?.estateWaterfalls);
  const entities = getNodes(queryData?.entities);
  const balanceSheet = queryData?.balanceSheet ?? null;
  const externalProfessionalTeam = getNodes(queryData?.professionalTeam);
  const legalDisclaimers =
    queryData?.tenantInformation?.disclosuresConfiguration ?? null;

  const externalPresentationConfiguration: ClientPresentationV2Configuration = {
    showPageNumbers: presentation?.showPageNumbers ?? false,
    showPageNumbersOnCoverSlide:
      presentation?.showPageNumbersOnCoverSlide ?? false,
    pageNumberToStartFrom: presentation?.pageNumberToStartFrom ?? 1,
    showLegalDisclaimer: presentation?.showLegalDisclaimer ?? false,
  };

  const { setLegalDisclaimers } =
    useGuardedTenantPresentationConfigurationContext();
  useEffect(() => {
    setLegalDisclaimers(legalDisclaimers);
  }, [legalDisclaimers, setLegalDisclaimers]);

  const [isPersistRunning, setPersistRunning] = useState(false);
  const [slideDetails, setSlideDetails] = useState<SlideDetails[]>([]);

  const growthProfileMap = useMemo(
    () =>
      growthProfiles.reduce<
        Record<string, ClientPresentationDesignerV2_GrowthProfileFragment>
      >((acc, growthProfile) => {
        acc[growthProfile.id] = growthProfile;
        return acc;
      }, {}),
    [growthProfiles]
  );

  const estateWaterfallOptionsMap = useMemo(
    () =>
      compact(estateWaterfalls).reduce<
        Record<
          string,
          ClientPresentationDesignerV2_EstateWaterfallOptionsFragment
        >
      >((acc, estateWaterfall) => {
        acc[estateWaterfall.id] = estateWaterfall;
        return acc;
      }, {}),
    [estateWaterfalls]
  );

  const [
    estateWaterfallBundleConfigurationMap,
    setEstateWaterfallBundleConfigurationMap,
  ] = useState<
    Record<string, ClientPresentationDesignerV2_EstateWaterfallFragment>
  >(() => {
    if (!presentation) {
      return {};
    }
    const bundlesWithWaterfalls = getNodes(presentation.bundles).filter(
      (bundle) => !!bundle.waterfallBundleConfiguration?.estateWaterfall
    );

    return bundlesWithWaterfalls.reduce<
      Record<string, ClientPresentationDesignerV2_EstateWaterfallFragment>
    >((acc, bundle) => {
      const estateWaterfall = getEstateWaterfallWithProjections(bundle);

      if (estateWaterfall) {
        acc[bundle.id] = estateWaterfall;
      }
      return acc;
    }, {});
  });

  const [beneficiaryWaterfallVizMap, setBeneficiaryWaterfallVizMap] = useState<
    Record<string, EstateWaterfallBeneficiarySlide_EstateWaterfallVizFragment>
  >(() => {
    if (!presentation) {
      return {};
    }
    const bundlesWithBeneficiaryWaterfallViz = getNodes(
      presentation.bundles
    ).filter(
      (bundle) =>
        !!bundle.waterfallBundleConfiguration?.beneficiaryEstateWaterfallViz
    );

    return bundlesWithBeneficiaryWaterfallViz.reduce<
      Record<string, EstateWaterfallBeneficiarySlide_EstateWaterfallVizFragment>
    >((acc, bundle) => {
      if (bundle.waterfallBundleConfiguration?.beneficiaryEstateWaterfallViz) {
        acc[bundle.id] =
          bundle.waterfallBundleConfiguration?.beneficiaryEstateWaterfallViz;
      }

      return acc;
    }, {});
  });

  const [entityDetailMap, setEntityDetailMap] = useState<
    ClientPresentationDesignerV2ContextShape['entityDetailMap']
  >(() => {
    if (!presentation) {
      return {};
    }

    return getNodes(presentation.bundles).reduce<
      ClientPresentationDesignerV2ContextShape['entityDetailMap']
    >((acc, bundle) => {
      if (bundle.entitySummaryBundleConfiguration?.entity) {
        const deathOrder = bundle.entitySummaryBundleConfiguration?.deathOrder;
        acc[bundle.id] = {
          ...bundle.entitySummaryBundleConfiguration?.entity,
          deathOrder,
        };
      }
      return acc;
    }, {});
  });

  const professionalTeam = useMemo(
    () =>
      externalProfessionalTeam ? mapDataToTable(externalProfessionalTeam) : [],
    [externalProfessionalTeam]
  );

  const [presentationConfiguration, setPresentationConfiguration] =
    useState<ClientPresentationV2Configuration | null>(
      externalPresentationConfiguration
    );

  const registerSlide = useCallback(
    (slide: SlideDetails) => {
      setSlideDetails((prevSlideDetails) => {
        const matchingSlideIndex = prevSlideDetails.findIndex(
          (detail) => detail.slideId === slide.slideId
        );

        if (matchingSlideIndex >= 0) {
          return prevSlideDetails;
        }

        return [...prevSlideDetails, slide];
      });
    },
    [setSlideDetails]
  );

  return (
    <ClientPresentationDesignerV2Context.Provider
      value={{
        householdId,
        presentationId,
        viewMode,
        household: household ?? null,
        growthProfileMap,
        loggedTransfers,
        entities,
        estateWaterfallOptionsMap,
        estateWaterfallBundleConfigurationMap,
        setEstateWaterfallBundleConfigurationMap,
        beneficiaryWaterfallVizMap,
        setBeneficiaryWaterfallVizMap,
        entityDetailMap,
        setEntityDetailMap,
        balanceSheet,
        professionalTeam,
        slideDetails,
        setSlideDetails,
        registerSlide,
        presentationConfiguration,
        setPresentationConfiguration,
        isPersistRunning,
        setPersistRunning,
      }}
    >
      {children}
    </ClientPresentationDesignerV2Context.Provider>
  );
}
