import { Box } from '@mui/material';

import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { CardWithHeader } from '@/components/layout/CardWithHeader';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';

import {
  HypotheticalSaleLoanFormPaths,
  HypotheticalSaleLoanFormShape,
} from '../EstateWaterfallHypotheticalSaleLoanModal.types';

export function SaleLoanPaymentForm() {
  const { control } = useFormContext<HypotheticalSaleLoanFormShape>();

  return (
    <Box>
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          <CardWithHeader title="Model payments as">
            <FormAwareRadioGroup<HypotheticalSaleLoanFormShape>
              fieldName={
                'paymentModel' as const satisfies HypotheticalSaleLoanFormPaths
              }
              label="Payment model"
              row={false}
              hideLabel
              required
              sx={{ mb: 1 }}
              control={control}
              options={[
                {
                  label: 'Interest only with balloon',
                  value: 'interestOnlyWithBalloon',
                },
                {
                  label: 'Amortizing',
                  value: 'amortizing',
                },
                {
                  label: 'Single lump sum payment at term end',
                  value: 'lumpSum',
                  helpText: 'Includes principal + compounded interest',
                },
              ]}
            />
          </CardWithHeader>
        </FormLayoutItem>
      </FormLayoutRow>
    </Box>
  );
}
