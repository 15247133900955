import { Box } from '@mui/material';

import { PresentationSlide } from '@/modules/presentation/PresentationSlide';

import { useRegisterSlide } from '../../../ClientPresentationDesignerV2.context';
import { ClientPresentationV2Bundle } from '../../../types/ClientPresentationV2.PresentationBundleType';
import { ClientPresentationV2Page } from '../../../types/ClientPresentationV2.PresentationPageType';
import { BaseBundleSlideProps } from '../BundleSlide.types';

export interface CustomSlideBundleProps extends BaseBundleSlideProps {
  bundle: ClientPresentationV2Bundle;
}

export function CustomSlideBundle({
  bundle,
  SlideWrapper = Box,
  isVisible,
}: CustomSlideBundleProps) {
  return (
    <>
      {bundle.pages.map((page) => (
        <CustomSlide
          key={page.id}
          page={page}
          bundle={bundle}
          SlideWrapper={SlideWrapper}
          isVisible={isVisible}
        />
      ))}
    </>
  );
}

export interface CustomSlideProps extends BaseBundleSlideProps {
  page: ClientPresentationV2Page;
}

export function CustomSlide({
  bundle,
  page,
  SlideWrapper = Box,
  isVisible,
}: CustomSlideProps) {
  useRegisterSlide({
    slideId: page.id,
    title: page.customPageConfiguration?.pageName ?? 'Custom slide',
    includeInToC: true,
    bundleKind: bundle.type,
  });

  if (!isVisible) {
    return null;
  }

  return (
    <SlideWrapper>
      <PresentationSlide.Slide id="slide-display">
        <Box>
          <pre>{JSON.stringify(page, null, 2)}</pre>
        </Box>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}
