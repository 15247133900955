import { Stack } from '@mui/material';
import { first } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';

import { Divider } from '@/components/Divider';
import { Button } from '@/components/form/baseInputs/Button';
import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import {
  FullScreenModal,
  FullScreenModalProps,
} from '@/components/modals/FullScreenModal/FullScreenModal';
import { Footer } from '@/components/navigation/Footer';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { SplitScreenLayoutWithDocuments } from '@/modules/documents/components/SplitScreenLayoutWithDocuments/SplitScreenLayoutWithDocuments';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { COLORS } from '@/styles/tokens/colors';
import { DispositiveProvisionTemplateKind } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { DISPOSITIVE_PROVISIONS_FORM_NAMESPACE } from '../DispositiveProvisionsForm/DispositiveProvisionsForm.constants';
import {
  DispositiveProvisionsModalDetails,
  Recipient,
} from '../DispositiveProvisionsForm/DispositiveProvisionsForm.types';
import { SURVIVING_SPOUSE_SENTINEL } from '../DispositiveProvisionsForm/hooks/useRecipientOptions';
import {
  DispositiveProvisionsTemplateEditorTab,
  DispositiveProvisionsTemplateHeader,
  DispositiveProvisionsTemplateLinkedEntities,
  //   DispositiveProvisionsTemplateRecipients,
} from './DispositiveProvisionsTemplateSplitScreenModal.components';
import { DispositiveProvisionsTemplateSplitScreenModalContextProvider } from './DispositiveProvisionsTemplateSplitScreenModal.context';
import {
  DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE,
  DispositiveProvisionsTemplateShape,
  DispositiveProvisionTemplateDetails,
  NEW_TEMPLATE_SENTINEL,
} from './DispositiveProvisionsTemplateSplitScreenModal.types';
import {
  getDefaultValues,
  mapFormDataToCreatePayload,
  mapFormDataToUpdatePayload,
  mapQueryDataToForm,
} from './DispositiveProvisionsTemplateSplitScreenModal.utils';
import {
  useCreateDispositiveProvisionsTemplateMutation,
  useDeleteDispositiveProvisionsTemplateMutation,
  useUpdateDispositiveProvisionTemplateMutation,
} from './graphql/DispositiveProvisionsTemplateMutations.generated';
import { useDispositiveProvisionTemplateDataQuery } from './graphql/DispositiveProvisionTemplateData.generated';

interface DispositiveProvisionsTemplateSplitScreenModalInnerProps
  extends FullScreenModalProps {
  onClose: (
    templateId?: string,
    recipieints?: Recipient[],
    isDeleted?: boolean
  ) => void;
  isFromEditingDispositions?: boolean;
  setDispositiveProvisionsModalDetails?: (
    details: DispositiveProvisionsModalDetails | null
  ) => void;
}

enum Tabs {
  Edit = 'edit',
  Links = 'links',
}

function DispositiveProvisionsTemplateSplitScreenModalInner({
  isFromEditingDispositions = false,
  setDispositiveProvisionsModalDetails,
  ...modalProps
}: DispositiveProvisionsTemplateSplitScreenModalInnerProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { householdId } = useHouseholdDetailsContext();

  const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.Edit);

  const { control, setValue, handleSubmit, formState } =
    useFormContext<DispositiveProvisionsTemplateShape>();

  const { isLoading, isSubmitting } = formState;

  const [createTemplate] = useCreateDispositiveProvisionsTemplateMutation({
    refetchQueries: ['DispositiveProvisionTemplates'],
    onCompleted: () => {
      showFeedback('Dispositive provision template created successfully', {
        variant: 'success',
      });
    },
    onError: (error) => {
      reportError(
        'Caught error while creating new dispositive provision template',
        error
      );
      showFeedback('An error occurred.  Please try again later.');
    },
  });

  const [updateTemplate] = useUpdateDispositiveProvisionTemplateMutation({
    refetchQueries: [
      'DispositiveProvisionTemplates',
      'DispositiveProvisionTemplateData', // need to cache bust the current data, otherwise the next open will load stale data
    ],
    onError: (error) => {
      showFeedback('An error occurred.  Please try again later.');
      reportError(
        'Caught error while updating dispositive provision template',
        error
      );
    },
    onCompleted: () => {
      showFeedback('Dispositive provision template updated', {
        variant: 'success',
      });
    },
  });

  const [deleteTemplate] = useDeleteDispositiveProvisionsTemplateMutation({
    refetchQueries: [
      'DispositiveProvisionTemplates',
      'ManageDispositions', // need to cache bust the currently-applied templates to reflect the deleted template
      'DispositiveProvisionTemplateData', // need to cache bust the current data, otherwise the next open will load stale data
    ],
    onError: (error) => {
      showFeedback('An error occurred.  Please try again later.');
      reportError(
        'Caught error while updating dispositive provision template',
        error
      );
    },
    onCompleted: () => {
      showFeedback('Dispositive provision template deleted', {
        variant: 'success',
      });
    },
  });

  const [documentId, templateId] = useWatch({
    control,
    name: [
      `${DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE}.documentId`,
      `${DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE}.id`,
    ],
  });

  const isEditingExistingTemplate = templateId !== NEW_TEMPLATE_SENTINEL;

  const onDelete = useCallback(async () => {
    await deleteTemplate({
      variables: {
        id: templateId,
      },
    });
    modalProps.onClose?.(undefined, undefined, true);
  }, [deleteTemplate, modalProps, templateId]);

  const onSubmit = handleSubmit(async (data) => {
    if (!householdId) {
      showFeedback('Please try again later');
      reportError(
        'Could not get the household ID to create a new dispositive provision template'
      );
      return;
    }

    if (
      data[DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE].id ===
      NEW_TEMPLATE_SENTINEL
    ) {
      const payload = mapFormDataToCreatePayload(data, householdId);
      const result = await createTemplate({
        variables: {
          input: payload,
        },
      });
      modalProps.onClose?.(
        result.data?.createDispositiveProvisionTemplate.id,
        data[DISPOSITIVE_PROVISIONS_FORM_NAMESPACE].recipients
      );
    } else {
      const payload = mapFormDataToUpdatePayload(data, householdId);
      await updateTemplate({
        variables: {
          input: payload,
        },
      });
      modalProps.onClose?.(
        data[DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE].id,
        data[DISPOSITIVE_PROVISIONS_FORM_NAMESPACE].recipients
      );
    }
  });

  return (
    <FullScreenModal {...modalProps}>
      <SplitScreenLayoutWithDocuments
        showAllHouseholdDocuments
        householdId={householdId}
        rightContent={
          <Stack
            spacing={3}
            sx={{
              p: 3,
              overflowY: 'auto',
              flexGrow: 1,
            }}
          >
            <DispositiveProvisionsTemplateHeader
              onDelete={onDelete}
              isExistingTemplate={isEditingExistingTemplate}
            />
            <Divider sx={{ borderColor: COLORS.ORANGE[500] }} />
            {isEditingExistingTemplate && (
              <ButtonGroup<Tabs>
                label={''}
                hideLabel
                value={currentTab}
                onChange={(_event, value) => setCurrentTab(value)}
                options={[
                  {
                    display: 'Edit template',
                    value: Tabs.Edit,
                  },
                  {
                    display: 'Linked entities',
                    value: Tabs.Links,
                  },
                ]}
              />
            )}
            {currentTab === Tabs.Links && (
              <DispositiveProvisionsTemplateLinkedEntities
                isFromEditingDispositions={isFromEditingDispositions}
                setDispositiveProvisionsModalDetails={
                  setDispositiveProvisionsModalDetails
                }
              />
            )}
            {currentTab === Tabs.Edit && (
              <DispositiveProvisionsTemplateEditorTab />
            )}
          </Stack>
        }
        overrideActiveDocumentId={documentId}
        onUpdateActiveDocumentId={({ documentId }) =>
          setValue(
            `${DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE}.documentId`,
            documentId
          )
        }
        footer={
          <Footer
            rightAction={
              <Stack
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
                direction="row"
              >
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => modalProps.onClose()}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={onSubmit}
                  loading={isLoading || isSubmitting}
                >
                  {isEditingExistingTemplate
                    ? 'Save changes'
                    : 'Create template'}
                </Button>
              </Stack>
            }
          />
        }
      />
    </FullScreenModal>
  );
}

export interface DispositiveProvisionsSplitScreenModalProps
  extends DispositiveProvisionsTemplateSplitScreenModalInnerProps {
  templateDetails: DispositiveProvisionTemplateDetails;
}

export function DispositiveProvisionsTemplateSplitScreenModal({
  templateDetails,
  ...modalProps
}: DispositiveProvisionsSplitScreenModalProps) {
  const { templateId, templateType } = templateDetails;
  const formData = useForm<DispositiveProvisionsTemplateShape>({
    defaultValues: getDefaultValues(templateDetails),
  });

  const { reset, setValue } = formData;

  const { data, loading } = useDispositiveProvisionTemplateDataQuery({
    fetchPolicy: 'network-only', // always fetch the latest data before editing
    variables: {
      templateWhere: {
        id: templateId,
      },
    },
  });

  useEffect(() => {
    if (loading) {
      return;
    }

    const existingTemplate = first(
      getNodes(data?.dispositiveProvisionTemplates)
    );

    if (existingTemplate) {
      reset(mapQueryDataToForm(existingTemplate));
    } else if (
      templateType ===
      DispositiveProvisionTemplateKind.OutrightToSurvivingSpouse
    ) {
      setValue(
        `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients.0.recipientId`,
        SURVIVING_SPOUSE_SENTINEL
      );
    }
  }, [data, loading, reset, setValue, templateType]);

  return (
    <DispositiveProvisionsTemplateSplitScreenModalContextProvider
      isTemplateMode
    >
      <FormProvider {...formData}>
        <DispositiveProvisionsTemplateSplitScreenModalInner {...modalProps} />
      </FormProvider>
    </DispositiveProvisionsTemplateSplitScreenModalContextProvider>
  );
}
