import { useEffect, useRef, useState } from 'react';

interface UseDelayedCloseBehaviorProps {
  closeDelayMs?: number;
  isForceOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
}

interface UseDelayedCloseBehaviorResult {
  isOpen: boolean;
  isMouseInTargetArea: boolean;
  setIsMouseInTargetArea: (isInArea: boolean) => void;
}

/**
 * A hook that provides delayed close behavior for menus, tooltips, and other hover-based UI elements.
 *
 * This hook manages the state of whether a UI element should be open or closed based on mouse interactions,
 * with a configurable delay before closing. This prevents accidental closures when a user briefly moves
 * their mouse away from the target area.
 *
 * @param props.closeDelayMs - The delay in milliseconds before closing when mouse leaves the target area. Defaults to 100ms.
 * @param props.isForceOpen - If true, forces the element to stay open regardless of mouse position.
 * @param props.onOpenChange - Optional callback that fires when the open state changes.
 * @returns An object containing:
 *   - isOpen: Whether the element should currently be displayed
 *   - isMouseInTargetArea: Whether the mouse is currently in the target area
 *   - setIsMouseInTargetArea: Function to update the mouse position state
 */

export function useDelayedCloseBehavior({
  closeDelayMs = 100,
  isForceOpen = false,
  onOpenChange,
}: UseDelayedCloseBehaviorProps = {}): UseDelayedCloseBehaviorResult {
  const [isMouseInTargetArea, setIsMouseInTargetArea] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const timerRef = useRef<number | null>(null);

  // This effect manage the open/close state based on mouse position
  // When the mouse enters the target area:
  // - Immediately opens the element
  // - Calls onOpenChange callback with true
  //
  // When the mouse leaves the target area:
  // - Starts a timer for closeDelayMs milliseconds
  // - If mouse is still outside after delay, closes the element and calls onOpenChange(false)
  // - If mouse re-enters before delay expires, cancels the close timer
  useEffect(() => {
    if (isMouseInTargetArea) {
      setIsOpen(true);
      onOpenChange?.(true);
    } else {
      timerRef.current = window.setTimeout(() => {
        if (isMouseInTargetArea) return;
        setIsOpen(false);
        onOpenChange?.(false);
        timerRef.current = null;
      }, closeDelayMs);
    }

    // cancel any pending close timer when the effect re-runs
    return () => {
      timerRef.current !== null && clearTimeout(timerRef.current);
    };
  }, [isMouseInTargetArea, closeDelayMs, onOpenChange]);

  return {
    isOpen: isOpen || isForceOpen,
    isMouseInTargetArea,
    setIsMouseInTargetArea,
  };
}
