import { Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { Button } from '@/components/form/baseInputs/Button';
import { Download01Icon } from '@/components/icons/Download01Icon';
import { EyeOffIcon } from '@/components/icons/EyeOffIcon';
import { useDocumentDownload } from '@/modules/files/hooks/useFileDownload';
import { DocumentRenderErrorProps } from '@/modules/pdf/PDFViewer/DocumentRenderError';
import { COLORS } from '@/styles/tokens/colors';

import { useHouseholdDocumentsViewerContext } from './context/HouseholdDocumentsViewer.context';
import { DocumentSelectorMenu } from './DocumentSelectorMenu';

export function DocumentRenderError({ documentId }: DocumentRenderErrorProps) {
  const [downloadFile, { loading: downloadingDocument }] =
    useDocumentDownload();
  const { targetDocuments: documents, updateActiveDocumentId } =
    useHouseholdDocumentsViewerContext();

  const hasDocuments = !isEmpty(documents);

  return (
    <Stack
      height="100%"
      direction="column"
      gap={3}
      alignItems="center"
      justifyContent="center"
    >
      <Stack
        direction="column"
        gap={1}
        alignItems="center"
        justifyContent="center"
      >
        <EyeOffIcon size={48} sx={{ color: COLORS.GRAY[200] }} />
        <Typography variant="h1">Unable to display uploaded file</Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        {documentId && (
          <Button
            variant="primary"
            size="sm"
            startIcon={Download01Icon}
            loading={downloadingDocument}
            onClick={() => downloadFile(documentId)}
          >
            Download file
          </Button>
        )}
        {hasDocuments && (
          <DocumentSelectorMenu
            label="Select other file"
            documents={
              documents?.map((doc) => ({
                id: doc.id,
                name: doc.name,
              })) ?? []
            }
            onDocumentSelect={(documentId) =>
              updateActiveDocumentId({
                documentId,
                shouldClearSearchResults: true,
              })
            }
          />
        )}
      </Stack>
    </Stack>
  );
}
