import {
  Edge,
  SectionLabelNode,
  TileNode,
} from '@/components/diagrams/FlowChart';
import { AfterDeath } from '@/types/schema';
import { FlowChartGraph } from '@/utils/graphology/FlowChartGraph';

import { CltTermDiagram_EntityFragment } from '../graphql/CltTermDiagram.generated';
import { CrtTermDiagram_EntityFragment } from '../graphql/CrtTermDiagram.generated';
import { DispositionsDiagram_EntityFragment } from '../graphql/DispositionsDiagram.generated';
import { GratTermDiagram_EntityFragment } from '../graphql/GratTermDiagram.generated';
import { OwnershipDiagram_EntityFragment } from '../graphql/OwnershipDiagram.generated';
import { QprtTermDiagram_EntityFragment } from '../graphql/QprtTermDiagram.generated';
import { EntityDiagram_NodeFragment } from '.';

export type GraphEdgeType = 'default';
export enum GraphNodeCategorizationType {
  Individual = 'INDIVIDUAL',
  PersonalAccount = 'PERSONAL_ACCOUNT',
  InsuranceAccount = 'INSURANCE_ACCOUNT',
  FamilyGiving = 'FAMILY_GIVING',
  CharitableEntity = 'CHARITABLE_ENTITY',
  SectionLabel = 'SECTION_LABEL',
  GroupNode = 'GROUP_NODE',
}

export interface MinimumNodeData {
  id: string;
  afterDeath: AfterDeath;
}

export type GraphNodeData = EntityDiagram_NodeFragment;

export type GraphNode = TileNode | SectionLabelNode;

export interface EntityDiagramGraphNodeAttributes<
  Node = GraphNode,
  Data = GraphNodeData,
> {
  node: Node;
  data: Data;
  categorizationType: GraphNodeCategorizationType;
}

export interface EntityDiagramGraphEdgeAttributes {
  type: GraphEdgeType;
  edge: Edge;
}

export interface EntityDiagramGraphAttributes {
  firstPrimaryClientDeathId: string;
  entity:
    | DispositionsDiagram_EntityFragment
    | OwnershipDiagram_EntityFragment
    | GratTermDiagram_EntityFragment
    | QprtTermDiagram_EntityFragment
    | CrtTermDiagram_EntityFragment
    | CltTermDiagram_EntityFragment;
}

export type EntityDiagramGraph = FlowChartGraph<
  EntityDiagramGraphNodeAttributes,
  EntityDiagramGraphEdgeAttributes,
  EntityDiagramGraphAttributes
>;
