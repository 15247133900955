import { Box, Stack, Typography, useTheme } from '@mui/material';
import { chunk, compact, isEmpty } from 'lodash';
import { useMemo } from 'react';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { COLORS } from '@/styles/tokens/colors';
import { PresentationBundleKind } from '@/types/schema';

import {
  getPageNumber,
  useClientPresentationDesignerV2Context,
  useRegisterSlide,
} from '../../../ClientPresentationDesignerV2.context';
import { ClientPresentationV2Footer } from '../../ClientPresentationV2Footer';
import { BaseBundleSlideProps } from '../BundleSlide.types';

interface TableOfContentsInnerProps extends TableOfContentsProps {
  slideItems: ReturnType<
    typeof useClientPresentationDesignerV2Context
  >['slideDetails'];
  idx: number;
  hasMorePages: boolean;
}

const BUNDLE_KIND_SUBTITLE_MAP: Partial<
  Record<PresentationBundleKind, string>
> = {
  [PresentationBundleKind.EntitySummaryBundle]: 'Entity summary',
  [PresentationBundleKind.WaterfallOverviewBundle]: 'Waterfall overview',
};

const ROW_HEIGHT = 56;

function TableOfContentsInner({
  bundle,
  SlideWrapper = Box,
  isVisible,
  slideItems,
  idx,
  hasMorePages,
}: TableOfContentsInnerProps) {
  const { slideDetails, presentationConfiguration } =
    useClientPresentationDesignerV2Context();
  const isDisplayTypographyEnabled = useFeatureFlag(
    'use_serif_font_in_presentation'
  );
  const slideId = `table-of-contents-${bundle.id}-page-${idx}`;

  useRegisterSlide({
    title: 'Table of Contents',
    slideId,
    includeInToC: idx === 0,
    bundleKind: PresentationBundleKind.TableOfContentsBundle,
  });

  const theme = useTheme();

  if (!isVisible) {
    return null;
  }

  const columns = chunk(slideItems, PAGE_SIZE / 2);

  return (
    <SlideWrapper>
      <PresentationSlide.Slide
        id={bundle.id}
        footer={<ClientPresentationV2Footer slideId={slideId} />}
      >
        <Stack spacing={3} justifyContent="center" p={10.5} height="100%">
          <Stack direction="column" alignItems="flex-start">
            <Typography
              variant={isDisplayTypographyEnabled ? 'h1_display' : 'h1'}
              color={theme.palette.primary.main}
              sx={{
                pb: theme.spacing(4),
              }}
            >
              Table of Contents
            </Typography>
            <Stack
              sx={{ flex: '0 0 410px', pb: 2 }}
              direction="row"
              spacing={theme.spacing(6)}
              width="100%"
            >
              {columns.map((column, columnIdx) => (
                <Stack
                  key={columnIdx}
                  sx={{
                    flex: '0 1 50%',
                  }}
                >
                  {column.map((item, itemIdx) => (
                    <Stack
                      key={`${item?.slideId ?? 'slideId'}-${itemIdx}`}
                      direction="row"
                      justifyContent="space-between"
                      sx={{
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        borderTop:
                          idx === 0
                            ? `1px solid ${theme.palette.divider}`
                            : undefined,
                        height: ROW_HEIGHT,
                      }}
                      alignItems="center"
                    >
                      <Stack
                        justifyContent="flex-start"
                        alignContent="flex-start"
                      >
                        <Typography
                          variant="h6"
                          color={COLORS.GRAY[900]}
                          component="span"
                        >
                          {item?.title ?? EMPTY_CONTENT_HYPHEN}
                        </Typography>
                        {BUNDLE_KIND_SUBTITLE_MAP[item?.bundleKind] && (
                          <Typography variant="subtitle2" component="span">
                            {BUNDLE_KIND_SUBTITLE_MAP[item?.bundleKind]}
                          </Typography>
                        )}
                      </Stack>
                      <Typography variant="subtitle2">
                        {item
                          ? getPageNumber(
                              item.slideId,
                              slideDetails,
                              presentationConfiguration
                            )
                          : EMPTY_CONTENT_HYPHEN}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              ))}
            </Stack>
            {hasMorePages && (
              <Stack direction="row" justifyContent="flex-end" width="100%">
                <Typography variant="subtitle2">
                  Continued on next page
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}

/**
 * Number of entries to display per page
 */
const PAGE_SIZE = 14;

export interface TableOfContentsProps extends BaseBundleSlideProps {
  bundleCount: number;
}

export function TableOfContents(props: TableOfContentsProps) {
  const { slideDetails } = useClientPresentationDesignerV2Context();
  const tableOfContentsItems = useMemo(
    () => slideDetails.filter(({ includeInToC }) => includeInToC),
    [slideDetails]
  );

  // allow the ToC to allocate pages for itself on first render,on the expectation that 1 bundle = 1 toc entry
  let pages = chunk(compact(tableOfContentsItems), PAGE_SIZE);
  if (isEmpty(pages)) {
    pages = chunk(new Array(props.bundleCount), PAGE_SIZE);
  }

  const maxIndex = pages.length - 1;

  return (
    <>
      {pages.map((slides, index) => (
        <TableOfContentsInner
          key={index}
          {...props}
          slideItems={slides}
          idx={index}
          hasMorePages={index < maxIndex}
        />
      ))}
    </>
  );
}
