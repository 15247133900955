import { useCallback, useState } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { useGenerateKnowledgeGraphFromDocumentsMutation } from '@/modules/aiOnboarding/graphql/aiOnboarding.generated';
import { useAsyncJobNotificationsContext } from '@/modules/asyncJobs/AsyncJobNotifications/context/AsyncJobNotifications.context';
import { aiOnboardingNotificationMapper } from '@/modules/asyncJobs/AsyncJobNotifications/mappers/aiOnboarding.mapper';
import { useMultiDocumentUploaderContext } from '@/modules/documents/MultiDocumentUploader/context/multiDocumentUploader.context';
import { DocumentType } from '@/types/schema';

interface UseTriggerAIOnboardingWorkflowReturn {
  loading: boolean;
  triggerWorkflow: (
    householdId: string,
    onSuccess?: () => void
  ) => Promise<void>;
}

/**
 * This hook provides a function to upload documents within the
 * MultiDocumentUploaderContext, and kick off the AI onboarding workflow.
 *
 * This will handle any errors that occur during the process, and add a
 * notification to the AsyncJobNotificationsContext on success.
 */
export function useTriggerAIOnboardingWorkflow(): UseTriggerAIOnboardingWorkflowReturn {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();

  const [loading, setLoading] = useState(false);

  const { uploadDocuments, uploadedFiles, selectedExistingDocuments } =
    useMultiDocumentUploaderContext();

  const { addNotification } = useAsyncJobNotificationsContext();

  const [generateMutation] = useGenerateKnowledgeGraphFromDocumentsMutation();

  const triggerWorkflow = useCallback(
    async (householdId: string, onSuccess?: () => void) => {
      setLoading(true);

      try {
        const allDocumentIds = [
          ...selectedExistingDocuments.map((doc) => doc.id),
        ];
        // First, upload the documents...
        // This will throw on error, which we handle in the catch block.
        if (uploadedFiles.length) {
          const createdDocumentIds = await uploadDocuments({
            householdId,
            suggestionsEnabled: true,
            defaultDocumentType: DocumentType.Other,
          });
          allDocumentIds.push(...createdDocumentIds);
        }

        // Verify at least 1 document was actually successfully uploaded.
        // This is more of a sanity check at this point.
        if (!allDocumentIds.length) {
          setLoading(false);
          reportError(
            'useTriggerAIOnboardingWorkflow: bulk upload documents succeeded, but 0 documents were returned'
          );
          showFeedback('Error uploading documents. Please try again.');
        }

        // Only proceed with the workflow if we have documents to process.
        // This does not throw; we handle it in the onError callback.
        await generateMutation({
          variables: {
            input: {
              householdID: householdId,
              documentIds: allDocumentIds,
            },
          },
          onCompleted: (data) => {
            setLoading(false);
            const newNotification = aiOnboardingNotificationMapper(
              data.generateKnowledgeGraphFromDocuments
            );
            if (!newNotification) {
              // This shouldn't happen, since the mutation should always return an
              // async job if it succeeded. But just in case, we'll log an error.
              reportError(
                `[aiOnboardingNotificationMapper] mapping ${JSON.stringify(
                  data.generateKnowledgeGraphFromDocuments
                )} to a new notification resulted in a null value`
              );
              return;
            }
            addNotification(newNotification);
            onSuccess?.();
          },
          onError: (error) => {
            setLoading(false);
            reportError(
              'useTriggerAIOnboardingWorkflow: error generating knowledge graph from documents',
              error
            );
            showFeedback('Error processing uploaded documents');
          },
        });
      } catch (e) {
        setLoading(false);
        reportError(
          'useTriggerAIOnboardingWorkflow: error bulk uploading documents',
          e as Error
        );
        showFeedback('Error uploading documents. Please try again.');
      }
    },
    [
      addNotification,
      generateMutation,
      reportError,
      selectedExistingDocuments,
      showFeedback,
      uploadDocuments,
      uploadedFiles.length,
    ]
  );

  return {
    loading,
    triggerWorkflow,
  };
}
