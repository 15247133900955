import { UnreachableError } from '@/utils/errors';

import {
  getTermVizFromEntity,
  GetTermVizFromEntityInput,
} from './getTermVizFromEntity';
import { GratTermDiagram_EntityFragment } from './graphql/GratTermDiagram.generated';

export interface GetGratTermVizFromEntityInput {
  entity: GratTermDiagram_EntityFragment;
  firstGrantorDeathId: string;
  survivingSpouse?: {
    id: string;
    displayName: string;
  };
}

export function getGratTermVizFromEntity({
  entity,
  firstGrantorDeathId,
  survivingSpouse,
}: GetGratTermVizFromEntityInput) {
  if (entity.subtype.__typename !== 'GRATTrust') {
    throw new UnreachableError({
      case: entity.subtype.__typename as never,
      message: 'Entity is not a GRATTrust',
    });
  }

  const grantors: GetTermVizFromEntityInput['grantors'] = [];
  const grantor = entity.subtype.grantor;
  if (grantor?.individual) {
    grantors.push({
      id: grantor.id,
      individual: {
        id: grantor.individual.id,
        displayName: grantor.individual.displayName,
      },
    });
  }

  const termBeneficiaries: GetTermVizFromEntityInput['termEndBeneficiaries'] =
    [];
  entity.subtype.beneficiaries?.forEach((b) => {
    termBeneficiaries.push({
      id: b.id,
      individual: b.individual,
      organization: b.organization,
      entity: b.entity,
    });
  });

  return getTermVizFromEntity({
    entity,
    grantors,
    currentTermBeneficiaries: [],
    termEndBeneficiaries: termBeneficiaries,
    firstGrantorDeathId,
    survivingSpouse,
  });
}
