import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { WithClasses } from '@/styles/types';

import { TileThemedTypography } from './TileThemedTypography';
import { TileFontSizeVariant, TileVariant } from './types';

const useStyles = makeStyles()((_theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px 6px 8px',
  },
}));

export interface TileFooterLabelProps {
  children: ReactNode;
  tileVariant: TileVariant;
  fontSizeVariant: TileFontSizeVariant;
  classes?: WithClasses<typeof useStyles>;
}

export const TileFooterLabel = ({
  children,
  tileVariant,
  fontSizeVariant,
  ...props
}: TileFooterLabelProps) => {
  const { classes } = useStyles(undefined, { props });
  return (
    <div className={classes.root}>
      <TileThemedTypography
        variant="subtitle2"
        tileVariant={tileVariant}
        fontSizeVariant={fontSizeVariant}
      >
        {children}
      </TileThemedTypography>
    </div>
  );
};
