import { compact } from 'lodash';
import { useMemo } from 'react';

import { ButtonGroupInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareButtonGroup } from '@/components/form/formAwareInputs/FormAwareButtonGroup';
import { useFormContext } from '@/components/react-hook-form';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { AfterDeath } from '@/types/schema';

import {
  HypotheticalSaleLoanFormPaths,
  HypotheticalSaleLoanFormShape,
} from '../EstateWaterfallHypotheticalSaleLoanModal.types';

export function SaleLoanIllustrationScenarioSelector() {
  const { control } = useFormContext<HypotheticalSaleLoanFormShape>();
  const { isTwoClientHousehold } = useHouseholdDetailsContext();

  const scenarioOptions = useMemo<ButtonGroupInputOption<AfterDeath>[]>(() => {
    return compact([
      {
        value: AfterDeath.None,
        display: `Before 1st death`,
      },
      {
        value: AfterDeath.First,
        display: isTwoClientHousehold ? `Before 2nd death` : `After 1st death`,
      },
      isTwoClientHousehold && {
        value: AfterDeath.Second,
        display: `After 2nd death`,
      },
    ]);
  }, [isTwoClientHousehold]);

  return (
    <FormAwareButtonGroup
      control={control}
      options={scenarioOptions}
      fieldName={
        'illustrationScenario' as const satisfies HypotheticalSaleLoanFormPaths
      }
      label="Year of illustration"
      hideLabel
    />
  );
}
