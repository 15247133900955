import { TILE_DEFAULT_WIDTH } from '@/components/diagrams/components/Tile';
import { Node, TileNode } from '@/components/diagrams/FlowChart';
import {
  getNodeMeasuredWidthHeight,
  isTileGroupNode,
  isTileNode,
} from '@/components/diagrams/FlowChart/utils/nodes';

const NODE_GRAVEYARD_NODE_X_SPACING = 40;
const NODE_GRAVEYARD_Y_SENTINEL = -10000; // Used as a sentinel to adjust the Y offset of the node graveyard to the top of all the nodes
export const NODE_GRAVEYARD_Y_OFFSET = 200; // The actual offset of the node graveyard from the top of the other nodes in the section

// These functions are used in conjunction to place nodes in the graveyard
// - First, the `isNodeEligibleForGraveyard` function is used to determine if a node should be placed in the graveyard
//   while initializing the graph.
// - Then, the `transformNodeForNodeGraveyard` function is used to transform the node into a graveyard node, and positions
//   it on the x-axis relative to the other graveyard nodes.
// - Finally, the `isNodeAwaitingGraveyardLayout` function is used to determine if a node is a graveyard node that needs
//   to be positioned on the y-axis. This needs to be done during the section layout phase, since the y-axis position is
//   dependent on that auto-positioning of the rest of the nodes in the section.

export function isNodeEligibleForGraveyard(node: Node): node is TileNode {
  // Nodes without a config and are a tile (not group, section, etc.)
  if (isTileNode(node) && !isTileGroupNode(node)) {
    return node.data.isNewTile ?? false;
  }

  return false;
}

export function transformNodeForNodeGraveyard(
  node: TileNode,
  nodeGraveyardIdx: number,
  graveyardStartX = 0
): Node {
  const { width } = getNodeMeasuredWidthHeight(node);
  const defaultWidth = width ?? TILE_DEFAULT_WIDTH;
  return {
    ...node,
    position: {
      x:
        graveyardStartX + // Start the graveyard at the specified X position to center the graveyard in the section
        nodeGraveyardIdx * (NODE_GRAVEYARD_NODE_X_SPACING + defaultWidth),
      y: NODE_GRAVEYARD_Y_SENTINEL,
    },
  };
}

export function isNodeAwaitingGraveyardLayout(node: Node) {
  return node.position.y === NODE_GRAVEYARD_Y_SENTINEL;
}
