import { PresentationBundleKind, PresentationPageKind } from '@/types/schema';

import {
  getNewBundleId,
  getNewPageId,
} from '../../ClientPresentationDesignerV2.utils';
import { ClientPresentationV2Bundle } from '../../types/ClientPresentationV2.PresentationBundleType';

export function getEmptyBundleForType(
  type: PresentationBundleKind
): ClientPresentationV2Bundle | null {
  const id = getNewBundleId(type);
  switch (type) {
    case PresentationBundleKind.CustomPageBundle:
      return {
        id,
        displayName: '',
        type: PresentationBundleKind.CustomPageBundle,
        pages: [
          {
            id: getNewPageId(PresentationPageKind.CustomPage),
            type: PresentationPageKind.CustomPage,
          },
        ],
      };
    case PresentationBundleKind.TableOfContentsBundle:
      return {
        id,
        displayName: '',
        type: PresentationBundleKind.TableOfContentsBundle,
        pages: [
          {
            id: getNewPageId(PresentationPageKind.TableOfContentsPage),
            type: PresentationPageKind.TableOfContentsPage,
          },
        ],
      };
    case PresentationBundleKind.BalanceSheetBundle:
      return {
        id,
        displayName: 'Balance sheet',
        type: PresentationBundleKind.BalanceSheetBundle,
        pages: [
          {
            id: getNewPageId(PresentationPageKind.BalanceSheetPage),
            type: PresentationPageKind.BalanceSheetPage,
          },
        ],
      };
    case PresentationBundleKind.EntitiesOverviewBundle:
      return {
        id,
        displayName: '',
        type: PresentationBundleKind.EntitiesOverviewBundle,
        pages: [
          {
            id: getNewPageId(PresentationPageKind.EntitiesSummaryPage),
            type: PresentationPageKind.EntitiesSummaryPage,
          },
        ],
      };

    case PresentationBundleKind.ProfessionalTeamBundle:
      return {
        id,
        displayName: 'Professional team',
        type: PresentationBundleKind.ProfessionalTeamBundle,
        pages: [
          {
            id: getNewPageId(PresentationPageKind.ProfessionalTeamPage),
            type: PresentationPageKind.ProfessionalTeamPage,
          },
        ],
      };

    case PresentationBundleKind.StandaloneDisclaimerBundle:
      return {
        id,
        displayName: 'Disclaimer',
        type: PresentationBundleKind.StandaloneDisclaimerBundle,
        pages: [
          {
            id: getNewPageId(PresentationPageKind.StandaloneDisclaimerPage),
            type: PresentationPageKind.StandaloneDisclaimerPage,
          },
        ],
      };

    default:
      return null;
  }
}
