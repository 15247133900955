import { MouseEventHandler } from 'react';

import { ButtonProps } from '@/components/form/baseInputs/Button';
import { PrintButton } from '@/components/form/baseInputs/Button/PrintButton';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { $downloadFileFromURL } from '@/modules/files/utils/fileUtils';
import { useDownloadClientPresentation } from '@/modules/presentation/clientPresentation/hooks/useDownloadClientPresentation';

export interface DownloadPresentationButtonProps extends ButtonProps {
  householdId: string;
  presentationId: string;
  presentationDisplayName: string;
  isV2Presentation: boolean;
}

export function DownloadPresentationButton({
  householdId,
  presentationId,
  presentationDisplayName,
  isV2Presentation,
  ...buttonProps
}: DownloadPresentationButtonProps) {
  const trackUserEvent = useTrackUserEvent();
  const { showFeedback } = useFeedback();
  const [printDocument, { loading }] = useDownloadClientPresentation(
    householdId,
    presentationId,
    isV2Presentation
  );

  const handlePrintAndDownloadDocument: MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.stopPropagation();

    void printDocument({
      onCompleted: (data) => {
        const downloadUrl = data.print.download.downloadURL;

        try {
          void $downloadFileFromURL(downloadUrl, presentationDisplayName);
          trackUserEvent('presentation exported', {
            presentationId,
            householdId,
            source: 'presentation_table',
          });
          showFeedback('PDF generated successfully', { variant: 'success' });
        } catch (err) {
          showFeedback('Failed to download PDF. Please try again.');
        }
      },
    });
  };

  return (
    <PrintButton
      loading={loading}
      onClick={handlePrintAndDownloadDocument}
      {...buttonProps}
    />
  );
}
