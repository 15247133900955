import { noop } from 'lodash';
import { useMemo } from 'react';

import { FlowChart } from '@/components/diagrams/FlowChart';
import { Background } from '@/components/diagrams/FlowChart/components/Background';
import { ControlButtonGroup } from '@/components/diagrams/FlowChart/components/Controls/ControlButtonGroup';
import { Controls } from '@/components/diagrams/FlowChart/components/Controls/Controls';
import { DownloadButton } from '@/components/diagrams/FlowChart/components/Controls/DownloadButton';
import { FitViewButton } from '@/components/diagrams/FlowChart/components/Controls/FitViewButton';
import { FullscreenButton } from '@/components/diagrams/FlowChart/components/Controls/FullscreenButton';
import { ZoomInButton } from '@/components/diagrams/FlowChart/components/Controls/ZoomInButton';
import { ZoomOutButton } from '@/components/diagrams/FlowChart/components/Controls/ZoomOutButton';
import { HelperLines } from '@/components/diagrams/FlowChart/components/HelperLines';
import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { LoadingOverlay } from '@/components/progress/LoadingOverlay/LoadingOverlay';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { THEME_SHADOWS } from '@/styles/themes/common';

import { ContextPrimaryClient } from '../household/contexts/householdDetails.context';
import { EntityDiagramLegend } from './components/EntityDiagramLegend/EntityDiagramLegend';
import { useEntityDiagramContext } from './contexts/entityDiagram.context';
import { useFlowChartProps } from './hooks/useFlowChartProps';
import { EntityDiagramVariant } from './types';

export interface EntityDiagramFlowChartProps {
  entityId: string;
  householdId: string;
  entity: {
    subtype: {
      displayName: string;
    };
  };
  primaryClients: ContextPrimaryClient[];
  // presentationMode is used to disable interactivity with the graph
  presentationMode?: boolean;
  selectedPrimaryClientId: string;
  setSelectedPrimaryClientId: (id: string) => void;
  entityDiagramVariant: EntityDiagramVariant;
}

export const EntityDiagramFlowChart = ({
  entityId,
  householdId,
  entity,
  primaryClients,
  presentationMode,
  selectedPrimaryClientId,
  setSelectedPrimaryClientId,
  entityDiagramVariant,
}: EntityDiagramFlowChartProps) => {
  const isViewOnly = useViewOnly();
  const trackUserEvent = useTrackUserEvent();
  const { flowChartProps, helperLineHorizontal, helperLineVertical } =
    useFlowChartProps();
  const { dispatch } = useEntityDiagramContext();

  const buttonGroupOptions = useMemo(() => {
    return primaryClients.map((client) => ({
      display: `${client.firstName} dies first`,
      value: client.id,
      buttonProps: {
        sx: {
          paddingX: 2,
          paddingY: '10px',
        },
      },
    }));
  }, [primaryClients]);

  if (presentationMode) {
    // Disable panning and zooming in presentation mode
    flowChartProps.panOnDrag = false;
    flowChartProps.zoomOnScroll = false;
    flowChartProps.zoomOnDoubleClick = false;
    flowChartProps.zoomOnPinch = false;
    flowChartProps.zoomActivationKeyCode = null;
    flowChartProps.onNodeClick = noop;
    flowChartProps.elementsSelectable = false;
  }

  const handleSelectPrimaryClient = (primaryClientId: string) => {
    const validPrimaryClientIds = primaryClients.map((client) => client.id);

    if (!validPrimaryClientIds.includes(primaryClientId)) {
      return;
    }

    if (primaryClientId === selectedPrimaryClientId) {
      return;
    }

    setSelectedPrimaryClientId(primaryClientId);
  };

  return (
    <>
      {flowChartProps.style?.opacity === 0 && !isViewOnly && (
        <LoadingOverlay open={true} updatingText="Loading..." />
      )}
      <FlowChart {...flowChartProps} presentationMode={presentationMode}>
        <Background />
        <HelperLines
          vertical={helperLineVertical}
          horizontal={helperLineHorizontal}
        />
        {!presentationMode && (
          <>
            <Controls position="top-left">
              {entityDiagramVariant === EntityDiagramVariant.Dispositions &&
                buttonGroupOptions.length > 1 && (
                  <ButtonGroup<string>
                    label="Primary Client"
                    hideLabel
                    options={buttonGroupOptions}
                    value={selectedPrimaryClientId}
                    onChange={(_, value) => {
                      handleSelectPrimaryClient(value);
                    }}
                    sx={{
                      whiteSpace: 'nowrap',
                      boxShadow: THEME_SHADOWS.lg,
                    }}
                  />
                )}
            </Controls>
            <Controls>
              <DownloadButton
                viewDisplayName={entity.subtype.displayName}
                householdId={householdId}
                onBeforeDownload={async () => {
                  dispatch({
                    type: 'START_EXPORT',
                  });
                  // Wait for the entity diagram to update before exporting the image
                  await new Promise((res) => setTimeout(res, 100));
                }}
                onAfterDownload={() => {
                  dispatch({ type: 'END_EXPORT' });
                  trackUserEvent('entity diagram exported', {
                    householdId,
                    entityId,
                  });
                }}
                tooltipText="Download entity diagram as PNG"
              />
              <ControlButtonGroup>
                <FullscreenButton />
                <FitViewButton
                  onClick={() => dispatch({ type: 'AUTO_POSITION' })}
                />
                <ZoomOutButton />
                <ZoomInButton />
              </ControlButtonGroup>
            </Controls>
            <Controls position="bottom-left" style={{ margin: 0 }}>
              <EntityDiagramLegend />
            </Controls>
          </>
        )}
      </FlowChart>
    </>
  );
};
