import { Box, Stack, Typography } from '@mui/material';
import { first, isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import AutoSizer from 'react-virtualized-auto-sizer';

import { FlowChartProvider } from '@/components/diagrams/FlowChart';
import { EstateWaterfall } from '@/modules/estateWaterfall';
import { EstateWaterfallProvider } from '@/modules/estateWaterfall/contexts/EstateWaterfall.provider';
import { applyWaterfallFilter } from '@/modules/estateWaterfall/EstateWaterfall.utils';
import { EstateWaterfallFragment } from '@/modules/estateWaterfall/graphql/EstateWaterfall.generated';
import { getNodeId } from '@/modules/estateWaterfall/waterfallGraph/utils';
import {
  ContextPrimaryClient,
  useHouseholdDetailsContext,
} from '@/modules/household/contexts/householdDetails.context';
import { PresentationSlideSecondaryHeading } from '@/modules/presentation/components/PresentationSlideSecondaryHeading';
import { DisclaimerCallout } from '@/modules/presentation/estateWaterfall/DisclaimerCallout';
import { PRESENTATION_SPACING_UNITS } from '@/modules/presentation/presentation.constants';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { COLORS } from '@/styles/tokens/colors';
import { PresentationBundleKind } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import {
  useBundleWaterfall,
  useClientPresentationDesignerV2Context,
  useRegisterSlide,
} from '../../../../ClientPresentationDesignerV2.context';
import { useIsDesignMode } from '../../../../ClientPresentationDesignerV2.hooks';
import { ClientPresentationDesignerV2_EstateWaterfallFragment } from '../../../../graphql/ClientPresentationDesignerV2.generated';
import { ClientPresentationV2Page } from '../../../../types/ClientPresentationV2.PresentationPageType';
import { ClientPresentationV2Footer } from '../../../ClientPresentationV2Footer';
import { BaseBundleSlideProps } from '../../BundleSlide.types';

const MIN_HEIGHT = 500;

export interface EstateWaterfallDiagramSlideProps extends BaseBundleSlideProps {
  page: ClientPresentationV2Page;
}

export function EstateWaterfallDiagramSlide({
  bundle,
  SlideWrapper,
  page,
  isVisible,
}: EstateWaterfallDiagramSlideProps) {
  const { isPersistRunning } = useClientPresentationDesignerV2Context();
  const waterfall = useBundleWaterfall(bundle.id);
  const { primaryClients } = useHouseholdDetailsContext();
  const isDesignMode = useIsDesignMode();
  const showError = !isPersistRunning && isVisible;

  if (!waterfall) {
    if (isDesignMode) {
      return showError ? (
        <Typography color={COLORS.FUNCTIONAL.ERROR.DEFAULT}>
          Waterfall not found
        </Typography>
      ) : null;
    }
    diagnostics.error(`Could not find waterfall for bundle ${bundle.id}`);
    return null;
  }

  if (!primaryClients) {
    if (isDesignMode) {
      return showError ? (
        <Typography color={COLORS.FUNCTIONAL.ERROR.DEFAULT}>
          Primary clients not found
        </Typography>
      ) : null;
    }
    diagnostics.error(`Could not find primary clients for household`);
    return null;
  }

  return (
    <EstateWaterfallDiagramSlideInner
      bundle={bundle}
      page={page}
      waterfall={waterfall}
      primaryClients={primaryClients}
      SlideWrapper={SlideWrapper}
      isVisible={isVisible}
    />
  );
}

interface EstateWaterfallDiagramSlideInnerProps
  extends EstateWaterfallDiagramSlideProps {
  waterfall: ClientPresentationDesignerV2_EstateWaterfallFragment;
  primaryClients: ContextPrimaryClient[];
}
function EstateWaterfallDiagramSlideInner({
  SlideWrapper = Box,
  bundle,
  page,
  waterfall,
  primaryClients,
  isVisible,
}: EstateWaterfallDiagramSlideInnerProps) {
  const householdId = waterfall.household?.id;

  /*
  useRegisterSlide({
    displayName: 'Diagram',
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });
  */

  /*
   * From here to the next multi-line comment is ported over from useQueryWaterfall,
   * since we don't want to make another network call here.
   */
  // The whole waterfall gets remounted when the waterfallId changes, so this resets to an empty set automatically
  const [initWaterfallNodeIds, setInitWaterfallNodeIds] = useState<Set<string>>(
    new Set<string>()
  );
  const waterfallWithFilter = useMemo(() => {
    return applyWaterfallFilter(waterfall);
  }, [waterfall]);
  const isFilteredWaterfall =
    waterfallWithFilter?.visualizationWithProjections.nodes.length !==
    waterfall?.visualizationWithProjections.nodes.length;
  const isEmptyWaterfall = isEmpty(
    waterfall?.visualizationWithProjections.nodes
  );
  const visibleNodeIds = useMemo(
    () =>
      waterfallWithFilter?.visualizationWithProjections.nodes.map((node) =>
        getNodeId({
          id: node.id,
          afterDeath: node.afterDeath,
        })
      ) ?? [],
    [waterfallWithFilter?.visualizationWithProjections.nodes]
  );

  const hiddenNodeIds = useMemo(() => {
    return (
      waterfall?.visualizationWithProjections.nodes
        .filter(
          (node) =>
            !visibleNodeIds?.includes(
              getNodeId({
                id: node.id,
                afterDeath: node.afterDeath,
              })
            )
        )
        .map((node) =>
          getNodeId({
            id: node.id,
            afterDeath: node.afterDeath,
          })
        ) ?? []
    );
  }, [waterfall?.visualizationWithProjections.nodes, visibleNodeIds]);
  useEffect(() => {
    if (initWaterfallNodeIds.size > 0) return;
    if (!waterfall) return;

    const waterfallIds = new Set<string>();
    waterfall.visualizationWithProjections.nodes.forEach((node) => {
      const nodeId = getNodeId({
        id: node.group?.id ?? node.id,
        afterDeath: node.afterDeath,
      });

      waterfallIds.add(nodeId);
    });

    setInitWaterfallNodeIds(waterfallIds);
  }, [initWaterfallNodeIds.size, waterfall]);
  /*
   * End of ported code
   */
  if (!householdId) return null;

  return (
    <EstateWaterfallDiagramSlideOutput
      bundle={bundle}
      page={page}
      waterfall={waterfallWithFilter}
      SlideWrapper={SlideWrapper}
      primaryClients={primaryClients}
      isFilteredWaterfall={isFilteredWaterfall}
      isEmptyWaterfall={isEmptyWaterfall}
      visibleNodeIds={visibleNodeIds}
      hiddenNodeIds={hiddenNodeIds}
      initWaterfallNodeIds={initWaterfallNodeIds}
      householdId={householdId}
      isVisible={isVisible}
    />
  );
}

interface EstateWaterfallDiagramSlideOutputProps
  extends EstateWaterfallDiagramSlideProps {
  waterfall: ClientPresentationDesignerV2_EstateWaterfallFragment;
  primaryClients: ContextPrimaryClient[];
  isFilteredWaterfall: boolean;
  isEmptyWaterfall: boolean;
  visibleNodeIds: string[];
  hiddenNodeIds: string[];
  initWaterfallNodeIds: Set<string>;
  householdId: string;
}
function EstateWaterfallDiagramSlideOutput({
  bundle,
  page,
  waterfall,
  SlideWrapper = Box,
  primaryClients,
  isFilteredWaterfall,
  isEmptyWaterfall,
  visibleNodeIds,
  hiddenNodeIds,
  initWaterfallNodeIds,
  householdId,
  isVisible,
}: EstateWaterfallDiagramSlideOutputProps) {
  const [waterfallReady, setWaterfallReady] = useState(false);
  const registerWaterfallReady = useCallback(() => {
    setWaterfallReady(true);
  }, [setWaterfallReady]);

  const [estateWaterfallContainerRef, containerDimensions] =
    useMeasure<HTMLDivElement>();

  const slideId = `estate-waterfall-${bundle.id}-diagram-${page.id}`;

  useRegisterSlide({
    slideId,
    title: bundle.displayName,
    includeInToC: page.id === first(bundle.pages)?.id,
    bundleKind: PresentationBundleKind.WaterfallOverviewBundle,
  });

  if (!isVisible) {
    return null;
  }

  return (
    <SlideWrapper key={slideId}>
      <PresentationSlide.Slide
        id={slideId}
        leftHeaderContent={
          <PresentationSlide.MainHeading heading={bundle.displayName} />
        }
        rightHeaderContent={
          <PresentationSlideSecondaryHeading
            clientDisplayName={waterfall.household?.displayName ?? null}
          />
        }
        footer={<ClientPresentationV2Footer slideId={slideId} />}
        readyToScale={waterfallReady}
        data-presentationwaterfallready={waterfallReady.toString()}
      >
        <Stack
          spacing={3}
          justifyContent="space-between"
          py={PRESENTATION_SPACING_UNITS}
          height="100%"
        >
          <Stack flexGrow={1}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              px={PRESENTATION_SPACING_UNITS}
            >
              <Typography variant="h2">Waterfall diagram</Typography>
            </Stack>
            <FlowChartProvider>
              <EstateWaterfallProvider
                waterfall={waterfall as EstateWaterfallFragment}
                primaryClients={primaryClients}
                isFilteredWaterfall={isFilteredWaterfall}
                isEmptyWaterfall={isEmptyWaterfall}
                visibleNodeIds={visibleNodeIds}
                hiddenNodeIds={hiddenNodeIds}
                initWaterfallNodeIds={initWaterfallNodeIds}
                presentationMode
                registerWaterfallReady={registerWaterfallReady}
                containerDimensions={containerDimensions}
              >
                <Stack
                  minHeight={MIN_HEIGHT}
                  height="100%"
                  flexGrow={1}
                  ref={estateWaterfallContainerRef}
                >
                  <AutoSizer>
                    {({ height, width }: { height: number; width: number }) => (
                      <EstateWaterfall
                        householdId={householdId}
                        waterfallId={waterfall.id}
                        height={Math.max(MIN_HEIGHT, height)}
                        width={width}
                        // Key so the state is reset on slide change
                        key={`${bundle.id}-${waterfall.id}`}
                      />
                    )}
                  </AutoSizer>
                </Stack>
              </EstateWaterfallProvider>
            </FlowChartProvider>
          </Stack>
          <Box px={PRESENTATION_SPACING_UNITS}>
            <DisclaimerCallout waterfall={waterfall} />
          </Box>
        </Stack>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}
