import { Stack, Typography } from '@mui/material';
import { isEqual, some } from 'lodash';
import { useMemo, useState } from 'react';
import { usePrevious } from 'react-use';

import { Callout } from '@/components/notifications/Callout/Callout';
import { Loader } from '@/components/progress/Loader/Loader';
import { useFormContext } from '@/components/react-hook-form';
import { diagnostics } from '@/utils/diagnostics';

import {
  HypotheticalSaleLoanFormKind,
  HypotheticalSaleLoanFormShape,
} from '../EstateWaterfallHypotheticalSaleLoanModal.types';
import { mapFormDataToCreateInput } from '../EstateWaterfallHypotheticalSaleLoanModal.utils';
import {
  SaleLoanIllustration_EstateWaterfallFragment,
  useSaleLoanEstateWaterfallQuery,
} from './graphql/SaleLoanIllustration.generated';
import { useGrowthProfileOptions } from './hooks/useGrowthProfileOptions';
import { SaleLoanIllustrationAssumptionsButton } from './SaleLoanIllustrationAssumptionsButton';
import { SaleLoanIllustrationAssumptionsCard } from './SaleLoanIllustrationAssumptionsCard';
import { SaleLoanIllustrationBarChart } from './SaleLoanIllustrationBarChart';
import { PROJECTION_PSEUDO_ID } from './SaleLoanIllustrationContent.utils';
import { SaleLoanIllustrationDetails } from './SaleLoanIllustrationDetails/SaleLoanIllustrationDetails';

interface SaleLoanIllustrationContentProps {
  waterfallId: string;
  householdId: string;
  saleLoanId: string | null; // saleLoanId is null if we're creating a new loan and it's not yet persisted
  modalKind: HypotheticalSaleLoanFormKind;
}

function hasAllRequiredFundingData(formData: HypotheticalSaleLoanFormShape) {
  switch (formData.fundingKind) {
    case 'proRata':
      return formData.proRataAmount || formData.proRataPercent;
    case 'assets': {
      const hasSelectedItems =
        formData.selectedAssetClassesAndBusinessIds.length > 0;
      const hasDefinedAmount = some(
        formData.selectedAssetClassAndBusinessValues,
        (value) => Boolean(value?.amount)
      );
      const hasDefinedPercent = some(
        formData.selectedAssetClassAndBusinessValues,
        (value) => Boolean(value?.percent)
      );
      return hasSelectedItems && (hasDefinedAmount || hasDefinedPercent);
    }
    default:
      return false;
  }
}

function getAtLeastCurrentYear(inputCurrentYear: number | null) {
  const currentYear = new Date().getFullYear();
  return Math.max(inputCurrentYear ?? 0, currentYear);
}

export function SaleLoanIllustrationContent({
  waterfallId,
  householdId,
  saleLoanId,
  modalKind,
}: SaleLoanIllustrationContentProps) {
  const [areAssumptionsOpen, setAreAssumptionsOpen] = useState(false);
  const [projectionWaterfall, setProjectionWaterfall] =
    useState<SaleLoanIllustration_EstateWaterfallFragment | null>(null);
  const { watch } = useFormContext<HypotheticalSaleLoanFormShape>();
  const formData = watch();
  const projectionInput = useMemo(() => {
    // we don't throw inside mapFormDataToCreateInput for these, so we check them here to
    // make sure we don't start showing projection data too soon
    if (!hasAllRequiredFundingData(formData)) {
      return null;
    }

    if (!formData.paymentModel) {
      return null;
    }

    if (
      !formData.firstGrantorDeathYear ||
      formData.firstGrantorDeathYear < 1900
    ) {
      return null;
    }

    if (
      formData.secondGrantorDeathYear &&
      formData.secondGrantorDeathYear < 1900
    ) {
      return null;
    }

    if (!formData.growthProfileOverrideId) {
      return null;
    }

    try {
      return {
        firstGrantorDeathYear: getAtLeastCurrentYear(
          formData.firstGrantorDeathYear
        ),
        secondGrantorDeathYear: getAtLeastCurrentYear(
          formData.secondGrantorDeathYear
        ),
        growthProfileID: formData.growthProfileOverrideId,
        simulationInputs: {
          hypotheticalSaleLoans: [
            {
              // we skip the projection if we don't have a valid form data
              create: mapFormDataToCreateInput(
                formData,
                modalKind,
                waterfallId,
                householdId
              ),
              pseudoID: PROJECTION_PSEUDO_ID,
              replaces: saleLoanId ?? null,
            },
          ],
        },
      };
    } catch (error) {
      // we don't have a valid form data, so we can't create a projection. this is expected when the user
      // is still filling out the form and we have incomplete data, so we don't want to log or show an error
      diagnostics.debug(
        '[SALE/LOAN] Invalid or insufficient form data; not creating projection',
        { error }
      );
      return null;
    }
  }, [formData, modalKind, waterfallId, householdId, saleLoanId]);

  const previousInput = usePrevious(projectionInput);
  const isSameInput = isEqual(previousInput, projectionInput);
  const skip = !projectionInput || isSameInput;
  const { error, loading, refetch } = useSaleLoanEstateWaterfallQuery({
    fetchPolicy: 'no-cache',
    variables: {
      waterfallId,
      projectionInput: projectionInput!,
      noSaleLoanProjectionInput: {
        firstGrantorDeathYear: formData.firstGrantorDeathYear,
        secondGrantorDeathYear: formData.secondGrantorDeathYear,
        growthProfileID: formData.growthProfileOverrideId,
        simulationInputs: {
          hypotheticalSaleLoans: [
            {
              create: null,
              pseudoID: PROJECTION_PSEUDO_ID,
              replaces: saleLoanId ?? null,
            },
          ],
        },
      },
    },
    onError: (error) => {
      diagnostics.error(
        'Error fetching projected waterfall for sale/loan designer',
        error
      );
    },
    // retrigger oncompleted when the we refetch the projections
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setProjectionWaterfall(data?.estateWaterfalls.edges?.[0]?.node ?? null);
    },
    skip,
  });

  const {
    options: growthProfileOptions,
    loading: growthProfileOptionsLoading,
  } = useGrowthProfileOptions(householdId);

  const handleToggleAssumptions = () => {
    setAreAssumptionsOpen((areOpen) => !areOpen);
  };

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="h1">Illustration</Typography>
          {loading && <Loader circularProgressProps={{ size: 20 }} />}
        </Stack>
        <SaleLoanIllustrationAssumptionsButton
          onClick={handleToggleAssumptions}
          loading={growthProfileOptionsLoading}
          growthProfileOptions={growthProfileOptions}
        />
      </Stack>

      {areAssumptionsOpen && (
        <SaleLoanIllustrationAssumptionsCard
          refetchProjections={refetch}
          growthProfileOptions={growthProfileOptions}
        />
      )}

      <SaleLoanIllustrationBarChart waterfall={projectionWaterfall} />

      {error && (
        <Callout severity="error">
          There was an error fetching the projected details. Please refresh the
          page and try again.
        </Callout>
      )}

      <SaleLoanIllustrationDetails waterfall={projectionWaterfall} />
    </Stack>
  );
}
