import { compact, isEmpty, orderBy } from 'lodash';
import { PropsWithChildren, useCallback, useState } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { getSuggestionAsSearchQuery } from '@/modules/aiSuggestions/aiSuggestions.utils';
import { evidenceAdapter } from '@/modules/aiSuggestions/evidence';
import { useMultiDocumentSearch } from '@/modules/documents/hooks/useMultiDocumentSearch';
import { diagnostics } from '@/utils/diagnostics';

import { CommonAiSuggestionFragment } from '../graphql/aiSuggestions.generated';
import { SelectedSuggestionEvidenceContext } from './SelectedSuggestionEvidence.context';

function useContextValue(): SelectedSuggestionEvidenceContext {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const { doMultiDocSearch } = useMultiDocumentSearch();

  const [documentPanelProps, setDocumentPanelProps] = useState<
    SelectedSuggestionEvidenceContext['documentPanelProps']
  >({
    activeDocumentViewerTab: undefined,
    activeDocumentId: undefined,
    annotations: undefined,
    searchQuery: '',
    searchLoading: false,
  });

  const locateSuggestion = useCallback(
    async (suggestion: CommonAiSuggestionFragment) => {
      if (!suggestion.file?.document?.id && !suggestion.kgNode) {
        diagnostics.error(
          `Trying to get evidence for a suggestion without a source document or KGNode. suggestionID=${suggestion.id}`
        );
        return;
      }

      // When the user clicks to view the evidence for a suggestion, and the
      // suggestion already has pre-searched evidence, we can show it directly
      // by setting the annotations...

      // Either all the evidence for this suggestion comes from a single document.
      if (suggestion.file?.document?.id) {
        setDocumentPanelProps({
          activeDocumentViewerTab: 'document',
          activeDocumentId: suggestion.file?.document?.id,
          annotations: compact(suggestion.evidence || []).map((e) =>
            evidenceAdapter(e, suggestion.file?.document?.id || '')
          ),
          searchQuery: getSuggestionAsSearchQuery(suggestion),
          searchLoading: false,
        });
        return;
      }

      // Or the suggestion has a list of evidence from multiple documents,
      // in which case, group the evidence list by document.
      if (!isEmpty(suggestion.evidence)) {
        const annotations = orderBy(
          (suggestion.evidence || [])
            .filter((e) => e?.document?.id) // Filter out evidence without a document
            .map((e) => evidenceAdapter(e, e?.document?.id || '')), // Convert to DocumentSearchResult type
          ['documentId']
        );

        setDocumentPanelProps({
          activeDocumentViewerTab: 'document',
          activeDocumentId: annotations[0]?.documentID,
          annotations,
          searchQuery: getSuggestionAsSearchQuery(suggestion),
          searchLoading: false,
        });
        return;
      }

      // OTHERWISE... we need to do an adhoc search for the evidence...

      const documentIds = compact(
        suggestion.kgNode?.mergedFrom?.map((n) => n.file?.document?.id)
      );

      setDocumentPanelProps({
        activeDocumentViewerTab: 'document',
        // Don't change the active document in the viewer until the search results come back
        activeDocumentId: undefined,
        searchQuery: getSuggestionAsSearchQuery(suggestion),
        annotations: undefined,
        searchLoading: true,
      });

      // Use the node name to search for the suggestion's evidence in the documents
      const searchQuery = getSuggestionAsSearchQuery(suggestion);

      try {
        const boundingRegions = await doMultiDocSearch(
          searchQuery,
          documentIds
        );

        setDocumentPanelProps((prev) => ({
          ...prev,
          activeDocumentId: boundingRegions[0]?.documentID,
          annotations: boundingRegions,
          searchLoading: false,
        }));
      } catch (e) {
        showFeedback(
          'Unable to locate evidence for this suggestion. Please try again later.'
        );
        reportError('error in multi-doc evidence search', e as Error);
      } finally {
        setDocumentPanelProps((prev) => ({
          ...prev,
          searchLoading: false,
        }));
      }
    },
    [doMultiDocSearch, reportError, showFeedback]
  );

  return {
    locateSuggestion,
    documentPanelProps,
    setDocumentPanelProps,
  };
}

export function SelectedSuggestionEvidenceProvider({
  children,
}: PropsWithChildren) {
  const value = useContextValue();

  return (
    <SelectedSuggestionEvidenceContext.Provider value={value}>
      {children}
    </SelectedSuggestionEvidenceContext.Provider>
  );
}
