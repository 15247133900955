import { noop } from 'lodash';
import { createContext, Dispatch, SetStateAction } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { AIOnboardingDocumentPanelProps } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal.types';

import { CommonAiSuggestionFragment } from '../graphql/aiSuggestions.generated';

/**
 * SuggestionEvidence holds the information about the selected suggestion to
 * show the evidence for. We use this object to communicate the annotations and
 * search bar text between the entity forms and the document viewer.
 */

export interface SelectedSuggestionEvidenceContext {
  /**
   * Locate the evidence for a suggestion in the doc viewer.
   * @param suggestion
   */
  locateSuggestion: (suggestion: CommonAiSuggestionFragment) => void;

  /**
   * The evidence for the selected suggestion to show in the doc viewer.
   */
  documentPanelProps: AIOnboardingDocumentPanelProps;
  setDocumentPanelProps: Dispatch<
    SetStateAction<AIOnboardingDocumentPanelProps>
  >;
}

export const getDefaultValues = (): SelectedSuggestionEvidenceContext => ({
  locateSuggestion: noop,
  documentPanelProps: {
    activeDocumentViewerTab: undefined,
    activeDocumentId: undefined,
    searchQuery: '',
    searchLoading: false,
    annotations: undefined,
  },
  setDocumentPanelProps: noop,
});

export const SelectedSuggestionEvidenceContext =
  createContext<SelectedSuggestionEvidenceContext>(getDefaultValues());

export const useSelectedSuggestionEvidenceContext = () => {
  return useGuardedContext(
    SelectedSuggestionEvidenceContext,
    'SelectedSuggestionEvidenceProvider'
  );
};
