import { Box, Stack, SxProps, Theme } from '@mui/material';
import { forwardRef, PropsWithChildren } from 'react';
import { useMeasure } from 'react-use';

import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { PRESENTATION_SPACING_UNITS } from '@/modules/presentation/presentation.constants';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { US_LETTER_ASPECT_RATIO } from '@/modules/presentation/PresentationSlide/PresentationSlide.constants';
import { ScalableSlideHeight } from '@/modules/presentation/ScalableSlide/ScalableSlideHeight';

export type SlideContainerProps = PropsWithChildren<{
  sx?: SxProps<Theme>;
  id?: string;
  autoZoomOnPrint?: boolean;
  scaleFactor?: number;
  hideFooter?: boolean;
}>;

export const BOTTOM_BAR_HEIGHT = 8 as const;

const ViewOnlyContent = forwardRef<HTMLDivElement, PropsWithChildren>(
  function _ViewOnlyContent({ children }, ref) {
    return (
      <Box p={PRESENTATION_SPACING_UNITS} width="100%" ref={ref}>
        {children}
      </Box>
    );
  }
);

export function SlideContainer({
  children,
  sx,
  id,
  autoZoomOnPrint = false,
  scaleFactor = 100,
  hideFooter = false,
}: SlideContainerProps) {
  const viewOnly = useViewOnly();

  const [contentRef, { width: contentWidth, height: contentHeight }] =
    useMeasure<HTMLDivElement>();

  if (viewOnly) {
    // Slide container for print to PDF
    return (
      <PresentationSlide.Slide
        id={id ?? 'slide'}
        footer={hideFooter ? null : <PresentationSlide.Footer />}
      >
        {autoZoomOnPrint ? (
          <ScalableSlideHeight
            contentHeightPx={contentHeight}
            containerWidthPx={contentWidth} // The content width is the page width
            aspectRatio={US_LETTER_ASPECT_RATIO}
            scaleFactor={scaleFactor}
          >
            <ViewOnlyContent ref={contentRef}>{children}</ViewOnlyContent>
          </ScalableSlideHeight>
        ) : (
          <ViewOnlyContent>{children}</ViewOnlyContent>
        )}
      </PresentationSlide.Slide>
    );
  }

  // Slide container for presentation mode
  return (
    <Stack sx={sx} pb={BOTTOM_BAR_HEIGHT} flexGrow={1} id={id}>
      <Stack width="100%" justifyContent="space-between" flexGrow={1}>
        {children}
        {hideFooter ? <Box /> : <PresentationSlide.Footer />}
      </Stack>
    </Stack>
  );
}
