import { Box, Stack, Typography } from '@mui/material';
import { FormProvider, useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { useForm, useFormContext } from '@/components/react-hook-form';

import { ClientPresentationV2Configuration } from '../../ClientPresentationDesignerV2.types';

function PresentationSettingsModalPagination() {
  const { control } = useFormContext<ClientPresentationV2Configuration>();
  const [showPageNumbers] = useWatch({
    control,
    name: ['showPageNumbers'],
  });
  return (
    <FormLayoutRow>
      <FormLayoutItem width={12}>
        <Card variant="filled">
          <Stack sx={{ p: 3, pb: 4 }} spacing={2}>
            <FormLayoutItem width={12}>
              <Typography variant="h4">Page numbers</Typography>
            </FormLayoutItem>
            <FormLayoutItem width={12}>
              <FormAwareSwitch<ClientPresentationV2Configuration>
                control={control}
                fieldName="showPageNumbers"
                label="Show page numbers"
                labelPosition="right"
              />
            </FormLayoutItem>
            {showPageNumbers && (
              <>
                <FormLayoutItem width={12}>
                  <Box pl={7}>
                    <FormAwareSwitch<ClientPresentationV2Configuration>
                      control={control}
                      fieldName="showPageNumbersOnCoverSlide"
                      label='Show on "Cover" page'
                      labelPosition="right"
                    />
                  </Box>
                </FormLayoutItem>
                <FormLayoutItem width={12}>
                  <Stack direction="row" pl={7} alignItems="center">
                    <Typography variant="label">Start at&nbsp;</Typography>
                    <Box width="48px">
                      <FormAwareFormattedNumberInput<ClientPresentationV2Configuration>
                        control={control}
                        fieldName="pageNumberToStartFrom"
                        label="Start from page"
                        hideLabel
                        allowNegative={false}
                      />
                    </Box>
                  </Stack>
                </FormLayoutItem>
              </>
            )}
          </Stack>
        </Card>
      </FormLayoutItem>
    </FormLayoutRow>
  );
}

function PresentationSettingsModalInner() {
  const { control } = useFormContext<ClientPresentationV2Configuration>();
  return (
    <>
      <PresentationSettingsModalPagination />
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          <FormAwareSwitch<ClientPresentationV2Configuration>
            control={control}
            fieldName="showLegalDisclaimer"
            label="Show legal disclaimer on all pages"
            labelPosition="right"
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </>
  );
}

export interface PresentationSettingsModalProps {
  onSave: (
    presentationConfiguration: ClientPresentationV2Configuration
  ) => void;
  onClose: () => void;
  presentationConfiguration: ClientPresentationV2Configuration;
}

export function PresentationSettingsModal({
  onClose,
  onSave,
  presentationConfiguration,
}: PresentationSettingsModalProps) {
  const modalForm = useForm<ClientPresentationV2Configuration>({
    defaultValues: presentationConfiguration,
  });

  const { handleSubmit } = modalForm;

  const onSubmit = handleSubmit((data) => {
    onSave(data);
    onClose();
  });

  return (
    <FormProvider {...modalForm}>
      <FormModal
        onClose={onClose}
        heading="Presentation settings"
        isOpen
        actions={
          <>
            <Button variant="secondary" size="sm" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" size="sm" onClick={onSubmit}>
              Save
            </Button>
          </>
        }
      >
        <PresentationSettingsModalInner />
      </FormModal>
    </FormProvider>
  );
}
