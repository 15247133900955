import { Stack } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ArrowRightIcon } from '@/components/icons/ArrowRightIcon';
import { WithClasses } from '@/styles/types';

import { TileThemedTypography } from './TileThemedTypography';
import { TileFontSizeVariant, TileVariant } from './types';

const useStyles = makeStyles()((_theme) => {
  return {
    before: {},
    after: {},
  };
});

export interface TileBeforeAndAfterProps {
  before: string;
  after: string;
  variant: TileVariant;
  fontSizeVariant: TileFontSizeVariant;
  classes?: WithClasses<typeof useStyles>;
}

export function TileBeforeAndAfter({
  before,
  after,
  classes: externalClasses,
  variant,
  fontSizeVariant,
}: TileBeforeAndAfterProps) {
  const { classes } = useStyles(undefined, {
    props: { classes: externalClasses },
  });

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <TileThemedTypography
        variant="h2light"
        tileVariant={variant}
        fontSizeVariant={fontSizeVariant}
        className={classes.before}
      >
        {before}
      </TileThemedTypography>
      <ArrowRightIcon size={16} />
      <TileThemedTypography
        variant="h2light"
        tileVariant={variant}
        fontSizeVariant={fontSizeVariant}
        className={classes.after}
      >
        {after}
      </TileThemedTypography>
    </Stack>
  );
}
