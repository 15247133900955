import { Box, Menu, SxProps, Typography, useTheme } from '@mui/material';
import { MenuItem as MUIMenuItem } from '@mui/material';
import { noop } from 'lodash';
import React, { useRef } from 'react';

import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { useDelayedCloseBehavior } from '@/hooks/useDelayedCloseBehavior';
import { COLORS } from '@/styles/tokens/colors';

import { MenuItem } from './MenuItem';

export interface FlyoutMenuItemProps {
  icon?: React.ReactNode;
  label: React.ReactNode;
  children?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  sx?: SxProps;
  typographyProps?: React.ComponentProps<typeof Typography>;
  muiMenuItemProps?: React.ComponentProps<typeof MUIMenuItem>;
}

/**
 * A menu item component that displays a submenu when hovered over.
 *
 * This component extends the basic MenuItem to add flyout/nested menu functionality.
 * When a user hovers over the menu item, it displays a submenu to the right of the
 * parent menu item. The submenu remains open for a short delay after the mouse leaves
 * to prevent accidental closing during normal mouse movement.
 */

export function FlyoutMenuItem({
  icon,
  label,
  children,
  disabled,
  loading,
  sx,
  typographyProps,
  muiMenuItemProps,
}: FlyoutMenuItemProps) {
  const theme = useTheme();
  const menuItemRef = useRef<HTMLLIElement>(null);

  const { isOpen, setIsMouseInTargetArea } = useDelayedCloseBehavior({
    closeDelayMs: 100,
  });

  return (
    <Box onMouseLeave={() => setIsMouseInTargetArea(false)}>
      <MenuItem
        sx={{
          ...sx,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }}
        icon={icon}
        label={label}
        onClick={noop} // No-op since this is just a flyout parent
        disabled={disabled}
        loading={loading}
        typographyProps={typographyProps}
        muiMenuItemProps={{
          ...muiMenuItemProps,
          onMouseEnter: () => setIsMouseInTargetArea(true),
          ref: menuItemRef,
        }}
        iconAfter={
          children &&
          !loading && (
            <Box
              display="flex"
              flexGrow={1}
              justifyContent="flex-end"
              alignItems="center"
            >
              <ChevronRightIcon size={18} color={COLORS.GRAY[500]} />
            </Box>
          )
        }
      />
      {children && (
        <Menu
          open={isOpen}
          onMouseEnter={() => setIsMouseInTargetArea(true)}
          onMouseLeave={() => setIsMouseInTargetArea(false)}
          anchorEl={menuItemRef.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            maxHeight: 500,
            overflow: 'auto',
            pointerEvents: 'none',
            '& .MuiPaper-root': {
              pointerEvents: 'auto',
              marginLeft: 1,
            },
          }}
        >
          {children}
        </Menu>
      )}
    </Box>
  );
}
