// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import { GraphViz_NodeConfigFragmentDoc } from '../../../../graphViz/graphql/GraphVizNodeConfig.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEstateWaterfallFirstGrantorQueryVariables = Types.Exact<{
  where: Types.EstateWaterfallWhereInput;
}>;


export type GetEstateWaterfallFirstGrantorQuery = { __typename?: 'Query', estateWaterfalls: { __typename?: 'EstateWaterfallConnection', edges?: Array<{ __typename?: 'EstateWaterfallEdge', node?: { __typename?: 'EstateWaterfall', id: string, displayName: string, kind: Types.EstateWaterfallKind, nodeConfigurations: Array<{ __typename?: 'EntityGraphNodeConfiguration', nodeID: string, hidden?: boolean | null, xPosition: number, yPosition: number, height?: number | null, width?: number | null, fontSize?: string | null, notes?: string | null }>, filter?: { __typename?: 'EstateWaterfallVisualizationFilter', id: string, includedClients: { __typename?: 'ClientProfileConnection', edges?: Array<{ __typename?: 'ClientProfileEdge', node?: { __typename?: 'ClientProfile', id: string } | null } | null> | null }, includedEntities: { __typename?: 'EntityConnection', edges?: Array<{ __typename?: 'EntityEdge', node?: { __typename?: 'Entity', id: string } | null } | null> | null } } | null, parent?: { __typename?: 'EstateWaterfall', id: string } | null, firstGrantorDeath: { __typename?: 'ClientProfile', id: string }, household: { __typename?: 'Household', id: string, displayName: string } } | null } | null> | null } };

export type GetEstateWaterfallFirstGrantor_EstateWaterfallFragment = { __typename?: 'EstateWaterfall', id: string, displayName: string, kind: Types.EstateWaterfallKind, nodeConfigurations: Array<{ __typename?: 'EntityGraphNodeConfiguration', nodeID: string, hidden?: boolean | null, xPosition: number, yPosition: number, height?: number | null, width?: number | null, fontSize?: string | null, notes?: string | null }>, filter?: { __typename?: 'EstateWaterfallVisualizationFilter', id: string, includedClients: { __typename?: 'ClientProfileConnection', edges?: Array<{ __typename?: 'ClientProfileEdge', node?: { __typename?: 'ClientProfile', id: string } | null } | null> | null }, includedEntities: { __typename?: 'EntityConnection', edges?: Array<{ __typename?: 'EntityEdge', node?: { __typename?: 'Entity', id: string } | null } | null> | null } } | null, parent?: { __typename?: 'EstateWaterfall', id: string } | null, firstGrantorDeath: { __typename?: 'ClientProfile', id: string }, household: { __typename?: 'Household', id: string, displayName: string } };

export type DownloadEntityTaxDetailsMutationVariables = Types.Exact<{
  waterfallId: Types.Scalars['ID']['input'];
}>;


export type DownloadEntityTaxDetailsMutation = { __typename?: 'Mutation', downloadEntityBreakdownExcel: { __typename?: 'File', id: string, download: { __typename?: 'FileDownload', downloadURL: string } } };

export const GetEstateWaterfallFirstGrantor_EstateWaterfallFragmentDoc = gql`
    fragment GetEstateWaterfallFirstGrantor_EstateWaterfall on EstateWaterfall {
  id
  displayName
  nodeConfigurations {
    ...GraphViz_NodeConfig
  }
  filter {
    id
    includedClients {
      edges {
        node {
          id
        }
      }
    }
    includedEntities {
      edges {
        node {
          id
        }
      }
    }
  }
  kind
  parent {
    id
  }
  firstGrantorDeath {
    id
  }
  household {
    id
    displayName
  }
}
    ${GraphViz_NodeConfigFragmentDoc}`;
export const GetEstateWaterfallFirstGrantorDocument = gql`
    query GetEstateWaterfallFirstGrantor($where: EstateWaterfallWhereInput!) {
  estateWaterfalls(where: $where) {
    edges {
      node {
        ...GetEstateWaterfallFirstGrantor_EstateWaterfall
      }
    }
  }
}
    ${GetEstateWaterfallFirstGrantor_EstateWaterfallFragmentDoc}`;

/**
 * __useGetEstateWaterfallFirstGrantorQuery__
 *
 * To run a query within a React component, call `useGetEstateWaterfallFirstGrantorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstateWaterfallFirstGrantorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstateWaterfallFirstGrantorQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetEstateWaterfallFirstGrantorQuery(baseOptions: Apollo.QueryHookOptions<GetEstateWaterfallFirstGrantorQuery, GetEstateWaterfallFirstGrantorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEstateWaterfallFirstGrantorQuery, GetEstateWaterfallFirstGrantorQueryVariables>(GetEstateWaterfallFirstGrantorDocument, options);
      }
export function useGetEstateWaterfallFirstGrantorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEstateWaterfallFirstGrantorQuery, GetEstateWaterfallFirstGrantorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEstateWaterfallFirstGrantorQuery, GetEstateWaterfallFirstGrantorQueryVariables>(GetEstateWaterfallFirstGrantorDocument, options);
        }
export function useGetEstateWaterfallFirstGrantorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEstateWaterfallFirstGrantorQuery, GetEstateWaterfallFirstGrantorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEstateWaterfallFirstGrantorQuery, GetEstateWaterfallFirstGrantorQueryVariables>(GetEstateWaterfallFirstGrantorDocument, options);
        }
export type GetEstateWaterfallFirstGrantorQueryHookResult = ReturnType<typeof useGetEstateWaterfallFirstGrantorQuery>;
export type GetEstateWaterfallFirstGrantorLazyQueryHookResult = ReturnType<typeof useGetEstateWaterfallFirstGrantorLazyQuery>;
export type GetEstateWaterfallFirstGrantorSuspenseQueryHookResult = ReturnType<typeof useGetEstateWaterfallFirstGrantorSuspenseQuery>;
export type GetEstateWaterfallFirstGrantorQueryResult = Apollo.QueryResult<GetEstateWaterfallFirstGrantorQuery, GetEstateWaterfallFirstGrantorQueryVariables>;
export const DownloadEntityTaxDetailsDocument = gql`
    mutation DownloadEntityTaxDetails($waterfallId: ID!) {
  downloadEntityBreakdownExcel(estateWaterfallID: $waterfallId) {
    id
    download {
      downloadURL
    }
  }
}
    `;
export type DownloadEntityTaxDetailsMutationFn = Apollo.MutationFunction<DownloadEntityTaxDetailsMutation, DownloadEntityTaxDetailsMutationVariables>;

/**
 * __useDownloadEntityTaxDetailsMutation__
 *
 * To run a mutation, you first call `useDownloadEntityTaxDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadEntityTaxDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadEntityTaxDetailsMutation, { data, loading, error }] = useDownloadEntityTaxDetailsMutation({
 *   variables: {
 *      waterfallId: // value for 'waterfallId'
 *   },
 * });
 */
export function useDownloadEntityTaxDetailsMutation(baseOptions?: Apollo.MutationHookOptions<DownloadEntityTaxDetailsMutation, DownloadEntityTaxDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadEntityTaxDetailsMutation, DownloadEntityTaxDetailsMutationVariables>(DownloadEntityTaxDetailsDocument, options);
      }
export type DownloadEntityTaxDetailsMutationHookResult = ReturnType<typeof useDownloadEntityTaxDetailsMutation>;
export type DownloadEntityTaxDetailsMutationResult = Apollo.MutationResult<DownloadEntityTaxDetailsMutation>;
export type DownloadEntityTaxDetailsMutationOptions = Apollo.BaseMutationOptions<DownloadEntityTaxDetailsMutation, DownloadEntityTaxDetailsMutationVariables>;
export const Operations = {
  Query: {
    GetEstateWaterfallFirstGrantor: 'GetEstateWaterfallFirstGrantor' as const
  },
  Mutation: {
    DownloadEntityTaxDetails: 'DownloadEntityTaxDetails' as const
  },
  Fragment: {
    GetEstateWaterfallFirstGrantor_EstateWaterfall: 'GetEstateWaterfallFirstGrantor_EstateWaterfall' as const
  }
}