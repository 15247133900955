import { Typography } from '@mui/material';
import Decimal from 'decimal.js';

import { ClientPresentationDesigner_EstateWaterfallFragment } from '../clientPresentation/ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';
import { getModelingDisclaimerText } from './estateWaterfallPresentation.utils';

interface DisclaimerCalloutProps {
  waterfall: ClientPresentationDesigner_EstateWaterfallFragment;
}

export function DisclaimerCallout({ waterfall }: DisclaimerCalloutProps) {
  return (
    <Typography variant="subtitle2">
      {getModelingDisclaimerText({
        estateTaxExemptionGrowthAssumption:
          waterfall.visualizationWithProjections.growthProfile
            ?.exemptionGrowthRate ?? new Decimal(0),
        includeExemptionSunset:
          waterfall.visualizationWithProjections.growthProfile
            ?.willExemptionSunset ?? false,
        estateTaxRateAssumption: new Decimal(40),
      })}
    </Typography>
  );
}
