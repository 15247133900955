import { createSectionBoundsHandlers } from '@/components/diagrams/FlowChart/nodes/SectionLabelNode/hooks';

import { EstateWaterfallState } from '../types';

export function applySectionBoundsToState(
  state: EstateWaterfallState
): EstateWaterfallState {
  const nodes = [...state.nodes];
  if (!nodes.length) {
    return { ...state, nodes };
  }

  const { initSectionBounds } = createSectionBoundsHandlers({
    changes: [],
    nextNodes: nodes,
  });

  initSectionBounds();

  return { ...state, nodes };
}
