import { cx } from '@emotion/css';
import { Box } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';

import {
  Wiggle,
  WiggleProps,
  WiggleStatus,
} from '@/components/animation/Wiggle/Wiggle';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { WithClasses } from '@/styles/types';

import { TILE_DEFAULT_VARIANT, TILE_DEFAULT_WIDTH } from './constants';
import { useStyles, useTileClasses } from './styles';
import { TileCard } from './TileCard';
import { TileFontSizeVariant, TileVariant } from './types';

export interface TileProps {
  lineOne: string;
  lineTwo?: ReactNode;
  lineThree?: ReactNode;
  variant?: TileVariant;
  fontSizeVariant?: TileFontSizeVariant;
  notes?: string;
  isGrouped?: boolean;
  isSelected?: boolean;
  children?: ReactNode;
  footerLabel?: string;
  classes?: WithClasses<typeof useStyles>;
  wiggleClasses?: WiggleProps['classes'];
  dragging?: boolean;
  hover?: boolean;
  isNewTile?: boolean;
  order?: number;
  xOffset?: number;
  yOffset?: number;
  stackY?: boolean;
  centerY?: boolean;
  width?: number;
  height?: number;
  omitFromCentering?: boolean;
  slots?: {
    FooterLabel?: ReactNode;
  };
}

export function Tile(props: TileProps) {
  const viewOnly = useViewOnly();
  const {
    variant = TILE_DEFAULT_VARIANT,
    isNewTile,
    wiggleClasses,
    width,
    height,
    ...otherProps
  } = props;

  const tileClasses = useTileClasses({
    variant,
    viewOnly,
    ...otherProps,
  });
  const { classes, variantClasses } = tileClasses;

  const tileCardProps = {
    ...props,
    ...tileClasses,
  };

  const [wiggleStatus, setWiggleStatus] = useState<WiggleStatus>(
    WiggleStatus.None
  );

  useEffect(() => {
    if (isNewTile) {
      setWiggleStatus(WiggleStatus.Wiggle);
    } else {
      setWiggleStatus(WiggleStatus.None);
    }
  }, [isNewTile]);

  if (variant === TileVariant.Pixel) {
    return <Box sx={{ width: '1px', height: '1px' }} />;
  }

  return (
    <div
      className={cx(variantClasses[variant].wrapper, classes.wrapper)}
      style={{
        // Use || over ?? so a width/height of 0 receives a default value
        width: width || TILE_DEFAULT_WIDTH,
        height: height || undefined,
      }}
    >
      <Wiggle
        wiggleStatus={wiggleStatus}
        animation={{
          numCycles: 5,
        }}
        classes={wiggleClasses}
      >
        <TileCard {...tileCardProps} variant={variant} />
      </Wiggle>
    </div>
  );
}
