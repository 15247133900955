import { UnreachableError } from '@/utils/errors';

import {
  getTermVizFromEntity,
  GetTermVizFromEntityInput,
} from './getTermVizFromEntity';
import { CltTermDiagram_EntityFragment } from './graphql/CltTermDiagram.generated';

export interface GetCltTermVizFromEntityInput {
  entity: CltTermDiagram_EntityFragment;
  firstGrantorDeathId: string;
  survivingSpouse?: {
    id: string;
    displayName: string;
  };
}

export function getCltTermVizFromEntity({
  entity,
  firstGrantorDeathId,
  survivingSpouse,
}: GetCltTermVizFromEntityInput) {
  if (entity.subtype.__typename !== 'CLTTrust') {
    throw new UnreachableError({
      case: entity.subtype.__typename as never,
      message: 'Entity is not a CLTTrust',
    });
  }

  const grantors: GetTermVizFromEntityInput['grantors'] = [];
  entity.subtype.grantors?.forEach((g) => {
    if (!g.individual) {
      return;
    }

    grantors.push({
      id: g.id,
      individual: {
        id: g.individual.id,
        displayName: g.individual.displayName,
      },
    });
  });

  const lifetimeBeneficiaries: GetTermVizFromEntityInput['currentTermBeneficiaries'] =
    [];
  entity.subtype.lifetimeBeneficiaries?.forEach((lb) => {
    lifetimeBeneficiaries.push({
      id: lb.id,
      individual: lb.individual,
      organization: lb.organization,
      entity: lb.entity,
    });
  });

  const termBeneficiaries: GetTermVizFromEntityInput['termEndBeneficiaries'] =
    [];
  entity.subtype.remainderBeneficiaries?.forEach((rb) => {
    termBeneficiaries.push({
      id: rb.id,
      individual: rb.individual,
      organization: rb.organization,
      entity: rb.entity,
    });
  });

  return getTermVizFromEntity({
    entity,
    grantors,
    currentTermBeneficiaries: lifetimeBeneficiaries,
    termEndBeneficiaries: termBeneficiaries,
    firstGrantorDeathId,
    survivingSpouse,
  });
}
