import Decimal from 'decimal.js';
import {
  EffectFunction,
  EffectReducer,
  EffectReducerExec,
  EffectsMap,
  InitialEffectStateGetter,
} from 'use-effect-reducer';

import { GraphViz_NodeConfigFragment } from '@/modules/graphViz/graphql/GraphVizNodeConfig.fragment.generated';
import {
  AfterDeath,
  ClientOrganizationKind,
  EntityInEstateStatus,
  EntityKind,
  TestamentaryEntityKind,
} from '@/types/schema';

import { EntityDiagramAction } from './actions';
import { EntityDiagramEffect } from './effects';
import { EntityDiagramState } from './state';

export * from './actions';
export * from './effects';
export * from './entityDiagramGraph';
export * from './state';

export type EntityDiagramEffectFn<Effect extends EntityDiagramEffect> =
  EffectFunction<EntityDiagramState, EntityDiagramAction, Effect>;

export type EntityDiagramEffectReducerQueueEffect = EffectReducerExec<
  EntityDiagramState,
  EntityDiagramAction,
  EntityDiagramEffect
>;

export type EntityDiagramReducer = EffectReducer<
  EntityDiagramState,
  EntityDiagramAction,
  EntityDiagramEffect
>;

export type EntityDiagramEffectsMap = EffectsMap<
  EntityDiagramState,
  EntityDiagramAction,
  EntityDiagramEffect
>;

export type EntityDiagramActionFn<T> = (
  state: EntityDiagramState,
  action: T,
  queueEffect: EntityDiagramEffectReducerQueueEffect
) => EntityDiagramState;

export type EntityDiagramInitialStateGetter = InitialEffectStateGetter<
  EntityDiagramState,
  EntityDiagramAction,
  EntityDiagramEffect
>;

export interface EntityDiagram_EntityNode {
  __typename: 'Entity';
  id: string;
  entityKind: EntityKind;
  extendedDisplayKind: string;
  hasIncomingPouroverDisposition: boolean;
  ownershipPercentage?: Decimal;
  subtype: {
    id: string;
    displayName: string;
    policies?: {
      id: string;
      deathBenefitAmount: Decimal;
    }[];
  };
}

interface EntityDiagram_TestamentaryEntityNode {
  __typename: 'TestamentaryEntity';
  id: string;
  testamentaryEntityKind: TestamentaryEntityKind;
  displayName: string;
}

interface EntityDiagram_ClientProfileNode {
  __typename: 'ClientProfile';
  id: string;
  displayName: string;
  ownershipPercentage?: Decimal;
}

interface EntityDiagram_ClientOrganizationNode {
  __typename: 'ClientOrganization';
  id: string;
  kind: ClientOrganizationKind;
  name: string;
}

export enum EntityDiagramTileKind {
  Grantor = 'GRANTOR', // The tile representing the grantor/owner/donor of the entity in question
  Entity = 'ENTITY', // The tile representing the entity in question
  IncomeBeneficiary = 'INCOME_BENEFICIARY', // The tile representing the beneficiary before the term end
  LeadBeneficiary = 'LEAD_BENEFICIARY', // The tile representing the beneficiary after the term end
  OwnershipBeneficiary = 'OWNERSHIP_BENEFICIARY', // The tile representing a business owner
  DispositionBeneficiary = 'DISPOSITION_BENEFICIARY', // The tile representing a beneficiary of a disposition
  TermEndBeneficiary = 'TERM_END_BENEFICIARY', // The tile representing the beneficiary after the term end
  CharitableTermEndBeneficiary = 'CHARITABLE_TERM_END_BENEFICIARY', // The tile representing a charitable beneficiary after the term end
}

export interface EntityDiagramTile {
  id: string;
  afterDeath: AfterDeath;
  entityDiagramID: string | null;
  inEstateStatus: EntityInEstateStatus;
  nodeConfiguration: GraphViz_NodeConfigFragment | null;
  order?: number;
  xOffset?: number;
  yOffset?: number;
  stackY?: boolean;
  omitFromCentering?: boolean;
  entityDiagramTileKind: EntityDiagramTileKind | EntityDiagramTileKind[];
  centerY?: boolean;
  node:
    | EntityDiagram_EntityNode
    | EntityDiagram_TestamentaryEntityNode
    | EntityDiagram_ClientProfileNode
    | EntityDiagram_ClientOrganizationNode;
}

// replaces EstateWaterfall_NodeFragment
export type EntityDiagram_NodeFragment =
  | EntityDiagramTile
  | {
      id: string;
      afterDeath: AfterDeath;
      node: null;
    };

// replaces EstateWaterfallEdgeKind
export enum EntityDiagramEdgeKind {
  Disposition = 'DISPOSITION',
  Ownership = 'OWNERSHIP',
  TermEnd = 'TERM_END',
  LeftToRightTransfer = 'LEFT_TO_RIGHT_TRANSFER',
  RightToLeftTransfer = 'RIGHT_TO_LEFT_TRANSFER',
  BidirectionalTransfer = 'BidirectionalTransfer',
}

// replaces EstateWaterfall_EdgeFragment
export interface EntityDiagram_EdgeFragment {
  from: EntityDiagram_NodeFragment;
  to: EntityDiagram_NodeFragment;
  kind: EntityDiagramEdgeKind;
}

export interface EntityDiagramProps {
  householdId: string;
  entityId: string;
  height: number;
  width: number;
  registerEntityDiagramReady?: (entityId: string) => void;
  setSelectedPrimaryClientId: (id: string) => void;
}
