import { css } from '@emotion/css';
import { Box, BoxProps, useTheme } from '@mui/material';
import { ComponentType } from 'react';

import { useListColumnStyles } from '@/components/lists/lists.utils';

import { ClientPresentationStandaloneSlideType } from '../clientPresentation/clientPresentation.types';
import { ClientPresentationDesigner_HouseholdFragment } from '../clientPresentation/ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';
import { useUnguardedClientPresentationDesignerContext } from '../clientPresentation/contexts/clientPresentationDesigner.context';
import { useRegisterSlide } from '../clientPresentation/hooks/useRegisterSlide';
import { PresentationSlideSecondaryHeading } from '../components/PresentationSlideSecondaryHeading';
import { PresentationSlide } from '../PresentationSlide';
import { ProfessionalTeamMember } from './ProfessionalTeamMember';
import { ProfessionalTeamSlideMember } from './ProfessionalTeamSlide.utils';

export interface ProfessionalTeamSummarySlideProps {
  professionalTeamMembers: ProfessionalTeamSlideMember[];
  client: ClientPresentationDesigner_HouseholdFragment;
  SlideWrapper?: ComponentType<BoxProps>;
}

export function useProfessionalTeamSlideColumns() {
  const theme = useTheme();
  const listColumnsStyles = useListColumnStyles({
    columnFill: 'auto',
    columnGap: '96px',
    height: '100%',
    padding: `${theme.spacing(1)} ${theme.spacing(6)} ${theme.spacing(2)}`,
    maxHeight: '865px', // height of a slide's body, necessary for the column content to wrap correctly
  });
  return listColumnsStyles;
}
export function ProfessionalTeamSlide({
  client,
  professionalTeamMembers,
  SlideWrapper = Box,
}: ProfessionalTeamSummarySlideProps) {
  const slideTitle = 'Professional team';
  const { shouldShowItem } = useUnguardedClientPresentationDesignerContext();
  const slideId = 'slide_professional_team';

  useRegisterSlide({
    displayName: slideTitle,
    bundleId: '',
    id: slideId,
    index: 0,
    identifier: ClientPresentationStandaloneSlideType.PROFESSIONAL_TEAM,
  });

  const listColumnsStyles = useProfessionalTeamSlideColumns();

  if (
    !shouldShowItem(ClientPresentationStandaloneSlideType.PROFESSIONAL_TEAM)
  ) {
    return null;
  }

  return (
    <SlideWrapper>
      <PresentationSlide.Slide
        id={slideId}
        leftHeaderContent={
          <PresentationSlide.MainHeading heading={slideTitle} />
        }
        rightHeaderContent={
          <PresentationSlideSecondaryHeading
            clientDisplayName={client.displayName}
          />
        }
        footer={<PresentationSlide.Footer />}
      >
        <Box className={css(listColumnsStyles)}>
          {professionalTeamMembers.map((teamMember, index) => (
            <ProfessionalTeamMember
              key={`${teamMember.name}-${index}`}
              teamMember={teamMember}
            />
          ))}
        </Box>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}
