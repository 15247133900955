import { Box, Card, Stack, Typography, useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import { useEffect } from 'react';

import { DataTable_LEGACY } from '@/components/tables/DataTable_LEGACY/DataTable_LEGACY';
import { Column } from '@/components/tables/DataTable_LEGACY/tableTypes';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { getAssetCategoryDisplay } from '@/modules/assets/assetCategoryUtils';
import {
  ASSET_CARD_WIDTH_PX,
  AssetDetailCard,
} from '@/modules/assets/AssetDetailCard/AssetDetailCard';
import { useCurrentAssetValuation } from '@/modules/assetValuation/useAssetValuation';
import { RollingGRATScenarioExplanation } from '@/modules/content/disclaimers/RollingGRATScenarioExplanation';
import { GratBeneficiaryTree } from '@/modules/entities/gratTrusts/GratBeneficiaryTree/GratBeneficiaryTree';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';
import { getRowsFromProjectionForPresentation } from '@/pages/designer/GRATDesigner/StructuringDesigner/ScenarioOverview/ScenarioOverview';
import { useGetScenarioProjectionLazyQuery } from '@/pages/designer/GRATDesigner/StructuringDesigner/ScenarioOverview/ScenarioOverview.generated';

import { useQuery } from '../hooks/usePresentation';
import { Slide } from '../hooks/usePresentation';
import { SlideContainer } from './SlideContainer';

interface TableProps {
  initialFundingValue: Decimal;
  termDuration: number;
  officialInterestRate: Decimal;
  annualAnnuityIncrease: Decimal;
  estimatedReturn: Decimal;
  taxableGiftAmount: Decimal;
}

function ProjectionTable({
  initialFundingValue,
  termDuration,
  officialInterestRate,
  annualAnnuityIncrease,
  estimatedReturn,
  taxableGiftAmount,
}: TableProps) {
  const [queryProjection, { data }] = useGetScenarioProjectionLazyQuery({
    variables: {
      params: {
        initialFundingValue: initialFundingValue,
        termDurationYears: termDuration,
        officialInterestRate: officialInterestRate,
        annualAnnuityIncrease: annualAnnuityIncrease,
        projectedRateOfReturn: estimatedReturn,
        targetGift: taxableGiftAmount,
      },
    },
  });

  useEffect(
    function fetchData() {
      void queryProjection();
    },
    [queryProjection]
  );

  const tableRows = getRowsFromProjectionForPresentation(
    data?.gratScenarioProjection.scenarioTableRows ?? null,
    termDuration || 0
  );

  return (
    <DataTable_LEGACY
      columns={SCENARIO_ILLUSTRATIONS_COLUMNS}
      rows={tableRows}
      variant="long"
      isSumTable={true}
      hideFooter_DANGEROUS_ONLY_GOES_TO_100_ITEMS
    />
  );
}

interface Props {
  proposal: ProposalFragment;
  currentSlide: Slide | null;
}

export const SCENARIO_ILLUSTRATIONS_COLUMNS: Column[] = [
  {
    field: 'timeFrame',
    headerName: '',
    width: 120,
    sortable: false,
    cellFormat: 'BoldText',
  },
  {
    field: 'annuityPayments',
    headerName: 'Annuity payment',
    width: 200,
    flex: 1,
    sortable: false,
    align: 'right',
    headerClassName: 'column-header-primary-color',
  },
  {
    field: 'percentOfInitialFunding',
    headerName: '% of initial funding value',
    width: 200,
    flex: 1,
    sortable: false,
    align: 'right',
  },
  {
    field: 'remainingAmount',
    headerName: 'Remaining payments',
    width: 200,
    flex: 1,
    sortable: false,
    align: 'right',
  },
];

export function StandaloneBeneficiaryTree({ proposal, currentSlide }: Props) {
  const theme = useTheme();
  const query = useQuery();
  const viewOnly = useViewOnly();

  const entityId = viewOnly ? currentSlide?.params.entity : query.get('entity');

  if (!entityId) {
    return null;
  }

  const entityProposal = proposal?.entityProposals?.find((entityProposal) => {
    if (entityProposal.entity?.id === entityId) {
      return true;
    }
    return false;
  });

  if (!entityProposal) {
    return null;
  }

  const {
    entity: { gratTrust },
  } = entityProposal;

  return (
    <SlideContainer
      sx={{
        paddingX: 11,
        paddingTop: 6,
      }}
      autoZoomOnPrint={true}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack>
          <Typography variant="h1" color={theme.palette.primary.main}>
            Beneficiary details: {gratTrust?.displayName ?? ''}
          </Typography>
        </Stack>
      </Stack>
      {gratTrust && (
        <GratBeneficiaryTree
          stage={entityProposal.entity.stage}
          gratTrust={gratTrust}
        />
      )}
    </SlideContainer>
  );
}

export function StrategyOverview({ proposal, currentSlide }: Props) {
  const { assetClassesById } = useTenantDetailsContext();
  const theme = useTheme();
  const query = useQuery();
  const viewOnly = useViewOnly();

  const entityId = viewOnly ? currentSlide?.params.entity : query.get('entity');

  const currentAssetValuation = useCurrentAssetValuation(entityId ?? '');
  const assets = currentAssetValuation?.assetValues.map((assetValue) => ({
    id: assetValue.asset.id,
    displayName: assetValue.asset.displayName,
    category: getAssetCategoryDisplay(assetValue.asset, assetClassesById),
    value: assetValue.value,
  }));

  if (!entityId) {
    return null;
  }

  const entityProposal = proposal?.entityProposals?.find((entityProposal) => {
    if (entityProposal.entity?.id === entityId) {
      return true;
    }
    return false;
  });

  if (!entityProposal) {
    return null;
  }

  const {
    entity: { gratTrust },
  } = entityProposal;

  const isRolling = gratTrust?.rollingPeriodYears !== null;

  return (
    <SlideContainer
      sx={{
        paddingX: 11,
        paddingTop: 6,
      }}
      autoZoomOnPrint={true}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack>
          <Typography variant="h1" color={theme.palette.primary.main}>
            {viewOnly
              ? `Trust details: ${gratTrust?.displayName ?? ''}`
              : (gratTrust?.displayName ?? '')}
          </Typography>
        </Stack>
      </Stack>
      {gratTrust && !viewOnly && (
        <GratBeneficiaryTree
          stage={entityProposal.entity.stage}
          gratTrust={gratTrust}
        />
      )}
      <Stack p={4} width={'100%'} flexDirection="row" justifyContent="center">
        <Card
          sx={{
            width: '100%',
            padding: 3,
          }}
        >
          <Stack flexDirection="column" spacing={2}>
            <Typography variant="h1">Asset details</Typography>
            <Box
              display="grid"
              gridAutoRows="1fr"
              gap={3}
              padding={2}
              gridTemplateColumns={`repeat(auto-fill, minmax(${ASSET_CARD_WIDTH_PX}px, 1fr))`}
              width="100%"
              justifyItems="center"
            >
              {assets?.map((asset) => {
                return (
                  <AssetDetailCard
                    key={asset.displayName}
                    displayName={asset.displayName}
                    category={asset.category}
                    value={asset.value}
                  />
                );
              })}
            </Box>
          </Stack>
        </Card>
      </Stack>
      <Stack
        p={4}
        width={'100%'}
        flexDirection="column"
        spacing={8}
        justifyContent="center"
      >
        <Card
          sx={{
            width: '100%',
            padding: 3,
          }}
        >
          <Stack flexDirection="column" spacing={2}>
            <Typography variant="h1">Annuity payments</Typography>
            <ProjectionTable
              initialFundingValue={
                gratTrust?.initialFundingValue ?? new Decimal(0)
              }
              termDuration={gratTrust?.termDurationYears ?? 0}
              officialInterestRate={
                gratTrust?.officialInterestRatePercent ?? new Decimal(0)
              }
              annualAnnuityIncrease={
                gratTrust?.annuityAnnualIncreasePercent ?? new Decimal(0)
              }
              // pass the official interest rate percent here
              // so the projection table shows the correct
              // annuity payments
              estimatedReturn={
                gratTrust?.officialInterestRatePercent ?? new Decimal(0)
              }
              taxableGiftAmount={gratTrust?.taxableGift ?? new Decimal(0)}
            />
          </Stack>
        </Card>
        {isRolling && <RollingGRATScenarioExplanation />}
      </Stack>
    </SlideContainer>
  );
}
