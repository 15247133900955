import { GridRowParams } from '@mui/x-data-grid-pro';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { LargeTableEmptyState } from '@/components/tables/components/LargeTableEmptyState/LargeTableEmptyState';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { useModalState } from '@/hooks/useModalState';
import { useReportError } from '@/hooks/useReportError';
import { GrowthProfileModal } from '@/modules/growthProfiles/GrowthProfileModal/GrowthProfileModal';
import { getNodes } from '@/utils/graphqlUtils';

import { DeleteUsedGrowthProfileModal } from './DeleteUsedGrowthProfileModal/DeleteUsedGrowthProfileModal';
import {
  GrowthProfilesTable_GrowthProfileFragment,
  Operations as GrowthProfilesTableOperations,
  useGrowthProfilesTableQuery,
} from './graphql/GrowthProfilesTable.generated';
import { RowData } from './GrowthProfilesTable.constants';
import { GrowthProfilesTableProvider } from './GrowthProfilesTable.context';
import { getGrowthProfilesTableColumns } from './GrowthProfilesTable.utils';

export interface GrowthProfileTableProps {
  householdId: string;
}

export interface GrowthProfilesModalData {
  growthProfileId?: string | null | undefined;
  isDuplicating?: boolean | null | undefined;
}

export function GrowthProfilesTable({ householdId }: GrowthProfileTableProps) {
  const [
    { isModalOpen: isGrowthProfileModalOpen, data: growthProfileModalData },
    { openModal: openGrowthProfileModal, closeModal: closeGrowthProfileModal },
  ] = useModalState<GrowthProfilesModalData>();
  const [
    {
      isModalOpen: isDeleteUsedGrowthProfileModalOpen,
      data: deleteUsedGrowthProfileModalData,
    },
    {
      openModal: openDeleteUsedGrowthProfileModal,
      closeModal: closeDeleteUsedGrowthProfileModal,
    },
  ] = useModalState<GrowthProfilesTable_GrowthProfileFragment>();

  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const { data, loading } = useGrowthProfilesTableQuery({
    variables: {
      where: {
        hasHouseholdWith: [
          {
            id: householdId,
          },
        ],
      },
    },
    onError: (error) => {
      reportError('failed to load growth profiles', error);
      showFeedback('Failed to load growth profiles.  Please try again later');
    },
  });

  const rows = getNodes(data?.growthProfiles);

  const columns = useMemo(
    () =>
      getGrowthProfilesTableColumns({
        openGrowthProfileModal,
        openDeleteUsedGrowthProfileModal,
      }),
    [openDeleteUsedGrowthProfileModal, openGrowthProfileModal]
  );

  // this _should_ never happen, but it's better to handle a blank case than to leave the user in a broken state
  if (!loading && isEmpty(rows)) {
    return (
      <GrowthProfilesTableProvider growthProfiles={[]}>
        <LargeTableEmptyState
          title="Add growth profiles"
          description="Growth profiles determine the future projections on the estate waterfall"
          ModalComponent={({ isOpen, onClose }) =>
            isOpen ? (
              <GrowthProfileModal
                onClose={onClose}
                householdId={householdId}
                refetchQueries={[
                  GrowthProfilesTableOperations.Query.GrowthProfilesTable,
                ]}
              />
            ) : null
          }
          buttonText="Add growth profile"
        />
      </GrowthProfilesTableProvider>
    );
  }

  return (
    <GrowthProfilesTableProvider growthProfiles={rows}>
      {isGrowthProfileModalOpen && (
        <GrowthProfileModal
          onClose={closeGrowthProfileModal}
          householdId={householdId}
          {...growthProfileModalData}
          refetchQueries={[
            GrowthProfilesTableOperations.Query.GrowthProfilesTable,
          ]}
        />
      )}
      {isDeleteUsedGrowthProfileModalOpen &&
        deleteUsedGrowthProfileModalData && (
          <DeleteUsedGrowthProfileModal
            growthProfile={deleteUsedGrowthProfileModalData}
            onClose={closeDeleteUsedGrowthProfileModal}
          />
        )}
      <DataTable
        rows={rows}
        columns={columns}
        hideFooterPagination
        pagination={false}
        onRowClick={({ row }: GridRowParams<RowData>) => {
          openGrowthProfileModal({
            growthProfileId: row.id,
          });
        }}
      />
    </GrowthProfilesTableProvider>
  );
}
