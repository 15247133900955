import { Stack } from '@mui/material';
import { first } from 'lodash';
import { useMemo } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import {
  SelectInputOption,
  TypeaheadSelectInputOption,
} from '@/components/form/baseInputs/inputTypes';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTypeaheadSelectInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadSelectInput';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { PresentationBundleKind, PresentationPageKind } from '@/types/schema';
import { PathsOf } from '@/types/subform';
import { diagnostics } from '@/utils/diagnostics';

import { useClientPresentationDesignerV2Context } from '../../ClientPresentationDesignerV2.context';
import {
  getNewBundleId,
  getNewPageId,
} from '../../ClientPresentationDesignerV2.utils';
import { ClientPresentationV2ModalUpdateType } from '../../ClientPresentationV2ModalContext';
import {
  ClientPresentationV2Bundle,
  ClientPresentationV2EntitySummaryBundle,
} from '../../types/ClientPresentationV2.PresentationBundleType';
import { updatePageArrayForPageInclusion } from './settingsModals.utils';

interface EntitySummaryBundleSettingsModalFormShape {
  bundle: ClientPresentationV2Bundle;
  shouldCreateMore: boolean;
}

function getDefaultValues(
  bundle: ClientPresentationV2Bundle | null | undefined,
  updateType: ClientPresentationV2ModalUpdateType,
  defaultDeathOrderId?: string | null | undefined
): EntitySummaryBundleSettingsModalFormShape {
  if (!bundle) {
    return {
      bundle: {
        id: getNewBundleId(PresentationBundleKind.EntitySummaryBundle),
        type: PresentationBundleKind.EntitySummaryBundle,
        entitySummaryConfiguration: {
          entityId: '',
          includeEntitySummaryPage: true,
          includeBeneficiariesAndDispositionsPage: false,
          /** Unused in M1 */
          deathOrderId: defaultDeathOrderId ?? '',
          includeEntityNotesOnSummaryPage: false,
          entityNotes: '',
          documentIds: [],
        },
        displayName: '',
        pages: [
          {
            id: getNewPageId(PresentationPageKind.EntitySummaryPage),
            type: PresentationPageKind.EntitySummaryPage,
          },
          {
            id: getNewPageId(
              PresentationPageKind.EntityBeneficiariesAndDispositionsPage
            ),
            type: PresentationPageKind.EntityBeneficiariesAndDispositionsPage,
          },
        ],
      },
      shouldCreateMore: false,
    };
  }

  return {
    bundle: {
      ...bundle,
      id:
        updateType === 'update'
          ? bundle.id
          : getNewBundleId(PresentationBundleKind.EntitySummaryBundle),
      pages: bundle.pages.map((page) => ({
        ...page,
        id: updateType === 'update' ? page.id : getNewPageId(page.type),
      })),
    },
    shouldCreateMore: false,
  };
}

interface EntitySummaryBundleSettingsModalInnerProps {
  deathOrderOptions: SelectInputOption<string>[];
}

function EntitySummaryBundleSettingsModalInner({
  deathOrderOptions,
}: EntitySummaryBundleSettingsModalInnerProps) {
  const { entities } = useClientPresentationDesignerV2Context();
  const { isTwoClientHousehold } = useHouseholdDetailsContext();
  const { control, reset } =
    useFormContext<EntitySummaryBundleSettingsModalFormShape>();
  const [shouldCreateMore] = useWatch({
    control,
    name: ['shouldCreateMore'],
  });

  useSubmitSuccessHandler(() => {
    if (shouldCreateMore) {
      reset({
        ...getDefaultValues(null, 'create'),
        shouldCreateMore: true,
      });
    }
  });

  const entityOptions = entities
    .map<TypeaheadSelectInputOption<string>>((entity) => ({
      value: entity.id,
      display: entity.subtype?.displayName ?? '',
    }))
    .sort((a, b) => a.display.localeCompare(b.display));

  return (
    <Stack spacing={3}>
      <FormAwareTypeaheadSelectInput<EntitySummaryBundleSettingsModalFormShape>
        fieldName={
          'bundle.entitySummaryConfiguration.entityId' as const satisfies PathsOf<EntitySummaryBundleSettingsModalFormShape>
        }
        control={control}
        options={entityOptions}
        label="Entity"
        required
      />
      {isTwoClientHousehold && (
        <>
          <FormAwareSelectInput<EntitySummaryBundleSettingsModalFormShape>
            fieldName={
              'bundle.entitySummaryConfiguration.deathOrderId' as const satisfies PathsOf<EntitySummaryBundleSettingsModalFormShape>
            }
            control={control}
            options={deathOrderOptions}
            label="Death order"
            required
          />
        </>
      )}
    </Stack>
  );
}

export interface EntitySummaryBundleSettingsModalProps {
  bundle?: ClientPresentationV2Bundle | null | undefined;
  updateType: ClientPresentationV2ModalUpdateType;
  onClose: () => void;
  onSave: (
    updatedBundle: ClientPresentationV2Bundle,
    shouldCreateMore: boolean
  ) => void;
}

export function EntitySummaryBundleSettingsModal({
  bundle,
  updateType,
  onClose,
  onSave,
}: EntitySummaryBundleSettingsModalProps) {
  const { possibleGrantors } = useHouseholdDetailsContext();
  const deathOrderOptions =
    possibleGrantors?.map<SelectInputOption<string>>((grantor) => ({
      value: grantor.id,
      display: `${grantor.firstName} dies first`,
    })) ?? [];
  const formMethods = useForm<EntitySummaryBundleSettingsModalFormShape>({
    defaultValues: getDefaultValues(
      bundle,
      updateType,
      first(deathOrderOptions)?.value
    ),
  });

  const [modalHeader, primaryButtonLabel] = useMemo(() => {
    switch (updateType) {
      case 'duplicate':
        return ['Duplicate entity', 'Add entity'];
      case 'update':
        return ['Manage entity', 'Save changes'];
      case 'create':
        return ['Add entity summary', 'Add entity summary'];
    }
  }, [updateType]);

  const { control, handleSubmit } = formMethods;

  const { entities } = useClientPresentationDesignerV2Context();
  const onSubmit = handleSubmit(
    ({ bundle: updatedBundle, shouldCreateMore }) => {
      if (!updatedBundle.entitySummaryConfiguration) {
        diagnostics.error(
          `No entity summary configuration found for ${updatedBundle.id}`
        );
        return;
      }

      const outputBundle: ClientPresentationV2EntitySummaryBundle = {
        ...updatedBundle,
        type: PresentationBundleKind.EntitySummaryBundle,
        displayName:
          entities.find(
            (entity) =>
              entity.id === updatedBundle.entitySummaryConfiguration?.entityId
          )?.subtype?.displayName ?? '',
        entitySummaryConfiguration: {
          ...updatedBundle.entitySummaryConfiguration,
        },
      };

      let newPages = updatedBundle.pages.slice();
      newPages = updatePageArrayForPageInclusion(
        newPages,
        PresentationPageKind.EntitySummaryPage,
        updatedBundle.entitySummaryConfiguration.includeEntitySummaryPage
      );
      newPages = updatePageArrayForPageInclusion(
        newPages,
        PresentationPageKind.EntityBeneficiariesAndDispositionsPage,
        updatedBundle.entitySummaryConfiguration
          .includeBeneficiariesAndDispositionsPage
      );

      outputBundle.pages = newPages;

      onSave(outputBundle, shouldCreateMore);
    }
  );

  return (
    <FormProvider {...formMethods}>
      <FormModal
        isOpen
        heading={modalHeader}
        onClose={onClose}
        actions={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            {updateType === 'create' ? (
              <FormAwareSwitch<EntitySummaryBundleSettingsModalFormShape>
                fieldName={
                  'shouldCreateMore' as const satisfies PathsOf<EntitySummaryBundleSettingsModalFormShape>
                }
                label="Add additional entities"
                control={control}
                labelPosition="right"
              />
            ) : (
              <>&nbsp;</> // blank for spacing
            )}
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button variant="secondary" size="sm" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primary" size="sm" onClick={onSubmit}>
                {primaryButtonLabel}
              </Button>
            </Stack>
          </Stack>
        }
      >
        <EntitySummaryBundleSettingsModalInner
          deathOrderOptions={deathOrderOptions}
        />
      </FormModal>
    </FormProvider>
  );
}
