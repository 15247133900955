import { useRequiredParam } from '@/hooks/useRequiredParam';
import { useGetClientPresentationDetails } from '@/modules/presentation/clientPresentation/ClientPresentationDesigner/hooks/useGetClientPresentationDetails';

export function usePrintOnlyClientPresentationDetails() {
  const presentationId = useRequiredParam('presentationId');
  const householdId = useRequiredParam('householdId');
  return useGetClientPresentationDetails({
    variables: { presentationId, householdId },
  });
}
