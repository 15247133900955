import { Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';

import { ColorBox } from '@/components/display/ColorBox/ColorBox';
import { COLORS } from '@/styles/tokens/colors';

interface EntityDiagramLegendProps {
  presentationMode?: boolean;
}

export function EntityDiagramLegend({
  presentationMode,
}: EntityDiagramLegendProps) {
  const theme = useTheme();

  const legendItems = useMemo(() => {
    return [
      {
        label: 'In estate',
        color: theme.palette.dataVisualizationPrimary.main,
      },
      {
        label: 'Out of estate (family)',
        color: theme.palette.dataVisualizationSecondary.main,
      },
      {
        label: 'Out of estate (charity)',
        color: theme.palette.dataVisualizationTertiary.main,
      },
    ];
  }, [
    theme.palette.dataVisualizationPrimary.main,
    theme.palette.dataVisualizationSecondary.main,
    theme.palette.dataVisualizationTertiary.main,
  ]);

  return (
    <Stack
      direction="row"
      spacing={2}
      p={1.5}
      sx={{
        backgroundColor: presentationMode ? undefined : COLORS.GRAY[100],
        borderTopRightRadius: '4px',
      }}
    >
      {legendItems.map((item) => (
        <Stack
          direction="row"
          alignItems="center"
          key={item.label}
          spacing={0.5}
        >
          <ColorBox color={item.color} />
          <Typography variant="subtitle2">{item.label}</Typography>
        </Stack>
      ))}
    </Stack>
  );
}
