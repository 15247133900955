import { useApolloClient } from '@apollo/client';
import { Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { LuminaryAIBannerCard } from '@/components/layout/LuminaryAIBannerCard';
import { UploadSingleDocumentModal } from '@/modules/aiDP/components/UploadSingleDocumentModal';
import { useUpdateKgRootMutation } from '@/modules/aiDP/graphql/aiDP.generated';
import { FetchEntityAiSuggestionsDocument } from '@/modules/aiSuggestions/graphql/aiSuggestions.generated';
import { useAsyncJobNotificationsContext } from '@/modules/asyncJobs/AsyncJobNotifications/context/AsyncJobNotifications.context';
import { KgRootStatus } from '@/types/schema';

interface EmptyAIDPStatusBannerProps {
  kgRootId: string;
}

export function EmptyAIDPStatusBanner({
  kgRootId,
}: EmptyAIDPStatusBannerProps) {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const client = useApolloClient();

  const { removeNotifications } = useAsyncJobNotificationsContext();

  const [updateKGRoot] = useUpdateKgRootMutation({
    onCompleted: async (data) => {
      removeNotifications([data.updateKgRoot.id]);

      await client.refetchQueries({
        include: [FetchEntityAiSuggestionsDocument],
        updateCache(cache) {
          cache.evict({ id: cache.identify(data.updateKgRoot) });
          cache.gc();
        },
      });
    },
  });

  const handleDismissEmptyAIDPStatusBanner = useCallback(async () => {
    await updateKGRoot({
      variables: {
        updateKGRootInput: {
          id: kgRootId,
          update: {
            status: KgRootStatus.SuggestionReviewComplete,
          },
        },
      },
    });
  }, [kgRootId, updateKGRoot]);

  return (
    <>
      <UploadSingleDocumentModal
        isOpen={isUploadModalOpen}
        handleClose={() => setIsUploadModalOpen(false)}
      />
      <LuminaryAIBannerCard
        bannerText={
          <Stack direction="column" gap={0.25} flexGrow={1}>
            <Typography variant="label">
              No recipients could be identified
            </Typography>
            <Typography variant="subtitle2">
              Manually input dispositions in the form below or upload additional
              documents with disposition details.
            </Typography>
          </Stack>
        }
        // TODO: Put this back after beta: https://linear.app/luminary/issue/T2-3985/[frontend]-single-document-upload-modal
        // primaryButtonProps={{
        //   onClick: () => setIsUploadModalOpen(true),
        //   startIcon: Upload01Icon,
        //   size: 'sm',
        //   variant: 'secondary',
        //   children: 'Upload',
        //   sx: { width: '100%' },
        // }}
        onDismiss={handleDismissEmptyAIDPStatusBanner}
      />
    </>
  );
}
