import { styled } from '@mui/material';
import React from 'react';

import { COLORS } from '@/styles/tokens/colors';
import { zIndexes } from '@/styles/zIndexes';

import { SIDEBAR_ANIMATION_TIMING_MS } from './sidebarConstants';

const EXPANDED_WIDTH = 230;
const COLLAPSED_WIDTH = 88;

export const MENU_ITEMS_ID = 'sidebar-menu-items';

interface StyledSidebarProps extends React.HTMLProps<HTMLDivElement> {
  width: number;
}

export const StyledSidebar = styled('nav')<StyledSidebarProps>(
  ({ theme: _, width }) => ({
    height: '100vh',
    backgroundColor: COLORS.NAVY[800],
    transition: `width ${SIDEBAR_ANIMATION_TIMING_MS}ms linear`,
    width: width,
    zIndex: zIndexes.MENU + 1, // above the AppBar
    position: 'relative',
    '@media print': {
      display: 'none',
    },
  })
);

interface SidebarWithContainerProps extends React.HTMLProps<HTMLDivElement> {
  expansionState: SidebarExpansionState;
  width?: number;
  collapsedWidth?: number;
}

type SidebarExpansionState =
  | 'collapsed'
  | 'temporarilyExpanded'
  | 'permanentlyExpanded';

export function SidebarWithContainer({
  expansionState,
  children,
  width: externalExpandedWidth,
  collapsedWidth,
  // there's a type incompatibility with this type that's not even used, so stripping it off here
  // to allow us to use the ...rest operator for other native properties.
  as: _as,
  ...nativeProps
}: React.PropsWithChildren<SidebarWithContainerProps>) {
  const displayedWidth =
    expansionState !== 'collapsed'
      ? (externalExpandedWidth ?? EXPANDED_WIDTH)
      : (collapsedWidth ?? COLLAPSED_WIDTH);
  const containerWidth =
    expansionState !== 'permanentlyExpanded'
      ? (collapsedWidth ?? COLLAPSED_WIDTH)
      : (externalExpandedWidth ?? EXPANDED_WIDTH);

  return (
    <div style={{ maxWidth: containerWidth }}>
      <StyledSidebar {...nativeProps} width={displayedWidth}>
        {children}
      </StyledSidebar>
    </div>
  );
}
