import {
  FitViewOptions,
  getViewportForBounds,
  Node,
  Rect,
  useReactFlow,
} from '@xyflow/react';
import { useCallback } from 'react';
import { UseMeasureRect } from 'react-use/lib/useMeasure';

import { useViewportTransform } from '@/components/diagrams/FlowChart/hooks/useViewportTransform';
import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';

import { EstateWaterfallEffectFn, FitViewEffect } from '../types';

// This can be removed when new_waterfall_fit_view is turned on for real
function useFitViewOptions() {
  const { getNodes } = useReactFlow();

  const getFitViewOptions = useCallback(
    (includeSectionLabels = false): FitViewOptions => {
      return {
        nodes: getNodes().filter((n) => {
          if (includeSectionLabels) return true;
          return n.type !== 'sectionLabel';
        }),
      };
    },
    [getNodes]
  );

  return { getFitViewOptions };
}

export function useFitViewEffect({
  containerDimensions,
}: {
  containerDimensions: UseMeasureRect;
}): EstateWaterfallEffectFn<FitViewEffect> {
  const { setViewport, getNodes, fitView, getViewport } = useReactFlow();
  const { getTransform } = useViewportTransform();
  const { getFitViewOptions } = useFitViewOptions();

  const legacyCallback = useCallback<EstateWaterfallEffectFn<FitViewEffect>>(
    (_state, { opts, perserveZoomLevel, kind }, _dispatch) => {
      const currentZoom = getTransform()[2];
      const preservedZoomLevelOpts: FitViewOptions = {
        minZoom: currentZoom,
        maxZoom: currentZoom,
      };

      const finalOpts = {
        ...(opts ?? getFitViewOptions(kind === 'init')),
        ...(perserveZoomLevel && preservedZoomLevelOpts),
      };

      void fitView(finalOpts);

      if (kind === 'init') {
        // If we are initializing, we want to accomodate the top section label
        const viewport = getViewport();

        void setViewport(viewport);
      }
    },
    [fitView, getFitViewOptions, getTransform, getViewport, setViewport]
  );

  const newCallback = useCallback<EstateWaterfallEffectFn<FitViewEffect>>(
    (_state, { perserveZoomLevel }, _dispatch) => {
      const nodes = getNodes();

      const currentZoom = getTransform()[2];
      const minZoom = 0;
      const maxZoom = 1.25;

      const zoomLevelOpts = {
        minZoom: perserveZoomLevel ? currentZoom : minZoom,
        maxZoom: perserveZoomLevel ? currentZoom : maxZoom,
      };

      // Get any tile node to use as the x position for the section label nodes
      // as we only care about the y position of these nodes
      const aTileNode = nodes.find((node) => node.type === 'tile');

      // Assign a height to the section label nodes so they take up
      // real space in the viewport calculations
      const sectionLabelNodeHeight = 100;

      // Modify the nodes so we can get the actual bounds of the nodes
      // excluding hidden nodes and the width component of section label nodes
      const modifiedNodes = nodes.flatMap((node) => {
        if (node.hidden) {
          return [];
        }

        if (node.type === 'sectionLabel') {
          return {
            ...node,
            position: {
              x: aTileNode?.position.x ?? 0,
              y: node.position.y - sectionLabelNodeHeight / 2,
            },
            measured: {
              // Override the width so it does not impact the viewport calculations
              width: 1,
              height: sectionLabelNodeHeight,
            },
          };
        }

        return node;
      });

      const getCustomBounds = (nodes: Node[]): Rect => {
        if (nodes.length === 0) return { x: 0, y: 0, width: 0, height: 0 };

        const minX = Math.min(...nodes.map((n) => n.position.x));
        const minY = Math.min(...nodes.map((n) => n.position.y));

        const maxX = Math.max(
          ...nodes.map((n) => n.position.x + (n.measured?.width ?? 0))
        );
        const maxY = Math.max(
          ...nodes.map((n) => n.position.y + (n.measured?.height ?? 0))
        );

        return {
          x: minX,
          y: minY,
          width: maxX - minX,
          // Add some additional height here so the spacing on the top and bottom
          // are equidistant
          height: maxY - minY + sectionLabelNodeHeight / 2,
        };
      };

      const nodesBounds = getCustomBounds(modifiedNodes);

      // This ratio is added to the bounds to create a buffer around the nodes
      const paddingRatio = 0.1;

      const viewportForBounds = getViewportForBounds(
        nodesBounds,
        containerDimensions.width,
        containerDimensions.height,
        zoomLevelOpts.minZoom,
        zoomLevelOpts.maxZoom,
        paddingRatio
      );

      void setViewport({
        x: viewportForBounds.x,
        y: viewportForBounds.y,
        zoom: viewportForBounds.zoom,
      });
    },
    [containerDimensions, getNodes, getTransform, setViewport]
  );

  if (isFeatureFlagEnabled('new_waterfall_fit_view')) {
    return newCallback;
  }

  return legacyCallback;
}
