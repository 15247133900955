import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { compact } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Path, useWatch } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { Tile } from '@/components/diagrams/components/Tile';
import { TileVariant } from '@/components/diagrams/components/Tile/types';
import { TileButtonWithPopover } from '@/components/form/baseInputs/Button/TileButtonWithPopover';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useFormContext } from '@/components/react-hook-form';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { SUPPORTED_HYPOTHETICAL_ENTITY_KINDS } from '@/modules/entities/draftEntities/draftEntities.constants';
import { entityKindToDisplayName } from '@/modules/entities/EntityForm/utils';
import { GraphNodeCategorizationType } from '@/modules/estateWaterfall/types';
import {
  categorizationTypeToVariant,
  kindToCategorizationType,
} from '@/modules/estateWaterfall/waterfallGraph/constants';
import { COLORS } from '@/styles/tokens/colors';
import {
  EntityStage,
  EstateWaterfallHypotheticalTransferDestinationKind,
} from '@/types/schema';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { UnreachableError } from '@/utils/errors';
import {
  formatCurrencyNoDecimals,
  formatCurrencyNoDecimalsAccounting,
} from '@/utils/formatting/currency';

import { useTransferDestinationOptions } from './hooks/useTransferDestinationOptions';
import {
  HypotheticalTransferFormShape,
  TransferDirection,
} from './HypotheticalTransferForm';
import { TransferReferenceObj } from './HypotheticalTransferForm.types';
import {
  EXTERNAL_ENTITY_SENTINEL,
  getEntityPropertiesFromEntityKind,
  getExternalTransferCopy,
} from './hypotheticalTransfers.utils';

interface ShouldTransferToNewHypotheticalEntityProps {
  isEdit: boolean;
  transferReferenceObj: TransferReferenceObj;
}
function ShouldTransferToNewHypotheticalEntity({
  isEdit,
  transferReferenceObj,
}: ShouldTransferToNewHypotheticalEntityProps) {
  const {
    control,
    formState: { isLoading },
  } = useFormContext<HypotheticalTransferFormShape>();

  // We disable this field when we are in the context of a draft entity
  // because we don't want to user to create a transfer from a draft entity
  // to another draft entity
  const entityStage = useMemo(() => {
    if (transferReferenceObj === EXTERNAL_ENTITY_SENTINEL) {
      return EntityStage.Draft;
    }

    const typeName = transferReferenceObj.__typename;

    switch (typeName) {
      case 'Entity':
        return transferReferenceObj.stage;
      case 'ClientProfile':
        return EntityStage.Active;
      default:
        return EntityStage.Draft;
    }
  }, [transferReferenceObj]);
  const isHypotheticalEntity = entityStage === EntityStage.Draft;

  return (
    <FormAwareSwitch<HypotheticalTransferFormShape>
      control={control}
      labelPosition="right"
      fieldName={
        'shouldTransferToNewHypotheticalEntity' as const satisfies Path<HypotheticalTransferFormShape>
      }
      label="Transfer to a new draft entity"
      disabled={isLoading || isEdit || isHypotheticalEntity}
    />
  );
}

interface TransferValueProps {
  transferReferenceObj: TransferReferenceObj;
  householdId: string;
}
function TransferValue({
  transferReferenceObj,
  householdId,
}: TransferValueProps) {
  const { control, watch } = useFormContext<HypotheticalTransferFormShape>();

  const [
    selectedDestinationEntity,
    selectedDestinationIndividual,
    selectedDestinationOrganization,
    transferDirection,
    inputValue,
    destinationKind,
  ] = watch([
    'destinationEntityID',
    'destinationIndividualID',
    'destinationOrganizationID',
    'transferDirection',
    'transferValue',
    'destinationKind',
  ]);

  const { possibleDestinations } = useTransferDestinationOptions(householdId);

  const selectedItem = possibleDestinations.find(
    ({ id }) =>
      id === selectedDestinationEntity ||
      id === selectedDestinationIndividual ||
      id === selectedDestinationOrganization
  );

  const entityValue = useMemo(() => {
    if (transferReferenceObj === EXTERNAL_ENTITY_SENTINEL) {
      return new Decimal(0);
    }

    const typeName = transferReferenceObj.__typename;

    const isInbound = transferDirection === TransferDirection.Inbound;
    const selectedItemCurrentValue =
      selectedItem?.currentValue ?? new Decimal(0);

    switch (typeName) {
      case 'Entity':
        return (
          (isInbound
            ? selectedItemCurrentValue
            : transferReferenceObj.subtype.currentValue) ?? new Decimal(0)
        );
      case 'ClientProfile':
        return isInbound
          ? selectedItemCurrentValue
          : sumDecimalJS(
              transferReferenceObj.ownedOwnershipStakes?.map(
                (s) => s.ownedValue
              ) ?? []
            );
      default:
        return new Decimal(0);
    }
  }, [transferReferenceObj, selectedItem, transferDirection]);

  const isWarning = useMemo(() => {
    if (!inputValue) {
      return false;
    }

    return inputValue?.greaterThan(entityValue) ?? false;
  }, [entityValue, inputValue]);

  const helpText = useMemo(() => {
    if (
      destinationKind ===
      EstateWaterfallHypotheticalTransferDestinationKind.External
    ) {
      return '';
    }

    if (isWarning) {
      return `Exceeds current asset value exclusive of transfers: ${formatCurrencyNoDecimals(
        entityValue
      )}`;
    }

    return `Current asset value exclusive of transfers: ${formatCurrencyNoDecimals(
      entityValue
    )}`;
  }, [destinationKind, entityValue, isWarning]);

  return (
    <FormAwareCurrencyInput<HypotheticalTransferFormShape>
      control={control}
      fieldName={
        'transferValue' as const satisfies Path<HypotheticalTransferFormShape>
      }
      label="Amount to transfer"
      required
      isDecimalJSInput
      decimalScale={0}
      helpText={helpText}
      helpTextVariant={isWarning ? 'warning' : 'default'}
    />
  );
}

const useTileStyles = makeStyles<{
  kind: GraphNodeCategorizationType | undefined;
}>()((_theme, { kind }) => {
  const variant = kind && categorizationTypeToVariant[kind];

  const backgroundColor = (() => {
    switch (variant) {
      case TileVariant.Default:
        return COLORS.PRIMITIVES.WHITE;
      case TileVariant.Primary:
        return COLORS.GRAY[50];
      case TileVariant.Secondary:
        return COLORS.TEAL[50];
      case TileVariant.Tertiary:
        return COLORS.BLUE[50];
      default:
        return COLORS.PRIMITIVES.WHITE;
    }
  })();
  return {
    // eslint-disable-next-line tss-unused-classes/unused-classes
    root: {
      backgroundColor,
    },
  };
});

interface SourceEntityProps {
  transferReferenceObj: TransferReferenceObj;
}
function SourceEntity({ transferReferenceObj }: SourceEntityProps) {
  const { control } = useFormContext<HypotheticalTransferFormShape>();

  const transferDirection = useWatch({
    control,
    name: 'transferDirection',
  });

  const entityName = useMemo(() => {
    if (transferReferenceObj === EXTERNAL_ENTITY_SENTINEL) {
      return getExternalTransferCopy(transferDirection);
    }

    const typeName = transferReferenceObj.__typename;

    switch (typeName) {
      case 'Entity':
        return transferReferenceObj.subtype.displayName;
      case 'ClientProfile':
        return transferReferenceObj.displayName;
      default:
        return '';
    }
  }, [transferDirection, transferReferenceObj]);

  const entityValue = useMemo(() => {
    if (transferReferenceObj === EXTERNAL_ENTITY_SENTINEL) {
      return new Decimal(0);
    }

    const typeName = transferReferenceObj.__typename;

    switch (typeName) {
      case 'Entity':
        return transferReferenceObj.subtype.currentValue;
      case 'ClientProfile':
        return sumDecimalJS(
          transferReferenceObj.ownedOwnershipStakes?.map((s) => s.ownedValue) ??
            []
        );
      default:
        return new Decimal(0);
    }
  }, [transferReferenceObj]);
  let label = 'From';
  if (transferDirection === TransferDirection.Inbound) {
    label = 'To';
  }

  const kind: GraphNodeCategorizationType | undefined = useMemo(() => {
    if (transferReferenceObj === EXTERNAL_ENTITY_SENTINEL) {
      // Style external entity transfer source tile as a personal
      // account. This gives it a light background.
      // External accounts are not represented on the waterfall.
      return GraphNodeCategorizationType.PersonalAccount;
    }

    const typeName = transferReferenceObj.__typename;

    switch (typeName) {
      case 'Entity':
        return kindToCategorizationType[transferReferenceObj.kind] ?? undefined;
      case 'ClientProfile':
        return GraphNodeCategorizationType.Individual;
      default:
        return GraphNodeCategorizationType.Individual;
    }
  }, [transferReferenceObj]);

  const { classes } = useTileStyles({
    kind,
  });

  return (
    <Stack spacing={1} sx={{ minWidth: 30, minHeight: 30 }}>
      <Typography variant="label">{label}</Typography>
      <Tile
        lineOne={entityName}
        lineTwo={formatCurrencyNoDecimals(entityValue)}
        variant={TileVariant.Default}
        isGrouped={false}
        classes={classes}
      />
    </Stack>
  );
}

interface DestionationEntityProps {
  householdId: string;
}
function DestinationEntity({ householdId }: DestionationEntityProps) {
  const { setValue, control } = useFormContext<HypotheticalTransferFormShape>();

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const [
    selectedDestinationEntity,
    selectedDestinationIndividual,
    selectedDestinationOrganization,
    selectedDestinationKind,
    transferDirection,
    isShouldTransferToNewHypotheticalEntitySwitchEnabled,
    hypotheticalEntityName,
    transferValue,
  ] = useWatch({
    control,
    name: [
      'destinationEntityID',
      'destinationIndividualID',
      'destinationOrganizationID',
      'destinationKind',
      'transferDirection',
      'shouldTransferToNewHypotheticalEntity',
      'hypotheticalEntityName',
      'transferValue',
    ],
  });

  let label = 'To';
  if (transferDirection === TransferDirection.Inbound) {
    label = 'From';
  }

  const {
    typeOptions: destinationOptions,
    possibleDestinations,
    loading: isLoadingOptions,
  } = useTransferDestinationOptions(householdId);

  const selectedItem = useMemo(() => {
    return possibleDestinations.find(({ id }) => {
      if (
        id === EXTERNAL_ENTITY_SENTINEL &&
        selectedDestinationKind ===
          EstateWaterfallHypotheticalTransferDestinationKind.External
      ) {
        return true;
      }

      return (
        id === selectedDestinationEntity ||
        id === selectedDestinationIndividual ||
        id === selectedDestinationOrganization
      );
    });
  }, [
    possibleDestinations,
    selectedDestinationEntity,
    selectedDestinationIndividual,
    selectedDestinationKind,
    selectedDestinationOrganization,
  ]);

  const { classes } = useTileStyles({
    kind: selectedItem?.kind,
  });

  const tileProps = useMemo(() => {
    if (isShouldTransferToNewHypotheticalEntitySwitchEnabled) {
      return {
        lineOne: hypotheticalEntityName ?? EMPTY_CONTENT_HYPHEN,
        variant: TileVariant.Default,
        footerLabel: 'Draft entity',
        lineTwo: formatCurrencyNoDecimalsAccounting(
          transferValue ?? new Decimal(0)
        ),
        isGrouped: false,
      };
    }

    if (selectedDestinationKind) {
      return {
        lineOne: selectedItem?.displayName ?? EMPTY_CONTENT_HYPHEN,
        variant: TileVariant.Default,
        // If the selected destination is a draft entity, then we
        // want to show the draft entity frame around the tile.
        footerLabel: selectedItem?.isDraft ? 'Draft entity' : undefined,
        lineTwo: formatCurrencyNoDecimalsAccounting(
          selectedItem?.currentValue ?? new Decimal(0)
        ),
        isGrouped: false,
        classes,
      };
    }

    return {};
  }, [
    classes,
    hypotheticalEntityName,
    isShouldTransferToNewHypotheticalEntitySwitchEnabled,
    selectedDestinationKind,
    selectedItem,
    transferValue,
  ]);

  const disabled =
    isShouldTransferToNewHypotheticalEntitySwitchEnabled || isLoadingOptions;

  return (
    <Stack
      spacing={1}
      sx={{
        alignSelf: 'stretch',
        minWidth: 30,
        minHeight: 30,
      }}
    >
      <Typography variant="label">{label}</Typography>
      <TileButtonWithPopover
        disabled={disabled}
        tileProps={tileProps}
        menuIsOpen={menuIsOpen}
        setMenuIsOpen={setMenuIsOpen}
      >
        {destinationOptions.map((option, idx) => {
          if ('type' in option) {
            const Component = () => option.component;

            return <Component key={idx} />;
          } else {
            return (
              <MenuItem
                sx={{
                  background:
                    selectedDestinationEntity === option.value
                      ? COLORS.FUNCTIONAL.HOVER
                      : 'transparent',
                  borderTop:
                    idx === destinationOptions.length - 1
                      ? `1px ${COLORS.GRAY[300]} solid} `
                      : undefined,
                }}
                key={option.value}
                label={option.display}
                onClick={() => {
                  switch (option.kind) {
                    case '':
                      setValue('destinationEntityID', undefined);
                      setValue('destinationIndividualID', undefined);
                      setValue('destinationOrganizationID', undefined);
                      setValue('destinationKind', undefined);
                      break;
                    case EstateWaterfallHypotheticalTransferDestinationKind.Entity:
                      setValue('destinationEntityID', option.value);
                      setValue('destinationIndividualID', undefined);
                      setValue('destinationOrganizationID', undefined);
                      setValue('destinationKind', option.kind);
                      break;
                    case EstateWaterfallHypotheticalTransferDestinationKind.Individual:
                      setValue('destinationEntityID', undefined);
                      setValue('destinationIndividualID', option.value);
                      setValue('destinationOrganizationID', undefined);
                      setValue('destinationKind', option.kind);
                      break;
                    case EstateWaterfallHypotheticalTransferDestinationKind.Organization:
                      setValue('destinationEntityID', undefined);
                      setValue('destinationIndividualID', undefined);
                      setValue('destinationOrganizationID', option.value);
                      setValue('destinationKind', option.kind);
                      break;
                    case EstateWaterfallHypotheticalTransferDestinationKind.External:
                      setValue('destinationEntityID', undefined);
                      setValue('destinationIndividualID', undefined);
                      setValue('destinationOrganizationID', undefined);
                      setValue('destinationKind', option.kind);
                      break;
                    default:
                      throw new UnreachableError({
                        case: option.kind,
                        message: `Unknown destination kind ${option.kind}`,
                      });
                  }

                  setMenuIsOpen(false);
                }}
              />
            );
          }
        })}
      </TileButtonWithPopover>
    </Stack>
  );
}

interface GrantorProps {
  transferReferenceObj: TransferReferenceObj;
}
function Grantor({ transferReferenceObj }: GrantorProps) {
  const {
    control,
    formState: { isLoading },
    setValue,
  } = useFormContext<HypotheticalTransferFormShape>();

  const selectedEntityKind = useWatch({
    control,
    name: 'hypotheticalEntityKind',
  });

  const selectedGrantor = useWatch({
    control,
    name: 'hypotheticalEntityGrantor',
  });

  const { allowMultiple, isDonor, isOwner } =
    getEntityPropertiesFromEntityKind(selectedEntityKind);

  useEffect(() => {
    // If the form has multiple grantors set, but the selected
    // entity kind only allows one grantor, then we need to
    // clear the grantor ids
    if (selectedGrantor === 'both' && !allowMultiple) {
      setValue('hypotheticalEntityGrantor', '');
    }
  }, [allowMultiple, selectedGrantor, setValue]);

  const options = useMemo(() => {
    if (transferReferenceObj === EXTERNAL_ENTITY_SENTINEL) {
      return [];
    }

    const possiblePrimaryClients: { displayName: string; id: string }[] =
      transferReferenceObj.household?.possiblePrimaryClients ?? [];
    const grantorOptions =
      possiblePrimaryClients.map((client) => {
        return {
          display: client.displayName,
          value: client.id,
        };
      }) ?? [];

    if (possiblePrimaryClients.length === 2 && allowMultiple) {
      return [
        {
          display: compact(
            possiblePrimaryClients.map((c) => c.displayName)
          ).join(' & '),
          value: 'both',
        },
        ...grantorOptions,
      ];
    }

    return grantorOptions;
  }, [allowMultiple, transferReferenceObj]);

  const label = useMemo(() => {
    let label = 'Grantor';

    if (isDonor) {
      label = 'Donor';
    }

    if (isOwner) {
      label = 'Owner';
    }

    if (allowMultiple) {
      return `${label}(s)`;
    }

    return label;
  }, [allowMultiple, isDonor, isOwner]);

  if (isLoading) {
    return null;
  }

  return (
    <FormAwareSelectInput<HypotheticalTransferFormShape>
      control={control}
      fieldName={
        'hypotheticalEntityGrantor' as const satisfies Path<HypotheticalTransferFormShape>
      }
      label={label}
      required
      options={options}
    />
  );
}

function HypotheticalEntityName() {
  const {
    control,
    formState: { isLoading },
  } = useFormContext<HypotheticalTransferFormShape>();

  return (
    <FormAwareTextInput<HypotheticalTransferFormShape>
      control={control}
      fieldName={
        'hypotheticalEntityName' as const satisfies Path<HypotheticalTransferFormShape>
      }
      label="Name of draft entity"
      disabled={isLoading}
      required
    />
  );
}
interface HypotheticalEntityKindProps {
  isEdit: boolean;
}
function HypotheticalEntityKind({ isEdit }: HypotheticalEntityKindProps) {
  const {
    control,
    formState: { isLoading },
  } = useFormContext<HypotheticalTransferFormShape>();
  // We disable this field when we are editing a hypothetical transfer
  // because we don't want to allow the user to change the entity kind
  // of an existing draft entity
  const disabled = isLoading || isEdit;

  const options = useMemo(() => {
    return SUPPORTED_HYPOTHETICAL_ENTITY_KINDS.map((kind) => {
      return {
        display: entityKindToDisplayName(kind),
        value: kind,
      };
    });
  }, []);

  return (
    <FormAwareSelectInput<HypotheticalTransferFormShape>
      control={control}
      fieldName={
        'hypotheticalEntityKind' as const satisfies Path<HypotheticalTransferFormShape>
      }
      label="Entity type"
      required
      options={options}
      disabled={disabled}
    />
  );
}

export const HypotheticalTransferFormFields = {
  ShouldTransferToNewHypotheticalEntity,
  TransferValue,
  SourceEntity,
  DestinationEntity,
  Grantor,
  HypotheticalEntityName,
  HypotheticalEntityKind,
};
