import { MutableRefObject, useRef } from 'react';

import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import {
  AugmentedCreateLifetimeExclusionEventInput,
  AugmentedUpdateLifetimeExclusionEventInput,
  LifetimeExclusionEventKind,
} from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { useLifetimeExclusionEventsQuery } from './graphql/RecordExemptionUsedModal.generated';
import { NAMESPACE } from './RecordExemptionUsedModal.constants';
import { RecordExemptionUsedForm } from './RecordExemptionUsedModal.types';

export function getCreateLifetimeExclusionEventInput(
  form: RecordExemptionUsedForm
): AugmentedCreateLifetimeExclusionEventInput {
  if (!form[NAMESPACE].asOfDate) {
    throw new Error('Date of gift is required');
  }

  if (!form[NAMESPACE].additionalLifetimeExemptionApplied) {
    throw new Error('Lifetime exemption used is required');
  }

  if (!form[NAMESPACE].additionalGstExemptionApplied) {
    throw new Error('GST exemption applied is required');
  }

  const input: AugmentedCreateLifetimeExclusionEventInput = {
    create: {
      giftCategory: form[NAMESPACE].assetType,
      eventDate: form[NAMESPACE].asOfDate,
      documentIDs: form[NAMESPACE].documentIds,
      lifetimeExclusionAmount:
        form[NAMESPACE].additionalLifetimeExemptionApplied,
      kind: LifetimeExclusionEventKind.Adjustment,
      gstExclusionAmount: form[NAMESPACE].additionalGstExemptionApplied,
      grantorID: form[NAMESPACE].grantor.clientProfileId,
      notes: form[NAMESPACE].notes,
    },
  };

  return input;
}

export function getUpdateLifetimeExclusionEventInput({
  form,
  lifetimeExclusionEventId,
  originalDocumentIds,
}: {
  form: RecordExemptionUsedForm;
  lifetimeExclusionEventId?: string;
  originalDocumentIds: MutableRefObject<string[]>;
}): AugmentedUpdateLifetimeExclusionEventInput {
  if (!lifetimeExclusionEventId) {
    throw new Error('Lifetime exclusion event id is required');
  }

  if (!form[NAMESPACE].asOfDate) {
    throw new Error('Date of gift is required');
  }

  if (!form[NAMESPACE].additionalLifetimeExemptionApplied) {
    throw new Error('Lifetime exemption used is required');
  }

  if (!form[NAMESPACE].additionalGstExemptionApplied) {
    throw new Error('GST exemption applied is required');
  }

  const input: AugmentedUpdateLifetimeExclusionEventInput = {
    id: lifetimeExclusionEventId,
    update: {
      eventDate: form[NAMESPACE].asOfDate,
      lifetimeExclusionAmount:
        form[NAMESPACE].additionalLifetimeExemptionApplied,
      gstExclusionAmount: form[NAMESPACE].additionalGstExemptionApplied,
      grantorID: form[NAMESPACE].grantor.clientProfileId,
      notes: form[NAMESPACE].notes,
      removeDocumentIDs: originalDocumentIds.current.filter(
        (d) => !form[NAMESPACE].documentIds.includes(d)
      ),
      addDocumentIDs: form[NAMESPACE].documentIds.filter(
        (d) => !originalDocumentIds.current.includes(d)
      ),
      giftCategory: form[NAMESPACE].assetType,
    },
  };

  return input;
}

export function useSyncDataToForm({
  lifetimeExclusionEventId,
  isDuplicate,
  isOpen,
}: {
  lifetimeExclusionEventId?: string;
  isDuplicate?: boolean;
  isOpen: boolean;
}) {
  const { reset } = useFormContext<RecordExemptionUsedForm>();

  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const originalDocumentIds = useRef<string[]>([]);

  const { loading } = useLifetimeExclusionEventsQuery({
    variables: {
      where: {
        id: lifetimeExclusionEventId,
      },
    },
    skip: !lifetimeExclusionEventId || !isOpen,
    onError: (error) => {
      showFeedback(FeedbackMessages.queryError);
      reportError('failed to load lifetime exclusion event', error, {
        lifetimeExclusionEventId,
      });
    },
    onCompleted: (data) => {
      const lifetimeExclusionEvents = getNodes(data.lifetimeExclusionEvents);
      const lifetimeExclusionEvent = lifetimeExclusionEvents[0];

      if (!lifetimeExclusionEvent) {
        reportError(
          'Missing lifetime exclusion event when editing; showing an empty modal',
          null,
          {
            lifetimeExclusionEventId,
          }
        );
        showFeedback(
          `We're unexpectedly missing some data for this lifetime exclusion event. Our team has been notified; please refresh the page and try again.`,
          {
            variant: 'warning',
          }
        );
        return;
      }

      const existingValues = {
        [NAMESPACE]: {
          grantor: {
            clientProfileId: lifetimeExclusionEvent?.grantor.id,
          },
          asOfDate: lifetimeExclusionEvent.eventDate,
          additionalLifetimeExemptionApplied:
            lifetimeExclusionEvent.lifetimeExclusionAmount,
          additionalGstExemptionApplied:
            lifetimeExclusionEvent.gstExclusionAmount,
          notes: lifetimeExclusionEvent.notes ?? '',
          assetType: lifetimeExclusionEvent.giftCategory ?? null,
          documentIds: isDuplicate
            ? []
            : (lifetimeExclusionEvent.documents?.map((d) => d.id) ?? []),
        },
      };

      reset(existingValues);
      originalDocumentIds.current = existingValues[NAMESPACE].documentIds;
    },
  });

  return {
    loading,
    originalDocumentIds,
  };
}
