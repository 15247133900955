import { cx } from '@emotion/css';
import {
  Node,
  NodeProps,
  NodeResizer,
  NodeResizerProps,
  Position,
} from '@xyflow/react';

import { useModalState } from '@/hooks/useModalState';
import { COLORS } from '@/styles/tokens/colors';

import { Handle, HandleProps } from '../../../components/Handle';
import { Tile, TileProps } from '../../../components/Tile';
import { useFlowChartContext } from '../../context/flowChart.context';
import { useCursorStyles } from '../../hooks/useCursorStyles';
import { useDraggingNode } from '../../hooks/useDraggingNode';
import { useIsInteractive } from '../../hooks/useIsInteractive';
import {
  NodeRankAttrs,
  SectionLabelNodeChildAttrs,
  TileNodeAdditionalProps,
} from '../../types';
import { TileEditModal } from './TileEditModal';
import { TileNodeControls } from './TileNodeControls/TileNodeControls';
import { TileNodeFontSizeControls } from './TileNodeControls/TileNodeFontSizeControls';
import { TileSettingsControlButton } from './TileNodeControls/TileSettingsControlButton';

//  isSelected is omitted because we compute it based on internal react flow props in this component
export type TileNodeData = Record<string, unknown> &
  Omit<TileProps, 'isSelected'> &
  NodeRankAttrs &
  SectionLabelNodeChildAttrs &
  TileNodeAdditionalProps;

export type TileNodeProps = NodeProps<Node<TileNodeData>>;

const AllPositions = Object.values(Position);

const lineStyle: NodeResizerProps['lineStyle'] = {
  zIndex: 1,
  borderColor: COLORS.NAVY[900],
};

const handleStyle: NodeResizerProps['handleStyle'] = {
  zIndex: 1,
  backgroundColor: COLORS.PRIMITIVES.WHITE,
  height: 8,
  width: 8,
  outline: `1px solid ${COLORS.NAVY[900]}`,
};

const defaultEditModal: NonNullable<TileNodeData['editModal']> = (props) => {
  return <TileEditModal {...props} />;
};

export function TileNode({
  id,
  data: {
    controls,
    editModal = defaultEditModal,
    classes: tileClasses = {},
    fontSizeVariant,
    ...tileProps
  },
  selected,
  isConnectable,
  dragging,
  width,
  height,
}: TileNodeProps) {
  const { root, ...classes } = tileClasses;
  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();
  const { dropTargetNode } = useFlowChartContext();
  const { cursorClassName } = useCursorStyles(id);
  const { draggingNode } = useDraggingNode();
  const { isInteractive } = useIsInteractive();
  const commonHandleProps: Partial<HandleProps> = {
    isConnectable,
    hidden: true,
  };
  const isGrouped = id === dropTargetNode?.id && !!draggingNode;
  const nodeControlsVisible = !!selected && isInteractive && !dragging;

  return (
    <>
      {isInteractive &&
        isModalOpen &&
        editModal?.({ isOpen: isModalOpen, onClose: closeModal })}
      <NodeResizer
        lineStyle={lineStyle}
        handleStyle={handleStyle}
        isVisible={nodeControlsVisible}
      />
      <TileNodeControls isVisible={nodeControlsVisible}>
        {controls ? (
          controls
        ) : (
          <>
            <TileNodeFontSizeControls />
            <TileSettingsControlButton onClick={openModal} />
          </>
        )}
      </TileNodeControls>
      <Tile
        classes={{
          root: cx(cursorClassName, root),
          ...classes,
        }}
        width={width}
        height={height}
        dragging={dragging}
        isSelected={dragging || !!selected}
        isGrouped={isGrouped}
        fontSizeVariant={fontSizeVariant}
        {...tileProps}
      />
      {/* Build source/target handles for each four corners of the tile square */}
      {AllPositions.map((position) => (
        <Handle
          key={`target-${position}`}
          type="target"
          {...commonHandleProps}
          id={position}
          position={position}
        />
      ))}
      {/* Build source/target handles for each four corners of the tile square */}
      {AllPositions.map((position) => (
        <Handle
          key={`source-${position}`}
          type="source"
          {...commonHandleProps}
          id={position}
          position={position}
        />
      ))}
    </>
  );
}
