import { compact } from 'lodash';
import { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import { FullScreenModal } from '@/components/modals/FullScreenModal/FullScreenModal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { AI_ONBOARDING_MODAL_STEP_CONFIGS } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal.constants';
import {
  AIOnboardingModalProps,
  AIOnboardingModalStep,
} from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal.types';
import { orderSuggestions } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal.utils';
import { AIOnboardingModalFooter } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalFooter';
import { getDefaultData } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.constants';
import { AIOnboardingModalFormShape } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import { dataToForm } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.utils';
import { AIOnboardingModalHeader } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalHeader';
import { AIOnboardingModalRightContent } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalRightContent';
import { useAIOnboardingModalContext } from '@/modules/aiOnboarding/AIOnboardingModal/context/AIOnboardingModal.context';
import { AIOnboardingModalProvider } from '@/modules/aiOnboarding/AIOnboardingModal/context/AIOnboardingModal.provider';
import {
  AiOnboardingModal_HouseholdFragment,
  useFetchAiOnboardingModalDataQuery,
} from '@/modules/aiOnboarding/AIOnboardingModal/graphql/aiOnboardingModal.generated';
import { useSelectedSuggestionEvidenceContext } from '@/modules/aiSuggestions/context/SelectedSuggestionEvidence.context';
import { SelectedSuggestionEvidenceProvider } from '@/modules/aiSuggestions/context/SelectedSuggestionEvidence.provider';
import { SplitScreenLayoutWithDocuments } from '@/modules/documents/components/SplitScreenLayoutWithDocuments/SplitScreenLayoutWithDocuments';
import { orderDocsAlphaByName } from '@/modules/documents/documents.utils';
import { MultiDocumentUploaderProvider } from '@/modules/documents/MultiDocumentUploader/context/MultiDocumentUploader.provider';
import { HouseholdBillableKind } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

interface AIOnboardingModalInnerProps {
  loading: boolean;
  household: AiOnboardingModal_HouseholdFragment;
  documentIds: string[];
}

function AIOnboardingModalInner({
  loading,
  household,
  documentIds,
}: AIOnboardingModalInnerProps) {
  const { isOpen, onClose, householdId, activeStep, suggestionsByFormPath } =
    useAIOnboardingModalContext();

  const {
    documentPanelProps: {
      activeDocumentId,
      activeDocumentViewerTab,
      annotations,
      searchQuery,
      searchLoading,
    },
    setDocumentPanelProps,
  } = useSelectedSuggestionEvidenceContext();

  const { reset } = useFormContext();

  useEffect(() => {
    if (
      loading ||
      !activeStep ||
      activeStep === AIOnboardingModalStep.NO_SUGGESTIONS
    ) {
      return;
    }

    const stepFormPaths =
      AI_ONBOARDING_MODAL_STEP_CONFIGS[activeStep].formPaths;

    const stepSuggestions = stepFormPaths.flatMap(
      (fp) => suggestionsByFormPath[fp]
    );

    // Set the form data when we go to a new step, with the suggestions for that step.
    reset(dataToForm(stepSuggestions, household));
  }, [activeStep, household, loading, reset, suggestionsByFormPath]);

  return (
    <FullScreenModal isOpen={isOpen} onClose={onClose}>
      <SplitScreenLayoutWithDocuments
        householdId={householdId}
        documentIds={documentIds}
        header={<AIOnboardingModalHeader />}
        rightContent={<AIOnboardingModalRightContent />}
        footer={<AIOnboardingModalFooter />}
        hideAddDocument={true}
        overrideActiveDocumentId={activeDocumentId}
        onUpdateActiveDocumentId={({
          documentId,
          shouldClearSearchResults,
        }) => {
          if (shouldClearSearchResults) {
            // Clear out the annotations and search-related props
            setDocumentPanelProps({
              activeDocumentViewerTab: 'document',
              activeDocumentId: documentId,
              searchQuery: '',
              annotations: undefined,
              searchLoading: false,
            });
            return;
          }
          // Otherwise, only update the document id and tab
          setDocumentPanelProps((prev) => ({
            ...prev,
            activeDocumentViewerTab: 'document',
            activeDocumentId: documentId,
          }));
        }}
        overrideDocumentViewerActiveTab={activeDocumentViewerTab}
        overrideSearchQuery={searchQuery}
        overrideSearchLoading={searchLoading}
        overrideAnnotations={annotations}
        // Order documents alphabetically by name in the viewer's document selector
        documentOrderFn={orderDocsAlphaByName}
      />
    </FullScreenModal>
  );
}

export function AIOnboardingModal(props: AIOnboardingModalProps) {
  const { reportError } = useReportError();
  const { createErrorFeedback } = useFeedback();

  const formMethods = useForm<AIOnboardingModalFormShape>({
    defaultValues: getDefaultData(),
  });

  const { data, loading } = useFetchAiOnboardingModalDataQuery({
    // Fetch the AIOnboardingModal data every time the modal is opened, in order
    // to always be able to get the documentUrl when the document is cached.
    // This fixes an issue where the document is not shown when the modal is opened.
    fetchPolicy: 'cache-and-network',
    variables: {
      kgRootID: props.kgRootId,
    },
    onError: (error) => {
      reportError('error fetching KGRoot for AIOnboardingModal', error);
      createErrorFeedback(
        'Could not load data. Please refresh the page and try again.'
      );
    },
  });

  const { documentIds, household, suggestions } = useMemo(() => {
    const node = getNodes(data?.kgRoots)?.[0];
    if (!node) {
      return {
        documentIds: [],
        household: {
          id: props.householdId,
          billableKind: HouseholdBillableKind.Billable,
          possiblePrimaryClients: [],
          possibleGrantors: [],
        },
        suggestions: [],
      };
    }

    // Order documents alphabetically by name. This is the same order that the
    // documents will be displayed in the viewer's document selector.
    const orderedDocIds = orderDocsAlphaByName(
      compact((node.files || []).map((file) => file?.document))
    ).map((doc) => doc.id);

    // Order suggestions by entity kind bucket, then by document order, then by name
    const suggestions = orderSuggestions(
      (node.aiSuggestions || []).filter((s) => !s.acceptanceStatus),
      orderedDocIds
    );

    return {
      documentIds: orderedDocIds,
      household: node.household,
      suggestions,
    };
  }, [data?.kgRoots, props.householdId]);

  return (
    <MultiDocumentUploaderProvider>
      <FormProvider {...formMethods}>
        <AIOnboardingModalProvider
          {...props}
          loading={loading}
          household={household}
          suggestions={suggestions}
          documentIds={documentIds}
        >
          <SelectedSuggestionEvidenceProvider>
            <AIOnboardingModalInner
              loading={loading}
              household={household}
              documentIds={documentIds}
            />
          </SelectedSuggestionEvidenceProvider>
        </AIOnboardingModalProvider>
      </FormProvider>
    </MultiDocumentUploaderProvider>
  );
}
