import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { useDefaultClientPresentationSections } from '@/modules/presentation/clientPresentation/hooks/useDefaultClientPresentationSections';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import {
  AugmentedCreatePresentationBundleInput,
  PresentationBundleKind,
  PresentationPageKind,
} from '@/types/schema';

import { useClientPresentationsButtonQuery } from '../ClientPresentationsTable/graphql/ClientPresentationsButton.generated';
import {
  useCreateClientPresentationMutation,
  useCreateClientPresentationV2Mutation,
} from '../graphql/ClientPresentations.generated';

interface CreatePresentationButtonProps {
  householdId: string;
}

export function CreatePresentationButton({
  householdId,
}: CreatePresentationButtonProps) {
  const navigate = useNavigate();
  const trackUserEvent = useTrackUserEvent();
  const defaultSectionOrder = useDefaultClientPresentationSections();
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const isClientPresentationV2Enabled = useFeatureFlag(
    'client_presentation_v2'
  );

  const { data, loading: loadingDisclaimer } =
    useClientPresentationsButtonQuery({
      skip: !isClientPresentationV2Enabled,
    });

  const includeV2Disclaimer =
    (data?.tenantInformation?.disclosuresConfiguration
      ?.includeLuminaryDisclaimer ||
      data?.tenantInformation?.disclosuresConfiguration
        ?.presentationStandaloneDisclaimerBody) ??
    false;

  const [createClientPresentationAndNavigate, { loading: loadingV1 }] =
    useCreateClientPresentationMutation({
      variables: {
        input: {
          create: {
            name: 'Untitled presentation',
            householdID: householdId,
            entityOrder: [],
            estateWaterfallOrder: [],
            sectionOrder: defaultSectionOrder,
          },
        },
      },
      onError: (err) => {
        reportError('Failed to create presentation', err);
        showFeedback('Failed to create a new presentation; please try again');
      },
      onCompleted: (data) => {
        trackUserEvent('presentation created');
        navigate(
          getCompletePathFromRouteKey(
            ROUTE_KEYS.HOUSEHOLD_DETAILS_PRESENTATION,
            {
              householdId,
              presentationId: data.createClientPresentation.id,
            }
          )
        );
      },
    });

  const [createClientPresentationAndNavigateV2, { loading: loadingV2 }] =
    useCreateClientPresentationV2Mutation({
      onError: (err) => {
        reportError('Failed to create presentation', err);
        showFeedback('Failed to create a new presentation; please try again');
      },
      onCompleted: (data) => {
        trackUserEvent('presentation created');
        navigate(
          getCompletePathFromRouteKey(
            ROUTE_KEYS.HOUSEHOLD_DETAILS_PRESENTATION_V2,
            {
              householdId,
              presentationId: data.createClientPresentationV2.id,
            }
          )
        );
      },
    });

  const loading = loadingV1 || loadingV2;

  const handleClick = useCallback(() => {
    if (isClientPresentationV2Enabled) {
      const withBundles: AugmentedCreatePresentationBundleInput[] = [
        {
          create: {
            householdID: householdId,
            kind: PresentationBundleKind.CoverSlideBundle,
          },
          withPages: [
            {
              create: {
                householdID: householdId,
                kind: PresentationPageKind.CoverPage,
              },
            },
          ],
        },
        {
          create: {
            householdID: householdId,
            kind: PresentationBundleKind.TableOfContentsBundle,
          },
          withPages: [
            {
              create: {
                householdID: householdId,
                kind: PresentationPageKind.TableOfContentsPage,
              },
            },
          ],
        },
        {
          create: {
            householdID: householdId,
            kind: PresentationBundleKind.BalanceSheetBundle,
          },
          withPages: [
            {
              create: {
                householdID: householdId,
                kind: PresentationPageKind.BalanceSheetPage,
              },
            },
          ],
        },
        {
          create: {
            householdID: householdId,
            kind: PresentationBundleKind.EntitiesOverviewBundle,
          },
          withPages: [
            {
              create: {
                householdID: householdId,
                kind: PresentationPageKind.EntitiesSummaryPage,
              },
            },
          ],
        },
      ];

      if (includeV2Disclaimer) {
        withBundles.push({
          create: {
            householdID: householdId,
            kind: PresentationBundleKind.StandaloneDisclaimerBundle,
          },
          withPages: [
            {
              create: {
                householdID: householdId,
                kind: PresentationPageKind.StandaloneDisclaimerPage,
              },
            },
          ],
        });
      }

      void createClientPresentationAndNavigateV2({
        variables: {
          input: {
            create: {
              name: 'Untitled presentation',
              householdID: householdId,
              pageNumberToStartFrom: 1,
              showPageNumbers: true,
              showPageNumbersOnCoverSlide: true,
              showLegalDisclaimer: true,
            },
            withBundles,
          },
        },
      });
    } else {
      void createClientPresentationAndNavigate();
    }
  }, [
    isClientPresentationV2Enabled,
    householdId,
    includeV2Disclaimer,
    createClientPresentationAndNavigateV2,
    createClientPresentationAndNavigate,
  ]);

  return (
    <Button
      startIcon={PlusIcon}
      loading={loading || loadingDisclaimer}
      variant="primary"
      size="sm"
      onClick={handleClick}
      data-testid="CreatePresentation"
    >
      Create report
    </Button>
  );
}
