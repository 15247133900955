import { capitalize } from 'lodash';

import { SaleLoanIllustration_EstateWaterfallFragment } from '../graphql/SaleLoanIllustration.generated';

export interface GetYearEventDescriptionParams {
  termStartYear: number;
  termLengthYears: number;
  isTwoGrantorHousehold: boolean;
}

export function getYearEventDescription(
  year: number,
  waterfall: SaleLoanIllustration_EstateWaterfallFragment,
  params: GetYearEventDescriptionParams | null
): string | undefined {
  if (!params) {
    return undefined;
  }

  const { termStartYear, termLengthYears, isTwoGrantorHousehold } = params;

  const firstGrantorDeathYear =
    waterfall.visualizationWithSaleLoanProjections.materializedInputParams
      .firstGrantorDeathYear;
  const secondGrantorDeathYear = isTwoGrantorHousehold
    ? waterfall.visualizationWithSaleLoanProjections.materializedInputParams
        .secondGrantorDeathYear
    : null;
  const termEndYear = termStartYear + termLengthYears;

  const descriptions = [];

  if (year === firstGrantorDeathYear || year === secondGrantorDeathYear) {
    descriptions.push('Death year');
  }

  if (year === termStartYear) {
    descriptions.push('Term start');
  }

  if (year === termEndYear - 1) {
    descriptions.push('Term end');
  }

  // first character uppercase, remainder lowercase
  return capitalize(descriptions.join(', '));
}
