// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
export type GraphViz_NodeConfigFragment = { __typename?: 'EntityGraphNodeConfiguration', nodeID: string, hidden?: boolean | null, xPosition: number, yPosition: number, height?: number | null, width?: number | null, fontSize?: string | null, notes?: string | null };

export const GraphViz_NodeConfigFragmentDoc = gql`
    fragment GraphViz_NodeConfig on EntityGraphNodeConfiguration {
  nodeID
  hidden
  xPosition
  yPosition
  height
  width
  fontSize
  notes
}
    `;
export const Operations = {
  Fragment: {
    GraphViz_NodeConfig: 'GraphViz_NodeConfig' as const
  }
}