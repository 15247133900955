import { useCallback, useState } from 'react';

import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { DocumentSummaryAiSummaryRating } from '@/types/schema';

import { DocumentDetailsDocument } from '../graphql/DocumentDetails.generated';
import { useRateSummaryMutation } from '../graphql/RateSummary.generated';

export function useRateSummary(
  documentSummaryId: string,
  existingRating: DocumentSummaryAiSummaryRating | null = null,
  allowFeedback: boolean
) {
  const trackUserEvent = useTrackUserEvent();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { householdId } = useHouseholdDetailsContext();
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const openFeedbackModal = useCallback(() => setIsFeedbackModalOpen(true), []);
  const closeFeedbackModal = useCallback(
    () => setIsFeedbackModalOpen(false),
    []
  );

  const [persistedRating, setPersistedRating] = useState(existingRating);

  const [rateSummaryMutation, muationProps] = useRateSummaryMutation({
    refetchQueries: [DocumentDetailsDocument],
    onCompleted: (data) => {
      setPersistedRating(data.updateDocumentSummary.aiSummaryRating ?? null);
      if (
        data.updateDocumentSummary.aiSummaryRating ===
        DocumentSummaryAiSummaryRating.Bad
      ) {
        trackUserEvent(`document ai summary negative feedback submitted`, {
          feedback: data.updateDocumentSummary?.aiSummaryFeedback,
          documentId: data.updateDocumentSummary?.document?.id,
          householdId,
        });
      }
    },
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      reportError('Failed to update rating for summary', error, {
        documentSummaryId,
      });
    },
  });

  const rateSummary = useCallback(
    (aiSummaryRating: DocumentSummaryAiSummaryRating) => {
      return rateSummaryMutation({
        variables: {
          updateDocumentSummaryId: documentSummaryId,
          input: {
            aiSummaryRating,
            clearAiSummaryFeedback:
              aiSummaryRating === DocumentSummaryAiSummaryRating.Good
                ? true // Note that we clear the feedback if the rating is positive
                : undefined, // Otherwise, we leave it as is
          },
        },
        optimisticResponse: {
          updateDocumentSummary: {
            id: documentSummaryId,
            __typename: 'DocumentSummary',
            aiSummaryRating,
            document: {
              // The doc id is not necessary for the optimistic response.
              // We use it in the onComplete in order to track the user event.
              id: '',
            },
          },
        },
      });
    },
    [documentSummaryId, rateSummaryMutation]
  );

  const provideFeedback = useCallback(
    (aiSummaryFeedback: string) => {
      return rateSummaryMutation({
        variables: {
          updateDocumentSummaryId: documentSummaryId,
          input: {
            aiSummaryFeedback,
          },
        },
      });
    },
    [documentSummaryId, rateSummaryMutation]
  );

  const upRateSummary = useCallback(() => {
    void rateSummary(DocumentSummaryAiSummaryRating.Good);
  }, [rateSummary]);

  const downRateSummary = useCallback(async () => {
    const { data } = await rateSummary(DocumentSummaryAiSummaryRating.Good);
    if (data && allowFeedback) {
      openFeedbackModal();
    }
    void rateSummary(DocumentSummaryAiSummaryRating.Bad);
  }, [allowFeedback, openFeedbackModal, rateSummary]);

  const clearSummaryRating = useCallback(() => {
    if (!documentSummaryId) return;

    void rateSummaryMutation({
      variables: {
        updateDocumentSummaryId: documentSummaryId,
        input: {
          clearAiSummaryRating: true,
          clearAiSummaryFeedback: true,
        },
      },
    });
  }, [documentSummaryId, rateSummaryMutation]);

  return {
    upRateSummary,
    downRateSummary,
    clearSummaryRating,
    persistedRating,
    provideFeedback,
    isFeedbackModalOpen,
    closeFeedbackModal,
    openFeedbackModal,
    ...muationProps,
  };
}
