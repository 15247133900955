import { Box } from '@mui/material';
import { chunk } from 'lodash';
import { ComponentType, useMemo } from 'react';

import { getGRATTrustSummaryProperties } from '@/modules/entities/details/grat/GRATTrustDetails';
import { getCLTTrustSummaryProperties } from '@/modules/entities/details/trusts/CLTTrustDetails';
import { getCRTTrustSummaryProperties } from '@/modules/entities/details/trusts/CRTTrustDetails';
import { getILITTrustSummaryProperties } from '@/modules/entities/details/trusts/ILITTrustDetails';
import { getQPRTTrustSummaryProperties } from '@/modules/entities/details/trusts/QPRTTrustDetails';
import { getRevocableIrrevocableTrustSummaryProperties } from '@/modules/entities/details/trusts/RevocableIrrevocableTrustDetails';
import { getSLATTrustSummaryProperties } from '@/modules/entities/details/trusts/SLATTrustDetails';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { getEntityTypeFromEntityKind } from '@/modules/entities/utils/getEntityTypeFromEntityKind';
import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import {
  getPaginatedBeneficiariesAndDispositionsForSummary,
  GetPaginatedBeneficiariesAndDispositionsForSummaryOutput,
} from '@/modules/presentation/entities/entityPresentationPagination.utils';
import { EntitySummaryGrantor } from '@/modules/presentation/entities/entityPresentations.types';
import {
  CLTTrustSummarySlide,
  CLTTrustSummarySlideProps,
} from '@/modules/presentation/entities/trusts/CLTTrust/CLTTrust.SummarySlide';
import {
  CRTTrustSummarySlide,
  CRTTrustSummarySlideProps,
} from '@/modules/presentation/entities/trusts/CRTTrust/CRTTrust.Summary';
import {
  GRATTrustSummarySlide,
  GRATTrustSummarySlideProps,
} from '@/modules/presentation/entities/trusts/GRATTrust/GRATTrust.SummarySlide';
import {
  ILITTrustSummarySlide,
  ILITTrustSummarySlideProps,
} from '@/modules/presentation/entities/trusts/ILITTrust/ILITTrust.Summary';
import {
  QPRTTrustSummarySlide,
  QPRTTrustSummarySlideProps,
} from '@/modules/presentation/entities/trusts/QPRTTrust/QPRTTrust.Summary';
import {
  RevocableIrrevocableTrustSummarySlide,
  RevocableIrrevocableTrustSummarySlideProps,
} from '@/modules/presentation/entities/trusts/RevocableTrust/RevocableIrrevocableTrust.SummarySlide';
import {
  SLATTrustSummarySlide,
  SLATTrustSummarySlideProps,
} from '@/modules/presentation/entities/trusts/SLATTrust/SLATTrust.Summary';
import { TrustSummaryData } from '@/modules/trusts/TrustDetails/TrustDetails.types';
import { EntityKind, PresentationBundleKind, StateCode } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';

import { useRegisterSlide } from '../../../ClientPresentationDesignerV2.context';
import { ClientPresentationDesignerV2_EntityFragment } from '../../../graphql/ClientPresentationDesignerV2.generated';
import { ClientPresentationV2Footer } from '../../ClientPresentationV2Footer';
import { BaseBundleSlideProps } from '../BundleSlide.types';
import { InsurancePoliciesSlide } from './InsurancePoliciesSlideV2';

export const TRUST_SUMMARY_SLIDE_MAP = {
  [EntityKind.IrrevocableTrust]: RevocableIrrevocableTrustSummarySlide,
  [EntityKind.RevocableTrust]: RevocableIrrevocableTrustSummarySlide,
  [EntityKind.SlatTrust]: SLATTrustSummarySlide,
  [EntityKind.QprtTrust]: QPRTTrustSummarySlide,
  [EntityKind.IlitTrust]: ILITTrustSummarySlide,
  [EntityKind.CrtTrust]: CRTTrustSummarySlide,
  [EntityKind.CltTrust]: CLTTrustSummarySlide,
  [EntityKind.GratTrust]: GRATTrustSummarySlide,
  [EntityKind.IndividualPersonalAccount]: null,
  [EntityKind.JointPersonalAccount]: null,
  [EntityKind.InsurancePersonalAccount]: null,
  [EntityKind.QualifiedTuitionPersonalAccount]: null,
  [EntityKind.RetirementPersonalAccount]: null,
  [EntityKind.CustodialPersonalAccount]: null,
  [EntityKind.DonorAdvisedFund]: null,
  [EntityKind.PrivateFoundation]: null,
  [EntityKind.LlcBusinessEntity]: null,
  [EntityKind.SoleProprietorshipBusinessEntity]: null,
  [EntityKind.ScorpBusinessEntity]: null,
  [EntityKind.CcorpBusinessEntity]: null,
  [EntityKind.GpBusinessEntity]: null,
  [EntityKind.LpBusinessEntity]: null,
};

type SlideComponentProps =
  | RevocableIrrevocableTrustSummarySlideProps
  | SLATTrustSummarySlideProps
  | QPRTTrustSummarySlideProps
  | ILITTrustSummarySlideProps
  | CRTTrustSummarySlideProps
  | CLTTrustSummarySlideProps
  | GRATTrustSummarySlideProps;

type SlideComponentType = ComponentType<SlideComponentProps>;
interface TrustSummarySlideInnerProps extends TrustSummarySlideProps {
  summary: TrustSummaryData | null;
  idx: number;
  slideProps: GetPaginatedBeneficiariesAndDispositionsForSummaryOutput;
  grantors: EntitySummaryGrantor[];
  SlideComponent: SlideComponentType;
}

function TrustSummarySlideInner({
  entity,
  bundle,
  page,
  SlideWrapper = Box,
  idx,
  summary,
  slideProps,
  grantors,
  SlideComponent,
  isVisible,
}: TrustSummarySlideInnerProps) {
  const slideId = `revocable-irrevocable-trust-summary-slide-${bundle.id}-${page?.id || 'no-page'}-${entity.id}-${idx}`;
  useRegisterSlide({
    slideId,
    title:
      bundle.displayName ?? `Entity Summary -- ${entity.subtype.displayName}`,
    includeInToC: idx === 0,
    bundleKind: PresentationBundleKind.EntitySummaryBundle,
  });
  if (!isVisible) {
    return null;
  }

  return (
    <SlideWrapper key={slideId}>
      <SlideComponent
        registrationProps={{
          bundleId: bundle.id,
          slideId,
          bundleIndex: idx,
        }}
        entity={entity}
        summary={summary!}
        displayName={entity.subtype.displayName}
        grantors={grantors}
        Footer={<ClientPresentationV2Footer slideId={slideId} />}
        {...slideProps}
      />
    </SlideWrapper>
  );
}

export interface TrustSummarySlideProps extends BaseBundleSlideProps {
  entity: ClientPresentationDesignerV2_EntityFragment;
  entityKind:
    | EntityKind.IrrevocableTrust
    | EntityKind.RevocableTrust
    | EntityKind.SlatTrust
    | EntityKind.QprtTrust
    | EntityKind.IlitTrust
    | EntityKind.CrtTrust
    | EntityKind.CltTrust
    | EntityKind.GratTrust;
}

export function TrustSummarySlide({
  entity,
  bundle,
  page,
  SlideWrapper = Box,
  entityKind,
  isVisible,
}: TrustSummarySlideProps) {
  const { availableStateEstateTaxes } = useIRSConstants();
  const grantors = useMemo<EntitySummaryGrantor[]>(() => {
    return (
      entity.household.possiblePrimaryClients?.map((grantor) => ({
        displayName: grantor.displayName,
        id: grantor.id,
        firstName: grantor.firstName,
        lastName: grantor.lastName,
        stateCode: grantor.address?.stateCode as StateCode | undefined,
        hasStateTax: grantor.address?.stateCode
          ? (availableStateEstateTaxes?.includes(
              grantor.address.stateCode as StateCode
            ) ?? false)
          : false,
      })) ?? []
    );
  }, [availableStateEstateTaxes, entity.household.possiblePrimaryClients]);

  let summary: TrustSummaryData | null = null;
  const SlideComponent = TRUST_SUMMARY_SLIDE_MAP[entityKind];

  switch (entityKind) {
    case EntityKind.IrrevocableTrust:
    case EntityKind.RevocableTrust:
      summary = getRevocableIrrevocableTrustSummaryProperties({
        entity,
        entityType: getEntityTypeFromEntityKind(entityKind),
      });
      break;

    case EntityKind.SlatTrust:
      summary = getSLATTrustSummaryProperties({
        entity,
        entityType: ENTITY_TYPES.SLAT,
      });
      break;

    case EntityKind.QprtTrust:
      summary = getQPRTTrustSummaryProperties({
        entity,
        entityType: ENTITY_TYPES.QPRT,
      });
      break;

    case EntityKind.IlitTrust:
      summary = getILITTrustSummaryProperties({
        entity,
        entityType: ENTITY_TYPES.ILIT,
      });
      break;

    case EntityKind.CrtTrust:
      summary = getCRTTrustSummaryProperties({
        entity,
        entityType: ENTITY_TYPES.CRT,
      });
      break;

    case EntityKind.CltTrust:
      summary = getCLTTrustSummaryProperties({
        entity,
        entityType: ENTITY_TYPES.CLT,
      });
      break;

    case EntityKind.GratTrust:
      summary = getGRATTrustSummaryProperties({
        entity,
        entityType: ENTITY_TYPES.GRAT,
      });
      break;

    default:
      throw new UnreachableError({
        case: entityKind,
        message: `Unhandled trust entity kind: ${entityKind}`,
      });
  }

  if (!summary || !SlideComponent) {
    diagnostics.error(
      `No summary data found for entity ${entity.id} in ${bundle.id}`
    );
    return null;
  }

  const { insurancePolicies } = summary;

  const policyPages = chunk(insurancePolicies, 2);

  const summaryPagesProps = getPaginatedBeneficiariesAndDispositionsForSummary({
    dispositionScenarios:
      entity.dispositionScenariosSubtype?.dispositionScenarios ?? [],
    entityId: entity.id,
    slideTypes: [],
    beneficiaries: [],
    beneficiariesGroups: summary.beneficiariesGroups.map((bg) => bg.items),
    entityKind: entity.kind,
    grantors,
    description: summary.description,
    reserveFirstPage: isFeatureFlagEnabled('entity_diagrams'),
  });

  return (
    <>
      {summaryPagesProps.map((slideProps, idx) => (
        <TrustSummarySlideInner
          key={`revocable-irrevocable-trust-summary-slide-${bundle.id}-${page?.id || 'no-page'}-${idx}`}
          entityKind={entityKind}
          entity={entity}
          bundle={bundle}
          page={page}
          SlideWrapper={SlideWrapper}
          SlideComponent={SlideComponent}
          summary={summary}
          idx={idx}
          slideProps={slideProps}
          grantors={grantors}
          isVisible={isVisible}
        />
      ))}
      {policyPages.map((pagePolicies, idx) => (
        <InsurancePoliciesSlide
          key={`revocable-irrevocable-trust-summary-slide-${bundle.id}-${page?.id || 'no-page'}-insurance-${idx}`}
          entity={entity}
          bundle={bundle}
          page={page}
          SlideWrapper={SlideWrapper}
          idx={idx}
          pagePolicies={pagePolicies}
          numberOfPolicySlides={policyPages.length}
          isVisible={isVisible}
        />
      ))}
    </>
  );
}
