import { Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo, useRef } from 'react';

import {
  FullScreenModal,
  FullScreenModalProps,
} from '@/components/modals/FullScreenModal/FullScreenModal';
import { Footer } from '@/components/navigation/Footer';
import { AIUserFeedbackButton } from '@/modules/aiUserFeedback/AIUserFeedbackButton';
import { DispositiveProvisionsModalDetailsType } from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/DispositiveProvisionsForm.types';
import { SplitScreenLayoutWithDocuments } from '@/modules/documents/components/SplitScreenLayoutWithDocuments/SplitScreenLayoutWithDocuments';
import { useClientProfileDetailsContext } from '@/modules/entities/contexts/clientProfileDetails/clientProfileDetails.context';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { useTestamentaryEntityDetailsContext } from '@/modules/entities/contexts/testamentaryEntityDetails/testamentaryEntityDetails.context';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { useManageDispositionsContext } from '@/pages/ClientDetailsPage/ClientDetailsManageDispositionsPage/context/ManageDispositions.context';
import { UserFeedbackFeedbackCategory } from '@/types/schema';

import { DispositiveProvisionsModalBody } from './DispositiveProvisionsModalBody';

export interface DispositiveProvisionsSplitScreenModalProps
  extends FullScreenModalProps {
  firstClientDeathId: string;
  currentNodeValue?: Decimal;
  isFromTemplateEditor?: boolean;
}

export function DispositiveProvisionsSplitScreenModal({
  firstClientDeathId,
  currentNodeValue,
  isFromTemplateEditor,
  ...modalProps
}: DispositiveProvisionsSplitScreenModalProps) {
  const aiDpEnabled = useFeatureFlag('ai_dispositive_provisions');
  const { householdId } = useHouseholdDetailsContext();
  const { entityId } = useEntityDetailsContext();
  const { entityId: testamentaryEntityId } =
    useTestamentaryEntityDetailsContext();
  const { clientProfileId } = useClientProfileDetailsContext();
  const { dispositiveProvisionsModalDetails: modalDetails } =
    useManageDispositionsContext();
  const { onClose } = modalProps;
  // The reference to the footer of the modal, where the cancel/save buttons
  // will be portaled to.
  const footerRef = useRef<HTMLDivElement>(null);

  const showAIFeedbackButton = useMemo(() => {
    return (
      modalDetails &&
      modalDetails.type === DispositiveProvisionsModalDetailsType.ENTITY &&
      aiDpEnabled
    );
  }, [aiDpEnabled, modalDetails]);

  return (
    <FullScreenModal {...modalProps}>
      <SplitScreenLayoutWithDocuments
        targetId={entityId ?? testamentaryEntityId ?? clientProfileId ?? ''}
        householdId={householdId}
        rightContent={
          <DispositiveProvisionsModalBody
            variant="manageDispositions"
            nodeValue={currentNodeValue}
            footerRef={footerRef}
            onClose={() => onClose?.()}
            firstDeathClientId={firstClientDeathId}
            isFromTemplateEditor={isFromTemplateEditor}
          />
        }
        footer={
          <Footer
            leftAction={
              showAIFeedbackButton ? (
                <AIUserFeedbackButton
                  feedbackCategory={
                    UserFeedbackFeedbackCategory.AiDpSuggestions
                  }
                />
              ) : undefined
            }
            rightAction={
              // actions will be portaled here using the footerRef
              <Stack
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
                direction="row"
                ref={footerRef}
              />
            }
          />
        }
      />
    </FullScreenModal>
  );
}
