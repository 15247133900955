import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { File06Icon } from '@/components/icons/File06Icon';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';

interface Document {
  id: string;
  name: string;
}

interface DocumentSelectorMenuProps {
  documents: Document[];
  onDocumentSelect: (documentId: string) => void;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  variant?:
    | 'primary'
    | 'secondary'
    | 'secondary-filled'
    | 'transparent'
    | 'destructive';
  label?: string;
  disabled?: boolean;
  loading?: boolean;
}

/**
 * A reusable menu component for selecting from a list of documents.
 *
 * @example
 * ```tsx
 * <DocumentSelectorMenu
 *   documents={documents}
 *   onDocumentSelect={(id) => console.log('Selected document:', id)}
 *   size="sm"
 *   variant="secondary"
 * />
 * ```
 */
export function DocumentSelectorMenu({
  documents,
  onDocumentSelect,
  size = 'sm',
  variant = 'secondary',
  label = 'Select existing',
  disabled,
  loading,
}: DocumentSelectorMenuProps) {
  return (
    <ButtonWithPopover
      startIcon={File06Icon}
      label={label}
      size={size}
      variant={variant}
      disabled={disabled}
      loading={loading}
      popperProps={{
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
        transformOrigin: { horizontal: 'left', vertical: 0 },
      }}
    >
      {documents?.map((doc) => (
        <MenuItem
          key={doc.id}
          label={doc.name}
          onClick={() => onDocumentSelect(doc.id)}
        />
      ))}
    </ButtonWithPopover>
  );
}
