import { useNodesInitialized } from '@xyflow/react';
import { useCallback, useEffect, useMemo } from 'react';
import { useMeasure } from 'react-use';
import { UseMeasureRect } from 'react-use/lib/useMeasure';

import { useFlowChartContext } from '@/components/diagrams/FlowChart';
import { useDocumentVisibility } from '@/hooks/useDocumentVisibility';
import { useEffectReducer } from '@/hooks/useEffectReducer';
import { useGetState } from '@/hooks/useGetState';
import { reduceEffectReducers } from '@/utils/reducerUtils';

import { useRecomputeSectionWidthsEffect } from '../effects';
import { useCompleteInitialiationEffect } from '../effects/completeInitialization.effect';
import { useFitViewEffect } from '../effects/fitView.effect';
import {
  entityDiagramReducer,
  entityDiagramStateTransformReducer,
} from '../reducers';
import { generateDefaultState } from '../state/generateDefaultState';
import {
  EntityDiagramEffectsMap,
  EntityDiagramInitialStateGetter,
} from '../types';
import { EntityDiagramAction } from '../types/actions';
import { EntityDiagramEffect } from '../types/effects';
import { EntityDiagramState, EntityDiagramStateProps } from '../types/state';
import { EntityDiagramContext } from './entityDiagram.context';

export interface EntityDiagramProviderProps
  extends Pick<
    EntityDiagramStateProps,
    | 'primaryClients'
    | 'entity'
    | 'isTwoClientHousehold'
    | 'selectedPrimaryClientId'
    | 'entityDiagramVariant'
  > {
  children: JSX.Element;
  presentationMode: boolean;
  registerDiagramReady?: () => void;
}

export const rootReducer = reduceEffectReducers([
  // Primary reducer that handles incoming actions
  entityDiagramReducer,
  // Reducer that composes state transform functions. Add computations
  // here that are true for every action type and computed based on state
  // or
  // Derived data which is computed from state, but is more in the form of helper
  // boolean flags, object-by-id look up maps, etc.
  entityDiagramStateTransformReducer,
]);

function useEffectsMap({
  containerDimensions,
}: {
  containerDimensions: UseMeasureRect;
}): EntityDiagramEffectsMap {
  const fitViewEffect = useFitViewEffect({
    containerDimensions,
  });
  const completeInitializationEffect = useCompleteInitialiationEffect();
  const recomputeSectionWidthsEffect = useRecomputeSectionWidthsEffect();
  return useMemo<EntityDiagramEffectsMap>(() => {
    return {
      fitViewEffect,
      completeInitializationEffect,
      recomputeSectionWidthsEffect,
    };
  }, [
    completeInitializationEffect,
    fitViewEffect,
    recomputeSectionWidthsEffect,
  ]);
}

export const EntityDiagramProvider = ({
  children,
  primaryClients,
  isTwoClientHousehold,
  entity,
  presentationMode,
  registerDiagramReady,
  selectedPrimaryClientId,
  entityDiagramVariant,
}: EntityDiagramProviderProps) => {
  const isNodesInitialized = useNodesInitialized();
  const { flowBounds } = useFlowChartContext();
  const visibility = useDocumentVisibility();

  const [entityDiagramContainerRef, containerDimensions] =
    useMeasure<HTMLDivElement>();

  const effectsMap = useEffectsMap({
    containerDimensions,
  });

  const getInitialState = useCallback<EntityDiagramInitialStateGetter>(
    (_queueEffect) => {
      return generateDefaultState({
        primaryClients,
        entity,
        isTwoClientHousehold,
        presentationMode,
        selectedPrimaryClientId,
        entityDiagramVariant,
      });
    },
    [
      entity,
      entityDiagramVariant,
      isTwoClientHousehold,
      presentationMode,
      primaryClients,
      selectedPrimaryClientId,
    ]
  );

  const [state, dispatch] = useEffectReducer<
    EntityDiagramState,
    EntityDiagramAction,
    EntityDiagramEffect
  >(rootReducer, getInitialState, effectsMap);

  const getState = useGetState(state);

  // Once react flow has measured everything, we can start positioning nodes
  useEffect(() => {
    if (!isNodesInitialized) return;

    if (state.isInitializing) {
      dispatch({ type: 'INITIALIZE_NODE_POSITIONS' });
    } else {
      // IMPORTANT code branch: isNodesInitalized can flip from true to false AFTER we've initalized our own node layout.
      // The most basic example of this is creating a group node that doesn't have a height / width until measured by reactflow,
      // so we need to capture this measurement event by "re-measuring" our internal styles, primarily on section groups.
      dispatch({ type: 'REACTFLOW_NODE_MEASUREMENT_EVENT' });
    }
  }, [dispatch, isNodesInitialized, state.isInitializing]);

  useEffect(() => {
    dispatch({
      type: 'FLOW_BOUNDS_CHANGED',
      height: flowBounds.height,
      width: flowBounds.width,
    });
  }, [flowBounds.height, flowBounds.width, dispatch]);

  const value = useMemo(
    () => ({ state, dispatch, getState }),
    [dispatch, getState, state]
  );

  const diagramReady = state.opacity === 1;

  useEffect(() => {
    if (!diagramReady) return;

    registerDiagramReady?.();
  }, [registerDiagramReady, entity.id, diagramReady]);

  // The flow chart requires the tab to be active to render correctly
  // https://github.com/xyflow/xyflow/issues/2636
  if (visibility !== 'visible' && !isNodesInitialized) return null;

  return (
    <EntityDiagramContext.Provider value={value}>
      <div ref={entityDiagramContainerRef} style={{ height: '100%' }}>
        {children}
      </div>
    </EntityDiagramContext.Provider>
  );
};
