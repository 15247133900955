import {
  AugmentedCreateEntityProposalInput,
  AugmentedCreateProposalInput,
  AugmentedCreateProposalScenarioInput,
  AugmentedUpdateEntityProposalInput,
  AugmentedUpdateProposalInput,
  CreateEntityProposalInput,
  EntityProposalAssetValuationProjectionType,
  GratProposalScenarioInTermValuationType,
  InputMaybe,
} from '@/types/schema';

import { EditAssumptionsForm } from '../modals/EditAssumptionsModal/constants';
import { DEFAULT_TAX_DRAG, FORM_NAME, FormShape } from './constants';

const endValuationMap = {
  [EntityProposalAssetValuationProjectionType.ProjectedRateOfReturn]:
    GratProposalScenarioInTermValuationType.PercentRateOfReturn,
  [EntityProposalAssetValuationProjectionType.ProjectedValue]:
    GratProposalScenarioInTermValuationType.EndTermValue,
};

interface MakeCreateInputArgs {
  values: FormShape;
  householdId: string;
  assumptions: EditAssumptionsForm;
  proposalId?: string;
  entityIdsInProposal?: string[];
}

export function makeCreateInput({
  values,
  householdId,
  assumptions,
  proposalId,
  entityIdsInProposal,
}: MakeCreateInputArgs): AugmentedCreateProposalInput {
  const {
    name: displayName,
    description: clientNotes,
    includeCumulativeView,
    entities,
  } = values[FORM_NAME];

  const createEntityProposals: AugmentedCreateEntityProposalInput[] = [];

  Object.entries(entities).forEach(([entityId, { scenarios }]) => {
    if (entityIdsInProposal && !entityIdsInProposal.includes(entityId)) {
      return;
    }

    const withProposalScenarios: AugmentedCreateProposalScenarioInput[] = [];
    const assumption = assumptions[entityId];

    scenarios.forEach((scenario) => {
      const {
        projectedRateOfReturn,
        preTaxProjectedRateOfReturn,
        selectedYearOption,
        projectedMarketValueAtTerm,
      } = scenario;

      withProposalScenarios.push({
        create: {},
        withGratProposalScenario: {
          create: {
            projectedAnnualReturnThroughTerm: projectedRateOfReturn,
            projectedAnnualReturnAfterTerm: preTaxProjectedRateOfReturn,
            projectionTimePeriodYears: parseInt(selectedYearOption),
            projectedMarketValueAtTerm,
            inTermValuationType: assumption
              ? (endValuationMap[assumption.termEndAssetValuationType] ?? null)
              : null,
          },
        },
      });
    });

    let termEndAssetValuationType: EditAssumptionsForm[string]['termEndAssetValuationType'] =
      EntityProposalAssetValuationProjectionType.ProjectedRateOfReturn;
    let taxDragPercent: EditAssumptionsForm[string]['taxDragPercent'] =
      DEFAULT_TAX_DRAG;
    if (assumption) {
      termEndAssetValuationType = assumption.termEndAssetValuationType;
      taxDragPercent = assumption.taxDragPercent;
    }

    const create: CreateEntityProposalInput = {
      assetValuationProjectionType: termEndAssetValuationType,
      taxDrag: taxDragPercent,
      entityID: entityId,
      proposalID: proposalId ?? '',
    };

    const proposal: AugmentedCreateEntityProposalInput = {
      create,
      withProposalScenarios,
    };

    createEntityProposals.push(proposal);
  });

  return {
    create: {
      householdID: householdId,
      clientNotes,
      displayName,
      includeCumulativeView,
    },
    withEntityProposals: createEntityProposals,
  };
}

// A downside of the augmented input types is that they include a lot of cruft.
// The narrowed type helps communicate what's actually needed.
interface Update extends AugmentedUpdateProposalInput {
  id: string;
  update: {
    householdID: string;
    displayName: string;
    clientNotes: string;
    includeCumulativeView: boolean;
    clearEntityProposals: boolean;
  };
  updateEntityProposals: {
    id: string;
    update: {
      clearProposalScenarios?: InputMaybe<boolean>;
    };
    withProposalScenarios?: InputMaybe<AugmentedCreateProposalScenarioInput[]>;
  }[];
}

export const makeUpdateInput = ({
  values,
  householdId,
  assumptions,
  proposalId,
  entityProposalIds,
}: {
  values: FormShape;
  householdId: string;
  assumptions: EditAssumptionsForm;
  proposalId: string;
  entityProposalIds: string[];
}): Update => {
  const createInput = makeCreateInput({
    values,
    householdId,
    assumptions,
    proposalId,
  });

  const withEntityProposals = createInput.withEntityProposals ?? [];

  const updateEntityProposals: AugmentedUpdateEntityProposalInput[] =
    withEntityProposals.map((ep, idx) => {
      const id = entityProposalIds[idx];
      if (!id) {
        throw new Error(`Expected entityProposalIds to have index of ${idx}`);
      }
      return {
        id: id,
        update: {
          clearProposalScenarios: true,
          assetValuationProjectionType: ep.create?.assetValuationProjectionType,
        },
        withProposalScenarios: ep.withProposalScenarios,
      };
    });

  return {
    id: proposalId,
    update: {
      householdID: householdId,
      displayName: values[FORM_NAME].name,
      clientNotes: values[FORM_NAME].description,
      includeCumulativeView: values[FORM_NAME].includeCumulativeView,
      clearEntityProposals: false,
    },
    updateEntityProposals,
  };
};
