import Decimal from 'decimal.js';
import {
  any,
  array,
  boolean as booleanType,
  date,
  define,
  Describe,
  enums,
  nullable,
  number,
  object,
  optional,
  string,
  Struct,
} from 'superstruct';
import { StructSchema } from 'superstruct/dist/utils';

import {
  AccessParameterFrequency,
  AccessParameterKind,
  AugmentedCreateAccessParameterInput,
  AugmentedCreateAccountInput,
  AugmentedCreateAssetValuationV2Input,
  AugmentedCreateBeneficiaryInput,
  AugmentedCreateBeneficiaryPowerOfAppointmentInput,
  AugmentedCreateEntityStateTaxInput,
  AugmentedCreatePrincipalInput,
  AugmentedCreateScheduledDistributionInput,
  AugmentedCreateTrustAdvisorInput,
  AugmentedCreateTrustAdvisorRoleInput,
  AugmentedCreateTrusteeDutyInput,
  AugmentedCreateTrusteeInput,
  AugmentedUpdateAccountInput,
  AugmentedUpdateAssetValuationV2Input,
  AugmentedUpdateGratAnnuityV2Input,
  BeneficiaryLevel,
  BeneficiaryPowerOfAppointmentPower,
  CreateEntityInput,
  EntityAttributionSource,
  EntityGstStatus,
  EntityInEstateStatus,
  EntityStage,
  NonTrustEntityTaxStatus,
  ScheduledDistributionFrequency,
  ScheduledDistributionKind,
  StateCode,
  TrustAdvisorLevel,
  TrustAdvisorRoleKind,
  TrusteeDutyKind,
  TrusteeLevel,
  TrusteeTrusteeCategory,
  TrustTaxStatus,
  UpdateGratAnnuityV2Input,
} from '@/types/schema';

// the cast is necessary for non-nullable decimals; without it, the resulting type
// will be Struct<Decimal, null>
export const decimal = define<Decimal>('Decimal', (value) =>
  Decimal.isDecimal(value)
) as unknown as Struct<Decimal, StructSchema<Decimal>>;

export const principalWithOwnershipSchema: Describe<AugmentedCreatePrincipalInput> =
  object({
    create: object({
      individualID: optional(nullable(string())),
      ownershipPercentage: optional(nullable(decimal)),
    }),
  });

export const dutySchema: Describe<AugmentedCreateTrusteeDutyInput> = object({
  create: object({
    kind: enums([
      TrusteeDutyKind.Administration,
      TrusteeDutyKind.Distribution,
      TrusteeDutyKind.Investment,
      TrusteeDutyKind.Special,
    ]),
  }),
});

export const trusteeSchema: Describe<AugmentedCreateTrusteeInput> = object({
  create: object({
    individualID: optional(nullable(string())),
    organizationID: optional(nullable(string())),
    level: optional(
      nullable(
        enums([
          TrusteeLevel.Primary,
          TrusteeLevel.Secondary,
          TrusteeLevel.Tertiary,
          TrusteeLevel.Other,
        ])
      )
    ),
    notes: optional(nullable(string())),
    trusteeCategory: optional(
      nullable(
        enums([
          TrusteeTrusteeCategory.Primary,
          TrusteeTrusteeCategory.Successor,
        ])
      )
    ),
  }),
  withDuties: optional(nullable(array(dutySchema))),
});

export const scheduledDistributionSchema: Describe<AugmentedCreateScheduledDistributionInput> =
  object({
    create: object({
      ageRequirementEnd: optional(nullable(number())),
      ageRequirementStart: optional(nullable(number())),
      amount: optional(nullable(decimal)),
      frequency: optional(
        nullable(
          enums([
            ScheduledDistributionFrequency.Monthly,
            ScheduledDistributionFrequency.Quarterly,
            ScheduledDistributionFrequency.Semiannually,
            ScheduledDistributionFrequency.Annually,
            ScheduledDistributionFrequency.OneTime,
          ])
        )
      ),
      kind: enums([
        ScheduledDistributionKind.AllIncome,
        ScheduledDistributionKind.Amount,
        ScheduledDistributionKind.Percentage,
      ]),
      percentage: optional(nullable(decimal)),
    }),
  });

export const accessParameterSchema: Describe<AugmentedCreateAccessParameterInput> =
  object({
    create: object({
      accessParameterNotes: optional(nullable(string())),
      amount: optional(nullable(decimal)),
      frequency: optional(
        nullable(
          enums([
            AccessParameterFrequency.Monthly,
            AccessParameterFrequency.Quarterly,
            AccessParameterFrequency.Semiannually,
            AccessParameterFrequency.Annually,
          ])
        )
      ),
      householdID: optional(nullable(string())),
      kind: enums([
        AccessParameterKind.AllTrustIncome,
        AccessParameterKind.Amount,
        AccessParameterKind.Full,
        AccessParameterKind.FullDiscretion,
        AccessParameterKind.Hems,
        AccessParameterKind.Hms,
        AccessParameterKind.Other,
        AccessParameterKind.Percentage,
        AccessParameterKind.NetIncome,
        AccessParameterKind.NetIncomeWithMakeup,
      ]),
      percentage: optional(nullable(decimal)),
    }),
    withAccessAgeParameters: optional(
      nullable(
        array(
          object({
            create: object({
              householdID: string(),
              ageRequirementEnd: optional(nullable(number())),
              ageRequirementStart: optional(nullable(number())),
              notes: optional(nullable(string())),
            }),
          })
        )
      )
    ),
  });

export const powerOfAppointmentSchema: Describe<AugmentedCreateBeneficiaryPowerOfAppointmentInput> =
  object({
    create: object({
      householdID: string(),
      power: optional(
        nullable(
          enums([
            BeneficiaryPowerOfAppointmentPower.General,
            BeneficiaryPowerOfAppointmentPower.Special,
            BeneficiaryPowerOfAppointmentPower.Other,
          ])
        )
      ),
      powerOtherNote: optional(nullable(string())),
    }),
  });

export const beneficiarySchema: Describe<AugmentedCreateBeneficiaryInput> =
  object({
    create: object({
      notes: optional(nullable(string())),
      level: optional(
        nullable(
          enums([
            BeneficiaryLevel.Primary,
            BeneficiaryLevel.Secondary,
            BeneficiaryLevel.Tertiary,
            BeneficiaryLevel.Other,
          ])
        )
      ),
      individualID: optional(nullable(string())),
      entityID: optional(nullable(string())),
      organizationID: optional(nullable(string())),
    }),
    withScheduledDistributions: optional(
      nullable(array(scheduledDistributionSchema))
    ),
    withAccessParameters: optional(nullable(array(accessParameterSchema))),
    withPowerOfAppointment: optional(nullable(powerOfAppointmentSchema)),
  });

export const roleSchema: Describe<AugmentedCreateTrustAdvisorRoleInput> =
  object({
    create: object({
      kind: enums([
        TrustAdvisorRoleKind.DistributionAdvisor,
        TrustAdvisorRoleKind.InvestmentAdvisor,
        TrustAdvisorRoleKind.TrustProtector,
        TrustAdvisorRoleKind.Other,
      ]),
    }),
  });

export const trustAdvisorSchema: Describe<AugmentedCreateTrustAdvisorInput> =
  object({
    create: object({
      individualID: optional(nullable(string())),
      organizationID: optional(nullable(string())),
      level: enums([
        TrustAdvisorLevel.Primary,
        TrustAdvisorLevel.Secondary,
        TrustAdvisorLevel.Tertiary,
        TrustAdvisorLevel.Other,
      ]),
      note: optional(nullable(string())),
    }),
    withRoles: optional(nullable(array(roleSchema))),
  });

export const grantorSchema: Describe<AugmentedCreatePrincipalInput> = object({
  create: object({
    individualID: optional(nullable(string())),
  }),
});

export const taxStatusSchema = optional(
  nullable(
    enums([
      TrustTaxStatus.GrantorTrust,
      TrustTaxStatus.NonGrantorTrust,
      TrustTaxStatus.NonTaxableTrust,
    ])
  )
);

export const nonTrustEntityTaxStatus = optional(
  nullable(
    enums([NonTrustEntityTaxStatus.NonTaxable, NonTrustEntityTaxStatus.Taxable])
  )
);

export const requiredInEstateStatusSchema = enums([
  EntityInEstateStatus.InEstate,
  EntityInEstateStatus.OutOfEstate,
]);

export const inEstateStatusSchema = optional(
  nullable(requiredInEstateStatusSchema)
);

export const gstStatusSchema = optional(
  nullable(
    enums([
      EntityGstStatus.GstExempt,
      EntityGstStatus.GstNonExempt,
      EntityGstStatus.MixedGst,
    ])
  )
);

export const stageSchema = enums([
  EntityStage.AiCreating,
  EntityStage.AiNeedsReview,
  EntityStage.AiCreationFailed,
  EntityStage.Draft,
  EntityStage.ReadyForProposal,
  EntityStage.Implementation,
  EntityStage.Active,
  EntityStage.Completed,
  EntityStage.Archived,
]);

export const attributionSourceSchema = enums([
  EntityAttributionSource.AddeparBulkImport,
  EntityAttributionSource.DocumentExtraction,
  EntityAttributionSource.IntakeForm,
  EntityAttributionSource.StrategyDesigner,
  EntityAttributionSource.FromSpeech,
  EntityAttributionSource.Duplicate,
]);

export const createEntityInput: Describe<CreateEntityInput> = object({
  householdID: string(),
  integrationEntityIDs: optional(nullable(array(string()))),
  addeparLinkedToNongrantorEntity: optional(nullable(booleanType())),
  documentIDs: optional(nullable(array(string()))),
  additionalDocumentIDs: optional(nullable(array(string()))),
  defaultDocumentID: optional(nullable(string())),
  attributionSource: optional(nullable(attributionSourceSchema)),
  stage: stageSchema,
});

export const commonUpdateEntityProperties = {
  addIntegrationEntityIDs: optional(nullable(array(string()))),
  removeIntegrationEntityIDs: optional(nullable(array(string()))),
  clearIntegrationEntities: optional(nullable(booleanType())),
  addeparLinkedToNongrantorEntity: optional(nullable(booleanType())),
  clearAddeparLinkedToNongrantorEntity: optional(nullable(booleanType())),
  clearStateTaxes: optional(nullable(booleanType())),
};

export const createValuationSchema: Describe<AugmentedCreateAssetValuationV2Input> =
  object({
    create: object({
      effectiveDate: date(),
      fixedValuationAmount: optional(nullable(decimal)),
      description: optional(nullable(string())),
    }),
    withAssets: optional(nullable(array(any()))),
  });

export const updateValuationSchema: Describe<AugmentedUpdateAssetValuationV2Input> =
  object({
    id: string(),
    update: object({
      clearAssets: optional(nullable(booleanType())),
      description: optional(nullable(string())),
      effectiveDate: optional(nullable(date())),
      fixedValuationAmount: optional(nullable(decimal)),
    }),
    withAssets: optional(nullable(array(any()))),
  });

export const createAccountSchema: Describe<AugmentedCreateAccountInput> =
  object({
    create: object({
      displayName: string(),
    }),
    withValuations: optional(nullable(array(createValuationSchema))),
    withInitialValuation: optional(nullable(createValuationSchema)),
  });

export const updateAccountSchema: Describe<AugmentedUpdateAccountInput> =
  object({
    id: string(),
    update: object({
      clearInitialValuation: optional(nullable(booleanType())),
    }),
    updateValuations: optional(nullable(array(updateValuationSchema))),
    updateInitialValuation: optional(nullable(updateValuationSchema)),
    withValuations: optional(nullable(array(createValuationSchema))),
    withInitialValuation: optional(nullable(createValuationSchema)),
  });

export const createAnnuitySchema = any();

const updateGratAnnuityV2Input: Describe<UpdateGratAnnuityV2Input> = object({
  paymentAmount: optional(nullable(decimal)),
  termDurationYears: optional(nullable(number())),
  yearOfTerm: optional(nullable(number())),
});

export const updateAnnuitySchema: Describe<AugmentedUpdateGratAnnuityV2Input> =
  object({
    id: string(),
    update: updateGratAnnuityV2Input,
  });

export const stateCodeSchema = enums([...Object.values(StateCode)]);

export const createEntityStateTaxSchema: Describe<AugmentedCreateEntityStateTaxInput> =
  object({
    create: object({
      stateCode: stateCodeSchema,
      inEstateStatus: requiredInEstateStatusSchema,
    }),
  });
