import { Box, Stack, Typography, useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import { useState } from 'react';

import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { ButtonTab, Tabs } from '@/components/navigation/NavigationTabs';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { ChartLegend } from '@/modules/proposal/ChartLegend';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';
import { PreTaxPostTax } from '@/modules/proposal/hooks/useProjectionForScenario';
import { getBeneficiariesFromProposal } from '@/modules/proposal/utils';
import { useProposalYearProjectionOptionsQuery } from '@/pages/proposal/Scenarios/graphql/ProposalYearProjectionOptions.generated';

import { Slide, useQuery } from '../hooks/usePresentation';
import { ScenariosDataComponent } from './ScenariosDataComponent';
import { SlideContainer } from './SlideContainer';

interface Props {
  proposal: ProposalFragment;
  currentSlide: Slide | null;
  initialSelectedTabIndex?: number;
  timeHorizonIdx?: number;
}

export const tabConfigurations: {
  display: string;
  value: Exclude<PreTaxPostTax, 'inTermCAGR'>;
}[] = [
  {
    display: 'Before estate tax',
    value: 'preTax',
  },
  {
    display: 'After estate tax',
    value: 'postTax',
  },
];

export function ImpactComparison({
  proposal,
  currentSlide,
  initialSelectedTabIndex = 0,
  timeHorizonIdx = 0,
}: Props) {
  const query = useQuery();
  const theme = useTheme();
  const viewOnly = useViewOnly();
  const { createErrorFeedback } = useFeedback();

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    initialSelectedTabIndex
  );
  const [selectedYearOptionIndex, setSelectedYearOptionIndex] =
    useState<number>(timeHorizonIdx);

  const entityId = viewOnly ? currentSlide?.params.entity : query.get('entity');

  const beneficiaries = getBeneficiariesFromProposal(proposal);

  const { data: proposalYearProjectionOptionsData } =
    useProposalYearProjectionOptionsQuery({
      variables: {
        entityId: entityId ?? '',
      },
      onError: createErrorFeedback(
        "We weren't able to fetch the data for this page. Please refresh the page and try again."
      ),

      skip: !entityId,
    });

  if (!proposalYearProjectionOptionsData) {
    return null;
  }

  if (!entityId) {
    return null;
  }

  const entityProposal = proposal.entityProposals?.find(
    (entityProposal) => entityProposal.entity.id === entityId
  );

  const yearOptions =
    proposalYearProjectionOptionsData.proposalYearProjectionOptionsV2 ?? false;

  if (!yearOptions) {
    return null;
  }

  const proposalYearProjectionOptions = yearOptions.map((option) => ({
    value: option.value.toString(),
    valueDecimal: new Decimal(option.value),
    display: `${option.display} yr`,
  }));

  if (!entityProposal) {
    throw new Error(`No entityProposal found for entityId ${entityId}`);
  }

  const { taxDrag, proposalScenarios, entity, assetValuationProjectionType } =
    entityProposal;

  return (
    <SlideContainer
      sx={{
        paddingX: 11,
        paddingTop: 6,
      }}
      id="impact-comparison"
      autoZoomOnPrint={true}
      scaleFactor={90}
    >
      <Typography variant="h1" color={theme.palette.primary.main}>
        {entity.gratTrust?.displayName ?? ''}
      </Typography>
      <Typography variant="subtitle1">{`Year ${proposalYearProjectionOptions[selectedYearOptionIndex]?.value}`}</Typography>

      <Stack flexDirection="row" justifyContent="end">
        <Stack width={theme.spacing(36)}>
          <ButtonGroup
            onChange={(_, value) => {
              const idx = proposalYearProjectionOptions.findIndex((option) => {
                if (option.value === value) {
                  return true;
                }

                return false;
              });

              setSelectedYearOptionIndex(idx);
            }}
            label=""
            options={proposalYearProjectionOptions}
            value={
              proposalYearProjectionOptions[selectedYearOptionIndex]?.value
            }
          />
        </Stack>
      </Stack>

      <Stack pt={viewOnly ? 1 : 3}>
        <ChartLegend justifyContent="end" variant="squares" />
      </Stack>
      {viewOnly && (
        <Typography variant="h2" color={theme.palette.primary.main}>
          {tabConfigurations[selectedTabIndex]?.display}
        </Typography>
      )}
      <Box mb={viewOnly ? 1 : 6}>
        <Tabs>
          {tabConfigurations.map(({ display, value }, idx) => (
            <ButtonTab
              key={value}
              display={display}
              isActive={selectedTabIndex === idx}
              onClick={() => setSelectedTabIndex(idx)}
              variant="tab2"
            />
          ))}
        </Tabs>
      </Box>
      <Stack direction="row" spacing={viewOnly ? 1 : 3} justifyContent="center">
        <ScenariosDataComponent
          proposalScenarios={proposalScenarios ?? []}
          entityId={entityId}
          taxDrag={taxDrag ?? new Decimal(0)}
          selectedYearOptionIndex={selectedYearOptionIndex}
          proposalYearProjectionOptions={proposalYearProjectionOptions}
          selectedTabIndex={selectedTabIndex}
          assetValuationProjectionType={assetValuationProjectionType}
          beneficiaries={beneficiaries}
        />
      </Stack>
    </SlideContainer>
  );
}
