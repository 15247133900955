import { Box, Stack } from '@mui/material';
import { chunk } from 'lodash';

import { HouseholdEstateValueMetrics } from '@/modules/household/HouseholdEstateValueMetrics/HouseholdEstateValueMetrics';
import { PresentationSlideSecondaryHeading } from '@/modules/presentation/components/PresentationSlideSecondaryHeading';
import { EntitiesSummaryTable } from '@/modules/presentation/entities/AllEntitiesSlide/EntitiesSummaryTable';
import { PRESENTATION_SPACING_UNITS } from '@/modules/presentation/presentation.constants';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { PresentationBundleKind } from '@/types/schema';

import {
  useClientPresentationDesignerV2Context,
  useRegisterSlide,
} from '../../../ClientPresentationDesignerV2.context';
import { ClientPresentationDesignerV2_EntityOptionsFragment } from '../../../graphql/ClientPresentationDesignerV2.generated';
import { ClientPresentationV2Footer } from '../../ClientPresentationV2Footer';
import { BaseBundleSlideProps } from '../BundleSlide.types';

interface AllEntitiesSlideInnerProps extends BaseBundleSlideProps {
  index: number;
  pageEntities: ClientPresentationDesignerV2_EntityOptionsFragment[];
}
function AllEntitiesSlideInner({
  bundle,
  index,
  pageEntities,
  SlideWrapper = Box,
  isVisible,
}: AllEntitiesSlideInnerProps) {
  const { household } = useClientPresentationDesignerV2Context();
  const slideId = `all-entities-${bundle.id}-${index}`;
  const slideTitle = bundle.displayName || 'All entities';

  useRegisterSlide({
    slideId,
    title: slideTitle,
    includeInToC: index === 0,
    bundleKind: PresentationBundleKind.EntitiesOverviewBundle,
  });

  if (!isVisible) {
    return null;
  }

  return (
    <SlideWrapper key={slideId}>
      <PresentationSlide.Slide
        id={slideId}
        leftHeaderContent={
          <PresentationSlide.MainHeading heading={slideTitle} />
        }
        rightHeaderContent={
          <PresentationSlideSecondaryHeading
            clientDisplayName={household?.displayName || null}
          />
        }
        footer={<ClientPresentationV2Footer slideId={slideId} />}
      >
        <Stack
          spacing={3}
          justifyContent="flex-start"
          p={PRESENTATION_SPACING_UNITS}
          height="100%"
        >
          {index === 0 && (
            <HouseholdEstateValueMetrics
              metrics={household?.estateValueMetrics}
            />
          )}
          <EntitiesSummaryTable entities={pageEntities} />
        </Stack>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}

export function AllEntitiesSlide({
  bundle,
  SlideWrapper,
  isVisible,
}: BaseBundleSlideProps) {
  // 9 rows fit comfortable onto one page, except for the first one, which also has the estate summary metrics and so can have fewer pages.
  const firstEntityPageLength = 7;
  const { entities } = useClientPresentationDesignerV2Context();

  const firstPageEntities = entities.slice(0, firstEntityPageLength);
  const remainingEntitiesPages = chunk(
    entities.slice(firstEntityPageLength),
    9
  );
  const entitiesPages = [firstPageEntities, ...remainingEntitiesPages];

  return (
    <>
      {entitiesPages.map((pageEntities, index) => (
        <AllEntitiesSlideInner
          key={index}
          bundle={bundle}
          index={index}
          pageEntities={pageEntities}
          SlideWrapper={SlideWrapper}
          isVisible={isVisible}
        />
      ))}
    </>
  );
}
