// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import { GraphViz_NodeConfigFragmentDoc } from './GraphVizNodeConfig.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GraphViz_MinimalViewFragment = { __typename?: 'EntityGraphView', id: string, displayName: string, kind?: Types.EntityGraphViewKind | null, orientation?: Types.EntityGraphViewOrientation | null };

export type GraphViz_ViewFragment = { __typename?: 'EntityGraphView', id: string, displayName: string, kind?: Types.EntityGraphViewKind | null, orientation?: Types.EntityGraphViewOrientation | null, nodeConfigurations: Array<{ __typename?: 'EntityGraphNodeConfiguration', nodeID: string, hidden?: boolean | null, xPosition: number, yPosition: number, height?: number | null, width?: number | null, fontSize?: string | null, notes?: string | null }> };

export type UpdateGraphVizViewMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateEntityGraphViewInput;
}>;


export type UpdateGraphVizViewMutation = { __typename?: 'Mutation', updateEntityGraphView: { __typename?: 'EntityGraphView', id: string, displayName: string, kind?: Types.EntityGraphViewKind | null, orientation?: Types.EntityGraphViewOrientation | null, nodeConfigurations: Array<{ __typename?: 'EntityGraphNodeConfiguration', nodeID: string, hidden?: boolean | null, xPosition: number, yPosition: number, height?: number | null, width?: number | null, fontSize?: string | null, notes?: string | null }> } };

export type CreateGraphVizViewMutationVariables = Types.Exact<{
  input: Types.AugmentedCreateEntityGraphViewInput;
}>;


export type CreateGraphVizViewMutation = { __typename?: 'Mutation', createEntityGraphView: { __typename?: 'EntityGraphView', id: string, displayName: string, kind?: Types.EntityGraphViewKind | null, orientation?: Types.EntityGraphViewOrientation | null, nodeConfigurations: Array<{ __typename?: 'EntityGraphNodeConfiguration', nodeID: string, hidden?: boolean | null, xPosition: number, yPosition: number, height?: number | null, width?: number | null, fontSize?: string | null, notes?: string | null }> } };

export type DeleteGraphVizViewMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteGraphVizViewMutation = { __typename?: 'Mutation', deleteEntityGraphView: string };

export const GraphViz_MinimalViewFragmentDoc = gql`
    fragment GraphViz_MinimalView on EntityGraphView {
  id
  displayName
  kind
  orientation
}
    `;
export const GraphViz_ViewFragmentDoc = gql`
    fragment GraphViz_View on EntityGraphView {
  ...GraphViz_MinimalView
  nodeConfigurations {
    ...GraphViz_NodeConfig
  }
}
    ${GraphViz_MinimalViewFragmentDoc}
${GraphViz_NodeConfigFragmentDoc}`;
export const UpdateGraphVizViewDocument = gql`
    mutation UpdateGraphVizView($input: AugmentedUpdateEntityGraphViewInput!) {
  updateEntityGraphView(input: $input) {
    ...GraphViz_View
  }
}
    ${GraphViz_ViewFragmentDoc}`;
export type UpdateGraphVizViewMutationFn = Apollo.MutationFunction<UpdateGraphVizViewMutation, UpdateGraphVizViewMutationVariables>;

/**
 * __useUpdateGraphVizViewMutation__
 *
 * To run a mutation, you first call `useUpdateGraphVizViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGraphVizViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGraphVizViewMutation, { data, loading, error }] = useUpdateGraphVizViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGraphVizViewMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGraphVizViewMutation, UpdateGraphVizViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGraphVizViewMutation, UpdateGraphVizViewMutationVariables>(UpdateGraphVizViewDocument, options);
      }
export type UpdateGraphVizViewMutationHookResult = ReturnType<typeof useUpdateGraphVizViewMutation>;
export type UpdateGraphVizViewMutationResult = Apollo.MutationResult<UpdateGraphVizViewMutation>;
export type UpdateGraphVizViewMutationOptions = Apollo.BaseMutationOptions<UpdateGraphVizViewMutation, UpdateGraphVizViewMutationVariables>;
export const CreateGraphVizViewDocument = gql`
    mutation CreateGraphVizView($input: AugmentedCreateEntityGraphViewInput!) {
  createEntityGraphView(input: $input) {
    ...GraphViz_View
  }
}
    ${GraphViz_ViewFragmentDoc}`;
export type CreateGraphVizViewMutationFn = Apollo.MutationFunction<CreateGraphVizViewMutation, CreateGraphVizViewMutationVariables>;

/**
 * __useCreateGraphVizViewMutation__
 *
 * To run a mutation, you first call `useCreateGraphVizViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGraphVizViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGraphVizViewMutation, { data, loading, error }] = useCreateGraphVizViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGraphVizViewMutation(baseOptions?: Apollo.MutationHookOptions<CreateGraphVizViewMutation, CreateGraphVizViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGraphVizViewMutation, CreateGraphVizViewMutationVariables>(CreateGraphVizViewDocument, options);
      }
export type CreateGraphVizViewMutationHookResult = ReturnType<typeof useCreateGraphVizViewMutation>;
export type CreateGraphVizViewMutationResult = Apollo.MutationResult<CreateGraphVizViewMutation>;
export type CreateGraphVizViewMutationOptions = Apollo.BaseMutationOptions<CreateGraphVizViewMutation, CreateGraphVizViewMutationVariables>;
export const DeleteGraphVizViewDocument = gql`
    mutation DeleteGraphVizView($id: ID!) {
  deleteEntityGraphView(id: $id)
}
    `;
export type DeleteGraphVizViewMutationFn = Apollo.MutationFunction<DeleteGraphVizViewMutation, DeleteGraphVizViewMutationVariables>;

/**
 * __useDeleteGraphVizViewMutation__
 *
 * To run a mutation, you first call `useDeleteGraphVizViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGraphVizViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGraphVizViewMutation, { data, loading, error }] = useDeleteGraphVizViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGraphVizViewMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGraphVizViewMutation, DeleteGraphVizViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGraphVizViewMutation, DeleteGraphVizViewMutationVariables>(DeleteGraphVizViewDocument, options);
      }
export type DeleteGraphVizViewMutationHookResult = ReturnType<typeof useDeleteGraphVizViewMutation>;
export type DeleteGraphVizViewMutationResult = Apollo.MutationResult<DeleteGraphVizViewMutation>;
export type DeleteGraphVizViewMutationOptions = Apollo.BaseMutationOptions<DeleteGraphVizViewMutation, DeleteGraphVizViewMutationVariables>;
export const Operations = {
  Mutation: {
    UpdateGraphVizView: 'UpdateGraphVizView' as const,
    CreateGraphVizView: 'CreateGraphVizView' as const,
    DeleteGraphVizView: 'DeleteGraphVizView' as const
  },
  Fragment: {
    GraphViz_MinimalView: 'GraphViz_MinimalView' as const,
    GraphViz_View: 'GraphViz_View' as const
  }
}